import Avatar from 'components/base/Avatar';
import { useWizardFormContext } from 'providers/WizardFormProvider';
import { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import avatarPlaceholder from 'assets/img/team/avatar.webp';
import AvatarDropzone from 'components/common/AvatarNSDropzone';
import DatePicker from 'components/base/DatePicker';
import { WizardFormData } from 'pages/modules/forms/WizardExample';
import Dropzone from 'components/base/Dropzone';

const WizardPersonalForm = () => {
  const methods = useWizardFormContext<WizardFormData>();
  const { formData, onChange, validation } = methods;
  const [avatar, setAvatar] = useState(avatarPlaceholder);

  const onDrop = (acceptedFiles: File[]) => {
    setAvatar(URL.createObjectURL(acceptedFiles[0]));
  };

  return (
    <>
      <Row className="mb-4">
        <Col md="auto">
          <Avatar src={avatar} placeholder size="3xl" />
        </Col>
        <Col>
          <AvatarDropzone onDrop={onDrop} />
        </Col>
      </Row>
      <Form.Group className="mb-2">
        <Form.Label>Module General</Form.Label>
        <Form.Control
          name="general_v1"
          type="text"
          onChange={onChange}
          required={validation}
          placeholder='Module General v1.0'
          value="Module General v1.0"
          readOnly
        >
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          This field is required.
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label>Files for module:</Form.Label>
          <Dropzone onDrop={acceptedFiles => console.log(acceptedFiles)} />
        <Form.Control.Feedback type="invalid">
          This field is required.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-2">
        <Form.Label>Description for version</Form.Label>
        <Form.Control
          as="textarea"
          value={formData.address}
          onChange={onChange}
          rows={4}
          required={validation}
        />
        <Form.Control.Feedback type="invalid">
          This field is required.
        </Form.Control.Feedback>
      </Form.Group>
    </>
  );
};

export default WizardPersonalForm;
