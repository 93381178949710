import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  Icon,
  UilChartPie,
  UilRobot
} from '@iconscout/react-unicons';
import { IUser } from 'data/models/user/IUser';

export interface Route {
  name: string;
  icon?: IconProp | string | string[];
  iconSet?: 'font-awesome' | 'feather' | 'unicons';
  pages?: Route[];
  path?: string;
  pathName?: string;
  flat?: boolean;
  topNavIcon?: string;
  dropdownInside?: boolean;
  active?: boolean;
  new?: boolean;
  hasNew?: boolean;
  next?: boolean;
  allowedGroups?: string[];
}

export interface RouteItems {
  label: string;
  horizontalNavLabel?: string;
  icon: Icon;
  labelDisabled?: boolean;
  pages: Route[];
  megaMenu?: boolean;
  active?: boolean;
}

export const filterRoutesByPermission = (
  routes: RouteItems[],
  user: IUser | null
): RouteItems[] => {
  if (!user) return [];

  const userGroups = user.groups.map(group => group.group.name);

  const filterRoutes = (pages: Route[]): Route[] => {
    return pages
      .filter(route => {
        if (!route.allowedGroups) return true;

        return route.allowedGroups.some(group => userGroups.includes(group));
      })
      .map(route => ({
        ...route,
        pages: route.pages ? filterRoutes(route.pages) : undefined,
      }));
  };

  return routes
    .map(routeItem => ({
      ...routeItem,
      pages: filterRoutes(routeItem.pages),
    }))
    .filter(routeItem => routeItem.pages.length > 0);
};

export const routes: RouteItems[] = [
  {
    label: 'MAIN MENU',
    horizontalNavLabel: 'home',
    active: true,
    icon: UilRobot,
    labelDisabled: false,
    pages: [
      {
        name: `${process.env.REACT_APP_NAME} ${process.env.REACT_APP_TITLE}`,
        icon: 'message-square',
        active: true,
        flat: true,
        hasNew: true,
        allowedGroups: ['Default Group'], // Grupo default: 'Default group'
        path: '/anubis/AI'
      },
      {
        name: 'Dashboard AI',
        path: '/anubis/dashboard',
        pathName: 'dashboard',
        topNavIcon:'phone',
        icon: 'bar-chart-2',
        allowedGroups: ['Default Group'],
        active: true
      },
      {
        name: 'Conversations',
        path: '/anubis/conversations',
        pathName: 'conversations',
        icon: 'message-circle',
        allowedGroups: ['Default Group'],
        active: true
      },
      {
        name: 'Shell',
        path: '/anubis/testshell',
        pathName: 'testshell',
        icon: 'command',
        allowedGroups: ['Shell Users'],
        active: true
      },
    ]
  },
  {
  label: 'ADMINISTRATION',
    horizontalNavLabel: 'administration',
    active: true,
    icon: UilRobot,
    labelDisabled: false,
    pages: [
      {
        name: 'My Account',
        icon: 'user-check',
        active: true,
        flat: true,
        path: '',
        pages: [
          {
            name: 'Profile',
            path: 'profile',
            pathName: 'myaccount',
            active: true
          }, //]}]} /*, <--- SOF*//
          {
            name: 'My Brain',
            path: 'mybrain',
            pathName: 'mybrain',
            allowedGroups: ['Shell Users'],
            active: true
          },
          {
            name: 'Security',
            path: '#',
            pathName: 'myaccount',
            active: false
          },
          {
            name: 'Payment',
            path: '#',
            pathName: 'myaccount',
            active: false
          },
          {
            name: 'API Center',
            path: '#',
            pathName: 'myaccount',
            active: false
          },
          {
            name: 'My Cart',
            path: '#',
            pathName: 'myaccount',
            active: false
          }
        ]
      },
      {
        name: 'Users & Tokens',
        icon: 'users',
        active: true,
        flat: false,
        path: 'userstokens/',
        allowedGroups: ['Shell Users']
      },
      {
        name: `${process.env.REACT_APP_NAME} Neuroskills`,
        icon: 'cpu',
        active: true,
        flat: false,
        path: 'neuroskills/',
        allowedGroups: ['Shell Users']
      },
      {
        name: `${process.env.REACT_APP_NAME} Prompts`,
        icon: 'command',
        active: true,
        flat: false,
        path: 'prompts/',
        allowedGroups: ['Shell Users']
      }
    ]
  },
  {
    label: 'CONFIGURATION',
      horizontalNavLabel: 'configuration',
      active: false,
      icon: UilRobot,
      labelDisabled: false,
      pages: [
        {
          name: 'General',
          icon: 'settings',
          active: false,
          flat: false,
          path: '#',
	allowedGroups: ['Shell Users']
        },
        {
          name: 'Advanced',
          icon: 'tool',
          active: false,
          flat: false,
          path: '#',
	allowedGroups: ['Shell Users']
        }
      ]
  },
  {
    label: 'SUPPORT',
    horizontalNavLabel: 'support',
    active: false,
    icon: UilRobot,
    labelDisabled: false,
      pages: [
        {
          name: 'Help Center',
          icon: 'help-circle',
          active: false,
          flat: false,
          hasNew: true,
          dropdownInside: true,
	allowedGroups: ['Shell Users'],
          pages: [
            {
              name: 'Documentation',
              icon: '',
              active: false,
              flat: false,
              path: '#',
	allowedGroups: ['Shell Users']
            },
            {
              name: 'Request a ticket',
              path: '#',
              pathName: 'ticket',
	allowedGroups: ['Shell Users']
            },
            {
              name: 'Contact Us',
              path: '#',
              new: true,
              pathName: 'contact',
	allowedGroups: ['Shell Users']
            },
            {
              name: 'Terms & Privacy',
              path: '#',
              pathName: 'terms',
	allowedGroups: ['Shell Users']
            },
            {
              name: 'Cookie Policy',
              path: '#',
              pathName: 'cookie',
	allowedGroups: ['Shell Users']
            }
          ]
        }
      ]
    }
];
