import { useState } from 'react';
import { Card, Col, Dropdown, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import behance from 'assets/img/nav-icons/behance.webp';
import googleCloud from 'assets/img/nav-icons/google-cloud.webp';
import slack from 'assets/img/nav-icons/slack.webp';
import gitlab from 'assets/img/nav-icons/gitlab.webp';
import bitbucket from 'assets/img/nav-icons/bitbucket.webp';
import googleDrive from 'assets/img/nav-icons/google-drive.webp';
import trello from 'assets/img/nav-icons/trello.webp';
import figma from 'assets/img/nav-icons/figma.webp';
import twitter from 'assets/img/nav-icons/twitter.webp';
import pinterest from 'assets/img/nav-icons/pinterest.webp';
import ln from 'assets/img/nav-icons/ln.webp';
import googleMaps from 'assets/img/nav-icons/google-maps.webp';
import googlePhotos from 'assets/img/nav-icons/google-photos.webp';
import isoicon from 'assets/img/Anubis/iso.png';
import xgis from 'assets/img/Anubis/xgis.png';
import sentinel from 'assets/img/Anubis/sentinel.png'
import whatsapp from 'assets/img/Anubis/whatsapp.png'
import hazbot from 'assets/img/Anubis/Hazball.png'
import aidetect from 'assets/img/Anubis/AIDetect.png'

import Scrollbar from 'components/base/Scrollbar';
import Badge from 'components/base/Badge';
import { FeatherIcon, Star } from 'lucide-react';
import { useUserContext } from 'layouts/UserContext';

type BadgeType = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info';

interface NineDotMenuProps {
  onSelect?: () => void;
}

interface BadgeConfig {
  type: BadgeType;
  text: string;
}

interface MenuItem {
  img: string;
  title: string;
  width: string;
  link: string;
  status: string;
  target?: string;
  badge?: BadgeConfig;
  group: string;	
}

const NineDotMenu: React.FC<NineDotMenuProps> = ({ onSelect }) => {
  const { user, isLoading } = useUserContext();

  if (isLoading) {
    return <>...</>;
  }

  if (!user) {
    return <>Error: No user detected</>;
  }

  // Obtener los grupos del usuario
  const userGroupNames = user.groups.map((g) => g.group.name);

  // Definir las apps con sus respectivos grupos
  const items: MenuItem[] = [
    { img: xgis, title: 'xGisMaps', group: 'disabled', width: '30', link: '#', status: 'grayscale(100%)', badge: { type: 'info', text: 'soon' } },
    { img: sentinel, title: 'Sentinel', group: 'disabled', width: '30', link: '#', status: 'grayscale(100%)', badge: { type: 'info', text: 'soon' } },
    { img: whatsapp, title: 'WhatsApp', group: 'Default Group', width: '30', link: 'whatsapp', status: 'grayscale(0%)', badge: { type: 'warning', text: 'new' }, target: '_self' },
    { img: hazbot, title: 'Hazbot', group: 'disabled', width: '30', link: '#', status: 'grayscale(100%)', badge: { type: 'info', text: 'soon' } },
    { img: aidetect, title: 'AIDetect', group: 'Default Group', width: '30', link: 'aidetect', status: 'grayscale(0%)', badge: { type: 'warning', text: 'new' }, target: '_self' },
    { img: isoicon, title: 'ISOTest', group: 'Default Group', width: '22', link: 'isotest', status: 'grayscale(0%)', badge: { type: 'warning', text: 'new' }, target: '_self' },
  ];

  return (
    <Dropdown.Menu
      align="end"
      className="navbar-dropdown-caret py-0 dropdown-nine-dots shadow border"
    >
      <Card className="position-relative border-0" style={{ height: '20rem', minWidth: 244 }}>
        <Scrollbar>
          <Card.Body className="pt-3 px-3 pb-0">
            <Row className="text-center align-items-center g-0">
              {items.map(item => {
                const hasAccess = userGroupNames.includes(item.group);
                const iconStyle = { filter: hasAccess ? 'grayscale(0%)' : 'grayscale(100%)' };
                const linkClasses = `d-block bg-body-secondary-hover p-2 rounded-3 text-center text-decoration-none mb-3 ${
                  hasAccess ? '' : 'disabled'
                }`;

                return (
                  <Col xs={4} key={item.title}>
                    <Link
                      to={hasAccess ? item.link : '#'}
                      target={item.target}
                      className={linkClasses}
                      onClick={(e) => !hasAccess && e.preventDefault()}
                      style={!hasAccess ? { pointerEvents: 'none', opacity: 0.5 } : {}}
                    >
                      <div className="relative top-0 right-0" style={{ transform: 'rotate(20deg)' }}>
                        {item.badge && (
                          <Badge bg={item.badge.type} variant="phoenix" iconPosition="end" className="fs-12" icon={<Star size={10} className="ms-1" />}>
                            {item.badge.text}
                          </Badge>
                        )}
                      </div>
                      <img className="fixed" src={item.img} alt={item.title} width={item.width || 30} style={iconStyle} />
                      <p className="mb-0 text-body-emphasis text-truncate fs-10 mt-1 pt-1">
                        {item.title}
                      </p>
                    </Link>
                  </Col>
                );
              })}
            </Row>
          </Card.Body>
        </Scrollbar>
      </Card>
    </Dropdown.Menu>
  );
};

export default NineDotMenu;