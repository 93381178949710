import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { Link } from 'react-router-dom';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import Button from 'components/base/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Project, Status, projects } from 'data/project-management/projects';
import Avatar from 'components/base/Avatar';
import { Col, Form, Modal, ProgressBar, Row } from 'react-bootstrap';
import classNames from 'classnames';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/UsersTokensActionDropdownItems';
import AvatarDropdown from 'components/common/AvatarDropdown';
import { faFileExport, faPlus } from '@fortawesome/free-solid-svg-icons';
import SearchBox from 'components/common/SearchBox';
import { ChangeEvent, useState } from 'react';
import AvatarDropzone from 'components/common/AvatarPTSDropzone';
import avatarPlaceholder from 'assets/img/team/avatar.webp';
import ReactSelect from 'components/base/ReactSelect';


const columns: ColumnDef<Project>[] = [
  {
    accessorKey: 'name',
    header: 'Neuro Prompt',
    cell: ({ row: { original } }) => {
      const { name } = original;
      return (
        <Link
          to="#"
          className="text-decoration-none fw-bold fs-9"
        >
          {name}
        </Link>
      );
    },
    meta: {
      cellProps: { className: 'white-space-nowrap' },
      headerProps: { style: { width: '30%' } }
    }
  },
  {
    id: 'assigness',
    header: 'NeuroSkills',
    cell: ({ row: { original } }) => {
      const { assigness } = original;
      return (
        <Avatar.Group
          total={assigness.length}
          size="s"
          className="overflow-hidden"
        >
          {assigness.slice(0, 4).map(assigne => (
            <AvatarDropdown user={assigne} size="s" key={assigne.id} />
          ))}
        </Avatar.Group>
      );
    },
    meta: {
      cellProps: { className: 'ps-3' },
      headerProps: { style: { width: '10%' }, className: 'ps-3' }
    }
  },
  {
    header: 'Register date',
    accessorKey: 'start',
    meta: {
      cellProps: { className: 'ps-3 fs-9 text-body white-space-nowrap' },
      headerProps: { style: { width: '10%' }, className: 'ps-3' }
    }
  },
  {
    header: 'Last Update',
    accessorKey: 'deadline',
    meta: {
      cellProps: { className: 'ps-3 fs-9 text-body white-space-nowrap' },
      headerProps: { style: { width: '15%' }, className: 'ps-3' }
    }
  },
  {
    id: 'calculation',
    header: 'Certified',
    cell: ({ row: { original } }) => {
      const { calculation } = original;
      if (calculation) {
        return (
          <>
            <p className="fw-bold text-body-emphasis fs-9 mb-0">
              {calculation?.amount}
            </p>
            <p className="fw-semibold fs-10 text-body-tertiary mb-0">
              {calculation?.label}
            </p>
          </>
        );
      }
      return (
        <Button variant="phoenix-secondary" className="btn-icon">
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      );
    },
    meta: {
      cellProps: { className: 'ps-3' },
      headerProps: { style: { width: '12%' }, className: 'ps-3' }
    }
  },
  {
    header: 'Status',
    id: 'status',
    cell: ({ row: { original } }) => {
      const { statusProgress } = original;

      return (
        <ProgressBar style={{ height: 3 }} className="progress-stack">
          {Object.keys(statusProgress).map(item => (
            <ProgressBar
              variant={classNames({
                success: item === 'completed',
                info: item === 'ongoing',
                danger: item === 'inactive',
                warning: item === 'critical'
              })}
              now={statusProgress[item as keyof Status]}
              key={item}
            />
          ))}
        </ProgressBar>
      );
    },
    meta: {
      cellProps: { className: 'ps-8' },
      headerProps: { style: { width: '10%' }, className: 'ps-8' }
    }
  },
  {
    id: 'action',
    cell: () => (
      <RevealDropdownTrigger>
        <RevealDropdown>
          <ActionDropdownItems />
        </RevealDropdown>
      </RevealDropdownTrigger>
    ),
    meta: {
      headerProps: { style: { width: '10%' }, className: 'text-end' },
      cellProps: { className: 'text-end' }
    }
  }
];

const NeuroSkillsDashboardTable = () => {
  const table = useAdvanceTable({
    data: projects,
    columns,
    pageSize: 6,
    pagination: true,
    sortable: true
  });
  
  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [avatar, setAvatar] = useState(avatarPlaceholder);

  const onDrop = (acceptedFiles: File[]) => {
    setAvatar(URL.createObjectURL(acceptedFiles[0]));
  };

  return (
    <>
    <div>
      <Row>
        <div className="mb-4">
          <Row className="g-3">
            <Col xs="auto">
              <SearchBox
                placeholder="Search Prompts"
                onChange={handleSearchInputChange} />
            </Col>
            <Col
              xs="auto"
              className="scrollbar overflow-hidden-y flex-grow-1"
            ></Col>
            <Col xs="auto">
              <Button disabled variant="link" className="text-body me-4 px-0">
                <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                Export
              </Button>
              <Button variant="primary" onClick={handleShow}>
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                Add prompt
              </Button>
            </Col>
          </Row>
        </div>
      </Row>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add new prompt</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Lists: [group prompts], avatar
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Prompt Title</Form.Label>
              <Form.Control type="text" placeholder="Title new prompt" autoFocus />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Label>Prompt conditions</Form.Label>
              <Form.Control as="textarea" rows={3} placeholder="List of conditions" />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>Applied Neuriskills</Form.Label>
              <ReactSelect
                options={[
                   {
                    value: 'all',
                    label: 'Add All neuroskills registered'
                  }
                ]}
                isMulti
                placeholder="Select neuroskills registered..."
              />
              <Form.Control.Feedback type="invalid">
                This field is required.
              </Form.Control.Feedback>
            </Form.Group>
            <Row className="mb-4">
              <Col md="auto">
                <Avatar src={avatar} placeholder size="3xl" />
              </Col>
              <Col>
                <AvatarDropzone onDrop={onDrop} />
              </Col>
            </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleClose}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal><AdvanceTableProvider {...table}>
        <AdvanceTable
          tableProps={{
            className: 'phoenix-table border-top border-translucent fs-9'
          }} />
        <AdvanceTableFooter pagination />
      </AdvanceTableProvider>
    </div></>
  );
};

export default NeuroSkillsDashboardTable;