import { WizardFormData } from 'pages/modules/forms/WizardExample';
import { useWizardFormContext } from 'providers/WizardFormProvider';
import { Col, Form, Row } from 'react-bootstrap';

const WizardBillingForm = () => {
  const { formData, onChange, validation } =
    useWizardFormContext<WizardFormData>();

  return (
    <div>
      <Row className="gx-3 gy-2">
        <Col lg={12}>
        You can't register more modules.
        Please continue.
        </Col>
      </Row>
    </div>
  );
};

export default WizardBillingForm;
