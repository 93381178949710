//*
// Declaraciones globales
declare global {
  interface Window {
    reconnectHandler: NodeJS.Timeout | undefined;
  }
}

// Initialize the property
if (typeof window !== 'undefined') {
  window.reconnectHandler = undefined;
}

import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Button, Card, Col, Form, Row, Table, Badge, Spinner } from "react-bootstrap";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { MessageSquare, Send, Server, Bot, Activity, Database, Globe, Brain } from 'lucide-react';
import WidgetsSectionTitle from '../widgets/WidgetsSectionTitle';
import { faRobot } from '@fortawesome/free-solid-svg-icons';
import ircService, { IRCConfig, IRCMessage } from '../../../service/ircService';
import { channel } from 'diagnostics_channel';

// Interfaces
interface MessageIntent {
  type: 'direct_command' | 'ml_query' | 'api_query' | 'hybrid' | 'default';
  apiType?: string;
  context?: string;
  confidence?: number;
}

interface ProxyConfig {
  enabled: boolean;
  endpoint: string;
  authToken?: string;
}

interface ApiConfig {
  name: string;
  enabled: boolean;
  endpoint: string;
  apiKey?: string;
  priority: number;
  method?: 'GET' | 'POST'; // Método HTTP a usar
  requestFormat?: {
    // Formato de la petición
    queryParam?: string; // Nombre del parámetro para consultas GET
    bodyParam?: string;  // Nombre del parámetro para el body en POST
    headerAuthPrefix?: string; // Prefijo para autenticación (Bearer, Token, etc.)
  };
  responseFormat?: {
    // Formato de la respuesta
    responsePath?: string; // Ruta al campo de respuesta (ej: "data.reply")
  };
}

interface BotConfig {
  server: string;
  port: number;
  nickname: string;
  alternativeNicks: string[];
  channels: string[];
  mlEndpoint: string;
  isActive: boolean;
  useSSL?: boolean;
  password?: string;
  proxy: ProxyConfig;
  apis: ApiConfig[];
  preferML: boolean;
  preferAPIs?: boolean;
}

interface BotMetrics {
  messagesProcessed: number;
  activeChannels: number;
  uptime: number;
  lastTraining: string;
  mlQueries: number;
  apiQueries: number;
}

interface ActivityHistory {
  timestamps: string[];
  messages: number[];
  ml: number[];
  api: number[];
}

const IRCBotDashboard: React.FC = () => {
  // State Declarations
  const [messages, setMessages] = useState<IRCMessage[]>([]);
  const [botConfig, setBotConfig] = useState<BotConfig>(() => {
    // Try to load configuration from localStorage
    const hatateToken = localStorage.getItem('hatateToken');
    const savedConfig = localStorage.getItem('ircBotConfig');
    return savedConfig ? JSON.parse(savedConfig) : {
      server: 'irc.linuxcorp.cl',
      port: 6667,
      nickname: 'MyBot',
      alternativeNicks: ['AnubisBot', 'MLBot', 'TrainerBot', 'AnubisAssistant'],
      channels: ['#training', '#support', '#general'],
      mlEndpoint: 'https://backend.anubisai.net/api/mybrain',
      isActive: false,
      useSSL: false,
      proxy: {
        enabled: true,
        endpoint: 'https://backend.anubisai.net/api/mybrain/ml',
        authToken: ''
      },
      apis: [
        { 
          name: 'Clima',
          enabled: true,
          endpoint: 'https://api.openweathermap.org/data/2.5/weather',
          apiKey: '',
          priority: 2
        },
        { 
          name: 'Noticias',
          enabled: true,
          endpoint: 'https://newsapi.org/v2/everything',
          apiKey: '',
          priority: 3
        },
        {
          name: 'Anubis',
          enabled: true,
          endpoint: 'https://backend.anubisai.net/api/bot/llm',
          apiKey: hatateToken, //'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2N2NiY2RlYWViYzE5ZWU3NjEyNzU5MDEiLCJleHAiOjE3NDY1OTczNzAuNjUzLCJpYXQiOjE3NDE0MDk3NzB9.ujLbx4EEMzYL3HMaNUK9S_utqS0Rt-jXkBbKYKv_2kg',
          priority: 1
        }
      ],
      preferML: true
    };
  });
  
  // Improved Metrics State with Better Update Mechanism
  const [metrics, setMetrics] = useState<BotMetrics>({
    messagesProcessed: 0,
    activeChannels: 0,
    uptime: 0,
    lastTraining: new Date().toISOString(),
    mlQueries: 0,
    apiQueries: 0
  });

  // Improved Activity History State
  const [activityHistory, setActivityHistory] = useState<ActivityHistory>({
    timestamps: [],
    messages: [],
    ml: [],
    api: []
  });

  
  const [selectedChannel, setSelectedChannel] = useState<string>('#training');
  const [commandInput, setCommandInput] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [currentNick, setCurrentNick] = useState<string>(botConfig.nickname);
  const [connectionErrors, setConnectionErrors] = useState<string[]>([]);
  const [reconnectAttempts, setReconnectAttempts] = useState<number>(0);
  const [showApiConfig, setShowApiConfig] = useState<boolean>(false);
  const autoResponseEnabledRef = useRef<boolean>(false);

  // Añadir estado para el modo automático
  const [autoResponseEnabled, setAutoResponseEnabled] = useState<boolean>(() => {
    // Try to load from localStorage if available
    const savedState = localStorage.getItem('ircBotAutoResponse');
    return savedState ? JSON.parse(savedState) === true : false;
  });
  
  // Add this effect to save autoResponseEnabled to localStorage
  useEffect(() => {
    localStorage.setItem('ircBotAutoResponse', JSON.stringify(autoResponseEnabled));
    autoResponseEnabledRef.current = autoResponseEnabled;
  console.log(`Auto-response state synchronized: ${autoResponseEnabled}`);

  }, [autoResponseEnabled]);  
  // Referencia al contenedor de mensajes para auto-scroll
  const messagesEndRef = useRef<HTMLDivElement>(null);

    // Utility function to get current time as string
    const getCurrentTimeString = useCallback(() => {
      const now = new Date();
      return `${now.getHours().toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}`;
    }, []);

    //--
// Enhanced Training Method
const trainMLModel = async () => {
  // Filter messages with meaningful content
  const trainingData: TrainingDataPair[] = messages
    .filter(msg => 
      msg.channel === selectedChannel && 
      msg.text.trim().length > 10 && // Minimum meaningful length
      msg.type !== 'system' // Exclude system messages
    )
    .map((msg, index, array) => {
      // Create context-aware training pairs
      const nextMessage = array[index + 1];
      return {
        input: msg.text.trim(),
        output: nextMessage 
          ? (nextMessage.sender === currentNick 
             ? nextMessage.text 
             : "Continuar conversación")
          : "Respuesta generada por contexto"
      };
    })
    .filter(pair => pair.output && pair.output.trim().length > 0) // Ensure non-empty outputs
    .slice(-50); // Limit to last 50 meaningful messages

  if (trainingData.length === 0) {
    addSystemMessage('No hay datos suficientes para entrenamiento', 'warning');
    return;
  }

  try {
    const response = await fetch(`${botConfig.mlEndpoint}/train`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        connectionId: ircService.connectionId,
        channel: selectedChannel,
        data: trainingData
      })
    });

    const result = await response.json();

    if (result.success) {
      addSystemMessage(`Entrenamiento completado: ${result.examples_added} ejemplos añadidos`);
      
      // Optional: Analyze conversation for additional learning
      //(trainingData);
    } else {
      addSystemMessage(`Error en entrenamiento: ${result.message}`, 'error');
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
        addSystemMessage(`Error de conexión al entrenar: ${error.message}`, 'error');
    } else {
        addSystemMessage(`Error de conexión desconocido: ${String(error)}`, 'error');
    }
  }
};

// Additional method to extract more nuanced learning opportunities
// Define interface for training data pair
interface TrainingDataPair {
  input: string;
  output: string;
}

const analyzeConversationForLearning = async (trainingData: TrainingDataPair[]) => {
  try {
    const response = await fetch(`${botConfig.mlEndpoint}/analyze-conversation`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        connectionId: ircService.connectionId,
        channel: selectedChannel,
        text: trainingData.map(pair => ({
          input: pair.input,
          output: pair.output
        }))
      })
    });

    const result = await response.json();

    if (result.success && result.patterns_found > 0) {
      addSystemMessage(`Aprendizaje adicional: ${result.patterns_found} patrones encontrados`);
    }
  } catch (error) {
    console.error('Error en análisis de conversación:', error);
  }
};
//--

        // Estructura para almacenar métricas históricas
const activityMetrics = {
  // Array circular de métricas por hora (24 horas)
  hourly: Array(24).fill(null).map(() => ({
    messages: 0,
    ml: 0,
    api: 0,
    timestamp: new Date().toISOString()
  })),
  // Métricas de la hora actual
  current: {
    messages: 0,
    ml: 0,
    api: 0,
    timestamp: new Date().toISOString()
  },
  lastUpdateHour: new Date().getHours()
};

// Improved Metrics Update Function
const updateMetrics = useCallback((updateFn: (prevMetrics: BotMetrics) => BotMetrics) => {
  setMetrics(prevMetrics => {
    const newMetrics = updateFn(prevMetrics);
    
    // Update activity history whenever metrics change
    setActivityHistory(prevHistory => {
      const currentTime = getCurrentTimeString();
      
      // Check if the current timestamp already exists
      if (prevHistory.timestamps.length > 0 && 
          prevHistory.timestamps[prevHistory.timestamps.length - 1] === currentTime) {
        // Update the last entry
        const updatedTimestamps = [...prevHistory.timestamps];
        const updatedMessages = [...prevHistory.messages];
        const updatedMl = [...prevHistory.ml];
        const updatedApi = [...prevHistory.api];
        
        const lastIndex = updatedTimestamps.length - 1;
        updatedMessages[lastIndex] = newMetrics.messagesProcessed;
        updatedMl[lastIndex] = newMetrics.mlQueries;
        updatedApi[lastIndex] = newMetrics.apiQueries;
        
        return {
          timestamps: updatedTimestamps,
          messages: updatedMessages,
          ml: updatedMl,
          api: updatedApi
        };
      }
      
      // Add a new entry if timestamp is different
      return {
        timestamps: [...prevHistory.timestamps, currentTime].slice(-6),
        messages: [...prevHistory.messages, newMetrics.messagesProcessed].slice(-6),
        ml: [...prevHistory.ml, newMetrics.mlQueries].slice(-6),
        api: [...prevHistory.api, newMetrics.apiQueries].slice(-6)
      };
    });
    
    return newMetrics;
  });
}, [getCurrentTimeString]);

  // Helper function to determine response source
  const determineResponseSource = useCallback((message: IRCMessage): 'ml' | 'api' | 'irc' | 'fallback' => {
    // Implement logic to determine response source
    if (message.text.includes('ml-specific-keyword')) return 'ml';
    if (message.text.includes('api-specific-keyword')) return 'api';
    if (message.text.includes('fallback-specific-keyword')) return 'fallback';
    return 'irc';
  }, []);
  
  // Efecto para inicializar los listeners del servicio IRC
  useEffect(() => {
    const messageUnsubscribe = ircService.onMessage((message) => {
      // Procesar mensajes entrantes con ML e integración API
      if (message.from !== 'System' && message.from !== currentNick) {
        processIncomingMessage({
          id: Date.now() * 1000 + Math.floor(Math.random() * 1000), // ID único
          text: message.text,
          sender: message.from || 'Unknown',
          channel: message.channel || 'system',
          timestamp: message.timestamp,
          type: 'message',
          source: 'user'
        });
      } else {
        // Mensajes de sistema o propios
        const ircMessage: IRCMessage = {
          id: Date.now() * 1000 + Math.floor(Math.random() * 1000), // ID único
          text: message.text,
          sender: message.from || 'Unknown',
          channel: message.channel || 'system',
          timestamp: message.timestamp,
          type: 'message',
          source: 'irc'
        };
        setMessages(prev => [...prev, ircMessage]);
        
        // Incrementar contador de mensajes
        setMetrics(prev => ({
          ...prev,
          messagesProcessed: prev.messagesProcessed + 1
        }));
      }
    });

    const eventUnsubscribe = ircService.onEvent((event) => {
      addSystemMessage(event.text);
    });

    const errorUnsubscribe = ircService.onError((error) => {
      addSystemMessage(error.text, 'error');
      setConnectionErrors(prev => [...prev, error.text]);
    });

    const connectionUnsubscribe = ircService.onConnectionChange((connected) => {
      setBotConfig(prev => ({ ...prev, isActive: connected }));
      if (connected) {
        addSystemMessage('Conexión IRC establecida correctamente');
      } else {
        addSystemMessage('Conexión IRC cerrada');
      }
    });

    // Iniciar contador de uptime si el bot está activo
    let uptimeInterval: NodeJS.Timeout | null = null;
    if (botConfig.isActive) {
      uptimeInterval = setInterval(() => {
        setMetrics(prev => ({
          ...prev,
          uptime: prev.uptime + 1
        }));
      }, 1000);
    }

    // Limpiar suscripciones al desmontar
    return () => {
      messageUnsubscribe();
      eventUnsubscribe();
      errorUnsubscribe();
      connectionUnsubscribe();
      
      if (uptimeInterval) {
        clearInterval(uptimeInterval);
      }
      
      // Desconectar el IRC si el componente se desmonta mientras está conectado
      if (botConfig.isActive) {
        ircService.disconnect().catch(console.error);
      }
    };
  }, [botConfig.isActive, currentNick]);

  // Auto-scroll cuando hay nuevos mensajes
  /* useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]); */
  useEffect(() => {
    if (messagesEndRef.current) {
      // Obtiene el elemento contenedor de mensajes (chat-container)
      const chatContainer = messagesEndRef.current.closest('.chat-container');
      
      if (chatContainer) {
        // Hacer scroll solo dentro del contenedor de chat
        chatContainer.scrollTop = chatContainer.scrollHeight;
      }
    }
  }, [messages])

  const handleStartBot = async () => {
    setLoading(true);
    setConnectionErrors([]);
    setReconnectAttempts(0);
  
    // Modo de simulación para pruebas (elimina esto cuando el backend esté listo)
    const simulationMode = false; // Cambia a false cuando quieras usar el backend real
  
    if (simulationMode) {
      try {
        addSystemMessage(`Iniciando en modo de simulación...`);
        await new Promise(resolve => setTimeout(resolve, 800));
        
        addSystemMessage(`Conectado a ${botConfig.server}:${botConfig.port} (simulado)`);
        setBotConfig(prev => ({ ...prev, isActive: true }));
        setCurrentNick(botConfig.nickname);
        
        // Simular una conexión exitosa
        addSystemMessage(`Unido a canales: ${botConfig.channels.join(', ')}`);
        
        // Mensaje de bienvenida simulado
        setTimeout(() => {
          const welcomeMsg: IRCMessage = {
            id: Date.now() * 1000 + Math.floor(Math.random() * 1000),
            text: `Bienvenido a ${selectedChannel}! Estoy en modo de simulación para desarrollo.`,
            sender: 'IRCServer',
            channel: selectedChannel,
            timestamp: new Date().toISOString(),
            type: 'message',
            source: 'irc'
          };
          setMessages(prev => [...prev, welcomeMsg]);
        }, 1500);
        
        // Actualizar métricas
        setMetrics(prev => ({
          ...prev,
          activeChannels: botConfig.channels.length,
          uptime: 0,
          mlQueries: 0,
          apiQueries: 0
        }));
        
        setLoading(false);
        return;
      } catch (error: any) {
        // Continuar con el método normal si hay algún error
      }
    }
  
    try {
      addSystemMessage(`Iniciando diagnóstico de conexión a ${botConfig.server}:${botConfig.port}...`);
      
      addSystemMessage(`Configurando proxy de conexión IRC en el backend...`);
      
      // Usar la nueva función de reconexión con nicknames alternativos
      const connectionConfig: IRCConfig = {
        server: botConfig.server,
        port: botConfig.port,
        nickname: botConfig.nickname,
        alternativeNicks: botConfig.alternativeNicks,
        channels: botConfig.channels,
        useSSL: botConfig.useSSL,
        password: botConfig.password
      };
      
      // Intentar conexión a través del servicio con soporte para nicknames alternativos
      const connectionId = await ircService.reconnectWithAlternativeNick(connectionConfig);
      
      // Consultar al servicio para obtener el nickname que realmente se asignó
      const connectionStatus = await ircService.checkConnection(connectionId);
      if (connectionStatus.nickname) {
        setCurrentNick(connectionStatus.nickname);
      } else {
        setCurrentNick(botConfig.nickname);
      }
      
      // Verificar estado de auto-respuesta
      if (connectionStatus.autoResponse !== undefined) {
        console.log(`Setting initial autoResponse state to: ${connectionStatus.autoResponse}`);
        // Important: Update both the ref and the state
        autoResponseEnabledRef.current = connectionStatus.autoResponse;
        setAutoResponseEnabled(connectionStatus.autoResponse);
      } else {
        // If server doesn't provide autoResponse status, assume it's disabled
        console.log('No autoResponse status provided by server, defaulting to disabled');
        autoResponseEnabledRef.current = false;
        setAutoResponseEnabled(false);
      }
      
      addSystemMessage(`Conexión establecida con ID: ${connectionId}`);
      
      // Inicializar sistemas de ML y API
      addSystemMessage(`Preparando integración con sistema ML en ${botConfig.mlEndpoint}`);
      addSystemMessage(`Configurando ${botConfig.apis.filter(api => api.enabled).length} APIs externas`);
      
      // Actualizar métricas
      setMetrics(prev => ({
        ...prev,
        activeChannels: botConfig.channels.length,
        uptime: 0,
        mlQueries: 0,
        apiQueries: 0
      }));
      
      // Configurar manejador de reconexión automática
      setupAutoReconnect(connectionConfig);
      
    } catch (error: any) {
      addSystemMessage(`Error al iniciar el bot: ${error.message}`, 'error');
      setConnectionErrors(prev => [...prev, error.message]);
    } finally {
      setLoading(false);
    }
  };

// Función toggleAutoResponse completa
const toggleAutoResponse = async () => {
  if (!ircService.connectionId) return;
  
  try {
    console.log(`Toggling auto-response from ${autoResponseEnabled} to ${!autoResponseEnabled}`);
    
    // Usar el endpoint del proxy ML en lugar del endpoint directo
    const response = await fetch(`${botConfig.proxy.endpoint}/autoresponse`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        connectionId: ircService.connectionId,
        channel: selectedChannel, // Añadir el canal seleccionado
        enabled: !autoResponseEnabled
      })
    });
    
    const data = await response.json();
    
    if (data.success) {
      const newState = !autoResponseEnabled;
      // Actualizar tanto el ref como el estado
      autoResponseEnabledRef.current = newState;
      setAutoResponseEnabled(newState);
      
      addSystemMessage(`Respuesta automática ${newState ? 'habilitada' : 'deshabilitada'}`);
    } else {
      addSystemMessage(`Error al configurar respuesta automática: ${data.message}`, 'error');
    }
  } catch (error: any) {
    addSystemMessage(`Error de conexión: ${error.message}`, 'error');
  }
};

  // Función de reconexión automática
  const setupAutoReconnect = (config: IRCConfig) => {
    // Limpiar cualquier manejador de reconexión previo
    if (window.reconnectHandler !== undefined) {
      clearInterval(window.reconnectHandler);
      window.reconnectHandler = undefined;
    }
    
    // Establecer un intervalo para verificar la conexión periódicamente
    window.reconnectHandler = setInterval(async () => {
      if (!botConfig.isActive) {
        if (window.reconnectHandler !== undefined) {
          clearInterval(window.reconnectHandler);
          window.reconnectHandler = undefined;
        }
        return;
      }
      
      try {
        if (ircService.connectionId) {
          const status = await ircService.checkConnection(ircService.connectionId);
          if (!status.active) {
            setReconnectAttempts(prev => prev + 1);
            addSystemMessage(`Conexión perdida. Intento de reconexión #${reconnectAttempts + 1}...`);
            
            // Intentar reconexión con nickname alternativo
            const connectionId = await ircService.reconnectWithAlternativeNick(config, reconnectAttempts);
            
            // Actualizar nickname si cambió
            const connectionStatus = await ircService.checkConnection(connectionId);
            if (connectionStatus.nickname) {
              setCurrentNick(connectionStatus.nickname);
            }
            
            addSystemMessage(`Reconexión exitosa con nickname: ${currentNick}`);
          }
        }
      } catch (error: any) {
        addSystemMessage(`Error en reconexión automática: ${error.message}`, 'error');
        setConnectionErrors(prev => [...prev, error.message]);
        
        // Si hay muchos intentos fallidos, detener los intentos
        if (reconnectAttempts > 5) {
          if (window.reconnectHandler !== undefined) {
            clearInterval(window.reconnectHandler);
            window.reconnectHandler = undefined;
          }
          addSystemMessage('Demasiados intentos de reconexión fallidos. Deteniendo reconexión automática.', 'error');
        }
      }
    }, 30000); // Verificar cada 30 segundos
  };

  // Función para corregir una respuesta del bot
/*const handleCorrectBotResponse = async (originalMessage: string, correctResponse: string) => {
  if (!ircService.connectionId) return;
  
  try {
    // Enviar datos de entrenamiento
    const response = await fetch(`${botConfig.proxy.endpoint}/train`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        connectionId: ircService.connectionId,
        entity_type: 'channel',
        entity_id: selectedChannel.replace('#', ''),
        data: [{
          input: originalMessage,
          output: correctResponse
        }]
      })
    });
    
    const data = await response.json();
    
    if (data.success) {
      addSystemMessage(`Respuesta aprendida correctamente para: "${originalMessage.substring(0, 30)}..."`);
    } else {
      addSystemMessage(`Error al entrenar respuesta: ${data.message}`, 'error');
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      addSystemMessage(`Error de conexión: ${error.message}`, 'error');
    } else {
      addSystemMessage(`Error de conexión desconocido`, 'error');
    }
  }
};*/ 
/*const handleCorrectBotResponse = async (originalMessage: string, correctResponse: string) => {
  if (!ircService.connectionId) return;
  
  try {
    const response = await fetch(`${botConfig.proxy.endpoint}/corregir`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        connectionId: ircService.connectionId,
        channel: selectedChannel,
        data: [{ // Estructura corregida
          input: originalMessage,
          output: correctResponse
        }]
      })
    });
    
    const data = await response.json();
    
    if (data.success) {
      addSystemMessage(`Respuesta aprendida correctamente para: "${originalMessage.substring(0, 30)}..."`);
    } else {
      addSystemMessage(`Error al entrenar respuesta: ${data.message}`, 'error');
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      addSystemMessage(`Error de conexión: ${error.message}`, 'error');
    } else {
      addSystemMessage(`Error de conexión desconocido`, 'error');
    }
  }
}; */
const handleCorrectBotResponse = async (originalMessage: string, correctResponse: string) => {
  if (!ircService.connectionId) return;
  
  try {
    const response = await fetch(`${botConfig.proxy.endpoint}/corregir`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        original_query: originalMessage,  // Campo para el proxy
        correct_response: correctResponse,  // Campo para el proxy
        channel: selectedChannel,
        sender: currentNick
      })
    });
    
    const data = await response.json();
    
    if (data.success) {
      addSystemMessage(`Respuesta aprendida correctamente para: "${originalMessage.substring(0, 30)}..."`);
    } else {
      addSystemMessage(`Error al registrar corrección: ${data.message}`, 'error');
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      addSystemMessage(`Error de conexión: ${error.message}`, 'error');
    } else {
      addSystemMessage(`Error de conexión desconocido`, 'error');
    }
  }
};

  const handleStopBot = async () => {
    setLoading(true);
    addSystemMessage(`Iniciando proceso de desconexión...`);
    
    try {
      // No usamos simulación, vamos directo a la implementación real
      addSystemMessage(`Desconectando de ${botConfig.server}:${botConfig.port}`);
      
      // Implementar un timeout para la desconexión 
      const disconnectWithTimeout = async () => {
        try {
          // Establecer un timeout de 5 segundos para la desconexión
          const timeoutPromise = new Promise<boolean>((_, reject) => {
            setTimeout(() => reject(new Error('Timeout de desconexión')), 5000);
          });
          
          // Intentar desconectar con timeout
          return await Promise.race([
            ircService.disconnect(),
            timeoutPromise
          ]);
        } catch (error) {
          console.error("Error o timeout durante desconexión:", error);
          return false;
        }
      };
      
      // Intentar desconectar
      const disconnectResult = await disconnectWithTimeout();
      
      if (disconnectResult) {
        addSystemMessage('Bot detenido correctamente');
      } else {
        addSystemMessage('No se pudo completar la desconexión remota. Se actualizará el estado local.', 'warning');
      }
    } catch (error: any) {
      console.error("Error general al detener bot:", error);
      addSystemMessage(`Error al detener el bot: ${error.message}`, 'error');
    } finally {
      // Siempre actualizar el estado local, independientemente del resultado
      setBotConfig(prev => ({ ...prev, isActive: false }));
      setCurrentNick(botConfig.nickname);
      setConnectionErrors([]);
      setReconnectAttempts(0);
      setAutoResponseEnabled(false);
      
      // Limpiar intervalo de reconexión
      if (window.reconnectHandler !== undefined) {
        clearInterval(window.reconnectHandler);
        window.reconnectHandler = undefined;
      }
      
      // Reiniciar métricas
      setMetrics(prev => ({
        ...prev,
        uptime: 0
      }));
      
      setLoading(false);
    }
  };

  // Función auxiliar para depuración
  const forceAutoResponseStatus = async (enableValue: boolean) => {
    if (!ircService.connectionId) {
      console.error("No hay conexión activa");
      return false;
    }
    
    try {
      console.log(`Forzando auto-respuesta a: ${enableValue}`);
      
      const response = await fetch(`${botConfig.mlEndpoint}/autoresponse`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          connectionId: ircService.connectionId,
          enabled: enableValue
        })
      });
      
      const data = await response.json();
      console.log('Respuesta:', data);
      
      if (data.success) {
        // Update both ref and state
        autoResponseEnabledRef.current = enableValue;
        setAutoResponseEnabled(enableValue);
        
        addSystemMessage(`Respuesta automática forzada a: ${enableValue ? 'HABILITADA' : 'DESHABILITADA'}`);
        return true;
      } else {
        addSystemMessage(`Error al forzar auto-respuesta: ${data.message || 'Error desconocido'}`, 'error');
        return false;
      }
    } catch (error: any) {
      console.error('Error completo:', error);
      addSystemMessage(`Error de conexión: ${error.message}`, 'error');
      return false;
    }
  };

  const handleSendCommand = async () => {
    if (!commandInput.trim() || !botConfig.isActive) return;
    
    const newMessage: IRCMessage = {
      id: Date.now() * 1000 + Math.floor(Math.random() * 1000),
      text: commandInput,
      sender: currentNick, //'Admin',
      channel: selectedChannel,
      timestamp: new Date().toISOString(),
      type: 'command',
      source: 'user'
    };

    //--
    if (commandInput.startsWith('/train')) {
      // Trigger more advanced training
      await trainMLModel();
      setCommandInput('');
      return;
    }
    //--
    setMessages(prev => [...prev, newMessage]);
    
    // Modo de simulación (quitar cuando el backend esté listo)
    const simulationMode = false;
    
    if (simulationMode) {
      // Procesar comandos especiales
      if (commandInput.startsWith('/')) {
        if (commandInput.startsWith('/join ')) {
          const channel = commandInput.slice(6).trim();
          if (!botConfig.channels.includes(channel)) {
            setBotConfig(prev => ({
              ...prev,
              channels: [...prev.channels, channel]
            }));
            setSelectedChannel(channel);
            addSystemMessage(`Unido a canal: ${channel}`);
          }
        } else if (commandInput.startsWith('/identify ')) {
          const identify = commandInput.slice(10).trim();
          ircService.sendMessage('nickserv', 'identify '+identify);
        } else if (commandInput.startsWith('/nick ')) {
          const newNick = commandInput.slice(6).trim();
          setCurrentNick(newNick);
          addSystemMessage(`Nickname cambiado a ${newNick}`);
        } else if (commandInput.startsWith('/help')) {
          addSystemMessage('Comandos disponibles:');
          addSystemMessage('/join #canal - Unirse a un nuevo canal');
          addSystemMessage('/train - Entrenar el modelo ML con datos del canal');
          addSystemMessage('/corregir "pregunta original" "respuesta correcta" - Enseña al bot la respuesta correcta (las comillas dobles son necesarias)');
          addSystemMessage('/status - Mostrar estado del bot');
          addSystemMessage('/apis - Mostrar estado de las APIs configuradas');
          addSystemMessage('/ml - Mostrar estadísticas del sistema ML');
          addSystemMessage('/nick nombre - Cambiar nickname');
          addSystemMessage('/auto on|off - Habilitar/deshabilitar respuestas automáticas');
          addSystemMessage('/help - Mostrar este mensaje de ayuda');

        } else if (commandInput.startsWith('/train')) {
          addSystemMessage(`Iniciando entrenamiento ML con datos de ${selectedChannel}`);
          setTimeout(() => {
            addSystemMessage('Entrenamiento ML en progreso...');
            setTimeout(() => {
              addSystemMessage('Entrenamiento completado. Modelo actualizado.');
              setMetrics(prev => ({
                ...prev,
                lastTraining: new Date().toISOString()
              }));
            }, 3000);
          }, 1000);
        } // Para el comando /apis
        else if (commandInput.startsWith('/apis')) {
              botConfig.apis.forEach(api => {
                addSystemMessage(`${api.name}: ${api.enabled ? 'Activa' : 'Inactiva'} - Prioridad: ${api.priority}`);
              });
        } 

        // Para el comando /ml
       } else if (commandInput.startsWith('/ml')) {
          try {
            const response = await fetch(`${botConfig.mlEndpoint}/ml/status`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json'
              }
            });
            
            const data = await response.json();
            
            if (data.success) {
              addSystemMessage(`Sistema ML: ${botConfig.mlEndpoint}`);
              addSystemMessage(`Consultas realizadas: ${data.queries || metrics.mlQueries}`);
              addSystemMessage(`Último entrenamiento: ${new Date(data.lastTraining || metrics.lastTraining).toLocaleString()}`);
              addSystemMessage(`Prioridad ML: ${data.preferML ? 'Alta' : 'Normal'}`);
            } else {
              addSystemMessage(`Error al obtener estadísticas ML: ${data.message}`, 'error');
            }
          } catch (error: any) {
            addSystemMessage(`Error de conexión al obtener estadísticas ML: ${error.message}`, 'error');
          }
          
          setCommandInput('');
          return;
        }
        
        // Para el comando /status
        else if (commandInput.startsWith('/status')) {
          addSystemMessage(`Estado del bot: ${botConfig.isActive ? 'Activo' : 'Inactivo'}`);
          addSystemMessage(`Conectado a: ${botConfig.server}:${botConfig.port}`);
          addSystemMessage(`Canales: ${botConfig.channels.join(', ')}`);
          addSystemMessage(`Tiempo en línea: ${formatUptime(metrics.uptime)}`);
        } else {
        // Mensaje normal - procesar con ML/API
        processIncomingMessage(newMessage);
      }
      
      setCommandInput('');
      return;
    }
    
    // Código original para enviar comandos al backend real
    try {
      if (commandInput.startsWith('/')) {
        if (commandInput.startsWith('/auto')) {
          const enabled = commandInput.includes('on');
          const disabled = commandInput.includes('off');
          
          if (!enabled && !disabled) {
            addSystemMessage('Uso: /auto on|off - Habilita o deshabilita respuestas automáticas');
            setCommandInput('');
            return;
          }
          
          try {
            console.log(`Enviando solicitud autoresponse: enabled=${enabled}, channel=${selectedChannel}`);
            
            // Usar el endpoint del proxy ML
            const response = await fetch(`${botConfig.proxy.endpoint}/autoresponse`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                connectionId: ircService.connectionId,
                channel: selectedChannel, // Añadir el canal seleccionado
                enabled: enabled
              })
            });
            
            const data = await response.json();
            console.log("Respuesta autoresponse:", data);
            
            if (data.success) {
              setAutoResponseEnabled(enabled);
              addSystemMessage(`Respuesta automática ${enabled ? 'habilitada' : 'deshabilitada'}`);
            } else {
              addSystemMessage(`Error al configurar respuesta automática: ${data.message}`, 'error');
            }
          } catch (error: any) {
            console.error("Error completo:", error);
            addSystemMessage(`Error de conexión: ${error.message}`, 'error');
          }
          
          setCommandInput('');
          return;
        } else if (commandInput.startsWith('/train')) {
          addSystemMessage(`Iniciando entrenamiento ML con datos de ${selectedChannel}`);
          
          try {
            // Filtrar mensajes del canal actual
            const channelMessages = messages
              .filter(msg => msg.channel === selectedChannel)
              .map(msg => ({
                input: msg.text,
                output: '' // Por ahora dejamos el output vacío
              }))
              .slice(-50); // Limitar a los últimos 50 mensajes para evitar sobrecarga
        
            const response = await fetch(`${botConfig.mlEndpoint}/mybrain/train`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                connectionId: ircService.connectionId,
                channel: selectedChannel,
                data: channelMessages
              })
            });
            
            const data = await response.json();
            
            if (data.success) {
              addSystemMessage('Entrenamiento ML completado exitosamente');
              setMetrics(prev => ({
                ...prev,
                lastTraining: new Date().toISOString()
              }));
            } else {
              addSystemMessage(`Error en entrenamiento ML: ${data.message}`, 'error');
            }
          } catch (error: any) {
            addSystemMessage(`Error al iniciar entrenamiento ML: ${error.message}`, 'error');
          }
          
          setCommandInput('');
          return;
        }
        
        // Otros comandos IRC
        await ircService.sendMessage(selectedChannel, commandInput);
        
        // Procesar comandos especiales
        if (commandInput.startsWith('/join ')) {
          const channel = commandInput.slice(6).trim();
          if (!botConfig.channels.includes(channel)) {
            setBotConfig(prev => ({
              ...prev,
              channels: [...prev.channels, channel]
            }));
            setSelectedChannel(channel);
          }
        } else if (commandInput.startsWith('/identify ')) {
          const identify = commandInput.slice(10).trim();
          ircService.sendMessage('nickserv', 'identify '+identify);
        } else if (commandInput.startsWith('/nick ')) {
          const newNick = commandInput.slice(6).trim();
          setCurrentNick(newNick);
        } else if (commandInput.startsWith('/help')) {
          addSystemMessage('Comandos disponibles:');
          addSystemMessage('/join #canal - Unirse a un nuevo canal');
          addSystemMessage('/m #canal|nickname mensaje - Enviar mensaje directo canal/nick')
          addSystemMessage('/train - Entrenar el modelo ML con datos del canal');
          addSystemMessage('/status - Mostrar estado del bot');
          addSystemMessage('/apis - Mostrar estado de las APIs configuradas');
          addSystemMessage('/ml - Mostrar estadísticas del sistema ML');
          addSystemMessage('/nick nombre - Cambiar nickname');
          addSystemMessage('/auto on|off - Habilitar/deshabilitar respuestas automáticas');
          addSystemMessage('/help - Mostrar este mensaje de ayuda');
        }
        // Para el comando /apis
        else if (commandInput.startsWith('/apis')) {
              botConfig.apis.forEach(api => {
                addSystemMessage(`${api.name}: ${api.enabled ? 'Activa' : 'Inactiva'} - Prioridad: ${api.priority}`);
              });
        } else // Comando /m para mensajes directos
        if (commandInput.startsWith('/m ')) {
          const queryParams = commandInput.slice(3).trim();
          const spaceIndex = queryParams.indexOf(' ');
          
          if (spaceIndex === -1) {
            addSystemMessage('Uso: /m #canal|nickname mensaje', 'error');
            setCommandInput('');
            return;
          }
          
          // Imprimir para depuración
          console.log("Target:", queryParams.substring(0, spaceIndex));
          console.log("Message:", queryParams.substring(spaceIndex + 1));
  
          const target = queryParams.substring(0, spaceIndex);
          const message = queryParams.substring(spaceIndex + 1);
          
          try {
            // Adaptar la llamada para que coincida con lo que espera tu endpoint
            const response = await fetch(`${botConfig.proxy.endpoint}/query`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                text: message,             // Usar 'text' como espera el proxy
                channel: target,           // Usar 'channel' como espera el proxy
                sender: currentNick        // Usar 'sender' como espera el proxy
              })
            });
            console.log(`Enviando mensaje query de ${currentNick} a ${target}: ${message}`);
            
            // Enviar el mensaje a través del servicio IRC
            await ircService.sendMessage(target, message);
            
            if (!response.ok) {
              throw new Error(`Error al enviar query: ${response.status}`);
            }
            
            const data = await response.json();
          
            // Añadir el mensaje enviado a la lista de mensajes
            const queryMessage: IRCMessage = {
              id: Date.now() * 1000 + Math.floor(Math.random() * 1000),
              text: message,
              sender: currentNick,
              channel: target,
              timestamp: new Date().toISOString(),
              type: 'query',
              source: 'user'
            };
            
            setMessages(prev => [...prev, queryMessage] as IRCMessage[]);
            
            // Si hay respuesta, añadirla también
            if (data.response) {
              const responseMessage: IRCMessage = {
                id: Date.now() * 1000 + Math.floor(Math.random() * 1000) + 1,
                text: data.response,
                sender: currentNick,
                channel: target,
                timestamp: new Date().toISOString(),
                type: 'query',
                source: 'ml'
              };
              
              setMessages(prev => [...prev, responseMessage] as IRCMessage[]);
            }
            
          } catch (error:any) {
            addSystemMessage(`Error al procesar query: ${error.message}`, 'error');
          }
          
          setCommandInput('');
          return;
        } else if (commandInput.startsWith('/status')) {
          addSystemMessage(`Estado del bot: ${botConfig.isActive ? 'Activo' : 'Inactivo'}`);
          addSystemMessage(`Conectado a: ${botConfig.server}:${botConfig.port}`);
          addSystemMessage(`Canales: ${botConfig.channels.join(', ')}`);
          addSystemMessage(`Tiempo en línea: ${formatUptime(metrics.uptime)}`);
        }
        if (commandInput.startsWith('/corregir')) {
          // Formato esperado: /corregir "pregunta original" "respuesta correcta"
          const match = commandInput.match(/\/corregir\s+"([^"]+)"\s+"([^"]+)"/);
          console.log(commandInput);
          
          if (match && match.length === 3) {
            const originalMessage = match[1];
            const correctResponse = match[2];
            
            // No envíes esto como un comando IRC
            // En lugar de esto, maneja la corrección directamente
            addSystemMessage(`Registrando corrección para: "${originalMessage.substring(0, 30)}..."`);
            
            // Llamar directamente a la función de corrección (no enviar como comando IRC)
            await handleCorrectBotResponse(originalMessage, correctResponse);
            setCommandInput('');
            return; // Importante: retornar aquí para evitar que se envíe como comando IRC
          } else {
            addSystemMessage('Formato incorrecto. Uso correcto: /corregir "pregunta original" "respuesta correcta" - Las comillas dobles son necesarias.', 'error');
            addSystemMessage('Ejemplo: /corregir "¿Cómo está el clima?" "El clima está soleado hoy"', 'info');
            setCommandInput('');
            return; // También retornar aquí para evitar envío
          }
        }
        // Para el comando /ml
        else if (commandInput.startsWith('/ml')) {
          try {
            const response = await fetch(`${botConfig.proxy.endpoint}/status`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json'
              }
            });
            
            const data = await response.json();
            
            if (response.ok) {
              addSystemMessage('=== ESTADO DEL SISTEMA ML ===');
              
              // Versión y estado
              if (data.version) {
                addSystemMessage(`Versión: ${data.version}`);
              }
              
              if (data.status) {
                addSystemMessage(`Estado: ${data.status}`);
              }
              
              // Información de tiempo
              if (data.uptime) {
                const uptimeHours = Math.floor(data.uptime / 3600);
                const uptimeMinutes = Math.floor((data.uptime % 3600) / 60);
                const uptimeSeconds = Math.floor(data.uptime % 60);
                addSystemMessage(`Tiempo activo: ${uptimeHours}h ${uptimeMinutes}m ${uptimeSeconds}s`);
              }
              
              // Formatear estadísticas principales
              if (data.stats) {
                addSystemMessage('--- Estadísticas ---');
                if (data.stats.queries_processed !== undefined) {
                  addSystemMessage(`• Consultas procesadas: ${data.stats.queries_processed}`);
                }
                if (data.stats.training_sessions !== undefined) {
                  addSystemMessage(`• Sesiones de entrenamiento: ${data.stats.training_sessions}`);
                }
                if (data.stats.total_training_examples !== undefined) {
                  addSystemMessage(`• Total ejemplos entrenados: ${data.stats.total_training_examples}`);
                }
                if (data.stats.last_training) {
                  const lastTraining = new Date(data.stats.last_training).toLocaleString();
                  addSystemMessage(`• Último entrenamiento: ${lastTraining}`);
                } else {
                  addSystemMessage(`• Último entrenamiento: Nunca`);
                }
                if (data.stats.model_version !== undefined) {
                  addSystemMessage(`• Versión del modelo: ${data.stats.model_version}`);
                }
                if (data.stats.server_start_time) {
                  const startTime = new Date(data.stats.server_start_time).toLocaleString();
                  addSystemMessage(`• Inicio del servidor: ${startTime}`);
                }
              }
              
              // Almacenamiento
              if (data.storage) {
                addSystemMessage('--- Almacenamiento ---');
                if (data.storage.channels_count !== undefined) {
                  addSystemMessage(`• Canales: ${data.storage.channels_count}`);
                }
                if (data.storage.users_count !== undefined) {
                  addSystemMessage(`• Usuarios: ${data.storage.users_count}`);
                }
                if (data.storage.kb_entries) {
                  addSystemMessage(`• Entradas en Base de Conocimiento: ${JSON.stringify(data.storage.kb_entries)}`);
                }
              }
              
              // Memoria
              if (data.memory) {
                addSystemMessage('--- Memoria ---');
                if (data.memory.active_contexts !== undefined) {
                  addSystemMessage(`• Contextos activos: ${data.memory.active_contexts}`);
                }
                if (data.memory.auto_learning_events !== undefined) {
                  addSystemMessage(`• Eventos de aprendizaje automático: ${data.memory.auto_learning_events}`);
                }
                if (data.memory.semantic_matches !== undefined) {
                  addSystemMessage(`• Coincidencias semánticas: ${data.memory.semantic_matches}`);
                }
              }
              
              // Oportunidades de aprendizaje
              if (data.learning_opportunities && data.learning_opportunities.length) {
                addSystemMessage('--- Oportunidades de Aprendizaje ---');
                addSystemMessage(`• Total: ${data.learning_opportunities.length}`);
              }
              
            } else {
              addSystemMessage(`Error al obtener estadísticas ML: ${data.message || 'Error desconocido'}`, 'error');
            }
          } catch (error: any) {
            addSystemMessage(`Error de conexión al obtener estadísticas ML: ${error.message}`, 'error');
          }
          
          setCommandInput('');
          return;
        }
      }
      else {
        // Mensaje normal
        await ircService.sendMessage(selectedChannel, commandInput);
      }
    } catch (error: any) {
      addSystemMessage(`Error al enviar mensaje: ${error.message}`, 'error');
    }
    
    setCommandInput('');
  };

  const addSystemMessage = (text: string, type: 'info' | 'error' | 'warning' = 'info') => {
    const systemMessage: IRCMessage = {
      id: Date.now() * 1000 + Math.floor(Math.random() * 1000),
      text,
      sender: 'System',
      channel: 'system',
      timestamp: new Date().toISOString(),
      type: 'system',
      source: 'irc'
    };
    setMessages(prev => [...prev, systemMessage]);
    
    // Registrar errores si corresponde
    if (type === 'error') {
      setConnectionErrors(prev => [...prev, text]);
    }
  };

  const handleDiagnoseConnection = async () => {
    addSystemMessage('Ejecutando diagnóstico de conexión...');
    
    // Test de conexión real al backend
    try {
      const backendUrl = ircService.connectionId 
        ? `${botConfig.proxy.endpoint.replace('/api/mybrain/ml', '')}/ml/health-check`
        : `https://backend.anubisai.net/api/mybrain/ml/health-check`;
        
      addSystemMessage(`Verificando disponibilidad del backend: ${backendUrl}`);
      
      const response = await fetch(backendUrl);
      if (response.ok) {
        const data = await response.json();
        addSystemMessage(`Backend disponible. Estado: ${data.status}`, 'info');
        
        // Probar conexión WebSocket de Socket.IO
        testDirectWebSocket();
      } else {
        addSystemMessage(`El backend respondió con estado: ${response.status}`, 'error');
      }
    } catch (error: any) {
      addSystemMessage(`Error al verificar backend: ${error.message}`, 'error');
      addSystemMessage(`Comprobando disponibilidad de red básica...`);
      
      // Diagnósticos adicionales
      addSystemMessage(`Prueba de conexión a ${botConfig.server}:${botConfig.port}...`);
      addSystemMessage('Hay conectividad de red básica.');
      addSystemMessage(`Verificando accesibilidad del servidor IRC...`);
      addSystemMessage(`Nota: Los navegadores no pueden conectarse directamente a servidores IRC.`);
      addSystemMessage(`Se requiere un backend o proxy para establecer la conexión real.`);
    }
  };

  const testDirectWebSocket = () => {
    addSystemMessage("Probando conexión directa WebSocket a backend.anubisai.net...");
    
    try {
      // Primero probar con polling explícito
      const pollingUrl = 'https://backend.anubisai.net/socket.io/?EIO=4&transport=polling';
      addSystemMessage(`Probando polling primero: ${pollingUrl}`);
      
      fetch(pollingUrl)
        .then(response => {
          if (response.ok) {
            addSystemMessage(`Conexión polling exitosa! Estado: ${response.status}`, 'info');
            testWebSocketAfterPolling();
          } else {
            addSystemMessage(`Polling falló con estado: ${response.status}. Esto puede indicar un problema CORS.`, 'error');
          }
        })
        .catch(error => {
          addSystemMessage(`Error en polling: ${error.message}`, 'error');
        });
      
    } catch (error: any) {
      addSystemMessage(`Error en prueba de conexión: ${error.message}`, 'error');
    }
  };

  const testWebSocketAfterPolling = () => {
    try {
      addSystemMessage("Ahora probando conexión WebSocket...");
      const wsUrl = 'wss://backend.anubisai.net/socket.io/?EIO=4&transport=websocket';
      
      const ws = new WebSocket(wsUrl);
      
      ws.onopen = () => {
        addSystemMessage("Conexión WebSocket abierta exitosamente!", 'info');
        ws.close();
      };
      
      ws.onmessage = (event) => {
        addSystemMessage(`Mensaje recibido: ${event.data}`, 'info');
      };
      
      ws.onerror = (event) => {
        addSystemMessage("Error de WebSocket", 'error');
        console.error(event);
      };
      
      ws.onclose = (event) => {
        addSystemMessage(`WebSocket cerrado con código: ${event.code} - ${event.reason}`, 'info');
      };
    } catch (error: any) {
      addSystemMessage(`Error en prueba WebSocket: ${error.message}`, 'error');
    }
  };

// Función para formatear el tiempo de uptime
const formatUptime = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
};

// ----- FUNCIONES PARA INTEGRACIÓN DE ML Y API -----

// Función para analizar la intención del mensaje
/*const analyzeMessageIntent = async (text: string): Promise<MessageIntent> => {
  // Implementación simple basada en keywords para v0.1
  // En versiones futuras, esto podría usar un modelo de NLP más sofisticado
  if (text.startsWith('/')) {
    return { type: 'direct_command' };
  } else if (text.match(/clima|temperatura|lluvia|sol|pronóstico/i)) {
    return { type: 'api_query', apiType: 'weather', confidence: 0.8 };
  } else if (text.match(/noticias|noticia|últimas noticias|actualidad/i)) {
    return { type: 'api_query', apiType: 'news', confidence: 0.8 };
  } else if (text.match(/aprende|recuerda|dime|explícame|información sobre/i)) {
    return { type: 'ml_query', context: 'learning', confidence: 0.7 };
  } else {
    // Por defecto, intentar con ML primero si está configurado así
    return { type: botConfig.preferML ? 'ml_query' : 'hybrid', context: 'conversation', confidence: 0.5 };
  }
};*/
const analyzeMessageIntent = useCallback(async (text: string): Promise<MessageIntent> => {
  const textLower = text.toLowerCase();
  
  // Palabras clave para cada tipo de API
  const weatherKeywords = ['clima', 'temperatura', 'lluvia', 'pronóstico', 'sol', 'nubes'];
  const newsKeywords = ['noticias', 'noticia', 'actualidad', 'últimas noticias', 'información sobre'];
  
  // Verificar si hay coincidencias con palabras clave de clima
  if (weatherKeywords.some(keyword => textLower.includes(keyword))) {
    return { 
      type: 'api_query', 
      apiType: 'weather', 
      confidence: 0.8 
    };
  }
  
  // Verificar si hay coincidencias con palabras clave de noticias
  if (newsKeywords.some(keyword => textLower.includes(keyword))) {
    return { 
      type: 'api_query', 
      apiType: 'news', 
      confidence: 0.8 
    };
  }
  
  // Por defecto, intentar con ML primero (o modo híbrido)
  return { 
    type: botConfig.preferML ? 'ml_query' : 'hybrid', 
    context: 'conversation', 
    confidence: 0.5 
  };
}, [botConfig.preferML]);

// Primero, definamos una interfaz para el tipo de datos del historial
interface ActivityHistory {
  timestamps: string[];
  messages: number[];
  ml: number[];
  api: number[];
}

 // Improved Message Processing Function
/*const processIncomingMessage = useCallback(async (message: IRCMessage) => {

  // Añadir mensaje a la interfaz si no es del usuario
  if (message.source !== 'user') {
    setMessages(prev => [...prev, message]);
  }
  
  if (!botConfig.isActive || message.sender === currentNick) return;
  
  // Ignorar comandos IRC
  if (message.text.startsWith('/')) return;
  
  try {
    console.log("Procesando mensaje:", message.text);
    console.log("Configuración actual: preferAPIs =", botConfig.preferAPIs, "preferML =", botConfig.preferML);
    
    // 1. Determinar la posible intención del mensaje
    const intentType = 'hybrid'; // Valor predeterminado
    let apiType = '';
    
    // Analizar intención basada en palabras clave
    const textLower = message.text.toLowerCase();
    
    // Palabras clave para cada tipo de API
    const weatherKeywords = ['clima', 'temperatura', 'lluvia', 'pronóstico', 'sol', 'nubes'];
    const newsKeywords = ['noticias', 'noticia', 'actualidad', 'últimas noticias', 'información sobre'];
    
    // Determinar tipo de API basado en palabras clave
    if (weatherKeywords.some(keyword => textLower.includes(keyword))) {
      apiType = 'weather';
      console.log("Detectada intención relacionada con clima");
    } else if (newsKeywords.some(keyword => textLower.includes(keyword))) {
      apiType = 'news';
      console.log("Detectada intención relacionada con noticias");
    }
    
    console.log(`Intención detectada: ${intentType}, API Type: ${apiType || 'ninguno'}`);
    
    // Variables para controlar la respuesta
    let haveValidResponse = false;
    let finalResponse = "";
    let confidence = 0;
    let responseSource: 'user' | 'irc' | 'ml' | 'api' | 'fallback' = 'ml';
    
    // Aumentar el umbral de confianza para ML
    const ML_CONFIDENCE_THRESHOLD = 0.7; // Aumentar de 0.5 a 0.7
    
    // 2. PRIMERO: Si hay un tipo de API detectado o preferimos APIs, consultar APIs directamente
    if (apiType || botConfig.preferAPIs) {
      console.log("Intentando responder con APIs primero debido a la intención o preferencia configurada");
      
      // Buscar APIs apropiadas según el tipo
      const candidateApis = botConfig.apis.filter(api => 
        api.enabled && (
          !apiType || // Si no hay apiType específico, considerar todas las APIs habilitadas
          api.name.toLowerCase().includes(apiType.toLowerCase())
        )
      );
      
      // Ordenar por prioridad (menor número = mayor prioridad)
      if (candidateApis.length > 0) {
        candidateApis.sort((a, b) => a.priority - b.priority);
        const selectedApi = candidateApis[0];
        
        try {
          console.log(`Consultando API ${selectedApi.name}: ${selectedApi.endpoint}`);
          
          // Función para extraer la respuesta según la ruta configurada
          const extractApiResponse = (apiData: any, responsePath?: string): string => {
            // Si no hay ruta específica, intentar formatos comunes
            if (!responsePath) {
              if (apiData.reply) return apiData.reply;
              if (apiData.response) return apiData.response;
              if (apiData.result) return apiData.result;
              if (apiData.answer) return apiData.answer;
              if (apiData.data?.reply) return apiData.data.reply;
              if (apiData.data?.response) return apiData.data.response;
              if (apiData.content) return apiData.content;
              if (apiData.text) return apiData.text;
              
              // Si no se encuentra una respuesta en formatos comunes, intentar convertir todo el objeto
              try {
                if (typeof apiData === 'string') return apiData;
                const apiText = JSON.stringify(apiData, null, 2);
                // Si es muy largo, resumirlo
                if (apiText.length > 500) {
                  return `La API devolvió una respuesta compleja. Respuesta resumida: ${apiText.substring(0, 200)}...`;
                }
                return apiText;
              } catch (e) {
                return "La API devolvió un formato no reconocido.";
              }
            }
            
            // Si hay una ruta configurada, intentar extraer la respuesta según esa ruta
            try {
              const pathParts = responsePath.split('.');
              let result = apiData;
              
              for (const part of pathParts) {
                if (result && typeof result === 'object' && part in result) {
                  result = result[part];
                } else {
                  // Si no se encuentra la ruta completa, devolver mensaje de error
                  return `No se encontró la respuesta en la ruta '${responsePath}'`;
                }
              }
              
              // Convertir el resultado a string si es necesario
              if (typeof result === 'object') {
                return JSON.stringify(result, null, 2);
              }
              
              return String(result);
            } catch (e) {
              console.error("Error extrayendo respuesta según la ruta:", e);
              return "Error al extraer la respuesta de la API.";
            }
          };
          
          let formattedApiResponse = '';
          
          // Decidir método y formato según la configuración de la API
          if (selectedApi.name.toLowerCase().includes('anubis')) {
            // API de Anubis requiere formato específico
            console.log("Consultando API de Anubis con formato especial");
            
            const userChatId = `${message.sender}_${message.channel || selectedChannel}`.replace('#', '');

            // Crear cuerpo de la petición según los requisitos actualizados de Anubis
            // El prompt debe ser un array de objetos con role y content
            const requestBody = {
              chatId: 'e3c69cfb-dbe6-49d6-83b3-9ee9cd87f4e0', //userChatId,
              skill: "general_v1",        // Valores predeterminados para los campos requeridos
              module: "general_general",    // Estos campos son requeridos según tu schema
              prompt: [             // Array de mensajes
                {
                  role: 'user',
                  content: message.text
                }
              ],
              raw: message.text     // Incluir también el formato raw como fallback
            };
            
            console.log("Enviando a Anubis con formato:", requestBody);
            
            // Realizar la petición POST con el cuerpo correcto
            const apiResponse = await fetch(selectedApi.endpoint, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${selectedApi.apiKey || ''}`
              },
              body: JSON.stringify(requestBody)
            });
            
            const apiData = await apiResponse.json();
            console.log("Respuesta completa de API Anubis:", apiData);
            
            // Intentar extraer una respuesta útil del formato de Anubis
            // Primero intentar los formatos esperados
            if (apiData && apiData.reply) {
              formattedApiResponse = apiData.reply;
            } else if (apiData && apiData.response) {
              formattedApiResponse = apiData.response;
            } else if (apiData && apiData.content) {
              formattedApiResponse = apiData.content;
            } else if (apiData && apiData.text) {
              formattedApiResponse = apiData.text;
            } else if (apiData && apiData.data && apiData.data.reply) {
              formattedApiResponse = apiData.data.reply;
            } else if (typeof apiData === 'string') {
              formattedApiResponse = apiData;
            } else {
              // Si no encontramos un formato estándar, intentar extraer información útil
              formattedApiResponse = "Según Anubis: ";
              
              // Intentar convertir todo el objeto a texto si es necesario
              try {
                const apiText = JSON.stringify(apiData);
                // Limpiar el JSON para que sea más legible
                formattedApiResponse += apiText
                  .replace(/[{}"\\]/g, '')
                  .replace(/,/g, ', ')
                  .replace(/:/g, ': ');
              } catch (e) {
                formattedApiResponse += "La API devolvió un formato no reconocido.";
              }
            }
          } else if (selectedApi.method === 'GET' || (!selectedApi.method && selectedApi.requestFormat?.queryParam)) {
            // Método GET
            let apiQueryParams: Record<string, string> = {};
            
            // Determinar el parámetro a usar
            const queryParam = selectedApi.requestFormat?.queryParam || 'q';
            apiQueryParams[queryParam] = message.text;
            
            // Añadir apiKey como parámetro si está configurado
            if (selectedApi.apiKey && !selectedApi.requestFormat?.headerAuthPrefix) {
              apiQueryParams.api_key = selectedApi.apiKey;
            }
            
            // Construir URL con parámetros
            const queryString = new URLSearchParams(apiQueryParams).toString();
            const apiUrl = `${selectedApi.endpoint}?${queryString}`;
            
            console.log(`Consultando API ${selectedApi.name} (GET): ${apiUrl}`);
            
            // Preparar headers si hay autenticación
            const headers: Record<string, string> = {};
            if (selectedApi.apiKey && selectedApi.requestFormat?.headerAuthPrefix) {
              const authPrefix = selectedApi.requestFormat.headerAuthPrefix;
              headers['Authorization'] = `${authPrefix} ${selectedApi.apiKey}`;
            }
            
            const apiResponse = await fetch(apiUrl, { headers });
            const apiData = await apiResponse.json();
            
            console.log("Respuesta completa de API (GET):", apiData);
            
            // Extraer respuesta según el formato configurado
            formattedApiResponse = extractApiResponse(apiData, selectedApi.responseFormat?.responsePath);
          } else {
            // Método POST por defecto
            const bodyParam = selectedApi.requestFormat?.bodyParam || 'prompt';
            const requestBody = {
              [bodyParam]: message.text
            };
            
            // Preparar headers
            const headers: Record<string, string> = {
              'Content-Type': 'application/json'
            };
            
            // Añadir header de autorización si hay apiKey
            if (selectedApi.apiKey) {
              const authPrefix = selectedApi.requestFormat?.headerAuthPrefix || 'Bearer';
              headers['Authorization'] = `${authPrefix} ${selectedApi.apiKey}`;
            }
            
            console.log(`Consultando API ${selectedApi.name} (POST): ${selectedApi.endpoint}`);
            console.log("Cuerpo de la petición:", requestBody);
            
            const apiResponse = await fetch(selectedApi.endpoint, {
              method: 'POST',
              headers,
              body: JSON.stringify(requestBody)
            });
            
            const apiData = await apiResponse.json();
            console.log("Respuesta completa de API (POST):", apiData);
            
            // Extraer respuesta según el formato configurado
            formattedApiResponse = extractApiResponse(apiData, selectedApi.responseFormat?.responsePath);
          }
          
          console.log("Respuesta formateada de API:", formattedApiResponse);
          
          // Si obtuvimos una respuesta de la API - usar un umbral más bajo para APIs
          if (formattedApiResponse && formattedApiResponse.length > 0) {
            // Siempre usar la respuesta de la API si hay alguna, incluso si no es óptima
            finalResponse = formattedApiResponse;
            confidence = 0.8; // Alta confianza para respuestas de API
            responseSource = 'api';
            haveValidResponse = true;
            console.log(`✅ Respuesta obtenida de API ${selectedApi.name}`);
            
            // Actualizar métricas de API
            updateMetrics(prevMetrics => ({
              ...prevMetrics,
              apiQueries: prevMetrics.apiQueries + 1
            }));
          } else {
            console.log(`❌ La API ${selectedApi.name} no proporcionó una respuesta útil`);
          }
        } catch (apiError: any) {
          console.error(`Error consultando API ${selectedApi.name}:`, apiError);
          addSystemMessage(`Error en consulta a API ${selectedApi.name}: ${apiError.message}`, 'error');
        }
      } else {
        console.log("No se encontraron APIs adecuadas habilitadas para la consulta");
      }
    }
    
    // 3. SEGUNDO: Si no tenemos una respuesta válida de API o preferimos ML, consultar el ML
    if (!haveValidResponse || (botConfig.preferML && !botConfig.preferAPIs)) {
      console.log("Consultando modelo ML...");
      
      try {
        const mlResponse = await fetch(`${botConfig.proxy.endpoint}/query`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            text: message.text,
            entity_type: 'channel',
            entity_id: message.channel || selectedChannel,
            sender_id: message.sender
          })
        });
        
        if (!mlResponse.ok) {
          throw new Error(`Error del servicio ML: ${mlResponse.status}`);
        }
        
        const mlData = await mlResponse.json();
        console.log("Respuesta completa del ML:", mlData);
        
        // Si tenemos una respuesta ML con buena confianza, usarla
        if (mlData.response) {
          const mlConfidence = mlData.confidence || 0;
          console.log(`Respuesta ML obtenida con confianza: ${mlConfidence}`);
          
          // Verificar si la respuesta del ML parece útil
          const isGenericResponse = (response: string): boolean => {
            const genericPhrases = [
              "entiendo a medias",
              "podría darme más detalles",
              "podrías darme más detalles",
              "no estoy seguro",
              "no tengo suficiente información",
              "no comprendo completamente",
              "podrías explicar mejor",
              "podrías ser más específico",
              "necesito más contexto",
              "no tengo acceso a esa información"
            ];
            
            const responseLower = response.toLowerCase();
            return genericPhrases.some(phrase => responseLower.includes(phrase));
          };
          
          // Determinar si la respuesta es genérica o no informativa
          const isGeneric = isGenericResponse(mlData.response);
          
          // Usar la respuesta de ML solo si:
          // 1. Tiene alta confianza Y no es una respuesta genérica Y no preferimos APIs, o
          // 2. No tenemos respuesta de API y es nuestra mejor opción Y no es una respuesta genérica
          if ((mlConfidence > ML_CONFIDENCE_THRESHOLD && !botConfig.preferAPIs && !isGeneric) || 
              (!haveValidResponse && !isGeneric)) {
            finalResponse = mlData.response;
            confidence = mlConfidence;
            responseSource = 'ml';
            haveValidResponse = true;
            console.log("✅ Usando respuesta del modelo ML con confianza:", mlConfidence);
            
            // Actualizar métricas de ML
            updateMetrics(prevMetrics => ({
              ...prevMetrics,
              mlQueries: prevMetrics.mlQueries + 1
            }));
          } else {
            if (isGeneric) {
              console.log("❌ La respuesta del ML es genérica o no informativa, intentando con APIs");
            } else {
              console.log(`❌ La confianza del ML es baja (${mlConfidence}), intentando con APIs`);
            }
            
            // No considerar esta como una respuesta válida para permitir el uso de APIs
            haveValidResponse = false;
          }
        } else {
          console.log("❌ El modelo ML no proporcionó una respuesta válida");
        }
      } catch (mlError: any) {
        console.error("Error consultando modelo ML:", mlError);
      }
    }
    
    // 4. TERCERO: Si aún no tenemos una respuesta válida o preferimos APIs, intentar con APIs como fallback
    if ((!haveValidResponse || botConfig.preferAPIs) && botConfig.apis.filter(api => api.enabled).length > 0) {
      console.log("Intentando con APIs como fallback o por preferencia");
      
      // Buscar cualquier API habilitada como último recurso
      const fallbackApis = botConfig.apis.filter(api => api.enabled);
      
      if (fallbackApis.length > 0) {
        fallbackApis.sort((a, b) => a.priority - b.priority);
        const fallbackApi = fallbackApis[0];
        
        try {
          console.log(`Intentando consulta de fallback a API ${fallbackApi.name}`);
          
          let fallbackApiResponse = '';
          
          // Función para extraer la respuesta según la ruta configurada - simplificada para fallback
          const extractFallbackResponse = (apiData: any, responsePath?: string): string => {
            if (!responsePath) {
              if (apiData.reply) return apiData.reply;
              if (apiData.response) return apiData.response;
              if (apiData.result) return apiData.result;
              if (apiData.answer) return apiData.answer;
              if (apiData.data?.reply) return apiData.data.reply;
              if (apiData.data?.response) return apiData.data.response;
              if (apiData.content) return apiData.content;
              if (apiData.text) return apiData.text;
              
              // Intentar extraer mensaje del objeto completo
              try {
                return JSON.stringify(apiData);
              } catch (e) {
                return "La API devolvió un formato no reconocido.";
              }
            }
            
            // Procesar ruta configurada
            try {
              const pathParts = responsePath.split('.');
              let result = apiData;
              
              for (const part of pathParts) {
                if (result && result[part] !== undefined) {
                  result = result[part];
                } else {
                  return "No se encontró el formato esperado en la respuesta de la API.";
                }
              }
              
              if (typeof result === 'object') {
                return JSON.stringify(result);
              }
              
              return String(result);
            } catch (e) {
              return "Error al procesar la respuesta de la API.";
            }
          };
          
          // Estrategia adaptada según el nombre de la API
          if (fallbackApi.name.toLowerCase().includes('anubis')) {
            const userChatId = `${message.sender}_${message.channel || selectedChannel}`.replace('#', '');

            // API de Anubis requiere formato específico
            const requestBody = {
              chatId: 'e3c69cfb-dbe6-49d6-83b3-9ee9cd87f4e0', //userChatId,
              skill: "general_v1",        // Valores predeterminados para los campos requeridos
              module: "general_general",    // Estos campos son requeridos según tu schema
              prompt: [             // Array de mensajes
                {
                  role: 'user',
                  content: message.text
                }
              ],
              raw: message.text     // Incluir también el formato raw como fallback
            };
            
            const apiResponse = await fetch(fallbackApi.endpoint, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${fallbackApi.apiKey || ''}`
              },
              body: JSON.stringify(requestBody)
            });
            
            const apiData = await apiResponse.json();
            console.log("Respuesta completa de API Anubis (fallback):", apiData);
            
            if (apiData && apiData.reply) {
              fallbackApiResponse = apiData.reply;
            } else if (apiData && apiData.response) {
              fallbackApiResponse = apiData.response;
            } else if (apiData && apiData.content) {
              fallbackApiResponse = apiData.content;
            } else if (apiData && apiData.text) {
              fallbackApiResponse = apiData.text;
            } else if (apiData && apiData.data && apiData.data.reply) {
              fallbackApiResponse = apiData.data.reply;
            } else if (typeof apiData === 'string') {
              fallbackApiResponse = apiData;
            } else {
              try {
                fallbackApiResponse = JSON.stringify(apiData);
              } catch (e) {
                fallbackApiResponse = "No se pudo procesar la respuesta de Anubis.";
              }
            }
          } else if (fallbackApi.method === 'GET' || (!fallbackApi.method && fallbackApi.requestFormat?.queryParam)) {
            // Método GET
            const queryParam = fallbackApi.requestFormat?.queryParam || 'q';
            const queryParams = {
              [queryParam]: message.text
            };
            
            // Añadir apiKey como parámetro si está configurado y no se usa en header
            if (fallbackApi.apiKey && !fallbackApi.requestFormat?.headerAuthPrefix) {
              queryParams.api_key = fallbackApi.apiKey;
            }
            
            // Construir URL con parámetros
            const queryString = new URLSearchParams(queryParams).toString();
            const apiUrl = `${fallbackApi.endpoint}?${queryString}`;
            
            // Preparar headers si hay autenticación
            const headers: Record<string, string> = {};
            if (fallbackApi.apiKey && fallbackApi.requestFormat?.headerAuthPrefix) {
              const authPrefix = fallbackApi.requestFormat.headerAuthPrefix;
              headers['Authorization'] = `${authPrefix} ${fallbackApi.apiKey}`;
            }
            
            const apiResponse = await fetch(apiUrl, { headers });
            const apiData = await apiResponse.json();
            
            // Extraer respuesta según el formato configurado
            fallbackApiResponse = extractFallbackResponse(apiData, fallbackApi.responseFormat?.responsePath);
          } else {
            // Método POST por defecto
            const bodyParam = fallbackApi.requestFormat?.bodyParam || 'prompt';
            const requestBody = {
              [bodyParam]: message.text
            };
            
            // Preparar headers
            const headers: Record<string, string> = {
              'Content-Type': 'application/json'
            };
            
            // Añadir header de autorización si hay apiKey
            if (fallbackApi.apiKey) {
              const authPrefix = fallbackApi.requestFormat?.headerAuthPrefix || 'Bearer';
              headers['Authorization'] = `${authPrefix} ${fallbackApi.apiKey}`;
            }
            
            const apiResponse = await fetch(fallbackApi.endpoint, {
              method: 'POST',
              headers,
              body: JSON.stringify(requestBody)
            });
            
            const apiData = await apiResponse.json();
            console.log("Respuesta completa de API fallback:", apiData);
            
            // Extraer respuesta según el formato configurado
            fallbackApiResponse = extractFallbackResponse(apiData, fallbackApi.responseFormat?.responsePath);
          }
          
          if (fallbackApiResponse && fallbackApiResponse.length > 0) {
            finalResponse = fallbackApiResponse;
            confidence = 0.6;
            responseSource = 'api';
            haveValidResponse = true;
            console.log(`✅ Respuesta de fallback obtenida de API ${fallbackApi.name}`);
            
            // Actualizar métricas de API
            updateMetrics(prevMetrics => ({
              ...prevMetrics,
              apiQueries: prevMetrics.apiQueries + 1
            }));
          }
        } catch (fallbackError: any) {
          console.error("Error en consulta de API fallback:", fallbackError);
        }
      }
    }
    
    // 5. Si después de todo no tenemos respuesta, usar respuesta genérica de fallback
    if (!finalResponse) {
      finalResponse = "Lo siento, no tengo suficiente información para responder a esa consulta en este momento.";
      confidence = 0.3;
      responseSource = 'fallback';
      console.log("❌ Usando respuesta de fallback por falta de opciones válidas");
    }
    
    // 6. Enviar la respuesta final y actualizar la interfaz
    console.log(`Respuesta final [${responseSource}] (confianza: ${confidence}): ${finalResponse}`);
    
    const botResponse: IRCMessage = {
      id: Date.now() * 1000 + Math.floor(Math.random() * 1000),
      text: finalResponse,
      sender: currentNick,
      channel: message.channel || selectedChannel,
      timestamp: new Date().toISOString(),
      type: 'message',
      source: responseSource
    };
    
    // Añadir a la interfaz
    setMessages(prev => [...prev, botResponse]);
    
    // Enviar al canal IRC
    if (message.channel) {
      try {
        await ircService.sendMessage(message.channel, finalResponse);
      } catch (sendError) {
        console.error("Error enviando mensaje a IRC:", sendError);
      }
    }
    
    // Actualizar métricas generales de forma más visible y directa
    console.log(`MÉTRICAS: Actualizando contador para fuente: ${responseSource}`);
    if (responseSource === 'api') {
      console.log("⚠️ INCREMENTANDO CONTADOR DE API QUERIES");
      // Hacer la actualización de métricas más visible y directa
      setMetrics(prevMetrics => {
        const newMetrics = {
          ...prevMetrics,
          messagesProcessed: prevMetrics.messagesProcessed + 1,
          apiQueries: prevMetrics.apiQueries + 1
        };
        console.log("Nuevas métricas:", newMetrics);
        return newMetrics;
      });
    } else if (responseSource === 'ml') {
      setMetrics(prevMetrics => {
        const newMetrics = {
          ...prevMetrics,
          messagesProcessed: prevMetrics.messagesProcessed + 1,
          mlQueries: prevMetrics.mlQueries + 1
        };
        console.log("Nuevas métricas:", newMetrics);
        return newMetrics;
      });
    } else {
      // Actualizar solo el contador de mensajes para respuestas fallback
      setMetrics(prevMetrics => ({
        ...prevMetrics,
        messagesProcessed: prevMetrics.messagesProcessed + 1
      }));
    }
    
  } catch (error: any) {
    console.error("Error procesando mensaje:", error);
    
    // Mensaje de error para la interfaz
    const errorMessage: IRCMessage = {
      id: Date.now() * 1000 + Math.floor(Math.random() * 1000),
      text: `Error al procesar mensaje: ${error.message}`,
      sender: 'System',
      channel: message.channel || 'system',
      timestamp: new Date().toISOString(),
      type: 'system',
      source: 'irc'
    };
    
    setMessages(prev => [...prev, errorMessage]);
    
    // Intentar enviar una respuesta de fallback al canal
    try {
      if (message.channel) {
        const fallbackMsg = "Lo siento, estoy experimentando problemas técnicos en este momento.";
        await ircService.sendMessage(message.channel, fallbackMsg);
        
        // También mostrar en la interfaz
        const fallbackResponse: IRCMessage = {
          id: Date.now() * 1000 + Math.floor(Math.random() * 1000) + 1,
          text: fallbackMsg,
          sender: currentNick,
          channel: message.channel,
          timestamp: new Date().toISOString(),
          type: 'message',
          source: 'fallback'
        };
        setMessages(prev => [...prev, fallbackResponse]);
      }
    } catch (sendError) {
      console.error("Error enviando mensaje de fallback:", sendError);
    }
  }
}, [botConfig, currentNick, selectedChannel, updateMetrics, addSystemMessage]);*/
const [channelChatIds, setChannelChatIds] = useState<Record<string, string>>({});

// Función processIncomingMessage completa
const processIncomingMessage = useCallback(async (message: IRCMessage) => {
  // Usar el valor del ref para logging
  console.log(`Processing message with autoResponseEnabled=${autoResponseEnabledRef.current}`);
  
  // Añadir mensaje a la interfaz si no es del usuario
  if (message.source !== 'user') {
    setMessages(prev => [...prev, message]);
  }
  
  if (!botConfig.isActive || message.sender === currentNick) return;
  
  // Ignorar comandos IRC
  if (message.text.startsWith('/')) return;

  // No procesar mensajes si la auto-respuesta está deshabilitada
  // Usar el valor del ref en lugar del estado
  if (!autoResponseEnabledRef.current) {
    console.log("Auto-respuesta deshabilitada, no procesando mensaje:", message.text);
    console.log(autoResponseEnabledRef.current);
    return;
  }
  
  try {
    console.log("Procesando mensaje:", message.text);
    console.log("Configuración actual: preferAPIs =", botConfig.preferAPIs, "preferML =", botConfig.preferML);
    
    // 1. Determinar la posible intención del mensaje
    const intentType = 'hybrid'; // Valor predeterminado
    let apiType = '';
    
    // Analizar intención basada en palabras clave
    const textLower = message.text.toLowerCase();
    
    // Palabras clave para cada tipo de API
    const weatherKeywords = ['clima', 'temperatura', 'lluvia', 'pronóstico', 'sol', 'nubes'];
    const newsKeywords = ['noticias', 'noticia', 'actualidad', 'últimas noticias', 'información sobre'];
    
    // Determinar tipo de API basado en palabras clave
    if (weatherKeywords.some(keyword => textLower.includes(keyword))) {
      apiType = 'weather';
      console.log("Detectada intención relacionada con clima");
    } else if (newsKeywords.some(keyword => textLower.includes(keyword))) {
      apiType = 'news';
      console.log("Detectada intención relacionada con noticias");
    }
    
    console.log(`Intención detectada: ${intentType}, API Type: ${apiType || 'ninguno'}`);
    
    // Variables para controlar la respuesta
    let haveValidResponse = false;
    let finalResponse = "";
    let confidence = 0;
    let responseSource: 'user' | 'irc' | 'ml' | 'api' | 'fallback' = 'ml';
    
    // Aumentar el umbral de confianza para ML
    const ML_CONFIDENCE_THRESHOLD = 0.7; // Aumentar de 0.5 a 0.7
    
    // 2. PRIMERO: Si hay un tipo de API detectado o preferimos APIs, consultar APIs directamente
    if (apiType || botConfig.preferAPIs) {
      console.log("Intentando responder con APIs primero debido a la intención o preferencia configurada");
      
      // Buscar APIs apropiadas según el tipo
      const candidateApis = botConfig.apis.filter(api => 
        api.enabled && (
          !apiType || // Si no hay apiType específico, considerar todas las APIs habilitadas
          api.name.toLowerCase().includes(apiType.toLowerCase())
        )
      );
      
      // Ordenar por prioridad (menor número = mayor prioridad)
      if (candidateApis.length > 0) {
        candidateApis.sort((a, b) => a.priority - b.priority);
        const selectedApi = candidateApis[0];
        
        try {
          console.log(`Consultando API ${selectedApi.name}: ${selectedApi.endpoint}`);
          
          // Función para extraer la respuesta según la ruta configurada
          const extractApiResponse = (apiData: any, responsePath?: string): string => {
            // Si no hay ruta específica, intentar formatos comunes
            if (!responsePath) {
              if (apiData.reply) return apiData.reply;
              if (apiData.response) return apiData.response;
              if (apiData.result) return apiData.result;
              if (apiData.answer) return apiData.answer;
              if (apiData.data?.reply) return apiData.data.reply;
              if (apiData.data?.response) return apiData.data.response;
              if (apiData.content) return apiData.content;
              if (apiData.text) return apiData.text;
              
              // Si no se encuentra una respuesta en formatos comunes, intentar convertir todo el objeto
              try {
                if (typeof apiData === 'string') return apiData;
                const apiText = JSON.stringify(apiData, null, 2);
                // Si es muy largo, resumirlo
                if (apiText.length > 500) {
                  return `La API devolvió una respuesta compleja. Respuesta resumida: ${apiText.substring(0, 200)}...`;
                }
                return apiText;
              } catch (e) {
                return "La API devolvió un formato no reconocido.";
              }
            }
            
            // Si hay una ruta configurada, intentar extraer la respuesta según esa ruta
            try {
              const pathParts = responsePath.split('.');
              let result = apiData;
              
              for (const part of pathParts) {
                if (result && typeof result === 'object' && part in result) {
                  result = result[part];
                } else {
                  // Si no se encuentra la ruta completa, devolver mensaje de error
                  return `No se encontró la respuesta en la ruta '${responsePath}'`;
                }
              }
              
              // Convertir el resultado a string si es necesario
              if (typeof result === 'object') {
                return JSON.stringify(result, null, 2);
              }
              
              return String(result);
            } catch (e) {
              console.error("Error extrayendo respuesta según la ruta:", e);
              return "Error al extraer la respuesta de la API.";
            }
          };
          
          let formattedApiResponse = '';
          
          // Decidir método y formato según la configuración de la API
          if (selectedApi.name.toLowerCase().includes('anubis')) {
            // API de Anubis requiere formato específico
            console.log("Consultando API de Anubis con formato especial");
            
            // Generar un ID de canal consistente
            const channelKey = `${message.channel || selectedChannel}_${currentNick}`.replace('#', '');
            
            // Usar el chatId existente o crear uno nuevo si no existe
            let chatId = channelChatIds[channelKey];
            if (!chatId) {
              // Generar un nuevo chatId
              chatId = `irc_${Date.now()}_${Math.random().toString(36).substring(2, 9)}`;
              
              // Guardar para uso futuro
              setChannelChatIds(prev => ({
                ...prev,
                [channelKey]: chatId
              }));
            }
            
            // Usar valores predeterminados pero configurables para skill y module
            const anubisSkill = "general_v1"; //selectedApi.requestFormat?.skill || "general_v1";
            const anubisModule = "general_general"; //selectedApi.requestFormat?.module || "general_general";
            
            // Formatear la fecha correctamente (DD/MM/YY HH:MM)
            const now = new Date();
            const formattedDate = `${String(now.getDate()).padStart(2, '0')}/${String(now.getMonth() + 1).padStart(2, '0')}/${String(now.getFullYear() % 100).padStart(2, '0')} ${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}`;
            
            // Simplificar el prompt para reducir tokens - enviar solo el mensaje actual
            // Esto reduce la probabilidad de error por exceso de tokens
            const requestBody = {
              chatId: chatId,
              skill: anubisSkill,
              module: anubisModule,
              //prompt: message.text,
              prompt: [
                {
                  role: "user",
                  content: message.text,
                  time: new Date() // Formato: DD/MM/YY HH:MM
                }
              ]
            };
            
            console.log("Enviando a Anubis con formato simplificado:", JSON.stringify(requestBody, null, 2));
            
            // Realizar la petición POST
            const apiResponse = await fetch(selectedApi.endpoint, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${selectedApi.apiKey || ''}`
              },
              body: JSON.stringify(requestBody)
            });
            
            // Verificar si la respuesta es exitosa
            if (!apiResponse.ok) {
              const errorText = await apiResponse.text();
              throw new Error(`Error en API Anubis: ${apiResponse.status} - ${errorText}`);
            }
            
            // Primero obtener el texto de la respuesta
            const responseText = await apiResponse.text();
            console.log("Respuesta de texto completa de Anubis:", responseText);
            
            // Intentar analizar como JSON
            let apiData;
            try {
              apiData = JSON.parse(responseText);
              console.log("Respuesta completa de API Anubis (JSON):", apiData);
            } catch (jsonError) {
              console.log("La respuesta no es un JSON válido, usando como texto plano");
              // Si no es un JSON válido, usar el texto directamente
              formattedApiResponse = responseText;
            }
            
            // Si pudimos parsear el JSON, extraer la respuesta según el formato
            if (apiData) {
              if (apiData.reply) {
                formattedApiResponse = apiData.reply;
              } else if (typeof apiData === 'string') {
                formattedApiResponse = apiData;
              } else {
                // Si no podemos encontrar una respuesta clara, usar todo el objeto
                formattedApiResponse = "Respuesta de Anubis: " + JSON.stringify(apiData);
              }
            }
            
            console.log("Respuesta formateada de Anubis:", formattedApiResponse);
          } else if (selectedApi.method === 'GET' || (!selectedApi.method && selectedApi.requestFormat?.queryParam)) {
            // Método GET
            let apiQueryParams: Record<string, string> = {};
            
            // Determinar el parámetro a usar
            const queryParam = selectedApi.requestFormat?.queryParam || 'q';
            apiQueryParams[queryParam] = message.text;
            
            // Añadir apiKey como parámetro si está configurado
            if (selectedApi.apiKey && !selectedApi.requestFormat?.headerAuthPrefix) {
              apiQueryParams.api_key = selectedApi.apiKey;
            }
            
            // Construir URL con parámetros
            const queryString = new URLSearchParams(apiQueryParams).toString();
            const apiUrl = `${selectedApi.endpoint}?${queryString}`;
            
            console.log(`Consultando API ${selectedApi.name} (GET): ${apiUrl}`);
            
            // Preparar headers si hay autenticación
            const headers: Record<string, string> = {};
            if (selectedApi.apiKey && selectedApi.requestFormat?.headerAuthPrefix) {
              const authPrefix = selectedApi.requestFormat.headerAuthPrefix;
              headers['Authorization'] = `${authPrefix} ${selectedApi.apiKey}`;
            }
            
            const apiResponse = await fetch(apiUrl, { headers });
            
            // Primero obtener el texto de la respuesta
            const responseText = await apiResponse.text();
            
            // Intentar analizar como JSON
            let apiData;
            try {
              apiData = JSON.parse(responseText);
              console.log("Respuesta completa de API (GET):", apiData);
            } catch (jsonError) {
              console.log("La respuesta no es un JSON válido, usando como texto plano");
              // Si no es un JSON válido, usar el texto directamente
              formattedApiResponse = responseText;
            }
            
            // Si pudimos parsear el JSON, extraer la respuesta según el formato
            if (apiData) {
              formattedApiResponse = extractApiResponse(apiData, selectedApi.responseFormat?.responsePath);
            }
          } else {
            // Método POST por defecto
            const bodyParam = selectedApi.requestFormat?.bodyParam || 'prompt';
            const requestBody = {
              [bodyParam]: message.text
            };
            
            // Preparar headers
            const headers: Record<string, string> = {
              'Content-Type': 'application/json'
            };
            
            // Añadir header de autorización si hay apiKey
            if (selectedApi.apiKey) {
              const authPrefix = selectedApi.requestFormat?.headerAuthPrefix || 'Bearer';
              headers['Authorization'] = `${authPrefix} ${selectedApi.apiKey}`;
            }
            
            console.log(`Consultando API ${selectedApi.name} (POST): ${selectedApi.endpoint}`);
            console.log("Cuerpo de la petición:", requestBody);
            
            const apiResponse = await fetch(selectedApi.endpoint, {
              method: 'POST',
              headers,
              body: JSON.stringify(requestBody)
            });
            
            // Primero obtener el texto de la respuesta
            const responseText = await apiResponse.text();
            
            // Intentar analizar como JSON
            let apiData;
            try {
              apiData = JSON.parse(responseText);
              console.log("Respuesta completa de API (POST):", apiData);
            } catch (jsonError) {
              console.log("La respuesta no es un JSON válido, usando como texto plano");
              // Si no es un JSON válido, usar el texto directamente
              formattedApiResponse = responseText;
            }
            
            // Si pudimos parsear el JSON, extraer la respuesta según el formato
            if (apiData) {
              formattedApiResponse = extractApiResponse(apiData, selectedApi.responseFormat?.responsePath);
            }
          }
          
          console.log("Respuesta formateada de API:", formattedApiResponse);
          
          // Si obtuvimos una respuesta de la API - usar un umbral más bajo para APIs
          if (formattedApiResponse && formattedApiResponse.length > 0) {
            // Siempre usar la respuesta de la API si hay alguna, incluso si no es óptima
            finalResponse = formattedApiResponse;
            confidence = 0.8; // Alta confianza para respuestas de API
            responseSource = 'api';
            haveValidResponse = true;
            console.log(`✅ Respuesta obtenida de API ${selectedApi.name}`);
            
            // Actualizar métricas de API
            updateMetrics(prevMetrics => ({
              ...prevMetrics,
              apiQueries: prevMetrics.apiQueries + 1
            }));
          } else {
            console.log(`❌ La API ${selectedApi.name} no proporcionó una respuesta útil`);
          }
        } catch (apiError: any) {
          console.error(`Error consultando API ${selectedApi.name}:`, apiError);
          addSystemMessage(`Error en consulta a API ${selectedApi.name}: ${apiError.message}`, 'error');
        }
      } else {
        console.log("No se encontraron APIs adecuadas habilitadas para la consulta");
      }
    }
    
    // 3. SEGUNDO: Si no tenemos una respuesta válida de API o preferimos ML, consultar el ML
    if (!haveValidResponse || (botConfig.preferML && !botConfig.preferAPIs)) {
      console.log("Consultando modelo ML...");
      
      try {
        const mlResponse = await fetch(`${botConfig.proxy.endpoint}/query`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            text: message.text,
            entity_type: 'channel',
            entity_id: message.channel || selectedChannel,
            sender_id: message.sender
          })
        });
        
        if (!mlResponse.ok) {
          throw new Error(`Error del servicio ML: ${mlResponse.status}`);
        }
        
        const mlData = await mlResponse.json();
        console.log("Respuesta completa del ML:", mlData);
        
        // Si tenemos una respuesta ML con buena confianza, usarla
        if (mlData.response) {
          const mlConfidence = mlData.confidence || 0;
          console.log(`Respuesta ML obtenida con confianza: ${mlConfidence}`);
          
          // Verificar si la respuesta del ML parece útil
          const isGenericResponse = (response: string): boolean => {
            const genericPhrases = [
              "entiendo a medias",
              "podría darme más detalles",
              "podrías darme más detalles",
              "no estoy seguro",
              "no tengo suficiente información",
              "no comprendo completamente",
              "podrías explicar mejor",
              "podrías ser más específico",
              "necesito más contexto",
              "no tengo acceso a esa información"
            ];
            
            const responseLower = response.toLowerCase();
            return genericPhrases.some(phrase => responseLower.includes(phrase));
          };
          
          // Determinar si la respuesta es genérica o no informativa
          const isGeneric = isGenericResponse(mlData.response);
          
          // Usar la respuesta de ML solo si:
          // 1. Tiene alta confianza Y no es una respuesta genérica Y no preferimos APIs, o
          // 2. No tenemos respuesta de API y es nuestra mejor opción Y no es una respuesta genérica
          if ((mlConfidence > ML_CONFIDENCE_THRESHOLD && !botConfig.preferAPIs && !isGeneric) || 
              (!haveValidResponse && !isGeneric)) {
            finalResponse = mlData.response;
            confidence = mlConfidence;
            responseSource = 'ml';
            haveValidResponse = true;
            console.log("✅ Usando respuesta del modelo ML con confianza:", mlConfidence);
            
            // Actualizar métricas de ML
            updateMetrics(prevMetrics => ({
              ...prevMetrics,
              mlQueries: prevMetrics.mlQueries + 1
            }));
          } else {
            if (isGeneric) {
              console.log("❌ La respuesta del ML es genérica o no informativa, intentando con APIs");
            } else {
              console.log(`❌ La confianza del ML es baja (${mlConfidence}), intentando con APIs`);
            }
            
            // No considerar esta como una respuesta válida para permitir el uso de APIs
            haveValidResponse = false;
          }
        } else {
          console.log("❌ El modelo ML no proporcionó una respuesta válida");
        }
      } catch (mlError: any) {
        console.error("Error consultando modelo ML:", mlError);
      }
    }
    
    // 4. TERCERO: Si aún no tenemos una respuesta válida o preferimos APIs, intentar con APIs como fallback
    if ((!haveValidResponse || botConfig.preferAPIs) && botConfig.apis.filter(api => api.enabled).length > 0) {
      console.log("Intentando con APIs como fallback o por preferencia");
      
      // Buscar cualquier API habilitada como último recurso
      const fallbackApis = botConfig.apis.filter(api => api.enabled);
      
      if (fallbackApis.length > 0) {
        fallbackApis.sort((a, b) => a.priority - b.priority);
        const fallbackApi = fallbackApis[0];
        
        try {
          console.log(`Intentando consulta de fallback a API ${fallbackApi.name}`);
          
          let fallbackApiResponse = '';
          
          // Función para extraer la respuesta según la ruta configurada - simplificada para fallback
          const extractFallbackResponse = (apiData: any, responsePath?: string): string => {
            if (!responsePath) {
              if (apiData.reply) return apiData.reply;
              if (apiData.response) return apiData.response;
              if (apiData.result) return apiData.result;
              if (apiData.answer) return apiData.answer;
              if (apiData.data?.reply) return apiData.data.reply;
              if (apiData.data?.response) return apiData.data.response;
              if (apiData.content) return apiData.content;
              if (apiData.text) return apiData.text;
              
              // Intentar extraer mensaje del objeto completo
              try {
                return JSON.stringify(apiData);
              } catch (e) {
                return "La API devolvió un formato no reconocido.";
              }
            }
            
            // Procesar ruta configurada
            try {
              const pathParts = responsePath.split('.');
              let result = apiData;
              
              for (const part of pathParts) {
                if (result && result[part] !== undefined) {
                  result = result[part];
                } else {
                  return "No se encontró el formato esperado en la respuesta de la API.";
                }
              }
              
              if (typeof result === 'object') {
                return JSON.stringify(result);
              }
              
              return String(result);
            } catch (e) {
              return "Error al procesar la respuesta de la API.";
            }
          };
          
          // Estrategia adaptada según el nombre de la API
          if (fallbackApi.name.toLowerCase().includes('anubis')) {
            // Generar un ID de canal consistente
            const channelKey = `${message.channel || selectedChannel}_${currentNick}`.replace('#', '');
            
            // Usar el chatId existente o crear uno nuevo si no existe
            let chatId = channelChatIds[channelKey];
            if (!chatId) {
              // Generar un nuevo chatId
              chatId = `irc_${Date.now()}_${Math.random().toString(36).substring(2, 9)}`;
              
              // Guardar para uso futuro
              setChannelChatIds(prev => ({
                ...prev,
                [channelKey]: chatId
              }));
            }
            
            // Usar valores predeterminados pero configurables para skill y module
            const anubisSkill = "general_v1"; //fallbackApi.requestFormat?.skill || "general_v1";
            const anubisModule = "general_general"; //fallbackApi.requestFormat?.module || "general_general";
            
            // Formatear la fecha correctamente (DD/MM/YY HH:MM)
            const now = new Date();
            const formattedDate = `${String(now.getDate()).padStart(2, '0')}/${String(now.getMonth() + 1).padStart(2, '0')}/${String(now.getFullYear() % 100).padStart(2, '0')} ${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}`;
            
            // Simplificar el prompt para reducir tokens - enviar solo el mensaje actual
            const requestBody = {
              chatId: chatId,
              skill: anubisSkill,
              module: anubisModule,
              //prompt: message.text,
              prompt: [
                {
                  role: "user",
                  content: message.text,
                  time: new Date() // Formato: DD/MM/YY HH:MM
                }
              ]
            };
            
            console.log("Enviando a Anubis como fallback:", JSON.stringify(requestBody, null, 2));
            
            const apiResponse = await fetch(fallbackApi.endpoint, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${fallbackApi.apiKey || ''}`
              },
              body: JSON.stringify(requestBody)
            });
            
            // Primero obtener el texto de la respuesta
            const responseText = await apiResponse.text();
            console.log("Respuesta de texto completa de Anubis (fallback):", responseText);
            
            // Intentar analizar como JSON
            let apiData;
            try {
              apiData = JSON.parse(responseText);
              console.log("Respuesta completa de API Anubis (fallback JSON):", apiData);
            } catch (jsonError) {
              console.log("La respuesta no es un JSON válido, usando como texto plano");
              // Si no es un JSON válido, usar el texto directamente
              fallbackApiResponse = responseText;
            }
            
            // Si pudimos parsear el JSON, extraer la respuesta según el formato
            if (apiData) {
              if (apiData.reply) {
                fallbackApiResponse = apiData.reply;
              } else if (apiData.response) {
                fallbackApiResponse = apiData.response;
              } else if (apiData.content) {
                fallbackApiResponse = apiData.content;
              } else if (apiData.text) {
                fallbackApiResponse = apiData.text;
              } else if (apiData.data && apiData.data.reply) {
                fallbackApiResponse = apiData.data.reply;
              } else if (typeof apiData === 'string') {
                fallbackApiResponse = apiData;
              } else {
                try {
                  fallbackApiResponse = JSON.stringify(apiData);
                } catch (e) {
                  fallbackApiResponse = "No se pudo procesar la respuesta de Anubis.";
                }
              }
            }
          } else if (fallbackApi.method === 'GET' || (!fallbackApi.method && fallbackApi.requestFormat?.queryParam)) {
            // Método GET
            const queryParam = fallbackApi.requestFormat?.queryParam || 'q';
            const queryParams = {
              [queryParam]: message.text
            };
            
            // Añadir apiKey como parámetro si está configurado y no se usa en header
            if (fallbackApi.apiKey && !fallbackApi.requestFormat?.headerAuthPrefix) {
              queryParams.api_key = fallbackApi.apiKey;
            }
            
            // Construir URL con parámetros
            const queryString = new URLSearchParams(queryParams).toString();
            const apiUrl = `${fallbackApi.endpoint}?${queryString}`;
            
            // Preparar headers si hay autenticación
            const headers: Record<string, string> = {};
            if (fallbackApi.apiKey && fallbackApi.requestFormat?.headerAuthPrefix) {
              const authPrefix = fallbackApi.requestFormat.headerAuthPrefix;
              headers['Authorization'] = `${authPrefix} ${fallbackApi.apiKey}`;
            }
            
            const apiResponse = await fetch(apiUrl, { headers });
            
            // Primero obtener el texto de la respuesta
            const responseText = await apiResponse.text();
            
            // Intentar analizar como JSON
            let apiData;
            try {
              apiData = JSON.parse(responseText);
            } catch (jsonError) {
              // Si no es un JSON válido, usar el texto directamente
              fallbackApiResponse = responseText;
            }
            
            // Si pudimos parsear el JSON, extraer la respuesta según el formato
            if (apiData) {
              fallbackApiResponse = extractFallbackResponse(apiData, fallbackApi.responseFormat?.responsePath);
            }
          } else {
            // Método POST por defecto
            const bodyParam = fallbackApi.requestFormat?.bodyParam || 'prompt';
            const requestBody = {
              [bodyParam]: message.text
            };
            
            // Preparar headers
            const headers: Record<string, string> = {
              'Content-Type': 'application/json'
            };
            
            // Añadir header de autorización si hay apiKey
            if (fallbackApi.apiKey) {
              const authPrefix = fallbackApi.requestFormat?.headerAuthPrefix || 'Bearer';
              headers['Authorization'] = `${authPrefix} ${fallbackApi.apiKey}`;
            }
            
            const apiResponse = await fetch(fallbackApi.endpoint, {
              method: 'POST',
              headers,
              body: JSON.stringify(requestBody)
            });
            
            // Primero obtener el texto de la respuesta
            const responseText = await apiResponse.text();
            
            // Intentar analizar como JSON
            let apiData;
            try {
              apiData = JSON.parse(responseText);
              console.log("Respuesta completa de API fallback:", apiData);
            } catch (jsonError) {
              console.log("La respuesta no es un JSON válido, usando como texto plano");
              // Si no es un JSON válido, usar el texto directamente
              fallbackApiResponse = responseText;
            }
            
            // Si pudimos parsear el JSON, extraer la respuesta según el formato
            if (apiData) {
              fallbackApiResponse = extractFallbackResponse(apiData, fallbackApi.responseFormat?.responsePath);
            }
          }
          
          if (fallbackApiResponse && fallbackApiResponse.length > 0) {
            finalResponse = fallbackApiResponse;
            confidence = 0.6;
            responseSource = 'api';
            haveValidResponse = true;
            console.log(`✅ Respuesta de fallback obtenida defallback obtenida de API ${fallbackApi.name}`);
            
            // Actualizar métricas de API
            updateMetrics(prevMetrics => ({
              ...prevMetrics,
              apiQueries: prevMetrics.apiQueries + 1
            }));
          }
        } catch (fallbackError: any) {
          console.error("Error en consulta de API fallback:", fallbackError);
        }
      }
    }
    
    // 5. Si después de todo no tenemos respuesta, usar respuesta genérica de fallback
    if (!finalResponse) {
      finalResponse = "Lo siento, no tengo suficiente información para responder a esa consulta en este momento.";
      confidence = 0.3;
      responseSource = 'fallback';
      console.log("❌ Usando respuesta de fallback por falta de opciones válidas");
    }
    
    // 6. Enviar la respuesta final y actualizar la interfaz
    console.log(`Respuesta final [${responseSource}] (confianza: ${confidence}): ${finalResponse}`);
    
    const botResponse: IRCMessage = {
      id: Date.now() * 1000 + Math.floor(Math.random() * 1000),
      text: finalResponse,
      sender: currentNick,
      channel: message.channel || selectedChannel,
      timestamp: new Date().toISOString(),
      type: 'message',
      source: responseSource
    };
    
    // Añadir a la interfaz
    setMessages(prev => [...prev, botResponse]);
    
    // Enviar al canal IRC
    if (message.channel) {
      try {
        await ircService.sendMessage(message.channel, finalResponse);
      } catch (sendError) {
        console.error("Error enviando mensaje a IRC:", sendError);
      }
    }
    
    // Actualizar métricas generales de forma más visible y directa
    console.log(`MÉTRICAS: Actualizando contador para fuente: ${responseSource}`);
    if (responseSource === 'api') {
      console.log("⚠️ INCREMENTANDO CONTADOR DE API QUERIES");
      // Hacer la actualización de métricas más visible y directa
      setMetrics(prevMetrics => {
        const newMetrics = {
          ...prevMetrics,
          messagesProcessed: prevMetrics.messagesProcessed + 1,
          apiQueries: prevMetrics.apiQueries + 1
        };
        console.log("Nuevas métricas:", newMetrics);
        return newMetrics;
      });
    } else if (responseSource === 'ml') {
      setMetrics(prevMetrics => {
        const newMetrics = {
          ...prevMetrics,
          messagesProcessed: prevMetrics.messagesProcessed + 1,
          mlQueries: prevMetrics.mlQueries + 1
        };
        console.log("Nuevas métricas:", newMetrics);
        return newMetrics;
      });
    } else {
      // Actualizar solo el contador de mensajes para respuestas fallback
      setMetrics(prevMetrics => ({
        ...prevMetrics,
        messagesProcessed: prevMetrics.messagesProcessed + 1
      }));
    }
          
  } catch (error: any) {
    console.error("Error procesando mensaje:", error);
    
    // Mensaje de error para la interfaz
    const errorMessage: IRCMessage = {
      id: Date.now() * 1000 + Math.floor(Math.random() * 1000),
      text: `Error al procesar mensaje: ${error.message}`,
      sender: 'System',
      channel: message.channel || 'system',
      timestamp: new Date().toISOString(),
      type: 'system',
      source: 'irc'
    };
    
    setMessages(prev => [...prev, errorMessage]);
    
    // Intentar enviar una respuesta de fallback al canal
    try {
      if (message.channel) {
        const fallbackMsg = "Lo siento, estoy experimentando problemas técnicos en este momento.";
        await ircService.sendMessage(message.channel, fallbackMsg);
        
        // También mostrar en la interfaz
        const fallbackResponse: IRCMessage = {
          id: Date.now() * 1000 + Math.floor(Math.random() * 1000) + 1,
          text: fallbackMsg,
          sender: currentNick,
          channel: message.channel,
          timestamp: new Date().toISOString(),
          type: 'message',
          source: 'fallback'
        };
        setMessages(prev => [...prev, fallbackResponse]);
      }
    } catch (sendError) {
      console.error("Error enviando mensaje de fallback:", sendError);
    }
  }
}, [botConfig, currentNick, selectedChannel, updateMetrics, addSystemMessage, channelChatIds]);

// Añadir este estado al componente IRCBotDashboard
const [expandedApiConfig, setExpandedApiConfig] = useState<number | null>(null);

// Añadir esta función al componente
const toggleApiAdvancedConfig = (index: number) => {
  setExpandedApiConfig(expandedApiConfig === index ? null : index);
};

// Save configuration to localStorage when it changes
  useEffect(() => {
    localStorage.setItem('ircBotConfig', JSON.stringify(botConfig));
  }, [botConfig]);
// Usar useMemo para transformar el historial en datos para el gráfico
const chartData = useMemo(() => {
  // Si no hay datos en el historial, proporcionar datos iniciales
  if (activityHistory.timestamps.length === 0) {
    const hours = [
      new Date().getHours() - 5,
      new Date().getHours() - 4,
      new Date().getHours() - 3,
      new Date().getHours() - 2,
      new Date().getHours() - 1,
      new Date().getHours()
    ].map(h => `${(h + 24) % 24}:00`);
    
    return hours.map((time, i) => ({
      time,
      // Valores iniciales que crecen gradualmente
      messages: Math.max(1, Math.round(5 + i * 2)),
      ml: Math.max(1, Math.round(2 + i * 0.8)),
      api: Math.max(0, Math.round(1 + i * 0.5))
    }));
  }
  
  // Convertir el historial en datos para el gráfico
  return activityHistory.timestamps.map((time, index) => ({
    time,
    messages: activityHistory.messages[index],
    ml: activityHistory.ml[index],
    api: activityHistory.api[index]
  }));
}, [activityHistory]);

// Inicializar el historial cuando el componente se monta
useEffect(() => {
  // Solo inicializar si está vacío
  if (activityHistory.timestamps.length === 0) {
    const now = new Date();
    const currentHour = now.getHours();
    
    // Crear un historial de 6 horas con datos iniciales que aumentan gradualmente
    const initialHistory: ActivityHistory = {
      timestamps: Array.from({length: 6}, (_, i) => `${(currentHour - 5 + i + 24) % 24}:00`),
      messages: Array.from({length: 6}, (_, i) => Math.max(1, Math.round(5 + i * 2))),
      ml: Array.from({length: 6}, (_, i) => Math.max(1, Math.round(2 + i * 0.8))),
      api: Array.from({length: 6}, (_, i) => Math.max(0, Math.round(1 + i * 0.5)))
    };
    
    setActivityHistory(initialHistory);
  }
}, []);

// Simular respuesta del modelo ML (en v0.1 es simulado, en el futuro conectará con tu backend ML)
const simulateMLResponse = async (text: string, brief: boolean = false): Promise<string> => {
  // Versión simplificada para v0.1
  // En el futuro, esto se conectaría con tu modelo ML real
  
  // Simular latencia de red/procesamiento
  await new Promise(resolve => setTimeout(resolve, 700));
  
  const responses: {[key: string]: string} = {
    "hola": "¡Hola! ¿En qué puedo ayudarte hoy?",
    "ayuda": "Puedo responder preguntas, proporcionar información y buscar datos en tiempo real. ¿Sobre qué tema necesitas ayuda?",
    "que puedes hacer": "Puedo procesar preguntas, aprender de conversaciones, y conectarme a APIs externas para obtener información actualizada.",
    "quien eres": `Soy ${currentNick}, un bot IRC con capacidades de aprendizaje automático. Estoy en fase de desarrollo v0.1.`,
  };
  
  // Buscar palabras clave
  for (const [key, response] of Object.entries(responses)) {
    if (text.toLowerCase().includes(key)) {
      return brief ? response.split('.')[0] : response;
    }
  }
  
  // Respuesta por defecto
  return brief 
    ? "Estoy procesando tu consulta con mi modelo de ML." 
    : `He analizado tu mensaje "${text}" y he generado esta respuesta basada en mi entrenamiento.`;
};

// Función para manejar cambios en el formato de respuesta
// Función para manejar cambios en el formato de respuesta
// Función para manejar cambios en el formato de respuesta
const handleApiResponseFormatChange = (index: number, field: string, value: string) => {
  setBotConfig(prev => {
    const newApis = [...prev.apis];
    
    // Asegurarnos de que el objeto API existe
    if (!newApis[index]) {
      return prev; // No hacer cambios si el índice está fuera de rango
    }
    
    // Crear el objeto responseFormat si no existe
    if (!newApis[index].responseFormat) {
      newApis[index].responseFormat = {};
    }
    
    // Asignar el valor según el campo específico - ahora estamos seguros de que responseFormat existe
    const responseFormat = newApis[index].responseFormat!; // El "!" indica a TypeScript que sabemos que este valor no es undefined
    
    if (field === 'responsePath') {
      responseFormat.responsePath = value;
    }
    // Agregar aquí más campos si se añaden en el futuro
    
    return {
      ...prev,
      apis: newApis
    };
  });
};

// Función para manejar cambios en el formato de petición
const handleApiRequestFormatChange = (index: number, field: string, value: string) => {
  setBotConfig(prev => {
    const newApis = [...prev.apis];
    
    // Asegurarnos de que el objeto API existe
    if (!newApis[index]) {
      return prev; // No hacer cambios si el índice está fuera de rango
    }
    
    // Crear el objeto requestFormat si no existe
    if (!newApis[index].requestFormat) {
      newApis[index].requestFormat = {};
    }
    
    // Asignar el valor según el campo específico - ahora estamos seguros de que requestFormat existe
    const requestFormat = newApis[index].requestFormat!;
    
    if (field === 'queryParam') {
      requestFormat.queryParam = value;
    } else if (field === 'bodyParam') {
      requestFormat.bodyParam = value;
    } else if (field === 'headerAuthPrefix') {
      requestFormat.headerAuthPrefix = value;
    }
    // Agregar aquí más campos si se añaden en el futuro
    
    return {
      ...prev,
      apis: newApis
    };
  });
};

// Simular respuesta de APIs externas
const simulateAPIResponse = async (text: string, apiType: string, brief: boolean = false): Promise<string> => {
  // Versión simulada para v0.1
  // En el futuro, esto se conectaría con APIs reales
  
  // Simular latencia de red
  await new Promise(resolve => setTimeout(resolve, 500));
  
  switch (apiType) {
    case 'weather':
      return brief 
        ? "Temperatura: 22°C, Parcialmente nublado" 
        : "El clima actual es: 22°C, Parcialmente nublado. Existe 20% de probabilidad de lluvia. Humedad: 65%.";
    
    case 'news':
      return brief 
        ? "Última noticia: Avances en IA" 
        : "Últimas noticias: 'Nuevos avances en Inteligencia Artificial permiten mayor personalización de respuestas', 'Desarrolladores implementan sistemas conversacionales mejorados'.";
      
    default:
      return brief 
        ? "Obteniendo datos en tiempo real..." 
        : `He consultado fuentes externas para responder a "${text}". Esta es una respuesta simulada que en el futuro se conectará con APIs reales para proporcionar datos actualizados.`;
  }
};

// Función para actualizar la configuración de una API
const handleApiConfigChange = (index: number, field: keyof ApiConfig, value: any) => {
  setBotConfig(prev => {
    const newApis = [...prev.apis];
    newApis[index] = {
      ...newApis[index],
      [field]: value
    };
    return {
      ...prev,
      apis: newApis
    };
  });
};

// Función para añadir una nueva API
const handleAddApi = () => {
  setBotConfig(prev => ({
    ...prev,
    apis: [
      ...prev.apis,
      {
        name: `API ${prev.apis.length + 1}`,
        enabled: true,
        endpoint: '',
        priority: 5,
        apiKey: '',
        method: 'GET'
      }
    ]
  }));
};

// Función para eliminar una API
const handleRemoveApi = (index: number) => {
  setBotConfig(prev => ({
    ...prev,
    apis: prev.apis.filter((_, i) => i !== index)
  }));
};

// Crear datos de actividad basados en métricas reales
// Reemplazar la implementación actual
const getActivityChartData = async () => {
  try {
    // Intentar obtener datos reales primero
    if (botConfig.isActive) {
      try {
          const response = await fetch(`https://backend.anubisai.net/api/mybrain/activity-metrics`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });
        
        if (response.ok) {
          const data = await response.json();
          
          if (data.success && data.metrics && data.metrics.length > 0) {
            console.log("Datos de actividad obtenidos del servidor:", data.metrics);
            return data.metrics;
          }
        }
      } catch (error) {
        console.error('Error al obtener métricas de actividad:', error);
        // Continuar con los datos fallback
      }
    }
    
    // Si llegamos aquí, usamos datos fallback
    const fallbackData = generateFallbackActivityData();
    console.log("Usando datos de actividad fallback:", fallbackData);
    return fallbackData;
  } catch (error) {
    console.error('Error inesperado en getActivityChartData:', error);
    // Asegurar que siempre devolvemos algo, incluso en caso de error
    return [
      { time: '00:00', messages: 5, ml: 3, api: 1 },
      { time: '04:00', messages: 7, ml: 4, api: 2 },
      { time: '08:00', messages: 9, ml: 5, api: 3 },
      { time: '12:00', messages: 11, ml: 6, api: 3 },
      { time: '16:00', messages: 13, ml: 7, api: 4 },
      { time: '20:00', messages: 15, ml: 8, api: 5 }
    ];
  }
};

const [activityData, setActivityData] = useState(() => {
// Generar datos iniciales para el gráfico
return [
  { time: '00:00', messages: 5, ml: 3, api: 1 },
  { time: '04:00', messages: 7, ml: 4, api: 2 },
  { time: '08:00', messages: 9, ml: 5, api: 3 },
  { time: '12:00', messages: 11, ml: 6, api: 3 },
  { time: '16:00', messages: 13, ml: 7, api: 4 },
  { time: '20:00', messages: 15, ml: 8, api: 5 }
];
});

// Efecto para cargar datos de actividad
useEffect(() => {
  // Función para cargar datos
  const loadActivityData = async () => {
    const data = await getActivityChartData();
    setActivityData(data);
  };
  
  // Cargar datos iniciales
  loadActivityData();
  
  // Actualizar cada minuto si el bot está activo
  let interval: NodeJS.Timeout | null = null;
  if (botConfig.isActive) {
    interval = setInterval(loadActivityData, 15000); // Reducir a 15 segundos para ver cambios más rápido
  }
  
  return () => {
    if (interval) {
      clearInterval(interval);
    }
  };
}, [botConfig.isActive, metrics.messagesProcessed, metrics.mlQueries, metrics.apiQueries]);

// Función de respaldo para generar datos simulados (solo si falla la API)
const generateFallbackActivityData = () => {
  const now = new Date();
  // Generar datos consistentes basados en la hora actual
  const seed = now.getDate() + now.getMonth() * 31;
  
  // Valores base mínimos más significativos para asegurar variación visible
  const MIN_BASE_MESSAGES = 5;
  const MIN_BASE_ML = 3;
  const MIN_BASE_API = 2;
  
  return Array.from({length: 6}, (_, i) => {
    const hour = new Date(now);
    hour.setHours(now.getHours() - (5 - i));
    
    // Usar valores reales de métricas como base, pero con mínimos significativos
    const baseMessages = Math.max(MIN_BASE_MESSAGES, metrics.messagesProcessed / 6);
    const baseML = Math.max(MIN_BASE_ML, metrics.mlQueries / 6);
    const baseAPI = Math.max(MIN_BASE_API, metrics.apiQueries / 6);
    
    // Factor de crecimiento que aumenta hacia las horas más recientes (de 0.6 a 1.4)
    const growthFactor = 0.6 + (i * 0.16);
    
    // Usar un algoritmo determinista para generar variaciones más pronunciadas
    const hourSeed = seed + hour.getHours();
    const messageVar = Math.sin(hourSeed * 0.3) * baseMessages * 0.7 * growthFactor;
    const mlVar = Math.cos(hourSeed * 0.5) * baseML * 0.6 * growthFactor;
    const apiVar = Math.sin(hourSeed * 0.7) * baseAPI * 0.5 * growthFactor;
    
    return {
      time: hour.getHours() + ':00',
      messages: Math.max(1, Math.round(baseMessages * growthFactor + messageVar)),
      ml: Math.max(1, Math.round(baseML * growthFactor + mlVar)),
      api: Math.max(0, Math.round(baseAPI * growthFactor + apiVar))
    };
  });
};

  /*function handleApiRequestFormatChange(index: number, arg1: string, value: string): void {
    throw new Error('Function not implemented.');
  }*/

return (
  <>
    <WidgetsSectionTitle
      title="MyBrain Bot ML Training Integration"
      subtitle="Gestión y monitoreo de entrenamiento ML"
      icon={faRobot}
      className="my-5"
    />
    <div className="p-4">
      {/* Bot Status and Controls */}
      <Row className="mb-4">
        <Col md={3}>
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <p className="text-muted mb-1">Estado del Bot</p>
                  <h3 className="mb-0">
                    {botConfig.isActive ? 
                      <span className="text-success">Activo</span> : 
                      <span className="text-danger">Inactivo</span>
                    }
                  </h3>
                  {botConfig.isActive && (
                    <small className="text-muted">Conectado como {currentNick}</small>
                  )}
                  {connectionErrors.length > 0 && (
                    <div className="mt-2">
                      <small className="text-danger">
                        {reconnectAttempts > 0 && `Intentos de reconexión: ${reconnectAttempts}`}
                      </small>
                    </div>
                  )}
                </div>
                <Bot size={24} className={botConfig.isActive ? 'text-success' : 'text-danger'} />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <p className="text-muted mb-1">Mensajes</p>
                  <h3 className="mb-0">{metrics.messagesProcessed}</h3>
                  <small className="text-muted">Tiempo: {formatUptime(metrics.uptime)}</small>
                </div>
                <MessageSquare size={24} className="text-primary" />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <p className="text-muted mb-1">Consultas ML</p>
                  <h3 className="mb-0">{metrics.mlQueries}</h3>
                  <div className="d-flex align-items-center">
                    <Badge bg={botConfig.preferML ? "primary" : "secondary"} className="me-1">
                      {botConfig.preferML ? "Prioridad Alta" : "Normal"}
                    </Badge>
                  </div>
                </div>
                <Brain size={24} className="text-info" />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <p className="text-muted mb-1">APIs Externas</p>
                  <h3 className="mb-0">{metrics.apiQueries}</h3>
                  <small className="text-muted">
                    {botConfig.apis.filter(api => api.enabled).length} activas
                  </small>
                </div>
                <Globe size={24} className="text-warning" />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mb-4">
        {/* Configuración del Bot */}
        <Col md={6}>
          <Card>
            <Card.Header className="d-flex justify-content-between align-items-center">
              <h5 className="mb-0">BOT Configuration</h5>
              <Button 
                variant="outline-secondary" 
                size="sm"
                onClick={() => setShowApiConfig(!showApiConfig)}
              >
                {showApiConfig ? "BOT Configuration" : "APIs Configuration"}
              </Button>
            </Card.Header>
            <Card.Body>
              {!showApiConfig ? (
                <Form>
                  {/*<Row>
                    <Col md={8}>
                      <Form.Group className="mb-3">
                        <Form.Label>Servidor IRC</Form.Label>
                        <Form.Control
                          type="text"
                          value="irc.linuxcorp.cl" //{botConfig.server}
                          onChange={(e) => setBotConfig(prev => ({ ...prev, server: e.target.value }))}
                          disabled={true} //{botConfig.isActive}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>Puerto</Form.Label>
                        <Form.Control
                          type="number"
                          value="6667" //{botConfig.port}
                          onChange={(e) => setBotConfig(prev => ({ ...prev, port: parseInt(e.target.value) || 6667 }))}
                          disabled={true} //{botConfig.isActive}
                        />
                      </Form.Group>
                    </Col>
                  </Row>*/}
                  <Form.Group className="mb-3">
                    <Form.Label>Nickname del Bot</Form.Label>
                    <Form.Control
                      type="text"
                      value={botConfig.nickname}
                      onChange={(e) => setBotConfig(prev => ({ ...prev, nickname: e.target.value }))}
                      disabled={botConfig.isActive}
                    />
                    <Form.Text className="text-muted">
                      Nicknames alternativos: {botConfig.alternativeNicks.join(', ')}
                    </Form.Text>
                  </Form.Group>
                  {/*<Form.Group className="mb-3">
                    <Form.Label>Contraseña del Servidor (opcional)</Form.Label>
                    <Form.Control
                      type="password"
                      value={botConfig.password || ''}
                      onChange={(e) => setBotConfig(prev => ({ ...prev, password: e.target.value }))}
                      placeholder="Dejar en blanco si no se requiere"
                      disabled={botConfig.isActive}
                    />
                  </Form.Group>*/}
                  <Form.Group className="mb-3">
                    <Form.Check 
                      type="switch"
                      id="ssl-switch"
                      label="Usar conexión SSL/TLS"
                      checked={botConfig.useSSL}
                      onChange={(e) => setBotConfig(prev => ({ ...prev, useSSL: e.target.checked }))}
                      disabled={botConfig.isActive}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Endpoint ML</Form.Label>
                    <Form.Control
                      type="text"
                      value={botConfig.apis.find(api => api.name === 'Anubis')?.endpoint || ''}
                      onChange={(e) => setBotConfig(prev => ({ ...prev, mlEndpoint: e.target.value }))}
                      disabled={botConfig.isActive}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                  <Form.Group className="mb-3">
  <Form.Check 
    type="switch"
    id="api-priority-switch"
    label="Priorizar APIs sobre ML"
    checked={botConfig.preferAPIs}
    onChange={(e) => setBotConfig(prev => ({ ...prev, preferAPIs: e.target.checked }))}
    disabled={botConfig.isActive}
  />
  <Form.Text className="text-muted">
    Si está activado, se priorizarán las respuestas de APIs sobre el modelo ML cuando sea posible.
  </Form.Text>
</Form.Group>
                    <Form.Check 
                      type="switch"
                      id="ml-priority-switch"
                      label="Priorizar modelo ML sobre APIs"
                      checked={botConfig.preferML}
                      onChange={(e) => setBotConfig(prev => ({ ...prev, preferML: e.target.checked }))}
                      disabled={botConfig.isActive}
                    />
                    <Form.Text className="text-muted">
                      Si está activado, se priorizarán las respuestas del modelo ML. Caso contrario, se combinarán respuestas de ML y APIs.
                    </Form.Text>
                  </Form.Group>
                  <div className="d-flex gap-2">
                    <Button
                      variant={botConfig.isActive ? "danger" : "success"}
                      onClick={botConfig.isActive ? handleStopBot : handleStartBot}
                      disabled={loading}
                    >
                      {loading ? (
                        <><Spinner as="span" animation="border" size="sm" /> Cargando...</>
                      ) : (
                        botConfig.isActive ? "Detener Bot" : "Iniciar Bot"
                      )}
                    </Button>
                    
                    {botConfig.isActive && (
  <>
    <Button
      variant={autoResponseEnabled ? "warning" : "outline-secondary"}
      onClick={toggleAutoResponse}
      disabled={!botConfig.isActive}
      className="ms-2"
    >
      {autoResponseEnabled ? "Deshabilitar Auto-Respuesta" : "Habilitar Auto-Respuesta"}
    </Button>
    
    {/* Botones de emergencia para depuración */}
    {/*<Button
      variant="danger"
      size="sm"
      onClick={() => forceAutoResponseStatus(false)}
      className="ms-2"
    >
      Forzar OFF
    </Button>
    <Button
      variant="success"
      size="sm"
      onClick={() => forceAutoResponseStatus(true)}
      className="ms-2"
    >
      Forzar ON
    </Button>*/}
  </>
)}
                    
                    {!botConfig.isActive && (
                      <Button
                        variant="outline-info"
                        onClick={handleDiagnoseConnection}
                        disabled={loading}
                      >
                        Diagnosticar Conexión
                      </Button>
                    )}
                  </div>
                </Form>
              ) : (
                // Panel de configuración de APIs
                <div>
                  <h6>Integración con APIs Externas</h6>
                  <p className="text-muted small">Configure las APIs externas que el bot utilizará para obtener información en tiempo real.</p>
{botConfig.apis.map((api, index) => (
  <Card key={index} className="mb-3">
    <Card.Body>
      <Row>
        <Col md={6}>
          <Form.Group className="mb-2">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type="text"
              value={api.name}
              onChange={(e) => handleApiConfigChange(index, 'name', e.target.value)}
              disabled={botConfig.isActive}
              size="sm"
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="mb-2">
            <Form.Label>Endpoint</Form.Label>
            <Form.Control
              type="text"
              value={api.endpoint}
              onChange={(e) => handleApiConfigChange(index, 'endpoint', e.target.value)}
              disabled={botConfig.isActive}
              size="sm"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Group className="mb-2">
            <Form.Label>API Key</Form.Label>
            <Form.Control
              type="password"
              value={api.apiKey || ''}
              onChange={(e) => handleApiConfigChange(index, 'apiKey', e.target.value)}
              disabled={botConfig.isActive}
              size="sm"
            />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group className="mb-2">
            <Form.Label>Prioridad</Form.Label>
            <Form.Control
              type="number"
              value={api.priority}
              onChange={(e) => handleApiConfigChange(index, 'priority', parseInt(e.target.value) || 5)}
              disabled={botConfig.isActive}
              size="sm"
              min={1}
              max={10}
            />
          </Form.Group>
        </Col>
        <Col md={3} className="d-flex align-items-end mb-2">
          <Form.Check 
            type="switch"
            id={`api-enabled-${index}`}
            label="Activa"
            checked={api.enabled}
            onChange={(e) => handleApiConfigChange(index, 'enabled', e.target.checked)}
            disabled={botConfig.isActive}
          />
        </Col>
      </Row>
      
      {/* Nueva sección para configuración avanzada */}
      <div className="mt-2">
        <Button 
          variant="outline-secondary" 
          size="sm" 
          className="mb-2"
          onClick={() => toggleApiAdvancedConfig(index)}
        >
          {expandedApiConfig === index ? 'Ocultar configuración avanzada' : 'Mostrar configuración avanzada'}
        </Button>
        
        {expandedApiConfig === index && (
          <Card className="mt-2">
            <Card.Body>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-2">
                    <Form.Label>Método</Form.Label>
                    <Form.Select
                      value={api.method || 'POST'}
                      onChange={(e) => handleApiConfigChange(index, 'method', e.target.value)}
                      disabled={botConfig.isActive}
                      size="sm"
                    >
                      <option value="GET">GET</option>
                      <option value="POST">POST</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              
              <h6 className="mt-2">Formato de Petición</h6>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-2">
                    <Form.Label>Parámetro de consulta (GET)</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="ej: q, query, prompt"
                      value={api.requestFormat?.queryParam || ''}
                      onChange={(e) => handleApiRequestFormatChange(index, 'queryParam', e.target.value)}
                      disabled={botConfig.isActive}
                      size="sm"
                    />
                    <Form.Text className="text-muted">
                      Nombre del parámetro para enviar el texto en consultas GET
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-2">
                    <Form.Label>Parámetro del cuerpo (POST)</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="ej: prompt, text, query"
                      value={api.requestFormat?.bodyParam || ''}
                      onChange={(e) => handleApiRequestFormatChange(index, 'bodyParam', e.target.value)}
                      disabled={botConfig.isActive}
                      size="sm"
                    />
                    <Form.Text className="text-muted">
                      Nombre del parámetro para enviar el texto en el cuerpo JSON de POST
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-2">
                    <Form.Label>Prefijo de Autenticación</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="ej: Bearer, Token"
                      value={api.requestFormat?.headerAuthPrefix || ''}
                      onChange={(e) => handleApiRequestFormatChange(index, 'headerAuthPrefix', e.target.value)}
                      disabled={botConfig.isActive}
                      size="sm"
                    />
                    <Form.Text className="text-muted">
                      Prefijo para el token de autenticación en el header
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              
              <h6 className="mt-2">Formato de Respuesta</h6>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-2">
                    <Form.Label>Ruta de la Respuesta</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="ej: data.reply, response, result"
                      value={api.responseFormat?.responsePath || ''}
                      onChange={(e) => handleApiResponseFormatChange(index, 'responsePath', e.target.value)}
                      disabled={botConfig.isActive}
                      size="sm"
                    />
                    <Form.Text className="text-muted">
                      Ruta al campo que contiene la respuesta en el JSON devuelto
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        )}
      </div>
      
      <div className="d-flex justify-content-end mt-2">
        <Button
          variant="outline-danger"
          size="sm"
          onClick={() => handleRemoveApi(index)}
          disabled={botConfig.isActive || botConfig.apis.length <= 1}
        >
          Eliminar API
        </Button>
      </div>
    </Card.Body>
  </Card>
))}
                  
                  <div className="d-flex justify-content-between mt-3">
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={handleAddApi}
                      disabled={botConfig.isActive}
                    >
                      Añadir Nueva API
                    </Button>
                    
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      onClick={() => setShowApiConfig(false)}
                    >
                      Volver a Configuración IRC
                    </Button>
                  </div>
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>

        {/* Consola IRC */}
        <Col md={6}>
          <Card>
            <Card.Header>
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="mb-0">Learning Console</h5>
                <Form.Select
                  style={{ width: 'auto' }}
                  value={selectedChannel}
                  onChange={(e) => setSelectedChannel(e.target.value)}
                >
                  {botConfig.channels.map(channel => (
                    <option key={channel} value={channel}>{channel}</option>
                  ))}
                </Form.Select>
              </div>
            </Card.Header>
            <Card.Body>
              <div className="chat-container mb-3" style={{ height: '300px', overflowY: 'auto' }}>
                {messages
                 .filter(msg => msg.type === 'system' || msg.channel === selectedChannel)
                 .map((message, index) => (
                   <div
                     key={`msg-${message.id}-${index}`}
                     className={`mb-2 ${
                       message.type === 'system' ? 
                         (message.text.includes('Error') ? 'text-danger' : 'text-muted') :
                       message.type === 'command' ? 'text-primary' : ''
                     }`}
                   >
                     <small>{new Date(message.timestamp).toLocaleTimeString()}</small>
                     <span className="mx-2 fw-bold">{message.sender}:</span>
                     <span>
                       {message.text}
                       {message.source && message.source !== 'user' && (
                         <Badge 
                           bg={
                             message.source === 'ml' ? 'info' :
                             message.source === 'api' ? 'warning' : 'secondary'
                           }
                           className="ms-2"
                         >
                           {message.source === 'ml' ? 'ML' :
                            message.source === 'api' ? 'API' : 'IRC'}
                         </Badge>
                       )}
                     </span>
                   </div>
                 ))}
                 <div ref={messagesEndRef} />
              </div>
              <Form className="d-flex gap-2" onSubmit={(e) => { e.preventDefault(); handleSendCommand(); }}>
                <Form.Control
                  type="text"
                  value={commandInput}
                  onChange={(e) => setCommandInput(e.target.value)}
                  placeholder="Escribe un comando o mensaje (/help para ayuda)"
                  disabled={!botConfig.isActive || loading}
                />
                <Button
                  variant="primary"
                  onClick={handleSendCommand}
                  disabled={!botConfig.isActive || loading || !commandInput.trim()}
                >
                  <Send size={20} />
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Datos de Actividad */}
      <Row>
        <Col>
        <Card>
  <Card.Header>
    <h5 className="mb-0">Actividad del Bot</h5>
  </Card.Header>
  <Card.Body>
    <div style={{ height: '300px' }}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="time" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="messages"
            stroke="#0d6efd"
            name="Mensajes Totales"
          />
          <Line
            type="monotone"
            dataKey="ml"
            stroke="#17a2b8"
            name="Consultas ML"
          />
          <Line
            type="monotone"
            dataKey="api"
            stroke="#ffc107"
            name="Consultas API"
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  </Card.Body>
</Card>
        </Col>
      </Row>
    </div>
  </>
);
};

export default IRCBotDashboard;

/*
// Declaraciones globales
declare global {
  interface Window {
    reconnectHandler: NodeJS.Timeout | undefined;
  }
}

// Initialize the property
if (typeof window !== 'undefined') {
  window.reconnectHandler = undefined;
}

import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Button, Card, Col, Form, Row, Table, Badge, Spinner } from "react-bootstrap";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { MessageSquare, Send, Server, Bot, Activity, Database, Globe, Brain } from 'lucide-react';
import WidgetsSectionTitle from '../widgets/WidgetsSectionTitle';
import { faRobot } from '@fortawesome/free-solid-svg-icons';
import ircService, { IRCConfig, IRCMessage } from '../../../service/ircService';

// Interfaces
interface MessageIntent {
  type: 'direct_command' | 'ml_query' | 'api_query' | 'hybrid' | 'default';
  apiType?: string;
  context?: string;
  confidence?: number;
}

interface ProxyConfig {
  enabled: boolean;
  endpoint: string;
  authToken?: string;
}

interface ApiConfig {
  name: string;
  enabled: boolean;
  endpoint: string;
  apiKey?: string;
  priority: number;
}

interface BotConfig {
  server: string;
  port: number;
  nickname: string;
  alternativeNicks: string[];
  channels: string[];
  mlEndpoint: string;
  isActive: boolean;
  useSSL?: boolean;
  password?: string;
  proxy: ProxyConfig;
  apis: ApiConfig[];
  preferML: boolean;
}

interface BotMetrics {
  messagesProcessed: number;
  activeChannels: number;
  uptime: number;
  lastTraining: string;
  mlQueries: number;
  apiQueries: number;
}

interface ActivityHistory {
  timestamps: string[];
  messages: number[];
  ml: number[];
  api: number[];
}

const MybrainBotDashboard: React.FC = () => {
  // State Declarations
  const [messages, setMessages] = useState<IRCMessage[]>([]);
  const [botConfig, setBotConfig] = useState<BotConfig>(() => {
    // Try to load configuration from localStorage
    const savedConfig = localStorage.getItem('ircBotConfig');
    return savedConfig ? JSON.parse(savedConfig) : {
      server: 'irc.linuxcorp.cl',
      port: 6667,
      nickname: 'MyBot',
      alternativeNicks: ['AnubisBot', 'MLBot', 'TrainerBot', 'AnubisAssistant'],
      channels: ['#training', '#support', '#general'],
      mlEndpoint: 'http://10.10.5.1:5000/train',
      isActive: false,
      useSSL: false,
      proxy: {
        enabled: true,
        endpoint: 'https://localhost:3456/api',
        authToken: ''
      },
      apis: [
        { 
          name: 'Clima',
          enabled: true,
          endpoint: 'https://api.openweathermap.org/data/2.5/weather',
          apiKey: '',
          priority: 2
        },
        { 
          name: 'Noticias',
          enabled: true,
          endpoint: 'https://newsapi.org/v2/everything',
          apiKey: '',
          priority: 3
        }
      ],
      preferML: true
    };
  });
  
  // Improved Metrics State with Better Update Mechanism
  const [metrics, setMetrics] = useState<BotMetrics>({
    messagesProcessed: 0,
    activeChannels: 0,
    uptime: 0,
    lastTraining: new Date().toISOString(),
    mlQueries: 0,
    apiQueries: 0
  });

  // Improved Activity History State
  const [activityHistory, setActivityHistory] = useState<ActivityHistory>({
    timestamps: [],
    messages: [],
    ml: [],
    api: []
  });

  
  const [selectedChannel, setSelectedChannel] = useState<string>('#training');
  const [commandInput, setCommandInput] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [currentNick, setCurrentNick] = useState<string>(botConfig.nickname);
  const [connectionErrors, setConnectionErrors] = useState<string[]>([]);
  const [reconnectAttempts, setReconnectAttempts] = useState<number>(0);
  const [showApiConfig, setShowApiConfig] = useState<boolean>(false);
  // Añadir estado para el modo automático
  const [autoResponseEnabled, setAutoResponseEnabled] = useState<boolean>(false);
  
  // Referencia al contenedor de mensajes para auto-scroll
  const messagesEndRef = useRef<HTMLDivElement>(null);

    // Utility function to get current time as string
    const getCurrentTimeString = useCallback(() => {
      const now = new Date();
      return `${now.getHours().toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}`;
    }, []);

        // Estructura para almacenar métricas históricas
const activityMetrics = {
  // Array circular de métricas por hora (24 horas)
  hourly: Array(24).fill(null).map(() => ({
    messages: 0,
    ml: 0,
    api: 0,
    timestamp: new Date().toISOString()
  })),
  // Métricas de la hora actual
  current: {
    messages: 0,
    ml: 0,
    api: 0,
    timestamp: new Date().toISOString()
  },
  lastUpdateHour: new Date().getHours()
};

// Improved Metrics Update Function
const updateMetrics = useCallback((updateFn: (prevMetrics: BotMetrics) => BotMetrics) => {
  setMetrics(prevMetrics => {
    const newMetrics = updateFn(prevMetrics);
    
    // Update activity history whenever metrics change
    setActivityHistory(prevHistory => {
      const currentTime = getCurrentTimeString();
      
      // Check if the current timestamp already exists
      if (prevHistory.timestamps.length > 0 && 
          prevHistory.timestamps[prevHistory.timestamps.length - 1] === currentTime) {
        // Update the last entry
        const updatedTimestamps = [...prevHistory.timestamps];
        const updatedMessages = [...prevHistory.messages];
        const updatedMl = [...prevHistory.ml];
        const updatedApi = [...prevHistory.api];
        
        const lastIndex = updatedTimestamps.length - 1;
        updatedMessages[lastIndex] = newMetrics.messagesProcessed;
        updatedMl[lastIndex] = newMetrics.mlQueries;
        updatedApi[lastIndex] = newMetrics.apiQueries;
        
        return {
          timestamps: updatedTimestamps,
          messages: updatedMessages,
          ml: updatedMl,
          api: updatedApi
        };
      }
      
      // Add a new entry if timestamp is different
      return {
        timestamps: [...prevHistory.timestamps, currentTime].slice(-6),
        messages: [...prevHistory.messages, newMetrics.messagesProcessed].slice(-6),
        ml: [...prevHistory.ml, newMetrics.mlQueries].slice(-6),
        api: [...prevHistory.api, newMetrics.apiQueries].slice(-6)
      };
    });
    
    return newMetrics;
  });
}, [getCurrentTimeString]);

  // Helper function to determine response source
  const determineResponseSource = useCallback((message: IRCMessage): 'ml' | 'api' | 'irc' => {
    // Implement logic to determine response source
    if (message.text.includes('ml-specific-keyword')) return 'ml';
    if (message.text.includes('api-specific-keyword')) return 'api';
    return 'irc';
  }, []);
  
  // Efecto para inicializar los listeners del servicio IRC
  useEffect(() => {
    const messageUnsubscribe = ircService.onMessage((message) => {
      // Procesar mensajes entrantes con ML e integración API
      if (message.from !== 'System' && message.from !== currentNick) {
        processIncomingMessage({
          id: Date.now() * 1000 + Math.floor(Math.random() * 1000), // ID único
          text: message.text,
          sender: message.from || 'Unknown',
          channel: message.channel || 'system',
          timestamp: message.timestamp,
          type: 'message',
          source: 'user'
        });
      } else {
        // Mensajes de sistema o propios
        const ircMessage: IRCMessage = {
          id: Date.now() * 1000 + Math.floor(Math.random() * 1000), // ID único
          text: message.text,
          sender: message.from || 'Unknown',
          channel: message.channel || 'system',
          timestamp: message.timestamp,
          type: 'message',
          source: 'irc'
        };
        setMessages(prev => [...prev, ircMessage]);
        
        // Incrementar contador de mensajes
        setMetrics(prev => ({
          ...prev,
          messagesProcessed: prev.messagesProcessed + 1
        }));
      }
    });

    const eventUnsubscribe = ircService.onEvent((event) => {
      addSystemMessage(event.text);
    });

    const errorUnsubscribe = ircService.onError((error) => {
      addSystemMessage(error.text, 'error');
      setConnectionErrors(prev => [...prev, error.text]);
    });

    const connectionUnsubscribe = ircService.onConnectionChange((connected) => {
      setBotConfig(prev => ({ ...prev, isActive: connected }));
      if (connected) {
        addSystemMessage('Conexión IRC establecida correctamente');
      } else {
        addSystemMessage('Conexión IRC cerrada');
      }
    });

    // Iniciar contador de uptime si el bot está activo
    let uptimeInterval: NodeJS.Timeout | null = null;
    if (botConfig.isActive) {
      uptimeInterval = setInterval(() => {
        setMetrics(prev => ({
          ...prev,
          uptime: prev.uptime + 1
        }));
      }, 1000);
    }

    // Limpiar suscripciones al desmontar
    return () => {
      messageUnsubscribe();
      eventUnsubscribe();
      errorUnsubscribe();
      connectionUnsubscribe();
      
      if (uptimeInterval) {
        clearInterval(uptimeInterval);
      }
      
      // Desconectar el IRC si el componente se desmonta mientras está conectado
      if (botConfig.isActive) {
        ircService.disconnect().catch(console.error);
      }
    };
  }, [botConfig.isActive, currentNick]);

  // Auto-scroll cuando hay nuevos mensajes
  /* useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]); *_/
  useEffect(() => {
    if (messagesEndRef.current) {
      // Obtiene el elemento contenedor de mensajes (chat-container)
      const chatContainer = messagesEndRef.current.closest('.chat-container');
      
      if (chatContainer) {
        // Hacer scroll solo dentro del contenedor de chat
        chatContainer.scrollTop = chatContainer.scrollHeight;
      }
    }
  }, [messages])

  const handleStartBot = async () => {
    setLoading(true);
    setConnectionErrors([]);
    setReconnectAttempts(0);

    // Modo de simulación para pruebas (elimina esto cuando el backend esté listo)
    const simulationMode = false; // Cambia a false cuando quieras usar el backend real

    if (simulationMode) {
      try {
        addSystemMessage(`Iniciando en modo de simulación...`);
        await new Promise(resolve => setTimeout(resolve, 800));
        
        addSystemMessage(`Conectado a ${botConfig.server}:${botConfig.port} (simulado)`);
        setBotConfig(prev => ({ ...prev, isActive: true }));
        setCurrentNick(botConfig.nickname);
        
        // Simular una conexión exitosa
        addSystemMessage(`Unido a canales: ${botConfig.channels.join(', ')}`);
        
        // Mensaje de bienvenida simulado
        setTimeout(() => {
          const welcomeMsg: IRCMessage = {
            id: Date.now() * 1000 + Math.floor(Math.random() * 1000),
            text: `Bienvenido a ${selectedChannel}! Estoy en modo de simulación para desarrollo.`,
            sender: 'IRCServer',
            channel: selectedChannel,
            timestamp: new Date().toISOString(),
            type: 'message',
            source: 'irc'
          };
          setMessages(prev => [...prev, welcomeMsg]);
        }, 1500);
        
        // Actualizar métricas
        setMetrics(prev => ({
          ...prev,
          activeChannels: botConfig.channels.length,
          uptime: 0,
          mlQueries: 0,
          apiQueries: 0
        }));
        
        setLoading(false);
        return;
      } catch (error: any) {
        // Continuar con el método normal si hay algún error
      }
    }

    try {
      addSystemMessage(`Iniciando diagnóstico de conexión a ${botConfig.server}:${botConfig.port}...`);
      
      addSystemMessage(`Configurando proxy de conexión IRC en el backend...`);
      
      // Usar la nueva función de reconexión con nicknames alternativos
      const connectionConfig: IRCConfig = {
        server: botConfig.server,
        port: botConfig.port,
        nickname: botConfig.nickname,
        alternativeNicks: botConfig.alternativeNicks,
        channels: botConfig.channels,
        useSSL: botConfig.useSSL,
        password: botConfig.password
      };
      
      // Intentar conexión a través del servicio con soporte para nicknames alternativos
      const connectionId = await ircService.reconnectWithAlternativeNick(connectionConfig);
      
      // Consultar al servicio para obtener el nickname que realmente se asignó
      const connectionStatus = await ircService.checkConnection(connectionId);
      if (connectionStatus.nickname) {
        setCurrentNick(connectionStatus.nickname);
      } else {
        setCurrentNick(botConfig.nickname);
      }
      
      // Verificar estado de auto-respuesta
      if (connectionStatus.autoResponse !== undefined) {
        setAutoResponseEnabled(connectionStatus.autoResponse);
      }
      
      addSystemMessage(`Conexión establecida con ID: ${connectionId}`);
      
      // Inicializar sistemas de ML y API
      addSystemMessage(`Preparando integración con sistema ML en ${botConfig.mlEndpoint}`);
      addSystemMessage(`Configurando ${botConfig.apis.filter(api => api.enabled).length} APIs externas`);
      
      // Actualizar métricas
      setMetrics(prev => ({
        ...prev,
        activeChannels: botConfig.channels.length,
        uptime: 0,
        mlQueries: 0,
        apiQueries: 0
      }));
      
      // Configurar manejador de reconexión automática
      setupAutoReconnect(connectionConfig);
      
    } catch (error: any) {
      addSystemMessage(`Error al iniciar el bot: ${error.message}`, 'error');
      setConnectionErrors(prev => [...prev, error.message]);
    } finally {
      setLoading(false);
    }
  };

  // Método para cambiar el modo de auto-respuesta
  const toggleAutoResponse = async () => {
    if (!ircService.connectionId) return;
    
    try {
      const response = await fetch(`https://backend.anubisai.net/api/mybrain/autoresponse`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          connectionId: ircService.connectionId,
          enabled: !autoResponseEnabled // Toggle actual basado en el estado actual
        })
      });
      
      const data = await response.json();
      
      if (data.success) {
        // Actualizar el estado local directamente
        const newState = !autoResponseEnabled;
        setAutoResponseEnabled(newState);
        console.log(`Toggle: Estado de autoResponseEnabled cambiado a: ${newState}`);
        addSystemMessage(`Respuesta automática ${newState ? 'habilitada' : 'deshabilitada'}`);
      } else {
        addSystemMessage(`Error al configurar respuesta automática: ${data.message}`, 'error');
      }
    } catch (error: unknown) {
      let errorMessage = "Error desconocido";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      addSystemMessage(`Error de conexión: ${errorMessage}`, 'error');
    }
  };

  // Función de reconexión automática
  const setupAutoReconnect = (config: IRCConfig) => {
    // Limpiar cualquier manejador de reconexión previo
    if (window.reconnectHandler !== undefined) {
      clearInterval(window.reconnectHandler);
      window.reconnectHandler = undefined;
    }
    
    // Establecer un intervalo para verificar la conexión periódicamente
    window.reconnectHandler = setInterval(async () => {
      if (!botConfig.isActive) {
        if (window.reconnectHandler !== undefined) {
          clearInterval(window.reconnectHandler);
          window.reconnectHandler = undefined;
        }
        return;
      }
      
      try {
        if (ircService.connectionId) {
          const status = await ircService.checkConnection(ircService.connectionId);
          if (!status.active) {
            setReconnectAttempts(prev => prev + 1);
            addSystemMessage(`Conexión perdida. Intento de reconexión #${reconnectAttempts + 1}...`);
            
            // Intentar reconexión con nickname alternativo
            const connectionId = await ircService.reconnectWithAlternativeNick(config, reconnectAttempts);
            
            // Actualizar nickname si cambió
            const connectionStatus = await ircService.checkConnection(connectionId);
            if (connectionStatus.nickname) {
              setCurrentNick(connectionStatus.nickname);
            }
            
            addSystemMessage(`Reconexión exitosa con nickname: ${currentNick}`);
          }
        }
      } catch (error: any) {
        addSystemMessage(`Error en reconexión automática: ${error.message}`, 'error');
        setConnectionErrors(prev => [...prev, error.message]);
        
        // Si hay muchos intentos fallidos, detener los intentos
        if (reconnectAttempts > 5) {
          if (window.reconnectHandler !== undefined) {
            clearInterval(window.reconnectHandler);
            window.reconnectHandler = undefined;
          }
          addSystemMessage('Demasiados intentos de reconexión fallidos. Deteniendo reconexión automática.', 'error');
        }
      }
    }, 30000); // Verificar cada 30 segundos
  };

  // Función para corregir una respuesta del bot
const handleCorrectBotResponse = async (originalMessage: string, correctResponse: string) => {
  if (!ircService.connectionId) return;
  
  try {
    // Enviar datos de entrenamiento
    const response = await fetch(`${botConfig.proxy.endpoint}/irc/train`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        connectionId: ircService.connectionId,
        entity_type: 'channel',
        entity_id: selectedChannel.replace('#', ''),
        data: [{
          input: originalMessage,
          output: correctResponse
        }]
      })
    });
    
    const data = await response.json();
    
    if (data.success) {
      addSystemMessage(`Respuesta aprendida correctamente para: "${originalMessage.substring(0, 30)}..."`);
    } else {
      addSystemMessage(`Error al entrenar respuesta: ${data.message}`, 'error');
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      addSystemMessage(`Error de conexión: ${error.message}`, 'error');
    } else {
      addSystemMessage(`Error de conexión desconocido`, 'error');
    }
  }
};

  const handleStopBot = async () => {
    setLoading(true);
    addSystemMessage(`Iniciando proceso de desconexión...`);
    
    try {
      // No usamos simulación, vamos directo a la implementación real
      addSystemMessage(`Desconectando de ${botConfig.server}:${botConfig.port}`);
      
      // Implementar un timeout para la desconexión 
      const disconnectWithTimeout = async () => {
        try {
          // Establecer un timeout de 5 segundos para la desconexión
          const timeoutPromise = new Promise<boolean>((_, reject) => {
            setTimeout(() => reject(new Error('Timeout de desconexión')), 5000);
          });
          
          // Intentar desconectar con timeout
          return await Promise.race([
            ircService.disconnect(),
            timeoutPromise
          ]);
        } catch (error) {
          console.error("Error o timeout durante desconexión:", error);
          return false;
        }
      };
      
      // Intentar desconectar
      const disconnectResult = await disconnectWithTimeout();
      
      if (disconnectResult) {
        addSystemMessage('Bot detenido correctamente');
      } else {
        addSystemMessage('No se pudo completar la desconexión remota. Se actualizará el estado local.', 'warning');
      }
    } catch (error: any) {
      console.error("Error general al detener bot:", error);
      addSystemMessage(`Error al detener el bot: ${error.message}`, 'error');
    } finally {
      // Siempre actualizar el estado local, independientemente del resultado
      setBotConfig(prev => ({ ...prev, isActive: false }));
      setCurrentNick(botConfig.nickname);
      setConnectionErrors([]);
      setReconnectAttempts(0);
      setAutoResponseEnabled(false);
      
      // Limpiar intervalo de reconexión
      if (window.reconnectHandler !== undefined) {
        clearInterval(window.reconnectHandler);
        window.reconnectHandler = undefined;
      }
      
      // Reiniciar métricas
      setMetrics(prev => ({
        ...prev,
        uptime: 0
      }));
      
      setLoading(false);
    }
  };

  const handleSendCommand = async () => {
    if (!commandInput.trim() || !botConfig.isActive) return;
    
    const newMessage: IRCMessage = {
      id: Date.now() * 1000 + Math.floor(Math.random() * 1000),
      text: commandInput,
      sender: 'Admin',
      channel: selectedChannel,
      timestamp: new Date().toISOString(),
      type: 'command',
      source: 'user'
    };

    setMessages(prev => [...prev, newMessage]);
    
    // Modo de simulación (quitar cuando el backend esté listo)
    const simulationMode = false;
    
    if (simulationMode) {
      // Procesar comandos especiales
      if (commandInput.startsWith('/')) {
        if (commandInput.startsWith('/join ')) {
          const channel = commandInput.slice(6).trim();
          if (!botConfig.channels.includes(channel)) {
            setBotConfig(prev => ({
              ...prev,
              channels: [...prev.channels, channel]
            }));
            setSelectedChannel(channel);
            addSystemMessage(`Unido a canal: ${channel}`);
          }
        } else if (commandInput.startsWith('/nick ')) {
          const newNick = commandInput.slice(6).trim();
          setCurrentNick(newNick);
          addSystemMessage(`Nickname cambiado a ${newNick}`);
        } else if (commandInput.startsWith('/help')) {
          addSystemMessage('Comandos disponibles:');
          addSystemMessage('/join #canal - Unirse a un nuevo canal');
          addSystemMessage('/train - Entrenar el modelo ML con datos del canal');
          addSystemMessage('/status - Mostrar estado del bot');
          addSystemMessage('/apis - Mostrar estado de las APIs configuradas');
          addSystemMessage('/ml - Mostrar estadísticas del sistema ML');
          addSystemMessage('/nick nombre - Cambiar nickname');
          addSystemMessage('/auto on|off - Habilitar/deshabilitar respuestas automáticas');
          addSystemMessage('/help - Mostrar este mensaje de ayuda');
        } else if (commandInput.startsWith('/train')) {
          addSystemMessage(`Iniciando entrenamiento ML con datos de ${selectedChannel}`);
          setTimeout(() => {
            addSystemMessage('Entrenamiento ML en progreso...');
            setTimeout(() => {
              addSystemMessage('Entrenamiento completado. Modelo actualizado.');
              setMetrics(prev => ({
                ...prev,
                lastTraining: new Date().toISOString()
              }));
            }, 3000);
          }, 1000);
        } else if (commandInput.startsWith('/corregir ')) {
          // Formato esperado: /corregir "pregunta original" "respuesta correcta"
          const match = commandInput.match(/\/corregir\s+"([^"]+)"\s+"([^"]+)"/);
          
          if (match && match.length === 3) {
            const originalMessage = match[1];
            const correctResponse = match[2];
            
            await handleCorrectBotResponse(originalMessage, correctResponse);
          } else {
            addSystemMessage('Formato incorrecto. Uso: /corregir "pregunta original" "respuesta correcta"', 'error');
          }
          
          setCommandInput('');
          return;
        } else if (commandInput.startsWith('/apis')) {
          // Mostrar estado de las APIs
          addSystemMessage('APIs configuradas:');
          botConfig.apis.forEach(api => {
            addSystemMessage(`${api.name}: ${api.enabled ? 'Activa' : 'Inactiva'} - Prioridad: ${api.priority}`);
          });
        } else if (commandInput.startsWith('/ml')) {
          // Mostrar estadísticas de ML
          addSystemMessage(`Sistema ML: ${botConfig.mlEndpoint}`);
          addSystemMessage(`Consultas realizadas: ${metrics.mlQueries}`);
          addSystemMessage(`Último entrenamiento: ${new Date(metrics.lastTraining).toLocaleString()}`);
          addSystemMessage(`Prioridad ML: ${botConfig.preferML ? 'Alta' : 'Normal'}`);
        } else if (commandInput.startsWith('/status')) {
          addSystemMessage(`Estado del bot: ${botConfig.isActive ? 'Activo' : 'Inactivo'}`);
          addSystemMessage(`Conectado a: ${botConfig.server}:${botConfig.port}`);
          addSystemMessage(`Canales: ${botConfig.channels.join(', ')}`);
          addSystemMessage(`Tiempo en línea: ${formatUptime(metrics.uptime)}`);
        } else if (commandInput.startsWith('/auto')) {
          const enabled = commandInput.includes('on');
          const disabled = commandInput.includes('off');
          if (!enabled && !disabled) {
            addSystemMessage('Uso: /auto on|off - Habilita o deshabilita respuestas automáticas');
            setCommandInput('');
            return;
          }try {
            // Llamar a la API para configurar autoResponseEnabled en el backend
            const response = await fetch(`https://backend.anubisai.net/api/mybrain/autoresponse`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                connectionId: ircService.connectionId,
                enabled: enabled
              })
            });
            
            const data = await response.json();
            
            if (data.success) {
              // Actualizar el estado local INMEDIATAMENTE
              setAutoResponseEnabled(enabled);
              console.log(`Estado de autoResponseEnabled establecido a: ${enabled}`);
              addSystemMessage(`Respuesta automática ${enabled ? 'habilitada' : 'deshabilitada'}`);
            } else {
              addSystemMessage(`Error al configurar respuesta automática: ${data.message}`, 'error');
            }
          } catch (error: unknown) {
            let errorMessage = "Error desconocido";
            if (error instanceof Error) {
              errorMessage = error.message;
            }
            addSystemMessage(`Error de conexión: ${errorMessage}`, 'error');
          }
          setCommandInput('');
          return;
        } else {
          addSystemMessage(`Comando ${commandInput} no reconocido. Usa /help para ver la lista de comandos.`);
        }
      } else {
        // Mensaje normal - procesar con ML/API
        processIncomingMessage(newMessage);
      }
      
      setCommandInput('');
      return;
    }
    
    // Código original para enviar comandos al backend real
    try {
      if (commandInput.startsWith('/')) {
        if (commandInput.startsWith('/auto')) {
          const enabled = commandInput.includes('on');
          const disabled = commandInput.includes('off');
          
          if (!enabled && !disabled) {
            addSystemMessage('Uso: /auto on|off - Habilita o deshabilita respuestas automáticas');
            setCommandInput('');
            return;
          }
          
          try {
            //const response = await fetch(`${botConfig.proxy.endpoint}/irc/autoresponse`, {
            const response = await fetch(`https://backend.anubisai.net/api/mybrain/autoresponse`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                connectionId: ircService.connectionId,
                enabled: enabled
              })
            });
            
            const data = await response.json();
            
            if (data.success) {
              setAutoResponseEnabled(enabled);
              addSystemMessage(`Respuesta automática ${enabled ? 'habilitada' : 'deshabilitada'}`);
            } else {
              addSystemMessage(`Error al configurar respuesta automática: ${data.message}`, 'error');
            }
          } catch (error: any) {
            addSystemMessage(`Error de conexión: ${error.message}`, 'error');
          }
          
          setCommandInput('');
          return;
        } else if (commandInput.startsWith('/train')) {
          addSystemMessage(`Iniciando entrenamiento ML con datos de ${selectedChannel}`);
          
          try {
            // Versión real (no simulada) 
            //const response = await fetch(`${botConfig.proxy.endpoint}/irc/train`, {
              const response = await fetch(`https://backend.anubisai.net/api/mybrain/train`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                connectionId: ircService.connectionId,
                channel: selectedChannel
              })
            });
            
            const data = await response.json();
            
            if (data.success) {
              addSystemMessage('Entrenamiento ML completado exitosamente');
              setMetrics(prev => ({
                ...prev,
                lastTraining: new Date().toISOString()
              }));
            } else {
              addSystemMessage(`Error en entrenamiento ML: ${data.message}`, 'error');
            }
          } catch (error: any) {
            addSystemMessage(`Error al iniciar entrenamiento ML: ${error.message}`, 'error');
          }
          
          setCommandInput('');
          return;
        }
        
        // Otros comandos IRC
        await ircService.sendMessage(selectedChannel, commandInput);
        
        // Procesar comandos especiales
        if (commandInput.startsWith('/join ')) {
          const channel = commandInput.slice(6).trim();
          if (!botConfig.channels.includes(channel)) {
            setBotConfig(prev => ({
              ...prev,
              channels: [...prev.channels, channel]
            }));
            setSelectedChannel(channel);
          }
        } else if (commandInput.startsWith('/nick ')) {
          const newNick = commandInput.slice(6).trim();
          setCurrentNick(newNick);
        } else if (commandInput.startsWith('/help')) {
          addSystemMessage('Comandos disponibles:');
          addSystemMessage('/join #canal - Unirse a un nuevo canal');
          addSystemMessage('/train - Entrenar el modelo ML con datos del canal');
          addSystemMessage('/status - Mostrar estado del bot');
          addSystemMessage('/apis - Mostrar estado de las APIs configuradas');
          addSystemMessage('/ml - Mostrar estadísticas del sistema ML');
          addSystemMessage('/nick nombre - Cambiar nickname');
          addSystemMessage('/auto on|off - Habilitar/deshabilitar respuestas automáticas');
          addSystemMessage('/help - Mostrar este mensaje de ayuda');
        }
      } else {
        // Mensaje normal
        await ircService.sendMessage(selectedChannel, commandInput);
      }
    } catch (error: any) {
      addSystemMessage(`Error al enviar mensaje: ${error.message}`, 'error');
    }
    
    setCommandInput('');
  };

  const addSystemMessage = (text: string, type: 'info' | 'error' | 'warning' = 'info') => {
    const systemMessage: IRCMessage = {
      id: Date.now() * 1000 + Math.floor(Math.random() * 1000),
      text,
      sender: 'System',
      channel: 'system',
      timestamp: new Date().toISOString(),
      type: 'system',
      source: 'irc'
    };
    setMessages(prev => [...prev, systemMessage]);
    
    // Registrar errores si corresponde
    if (type === 'error') {
      setConnectionErrors(prev => [...prev, text]);
    }
  };

  const handleDiagnoseConnection = async () => {
    addSystemMessage('Ejecutando diagnóstico de conexión...');
    
    // Test de conexión real al backend
    try {
      const backendUrl = ircService.connectionId 
        ? `${botConfig.proxy.endpoint.replace('/api', '')}/health-check`
        : `https://backend.anubisai.net/health-check`;
        
      addSystemMessage(`Verificando disponibilidad del backend: ${backendUrl}`);
      
      const response = await fetch(backendUrl);
      if (response.ok) {
        const data = await response.json();
        addSystemMessage(`Backend disponible. Estado: ${data.status}`, 'info');
        
        // Probar conexión WebSocket de Socket.IO
        testDirectWebSocket();
      } else {
        addSystemMessage(`El backend respondió con estado: ${response.status}`, 'error');
      }
    } catch (error: any) {
      addSystemMessage(`Error al verificar backend: ${error.message}`, 'error');
      addSystemMessage(`Comprobando disponibilidad de red básica...`);
      
      // Diagnósticos adicionales
      addSystemMessage(`Prueba de conexión a ${botConfig.server}:${botConfig.port}...`);
      addSystemMessage('Hay conectividad de red básica.');
      addSystemMessage(`Verificando accesibilidad del servidor IRC...`);
      addSystemMessage(`Nota: Los navegadores no pueden conectarse directamente a servidores IRC.`);
      addSystemMessage(`Se requiere un backend o proxy para establecer la conexión real.`);
    }
  };

  const testDirectWebSocket = () => {
    addSystemMessage("Probando conexión directa WebSocket a backend.anubisai.net...");
    
    try {
      // Primero probar con polling explícito
      const pollingUrl = 'https://backend.anubisai.net/socket.io/?EIO=4&transport=polling';
      addSystemMessage(`Probando polling primero: ${pollingUrl}`);
      
      fetch(pollingUrl)
        .then(response => {
          if (response.ok) {
            addSystemMessage(`Conexión polling exitosa! Estado: ${response.status}`, 'info');
            testWebSocketAfterPolling();
          } else {
            addSystemMessage(`Polling falló con estado: ${response.status}. Esto puede indicar un problema CORS.`, 'error');
          }
        })
        .catch(error => {
          addSystemMessage(`Error en polling: ${error.message}`, 'error');
        });
      
    } catch (error: any) {
      addSystemMessage(`Error en prueba de conexión: ${error.message}`, 'error');
    }
  };

  const testWebSocketAfterPolling = () => {
    try {
      addSystemMessage("Ahora probando conexión WebSocket...");
      const wsUrl = 'wss://backend.anubisai.net/socket.io/?EIO=4&transport=websocket';
      
      const ws = new WebSocket(wsUrl);
      
      ws.onopen = () => {
        addSystemMessage("Conexión WebSocket abierta exitosamente!", 'info');
        ws.close();
      };
      
      ws.onmessage = (event) => {
        addSystemMessage(`Mensaje recibido: ${event.data}`, 'info');
      };
      
      ws.onerror = (event) => {
        addSystemMessage("Error de WebSocket", 'error');
        console.error(event);
      };
      
      ws.onclose = (event) => {
        addSystemMessage(`WebSocket cerrado con código: ${event.code} - ${event.reason}`, 'info');
      };
    } catch (error: any) {
      addSystemMessage(`Error en prueba WebSocket: ${error.message}`, 'error');
    }
  };

// Función para formatear el tiempo de uptime
const formatUptime = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
};

// ----- FUNCIONES PARA INTEGRACIÓN DE ML Y API -----

// Función para analizar la intención del mensaje
const analyzeMessageIntent = async (text: string): Promise<MessageIntent> => {
  // Implementación simple basada en keywords para v0.1
  // En versiones futuras, esto podría usar un modelo de NLP más sofisticado
  if (text.startsWith('/')) {
    return { type: 'direct_command' };
  } else if (text.match(/clima|temperatura|lluvia|sol|pronóstico/i)) {
    return { type: 'api_query', apiType: 'weather', confidence: 0.8 };
  } else if (text.match(/noticias|noticia|últimas noticias|actualidad/i)) {
    return { type: 'api_query', apiType: 'news', confidence: 0.8 };
  } else if (text.match(/aprende|recuerda|dime|explícame|información sobre/i)) {
    return { type: 'ml_query', context: 'learning', confidence: 0.7 };
  } else {
    // Por defecto, intentar con ML primero si está configurado así
    return { type: botConfig.preferML ? 'ml_query' : 'hybrid', context: 'conversation', confidence: 0.5 };
  }
};

// Primero, definamos una interfaz para el tipo de datos del historial
interface ActivityHistory {
  timestamps: string[];
  messages: number[];
  ml: number[];
  api: number[];
}

 // Improved Message Processing Function
// Modificación de la función processIncomingMessage
const processIncomingMessage = useCallback(async (message: IRCMessage) => {
  // Add user message to context
  if (message.source !== 'user') {
    setMessages(prev => [...prev, message]);
  }
  
  if (!botConfig.isActive || message.sender === currentNick) return;
  
  // Skip IRC commands
  if (message.text.startsWith('/')) return;
  
  try {
    // Simulate brief processing time
    setTimeout(async () => {
      console.log("Procesando mensaje:", {
        text: message.text,
        sender: message.sender,
        channel: message.channel,
        type: message.type
      });
      // Determine message intent and response source
      const intent = await analyzeMessageIntent(message.text);
      let responseSource = determineResponseSource(message);
      
      let responseText = '';
      
      // Generate response based on intent
      switch (intent.type) {
        case 'ml_query':
          responseText = await simulateMLResponse(message.text);
          responseSource = 'ml';
          setMetrics(prev => ({ ...prev, mlQueries: prev.mlQueries + 1 }));
          break;
        case 'api_query':
          responseText = await simulateAPIResponse(message.text, intent.apiType || 'general');
          responseSource = 'api';
          setMetrics(prev => ({ ...prev, apiQueries: prev.apiQueries + 1 }));
          break;
        default:
          responseText = 'No entiendo completamente tu consulta.';
          break;
      }
      
      // Create bot response for UI
      const botResponse: IRCMessage = {
        id: Date.now() * 1000 + Math.floor(Math.random() * 1000),
        text: responseText,
        sender: currentNick,
        channel: message.channel || selectedChannel, // Usa el canal del mensaje o el seleccionado
        timestamp: new Date().toISOString(),
        type: 'message',
        source: responseSource
      };
      
      // Update messages in UI
      setMessages(prev => [...prev, botResponse]);
      
      // SOLUCIÓN: Enviar la respuesta al canal IRC
      // Usar explícitamente el canal del mensaje original si está disponible
      const targetChannel = message.channel || selectedChannel;
      
      // Búsqueda más amplia de comandos de auto on/off
      const autoCommandMessages = messages.filter(m => 
        (m.sender === 'Admin' || m.sender === 'System') && 
        (m.text.includes('/auto') || m.text.includes('Respuesta automática'))
      );
      
      console.log("Mensajes de comandos auto:", autoCommandMessages.map(m => ({
        sender: m.sender,
        text: m.text,
        timestamp: m.timestamp
      })));
      
      // Por defecto, permitir respuestas (como medida temporal)
      let shouldRespond = true;
      
      // Si hay mensajes de auto, determinar estado basado en el más reciente
      if (autoCommandMessages.length > 0) {
        // Ordenar por timestamp, más reciente primero
        autoCommandMessages.sort((a, b) => 
          new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
        );
        
        const latestAutoMessage = autoCommandMessages[0];
        console.log("Mensaje más reciente de auto:", latestAutoMessage);
        
        // Verificar si el mensaje más reciente indica deshabilitación
        if (
          (latestAutoMessage.text.includes('/auto off')) || 
          (latestAutoMessage.text.includes('deshabilitada'))
        ) {
          shouldRespond = false;
        }
      }

      
      console.log("Decisión final de respuesta:", {
        shouldRespond,
        targetChannel,
        autoCommandCount: autoCommandMessages.length
      });
      
      if (shouldRespond && targetChannel && targetChannel.startsWith('#')) {
        try {
          await ircService.sendMessage(targetChannel, responseText);
          console.log(`Respuesta enviada a IRC canal ${targetChannel}: ${responseText}`);
        } catch (error: unknown) {
          console.error("Error enviando respuesta a IRC:", error);
          if (error instanceof Error) {
            addSystemMessage(`Error al enviar respuesta a IRC: ${error.message}`, 'error');
          } else {
            addSystemMessage(`Error al enviar respuesta a IRC: ${String(error)}`, 'error');
          }
        }
      } else {
        console.log("Auto-respuesta deshabilitada o mensaje no es de un canal");
      }
      
      // Update metrics atomically
      updateMetrics(prevMetrics => ({
        ...prevMetrics,
        messagesProcessed: prevMetrics.messagesProcessed + 1,
        mlQueries: responseSource === 'ml' ? prevMetrics.mlQueries + 1 : prevMetrics.mlQueries,
        apiQueries: responseSource === 'api' ? prevMetrics.apiQueries + 1 : prevMetrics.apiQueries
      }));
      
    }, 1000); // Simulate processing time
  } catch (error: unknown) {
    console.error("Error processing message:", error);
    let errorMessage = "Error desconocido";
    if (error instanceof Error) {
      errorMessage = error.message;
    } else if (error !== null && error !== undefined) {
      errorMessage = String(error);
    }
    
    setMessages(prev => [...prev, {
      id: Date.now() * 1000 + Math.floor(Math.random() * 1000),
      text: `Error al procesar mensaje: ${errorMessage}`,
      sender: 'System',
      channel: 'system',
      timestamp: new Date().toISOString(),
      type: 'system',
      source: 'irc'
    }]);
  }
}, [botConfig.isActive, currentNick, updateMetrics, determineResponseSource, autoResponseEnabled, addSystemMessage, selectedChannel, messages]);
 
  // Save configuration to localStorage when it changes
  useEffect(() => {
    localStorage.setItem('ircBotConfig', JSON.stringify(botConfig));
  }, [botConfig]);
// Usar useMemo para transformar el historial en datos para el gráfico
const chartData = useMemo(() => {
  // Si no hay datos en el historial, proporcionar datos iniciales
  if (activityHistory.timestamps.length === 0) {
    const hours = [
      new Date().getHours() - 5,
      new Date().getHours() - 4,
      new Date().getHours() - 3,
      new Date().getHours() - 2,
      new Date().getHours() - 1,
      new Date().getHours()
    ].map(h => `${(h + 24) % 24}:00`);
    
    return hours.map((time, i) => ({
      time,
      // Valores iniciales que crecen gradualmente
      messages: Math.max(1, Math.round(5 + i * 2)),
      ml: Math.max(1, Math.round(2 + i * 0.8)),
      api: Math.max(0, Math.round(1 + i * 0.5))
    }));
  }
  
  // Convertir el historial en datos para el gráfico
  return activityHistory.timestamps.map((time, index) => ({
    time,
    messages: activityHistory.messages[index],
    ml: activityHistory.ml[index],
    api: activityHistory.api[index]
  }));
}, [activityHistory]);

// Inicializar el historial cuando el componente se monta
useEffect(() => {
  // Solo inicializar si está vacío
  if (activityHistory.timestamps.length === 0) {
    const now = new Date();
    const currentHour = now.getHours();
    
    // Crear un historial de 6 horas con datos iniciales que aumentan gradualmente
    const initialHistory: ActivityHistory = {
      timestamps: Array.from({length: 6}, (_, i) => `${(currentHour - 5 + i + 24) % 24}:00`),
      messages: Array.from({length: 6}, (_, i) => Math.max(1, Math.round(5 + i * 2))),
      ml: Array.from({length: 6}, (_, i) => Math.max(1, Math.round(2 + i * 0.8))),
      api: Array.from({length: 6}, (_, i) => Math.max(0, Math.round(1 + i * 0.5)))
    };
    
    setActivityHistory(initialHistory);
  }
}, []);

// Simular respuesta del modelo ML (en v0.1 es simulado, en el futuro conectará con tu backend ML)
const simulateMLResponse = async (text: string, brief: boolean = false): Promise<string> => {
  // Versión simplificada para v0.1
  // En el futuro, esto se conectaría con tu modelo ML real
  
  // Simular latencia de red/procesamiento
  await new Promise(resolve => setTimeout(resolve, 700));
  
  const responses: {[key: string]: string} = {
    "hola": "¡Hola! ¿En qué puedo ayudarte hoy?",
    "ayuda": "Puedo responder preguntas, proporcionar información y buscar datos en tiempo real. ¿Sobre qué tema necesitas ayuda?",
    "que puedes hacer": "Puedo procesar preguntas, aprender de conversaciones, y conectarme a APIs externas para obtener información actualizada.",
    "quien eres": `Soy ${currentNick}, un bot IRC con capacidades de aprendizaje automático. Estoy en fase de desarrollo v0.1.`,
  };
  
  // Buscar palabras clave
  for (const [key, response] of Object.entries(responses)) {
    if (text.toLowerCase().includes(key)) {
      return brief ? response.split('.')[0] : response;
    }
  }
  
  // Respuesta por defecto
  return brief 
    ? "Estoy procesando tu consulta con mi modelo de ML." 
    : `He analizado tu mensaje "${text}" y he generado esta respuesta basada en mi entrenamiento.`;
};

// Simular respuesta de APIs externas
const simulateAPIResponse = async (text: string, apiType: string, brief: boolean = false): Promise<string> => {
  // Versión simulada para v0.1
  // En el futuro, esto se conectaría con APIs reales
  
  // Simular latencia de red
  await new Promise(resolve => setTimeout(resolve, 500));
  
  switch (apiType) {
    case 'weather':
      return brief 
        ? "Temperatura: 22°C, Parcialmente nublado" 
        : "El clima actual es: 22°C, Parcialmente nublado. Existe 20% de probabilidad de lluvia. Humedad: 65%.";
    
    case 'news':
      return brief 
        ? "Última noticia: Avances en IA" 
        : "Últimas noticias: 'Nuevos avances en Inteligencia Artificial permiten mayor personalización de respuestas', 'Desarrolladores implementan sistemas conversacionales mejorados'.";
      
    default:
      return brief 
        ? "Obteniendo datos en tiempo real..." 
        : `He consultado fuentes externas para responder a "${text}". Esta es una respuesta simulada que en el futuro se conectará con APIs reales para proporcionar datos actualizados.`;
  }
};

// Función para actualizar la configuración de una API
const handleApiConfigChange = (index: number, field: keyof ApiConfig, value: any) => {
  setBotConfig(prev => {
    const newApis = [...prev.apis];
    newApis[index] = {
      ...newApis[index],
      [field]: value
    };
    return {
      ...prev,
      apis: newApis
    };
  });
};

// Función para añadir una nueva API
const handleAddApi = () => {
  setBotConfig(prev => ({
    ...prev,
    apis: [
      ...prev.apis,
      {
        name: `API ${prev.apis.length + 1}`,
        enabled: true,
        endpoint: '',
        priority: 5,
        apiKey: ''
      }
    ]
  }));
};

// Función para eliminar una API
const handleRemoveApi = (index: number) => {
  setBotConfig(prev => ({
    ...prev,
    apis: prev.apis.filter((_, i) => i !== index)
  }));
};

// Crear datos de actividad basados en métricas reales
// Reemplazar la implementación actual
const getActivityChartData = async () => {
  try {
    // Intentar obtener datos reales primero
    if (botConfig.isActive) {
      try {
        //const response = await fetch(`${botConfig.proxy.endpoint}/irc/activity-metrics`, {
          const response = await fetch(`https://backend.anubisai.net/api/mybrain/activity-metrics`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });
        
        if (response.ok) {
          const data = await response.json();
          
          if (data.success && data.metrics && data.metrics.length > 0) {
            console.log("Datos de actividad obtenidos del servidor:", data.metrics);
            return data.metrics;
          }
        }
      } catch (error) {
        console.error('Error al obtener métricas de actividad:', error);
        // Continuar con los datos fallback
      }
    }
    
    // Si llegamos aquí, usamos datos fallback
    const fallbackData = generateFallbackActivityData();
    console.log("Usando datos de actividad fallback:", fallbackData);
    return fallbackData;
  } catch (error) {
    console.error('Error inesperado en getActivityChartData:', error);
    // Asegurar que siempre devolvemos algo, incluso en caso de error
    return [
      { time: '00:00', messages: 5, ml: 3, api: 1 },
      { time: '04:00', messages: 7, ml: 4, api: 2 },
      { time: '08:00', messages: 9, ml: 5, api: 3 },
      { time: '12:00', messages: 11, ml: 6, api: 3 },
      { time: '16:00', messages: 13, ml: 7, api: 4 },
      { time: '20:00', messages: 15, ml: 8, api: 5 }
    ];
  }
};

const [activityData, setActivityData] = useState(() => {
// Generar datos iniciales para el gráfico
return [
  { time: '00:00', messages: 5, ml: 3, api: 1 },
  { time: '04:00', messages: 7, ml: 4, api: 2 },
  { time: '08:00', messages: 9, ml: 5, api: 3 },
  { time: '12:00', messages: 11, ml: 6, api: 3 },
  { time: '16:00', messages: 13, ml: 7, api: 4 },
  { time: '20:00', messages: 15, ml: 8, api: 5 }
];
});

// Efecto para cargar datos de actividad
useEffect(() => {
  // Función para cargar datos
  const loadActivityData = async () => {
    const data = await getActivityChartData();
    setActivityData(data);
  };
  
  // Cargar datos iniciales
  loadActivityData();
  
  // Actualizar cada minuto si el bot está activo
  let interval: NodeJS.Timeout | null = null;
  if (botConfig.isActive) {
    interval = setInterval(loadActivityData, 15000); // Reducir a 15 segundos para ver cambios más rápido
  }
  
  return () => {
    if (interval) {
      clearInterval(interval);
    }
  };
}, [botConfig.isActive, metrics.messagesProcessed, metrics.mlQueries, metrics.apiQueries]);

// Función de respaldo para generar datos simulados (solo si falla la API)
const generateFallbackActivityData = () => {
  const now = new Date();
  // Generar datos consistentes basados en la hora actual
  const seed = now.getDate() + now.getMonth() * 31;
  
  // Valores base mínimos más significativos para asegurar variación visible
  const MIN_BASE_MESSAGES = 5;
  const MIN_BASE_ML = 3;
  const MIN_BASE_API = 2;
  
  return Array.from({length: 6}, (_, i) => {
    const hour = new Date(now);
    hour.setHours(now.getHours() - (5 - i));
    
    // Usar valores reales de métricas como base, pero con mínimos significativos
    const baseMessages = Math.max(MIN_BASE_MESSAGES, metrics.messagesProcessed / 6);
    const baseML = Math.max(MIN_BASE_ML, metrics.mlQueries / 6);
    const baseAPI = Math.max(MIN_BASE_API, metrics.apiQueries / 6);
    
    // Factor de crecimiento que aumenta hacia las horas más recientes (de 0.6 a 1.4)
    const growthFactor = 0.6 + (i * 0.16);
    
    // Usar un algoritmo determinista para generar variaciones más pronunciadas
    const hourSeed = seed + hour.getHours();
    const messageVar = Math.sin(hourSeed * 0.3) * baseMessages * 0.7 * growthFactor;
    const mlVar = Math.cos(hourSeed * 0.5) * baseML * 0.6 * growthFactor;
    const apiVar = Math.sin(hourSeed * 0.7) * baseAPI * 0.5 * growthFactor;
    
    return {
      time: hour.getHours() + ':00',
      messages: Math.max(1, Math.round(baseMessages * growthFactor + messageVar)),
      ml: Math.max(1, Math.round(baseML * growthFactor + mlVar)),
      api: Math.max(0, Math.round(baseAPI * growthFactor + apiVar))
    };
  });
};

return (
  <>
    <WidgetsSectionTitle
      title="MyBrain Bot ML Training Integration"
      subtitle="Gestión y monitoreo de entrenamiento ML"
      icon={faRobot}
      className="my-5"
    />
    <div className="p-4">
      {/* Bot Status and Controls *_/}
      <Row className="mb-4">
        <Col md={3}>
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <p className="text-muted mb-1">Estado del Bot</p>
                  <h3 className="mb-0">
                    {botConfig.isActive ? 
                      <span className="text-success">Activo</span> : 
                      <span className="text-danger">Inactivo</span>
                    }
                  </h3>
                  {botConfig.isActive && (
                    <small className="text-muted">Conectado como {currentNick}</small>
                  )}
                  {connectionErrors.length > 0 && (
                    <div className="mt-2">
                      <small className="text-danger">
                        {reconnectAttempts > 0 && `Intentos de reconexión: ${reconnectAttempts}`}
                      </small>
                    </div>
                  )}
                </div>
                <Bot size={24} className={botConfig.isActive ? 'text-success' : 'text-danger'} />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <p className="text-muted mb-1">Mensajes</p>
                  <h3 className="mb-0">{metrics.messagesProcessed}</h3>
                  <small className="text-muted">Tiempo: {formatUptime(metrics.uptime)}</small>
                </div>
                <MessageSquare size={24} className="text-primary" />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <p className="text-muted mb-1">Consultas ML</p>
                  <h3 className="mb-0">{metrics.mlQueries}</h3>
                  <div className="d-flex align-items-center">
                    <Badge bg={botConfig.preferML ? "primary" : "secondary"} className="me-1">
                      {botConfig.preferML ? "Prioridad Alta" : "Normal"}
                    </Badge>
                  </div>
                </div>
                <Brain size={24} className="text-info" />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <p className="text-muted mb-1">APIs Externas</p>
                  <h3 className="mb-0">{metrics.apiQueries}</h3>
                  <small className="text-muted">
                    {botConfig.apis.filter(api => api.enabled).length} activas
                  </small>
                </div>
                <Globe size={24} className="text-warning" />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mb-4">
        {/* Configuración del Bot *_/}
        <Col md={6}>
          <Card>
            <Card.Header className="d-flex justify-content-between align-items-center">
              <h5 className="mb-0">BOT Configuration</h5>
              <Button 
                variant="outline-secondary" 
                size="sm"
                onClick={() => setShowApiConfig(!showApiConfig)}
              >
                {showApiConfig ? "BOT Configuration" : "APIs Configuration"}
              </Button>
            </Card.Header>
            <Card.Body>
              {!showApiConfig ? (
                <Form>
                  {/*<Row>
                    <Col md={8}>
                      <Form.Group className="mb-3">
                        <Form.Label>Servidor IRC</Form.Label>
                        <Form.Control
                          type="text"
                          value="irc.linuxcorp.cl" //{botConfig.server}
                          onChange={(e) => setBotConfig(prev => ({ ...prev, server: e.target.value }))}
                          disabled={true} //{botConfig.isActive}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>Puerto</Form.Label>
                        <Form.Control
                          type="number"
                          value="6667" //{botConfig.port}
                          onChange={(e) => setBotConfig(prev => ({ ...prev, port: parseInt(e.target.value) || 6667 }))}
                          disabled={true} //{botConfig.isActive}
                        />
                      </Form.Group>
                    </Col>
                  </Row>*_/}
                  <Form.Group className="mb-3">
                    <Form.Label>Nickname del Bot</Form.Label>
                    <Form.Control
                      type="text"
                      value={botConfig.nickname}
                      onChange={(e) => setBotConfig(prev => ({ ...prev, nickname: e.target.value }))}
                      disabled={botConfig.isActive}
                    />
                    <Form.Text className="text-muted">
                      Nicknames alternativos: {botConfig.alternativeNicks.join(', ')}
                    </Form.Text>
                  </Form.Group>
                  {/*<Form.Group className="mb-3">
                    <Form.Label>Contraseña del Servidor (opcional)</Form.Label>
                    <Form.Control
                      type="password"
                      value={botConfig.password || ''}
                      onChange={(e) => setBotConfig(prev => ({ ...prev, password: e.target.value }))}
                      placeholder="Dejar en blanco si no se requiere"
                      disabled={botConfig.isActive}
                    />
                  </Form.Group>*_/}
                  <Form.Group className="mb-3">
                    <Form.Check 
                      type="switch"
                      id="ssl-switch"
                      label="Usar conexión SSL/TLS"
                      checked={botConfig.useSSL}
                      onChange={(e) => setBotConfig(prev => ({ ...prev, useSSL: e.target.checked }))}
                      disabled={botConfig.isActive}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Endpoint ML</Form.Label>
                    <Form.Control
                      type="text"
                      value={botConfig.mlEndpoint}
                      onChange={(e) => setBotConfig(prev => ({ ...prev, mlEndpoint: e.target.value }))}
                      disabled={true} //{botConfig.isActive}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Check 
                      type="switch"
                      id="ml-priority-switch"
                      label="Priorizar modelo ML sobre APIs"
                      checked={botConfig.preferML}
                      onChange={(e) => setBotConfig(prev => ({ ...prev, preferML: e.target.checked }))}
                      disabled={botConfig.isActive}
                    />
                    <Form.Text className="text-muted">
                      Si está activado, se priorizarán las respuestas del modelo ML. Caso contrario, se combinarán respuestas de ML y APIs.
                    </Form.Text>
                  </Form.Group>
                  <div className="d-flex gap-2">
                    <Button
                      variant={botConfig.isActive ? "danger" : "success"}
                      onClick={botConfig.isActive ? handleStopBot : handleStartBot}
                      disabled={loading}
                    >
                      {loading ? (
                        <><Spinner as="span" animation="border" size="sm" /> Cargando...</>
                      ) : (
                        botConfig.isActive ? "Detener Bot" : "Iniciar Bot"
                      )}
                    </Button>
                    
                    {botConfig.isActive && (
                      <Button
                        variant={autoResponseEnabled ? "warning" : "outline-secondary"}
                        onClick={toggleAutoResponse}
                        disabled={!botConfig.isActive}
                        className="ms-2"
                      >
                        {autoResponseEnabled ? "Deshabilitar Auto-Respuesta" : "Habilitar Auto-Respuesta"}
                      </Button>
                    )}
                    
                    {!botConfig.isActive && (
                      <Button
                        variant="outline-info"
                        onClick={handleDiagnoseConnection}
                        disabled={loading}
                      >
                        Diagnosticar Conexión
                      </Button>
                    )}
                  </div>
                </Form>
              ) : (
                // Panel de configuración de APIs
                <div>
                  <h6>Integración con APIs Externas</h6>
                  <p className="text-muted small">Configure las APIs externas que el bot utilizará para obtener información en tiempo real.</p>
                  
                  {botConfig.apis.map((api, index) => (
                    <Card key={index} className="mb-3">
                      <Card.Body>
                        <Row>
                          <Col md={6}>
                            <Form.Group className="mb-2">
                              <Form.Label>Nombre</Form.Label>
                              <Form.Control
                                type="text"
                                value={api.name}
                                onChange={(e) => handleApiConfigChange(index, 'name', e.target.value)}
                                disabled={botConfig.isActive}
                                size="sm"
                              />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-2">
                              <Form.Label>Endpoint</Form.Label>
                              <Form.Control
                                type="text"
                                value={api.endpoint}
                                onChange={(e) => handleApiConfigChange(index, 'endpoint', e.target.value)}
                                disabled={botConfig.isActive}
                                size="sm"
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <Form.Group className="mb-2">
                              <Form.Label>API Key</Form.Label>
                              <Form.Control
                                type="password"
                                value={api.apiKey || ''}
                                onChange={(e) => handleApiConfigChange(index, 'apiKey', e.target.value)}
                                disabled={botConfig.isActive}
                                size="sm"
                              />
                            </Form.Group>
                          </Col>
                          <Col md={4}>
                            <Form.Group className="mb-2">
                              <Form.Label>Prioridad</Form.Label>
                              <Form.Control
                                type="number"
                                value={api.priority}
                                onChange={(e) => handleApiConfigChange(index, 'priority', parseInt(e.target.value) || 5)}
                                disabled={botConfig.isActive}
                                size="sm"
                                min={1}
                                max={10}
                              />
                            </Form.Group>
                          </Col>
                          <Col md={2} className="d-flex align-items-end mb-2">
                            <Form.Check 
                              type="switch"
                              id={`api-enabled-${index}`}
                              label="Activa"
                              checked={api.enabled}
                              onChange={(e) => handleApiConfigChange(index, 'enabled', e.target.checked)}
                              disabled={botConfig.isActive}
                            />
                          </Col>
                        </Row>
                        <div className="d-flex justify-content-end">
                          <Button
                            variant="outline-danger"
                            size="sm"
                            onClick={() => handleRemoveApi(index)}
                            disabled={botConfig.isActive || botConfig.apis.length <= 1}
                          >
                            Eliminar API
                          </Button>
                        </div>
                      </Card.Body>
                    </Card>
                  ))}
                  
                  <div className="d-flex justify-content-between mt-3">
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={handleAddApi}
                      disabled={botConfig.isActive}
                    >
                      Añadir Nueva API
                    </Button>
                    
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      onClick={() => setShowApiConfig(false)}
                    >
                      Volver a Configuración IRC
                    </Button>
                  </div>
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>

        {/* Consola IRC *_/}
        <Col md={6}>
          <Card>
            <Card.Header>
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="mb-0">Learning Console</h5>
                <Form.Select
                  style={{ width: 'auto' }}
                  value={selectedChannel}
                  onChange={(e) => setSelectedChannel(e.target.value)}
                >
                  {botConfig.channels.map(channel => (
                    <option key={channel} value={channel}>{channel}</option>
                  ))}
                </Form.Select>
              </div>
            </Card.Header>
            <Card.Body>
              <div className="chat-container mb-3" style={{ height: '300px', overflowY: 'auto' }}>
                {messages
                 .filter(msg => msg.type === 'system' || msg.channel === selectedChannel)
                 .map((message, index) => (
                   <div
                     key={`msg-${message.id}-${index}`}
                     className={`mb-2 ${
                       message.type === 'system' ? 
                         (message.text.includes('Error') ? 'text-danger' : 'text-muted') :
                       message.type === 'command' ? 'text-primary' : ''
                     }`}
                   >
                     <small>{new Date(message.timestamp).toLocaleTimeString()}</small>
                     <span className="mx-2 fw-bold">{message.sender}:</span>
                     <span>
                       {message.text}
                       {message.source && message.source !== 'user' && (
                         <Badge 
                           bg={
                             message.source === 'ml' ? 'info' :
                             message.source === 'api' ? 'warning' : 'secondary'
                           }
                           className="ms-2"
                         >
                           {message.source === 'ml' ? 'ML' :
                            message.source === 'api' ? 'API' : 'IRC'}
                         </Badge>
                       )}
                     </span>
                   </div>
                 ))}
                 <div ref={messagesEndRef} />
              </div>
              <Form className="d-flex gap-2" onSubmit={(e) => { e.preventDefault(); handleSendCommand(); }}>
                <Form.Control
                  type="text"
                  value={commandInput}
                  onChange={(e) => setCommandInput(e.target.value)}
                  placeholder="Escribe un comando o mensaje (/help para ayuda)"
                  disabled={!botConfig.isActive || loading}
                />
                <Button
                  variant="primary"
                  onClick={handleSendCommand}
                  disabled={!botConfig.isActive || loading || !commandInput.trim()}
                >
                  <Send size={20} />
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Datos de Actividad *_/}
      <Row>
        <Col>
        <Card>
  <Card.Header>
    <h5 className="mb-0">Actividad del Bot</h5>
  </Card.Header>
  <Card.Body>
    <div style={{ height: '300px' }}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="time" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="messages"
            stroke="#0d6efd"
            name="Mensajes Totales"
          />
          <Line
            type="monotone"
            dataKey="ml"
            stroke="#17a2b8"
            name="Consultas ML"
          />
          <Line
            type="monotone"
            dataKey="api"
            stroke="#ffc107"
            name="Consultas API"
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  </Card.Body>
</Card>
        </Col>
      </Row>
    </div>
  </>
);
};

export default MybrainBotDashboard; */

/*
// Declaraciones globales
declare global {
  interface Window {
    reconnectHandler: NodeJS.Timeout | undefined;
  }
}

// Initialize the property
if (typeof window !== 'undefined') {
  window.reconnectHandler = undefined;
}

import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Button, Card, Col, Form, Row, Table, Badge, Spinner } from "react-bootstrap";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { MessageSquare, Send, Server, Bot, Activity, Database, Globe, Brain } from 'lucide-react';
import WidgetsSectionTitle from '../widgets/WidgetsSectionTitle';
import { faRobot } from '@fortawesome/free-solid-svg-icons';
import ircService, { IRCConfig, IRCMessage } from '../../../service/ircService';

// Interfaces
interface MessageIntent {
  type: 'direct_command' | 'ml_query' | 'api_query' | 'hybrid' | 'default';
  apiType?: string;
  context?: string;
  confidence?: number;
}

interface ProxyConfig {
  enabled: boolean;
  endpoint: string;
  authToken?: string;
}

interface ApiConfig {
  name: string;
  enabled: boolean;
  endpoint: string;
  apiKey?: string;
  priority: number;
}

interface BotConfig {
  server: string;
  port: number;
  nickname: string;
  alternativeNicks: string[];
  channels: string[];
  mlEndpoint: string;
  isActive: boolean;
  useSSL?: boolean;
  password?: string;
  proxy: ProxyConfig;
  apis: ApiConfig[];
  preferML: boolean;
}

interface BotMetrics {
  messagesProcessed: number;
  activeChannels: number;
  uptime: number;
  lastTraining: string;
  mlQueries: number;
  apiQueries: number;
}

interface ActivityHistory {
  timestamps: string[];
  messages: number[];
  ml: number[];
  api: number[];
}

const IRCBotDashboard: React.FC = () => {
  // State Declarations
  const [messages, setMessages] = useState<IRCMessage[]>([]);
  const [botConfig, setBotConfig] = useState<BotConfig>(() => {
    // Try to load configuration from localStorage
    const savedConfig = localStorage.getItem('ircBotConfig');
    return savedConfig ? JSON.parse(savedConfig) : {
      server: 'irc.linuxcorp.cl',
      port: 6667,
      nickname: 'MyBot',
      alternativeNicks: ['AnubisBot', 'MLBot', 'TrainerBot', 'AnubisAssistant'],
      channels: ['#training', '#support', '#general'],
      mlEndpoint: 'http://10.10.5.1:5000/train',
      isActive: false,
      useSSL: false,
      proxy: {
        enabled: true,
        endpoint: 'https://localhost:3456/api',
        authToken: ''
      },
      apis: [
        { 
          name: 'Clima',
          enabled: true,
          endpoint: 'https://api.openweathermap.org/data/2.5/weather',
          apiKey: '',
          priority: 2
        },
        { 
          name: 'Noticias',
          enabled: true,
          endpoint: 'https://newsapi.org/v2/everything',
          apiKey: '',
          priority: 3
        }
      ],
      preferML: true
    };
  });
  
  // Improved Metrics State with Better Update Mechanism
  const [metrics, setMetrics] = useState<BotMetrics>({
    messagesProcessed: 0,
    activeChannels: 0,
    uptime: 0,
    lastTraining: new Date().toISOString(),
    mlQueries: 0,
    apiQueries: 0
  });

  // Improved Activity History State
  const [activityHistory, setActivityHistory] = useState<ActivityHistory>({
    timestamps: [],
    messages: [],
    ml: [],
    api: []
  });

  
  const [selectedChannel, setSelectedChannel] = useState<string>('#training');
  const [commandInput, setCommandInput] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [currentNick, setCurrentNick] = useState<string>(botConfig.nickname);
  const [connectionErrors, setConnectionErrors] = useState<string[]>([]);
  const [reconnectAttempts, setReconnectAttempts] = useState<number>(0);
  const [showApiConfig, setShowApiConfig] = useState<boolean>(false);
  // Añadir estado para el modo automático
  const [autoResponseEnabled, setAutoResponseEnabled] = useState<boolean>(false);
  
  // Referencia al contenedor de mensajes para auto-scroll
  const messagesEndRef = useRef<HTMLDivElement>(null);

    // Utility function to get current time as string
    const getCurrentTimeString = useCallback(() => {
      const now = new Date();
      return `${now.getHours().toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}`;
    }, []);

        // Estructura para almacenar métricas históricas
const activityMetrics = {
  // Array circular de métricas por hora (24 horas)
  hourly: Array(24).fill(null).map(() => ({
    messages: 0,
    ml: 0,
    api: 0,
    timestamp: new Date().toISOString()
  })),
  // Métricas de la hora actual
  current: {
    messages: 0,
    ml: 0,
    api: 0,
    timestamp: new Date().toISOString()
  },
  lastUpdateHour: new Date().getHours()
};

// Improved Metrics Update Function
const updateMetrics = useCallback((updateFn: (prevMetrics: BotMetrics) => BotMetrics) => {
  setMetrics(prevMetrics => {
    const newMetrics = updateFn(prevMetrics);
    
    // Update activity history whenever metrics change
    setActivityHistory(prevHistory => {
      const currentTime = getCurrentTimeString();
      
      // Check if the current timestamp already exists
      if (prevHistory.timestamps.length > 0 && 
          prevHistory.timestamps[prevHistory.timestamps.length - 1] === currentTime) {
        // Update the last entry
        const updatedTimestamps = [...prevHistory.timestamps];
        const updatedMessages = [...prevHistory.messages];
        const updatedMl = [...prevHistory.ml];
        const updatedApi = [...prevHistory.api];
        
        const lastIndex = updatedTimestamps.length - 1;
        updatedMessages[lastIndex] = newMetrics.messagesProcessed;
        updatedMl[lastIndex] = newMetrics.mlQueries;
        updatedApi[lastIndex] = newMetrics.apiQueries;
        
        return {
          timestamps: updatedTimestamps,
          messages: updatedMessages,
          ml: updatedMl,
          api: updatedApi
        };
      }
      
      // Add a new entry if timestamp is different
      return {
        timestamps: [...prevHistory.timestamps, currentTime].slice(-6),
        messages: [...prevHistory.messages, newMetrics.messagesProcessed].slice(-6),
        ml: [...prevHistory.ml, newMetrics.mlQueries].slice(-6),
        api: [...prevHistory.api, newMetrics.apiQueries].slice(-6)
      };
    });
    
    return newMetrics;
  });
}, [getCurrentTimeString]);

  // Helper function to determine response source
  const determineResponseSource = useCallback((message: IRCMessage): 'ml' | 'api' | 'irc' => {
    // Implement logic to determine response source
    if (message.text.includes('ml-specific-keyword')) return 'ml';
    if (message.text.includes('api-specific-keyword')) return 'api';
    return 'irc';
  }, []);
  
  // Efecto para inicializar los listeners del servicio IRC
  useEffect(() => {
    const messageUnsubscribe = ircService.onMessage((message) => {
      // Procesar mensajes entrantes con ML e integración API
      if (message.from !== 'System' && message.from !== currentNick) {
        processIncomingMessage({
          id: Date.now() * 1000 + Math.floor(Math.random() * 1000), // ID único
          text: message.text,
          sender: message.from || 'Unknown',
          channel: message.channel || 'system',
          timestamp: message.timestamp,
          type: 'message',
          source: 'user'
        });
      } else {
        // Mensajes de sistema o propios
        const ircMessage: IRCMessage = {
          id: Date.now() * 1000 + Math.floor(Math.random() * 1000), // ID único
          text: message.text,
          sender: message.from || 'Unknown',
          channel: message.channel || 'system',
          timestamp: message.timestamp,
          type: 'message',
          source: 'irc'
        };
        setMessages(prev => [...prev, ircMessage]);
        
        // Incrementar contador de mensajes
        setMetrics(prev => ({
          ...prev,
          messagesProcessed: prev.messagesProcessed + 1
        }));
      }
    });

    const eventUnsubscribe = ircService.onEvent((event) => {
      addSystemMessage(event.text);
    });

    const errorUnsubscribe = ircService.onError((error) => {
      addSystemMessage(error.text, 'error');
      setConnectionErrors(prev => [...prev, error.text]);
    });

    const connectionUnsubscribe = ircService.onConnectionChange((connected) => {
      setBotConfig(prev => ({ ...prev, isActive: connected }));
      if (connected) {
        addSystemMessage('Conexión IRC establecida correctamente');
      } else {
        addSystemMessage('Conexión IRC cerrada');
      }
    });

    // Iniciar contador de uptime si el bot está activo
    let uptimeInterval: NodeJS.Timeout | null = null;
    if (botConfig.isActive) {
      uptimeInterval = setInterval(() => {
        setMetrics(prev => ({
          ...prev,
          uptime: prev.uptime + 1
        }));
      }, 1000);
    }

    // Limpiar suscripciones al desmontar
    return () => {
      messageUnsubscribe();
      eventUnsubscribe();
      errorUnsubscribe();
      connectionUnsubscribe();
      
      if (uptimeInterval) {
        clearInterval(uptimeInterval);
      }
      
      // Desconectar el IRC si el componente se desmonta mientras está conectado
      if (botConfig.isActive) {
        ircService.disconnect().catch(console.error);
      }
    };
  }, [botConfig.isActive, currentNick]);

  // Auto-scroll cuando hay nuevos mensajes
  /* useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]); *_/
  useEffect(() => {
    if (messagesEndRef.current) {
      // Obtiene el elemento contenedor de mensajes (chat-container)
      const chatContainer = messagesEndRef.current.closest('.chat-container');
      
      if (chatContainer) {
        // Hacer scroll solo dentro del contenedor de chat
        chatContainer.scrollTop = chatContainer.scrollHeight;
      }
    }
  }, [messages])

  const handleStartBot = async () => {
    setLoading(true);
    setConnectionErrors([]);
    setReconnectAttempts(0);

    // Modo de simulación para pruebas (elimina esto cuando el backend esté listo)
    const simulationMode = false; // Cambia a false cuando quieras usar el backend real

    if (simulationMode) {
      try {
        addSystemMessage(`Iniciando en modo de simulación...`);
        await new Promise(resolve => setTimeout(resolve, 800));
        
        addSystemMessage(`Conectado a ${botConfig.server}:${botConfig.port} (simulado)`);
        setBotConfig(prev => ({ ...prev, isActive: true }));
        setCurrentNick(botConfig.nickname);
        
        // Simular una conexión exitosa
        addSystemMessage(`Unido a canales: ${botConfig.channels.join(', ')}`);
        
        // Mensaje de bienvenida simulado
        setTimeout(() => {
          const welcomeMsg: IRCMessage = {
            id: Date.now() * 1000 + Math.floor(Math.random() * 1000),
            text: `Bienvenido a ${selectedChannel}! Estoy en modo de simulación para desarrollo.`,
            sender: 'IRCServer',
            channel: selectedChannel,
            timestamp: new Date().toISOString(),
            type: 'message',
            source: 'irc'
          };
          setMessages(prev => [...prev, welcomeMsg]);
        }, 1500);
        
        // Actualizar métricas
        setMetrics(prev => ({
          ...prev,
          activeChannels: botConfig.channels.length,
          uptime: 0,
          mlQueries: 0,
          apiQueries: 0
        }));
        
        setLoading(false);
        return;
      } catch (error: any) {
        // Continuar con el método normal si hay algún error
      }
    }

    try {
      addSystemMessage(`Iniciando diagnóstico de conexión a ${botConfig.server}:${botConfig.port}...`);
      
      addSystemMessage(`Configurando proxy de conexión IRC en el backend...`);
      
      // Usar la nueva función de reconexión con nicknames alternativos
      const connectionConfig: IRCConfig = {
        server: botConfig.server,
        port: botConfig.port,
        nickname: botConfig.nickname,
        alternativeNicks: botConfig.alternativeNicks,
        channels: botConfig.channels,
        useSSL: botConfig.useSSL,
        password: botConfig.password
      };
      
      // Intentar conexión a través del servicio con soporte para nicknames alternativos
      const connectionId = await ircService.reconnectWithAlternativeNick(connectionConfig);
      
      // Consultar al servicio para obtener el nickname que realmente se asignó
      const connectionStatus = await ircService.checkConnection(connectionId);
      if (connectionStatus.nickname) {
        setCurrentNick(connectionStatus.nickname);
      } else {
        setCurrentNick(botConfig.nickname);
      }
      
      // Verificar estado de auto-respuesta
      if (connectionStatus.autoResponse !== undefined) {
        setAutoResponseEnabled(connectionStatus.autoResponse);
      }
      
      addSystemMessage(`Conexión establecida con ID: ${connectionId}`);
      
      // Inicializar sistemas de ML y API
      addSystemMessage(`Preparando integración con sistema ML en ${botConfig.mlEndpoint}`);
      addSystemMessage(`Configurando ${botConfig.apis.filter(api => api.enabled).length} APIs externas`);
      
      // Actualizar métricas
      setMetrics(prev => ({
        ...prev,
        activeChannels: botConfig.channels.length,
        uptime: 0,
        mlQueries: 0,
        apiQueries: 0
      }));
      
      // Configurar manejador de reconexión automática
      setupAutoReconnect(connectionConfig);
      
    } catch (error: any) {
      addSystemMessage(`Error al iniciar el bot: ${error.message}`, 'error');
      setConnectionErrors(prev => [...prev, error.message]);
    } finally {
      setLoading(false);
    }
  };

  // Método para cambiar el modo de auto-respuesta
  const toggleAutoResponse = async () => {
    if (!ircService.connectionId) return;
    
    try {
      //const response = await fetch(`${botConfig.proxy.endpoint}/irc/autoresponse`, {
      const response = await fetch(`https://backend.anubisai.net/api/mybrain/autoresponse`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          connectionId: ircService.connectionId,
          enabled: !autoResponseEnabled
        })
      });
      
      const data = await response.json();
      
      if (data.success) {
        setAutoResponseEnabled(!autoResponseEnabled);
        addSystemMessage(`Respuesta automática ${!autoResponseEnabled ? 'habilitada' : 'deshabilitada'}`);
      } else {
        addSystemMessage(`Error al configurar respuesta automática: ${data.message}`, 'error');
      }
    } catch (error: any) {
      addSystemMessage(`Error de conexión: ${error.message}`, 'error');
    }
  };

  // Función de reconexión automática
  const setupAutoReconnect = (config: IRCConfig) => {
    // Limpiar cualquier manejador de reconexión previo
    if (window.reconnectHandler !== undefined) {
      clearInterval(window.reconnectHandler);
      window.reconnectHandler = undefined;
    }
    
    // Establecer un intervalo para verificar la conexión periódicamente
    window.reconnectHandler = setInterval(async () => {
      if (!botConfig.isActive) {
        if (window.reconnectHandler !== undefined) {
          clearInterval(window.reconnectHandler);
          window.reconnectHandler = undefined;
        }
        return;
      }
      
      try {
        if (ircService.connectionId) {
          const status = await ircService.checkConnection(ircService.connectionId);
          if (!status.active) {
            setReconnectAttempts(prev => prev + 1);
            addSystemMessage(`Conexión perdida. Intento de reconexión #${reconnectAttempts + 1}...`);
            
            // Intentar reconexión con nickname alternativo
            const connectionId = await ircService.reconnectWithAlternativeNick(config, reconnectAttempts);
            
            // Actualizar nickname si cambió
            const connectionStatus = await ircService.checkConnection(connectionId);
            if (connectionStatus.nickname) {
              setCurrentNick(connectionStatus.nickname);
            }
            
            addSystemMessage(`Reconexión exitosa con nickname: ${currentNick}`);
          }
        }
      } catch (error: any) {
        addSystemMessage(`Error en reconexión automática: ${error.message}`, 'error');
        setConnectionErrors(prev => [...prev, error.message]);
        
        // Si hay muchos intentos fallidos, detener los intentos
        if (reconnectAttempts > 5) {
          if (window.reconnectHandler !== undefined) {
            clearInterval(window.reconnectHandler);
            window.reconnectHandler = undefined;
          }
          addSystemMessage('Demasiados intentos de reconexión fallidos. Deteniendo reconexión automática.', 'error');
        }
      }
    }, 30000); // Verificar cada 30 segundos
  };

  // Función para corregir una respuesta del bot
const handleCorrectBotResponse = async (originalMessage: string, correctResponse: string) => {
  if (!ircService.connectionId) return;
  
  try {
    // Enviar datos de entrenamiento
    const response = await fetch(`${botConfig.proxy.endpoint}/irc/train`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        connectionId: ircService.connectionId,
        entity_type: 'channel',
        entity_id: selectedChannel.replace('#', ''),
        data: [{
          input: originalMessage,
          output: correctResponse
        }]
      })
    });
    
    const data = await response.json();
    
    if (data.success) {
      addSystemMessage(`Respuesta aprendida correctamente para: "${originalMessage.substring(0, 30)}..."`);
    } else {
      addSystemMessage(`Error al entrenar respuesta: ${data.message}`, 'error');
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      addSystemMessage(`Error de conexión: ${error.message}`, 'error');
    } else {
      addSystemMessage(`Error de conexión desconocido`, 'error');
    }
  }
};

  const handleStopBot = async () => {
    setLoading(true);
    addSystemMessage(`Iniciando proceso de desconexión...`);
    
    try {
      // No usamos simulación, vamos directo a la implementación real
      addSystemMessage(`Desconectando de ${botConfig.server}:${botConfig.port}`);
      
      // Implementar un timeout para la desconexión 
      const disconnectWithTimeout = async () => {
        try {
          // Establecer un timeout de 5 segundos para la desconexión
          const timeoutPromise = new Promise<boolean>((_, reject) => {
            setTimeout(() => reject(new Error('Timeout de desconexión')), 5000);
          });
          
          // Intentar desconectar con timeout
          return await Promise.race([
            ircService.disconnect(),
            timeoutPromise
          ]);
        } catch (error) {
          console.error("Error o timeout durante desconexión:", error);
          return false;
        }
      };
      
      // Intentar desconectar
      const disconnectResult = await disconnectWithTimeout();
      
      if (disconnectResult) {
        addSystemMessage('Bot detenido correctamente');
      } else {
        addSystemMessage('No se pudo completar la desconexión remota. Se actualizará el estado local.', 'warning');
      }
    } catch (error: any) {
      console.error("Error general al detener bot:", error);
      addSystemMessage(`Error al detener el bot: ${error.message}`, 'error');
    } finally {
      // Siempre actualizar el estado local, independientemente del resultado
      setBotConfig(prev => ({ ...prev, isActive: false }));
      setCurrentNick(botConfig.nickname);
      setConnectionErrors([]);
      setReconnectAttempts(0);
      setAutoResponseEnabled(false);
      
      // Limpiar intervalo de reconexión
      if (window.reconnectHandler !== undefined) {
        clearInterval(window.reconnectHandler);
        window.reconnectHandler = undefined;
      }
      
      // Reiniciar métricas
      setMetrics(prev => ({
        ...prev,
        uptime: 0
      }));
      
      setLoading(false);
    }
  };

  const handleSendCommand = async () => {
    if (!commandInput.trim() || !botConfig.isActive) return;
    
    const newMessage: IRCMessage = {
      id: Date.now() * 1000 + Math.floor(Math.random() * 1000),
      text: commandInput,
      sender: 'Admin',
      channel: selectedChannel,
      timestamp: new Date().toISOString(),
      type: 'command',
      source: 'user'
    };

    setMessages(prev => [...prev, newMessage]);
    
    // Modo de simulación (quitar cuando el backend esté listo)
    const simulationMode = false;
    
    if (simulationMode) {
      // Procesar comandos especiales
      if (commandInput.startsWith('/')) {
        if (commandInput.startsWith('/join ')) {
          const channel = commandInput.slice(6).trim();
          if (!botConfig.channels.includes(channel)) {
            setBotConfig(prev => ({
              ...prev,
              channels: [...prev.channels, channel]
            }));
            setSelectedChannel(channel);
            addSystemMessage(`Unido a canal: ${channel}`);
          }
        } else if (commandInput.startsWith('/nick ')) {
          const newNick = commandInput.slice(6).trim();
          setCurrentNick(newNick);
          addSystemMessage(`Nickname cambiado a ${newNick}`);
        } else if (commandInput.startsWith('/help')) {
          addSystemMessage('Comandos disponibles:');
          addSystemMessage('/join #canal - Unirse a un nuevo canal');
          addSystemMessage('/train - Entrenar el modelo ML con datos del canal');
          addSystemMessage('/status - Mostrar estado del bot');
          addSystemMessage('/apis - Mostrar estado de las APIs configuradas');
          addSystemMessage('/ml - Mostrar estadísticas del sistema ML');
          addSystemMessage('/nick nombre - Cambiar nickname');
          addSystemMessage('/auto on|off - Habilitar/deshabilitar respuestas automáticas');
          addSystemMessage('/help - Mostrar este mensaje de ayuda');
        } else if (commandInput.startsWith('/train')) {
          addSystemMessage(`Iniciando entrenamiento ML con datos de ${selectedChannel}`);
          setTimeout(() => {
            addSystemMessage('Entrenamiento ML en progreso...');
            setTimeout(() => {
              addSystemMessage('Entrenamiento completado. Modelo actualizado.');
              setMetrics(prev => ({
                ...prev,
                lastTraining: new Date().toISOString()
              }));
            }, 3000);
          }, 1000);
        } else if (commandInput.startsWith('/corregir ')) {
          // Formato esperado: /corregir "pregunta original" "respuesta correcta"
          const match = commandInput.match(/\/corregir\s+"([^"]+)"\s+"([^"]+)"/);
          
          if (match && match.length === 3) {
            const originalMessage = match[1];
            const correctResponse = match[2];
            
            await handleCorrectBotResponse(originalMessage, correctResponse);
          } else {
            addSystemMessage('Formato incorrecto. Uso: /corregir "pregunta original" "respuesta correcta"', 'error');
          }
          
          setCommandInput('');
          return;
        } else if (commandInput.startsWith('/apis')) {
          // Mostrar estado de las APIs
          addSystemMessage('APIs configuradas:');
          botConfig.apis.forEach(api => {
            addSystemMessage(`${api.name}: ${api.enabled ? 'Activa' : 'Inactiva'} - Prioridad: ${api.priority}`);
          });
        } else if (commandInput.startsWith('/ml')) {
          // Mostrar estadísticas de ML
          addSystemMessage(`Sistema ML: ${botConfig.mlEndpoint}`);
          addSystemMessage(`Consultas realizadas: ${metrics.mlQueries}`);
          addSystemMessage(`Último entrenamiento: ${new Date(metrics.lastTraining).toLocaleString()}`);
          addSystemMessage(`Prioridad ML: ${botConfig.preferML ? 'Alta' : 'Normal'}`);
        } else if (commandInput.startsWith('/status')) {
          addSystemMessage(`Estado del bot: ${botConfig.isActive ? 'Activo' : 'Inactivo'}`);
          addSystemMessage(`Conectado a: ${botConfig.server}:${botConfig.port}`);
          addSystemMessage(`Canales: ${botConfig.channels.join(', ')}`);
          addSystemMessage(`Tiempo en línea: ${formatUptime(metrics.uptime)}`);
        } else if (commandInput.startsWith('/auto')) {
          addSystemMessage('Uso: /auto on|off - Habilita o deshabilita respuestas automáticas');
        } else {
          addSystemMessage(`Comando ${commandInput} no reconocido. Usa /help para ver la lista de comandos.`);
        }
      } else {
        // Mensaje normal - procesar con ML/API
        processIncomingMessage(newMessage);
      }
      
      setCommandInput('');
      return;
    }
    
    // Código original para enviar comandos al backend real
    try {
      if (commandInput.startsWith('/')) {
        if (commandInput.startsWith('/auto')) {
          const enabled = commandInput.includes('on');
          const disabled = commandInput.includes('off');
          
          if (!enabled && !disabled) {
            addSystemMessage('Uso: /auto on|off - Habilita o deshabilita respuestas automáticas');
            setCommandInput('');
            return;
          }
          
          try {
            //const response = await fetch(`${botConfig.proxy.endpoint}/irc/autoresponse`, {
            const response = await fetch(`https://backend.anubisai.net/api/mybrain/autoresponse`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                connectionId: ircService.connectionId,
                enabled: enabled
              })
            });
            
            const data = await response.json();
            
            if (data.success) {
              setAutoResponseEnabled(enabled);
              addSystemMessage(`Respuesta automática ${enabled ? 'habilitada' : 'deshabilitada'}`);
            } else {
              addSystemMessage(`Error al configurar respuesta automática: ${data.message}`, 'error');
            }
          } catch (error: any) {
            addSystemMessage(`Error de conexión: ${error.message}`, 'error');
          }
          
          setCommandInput('');
          return;
        } else if (commandInput.startsWith('/train')) {
          addSystemMessage(`Iniciando entrenamiento ML con datos de ${selectedChannel}`);
          
          try {
            // Versión real (no simulada) 
            //const response = await fetch(`${botConfig.proxy.endpoint}/irc/train`, {
              const response = await fetch(`https://backend.anubisai.net/api/mybrain/train`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                connectionId: ircService.connectionId,
                channel: selectedChannel
              })
            });
            
            const data = await response.json();
            
            if (data.success) {
              addSystemMessage('Entrenamiento ML completado exitosamente');
              setMetrics(prev => ({
                ...prev,
                lastTraining: new Date().toISOString()
              }));
            } else {
              addSystemMessage(`Error en entrenamiento ML: ${data.message}`, 'error');
            }
          } catch (error: any) {
            addSystemMessage(`Error al iniciar entrenamiento ML: ${error.message}`, 'error');
          }
          
          setCommandInput('');
          return;
        }
        
        // Otros comandos IRC
        await ircService.sendMessage(selectedChannel, commandInput);
        
        // Procesar comandos especiales
        if (commandInput.startsWith('/join ')) {
          const channel = commandInput.slice(6).trim();
          if (!botConfig.channels.includes(channel)) {
            setBotConfig(prev => ({
              ...prev,
              channels: [...prev.channels, channel]
            }));
            setSelectedChannel(channel);
          }
        } else if (commandInput.startsWith('/nick ')) {
          const newNick = commandInput.slice(6).trim();
          setCurrentNick(newNick);
        } else if (commandInput.startsWith('/help')) {
          addSystemMessage('Comandos disponibles:');
          addSystemMessage('/join #canal - Unirse a un nuevo canal');
          addSystemMessage('/train - Entrenar el modelo ML con datos del canal');
          addSystemMessage('/status - Mostrar estado del bot');
          addSystemMessage('/apis - Mostrar estado de las APIs configuradas');
          addSystemMessage('/ml - Mostrar estadísticas del sistema ML');
          addSystemMessage('/nick nombre - Cambiar nickname');
          addSystemMessage('/auto on|off - Habilitar/deshabilitar respuestas automáticas');
          addSystemMessage('/help - Mostrar este mensaje de ayuda');
        }
      } else {
        // Mensaje normal
        await ircService.sendMessage(selectedChannel, commandInput);
      }
    } catch (error: any) {
      addSystemMessage(`Error al enviar mensaje: ${error.message}`, 'error');
    }
    
    setCommandInput('');
  };

  const addSystemMessage = (text: string, type: 'info' | 'error' | 'warning' = 'info') => {
    const systemMessage: IRCMessage = {
      id: Date.now() * 1000 + Math.floor(Math.random() * 1000),
      text,
      sender: 'System',
      channel: 'system',
      timestamp: new Date().toISOString(),
      type: 'system',
      source: 'irc'
    };
    setMessages(prev => [...prev, systemMessage]);
    
    // Registrar errores si corresponde
    if (type === 'error') {
      setConnectionErrors(prev => [...prev, text]);
    }
  };

  const handleDiagnoseConnection = async () => {
    addSystemMessage('Ejecutando diagnóstico de conexión...');
    
    // Test de conexión real al backend
    try {
      const backendUrl = ircService.connectionId 
        ? `${botConfig.proxy.endpoint.replace('/api', '')}/health-check`
        : `https://backend.anubisai.net/health-check`;
        
      addSystemMessage(`Verificando disponibilidad del backend: ${backendUrl}`);
      
      const response = await fetch(backendUrl);
      if (response.ok) {
        const data = await response.json();
        addSystemMessage(`Backend disponible. Estado: ${data.status}`, 'info');
        
        // Probar conexión WebSocket de Socket.IO
        testDirectWebSocket();
      } else {
        addSystemMessage(`El backend respondió con estado: ${response.status}`, 'error');
      }
    } catch (error: any) {
      addSystemMessage(`Error al verificar backend: ${error.message}`, 'error');
      addSystemMessage(`Comprobando disponibilidad de red básica...`);
      
      // Diagnósticos adicionales
      addSystemMessage(`Prueba de conexión a ${botConfig.server}:${botConfig.port}...`);
      addSystemMessage('Hay conectividad de red básica.');
      addSystemMessage(`Verificando accesibilidad del servidor IRC...`);
      addSystemMessage(`Nota: Los navegadores no pueden conectarse directamente a servidores IRC.`);
      addSystemMessage(`Se requiere un backend o proxy para establecer la conexión real.`);
    }
  };

  const testDirectWebSocket = () => {
    addSystemMessage("Probando conexión directa WebSocket a backend.anubisai.net...");
    
    try {
      // Primero probar con polling explícito
      const pollingUrl = 'https://backend.anubisai.net/socket.io/?EIO=4&transport=polling';
      addSystemMessage(`Probando polling primero: ${pollingUrl}`);
      
      fetch(pollingUrl)
        .then(response => {
          if (response.ok) {
            addSystemMessage(`Conexión polling exitosa! Estado: ${response.status}`, 'info');
            testWebSocketAfterPolling();
          } else {
            addSystemMessage(`Polling falló con estado: ${response.status}. Esto puede indicar un problema CORS.`, 'error');
          }
        })
        .catch(error => {
          addSystemMessage(`Error en polling: ${error.message}`, 'error');
        });
      
    } catch (error: any) {
      addSystemMessage(`Error en prueba de conexión: ${error.message}`, 'error');
    }
  };

  const testWebSocketAfterPolling = () => {
    try {
      addSystemMessage("Ahora probando conexión WebSocket...");
      const wsUrl = 'wss://backend.anubisai.net/socket.io/?EIO=4&transport=websocket';
      
      const ws = new WebSocket(wsUrl);
      
      ws.onopen = () => {
        addSystemMessage("Conexión WebSocket abierta exitosamente!", 'info');
        ws.close();
      };
      
      ws.onmessage = (event) => {
        addSystemMessage(`Mensaje recibido: ${event.data}`, 'info');
      };
      
      ws.onerror = (event) => {
        addSystemMessage("Error de WebSocket", 'error');
        console.error(event);
      };
      
      ws.onclose = (event) => {
        addSystemMessage(`WebSocket cerrado con código: ${event.code} - ${event.reason}`, 'info');
      };
    } catch (error: any) {
      addSystemMessage(`Error en prueba WebSocket: ${error.message}`, 'error');
    }
  };

// Función para formatear el tiempo de uptime
const formatUptime = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
};

// ----- FUNCIONES PARA INTEGRACIÓN DE ML Y API -----

// Función para analizar la intención del mensaje
const analyzeMessageIntent = async (text: string): Promise<MessageIntent> => {
  // Implementación simple basada en keywords para v0.1
  // En versiones futuras, esto podría usar un modelo de NLP más sofisticado
  if (text.startsWith('/')) {
    return { type: 'direct_command' };
  } else if (text.match(/clima|temperatura|lluvia|sol|pronóstico/i)) {
    return { type: 'api_query', apiType: 'weather', confidence: 0.8 };
  } else if (text.match(/noticias|noticia|últimas noticias|actualidad/i)) {
    return { type: 'api_query', apiType: 'news', confidence: 0.8 };
  } else if (text.match(/aprende|recuerda|dime|explícame|información sobre/i)) {
    return { type: 'ml_query', context: 'learning', confidence: 0.7 };
  } else {
    // Por defecto, intentar con ML primero si está configurado así
    return { type: botConfig.preferML ? 'ml_query' : 'hybrid', context: 'conversation', confidence: 0.5 };
  }
};

// Primero, definamos una interfaz para el tipo de datos del historial
interface ActivityHistory {
  timestamps: string[];
  messages: number[];
  ml: number[];
  api: number[];
}

 // Improved Message Processing Function
 const processIncomingMessage = useCallback(async (message: IRCMessage) => {
  // Add user message to context
  if (message.source !== 'user') {
    setMessages(prev => [...prev, message]);
  }
  
  if (!botConfig.isActive || message.sender === currentNick) return;
  
  // Skip IRC commands
  if (message.text.startsWith('/')) return;
  
  try {
    // Simulate brief processing time
    setTimeout(async () => {
      // Determine message intent and response source
      const intent = await analyzeMessageIntent(message.text);
      let responseSource = determineResponseSource(message);
      
      let responseText = '';
      
      // Generate response based on intent
      switch (intent.type) {
        /*case 'ml_query':
          responseText = await simulateMLResponse(message.text);
          break;*_/
        case 'ml_query':
          responseText = await simulateMLResponse(message.text);
          responseSource = 'ml';
          setMetrics(prev => ({ ...prev, mlQueries: prev.mlQueries + 1 }));
          break;
        case 'api_query':
          responseText = await simulateAPIResponse(message.text, intent.apiType || 'general');
          break;
        default:
          responseText = 'No entiendo completamente tu consulta.';
          break;
      }
      
      // Create bot response
      const botResponse: IRCMessage = {
        id: Date.now() * 1000 + Math.floor(Math.random() * 1000),
        text: responseText,
        sender: currentNick,
        channel: message.channel,
        timestamp: new Date().toISOString(),
        type: 'message',
        source: responseSource
      };
      
      // Update messages
      setMessages(prev => [...prev, botResponse]);
      
      // Update metrics atomically
      updateMetrics(prevMetrics => ({
        ...prevMetrics,
        messagesProcessed: prevMetrics.messagesProcessed + 1,
        mlQueries: responseSource === 'ml' ? prevMetrics.mlQueries + 1 : prevMetrics.mlQueries,
        apiQueries: responseSource === 'api' ? prevMetrics.apiQueries + 1 : prevMetrics.apiQueries
      }));
      
    }, 1000); // Simulate processing time
  } catch (error: any) {
    console.error("Error processing message:", error);
    setMessages(prev => [...prev, {
      id: Date.now() * 1000 + Math.floor(Math.random() * 1000),
      text: `Error al procesar mensaje: ${error.message}`,
      sender: 'System',
      channel: 'system',
      timestamp: new Date().toISOString(),
      type: 'system',
      source: 'irc'
    }]);
  }
}, [botConfig.isActive, currentNick, updateMetrics, determineResponseSource]);

  // Save configuration to localStorage when it changes
  useEffect(() => {
    localStorage.setItem('ircBotConfig', JSON.stringify(botConfig));
  }, [botConfig]);
// Usar useMemo para transformar el historial en datos para el gráfico
const chartData = useMemo(() => {
  // Si no hay datos en el historial, proporcionar datos iniciales
  if (activityHistory.timestamps.length === 0) {
    const hours = [
      new Date().getHours() - 5,
      new Date().getHours() - 4,
      new Date().getHours() - 3,
      new Date().getHours() - 2,
      new Date().getHours() - 1,
      new Date().getHours()
    ].map(h => `${(h + 24) % 24}:00`);
    
    return hours.map((time, i) => ({
      time,
      // Valores iniciales que crecen gradualmente
      messages: Math.max(1, Math.round(5 + i * 2)),
      ml: Math.max(1, Math.round(2 + i * 0.8)),
      api: Math.max(0, Math.round(1 + i * 0.5))
    }));
  }
  
  // Convertir el historial en datos para el gráfico
  return activityHistory.timestamps.map((time, index) => ({
    time,
    messages: activityHistory.messages[index],
    ml: activityHistory.ml[index],
    api: activityHistory.api[index]
  }));
}, [activityHistory]);

// Inicializar el historial cuando el componente se monta
useEffect(() => {
  // Solo inicializar si está vacío
  if (activityHistory.timestamps.length === 0) {
    const now = new Date();
    const currentHour = now.getHours();
    
    // Crear un historial de 6 horas con datos iniciales que aumentan gradualmente
    const initialHistory: ActivityHistory = {
      timestamps: Array.from({length: 6}, (_, i) => `${(currentHour - 5 + i + 24) % 24}:00`),
      messages: Array.from({length: 6}, (_, i) => Math.max(1, Math.round(5 + i * 2))),
      ml: Array.from({length: 6}, (_, i) => Math.max(1, Math.round(2 + i * 0.8))),
      api: Array.from({length: 6}, (_, i) => Math.max(0, Math.round(1 + i * 0.5)))
    };
    
    setActivityHistory(initialHistory);
  }
}, []);

// Simular respuesta del modelo ML (en v0.1 es simulado, en el futuro conectará con tu backend ML)
const simulateMLResponse = async (text: string, brief: boolean = false): Promise<string> => {
  // Versión simplificada para v0.1
  // En el futuro, esto se conectaría con tu modelo ML real
  
  // Simular latencia de red/procesamiento
  await new Promise(resolve => setTimeout(resolve, 700));
  
  const responses: {[key: string]: string} = {
    "hola": "¡Hola! ¿En qué puedo ayudarte hoy?",
    "ayuda": "Puedo responder preguntas, proporcionar información y buscar datos en tiempo real. ¿Sobre qué tema necesitas ayuda?",
    "que puedes hacer": "Puedo procesar preguntas, aprender de conversaciones, y conectarme a APIs externas para obtener información actualizada.",
    "quien eres": `Soy ${currentNick}, un bot IRC con capacidades de aprendizaje automático. Estoy en fase de desarrollo v0.1.`,
  };
  
  // Buscar palabras clave
  for (const [key, response] of Object.entries(responses)) {
    if (text.toLowerCase().includes(key)) {
      return brief ? response.split('.')[0] : response;
    }
  }
  
  // Respuesta por defecto
  return brief 
    ? "Estoy procesando tu consulta con mi modelo de ML." 
    : `He analizado tu mensaje "${text}" y he generado esta respuesta basada en mi entrenamiento.`;
};

// Simular respuesta de APIs externas
const simulateAPIResponse = async (text: string, apiType: string, brief: boolean = false): Promise<string> => {
  // Versión simulada para v0.1
  // En el futuro, esto se conectaría con APIs reales
  
  // Simular latencia de red
  await new Promise(resolve => setTimeout(resolve, 500));
  
  switch (apiType) {
    case 'weather':
      return brief 
        ? "Temperatura: 22°C, Parcialmente nublado" 
        : "El clima actual es: 22°C, Parcialmente nublado. Existe 20% de probabilidad de lluvia. Humedad: 65%.";
    
    case 'news':
      return brief 
        ? "Última noticia: Avances en IA" 
        : "Últimas noticias: 'Nuevos avances en Inteligencia Artificial permiten mayor personalización de respuestas', 'Desarrolladores implementan sistemas conversacionales mejorados'.";
      
    default:
      return brief 
        ? "Obteniendo datos en tiempo real..." 
        : `He consultado fuentes externas para responder a "${text}". Esta es una respuesta simulada que en el futuro se conectará con APIs reales para proporcionar datos actualizados.`;
  }
};

// Función para actualizar la configuración de una API
const handleApiConfigChange = (index: number, field: keyof ApiConfig, value: any) => {
  setBotConfig(prev => {
    const newApis = [...prev.apis];
    newApis[index] = {
      ...newApis[index],
      [field]: value
    };
    return {
      ...prev,
      apis: newApis
    };
  });
};

// Función para añadir una nueva API
const handleAddApi = () => {
  setBotConfig(prev => ({
    ...prev,
    apis: [
      ...prev.apis,
      {
        name: `API ${prev.apis.length + 1}`,
        enabled: true,
        endpoint: '',
        priority: 5,
        apiKey: ''
      }
    ]
  }));
};

// Función para eliminar una API
const handleRemoveApi = (index: number) => {
  setBotConfig(prev => ({
    ...prev,
    apis: prev.apis.filter((_, i) => i !== index)
  }));
};

// Crear datos de actividad basados en métricas reales
// Reemplazar la implementación actual
const getActivityChartData = async () => {
  try {
    // Intentar obtener datos reales primero
    if (botConfig.isActive) {
      try {
        //const response = await fetch(`${botConfig.proxy.endpoint}/irc/activity-metrics`, {
          const response = await fetch(`https://backend.anubisai.net/api/mybrain/activity-metrics`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });
        
        if (response.ok) {
          const data = await response.json();
          
          if (data.success && data.metrics && data.metrics.length > 0) {
            console.log("Datos de actividad obtenidos del servidor:", data.metrics);
            return data.metrics;
          }
        }
      } catch (error) {
        console.error('Error al obtener métricas de actividad:', error);
        // Continuar con los datos fallback
      }
    }
    
    // Si llegamos aquí, usamos datos fallback
    const fallbackData = generateFallbackActivityData();
    console.log("Usando datos de actividad fallback:", fallbackData);
    return fallbackData;
  } catch (error) {
    console.error('Error inesperado en getActivityChartData:', error);
    // Asegurar que siempre devolvemos algo, incluso en caso de error
    return [
      { time: '00:00', messages: 5, ml: 3, api: 1 },
      { time: '04:00', messages: 7, ml: 4, api: 2 },
      { time: '08:00', messages: 9, ml: 5, api: 3 },
      { time: '12:00', messages: 11, ml: 6, api: 3 },
      { time: '16:00', messages: 13, ml: 7, api: 4 },
      { time: '20:00', messages: 15, ml: 8, api: 5 }
    ];
  }
};

const [activityData, setActivityData] = useState(() => {
// Generar datos iniciales para el gráfico
return [
  { time: '00:00', messages: 5, ml: 3, api: 1 },
  { time: '04:00', messages: 7, ml: 4, api: 2 },
  { time: '08:00', messages: 9, ml: 5, api: 3 },
  { time: '12:00', messages: 11, ml: 6, api: 3 },
  { time: '16:00', messages: 13, ml: 7, api: 4 },
  { time: '20:00', messages: 15, ml: 8, api: 5 }
];
});

// Efecto para cargar datos de actividad
useEffect(() => {
  // Función para cargar datos
  const loadActivityData = async () => {
    const data = await getActivityChartData();
    setActivityData(data);
  };
  
  // Cargar datos iniciales
  loadActivityData();
  
  // Actualizar cada minuto si el bot está activo
  let interval: NodeJS.Timeout | null = null;
  if (botConfig.isActive) {
    interval = setInterval(loadActivityData, 15000); // Reducir a 15 segundos para ver cambios más rápido
  }
  
  return () => {
    if (interval) {
      clearInterval(interval);
    }
  };
}, [botConfig.isActive, metrics.messagesProcessed, metrics.mlQueries, metrics.apiQueries]);

// Función de respaldo para generar datos simulados (solo si falla la API)
const generateFallbackActivityData = () => {
  const now = new Date();
  // Generar datos consistentes basados en la hora actual
  const seed = now.getDate() + now.getMonth() * 31;
  
  // Valores base mínimos más significativos para asegurar variación visible
  const MIN_BASE_MESSAGES = 5;
  const MIN_BASE_ML = 3;
  const MIN_BASE_API = 2;
  
  return Array.from({length: 6}, (_, i) => {
    const hour = new Date(now);
    hour.setHours(now.getHours() - (5 - i));
    
    // Usar valores reales de métricas como base, pero con mínimos significativos
    const baseMessages = Math.max(MIN_BASE_MESSAGES, metrics.messagesProcessed / 6);
    const baseML = Math.max(MIN_BASE_ML, metrics.mlQueries / 6);
    const baseAPI = Math.max(MIN_BASE_API, metrics.apiQueries / 6);
    
    // Factor de crecimiento que aumenta hacia las horas más recientes (de 0.6 a 1.4)
    const growthFactor = 0.6 + (i * 0.16);
    
    // Usar un algoritmo determinista para generar variaciones más pronunciadas
    const hourSeed = seed + hour.getHours();
    const messageVar = Math.sin(hourSeed * 0.3) * baseMessages * 0.7 * growthFactor;
    const mlVar = Math.cos(hourSeed * 0.5) * baseML * 0.6 * growthFactor;
    const apiVar = Math.sin(hourSeed * 0.7) * baseAPI * 0.5 * growthFactor;
    
    return {
      time: hour.getHours() + ':00',
      messages: Math.max(1, Math.round(baseMessages * growthFactor + messageVar)),
      ml: Math.max(1, Math.round(baseML * growthFactor + mlVar)),
      api: Math.max(0, Math.round(baseAPI * growthFactor + apiVar))
    };
  });
};

return (
  <>
    <WidgetsSectionTitle
      title="MyBrain Bot ML Training Integration"
      subtitle="Gestión y monitoreo de entrenamiento ML"
      icon={faRobot}
      className="my-5"
    />
    <div className="p-4">
      {/* Bot Status and Controls *_/}
      <Row className="mb-4">
        <Col md={3}>
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <p className="text-muted mb-1">Estado del Bot</p>
                  <h3 className="mb-0">
                    {botConfig.isActive ? 
                      <span className="text-success">Activo</span> : 
                      <span className="text-danger">Inactivo</span>
                    }
                  </h3>
                  {botConfig.isActive && (
                    <small className="text-muted">Conectado como {currentNick}</small>
                  )}
                  {connectionErrors.length > 0 && (
                    <div className="mt-2">
                      <small className="text-danger">
                        {reconnectAttempts > 0 && `Intentos de reconexión: ${reconnectAttempts}`}
                      </small>
                    </div>
                  )}
                </div>
                <Bot size={24} className={botConfig.isActive ? 'text-success' : 'text-danger'} />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <p className="text-muted mb-1">Mensajes</p>
                  <h3 className="mb-0">{metrics.messagesProcessed}</h3>
                  <small className="text-muted">Tiempo: {formatUptime(metrics.uptime)}</small>
                </div>
                <MessageSquare size={24} className="text-primary" />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <p className="text-muted mb-1">Consultas ML</p>
                  <h3 className="mb-0">{metrics.mlQueries}</h3>
                  <div className="d-flex align-items-center">
                    <Badge bg={botConfig.preferML ? "primary" : "secondary"} className="me-1">
                      {botConfig.preferML ? "Prioridad Alta" : "Normal"}
                    </Badge>
                  </div>
                </div>
                <Brain size={24} className="text-info" />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <p className="text-muted mb-1">APIs Externas</p>
                  <h3 className="mb-0">{metrics.apiQueries}</h3>
                  <small className="text-muted">
                    {botConfig.apis.filter(api => api.enabled).length} activas
                  </small>
                </div>
                <Globe size={24} className="text-warning" />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mb-4">
        {/* Configuración del Bot *_/}
        <Col md={6}>
          <Card>
            <Card.Header className="d-flex justify-content-between align-items-center">
              <h5 className="mb-0">BOT Configuration</h5>
              <Button 
                variant="outline-secondary" 
                size="sm"
                onClick={() => setShowApiConfig(!showApiConfig)}
              >
                {showApiConfig ? "BOT Configuration" : "APIs Configuration"}
              </Button>
            </Card.Header>
            <Card.Body>
              {!showApiConfig ? (
                <Form>
                  {/*<Row>
                    <Col md={8}>
                      <Form.Group className="mb-3">
                        <Form.Label>Servidor IRC</Form.Label>
                        <Form.Control
                          type="text"
                          value="irc.linuxcorp.cl" //{botConfig.server}
                          onChange={(e) => setBotConfig(prev => ({ ...prev, server: e.target.value }))}
                          disabled={true} //{botConfig.isActive}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>Puerto</Form.Label>
                        <Form.Control
                          type="number"
                          value="6667" //{botConfig.port}
                          onChange={(e) => setBotConfig(prev => ({ ...prev, port: parseInt(e.target.value) || 6667 }))}
                          disabled={true} //{botConfig.isActive}
                        />
                      </Form.Group>
                    </Col>
                  </Row>*_/}
                  <Form.Group className="mb-3">
                    <Form.Label>Nickname del Bot</Form.Label>
                    <Form.Control
                      type="text"
                      value={botConfig.nickname}
                      onChange={(e) => setBotConfig(prev => ({ ...prev, nickname: e.target.value }))}
                      disabled={botConfig.isActive}
                    />
                    <Form.Text className="text-muted">
                      Nicknames alternativos: {botConfig.alternativeNicks.join(', ')}
                    </Form.Text>
                  </Form.Group>
                  {/*<Form.Group className="mb-3">
                    <Form.Label>Contraseña del Servidor (opcional)</Form.Label>
                    <Form.Control
                      type="password"
                      value={botConfig.password || ''}
                      onChange={(e) => setBotConfig(prev => ({ ...prev, password: e.target.value }))}
                      placeholder="Dejar en blanco si no se requiere"
                      disabled={botConfig.isActive}
                    />
                  </Form.Group>*_/}
                  <Form.Group className="mb-3">
                    <Form.Check 
                      type="switch"
                      id="ssl-switch"
                      label="Usar conexión SSL/TLS"
                      checked={botConfig.useSSL}
                      onChange={(e) => setBotConfig(prev => ({ ...prev, useSSL: e.target.checked }))}
                      disabled={botConfig.isActive}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Endpoint ML</Form.Label>
                    <Form.Control
                      type="text"
                      value={botConfig.mlEndpoint}
                      onChange={(e) => setBotConfig(prev => ({ ...prev, mlEndpoint: e.target.value }))}
                      disabled={true} //{botConfig.isActive}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Check 
                      type="switch"
                      id="ml-priority-switch"
                      label="Priorizar modelo ML sobre APIs"
                      checked={botConfig.preferML}
                      onChange={(e) => setBotConfig(prev => ({ ...prev, preferML: e.target.checked }))}
                      disabled={botConfig.isActive}
                    />
                    <Form.Text className="text-muted">
                      Si está activado, se priorizarán las respuestas del modelo ML. Caso contrario, se combinarán respuestas de ML y APIs.
                    </Form.Text>
                  </Form.Group>
                  <div className="d-flex gap-2">
                    <Button
                      variant={botConfig.isActive ? "danger" : "success"}
                      onClick={botConfig.isActive ? handleStopBot : handleStartBot}
                      disabled={loading}
                    >
                      {loading ? (
                        <><Spinner as="span" animation="border" size="sm" /> Cargando...</>
                      ) : (
                        botConfig.isActive ? "Detener Bot" : "Iniciar Bot"
                      )}
                    </Button>
                    
                    {botConfig.isActive && (
                      <Button
                        variant={autoResponseEnabled ? "warning" : "outline-secondary"}
                        onClick={toggleAutoResponse}
                        disabled={!botConfig.isActive}
                        className="ms-2"
                      >
                        {autoResponseEnabled ? "Deshabilitar Auto-Respuesta" : "Habilitar Auto-Respuesta"}
                      </Button>
                    )}
                    
                    {!botConfig.isActive && (
                      <Button
                        variant="outline-info"
                        onClick={handleDiagnoseConnection}
                        disabled={loading}
                      >
                        Diagnosticar Conexión
                      </Button>
                    )}
                  </div>
                </Form>
              ) : (
                // Panel de configuración de APIs
                <div>
                  <h6>Integración con APIs Externas</h6>
                  <p className="text-muted small">Configure las APIs externas que el bot utilizará para obtener información en tiempo real.</p>
                  
                  {botConfig.apis.map((api, index) => (
                    <Card key={index} className="mb-3">
                      <Card.Body>
                        <Row>
                          <Col md={6}>
                            <Form.Group className="mb-2">
                              <Form.Label>Nombre</Form.Label>
                              <Form.Control
                                type="text"
                                value={api.name}
                                onChange={(e) => handleApiConfigChange(index, 'name', e.target.value)}
                                disabled={botConfig.isActive}
                                size="sm"
                              />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-2">
                              <Form.Label>Endpoint</Form.Label>
                              <Form.Control
                                type="text"
                                value={api.endpoint}
                                onChange={(e) => handleApiConfigChange(index, 'endpoint', e.target.value)}
                                disabled={botConfig.isActive}
                                size="sm"
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <Form.Group className="mb-2">
                              <Form.Label>API Key</Form.Label>
                              <Form.Control
                                type="password"
                                value={api.apiKey || ''}
                                onChange={(e) => handleApiConfigChange(index, 'apiKey', e.target.value)}
                                disabled={botConfig.isActive}
                                size="sm"
                              />
                            </Form.Group>
                          </Col>
                          <Col md={4}>
                            <Form.Group className="mb-2">
                              <Form.Label>Prioridad</Form.Label>
                              <Form.Control
                                type="number"
                                value={api.priority}
                                onChange={(e) => handleApiConfigChange(index, 'priority', parseInt(e.target.value) || 5)}
                                disabled={botConfig.isActive}
                                size="sm"
                                min={1}
                                max={10}
                              />
                            </Form.Group>
                          </Col>
                          <Col md={2} className="d-flex align-items-end mb-2">
                            <Form.Check 
                              type="switch"
                              id={`api-enabled-${index}`}
                              label="Activa"
                              checked={api.enabled}
                              onChange={(e) => handleApiConfigChange(index, 'enabled', e.target.checked)}
                              disabled={botConfig.isActive}
                            />
                          </Col>
                        </Row>
                        <div className="d-flex justify-content-end">
                          <Button
                            variant="outline-danger"
                            size="sm"
                            onClick={() => handleRemoveApi(index)}
                            disabled={botConfig.isActive || botConfig.apis.length <= 1}
                          >
                            Eliminar API
                          </Button>
                        </div>
                      </Card.Body>
                    </Card>
                  ))}
                  
                  <div className="d-flex justify-content-between mt-3">
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={handleAddApi}
                      disabled={botConfig.isActive}
                    >
                      Añadir Nueva API
                    </Button>
                    
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      onClick={() => setShowApiConfig(false)}
                    >
                      Volver a Configuración IRC
                    </Button>
                  </div>
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>

        {/* Consola IRC *_/}
        <Col md={6}>
          <Card>
            <Card.Header>
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="mb-0">Learning Console</h5>
                <Form.Select
                  style={{ width: 'auto' }}
                  value={selectedChannel}
                  onChange={(e) => setSelectedChannel(e.target.value)}
                >
                  {botConfig.channels.map(channel => (
                    <option key={channel} value={channel}>{channel}</option>
                  ))}
                </Form.Select>
              </div>
            </Card.Header>
            <Card.Body>
              <div className="chat-container mb-3" style={{ height: '300px', overflowY: 'auto' }}>
                {messages
                 .filter(msg => msg.type === 'system' || msg.channel === selectedChannel)
                 .map((message, index) => (
                   <div
                     key={`msg-${message.id}-${index}`}
                     className={`mb-2 ${
                       message.type === 'system' ? 
                         (message.text.includes('Error') ? 'text-danger' : 'text-muted') :
                       message.type === 'command' ? 'text-primary' : ''
                     }`}
                   >
                     <small>{new Date(message.timestamp).toLocaleTimeString()}</small>
                     <span className="mx-2 fw-bold">{message.sender}:</span>
                     <span>
                       {message.text}
                       {message.source && message.source !== 'user' && (
                         <Badge 
                           bg={
                             message.source === 'ml' ? 'info' :
                             message.source === 'api' ? 'warning' : 'secondary'
                           }
                           className="ms-2"
                         >
                           {message.source === 'ml' ? 'ML' :
                            message.source === 'api' ? 'API' : 'IRC'}
                         </Badge>
                       )}
                     </span>
                   </div>
                 ))}
                 <div ref={messagesEndRef} />
              </div>
              <Form className="d-flex gap-2" onSubmit={(e) => { e.preventDefault(); handleSendCommand(); }}>
                <Form.Control
                  type="text"
                  value={commandInput}
                  onChange={(e) => setCommandInput(e.target.value)}
                  placeholder="Escribe un comando o mensaje (/help para ayuda)"
                  disabled={!botConfig.isActive || loading}
                />
                <Button
                  variant="primary"
                  onClick={handleSendCommand}
                  disabled={!botConfig.isActive || loading || !commandInput.trim()}
                >
                  <Send size={20} />
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Datos de Actividad *_/}
      <Row>
        <Col>
        <Card>
  <Card.Header>
    <h5 className="mb-0">Actividad del Bot</h5>
  </Card.Header>
  <Card.Body>
    <div style={{ height: '300px' }}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="time" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="messages"
            stroke="#0d6efd"
            name="Mensajes Totales"
          />
          <Line
            type="monotone"
            dataKey="ml"
            stroke="#17a2b8"
            name="Consultas ML"
          />
          <Line
            type="monotone"
            dataKey="api"
            stroke="#ffc107"
            name="Consultas API"
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  </Card.Body>
</Card>
        </Col>
      </Row>
    </div>
  </>
);
};

export default IRCBotDashboard; */

/*
import React, { useState, useEffect, useRef } from 'react';
import { Button, Card, Col, Form, Row, Table, Badge, Spinner } from "react-bootstrap";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { MessageSquare, Send, Server, Bot, Activity, Database, Globe, Brain } from 'lucide-react';
import WidgetsSectionTitle from '../widgets/WidgetsSectionTitle';
import { faRobot } from '@fortawesome/free-solid-svg-icons';
import ircService from '../../../service/ircService';


// Interfaces
interface IRCMessage {
  id: number;
  text: string;
  sender: string;
  channel: string;
  timestamp: string;
  type: 'message' | 'command' | 'system';
  source?: 'user' | 'irc' | 'ml' | 'api';
}

interface ProxyConfig {
  enabled: boolean;
  endpoint: string;
  authToken?: string;
}

interface ApiConfig {
  name: string;
  enabled: boolean;
  endpoint: string;
  apiKey?: string;
  priority: number;
}

interface BotConfig {
  server: string;
  port: number;
  nickname: string;
  alternativeNicks: string[];
  channels: string[];
  mlEndpoint: string;
  isActive: boolean;
  useSSL?: boolean;
  password?: string;
  proxy: ProxyConfig;
  apis: ApiConfig[];
  preferML: boolean;
}

interface BotMetrics {
  messagesProcessed: number;
  activeChannels: number;
  uptime: number;
  lastTraining: string;
  mlQueries: number;
  apiQueries: number;
}

interface MessageIntent {
  type: 'direct_command' | 'ml_query' | 'api_query' | 'hybrid' | 'default';
  apiType?: string;
  context?: string;
  confidence?: number;
}

interface IRCConfig {
  server: string;
  port: number;
  nickname: string;
  channels?: string[];
  useSSL?: boolean;
  password?: string;
  alternativeNicks?: string[];
}

// Declaration for additional window properties
declare global {
  interface Window {
    reconnectHandler: NodeJS.Timeout | undefined;  // Changed from null to undefined
  }
}

// Initialize the property
if (typeof window !== 'undefined') {
  window.reconnectHandler = undefined;  // Changed from null to undefined
}

const IRCBotDashboard: React.FC = () => {
  const [messages, setMessages] = useState<IRCMessage[]>([]);
  const [botConfig, setBotConfig] = useState<BotConfig>(() => {
    // Intentar cargar la configuración desde localStorage
    const savedConfig = localStorage.getItem('ircBotConfig');
    return savedConfig ? JSON.parse(savedConfig) : {
      server: 'irc.cl',
      port: 6667,
      nickname: 'GPTLearnerBot',
      alternativeNicks: ['GPTBot', 'MLBot', 'TrainerBot', 'GPTAssistant'],
      channels: ['#training', '#support', '#general'],
      mlEndpoint: 'http://10.10.5.9:5000/train',
      isActive: false,
      useSSL: false,
      proxy: {
        enabled: true,
        endpoint: 'https://localhost:3456/api', //process.env.REACT_APP_API_URL || 'http://localhost:4001/api',
        authToken: ''
      },
      apis: [
        { 
          name: 'Clima',
          enabled: true,
          endpoint: 'https://api.openweathermap.org/data/2.5/weather',
          apiKey: '',
          priority: 2
        },
        { 
          name: 'Noticias',
          enabled: true,
          endpoint: 'https://newsapi.org/v2/everything',
          apiKey: '',
          priority: 3
        }
      ],
      preferML: true
    };
  });
  
  // Add this method to your IRCBotDashboard.tsx for testing
const testDirectWebSocket = () => {
  addSystemMessage("Testing direct WebSocket connection to backend.anubisai.net...");
  
  try {
    // First try with explicit polling
    const pollingUrl = 'https://backend.anubisai.net/socket.io/?EIO=4&transport=polling';
    addSystemMessage(`Testing polling first: ${pollingUrl}`);
    
    fetch(pollingUrl)
      .then(response => {
        if (response.ok) {
          addSystemMessage(`Polling connection successful! Status: ${response.status}`, 'info');
          testWebSocketAfterPolling();
        } else {
          addSystemMessage(`Polling failed with status: ${response.status}. This may indicate a CORS issue.`, 'error');
        }
      })
      .catch(error => {
        addSystemMessage(`Polling error: ${error.message}`, 'error');
      });
    
  } catch (error: any) {
    addSystemMessage(`Error in connection test: ${error.message}`, 'error');
  }
};

const testWebSocketAfterPolling = () => {
  try {
    addSystemMessage("Now testing WebSocket connection...");
    const wsUrl = 'wss://backend.anubisai.net/socket.io/?EIO=4&transport=websocket';
    
    const ws = new WebSocket(wsUrl);
    
    ws.onopen = () => {
      addSystemMessage("WebSocket connection opened successfully!", 'info');
      ws.close();
    };
    
    ws.onmessage = (event) => {
      addSystemMessage(`Received message: ${event.data}`, 'info');
    };
    
    ws.onerror = (event) => {
      addSystemMessage("WebSocket error occurred", 'error');
      console.error(event);
    };
    
    ws.onclose = (event) => {
      addSystemMessage(`WebSocket closed with code: ${event.code} - ${event.reason}`, 'info');
    };
  } catch (error: any) {
    addSystemMessage(`WebSocket test error: ${error.message}`, 'error');
  }
};

  const [metrics, setMetrics] = useState<BotMetrics>({
    messagesProcessed: 0,
    activeChannels: 0,
    uptime: 0,
    lastTraining: new Date().toISOString(),
    mlQueries: 0,
    apiQueries: 0
  });
  
  const [selectedChannel, setSelectedChannel] = useState<string>('#training');
  const [commandInput, setCommandInput] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [currentNick, setCurrentNick] = useState<string>(botConfig.nickname);
  const [connectionErrors, setConnectionErrors] = useState<string[]>([]);
  const [reconnectAttempts, setReconnectAttempts] = useState<number>(0);
  const [showApiConfig, setShowApiConfig] = useState<boolean>(false);
  
  // Guardar configuración en localStorage cuando cambia
  useEffect(() => {
    localStorage.setItem('ircBotConfig', JSON.stringify(botConfig));
  }, [botConfig]);
  
  // Referencia al contenedor de mensajes para auto-scroll
  const messagesEndRef = useRef<HTMLDivElement>(null);

  // Efecto para inicializar los listeners del servicio IRC
  useEffect(() => {
    const messageUnsubscribe = ircService.onMessage((message) => {
      // Procesar mensajes entrantes con ML e integración API
      if (message.from !== 'System' && message.from !== currentNick) {
        processIncomingMessage({
          id: Date.now(),
          text: message.text,
          sender: message.from || 'Unknown',
          channel: message.channel || 'system',
          timestamp: message.timestamp,
          type: 'message',
          source: 'user'
        });
      } else {
        // Mensajes de sistema o propios
        const ircMessage: IRCMessage = {
          id: Date.now(),
          text: message.text,
          sender: message.from || 'Unknown',
          channel: message.channel || 'system',
          timestamp: message.timestamp,
          type: 'message',
          source: 'irc'
        };
        setMessages(prev => [...prev, ircMessage]);
        
        // Incrementar contador de mensajes
        setMetrics(prev => ({
          ...prev,
          messagesProcessed: prev.messagesProcessed + 1
        }));
      }
    });

    const eventUnsubscribe = ircService.onEvent((event) => {
      addSystemMessage(event.text);
    });

    const errorUnsubscribe = ircService.onError((error) => {
      addSystemMessage(error.text, 'error');
      setConnectionErrors(prev => [...prev, error.text]);
    });

    const connectionUnsubscribe = ircService.onConnectionChange((connected) => {
      setBotConfig(prev => ({ ...prev, isActive: connected }));
      if (connected) {
        addSystemMessage('Conexión IRC establecida correctamente');
      } else {
        addSystemMessage('Conexión IRC cerrada');
      }
    });

    // Iniciar contador de uptime si el bot está activo
    let uptimeInterval: NodeJS.Timeout | null = null;
    if (botConfig.isActive) {
      uptimeInterval = setInterval(() => {
        setMetrics(prev => ({
          ...prev,
          uptime: prev.uptime + 1
        }));
      }, 1000);
    }

    // Limpiar suscripciones al desmontar
    return () => {
      messageUnsubscribe();
      eventUnsubscribe();
      errorUnsubscribe();
      connectionUnsubscribe();
      
      if (uptimeInterval) {
        clearInterval(uptimeInterval);
      }
      
      // Desconectar el IRC si el componente se desmonta mientras está conectado
      if (botConfig.isActive) {
        ircService.disconnect().catch(console.error);
      }
    };
  }, [botConfig.isActive, currentNick]);

  // Auto-scroll cuando hay nuevos mensajes
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const handleStartBot = async () => {
    setLoading(true);
    setConnectionErrors([]);
    setReconnectAttempts(0);
  
    // Modo de simulación para pruebas (elimina esto cuando el backend esté listo)
    const simulationMode = false; // Cambia a false cuando quieras usar el backend real
  
    if (simulationMode) {
      try {
        addSystemMessage(`Iniciando en modo de simulación...`);
        await new Promise(resolve => setTimeout(resolve, 800));
        
        addSystemMessage(`Conectado a ${botConfig.server}:${botConfig.port} (simulado)`);
        setBotConfig(prev => ({ ...prev, isActive: true }));
        setCurrentNick(botConfig.nickname);
        
        // Simular una conexión exitosa
        addSystemMessage(`Unido a canales: ${botConfig.channels.join(', ')}`);
        
        // Mensaje de bienvenida simulado
        setTimeout(() => {
          const welcomeMsg: IRCMessage = {
            id: Date.now(),
            text: `Bienvenido a ${selectedChannel}! Estoy en modo de simulación para desarrollo.`,
            sender: 'IRCServer',
            channel: selectedChannel,
            timestamp: new Date().toISOString(),
            type: 'message',
            source: 'irc'
          };
          setMessages(prev => [...prev, welcomeMsg]);
        }, 1500);
        
        // Actualizar métricas
        setMetrics(prev => ({
          ...prev,
          activeChannels: botConfig.channels.length,
          uptime: 0,
          mlQueries: 0,
          apiQueries: 0
        }));
        
        setLoading(false);
        return;
      } catch (error: any) {
        // Continuar con el método normal si hay algún error
      }
    }
  
    try {
      addSystemMessage(`Iniciando diagnóstico de conexión a ${botConfig.server}:${botConfig.port}...`);
      
      addSystemMessage(`Configurando proxy de conexión IRC en el backend...`);
      
      // Usar la nueva función de reconexión con nicknames alternativos
      const connectionConfig = {
        server: botConfig.server,
        port: botConfig.port,
        nickname: botConfig.nickname,
        alternativeNicks: botConfig.alternativeNicks,
        channels: botConfig.channels,
        useSSL: botConfig.useSSL,
        password: botConfig.password
      };
      
      // Intentar conexión a través del servicio con soporte para nicknames alternativos
      const connectionId = await ircService.reconnectWithAlternativeNick(connectionConfig);
      
      // Consultar al servicio para obtener el nickname que realmente se asignó
      const connectionStatus = await ircService.checkConnection(connectionId);
      if (connectionStatus.nickname) {
        setCurrentNick(connectionStatus.nickname);
      } else {
        setCurrentNick(botConfig.nickname);
      }
      
      addSystemMessage(`Conexión establecida con ID: ${connectionId}`);
      
      // Inicializar sistemas de ML y API
      addSystemMessage(`Preparando integración con sistema ML en ${botConfig.mlEndpoint}`);
      addSystemMessage(`Configurando ${botConfig.apis.filter(api => api.enabled).length} APIs externas`);
      
      // Actualizar métricas
      setMetrics(prev => ({
        ...prev,
        activeChannels: botConfig.channels.length,
        uptime: 0,
        mlQueries: 0,
        apiQueries: 0
      }));
      
      // Configurar manejador de reconexión automática
      setupAutoReconnect(connectionConfig);
      
    } catch (error: any) {
      addSystemMessage(`Error al iniciar el bot: ${error.message}`, 'error');
      setConnectionErrors(prev => [...prev, error.message]);
    } finally {
      setLoading(false);
    }
  };
  
  // Función de reconexión automática
  const setupAutoReconnect = (config: IRCConfig) => {
    // Limpiar cualquier manejador de reconexión previo
    if (window.reconnectHandler !== undefined) {  // Check for undefined instead of truthy check
      clearInterval(window.reconnectHandler);
      window.reconnectHandler = undefined;  // Reset to undefined after clearing
    }
    
    // Establecer un intervalo para verificar la conexión periódicamente
    window.reconnectHandler = setInterval(async () => {
      if (!botConfig.isActive) {
        if (window.reconnectHandler !== undefined) {  // Add explicit check here too
          clearInterval(window.reconnectHandler);
          window.reconnectHandler = undefined;  // Reset to undefined
        }
        return;
      }
      
      // Rest of the function remains the same
      try {
        if (ircService.connectionId) {
          const status = await ircService.checkConnection(ircService.connectionId);
          if (!status.active) {
            setReconnectAttempts(prev => prev + 1);
            addSystemMessage(`Conexión perdida. Intento de reconexión #${reconnectAttempts + 1}...`);
            
            // Intentar reconexión con nickname alternativo
            const connectionId = await ircService.reconnectWithAlternativeNick(config, reconnectAttempts);
            
            // Actualizar nickname si cambió
            const connectionStatus = await ircService.checkConnection(connectionId);
            if (connectionStatus.nickname) {
              setCurrentNick(connectionStatus.nickname);
            }
            
            addSystemMessage(`Reconexión exitosa con nickname: ${currentNick}`);
          }
        }
      } catch (error: any) {
        addSystemMessage(`Error en reconexión automática: ${error.message}`, 'error');
        setConnectionErrors(prev => [...prev, error.message]);
        
        // Si hay muchos intentos fallidos, detener los intentos
        if (reconnectAttempts > 5) {
          if (window.reconnectHandler !== undefined) {  // Add explicit check here too
            clearInterval(window.reconnectHandler);
            window.reconnectHandler = undefined;  // Reset to undefined
          }
          addSystemMessage('Demasiados intentos de reconexión fallidos. Deteniendo reconexión automática.', 'error');
        }
      }
    }, 10000); // Verificar cada 30 segundos
  };

  const handleStopBot = async () => {
    setLoading(true);
    try {
      // Modo de simulación
      if (true) { // Cambiar a "false" cuando el backend esté listo
        addSystemMessage(`Desconectando modo de simulación...`);
        await new Promise(resolve => setTimeout(resolve, 500));
        
        setBotConfig(prev => ({ ...prev, isActive: false }));
        addSystemMessage('Bot detenido correctamente');
        
        // Reiniciar métricas
        setMetrics(prev => ({
          ...prev,
          uptime: 0
        }));
        
        setLoading(false);
        return;
      }
      
      // Código para desconexión real
      addSystemMessage(`Desconectando de ${botConfig.server}:${botConfig.port}`);
      
      await ircService.disconnect();
      
      addSystemMessage('Bot detenido correctamente');
      setCurrentNick(botConfig.nickname);
      setConnectionErrors([]);
      setReconnectAttempts(0);
      
      // Reiniciar métricas
      setMetrics(prev => ({
        ...prev,
        uptime: 0
      }));
    } catch (error: any) {
      addSystemMessage(`Error al detener el bot: ${error.message}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleSendCommand = async () => {
    if (!commandInput.trim() || !botConfig.isActive) return;
    
    const newMessage: IRCMessage = {
      id: Date.now(),
      text: commandInput,
      sender: 'Admin',
      channel: selectedChannel,
      timestamp: new Date().toISOString(),
      type: 'command',
      source: 'user'
    };

    setMessages(prev => [...prev, newMessage]);
    
    // Modo de simulación (quitar cuando el backend esté listo)
    const simulationMode = false;
    
    if (simulationMode) {
      // Procesar comandos especiales
      if (commandInput.startsWith('/')) {
        if (commandInput.startsWith('/join ')) {
          const channel = commandInput.slice(6).trim();
          if (!botConfig.channels.includes(channel)) {
            setBotConfig(prev => ({
              ...prev,
              channels: [...prev.channels, channel]
            }));
            setSelectedChannel(channel);
            addSystemMessage(`Unido a canal: ${channel}`);
          }
        } else if (commandInput.startsWith('/nick ')) {
          const newNick = commandInput.slice(6).trim();
          setCurrentNick(newNick);
          addSystemMessage(`Nickname cambiado a ${newNick}`);
        } else if (commandInput.startsWith('/help')) {
          addSystemMessage('Comandos disponibles:');
          addSystemMessage('/join #canal - Unirse a un nuevo canal');
          addSystemMessage('/train - Entrenar el modelo ML con datos del canal');
          addSystemMessage('/status - Mostrar estado del bot');
          addSystemMessage('/apis - Mostrar estado de las APIs configuradas');
          addSystemMessage('/ml - Mostrar estadísticas del sistema ML');
          addSystemMessage('/nick nombre - Cambiar nickname');
          addSystemMessage('/help - Mostrar este mensaje de ayuda');
        } else if (commandInput.startsWith('/train')) {
          addSystemMessage(`Iniciando entrenamiento ML con datos de ${selectedChannel}`);
          setTimeout(() => {
            addSystemMessage('Entrenamiento ML en progreso...');
            setTimeout(() => {
              addSystemMessage('Entrenamiento completado. Modelo actualizado.');
              setMetrics(prev => ({
                ...prev,
                lastTraining: new Date().toISOString()
              }));
            }, 3000);
          }, 1000);
        } else if (commandInput.startsWith('/apis')) {
          // Mostrar estado de las APIs
          addSystemMessage('APIs configuradas:');
          botConfig.apis.forEach(api => {
            addSystemMessage(`${api.name}: ${api.enabled ? 'Activa' : 'Inactiva'} - Prioridad: ${api.priority}`);
          });
        } else if (commandInput.startsWith('/ml')) {
          // Mostrar estadísticas de ML
          addSystemMessage(`Sistema ML: ${botConfig.mlEndpoint}`);
          addSystemMessage(`Consultas realizadas: ${metrics.mlQueries}`);
          addSystemMessage(`Último entrenamiento: ${new Date(metrics.lastTraining).toLocaleString()}`);
          addSystemMessage(`Prioridad ML: ${botConfig.preferML ? 'Alta' : 'Normal'}`);
        } else if (commandInput.startsWith('/status')) {
          addSystemMessage(`Estado del bot: ${botConfig.isActive ? 'Activo' : 'Inactivo'}`);
          addSystemMessage(`Conectado a: ${botConfig.server}:${botConfig.port}`);
          addSystemMessage(`Canales: ${botConfig.channels.join(', ')}`);
          addSystemMessage(`Tiempo en línea: ${formatUptime(metrics.uptime)}`);
        } else {
          addSystemMessage(`Comando ${commandInput} no reconocido. Usa /help para ver la lista de comandos.`);
        }
      } else {
        // Mensaje normal - procesar con ML/API
        processIncomingMessage(newMessage);
      }
      
      setCommandInput('');
      return;
    }
    
    // Código original para enviar comandos al backend real
    try {
      if (commandInput.startsWith('/')) {
        // Comando IRC
        await ircService.sendMessage(selectedChannel, commandInput);
        
        // Procesar comandos especiales
        if (commandInput.startsWith('/join ')) {
          const channel = commandInput.slice(6).trim();
          if (!botConfig.channels.includes(channel)) {
            setBotConfig(prev => ({
              ...prev,
              channels: [...prev.channels, channel]
            }));
            setSelectedChannel(channel);
          }
        } else if (commandInput.startsWith('/nick ')) {
          const newNick = commandInput.slice(6).trim();
          setCurrentNick(newNick);
        } else if (commandInput.startsWith('/help')) {
          addSystemMessage('Comandos disponibles:');
          addSystemMessage('/join #canal - Unirse a un nuevo canal');
          addSystemMessage('/train - Entrenar el modelo ML con datos del canal');
          addSystemMessage('/status - Mostrar estado del bot');
          addSystemMessage('/apis - Mostrar estado de las APIs configuradas');
          addSystemMessage('/ml - Mostrar estadísticas del sistema ML');
          addSystemMessage('/nick nombre - Cambiar nickname');
          addSystemMessage('/help - Mostrar este mensaje de ayuda');
        } else if (commandInput.startsWith('/train')) {
          addSystemMessage(`Iniciando entrenamiento ML con datos de ${selectedChannel}`);
          setTimeout(() => {
            addSystemMessage('Entrenamiento ML en progreso...');
            setTimeout(() => {
              addSystemMessage('Entrenamiento completado. Modelo actualizado.');
              setMetrics(prev => ({
                ...prev,
                lastTraining: new Date().toISOString()
              }));
            }, 3000);
          }, 1000);
        } else if (commandInput.startsWith('/apis')) {
          // Mostrar estado de las APIs
          addSystemMessage('APIs configuradas:');
          botConfig.apis.forEach(api => {
            addSystemMessage(`${api.name}: ${api.enabled ? 'Activa' : 'Inactiva'} - Prioridad: ${api.priority}`);
          });
        } else if (commandInput.startsWith('/ml')) {
          // Mostrar estadísticas de ML
          addSystemMessage(`Sistema ML: ${botConfig.mlEndpoint}`);
          addSystemMessage(`Consultas realizadas: ${metrics.mlQueries}`);
          addSystemMessage(`Último entrenamiento: ${new Date(metrics.lastTraining).toLocaleString()}`);
          addSystemMessage(`Prioridad ML: ${botConfig.preferML ? 'Alta' : 'Normal'}`);
        }
      } else {
        // Mensaje normal
        await ircService.sendMessage(selectedChannel, commandInput);
      }
    } catch (error: any) {
      addSystemMessage(`Error al enviar mensaje: ${error.message}`, 'error');
    }
    
    setCommandInput('');
  };

  const addSystemMessage = (text: string, type: 'info' | 'error' | 'warning' = 'info') => {
    const systemMessage: IRCMessage = {
      id: Date.now(),
      text,
      sender: 'System',
      channel: 'system',
      timestamp: new Date().toISOString(),
      type: 'system',
      source: 'irc'
    };
    setMessages(prev => [...prev, systemMessage]);
    
    // Registrar errores si corresponde
    if (type === 'error') {
      setConnectionErrors(prev => [...prev, text]);
    }
  };

  const handleDiagnoseConnection = () => {
    addSystemMessage('Ejecutando diagnóstico de conexión...');
    setTimeout(() => {
      addSystemMessage(`Prueba de conexión a ${botConfig.server}:${botConfig.port}...`);
      setTimeout(() => {
        addSystemMessage('Hay conectividad de red básica.');
        addSystemMessage(`Verificando accesibilidad del servidor IRC...`);
        setTimeout(() => {
          addSystemMessage(`Nota: Los navegadores no pueden conectarse directamente a servidores IRC.`);
          addSystemMessage(`Se requiere un backend o proxy para establecer la conexión real.`);
          
          if (botConfig.server === 'irc.cl') {
            addSystemMessage(`Verificación manual del servidor ${botConfig.server}:${botConfig.port} mediante telnet muestra que SÍ está disponible.`);
            addSystemMessage(`Recomendación: Configure el Proxy IRC en el backend para establecer la conexión.`);
          } else {
            addSystemMessage(`No se puede verificar directamente ${botConfig.server} desde el navegador.`);
            addSystemMessage(`Si ha comprobado manualmente que el servidor está disponible, proceda a configurar el Proxy IRC.`);
          }
        }, 1000);
      }, 800);
    }, 500);
  };

  // Función para formatear el tiempo de uptime
  const formatUptime = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  // ----- NUEVAS FUNCIONES PARA INTEGRACIÓN DE ML Y API -----
  
  // Función para analizar la intención del mensaje
  const analyzeMessageIntent = async (text: string): Promise<MessageIntent> => {
    // Implementación simple basada en keywords para v0.1
    // En versiones futuras, esto podría usar un modelo de NLP más sofisticado
    if (text.startsWith('/')) {
      return { type: 'direct_command' };
    } else if (text.match(/clima|temperatura|lluvia|sol|pronóstico/i)) {
      return { type: 'api_query', apiType: 'weather', confidence: 0.8 };
    } else if (text.match(/noticias|noticia|últimas noticias|actualidad/i)) {
      return { type: 'api_query', apiType: 'news', confidence: 0.8 };
    } else if (text.match(/aprende|recuerda|dime|explícame|información sobre/i)) {
      return { type: 'ml_query', context: 'learning', confidence: 0.7 };
    } else {
      // Por defecto, intentar con ML primero si está configurado así
      return { type: botConfig.preferML ? 'ml_query' : 'hybrid', context: 'conversation', confidence: 0.5 };
    }
  };

  // Procesar mensajes entrantes
  const processIncomingMessage = async (message: IRCMessage) => {
    // Añadir mensaje del usuario para mantener el contexto
    if (message.source !== 'user') {
      setMessages(prev => [...prev, message]);
    }
    
    if (!botConfig.isActive || message.sender === currentNick) return;
    
    // No procesar comandos IRC
    if (message.text.startsWith('/')) return;
    
    // Analizar intención del mensaje
    try {
      // Simular un breve "pensando..."
      setTimeout(async () => {
        // Determinar la intención del mensaje
        const intent = await analyzeMessageIntent(message.text);
        
        let responseText = '';
        let responseSource: 'ml' | 'api' | 'irc' = 'irc';
        
        // Procesar según la intención
        switch (intent.type) {
          case 'ml_query':
            responseText = await simulateMLResponse(message.text);
            responseSource = 'ml';
            setMetrics(prev => ({ ...prev, mlQueries: prev.mlQueries + 1 }));
            break;
            
          case 'api_query':
            responseText = await simulateAPIResponse(message.text, intent.apiType || 'general');
            responseSource = 'api';
            setMetrics(prev => ({ ...prev, apiQueries: prev.apiQueries + 1 }));
            break;
            
          case 'hybrid':
            // Combinar ML y API (priorizar según configuración)
            if (botConfig.preferML) {
              responseText = await simulateMLResponse(message.text);
              responseSource = 'ml';
              setMetrics(prev => ({ ...prev, mlQueries: prev.mlQueries + 1 }));
            } else {
              responseText = `Respuesta combinada:\n- ML: ${await simulateMLResponse(message.text, true)}\n- API: ${await simulateAPIResponse(message.text, 'general', true)}`;
              responseSource = 'ml'; // Marcar como ML pero es híbrido
              setMetrics(prev => ({ 
                ...prev, 
                mlQueries: prev.mlQueries + 1,
                apiQueries: prev.apiQueries + 1
              }));
            }
            break;
            
          default:
            responseText = `No entiendo completamente tu consulta. ¿Podrías reformularla?`;
            responseSource = 'irc';
            break;
        }
        
        // Añadir la respuesta a los mensajes
        const botResponse: IRCMessage = {
          id: Date.now(),
          text: responseText,
          sender: currentNick,
          channel: message.channel,
          timestamp: new Date().toISOString(),
          type: 'message',
          source: responseSource
        };
        
        setMessages(prev => [...prev, botResponse]);
        
        // En una implementación real, aquí enviarías la respuesta al canal IRC
        // await ircService.sendMessage(message.channel, responseText);
        
        // Actualizar métricas
        setMetrics(prev => ({
          ...prev,
          messagesProcessed: prev.messagesProcessed + 1
        }));
      }, 1000); // Simular tiempo de procesamiento
    } catch (error: any) {
      console.error("Error al procesar mensaje:", error);
      addSystemMessage(`Error al procesar mensaje: ${error.message}`, 'error');
    }
  };

  // Simular respuesta del modelo ML (en v0.1 es simulado, en el futuro conectará con tu backend ML)
  const simulateMLResponse = async (text: string, brief: boolean = false): Promise<string> => {
    // Versión simplificada para v0.1
    // En el futuro, esto se conectaría con tu modelo ML real
    
    // Simular latencia de red/procesamiento
    await new Promise(resolve => setTimeout(resolve, 700));
    
    const responses: {[key: string]: string} = {
      "hola": "¡Hola! ¿En qué puedo ayudarte hoy?",
      "ayuda": "Puedo responder preguntas, proporcionar información y buscar datos en tiempo real. ¿Sobre qué tema necesitas ayuda?",
      "que puedes hacer": "Puedo procesar preguntas, aprender de conversaciones, y conectarme a APIs externas para obtener información actualizada.",
      "quien eres": `Soy ${currentNick}, un bot IRC con capacidades de aprendizaje automático. Estoy en fase de desarrollo v0.1.`,
    };
    
    // Buscar palabras clave
    for (const [key, response] of Object.entries(responses)) {
      if (text.toLowerCase().includes(key)) {
        return brief ? response.split('.')[0] : response;
      }
    }
    
    // Respuesta por defecto
    return brief 
      ? "Estoy procesando tu consulta con mi modelo de ML." 
      : `He analizado tu mensaje "${text}" y he generado esta respuesta basada en mi entrenamiento. Este es un ejemplo simulado de respuesta ML que en el futuro se conectará con tu sistema ML existente.`;
  };

  // Simular respuesta de APIs externas
  const simulateAPIResponse = async (text: string, apiType: string, brief: boolean = false): Promise<string> => {
    // Versión simulada para v0.1
    // En el futuro, esto se conectaría con APIs reales
    
    // Simular latencia de red
    await new Promise(resolve => setTimeout(resolve, 500));
    
    switch (apiType) {
      case 'weather':
        return brief 
          ? "Temperatura: 22°C, Parcialmente nublado" 
          : "El clima actual es: 22°C, Parcialmente nublado. Existe 20% de probabilidad de lluvia. Humedad: 65%.";
      
      case 'news':
        return brief 
          ? "Última noticia: Avances en IA" 
          : "Últimas noticias: 'Nuevos avances en Inteligencia Artificial permiten mayor personalización de respuestas', 'Desarrolladores implementan sistemas conversacionales mejorados'.";
        
      default:
        return brief 
          ? "Obteniendo datos en tiempo real..." 
          : `He consultado fuentes externas para responder a "${text}". Esta es una respuesta simulada que en el futuro se conectará con APIs reales para proporcionar datos actualizados.`;
    }
  };

  // Función para actualizar la configuración de una API
  const handleApiConfigChange = (index: number, field: keyof ApiConfig, value: any) => {
    setBotConfig(prev => {
      const newApis = [...prev.apis];
      newApis[index] = {
        ...newApis[index],
        [field]: value
      };
      return {
        ...prev,
        apis: newApis
      };
    });
  };
  
  // Función para añadir una nueva API
  const handleAddApi = () => {
    setBotConfig(prev => ({
      ...prev,
      apis: [
        ...prev.apis,
        {
          name: `API ${prev.apis.length + 1}`,
          enabled: true,
          endpoint: '',
          priority: 5,
          apiKey: ''
        }
      ]
    }));
  };
  
  // Función para eliminar una API
  const handleRemoveApi = (index: number) => {
    setBotConfig(prev => ({
      ...prev,
      apis: prev.apis.filter((_, i) => i !== index)
    }));
  };

  // Crear datos de actividad basados en métricas reales
  const getActivityChartData = () => {
    const now = new Date();
    const pastHours = Array.from({length: 6}, (_, i) => {
      const hour = new Date(now);
      hour.setHours(now.getHours() - (5 - i));
      return {
        timetime: hour.getHours() + ':00',
        messages: Math.floor(Math.random() * metrics.messagesProcessed / 6),
        ml: Math.floor(Math.random() * metrics.mlQueries / 6),
        api: Math.floor(Math.random() * metrics.apiQueries / 6)
      };
    });
    return pastHours;
  };

  return (
    <>
      <WidgetsSectionTitle
        title="IRC Bot ML Training Integration"
        subtitle="Gestión y monitoreo de entrenamiento ML a través de canales IRC"
        icon={faRobot}
        className="my-5"
      />
      <div className="p-4">
        {/* Bot Status and Controls *_/}
        <Row className="mb-4">
          <Col md={3}>
            <Card>
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <p className="text-muted mb-1">Estado del Bot</p>
                    <h3 className="mb-0">
                      {botConfig.isActive ? 
                        <span className="text-success">Activo</span> : 
                        <span className="text-danger">Inactivo</span>
                      }
                    </h3>
                    {botConfig.isActive && (
                      <small className="text-muted">Conectado como {currentNick}</small>
                    )}
                    {connectionErrors.length > 0 && (
                      <div className="mt-2">
                        <small className="text-danger">
                          {reconnectAttempts > 0 && `Intentos de reconexión: ${reconnectAttempts}`}
                        </small>
                      </div>
                    )}
                  </div>
                  <Bot size={24} className={botConfig.isActive ? 'text-success' : 'text-danger'} />
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card>
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <p className="text-muted mb-1">Mensajes</p>
                    <h3 className="mb-0">{metrics.messagesProcessed}</h3>
                    <small className="text-muted">Tiempo: {formatUptime(metrics.uptime)}</small>
                  </div>
                  <MessageSquare size={24} className="text-primary" />
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card>
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <p className="text-muted mb-1">Consultas ML</p>
                    <h3 className="mb-0">{metrics.mlQueries}</h3>
                    <div className="d-flex align-items-center">
                      <Badge bg={botConfig.preferML ? "primary" : "secondary"} className="me-1">
                        {botConfig.preferML ? "Prioridad Alta" : "Normal"}
                      </Badge>
                    </div>
                  </div>
                  <Brain size={24} className="text-info" />
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card>
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <p className="text-muted mb-1">APIs Externas</p>
                    <h3 className="mb-0">{metrics.apiQueries}</h3>
                    <small className="text-muted">
                      {botConfig.apis.filter(api => api.enabled).length} activas
                    </small>
                  </div>
                  <Globe size={24} className="text-warning" />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mb-4">
          {/* Configuración del Bot *_/}
          <Col md={6}>
            <Card>
              <Card.Header className="d-flex justify-content-between align-items-center">
                <h5 className="mb-0">Configuración del Bot</h5>
                <Button 
                  variant="outline-secondary" 
                  size="sm"
                  onClick={() => setShowApiConfig(!showApiConfig)}
                >
                  {showApiConfig ? "Configuración IRC" : "Configuración APIs"}
                </Button>
              </Card.Header>
              <Card.Body>
                {!showApiConfig ? (
                  <Form>
                    <Row>
                      <Col md={8}>
                        <Form.Group className="mb-3">
                          <Form.Label>Servidor IRC</Form.Label>
                          <Form.Control
                            type="text"
                            value={botConfig.server}
                            onChange={(e) => setBotConfig(prev => ({ ...prev, server: e.target.value }))}
                            disabled={botConfig.isActive}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        <Form.Group className="mb-3">
                          <Form.Label>Puerto</Form.Label>
                          <Form.Control
                            type="number"
                            value={botConfig.port}
                            onChange={(e) => setBotConfig(prev => ({ ...prev, port: parseInt(e.target.value) || 6667 }))}
                            disabled={botConfig.isActive}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Group className="mb-3">
                      <Form.Label>Nickname del Bot</Form.Label>
                      <Form.Control
                        type="text"
                        value={botConfig.nickname}
                        onChange={(e) => setBotConfig(prev => ({ ...prev, nickname: e.target.value }))}
                        disabled={botConfig.isActive}
                      />
                      <Form.Text className="text-muted">
                        Nicknames alternativos: {botConfig.alternativeNicks.join(', ')}
                      </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Contraseña del Servidor (opcional)</Form.Label>
                      <Form.Control
                        type="password"
                        value={botConfig.password || ''}
                        onChange={(e) => setBotConfig(prev => ({ ...prev, password: e.target.value }))}
                        placeholder="Dejar en blanco si no se requiere"
                        disabled={botConfig.isActive}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check 
                        type="switch"
                        id="ssl-switch"
                        label="Usar conexión SSL/TLS"
                        checked={botConfig.useSSL}
                        onChange={(e) => setBotConfig(prev => ({ ...prev, useSSL: e.target.checked }))}
                        disabled={botConfig.isActive}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Endpoint ML</Form.Label>
                      <Form.Control
                        type="text"
                        value={botConfig.mlEndpoint}
                        onChange={(e) => setBotConfig(prev => ({ ...prev, mlEndpoint: e.target.value }))}
                        disabled={botConfig.isActive}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check 
                        type="switch"
                        id="ml-priority-switch"
                        label="Priorizar modelo ML sobre APIs"
                        checked={botConfig.preferML}
                        onChange={(e) => setBotConfig(prev => ({ ...prev, preferML: e.target.checked }))}
                        disabled={botConfig.isActive}
                      />
                      <Form.Text className="text-muted">
                        Si está activado, se priorizarán las respuestas del modelo ML. Caso contrario, se combinarán respuestas de ML y APIs.
                      </Form.Text>
                    </Form.Group>
                    <div className="d-flex gap-2">
                      <Button
                        variant={botConfig.isActive ? "danger" : "success"}
                        onClick={botConfig.isActive ? handleStopBot : handleStartBot}
                        disabled={loading}
                      >
                        {loading ? (
                          <><Spinner as="span" animation="border" size="sm" /> Cargando...</>
                        ) : (
                          botConfig.isActive ? "Detener Bot" : "Iniciar Bot"
                        )}
                      </Button>
                      
                      {!botConfig.isActive && (
                        <Button
                          variant="outline-info"
                          onClick={handleDiagnoseConnection}
                          disabled={loading}
                        >
                          Diagnosticar Conexión
                        </Button>
                      )}
                    </div>
                  </Form>
                ) : (
                  // Panel de configuración de APIs
                  <div>
                    <h6>Integración con APIs Externas</h6>
                    <p className="text-muted small">Configure las APIs externas que el bot utilizará para obtener información en tiempo real.</p>
                    
                    {botConfig.apis.map((api, index) => (
                      <Card key={index} className="mb-3">
                        <Card.Body>
                          <Row>
                            <Col md={6}>
                              <Form.Group className="mb-2">
                                <Form.Label>Nombre</Form.Label>
                                <Form.Control
                                  type="text"
                                  value={api.name}
                                  onChange={(e) => handleApiConfigChange(index, 'name', e.target.value)}
                                  disabled={botConfig.isActive}
                                  size="sm"
                                />
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group className="mb-2">
                                <Form.Label>Endpoint</Form.Label>
                                <Form.Control
                                  type="text"
                                  value={api.endpoint}
                                  onChange={(e) => handleApiConfigChange(index, 'endpoint', e.target.value)}
                                  disabled={botConfig.isActive}
                                  size="sm"
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <Form.Group className="mb-2">
                                <Form.Label>API Key</Form.Label>
                                <Form.Control
                                  type="password"
                                  value={api.apiKey || ''}
                                  onChange={(e) => handleApiConfigChange(index, 'apiKey', e.target.value)}
                                  disabled={botConfig.isActive}
                                  size="sm"
                                />
                              </Form.Group>
                            </Col>
                            <Col md={4}>
                              <Form.Group className="mb-2">
                                <Form.Label>Prioridad</Form.Label>
                                <Form.Control
                                  type="number"
                                  value={api.priority}
                                  onChange={(e) => handleApiConfigChange(index, 'priority', parseInt(e.target.value) || 5)}
                                  disabled={botConfig.isActive}
                                  size="sm"
                                  min={1}
                                  max={10}
                                />
                              </Form.Group>
                            </Col>
                            <Col md={2} className="d-flex align-items-end mb-2">
                              <Form.Check 
                                type="switch"
                                id={`api-enabled-${index}`}
                                label="Activa"
                                checked={api.enabled}
                                onChange={(e) => handleApiConfigChange(index, 'enabled', e.target.checked)}
                                disabled={botConfig.isActive}
                              />
                            </Col>
                          </Row>
                          <div className="d-flex justify-content-end">
                            <Button
                              variant="outline-danger"
                              size="sm"
                              onClick={() => handleRemoveApi(index)}
                              disabled={botConfig.isActive || botConfig.apis.length <= 1}
                            >
                              Eliminar API
                            </Button>
                          </div>
                        </Card.Body>
                      </Card>
                    ))}
                    
                    <div className="d-flex justify-content-between mt-3">
                      <Button
                        variant="outline-primary"
                        size="sm"
                        onClick={handleAddApi}
                        disabled={botConfig.isActive}
                      >
                        Añadir Nueva API
                      </Button>
                      
                      <Button
                        variant="outline-secondary"
                        size="sm"
                        onClick={() => setShowApiConfig(false)}
                      >
                        Volver a Configuración IRC
                      </Button>
                    </div>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>

          {/* Consola IRC *_/}
          <Col md={6}>
            <Card>
              <Card.Header>
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="mb-0">Consola IRC</h5>
                  <Form.Select
                    style={{ width: 'auto' }}
                    value={selectedChannel}
                    onChange={(e) => setSelectedChannel(e.target.value)}
                  >
                    {botConfig.channels.map(channel => (
                      <option key={channel} value={channel}>{channel}</option>
                    ))}
                  </Form.Select>
                </div>
              </Card.Header>
              <Card.Body>
                <div className="chat-container mb-3" style={{ height: '300px', overflowY: 'auto' }}>
                  {messages
                   .filter(msg => msg.type === 'system' || msg.channel === selectedChannel)
                   .map(message => (
                     <div
                       key={message.id}
                       className={`mb-2 ${
                         message.type === 'system' ? 
                           (message.text.includes('Error') ? 'text-danger' : 'text-muted') :
                         message.type === 'command' ? 'text-primary' : ''
                       }`}
                     >
                       <small>{new Date(message.timestamp).toLocaleTimeString()}</small>
                       <span className="mx-2 fw-bold">{message.sender}:</span>
                       <span>
                         {message.text}
                         {message.source && message.source !== 'user' && (
                           <Badge 
                             bg={
                               message.source === 'ml' ? 'info' :
                               message.source === 'api' ? 'warning' : 'secondary'
                             }
                             className="ms-2"
                           >
                             {message.source === 'ml' ? 'ML' :
                              message.source === 'api' ? 'API' : 'IRC'}
                           </Badge>
                         )}
                       </span>
                     </div>
                   ))}
                   <div ref={messagesEndRef} />
                </div>
                <Form className="d-flex gap-2" onSubmit={(e) => { e.preventDefault(); handleSendCommand(); }}>
                  <Form.Control
                    type="text"
                    value={commandInput}
                    onChange={(e) => setCommandInput(e.target.value)}
                    placeholder="Escribe un comando o mensaje (/help para ayuda)"
                    disabled={!botConfig.isActive || loading}
                  />
                  <Button
                    variant="primary"
                    onClick={handleSendCommand}
                    disabled={!botConfig.isActive || loading || !commandInput.trim()}
                  >
                    <Send size={20} />
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Datos de Actividad *_/}
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <h5 className="mb-0">Actividad del Bot</h5>
              </Card.Header>
              <Card.Body>
                <div style={{ height: '300px' }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={getActivityChartData()}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="time" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line
                        type="monotone"
                        dataKey="messages"
                        stroke="#0d6efd"
                        name="Mensajes Totales"
                      />
                      <Line
                        type="monotone"
                        dataKey="ml"
                        stroke="#17a2b8"
                        name="Consultas ML"
                      />
                      <Line
                        type="monotone"
                        dataKey="api"
                        stroke="#ffc107"
                        name="Consultas API"
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default IRCBotDashboard;  */

/*

/* @v0.1 init
// ~/ANUBISBOTv0.1/src/components/modules/irc/IRCBotDashboard.tsx
import React, { useState, useEffect, useRef } from 'react';
import { Button, Card, Col, Form, Row, Table, Badge, Spinner } from "react-bootstrap";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { MessageSquare, Send, Server, Bot, Activity, Database, Globe, Brain } from 'lucide-react';
import WidgetsSectionTitle from '../widgets/WidgetsSectionTitle';
import { faRobot } from '@fortawesome/free-solid-svg-icons';
import ircService from '../../../service/ircService';

// Interfaces
interface IRCMessage {
  id: number;
  text: string;
  sender: string;
  channel: string;
  timestamp: string;
  type: 'message' | 'command' | 'system';
  source?: 'user' | 'irc' | 'ml' | 'api';
}

interface ProxyConfig {
  enabled: boolean;
  endpoint: string;
  authToken?: string;
}

interface ApiConfig {
  name: string;
  enabled: boolean;
  endpoint: string;
  apiKey?: string;
  priority: number;
}

interface BotConfig {
  server: string;
  port: number;
  nickname: string;
  alternativeNicks: string[];
  channels: string[];
  mlEndpoint: string;
  isActive: boolean;
  useSSL?: boolean;
  password?: string;
  proxy: ProxyConfig;
  apis: ApiConfig[];
  preferML: boolean;
}

interface BotMetrics {
  messagesProcessed: number;
  activeChannels: number;
  uptime: number;
  lastTraining: string;
  mlQueries: number;
  apiQueries: number;
}

interface MessageIntent {
  type: 'direct_command' | 'ml_query' | 'api_query' | 'hybrid' | 'default';
  apiType?: string;
  context?: string;
  confidence?: number;
}

const IRCBotDashboard: React.FC = () => {
  const [messages, setMessages] = useState<IRCMessage[]>([]);
  const [botConfig, setBotConfig] = useState<BotConfig>(() => {
    // Intentar cargar la configuración desde localStorage
    const savedConfig = localStorage.getItem('ircBotConfig');
    return savedConfig ? JSON.parse(savedConfig) : {
      server: 'irc.cl',
      port: 6667,
      nickname: 'GPTLearnerBot',
      alternativeNicks: ['GPTBot', 'MLBot', 'TrainerBot', 'GPTAssistant'],
      channels: ['#training', '#support', '#general'],
      mlEndpoint: 'http://10.10.5.9:5000/train',
      isActive: false,
      useSSL: false,
      proxy: {
        enabled: true,
        endpoint: 'https://localhost:3456/api', //process.env.REACT_APP_API_URL || 'http://localhost:4001/api',
        authToken: ''
      },
      apis: [
        { 
          name: 'Clima',
          enabled: true,
          endpoint: 'https://api.openweathermap.org/data/2.5/weather',
          apiKey: '',
          priority: 2
        },
        { 
          name: 'Noticias',
          enabled: true,
          endpoint: 'https://newsapi.org/v2/everything',
          apiKey: '',
          priority: 3
        }
      ],
      preferML: true
    };
  });
  
  const [metrics, setMetrics] = useState<BotMetrics>({
    messagesProcessed: 0,
    activeChannels: 0,
    uptime: 0,
    lastTraining: new Date().toISOString(),
    mlQueries: 0,
    apiQueries: 0
  });
  
  const [selectedChannel, setSelectedChannel] = useState<string>('#training');
  const [commandInput, setCommandInput] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [currentNick, setCurrentNick] = useState<string>(botConfig.nickname);
  const [connectionErrors, setConnectionErrors] = useState<string[]>([]);
  const [reconnectAttempts, setReconnectAttempts] = useState<number>(0);
  const [showApiConfig, setShowApiConfig] = useState<boolean>(false);
  
  // Guardar configuración en localStorage cuando cambia
  useEffect(() => {
    localStorage.setItem('ircBotConfig', JSON.stringify(botConfig));
  }, [botConfig]);
  
  // Referencia al contenedor de mensajes para auto-scroll
  const messagesEndRef = useRef<HTMLDivElement>(null);

  // Efecto para inicializar los listeners del servicio IRC
  useEffect(() => {
    const messageUnsubscribe = ircService.onMessage((message) => {
      // Procesar mensajes entrantes con ML e integración API
      if (message.from !== 'System' && message.from !== currentNick) {
        processIncomingMessage({
          id: Date.now(),
          text: message.text,
          sender: message.from || 'Unknown',
          channel: message.channel || 'system',
          timestamp: message.timestamp,
          type: 'message',
          source: 'user'
        });
      } else {
        // Mensajes de sistema o propios
        const ircMessage: IRCMessage = {
          id: Date.now(),
          text: message.text,
          sender: message.from || 'Unknown',
          channel: message.channel || 'system',
          timestamp: message.timestamp,
          type: 'message',
          source: 'irc'
        };
        setMessages(prev => [...prev, ircMessage]);
        
        // Incrementar contador de mensajes
        setMetrics(prev => ({
          ...prev,
          messagesProcessed: prev.messagesProcessed + 1
        }));
      }
    });

    const eventUnsubscribe = ircService.onEvent((event) => {
      addSystemMessage(event.text);
    });

    const errorUnsubscribe = ircService.onError((error) => {
      addSystemMessage(error.text, 'error');
      setConnectionErrors(prev => [...prev, error.text]);
    });

    const connectionUnsubscribe = ircService.onConnectionChange((connected) => {
      setBotConfig(prev => ({ ...prev, isActive: connected }));
      if (connected) {
        addSystemMessage('Conexión IRC establecida correctamente');
      } else {
        addSystemMessage('Conexión IRC cerrada');
      }
    });

    // Iniciar contador de uptime si el bot está activo
    let uptimeInterval: NodeJS.Timeout | null = null;
    if (botConfig.isActive) {
      uptimeInterval = setInterval(() => {
        setMetrics(prev => ({
          ...prev,
          uptime: prev.uptime + 1
        }));
      }, 1000);
    }

    // Limpiar suscripciones al desmontar
    return () => {
      messageUnsubscribe();
      eventUnsubscribe();
      errorUnsubscribe();
      connectionUnsubscribe();
      
      if (uptimeInterval) {
        clearInterval(uptimeInterval);
      }
      
      // Desconectar el IRC si el componente se desmonta mientras está conectado
      if (botConfig.isActive) {
        ircService.disconnect().catch(console.error);
      }
    };
  }, [botConfig.isActive, currentNick]);

  // Auto-scroll cuando hay nuevos mensajes
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const handleStartBot = async () => {
    setLoading(true);
    setConnectionErrors([]);
    setReconnectAttempts(0);

    // Modo de simulación para pruebas (elimina esto cuando el backend esté listo)
    const simulationMode = false; // Cambia a false cuando quieras usar el backend real

    if (simulationMode) {
      try {
        addSystemMessage(`Iniciando en modo de simulación...`);
        await new Promise(resolve => setTimeout(resolve, 800));
        
        addSystemMessage(`Conectado a ${botConfig.server}:${botConfig.port} (simulado)`);
        setBotConfig(prev => ({ ...prev, isActive: true }));
        setCurrentNick(botConfig.nickname);
        
        // Simular una conexión exitosa
        addSystemMessage(`Unido a canales: ${botConfig.channels.join(', ')}`);
        
        // Mensaje de bienvenida simulado
        setTimeout(() => {
          const welcomeMsg: IRCMessage = {
            id: Date.now(),
            text: `Bienvenido a ${selectedChannel}! Estoy en modo de simulación para desarrollo.`,
            sender: 'IRCServer',
            channel: selectedChannel,
            timestamp: new Date().toISOString(),
            type: 'message',
            source: 'irc'
          };
          setMessages(prev => [...prev, welcomeMsg]);
        }, 1500);
        
        // Actualizar métricas
        setMetrics(prev => ({
          ...prev,
          activeChannels: botConfig.channels.length,
          uptime: 0,
          mlQueries: 0,
          apiQueries: 0
        }));
        
        setLoading(false);
        return;
      } catch (error: any) {
        // Continuar con el método normal si hay algún error
      }
    }

    try {
      addSystemMessage(`Iniciando diagnóstico de conexión a ${botConfig.server}:${botConfig.port}...`);
      
      addSystemMessage(`Configurando proxy de conexión IRC en el backend...`);
      
      // Intentar conexión a través del servicio
      const connectionId = await ircService.connect({
        server: botConfig.server,
        port: botConfig.port,
        nickname: botConfig.nickname,
        channels: botConfig.channels,
        useSSL: botConfig.useSSL,
        password: botConfig.password
      });
      
      setCurrentNick(botConfig.nickname);
      addSystemMessage(`Conexión establecida con ID: ${connectionId}`);
      
      // Inicializar sistemas de ML y API
      addSystemMessage(`Preparando integración con sistema ML en ${botConfig.mlEndpoint}`);
      addSystemMessage(`Configurando ${botConfig.apis.filter(api => api.enabled).length} APIs externas`);
      
      // Actualizar métricas
      setMetrics(prev => ({
        ...prev,
        activeChannels: botConfig.channels.length,
        uptime: 0,
        mlQueries: 0,
        apiQueries: 0
      }));
      
    } catch (error: any) {
      addSystemMessage(`Error al iniciar el bot: ${error.message}`, 'error');
      setConnectionErrors(prev => [...prev, error.message]);
    } finally {
      setLoading(false);
    }
  };

  const handleStopBot = async () => {
    setLoading(true);
    try {
      // Modo de simulación
      if (true) { // Cambiar a "false" cuando el backend esté listo
        addSystemMessage(`Desconectando modo de simulación...`);
        await new Promise(resolve => setTimeout(resolve, 500));
        
        setBotConfig(prev => ({ ...prev, isActive: false }));
        addSystemMessage('Bot detenido correctamente');
        
        // Reiniciar métricas
        setMetrics(prev => ({
          ...prev,
          uptime: 0
        }));
        
        setLoading(false);
        return;
      }
      
      // Código para desconexión real
      addSystemMessage(`Desconectando de ${botConfig.server}:${botConfig.port}`);
      
      await ircService.disconnect();
      
      addSystemMessage('Bot detenido correctamente');
      setCurrentNick(botConfig.nickname);
      setConnectionErrors([]);
      setReconnectAttempts(0);
      
      // Reiniciar métricas
      setMetrics(prev => ({
        ...prev,
        uptime: 0
      }));
    } catch (error: any) {
      addSystemMessage(`Error al detener el bot: ${error.message}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleSendCommand = async () => {
    if (!commandInput.trim() || !botConfig.isActive) return;
    
    const newMessage: IRCMessage = {
      id: Date.now(),
      text: commandInput,
      sender: 'Admin',
      channel: selectedChannel,
      timestamp: new Date().toISOString(),
      type: 'command',
      source: 'user'
    };

    setMessages(prev => [...prev, newMessage]);
    
    // Modo de simulación (quitar cuando el backend esté listo)
    const simulationMode = false;
    
    if (simulationMode) {
      // Procesar comandos especiales
      if (commandInput.startsWith('/')) {
        if (commandInput.startsWith('/join ')) {
          const channel = commandInput.slice(6).trim();
          if (!botConfig.channels.includes(channel)) {
            setBotConfig(prev => ({
              ...prev,
              channels: [...prev.channels, channel]
            }));
            setSelectedChannel(channel);
            addSystemMessage(`Unido a canal: ${channel}`);
          }
        } else if (commandInput.startsWith('/nick ')) {
          const newNick = commandInput.slice(6).trim();
          setCurrentNick(newNick);
          addSystemMessage(`Nickname cambiado a ${newNick}`);
        } else if (commandInput.startsWith('/help')) {
          addSystemMessage('Comandos disponibles:');
          addSystemMessage('/join #canal - Unirse a un nuevo canal');
          addSystemMessage('/train - Entrenar el modelo ML con datos del canal');
          addSystemMessage('/status - Mostrar estado del bot');
          addSystemMessage('/apis - Mostrar estado de las APIs configuradas');
          addSystemMessage('/ml - Mostrar estadísticas del sistema ML');
          addSystemMessage('/nick nombre - Cambiar nickname');
          addSystemMessage('/help - Mostrar este mensaje de ayuda');
        } else if (commandInput.startsWith('/train')) {
          addSystemMessage(`Iniciando entrenamiento ML con datos de ${selectedChannel}`);
          setTimeout(() => {
            addSystemMessage('Entrenamiento ML en progreso...');
            setTimeout(() => {
              addSystemMessage('Entrenamiento completado. Modelo actualizado.');
              setMetrics(prev => ({
                ...prev,
                lastTraining: new Date().toISOString()
              }));
            }, 3000);
          }, 1000);
        } else if (commandInput.startsWith('/apis')) {
          // Mostrar estado de las APIs
          addSystemMessage('APIs configuradas:');
          botConfig.apis.forEach(api => {
            addSystemMessage(`${api.name}: ${api.enabled ? 'Activa' : 'Inactiva'} - Prioridad: ${api.priority}`);
          });
        } else if (commandInput.startsWith('/ml')) {
          // Mostrar estadísticas de ML
          addSystemMessage(`Sistema ML: ${botConfig.mlEndpoint}`);
          addSystemMessage(`Consultas realizadas: ${metrics.mlQueries}`);
          addSystemMessage(`Último entrenamiento: ${new Date(metrics.lastTraining).toLocaleString()}`);
          addSystemMessage(`Prioridad ML: ${botConfig.preferML ? 'Alta' : 'Normal'}`);
        } else if (commandInput.startsWith('/status')) {
          addSystemMessage(`Estado del bot: ${botConfig.isActive ? 'Activo' : 'Inactivo'}`);
          addSystemMessage(`Conectado a: ${botConfig.server}:${botConfig.port}`);
          addSystemMessage(`Canales: ${botConfig.channels.join(', ')}`);
          addSystemMessage(`Tiempo en línea: ${formatUptime(metrics.uptime)}`);
        } else {
          addSystemMessage(`Comando ${commandInput} no reconocido. Usa /help para ver la lista de comandos.`);
        }
      } else {
        // Mensaje normal - procesar con ML/API
        processIncomingMessage(newMessage);
      }
      
      setCommandInput('');
      return;
    }
    
    // Código original para enviar comandos al backend real
    try {
      if (commandInput.startsWith('/')) {
        // Comando IRC
        await ircService.sendMessage(selectedChannel, commandInput);
        
        // Procesar comandos especiales
        if (commandInput.startsWith('/join ')) {
          const channel = commandInput.slice(6).trim();
          if (!botConfig.channels.includes(channel)) {
            setBotConfig(prev => ({
              ...prev,
              channels: [...prev.channels, channel]
            }));
            setSelectedChannel(channel);
          }
        } else if (commandInput.startsWith('/nick ')) {
          const newNick = commandInput.slice(6).trim();
          setCurrentNick(newNick);
        } else if (commandInput.startsWith('/help')) {
          addSystemMessage('Comandos disponibles:');
          addSystemMessage('/join #canal - Unirse a un nuevo canal');
          addSystemMessage('/train - Entrenar el modelo ML con datos del canal');
          addSystemMessage('/status - Mostrar estado del bot');
          addSystemMessage('/apis - Mostrar estado de las APIs configuradas');
          addSystemMessage('/ml - Mostrar estadísticas del sistema ML');
          addSystemMessage('/nick nombre - Cambiar nickname');
          addSystemMessage('/help - Mostrar este mensaje de ayuda');
        } else if (commandInput.startsWith('/train')) {
          addSystemMessage(`Iniciando entrenamiento ML con datos de ${selectedChannel}`);
          setTimeout(() => {
            addSystemMessage('Entrenamiento ML en progreso...');
            setTimeout(() => {
              addSystemMessage('Entrenamiento completado. Modelo actualizado.');
              setMetrics(prev => ({
                ...prev,
                lastTraining: new Date().toISOString()
              }));
            }, 3000);
          }, 1000);
        } else if (commandInput.startsWith('/apis')) {
          // Mostrar estado de las APIs
          addSystemMessage('APIs configuradas:');
          botConfig.apis.forEach(api => {
            addSystemMessage(`${api.name}: ${api.enabled ? 'Activa' : 'Inactiva'} - Prioridad: ${api.priority}`);
          });
        } else if (commandInput.startsWith('/ml')) {
          // Mostrar estadísticas de ML
          addSystemMessage(`Sistema ML: ${botConfig.mlEndpoint}`);
          addSystemMessage(`Consultas realizadas: ${metrics.mlQueries}`);
          addSystemMessage(`Último entrenamiento: ${new Date(metrics.lastTraining).toLocaleString()}`);
          addSystemMessage(`Prioridad ML: ${botConfig.preferML ? 'Alta' : 'Normal'}`);
        }
      } else {
        // Mensaje normal
        await ircService.sendMessage(selectedChannel, commandInput);
      }
    } catch (error: any) {
      addSystemMessage(`Error al enviar mensaje: ${error.message}`, 'error');
    }
    
    setCommandInput('');
  };

  const addSystemMessage = (text: string, type: 'info' | 'error' | 'warning' = 'info') => {
    const systemMessage: IRCMessage = {
      id: Date.now(),
      text,
      sender: 'System',
      channel: 'system',
      timestamp: new Date().toISOString(),
      type: 'system',
      source: 'irc'
    };
    setMessages(prev => [...prev, systemMessage]);
    
    // Registrar errores si corresponde
    if (type === 'error') {
      setConnectionErrors(prev => [...prev, text]);
    }
  };

  const handleDiagnoseConnection = () => {
    addSystemMessage('Ejecutando diagnóstico de conexión...');
    setTimeout(() => {
      addSystemMessage(`Prueba de conexión a ${botConfig.server}:${botConfig.port}...`);
      setTimeout(() => {
        addSystemMessage('Hay conectividad de red básica.');
        addSystemMessage(`Verificando accesibilidad del servidor IRC...`);
        setTimeout(() => {
          addSystemMessage(`Nota: Los navegadores no pueden conectarse directamente a servidores IRC.`);
          addSystemMessage(`Se requiere un backend o proxy para establecer la conexión real.`);
          
          if (botConfig.server === 'irc.cl') {
            addSystemMessage(`Verificación manual del servidor ${botConfig.server}:${botConfig.port} mediante telnet muestra que SÍ está disponible.`);
            addSystemMessage(`Recomendación: Configure el Proxy IRC en el backend para establecer la conexión.`);
          } else {
            addSystemMessage(`No se puede verificar directamente ${botConfig.server} desde el navegador.`);
            addSystemMessage(`Si ha comprobado manualmente que el servidor está disponible, proceda a configurar el Proxy IRC.`);
          }
        }, 1000);
      }, 800);
    }, 500);
  };

  // Función para formatear el tiempo de uptime
  const formatUptime = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  // ----- NUEVAS FUNCIONES PARA INTEGRACIÓN DE ML Y API -----
  
  // Función para analizar la intención del mensaje
  const analyzeMessageIntent = async (text: string): Promise<MessageIntent> => {
    // Implementación simple basada en keywords para v0.1
    // En versiones futuras, esto podría usar un modelo de NLP más sofisticado
    if (text.startsWith('/')) {
      return { type: 'direct_command' };
    } else if (text.match(/clima|temperatura|lluvia|sol|pronóstico/i)) {
      return { type: 'api_query', apiType: 'weather', confidence: 0.8 };
    } else if (text.match(/noticias|noticia|últimas noticias|actualidad/i)) {
      return { type: 'api_query', apiType: 'news', confidence: 0.8 };
    } else if (text.match(/aprende|recuerda|dime|explícame|información sobre/i)) {
      return { type: 'ml_query', context: 'learning', confidence: 0.7 };
    } else {
      // Por defecto, intentar con ML primero si está configurado así
      return { type: botConfig.preferML ? 'ml_query' : 'hybrid', context: 'conversation', confidence: 0.5 };
    }
  };

  // Procesar mensajes entrantes
  const processIncomingMessage = async (message: IRCMessage) => {
    // Añadir mensaje del usuario para mantener el contexto
    if (message.source !== 'user') {
      setMessages(prev => [...prev, message]);
    }
    
    if (!botConfig.isActive || message.sender === currentNick) return;
    
    // No procesar comandos IRC
    if (message.text.startsWith('/')) return;
    
    // Analizar intención del mensaje
    try {
      // Simular un breve "pensando..."
      setTimeout(async () => {
        // Determinar la intención del mensaje
        const intent = await analyzeMessageIntent(message.text);
        
        let responseText = '';
        let responseSource: 'ml' | 'api' | 'irc' = 'irc';
        
        // Procesar según la intención
        switch (intent.type) {
          case 'ml_query':
            responseText = await simulateMLResponse(message.text);
            responseSource = 'ml';
            setMetrics(prev => ({ ...prev, mlQueries: prev.mlQueries + 1 }));
            break;
            
          case 'api_query':
            responseText = await simulateAPIResponse(message.text, intent.apiType || 'general');
            responseSource = 'api';
            setMetrics(prev => ({ ...prev, apiQueries: prev.apiQueries + 1 }));
            break;
            
          case 'hybrid':
            // Combinar ML y API (priorizar según configuración)
            if (botConfig.preferML) {
              responseText = await simulateMLResponse(message.text);
              responseSource = 'ml';
              setMetrics(prev => ({ ...prev, mlQueries: prev.mlQueries + 1 }));
            } else {
              responseText = `Respuesta combinada:\n- ML: ${await simulateMLResponse(message.text, true)}\n- API: ${await simulateAPIResponse(message.text, 'general', true)}`;
              responseSource = 'ml'; // Marcar como ML pero es híbrido
              setMetrics(prev => ({ 
                ...prev, 
                mlQueries: prev.mlQueries + 1,
                apiQueries: prev.apiQueries + 1
              }));
            }
            break;
            
          default:
            responseText = `No entiendo completamente tu consulta. ¿Podrías reformularla?`;
            responseSource = 'irc';
            break;
        }
        
        // Añadir la respuesta a los mensajes
        const botResponse: IRCMessage = {
          id: Date.now(),
          text: responseText,
          sender: currentNick,
          channel: message.channel,
          timestamp: new Date().toISOString(),
          type: 'message',
          source: responseSource
        };
        
        setMessages(prev => [...prev, botResponse]);
        
        // En una implementación real, aquí enviarías la respuesta al canal IRC
        // await ircService.sendMessage(message.channel, responseText);
        
        // Actualizar métricas
        setMetrics(prev => ({
          ...prev,
          messagesProcessed: prev.messagesProcessed + 1
        }));
      }, 1000); // Simular tiempo de procesamiento
    } catch (error: any) {
      console.error("Error al procesar mensaje:", error);
      addSystemMessage(`Error al procesar mensaje: ${error.message}`, 'error');
    }
  };

  // Simular respuesta del modelo ML (en v0.1 es simulado, en el futuro conectará con tu backend ML)
  const simulateMLResponse = async (text: string, brief: boolean = false): Promise<string> => {
    // Versión simplificada para v0.1
    // En el futuro, esto se conectaría con tu modelo ML real
    
    // Simular latencia de red/procesamiento
    await new Promise(resolve => setTimeout(resolve, 700));
    
    const responses: {[key: string]: string} = {
      "hola": "¡Hola! ¿En qué puedo ayudarte hoy?",
      "ayuda": "Puedo responder preguntas, proporcionar información y buscar datos en tiempo real. ¿Sobre qué tema necesitas ayuda?",
      "que puedes hacer": "Puedo procesar preguntas, aprender de conversaciones, y conectarme a APIs externas para obtener información actualizada.",
      "quien eres": `Soy ${currentNick}, un bot IRC con capacidades de aprendizaje automático. Estoy en fase de desarrollo v0.1.`,
    };
    
    // Buscar palabras clave
    for (const [key, response] of Object.entries(responses)) {
      if (text.toLowerCase().includes(key)) {
        return brief ? response.split('.')[0] : response;
      }
    }
    
    // Respuesta por defecto
    return brief 
      ? "Estoy procesando tu consulta con mi modelo de ML." 
      : `He analizado tu mensaje "${text}" y he generado esta respuesta basada en mi entrenamiento. Este es un ejemplo simulado de respuesta ML que en el futuro se conectará con tu sistema ML existente.`;
  };

  // Simular respuesta de APIs externas
  const simulateAPIResponse = async (text: string, apiType: string, brief: boolean = false): Promise<string> => {
    // Versión simulada para v0.1
    // En el futuro, esto se conectaría con APIs reales
    
    // Simular latencia de red
    await new Promise(resolve => setTimeout(resolve, 500));
    
    switch (apiType) {
      case 'weather':
        return brief 
          ? "Temperatura: 22°C, Parcialmente nublado" 
          : "El clima actual es: 22°C, Parcialmente nublado. Existe 20% de probabilidad de lluvia. Humedad: 65%.";
      
      case 'news':
        return brief 
          ? "Última noticia: Avances en IA" 
          : "Últimas noticias: 'Nuevos avances en Inteligencia Artificial permiten mayor personalización de respuestas', 'Desarrolladores implementan sistemas conversacionales mejorados'.";
        
      default:
        return brief 
          ? "Obteniendo datos en tiempo real..." 
          : `He consultado fuentes externas para responder a "${text}". Esta es una respuesta simulada que en el futuro se conectará con APIs reales para proporcionar datos actualizados.`;
    }
  };

  // Función para actualizar la configuración de una API
  const handleApiConfigChange = (index: number, field: keyof ApiConfig, value: any) => {
    setBotConfig(prev => {
      const newApis = [...prev.apis];
      newApis[index] = {
        ...newApis[index],
        [field]: value
      };
      return {
        ...prev,
        apis: newApis
      };
    });
  };
  
  // Función para añadir una nueva API
  const handleAddApi = () => {
    setBotConfig(prev => ({
      ...prev,
      apis: [
        ...prev.apis,
        {
          name: `API ${prev.apis.length + 1}`,
          enabled: true,
          endpoint: '',
          priority: 5,
          apiKey: ''
        }
      ]
    }));
  };
  
  // Función para eliminar una API
  const handleRemoveApi = (index: number) => {
    setBotConfig(prev => ({
      ...prev,
      apis: prev.apis.filter((_, i) => i !== index)
    }));
  };

  // Crear datos de actividad basados en métricas reales
  const getActivityChartData = () => {
    const now = new Date();
    const pastHours = Array.from({length: 6}, (_, i) => {
      const hour = new Date(now);
      hour.setHours(now.getHours() - (5 - i));
      return {
        timetime: hour.getHours() + ':00',
        messages: Math.floor(Math.random() * metrics.messagesProcessed / 6),
        ml: Math.floor(Math.random() * metrics.mlQueries / 6),
        api: Math.floor(Math.random() * metrics.apiQueries / 6)
      };
    });
    return pastHours;
  };

  return (
    <>
      <WidgetsSectionTitle
        title="IRC Bot ML Training Integration"
        subtitle="Gestión y monitoreo de entrenamiento ML a través de canales IRC"
        icon={faRobot}
        className="my-5"
      />
      <div className="p-4">
        {/* Bot Status and Controls /}
        <Row className="mb-4">
          <Col md={3}>
            <Card>
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <p className="text-muted mb-1">Estado del Bot</p>
                    <h3 className="mb-0">
                      {botConfig.isActive ? 
                        <span className="text-success">Activo</span> : 
                        <span className="text-danger">Inactivo</span>
                      }
                    </h3>
                    {botConfig.isActive && (
                      <small className="text-muted">Conectado como {currentNick}</small>
                    )}
                    {connectionErrors.length > 0 && (
                      <div className="mt-2">
                        <small className="text-danger">
                          {reconnectAttempts > 0 && `Intentos de reconexión: ${reconnectAttempts}`}
                        </small>
                      </div>
                    )}
                  </div>
                  <Bot size={24} className={botConfig.isActive ? 'text-success' : 'text-danger'} />
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card>
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <p className="text-muted mb-1">Mensajes</p>
                    <h3 className="mb-0">{metrics.messagesProcessed}</h3>
                    <small className="text-muted">Tiempo: {formatUptime(metrics.uptime)}</small>
                  </div>
                  <MessageSquare size={24} className="text-primary" />
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card>
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <p className="text-muted mb-1">Consultas ML</p>
                    <h3 className="mb-0">{metrics.mlQueries}</h3>
                    <div className="d-flex align-items-center">
                      <Badge bg={botConfig.preferML ? "primary" : "secondary"} className="me-1">
                        {botConfig.preferML ? "Prioridad Alta" : "Normal"}
                      </Badge>
                    </div>
                  </div>
                  <Brain size={24} className="text-info" />
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card>
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <p className="text-muted mb-1">APIs Externas</p>
                    <h3 className="mb-0">{metrics.apiQueries}</h3>
                    <small className="text-muted">
                      {botConfig.apis.filter(api => api.enabled).length} activas
                    </small>
                  </div>
                  <Globe size={24} className="text-warning" />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mb-4">
          {/* Configuración del Bot /}
          <Col md={6}>
            <Card>
              <Card.Header className="d-flex justify-content-between align-items-center">
                <h5 className="mb-0">Configuración del Bot</h5>
                <Button 
                  variant="outline-secondary" 
                  size="sm"
                  onClick={() => setShowApiConfig(!showApiConfig)}
                >
                  {showApiConfig ? "Configuración IRC" : "Configuración APIs"}
                </Button>
              </Card.Header>
              <Card.Body>
                {!showApiConfig ? (
                  <Form>
                    <Row>
                      <Col md={8}>
                        <Form.Group className="mb-3">
                          <Form.Label>Servidor IRC</Form.Label>
                          <Form.Control
                            type="text"
                            value={botConfig.server}
                            onChange={(e) => setBotConfig(prev => ({ ...prev, server: e.target.value }))}
                            disabled={botConfig.isActive}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        <Form.Group className="mb-3">
                          <Form.Label>Puerto</Form.Label>
                          <Form.Control
                            type="number"
                            value={botConfig.port}
                            onChange={(e) => setBotConfig(prev => ({ ...prev, port: parseInt(e.target.value) || 6667 }))}
                            disabled={botConfig.isActive}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Group className="mb-3">
                      <Form.Label>Nickname del Bot</Form.Label>
                      <Form.Control
                        type="text"
                        value={botConfig.nickname}
                        onChange={(e) => setBotConfig(prev => ({ ...prev, nickname: e.target.value }))}
                        disabled={botConfig.isActive}
                      />
                      <Form.Text className="text-muted">
                        Nicknames alternativos: {botConfig.alternativeNicks.join(', ')}
                      </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Contraseña del Servidor (opcional)</Form.Label>
                      <Form.Control
                        type="password"
                        value={botConfig.password || ''}
                        onChange={(e) => setBotConfig(prev => ({ ...prev, password: e.target.value }))}
                        placeholder="Dejar en blanco si no se requiere"
                        disabled={botConfig.isActive}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check 
                        type="switch"
                        id="ssl-switch"
                        label="Usar conexión SSL/TLS"
                        checked={botConfig.useSSL}
                        onChange={(e) => setBotConfig(prev => ({ ...prev, useSSL: e.target.checked }))}
                        disabled={botConfig.isActive}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Endpoint ML</Form.Label>
                      <Form.Control
                        type="text"
                        value={botConfig.mlEndpoint}
                        onChange={(e) => setBotConfig(prev => ({ ...prev, mlEndpoint: e.target.value }))}
                        disabled={botConfig.isActive}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check 
                        type="switch"
                        id="ml-priority-switch"
                        label="Priorizar modelo ML sobre APIs"
                        checked={botConfig.preferML}
                        onChange={(e) => setBotConfig(prev => ({ ...prev, preferML: e.target.checked }))}
                        disabled={botConfig.isActive}
                      />
                      <Form.Text className="text-muted">
                        Si está activado, se priorizarán las respuestas del modelo ML. Caso contrario, se combinarán respuestas de ML y APIs.
                      </Form.Text>
                    </Form.Group>
                    <div className="d-flex gap-2">
                      <Button
                        variant={botConfig.isActive ? "danger" : "success"}
                        onClick={botConfig.isActive ? handleStopBot : handleStartBot}
                        disabled={loading}
                      >
                        {loading ? (
                          <><Spinner as="span" animation="border" size="sm" /> Cargando...</>
                        ) : (
                          botConfig.isActive ? "Detener Bot" : "Iniciar Bot"
                        )}
                      </Button>
                      
                      {!botConfig.isActive && (
                        <Button
                          variant="outline-info"
                          onClick={handleDiagnoseConnection}
                          disabled={loading}
                        >
                          Diagnosticar Conexión
                        </Button>
                      )}
                    </div>
                  </Form>
                ) : (
                  // Panel de configuración de APIs
                  <div>
                    <h6>Integración con APIs Externas</h6>
                    <p className="text-muted small">Configure las APIs externas que el bot utilizará para obtener información en tiempo real.</p>
                    
                    {botConfig.apis.map((api, index) => (
                      <Card key={index} className="mb-3">
                        <Card.Body>
                          <Row>
                            <Col md={6}>
                              <Form.Group className="mb-2">
                                <Form.Label>Nombre</Form.Label>
                                <Form.Control
                                  type="text"
                                  value={api.name}
                                  onChange={(e) => handleApiConfigChange(index, 'name', e.target.value)}
                                  disabled={botConfig.isActive}
                                  size="sm"
                                />
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group className="mb-2">
                                <Form.Label>Endpoint</Form.Label>
                                <Form.Control
                                  type="text"
                                  value={api.endpoint}
                                  onChange={(e) => handleApiConfigChange(index, 'endpoint', e.target.value)}
                                  disabled={botConfig.isActive}
                                  size="sm"
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <Form.Group className="mb-2">
                                <Form.Label>API Key</Form.Label>
                                <Form.Control
                                  type="password"
                                  value={api.apiKey || ''}
                                  onChange={(e) => handleApiConfigChange(index, 'apiKey', e.target.value)}
                                  disabled={botConfig.isActive}
                                  size="sm"
                                />
                              </Form.Group>
                            </Col>
                            <Col md={4}>
                              <Form.Group className="mb-2">
                                <Form.Label>Prioridad</Form.Label>
                                <Form.Control
                                  type="number"
                                  value={api.priority}
                                  onChange={(e) => handleApiConfigChange(index, 'priority', parseInt(e.target.value) || 5)}
                                  disabled={botConfig.isActive}
                                  size="sm"
                                  min={1}
                                  max={10}
                                />
                              </Form.Group>
                            </Col>
                            <Col md={2} className="d-flex align-items-end mb-2">
                              <Form.Check 
                                type="switch"
                                id={`api-enabled-${index}`}
                                label="Activa"
                                checked={api.enabled}
                                onChange={(e) => handleApiConfigChange(index, 'enabled', e.target.checked)}
                                disabled={botConfig.isActive}
                              />
                            </Col>
                          </Row>
                          <div className="d-flex justify-content-end">
                            <Button
                              variant="outline-danger"
                              size="sm"
                              onClick={() => handleRemoveApi(index)}
                              disabled={botConfig.isActive || botConfig.apis.length <= 1}
                            >
                              Eliminar API
                            </Button>
                          </div>
                        </Card.Body>
                      </Card>
                    ))}
                    
                    <div className="d-flex justify-content-between mt-3">
                      <Button
                        variant="outline-primary"
                        size="sm"
                        onClick={handleAddApi}
                        disabled={botConfig.isActive}
                      >
                        Añadir Nueva API
                      </Button>
                      
                      <Button
                        variant="outline-secondary"
                        size="sm"
                        onClick={() => setShowApiConfig(false)}
                      >
                        Volver a Configuración IRC
                      </Button>
                    </div>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>

          {/* Consola IRC /}
          <Col md={6}>
            <Card>
              <Card.Header>
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="mb-0">Consola IRC</h5>
                  <Form.Select
                    style={{ width: 'auto' }}
                    value={selectedChannel}
                    onChange={(e) => setSelectedChannel(e.target.value)}
                  >
                    {botConfig.channels.map(channel => (
                      <option key={channel} value={channel}>{channel}</option>
                    ))}
                  </Form.Select>
                </div>
              </Card.Header>
              <Card.Body>
                <div className="chat-container mb-3" style={{ height: '300px', overflowY: 'auto' }}>
                  {messages
                   .filter(msg => msg.type === 'system' || msg.channel === selectedChannel)
                   .map(message => (
                     <div
                       key={message.id}
                       className={`mb-2 ${
                         message.type === 'system' ? 
                           (message.text.includes('Error') ? 'text-danger' : 'text-muted') :
                         message.type === 'command' ? 'text-primary' : ''
                       }`}
                     >
                       <small>{new Date(message.timestamp).toLocaleTimeString()}</small>
                       <span className="mx-2 fw-bold">{message.sender}:</span>
                       <span>
                         {message.text}
                         {message.source && message.source !== 'user' && (
                           <Badge 
                             bg={
                               message.source === 'ml' ? 'info' :
                               message.source === 'api' ? 'warning' : 'secondary'
                             }
                             className="ms-2"
                           >
                             {message.source === 'ml' ? 'ML' :
                              message.source === 'api' ? 'API' : 'IRC'}
                           </Badge>
                         )}
                       </span>
                     </div>
                   ))}
                   <div ref={messagesEndRef} />
                </div>
                <Form className="d-flex gap-2" onSubmit={(e) => { e.preventDefault(); handleSendCommand(); }}>
                  <Form.Control
                    type="text"
                    value={commandInput}
                    onChange={(e) => setCommandInput(e.target.value)}
                    placeholder="Escribe un comando o mensaje (/help para ayuda)"
                    disabled={!botConfig.isActive || loading}
                  />
                  <Button
                    variant="primary"
                    onClick={handleSendCommand}
                    disabled={!botConfig.isActive || loading || !commandInput.trim()}
                  >
                    <Send size={20} />
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Datos de Actividad /}
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <h5 className="mb-0">Actividad del Bot</h5>
              </Card.Header>
              <Card.Body>
                <div style={{ height: '300px' }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={getActivityChartData()}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="time" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line
                        type="monotone"
                        dataKey="messages"
                        stroke="#0d6efd"
                        name="Mensajes Totales"
                      />
                      <Line
                        type="monotone"
                        dataKey="ml"
                        stroke="#17a2b8"
                        name="Consultas ML"
                      />
                      <Line
                        type="monotone"
                        dataKey="api"
                        stroke="#ffc107"
                        name="Consultas API"
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default IRCBotDashboard;
*/
