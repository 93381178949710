import React, { useEffect, useState } from 'react';
import WhatsAppConversationProgress from 'components/modules/whatsapp/charts/WhatsAppConversationsCharts';
import axios from 'axios';

type LanguageType = 'es' | 'en';

interface Conversation {
  id: string;
  name: string;
  lastMessageTime: string;
  firstMessageTime: string;
  messageCount: number;
  preview: string;
}

interface CustomWhatsAppChartProps {
  language: LanguageType;
}

// URL y API key para el endpoint de WhatsApp
const API_BASE_URL = 'https://backend.anubisai.net/api/whatsapp/bot';
const API_KEY = 'anubis-whatsapp-api-key';

const CustomWhatsAppChart: React.FC<CustomWhatsAppChartProps> = ({ language }) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  
  useEffect(() => {
    // Cargar los datos de conversaciones y luego modificar el gráfico
    fetchConversationsAndUpdateChart();
  }, []); // Eliminamos la dependencia de language
  
  const fetchConversationsAndUpdateChart = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/conversations`, {
        headers: { 'x-api-key': API_KEY }
      });
      
      if (response.data.success) {
        const conversations = response.data.conversations;
        
        // Ordenar por número de mensajes (de mayor a menor)
        const sortedConversations = [...conversations].sort((a, b) => 
          b.messageCount - a.messageCount
        );
        
        // Tomar los primeros 5 contactos (o menos si no hay suficientes)
        const topContacts = sortedConversations.slice(0, 5);
        
        // Una vez cargados los datos, actualizar las etiquetas del gráfico
        // usando manipulación del DOM después de que el componente se monte
        setTimeout(() => {
          updateChartLabels(topContacts);
          setDataLoaded(true);
        }, 300);
      }
    } catch (error) {
      console.error('Error al cargar los datos de conversaciones:', error);
      setDataLoaded(true); // Marcar como cargado incluso con error para evitar bucles
    }
  };
  
  const updateChartLabels = (contacts: Conversation[]) => {
    try {
      // Intentar encontrar y modificar los elementos del gráfico en el DOM
      // Nota: esto depende de la estructura interna del componente WhatsAppConversationProgress
      
      // Opciones comunes para etiquetas de gráficos
      const chartLabelSelectors = [
        '.chartjs-render-monitor .chart-legend li',    // Para Chart.js
        '.recharts-legend-item-text',                  // Para Recharts
        '.apexcharts-legend-text',                     // Para ApexCharts
        'text.highcharts-legend-item > tspan',         // Para Highcharts
        '.chart-label',                                // Clase personalizada
        '.custom-chart-label',                         // Otra clase personalizada
        '[data-testid="chart-label"]',                 // Por atributo data-testid
        '.chart-container .legend-item'                // Estructura anidada
      ];
      
      // Probar cada selector para encontrar los elementos de etiqueta
      for (const selector of chartLabelSelectors) {
        const labels = document.querySelectorAll(selector);
        
        if (labels && labels.length > 0) {
          console.log(`Encontrados ${labels.length} elementos con selector: ${selector}`);
          
          // Modificar las etiquetas que tengamos disponibles
          labels.forEach((label, index) => {
            if (index < contacts.length) {
              // Asegurarse de que el nombre no sea demasiado largo
              const displayName = contacts[index].name.length > 15 
                ? contacts[index].name.substring(0, 12) + '...' 
                : contacts[index].name;
              
              // Modificar el texto del elemento
              label.textContent = displayName;
            }
          });
          
          // Si encontramos y modificamos etiquetas, salir del bucle
          break;
        }
      }
      
      // Buscar también los valores de datos (pueden estar como <text>, <tspan>, etc.)
      const chartValueSelectors = [
        '.chartjs-render-monitor .chart-value',
        '.recharts-text.recharts-cartesian-axis-tick-value tspan',
        '.apexcharts-yaxis-label tspan',
        '.highcharts-yaxis-labels text',
        '.chart-value',
        '.custom-chart-value',
        '[data-testid="chart-value"]'
      ];
      
      // Esto es opcional, solo si necesitamos modificar también los valores
      for (const selector of chartValueSelectors) {
        const values = document.querySelectorAll(selector);
        
        if (values && values.length > 0) {
          console.log(`Encontrados ${values.length} elementos de valor con selector: ${selector}`);
          
          // Aquí podríamos actualizar los valores si fuera necesario
          // Por simplicidad, no lo implementamos en este ejemplo
        }
      }
      
    } catch (error) {
      console.error('Error al actualizar las etiquetas del gráfico:', error);
    }
  };
  
  return (
    <>
      {/* Renderizar el componente original sin pasarle propiedades */}
      <WhatsAppConversationProgress language={'es'} />
      
      {/* Renderizar un componente oculto que ayuda a disparar la actualización de las etiquetas */}
      {!dataLoaded && (
        <div className="d-none">Cargando...</div>
      )}
    </>
  );
};

export default CustomWhatsAppChart;