import { WizardFormData } from 'pages/modules/forms/WizardExample';
import { useWizardFormContext } from 'providers/WizardFormProvider';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReactSelect from 'components/base/ReactSelect';
import { useUserContext } from 'layouts/UserContext';
import { IUser } from 'data/models/user/IUser';

const WizardNSFormAccount = ({ id }: { id: string }) => {
  const methods = useWizardFormContext<WizardFormData>();
  const { formData, onChange, validation } = methods;

  const { user } = useUserContext();

  const childAccounts: IUser[] = user?.children || [];

  return (
    <>
      <Form.Group className="mb-2">
        <Form.Label className="text-body">Name Neuroskill</Form.Label>
        <Form.Control
          type="text"
          name="name"
          placeholder="Name to display"
          value={formData.name || ''}
          onChange={onChange}
          required={validation}
        />
        <Form.Control.Feedback type="invalid">
          This field is required.
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label>Email*</Form.Label>
        <Form.Control
          type="email"
          name="email"
          placeholder="Email address"
          value={formData.email || ''}
          onChange={onChange}
          required={validation}
        />
        <Form.Control.Feedback type="invalid">
          This field is required.
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label>Members*</Form.Label>
        <ReactSelect
          options={childAccounts.map((account) => ({
            value: account._id,
            label: account.name + ' ' + account.lastName
          }))}
          isMulti
          placeholder="Select members..."
          />
              { /* <ReactSelect
               options={[
                {
                  value: 'all',
                  label: 'Add All members registered'
                }
              ]}
              isMulti
              placeholder="Select members registered..."
              /> */ }
               <Form.Control.Feedback type="invalid">
          This field is required.
        </Form.Control.Feedback>
      </Form.Group>
      <Row className="g-3 mb-3">
        <Col>
          <Form.Group className="mb-2">
            <Form.Label className="text-body">Description*</Form.Label>
            <Form.Control
              as="textarea"
              value={formData.address}
              onChange={onChange}
              rows={4}
              required={validation}
            />
            <Form.Control.Feedback type="invalid">
              This field is required.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Form.Check className="form-check">
        <Form.Check.Input
          type="checkbox"
          name="terms"
          id={`${id}-terms`}
          checked={formData.accept_terms}
          onChange={onChange}
          required={validation}
        />
        <Form.Check.Label className="text-body" htmlFor={`${id}-terms`}>
          I accept the <Link to="#!">terms</Link> and{' '}
          <Link to="#!">privacy policy</Link>
        </Form.Check.Label>
      </Form.Check>
    </>
  );
};

export default WizardNSFormAccount;