import React, { useEffect, useState, useCallback, useContext } from 'react';
import classNames from 'classnames';
import { Container } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import NavbarDual from 'components/navbars/navbar-dual/NavbarDual';
import NavbarTopHorizontal from 'components/navbars/navbar-horizontal/NavbarTopHorizontal';
import NavbarTopDefault from 'components/navbars/navbar-top/NavbarTopDefault';
import NavbarVertical from 'components/navbars/navbar-vertical/NavbarVertical';
import { IUser } from 'data/models/user/IUser';
import { UserService } from 'service/userService';
import { useAppContext } from 'providers/AppProvider';
import { useMainLayoutContext } from 'providers/MainLayoutProvider';
import { UserContext } from './UserContext';
import ChatWidget from 'components/common/help-widget/ChatWidget';
import Footer from 'components/footers/Footer';

const MainLayout: React.FC = () => {
  const {
    config: { navbarPosition }
  } = useAppContext();
  const { contentClass, footerClass } = useMainLayoutContext();
  const userContext = useContext(UserContext);

  const canAccessChat = userContext?.user?.groups?.some(group => 
    group.group.name === 'Shell Users'
  );

  return (
    <Container fluid className="p-0" >
       {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
          <NavbarVertical />
        )}
        {navbarPosition === 'vertical' && <NavbarTopDefault />}
       {(navbarPosition === 'horizontal' || navbarPosition === 'combo') && (
          <NavbarTopHorizontal />
        )}
       {navbarPosition === 'dual' && <NavbarDual />}
        <div className={classNames(
          contentClass, 
          'content'
        )}>
          <Outlet />
          <Footer className={classNames(footerClass, 'position-absolute')} />
          {canAccessChat && <ChatWidget />} {/* sin restricción */}
      </div>
    </Container>
  );
  
  /*
  return (
    <UserContext.Provider value={{ user, isLoading, error, refetchUser: fetchUser }}>
      <Container fluid className="px-0">
        {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
          <NavbarVertical />
        )}
        {navbarPosition === 'vertical' && <NavbarTopDefault />}
        {(navbarPosition === 'horizontal' || navbarPosition === 'combo') && (
          <NavbarTopHorizontal />
        )}
        {navbarPosition === 'dual' && <NavbarDual />}
        <div className={classNames(contentClass, 'content', 'mt-0')} style={{ paddingTop: '5rem' }} draggable='false'>
          <Outlet />
       {/*}   <Footer className={classNames(footerClass, 'position-absolute')} /> }
          {/*<ChatWidget />*/ /*} 
        </div>
      </Container>
    </UserContext.Provider>
  );
  */
};

export default MainLayout;
