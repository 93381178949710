import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { Link } from 'react-router-dom';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import Button from 'components/base/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Project, Status, projects } from 'data/project-management/projects';
import Avatar from 'components/base/Avatar';
import { Col, Form, Modal, ProgressBar, Row } from 'react-bootstrap';
import classNames from 'classnames';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/UsersTokensActionDropdownItems';
import AvatarDropdown from 'components/common/AvatarDropdown';
import { faFileExport, faPlus, faXmarkCircle, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import SearchBox from 'components/common/SearchBox';
import { ChangeEvent, useState } from 'react';

import NSWizardNav from 'components/wizard/WizardNSRegisterNav';
import WizardNSForm from 'components/wizard/WizardNSForm';
import WizardNSFormAccount from 'components/forms/WizardNSFormAccount';
import WizardPersonalForm from 'components/forms/WizardNSPersonal';
import WizardModuleForm from 'components/forms/WizardNSModuleForm';
import WizardNSFormSuccessStep from 'components/wizard/WizardNSFormSuccessStep';


import moduleImg from 'assets/img/Anubis/Hazball.png';

import WizardFormFooter from 'components/wizard/WizardFormFooter';


import useWizardForm from 'hooks/useWizardForm';
import WizardFormProvider from 'providers/WizardFormProvider';
import { Card, Tab } from 'react-bootstrap';
import { INeuroSkill } from 'data/models/neuro/INeuroSkill';
import { useUserContext } from 'layouts/UserContext';

const columns: ColumnDef<INeuroSkill>[] = [
  {
    accessorKey: 'name',
    header: 'NeuroSkill',
    cell: ({ row: { original } }) => {
      const { displayName } = original;
      return (
        <Link
          to="#"
          className="text-decoration-none fw-bold fs-9"
        >
          {displayName}
        </Link>
      );
    },
    meta: {
      cellProps: { className: 'white-space-nowrap' },
      headerProps: { style: { width: '30%' } }
    }
  },
  {
    id: 'assigness',
    header: 'Modules',
    cell: ({ row: { original } }) => {
      const { modules } = original;

      const dummyUser = {
        id: 1,
        name: 'Dummy',
        avatar: moduleImg,
        username: 'dummyuser',
        connections: 0,
        mutual: 0
      }

      if (modules) {
        return (
          <Avatar.Group
            total={modules.length}
            size="s"
            className="overflow-hidden"
          >
            {modules.slice(0, 4).map(module => (
              <AvatarDropdown user={dummyUser} size="s" key={module.identifier} />
            ))}
          </Avatar.Group>
        );
      }

      return (
        <Button variant="phoenix-secondary" className="btn-icon">
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      );
    },
    meta: {
      cellProps: { className: 'ps-3' },
      headerProps: { style: { width: '10%' }, className: 'ps-3' }
    }
  },
  {
    header: 'Register date',
    accessorKey: 'createdAt',
    meta: {
      cellProps: { className: 'ps-3 fs-9 text-body white-space-nowrap' },
      headerProps: { style: { width: '10%' }, className: 'ps-3' }
    }
  },
  {
    header: 'Last Update',
    accessorKey: 'updatedAt',
    meta: {
      cellProps: { className: 'ps-3 fs-9 text-body white-space-nowrap' },
      headerProps: { style: { width: '15%' }, className: 'ps-3' }
    }
  },
  {
    id: 'calculation',
    header: 'Certified',
    cell: ({ row: { original } }) => {
      const certified = { original };

      if (certified) {
        return (
          <>
            <p className="fw-bold text-body-emphasis fs-9 mb-0">
              <FontAwesomeIcon icon={faCircleCheck} />
            </p>
          </>
        );
      }
      return (
        <>
          <p className="fw-bold text-body-emphasis fs-9 mb-0">
            <FontAwesomeIcon icon={faXmarkCircle} />
          </p>
        </>
      );
    },
    meta: {
      cellProps: { className: 'ps-3' },
      headerProps: { style: { width: '12%' }, className: 'ps-3' }
    }
  }/*,
  {
    id: 'progress',
    header: 'Training',
    cell: ({ row: { original } }) => {
      return (
        <>
          <p className="text-body-secondary fs-10 mb-0">
            {10} / {100}
          </p>
          <ProgressBar
            now={(10 / 100) * 100}
            style={{ height: 3 }}
            variant="success"
          />
        </>
      );
    },
    meta: {
      cellProps: { className: 'ps-3' },
      headerProps: { style: { width: '5%' }, className: 'ps-3' }
    }
  },
  {
    header: 'Status',
    id: 'status',
    cell: ({ row: { original } }) => {
      const statusProgress: Status = {
        ongoing: 40,
        critical: 20,
        inactive: 10,
        completed: 30,
      }; // datos de ej

      return (
        <ProgressBar style={{ height: 3 }} className="progress-stack">
          {Object.keys(statusProgress).map(item => (
            <ProgressBar
              variant={classNames({
                success: item === 'completed',
                info: item === 'ongoing',
                danger: item === 'inactive',
                warning: item === 'critical'
              })}
              now={statusProgress[item as keyof Status]}
              key={item}
            />
          ))}
        </ProgressBar>
      );
    },
    meta: {
      cellProps: { className: 'ps-8' },
      headerProps: { style: { width: '10%' }, className: 'ps-8' }
    }
  }*/,
  {
    id: 'action',
    cell: () => (
      <RevealDropdownTrigger>
        <RevealDropdown>
          <ActionDropdownItems />
        </RevealDropdown>
      </RevealDropdownTrigger>
    ),
    meta: {
      headerProps: { style: { width: '10%' }, className: 'text-end' },
      cellProps: { className: 'text-end' }
    }
  }
];

const NeuroSkillsDashboardTable = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const { user } = useUserContext();
  const table = useAdvanceTable({
    data: user?.botInfo.neuroSkills as INeuroSkill[],
    columns,
    pageSize: 6,
    pagination: true,
    sortable: true
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  
  };

  interface WizardFormData {
    name: string;
    accept_terms: boolean;
    email: string;
    password: string;
    confirm_password: string;
    gender: string;
    phone: string;
    dob: string;
    address: string;
    card: number;
    country: string;
    zip: number;
    date_of_expire: string;
    cvv: number;
  }

  const form = useWizardForm<WizardFormData>({
    totalStep: 4,
    validation: true
  });

  return (
    <div>
      <Row>
        <div className="mb-4">
          <Row className="g-3">
            <Col xs="auto">
              <SearchBox
                placeholder="Search NeuroSkills"
                onChange={handleSearchInputChange} />
            </Col>
            <Col
              xs="auto"
              className="scrollbar overflow-hidden-y flex-grow-1"
            ></Col>
            <Col xs="auto">
              <Button disabled variant="link" className="text-body me-4 px-0">
                <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                Export
              </Button>
              <Button variant="primary" onClick={handleShow}>
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                Add neuroskill
              </Button>
            </Col>
          </Row>
        </div>
      </Row>
      <AdvanceTableProvider {...table}>
        <AdvanceTable
          tableProps={{
            className: 'phoenix-table border-top border-translucent fs-9'
          }}
        />
        <AdvanceTableFooter pagination />
      </AdvanceTableProvider>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add new NeuroSkill</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <WizardFormProvider {...form}>
            <Card className="theme-wizard">
              <Card.Header className="bg-body-highlight pt-3 pb-2 border-bottom-0">
                <NSWizardNav />
              </Card.Header>
              <Card.Body>
                <Tab.Content>
                  <Tab.Pane eventKey={1}>
                    <WizardNSForm step={1}>
                      <WizardNSFormAccount  id='validation'/>
                    </WizardNSForm>
                  </Tab.Pane>
                  <Tab.Pane eventKey={2}>
                    <WizardNSForm step={2}>
                      <WizardPersonalForm />
                    </WizardNSForm>
                  </Tab.Pane>
                  <Tab.Pane eventKey={3}>
                    <WizardNSForm step={3}>
                      <WizardModuleForm />
                    </WizardNSForm>
                  </Tab.Pane>
                  <Tab.Pane eventKey={4}>
                    <WizardNSFormSuccessStep />
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>

        <Card.Footer className="border-top-0">
          <WizardFormFooter 
            className={classNames({ 'd-none': !form.getCanNextPage })}
          />
        </Card.Footer>
      </Card>
          </WizardFormProvider>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default NeuroSkillsDashboardTable;
