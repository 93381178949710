import { useState, useEffect } from 'react';
import { Table, Button, Spinner, Alert, Modal, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrashAlt, faPlus, faSync, faRobot } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { useUserContext } from 'layouts/UserContext';

type LanguageType = 'es' | 'en';

// Interface for CoPilot prompts
interface ICoPilotPrompt {
  _id: string;
  name: string;
  prompt: string;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
}

type CoPilotPromptsTableProps = {
  language: LanguageType;
};

const API_BASE_URL = 'https://backend.anubisai.net/api/whatsapp/bot';
const API_KEY = 'anubis-whatsapp-api-key';

const CoPilotPromptsTable = ({ language }: CoPilotPromptsTableProps) => {
  const { user, isLoading } = useUserContext();
  const [prompts, setPrompts] = useState<ICoPilotPrompt[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isTraining, setIsTraining] = useState(false);
  const [trainingSuccess, setTrainingSuccess] = useState(false);
  
  // Modal states
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState<'add' | 'edit'>('add');
  const [currentPrompt, setCurrentPrompt] = useState<ICoPilotPrompt | null>(null);
  
  // Form states
  const [promptName, setPromptName] = useState('');
  const [promptContent, setPromptContent] = useState('');
  const [promptActive, setPromptActive] = useState(true);

  // Function to train the CoPilot model
  const handleTrainModel = async () => {
    try {
      setIsTraining(true);
      setError(null);
      
      // API call to trigger model training
      const response = await axios.post(`${API_BASE_URL}/copilot/train`, 
        { 
          userId: user?._id,
          processName: 'WSAPP-SRV_' + (user?.username?.charAt(0) || '')
        },
        { 
          headers: { 'x-api-key': API_KEY },
          timeout: 30000 // 30 second timeout for longer operations
        }
      );
      
      if (response.data.success) {
        setTrainingSuccess(true);
        setTimeout(() => setTrainingSuccess(false), 3000); // Hide success message after 3 seconds
      } else {
        setError(response.data.message || 'Error al entrenar el modelo');
      }
    } catch (error) {
      console.error('Error training CoPilot model:', error);
      setError(language === 'es' 
        ? 'Error al entrenar el modelo de CoPilot' 
        : 'Error training CoPilot model');
    } finally {
      setIsTraining(false);
    }
  };

  // Fetch CoPilot prompts on component mount
  useEffect(() => {
    // Add mock data for development/testing
    if (process.env.NODE_ENV === 'development') {
      loadMockData();
    } else {
      fetchPrompts();
    }
  }, []);
  
  // Load mock data for development/testing
  const loadMockData = () => {
    const mockPrompts: ICoPilotPrompt[] = [
      {
        _id: '1',
        name: 'Respuestas Comerciales',
        prompt: 'Eres un asistente comercial de nuestra empresa. Tu objetivo es proporcionar información sobre nuestros productos y servicios de manera amable y profesional. Si te preguntan por precios específicos, indica que un representante se pondrá en contacto para proporcionar una cotización personalizada.',
        isActive: true,
        createdAt: new Date(Date.now() - 7 * 86400000).toISOString(),
        updatedAt: new Date(Date.now() - 2 * 86400000).toISOString()
      },
      {
        _id: '2',
        name: 'Soporte Técnico',
        prompt: 'Eres un asistente de soporte técnico. Tu objetivo es ayudar a resolver problemas técnicos comunes. Para problemas complejos, debes recopilar información básica y señalar que un técnico especializado se pondrá en contacto.',
        isActive: true,
        createdAt: new Date(Date.now() - 14 * 86400000).toISOString(),
        updatedAt: new Date(Date.now() - 14 * 86400000).toISOString()
      },
      {
        _id: '3',
        name: 'Preguntas Frecuentes',
        prompt: 'Eres un asistente informativo. Tu objetivo es responder a las preguntas frecuentes sobre nuestra empresa, horarios, ubicaciones y políticas generales. Mantén tus respuestas concisas y directas.',
        isActive: false,
        createdAt: new Date(Date.now() - 30 * 86400000).toISOString(),
        updatedAt: new Date(Date.now() - 10 * 86400000).toISOString()
      }
    ];
    
    setPrompts(mockPrompts);
    setIsLoaded(true);
  };

  const fetchPrompts = async () => {
    try {
      setIsLoaded(false);
      
      // Simulate API call - replace with actual implementation
      // This would be replaced with a real API call in production
      const response = await axios.get(`${API_BASE_URL}/copilot/prompts`, {
        headers: { 'x-api-key': API_KEY },
        params: { userId: user?._id }
      });
      
      if (response.data.success) {
        setPrompts(response.data.prompts || []);
      } else {
        setError(response.data.message || 'Error loading prompts');
      }
    } catch (error) {
      console.error('Error fetching CoPilot prompts:', error);
      setError(language === 'es' 
        ? 'Error al cargar los prompts de CoPilot' 
        : 'Error loading CoPilot prompts');
      
      // Use mock data as fallback if API fails
      loadMockData();
    } finally {
      setIsLoaded(true);
    }
  };

  // Open modal for adding a new prompt
  const handleAddPrompt = () => {
    setModalMode('add');
    setPromptName('');
    setPromptContent('');
    setPromptActive(true);
    setCurrentPrompt(null);
    setShowModal(true);
  };

  // Open modal for editing an existing prompt
  const handleEditPrompt = (prompt: ICoPilotPrompt) => {
    setModalMode('edit');
    setPromptName(prompt.name);
    setPromptContent(prompt.prompt);
    setPromptActive(prompt.isActive);
    setCurrentPrompt(prompt);
    setShowModal(true);
  };

  // Handle prompt deletion
  const handleDeletePrompt = async (id: string) => {
    if (window.confirm(language === 'es' 
      ? '¿Está seguro que desea eliminar este prompt?' 
      : 'Are you sure you want to delete this prompt?')) {
      
      try {
        setIsSubmitting(true);
        
        // Simulate API call - replace with actual implementation
        const response = await axios.delete(`${API_BASE_URL}/copilot/prompts/${id}`, {
          headers: { 'x-api-key': API_KEY }
        });
        
        if (response.data.success) {
          // Update local state
          setPrompts(prompts.filter(prompt => prompt._id !== id));
        } else {
          setError(response.data.message || 'Error deleting prompt');
        }
      } catch (error) {
        console.error('Error deleting prompt:', error);
        setError(language === 'es' 
          ? 'Error al eliminar el prompt' 
          : 'Error deleting prompt');
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  // Handle form submission
  const handleSubmit = async () => {
    if (!promptName.trim() || !promptContent.trim()) {
      setError(language === 'es' 
        ? 'El nombre y el contenido del prompt son obligatorios' 
        : 'Prompt name and content are required');
      return;
    }
    
    try {
      setIsSubmitting(true);
      
      const promptData = {
        name: promptName.trim(),
        prompt: promptContent.trim(),
        isActive: promptActive,
        userId: user?._id
      };
      
      let response;
      
      if (modalMode === 'add') {
        // Create new prompt
        response = await axios.post(`${API_BASE_URL}/copilot/prompts`, promptData, {
          headers: { 'x-api-key': API_KEY }
        });
      } else {
        // Update existing prompt
        response = await axios.put(`${API_BASE_URL}/copilot/prompts/${currentPrompt?._id}`, promptData, {
          headers: { 'x-api-key': API_KEY }
        });
      }
      
      if (response.data.success) {
        // Refresh prompts list
        await fetchPrompts();
        setShowModal(false);
      } else {
        setError(response.data.message || 'Error saving prompt');
      }
    } catch (error) {
      console.error('Error saving prompt:', error);
      setError(language === 'es' 
        ? 'Error al guardar el prompt' 
        : 'Error saving prompt');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle modal close
  const handleCloseModal = () => {
    setShowModal(false);
    setError(null);
  };

  // Loading state
  if (!isLoaded || isLoading) {
    return (
      <div className="text-center my-5">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">
            {language === 'es' ? 'Cargando...' : 'Loading...'}
          </span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className="mt-4">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h4>
          {language === 'es' ? 'Prompts de CoPilot' : 'CoPilot Prompts'}
        </h4>
        <div>
          <Button 
            variant="success" 
            className="me-2"
            onClick={handleTrainModel}
            disabled={isSubmitting || isTraining || prompts.length === 0}
          >
            <FontAwesomeIcon icon={faSync} spin={isTraining} className="me-2" />
            {isTraining 
              ? (language === 'es' ? 'Entrenando...' : 'Training...') 
              : (language === 'es' ? 'Entrenar Modelo' : 'Train Model')}
          </Button>
          <Button 
            variant="primary" 
            onClick={handleAddPrompt}
            disabled={isSubmitting || isTraining}
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            {language === 'es' ? 'Nuevo Prompt' : 'New Prompt'}
          </Button>
        </div>
      </div>
      
      {trainingSuccess && (
        <Alert variant="success" className="mb-3">
          <i className="fas fa-check-circle me-2"></i>
          {language === 'es' 
            ? 'El modelo de CoPilot ha sido entrenado exitosamente' 
            : 'CoPilot model has been trained successfully'}
        </Alert>
      )}
      
      {error && (
        <Alert variant="danger" onClose={() => setError(null)} dismissible>
          {error}
        </Alert>
      )}
      
      {prompts.length === 0 ? (
        <Alert variant="info">
          {language === 'es' 
            ? 'No hay prompts de CoPilot configurados. Puede agregar un nuevo prompt para personalizar el comportamiento del asistente.' 
            : 'No CoPilot prompts configured. You can add a new prompt to customize the assistant behavior.'}
        </Alert>
      ) : (
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>{language === 'es' ? 'Nombre' : 'Name'}</th>
              <th>{language === 'es' ? 'Prompt' : 'Prompt'}</th>
              <th className="text-center" style={{ width: '100px' }}>
                {language === 'es' ? 'Activo' : 'Active'}
              </th>
              <th className="text-center" style={{ width: '120px' }}>
                {language === 'es' ? 'Acciones' : 'Actions'}
              </th>
            </tr>
          </thead>
          <tbody>
            {prompts.map((prompt) => (
              <tr key={prompt._id}>
                <td>{prompt.name}</td>
                <td>
                  <div className="prompt-content" style={{ maxHeight: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {prompt.prompt.length > 200 
                      ? `${prompt.prompt.substring(0, 200)}...` 
                      : prompt.prompt}
                  </div>
                </td>
                <td className="text-center">
                  <Form.Check 
                    type="switch"
                    checked={prompt.isActive}
                    onChange={async () => {
                      try {
                        const response = await axios.put(`${API_BASE_URL}/copilot/prompts/${prompt._id}/toggle`, 
                          { isActive: !prompt.isActive },
                          { headers: { 'x-api-key': API_KEY } }
                        );
                        
                        if (response.data.success) {
                          // Update local state
                          setPrompts(prompts.map(p => 
                            p._id === prompt._id ? {...p, isActive: !p.isActive} : p
                          ));
                        }
                      } catch (error) {
                        console.error('Error toggling prompt status:', error);
                        setError(language === 'es' 
                          ? 'Error al cambiar el estado del prompt' 
                          : 'Error changing prompt status');
                      }
                    }}
                    disabled={isSubmitting}
                  />
                </td>
                <td className="text-center">
                  <Button 
                    variant="outline-primary" 
                    size="sm" 
                    className="me-2"
                    onClick={() => handleEditPrompt(prompt)}
                    disabled={isSubmitting}
                    title={language === 'es' ? 'Editar' : 'Edit'}
                  >
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </Button>
                  <Button 
                    variant="outline-danger" 
                    size="sm"
                    onClick={() => handleDeletePrompt(prompt._id)}
                    disabled={isSubmitting}
                    title={language === 'es' ? 'Eliminar' : 'Delete'}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      
      {/* Modal for adding/editing prompts */}
      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {modalMode === 'add' 
              ? (language === 'es' ? 'Nuevo Prompt de CoPilot' : 'New CoPilot Prompt')
              : (language === 'es' ? 'Editar Prompt de CoPilot' : 'Edit CoPilot Prompt')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && (
            <Alert variant="danger">
              {error}
            </Alert>
          )}
          
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>{language === 'es' ? 'Nombre del Prompt' : 'Prompt Name'}</Form.Label>
              <Form.Control 
                type="text" 
                value={promptName}
                onChange={(e) => setPromptName(e.target.value)}
                placeholder={language === 'es' ? 'Ingrese un nombre descriptivo' : 'Enter a descriptive name'}
                disabled={isSubmitting}
              />
              <Form.Text className="text-muted">
                {language === 'es' 
                  ? 'Un nombre claro que identifique el propósito de este prompt' 
                  : 'A clear name that identifies the purpose of this prompt'}
              </Form.Text>
            </Form.Group>
            
            <Form.Group className="mb-3">
              <Form.Label>{language === 'es' ? 'Contenido del Prompt' : 'Prompt Content'}</Form.Label>
              <Form.Control 
                as="textarea" 
                rows={10}
                value={promptContent}
                onChange={(e) => setPromptContent(e.target.value)}
                placeholder={language === 'es' 
                  ? 'Escriba las instrucciones para el modelo de IA...' 
                  : 'Write the instructions for the AI model...'}
                disabled={isSubmitting}
              />
              <Form.Text className="text-muted">
                {language === 'es' 
                  ? 'Instrucciones detalladas sobre cómo debe comportarse el asistente de IA' 
                  : 'Detailed instructions about how the AI assistant should behave'}
              </Form.Text>
            </Form.Group>
            
            <Form.Group className="mb-3">
              <Form.Check 
                type="switch"
                id="prompt-active-switch"
                label={language === 'es' ? 'Prompt Activo' : 'Active Prompt'}
                checked={promptActive}
                onChange={(e) => setPromptActive(e.target.checked)}
                disabled={isSubmitting}
              />
              <Form.Text className="text-muted">
                {language === 'es' 
                  ? 'Solo los prompts activos serán utilizados por el sistema' 
                  : 'Only active prompts will be used by the system'}
              </Form.Text>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal} disabled={isSubmitting}>
            {language === 'es' ? 'Cancelar' : 'Cancel'}
          </Button>
          <Button 
            variant="primary" 
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <>
                <Spinner animation="border" size="sm" className="me-2" />
                {language === 'es' ? 'Guardando...' : 'Saving...'}
              </>
            ) : (
              language === 'es' ? 'Guardar' : 'Save'
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CoPilotPromptsTable;