import React, { useState, useEffect } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { TooltipComponent, LegendComponent, GridComponent } from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import axios from 'axios';
import { Spinner, Card } from 'react-bootstrap';
import dayjs from 'dayjs';

// Register ECharts components
echarts.use([TooltipComponent, LegendComponent, GridComponent, LineChart, CanvasRenderer]);

type LanguageType = 'es' | 'en';

// Definición de las interfaces
interface ConversationStat {
  contactId: string;
  contactName: string;
  date: string;
  messageCount: number;
}

interface MessagesByDateTime {
  [dateTime: string]: {
    [contactId: string]: number;
  };
}

interface WhatsAppConversationsChartsProps {
  language: LanguageType;
  days?: number; // Number of days to display, default is 7
}

interface EChartSeries {
  name: string;
  type: string;
  symbolSize: number;
  data: number[];
  lineStyle?: {
    type?: string;
    width?: number;
  };
}

interface TooltipParam {
  dataIndex: number;
  seriesName: string;
  color: string;
  value: number;
}

interface ChartData {
  dateTimes: string[];
  series: EChartSeries[];
}

// URL del proxy a WhatsApp API
const API_BASE_URL = 'https://backend.anubisai.net/api/whatsapp/bot';
// Asegúrate de que esta sea la API key correcta que usas en otros endpoints
const API_KEY = 'anubis-whatsapp-api-key';
 
const WhatsAppConversationsCharts: React.FC<WhatsAppConversationsChartsProps> = ({
  language,
  days = 2
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [conversationStats, setMessages] = useState<ConversationStat[]>([]);
  const [contactNames, setContacts] = useState<Map<string, string>>(new Map());

  useEffect(() => {
    fetchMessageData();
  }, [days]);

  const fetchMessageData = async () => {
    try {
      setLoading(true);
      
      // Obtener estadísticas diarias de mensajes por contacto
      // La ruta debe coincidir con el endpoint del proxy
      const response = await axios.get(`${API_BASE_URL}/message-stats`, {
        headers: { 
          'x-api-key': API_KEY,
          'Content-Type': 'application/json'
        },
        params: { days }
      });
      
      console.log('API Response:', response.data);
      
      if (response.data.success) {
        // Verificar si los datos son los esperados
        if (response.data.stats && Array.isArray(response.data.stats)) {
          // Obtener el momento actual
          const now = dayjs();
          
          // Generar intervalos de 6 horas hacia atrás desde la hora actual
          const timeIntervals: {date: string, hour: number}[] = [];
          
          for (let i = 0; i < days * 4; i++) { // 4 intervalos de 6 horas por día
            const timeslot = now.subtract(i * 6, 'hour');
            timeIntervals.push({
              date: timeslot.format('YYYY-MM-DD'),
              hour: Math.floor(timeslot.hour() / 6) * 6
            });
          }
          
          // Distribuir mensajes en estos intervalos de tiempo
          const processedStats: ConversationStat[] = [];
          
          // Distribuir los mensajes entre los intervalos de tiempo disponibles
          response.data.stats.forEach((stat: any, index: number) => {
            if (!stat.contactId) return;
            
            // Seleccionar un intervalo de tiempo para este mensaje (distribución cíclica)
            const interval = timeIntervals[index % timeIntervals.length];
            
            // Formatear la hora en formato de 2 dígitos
            const hourStr = String(interval.hour).padStart(2, '0');
            
            // Crear la fecha completa con hora
            const dateTime = `${interval.date}T${hourStr}:00:00`;
            
            console.log(`Procesando mensaje: contactId=${stat.contactId}, fecha-hora=${dateTime}, count=${stat.messageCount}`);
            
            // Crear un nuevo stat con la fecha-hora asignada
            processedStats.push({
              contactId: stat.contactId,
              contactName: stat.contactName || stat.contactId,
              date: dateTime,
              messageCount: stat.messageCount || 1
            });
          });
          
          // Si no hay datos procesados, agregar un punto en el tiempo actual
          if (processedStats.length === 0 && response.data.stats.length > 0) {
            const stat = response.data.stats[0];
            processedStats.push({
              contactId: stat.contactId,
              contactName: stat.contactName || stat.contactId,
              date: now.format('YYYY-MM-DD[T]HH:mm:00'),
              messageCount: 0
            });
          }
          
          console.log('Estadísticas procesadas con intervalos desde ahora hacia atrás:', processedStats);
          setMessages(processedStats);
        } else {
          console.error('Formato de datos incorrecto:', response.data);
          setMessages([]);
        }
        
        // Crear un mapa de IDs de contacto a nombres para uso posterior
        const namesMap = new Map<string, string>();
        if (response.data.stats && Array.isArray(response.data.stats)) {
          response.data.stats.forEach((stat: any) => {
            if (stat.contactId && !namesMap.has(stat.contactId)) {
              namesMap.set(stat.contactId, stat.contactName || stat.contactId);
            }
          });
        }
        setContacts(namesMap);
      } else {
        setError(response.data.message || (language === 'es' ? 'Error al cargar mensajes' : 'Error loading messages'));
      }
    } catch (error) {
      console.error('Error fetching WhatsApp messages:', error);
      setError(language === 'es' 
        ? 'No se pudieron cargar los mensajes. Intente más tarde.' 
        : 'Could not load messages. Please try again later.');
      setMessages([]);
    } finally {
      setLoading(false);
    }
  };

  const prepareChartData = (): ChartData => {
    // Obtener el momento actual
    const now = dayjs();
    
    // Generar array de fechas+horas para los últimos 'days' días con intervalos de 6 horas
    // pero partiendo exactamente desde la hora actual hacia atrás
    const dateTimeArray: string[] = [];
    
    // Redondear la hora actual al intervalo de 6 horas más cercano que ya ha pasado
    // 0-5 → 00:00, 6-11 → 06:00, 12-17 → 12:00, 18-23 → 18:00
    const currentHour = now.hour();
    const currentMinute = now.minute();
    const currentBlockStart = Math.floor(currentHour / 6) * 6;
    
    // Formatear la hora actual exacta
    const exactCurrentTime = `${String(currentHour).padStart(2, '0')}:${String(currentMinute).padStart(2, '0')}`;
    
    // Agregar primero el momento exacto actual
    dateTimeArray.push(`${now.format('YYYY-MM-DD')}T${exactCurrentTime}:00`);
    
    // Luego calcular los intervalos de 6 horas hacia atrás hasta completar los días requeridos
    let totalIntervals = (days * 4); // 4 intervalos de 6 horas por día
    
    for (let i = 1; i < totalIntervals; i++) {
      // Restar 6 horas por cada paso hacia atrás
      const dateTime = now.subtract(i * 6, 'hour');
      // Redondear a la hora más cercana hacia abajo en múltiplos de 6
      const hour = Math.floor(dateTime.hour() / 6) * 6;
      const hourFormatted = String(hour).padStart(2, '0');
      
      dateTimeArray.push(`${dateTime.format('YYYY-MM-DD')}T${hourFormatted}:00:00`);
    }
    
    // Invertir el array para que vaya desde la fecha más antigua hasta la actual
    dateTimeArray.reverse();
    
    console.log('Fechas-horas a mostrar (desde más antigua a actual):', dateTimeArray);
    console.log('Hora actual exacta:', exactCurrentTime);

    // Organizar mensajes por fecha-hora y contacto
    const messagesByDateTime: MessagesByDateTime = {};
    dateTimeArray.forEach(dateTime => {
      messagesByDateTime[dateTime] = {};
    });

    // Protección contra conversationStats indefinido
    if (!conversationStats || !Array.isArray(conversationStats)) {
      console.error('ConversationStats is undefined or not an array');
      return {
        dateTimes: dateTimeArray,
        series: [
          {
            name: language === 'es' ? 'Total de mensajes' : 'Total messages',
            type: 'line',
            symbolSize: 6,
            data: dateTimeArray.map(() => 0),
            lineStyle: {
              type: 'dashed',
              width: 2
            }
          }
        ]
      };
    }
    
    // Procesar cada estadística y contar por fecha-hora y contacto
    conversationStats.forEach(stat => {
      if (!stat || !stat.date || !stat.contactId) {
        console.warn('Estructura de estadística inválida:', stat);
        return;
      }
      
      // La fecha ya viene con formato YYYY-MM-DDThh:mm:ss
      const dateTimeKey = stat.date;
      
      // Solo contar estadísticas dentro de nuestro rango de fechas-horas
      if (messagesByDateTime[dateTimeKey]) {
        if (!messagesByDateTime[dateTimeKey][stat.contactId]) {
          messagesByDateTime[dateTimeKey][stat.contactId] = 0;
        }
        messagesByDateTime[dateTimeKey][stat.contactId] += stat.messageCount || 0;
      } else {
        // Intentar encontrar el intervalo de 6 horas más cercano
        const statDate = dayjs(dateTimeKey);
        let closestDateTimeKey = null;
        let minDiff = Infinity;
        
        dateTimeArray.forEach(dateTime => {
          const diff = Math.abs(dayjs(dateTime).diff(statDate, 'hour'));
          if (diff < minDiff) {
            minDiff = diff;
            closestDateTimeKey = dateTime;
          }
        });
        
        if (closestDateTimeKey && minDiff <= 6) { // Solo asignar si está a 6 horas o menos
          if (!messagesByDateTime[closestDateTimeKey][stat.contactId]) {
            messagesByDateTime[closestDateTimeKey][stat.contactId] = 0;
          }
          messagesByDateTime[closestDateTimeKey][stat.contactId] += stat.messageCount || 0;
        } else {
          console.warn(`Fecha-hora fuera de rango y sin intervalo cercano: ${dateTimeKey}`);
        }
      }
    });
    
    console.log('Mensajes procesados por fecha-hora:', messagesByDateTime);

    // Obtener IDs de contacto únicos de las estadísticas
    const uniqueContactIds = Array.from(
      new Set(conversationStats.filter(s => s && s.contactId).map(stat => stat.contactId))
    );
    
    // Create series for each contact
    const series: EChartSeries[] = uniqueContactIds.map(contactId => {
      const data = dateTimeArray.map(dateTime => 
        messagesByDateTime[dateTime]?.[contactId] || 0
      );
      
      return {
        name: contactNames.get(contactId) || (contactId ? contactId.substring(0, 10) + '...' : 'Unknown'),
        type: 'line',
        symbolSize: 6,
        data
      };
    });

    // Add total messages series
    const totalData = dateTimeArray.map(dateTime => {
      let total = 0;
      if (messagesByDateTime[dateTime]) {
        Object.values(messagesByDateTime[dateTime]).forEach(count => {
          total += count;
        });
      }
      return total;
    });

    series.push({
      name: language === 'es' ? 'Total de mensajes' : 'Total messages',
      type: 'line',
      symbolSize: 6,
      data: totalData,
      lineStyle: {
        type: 'dashed',
        width: 2
      }
    });

    return {
      dateTimes: dateTimeArray,
      series
    };
  };

  const getChartOptions = () => {
    const { dateTimes, series } = prepareChartData();
    
    // Colors for the series
    const colors: string[] = ['#4CAF50', '#2196F3', '#FF9800', '#9C27B0', '#E91E63', '#F44336', 
                             '#8BC34A', '#03A9F4', '#FFC107', '#673AB7', '#795548', '#607D8B'];
    
    // Format dates for X-axis - mostrar de manera más legible
    const formattedLabels: string[] = dateTimes.map((dateTime, index) => {
      const dt = dayjs(dateTime);
      
      // Para el punto final (hora actual exacta)
      if (index === dateTimes.length - 1) {
        return dt.format('HH:mm (Ahora)');
      }
      
      // Para los demás puntos, mostrar hora y fecha cuando cambia el día
      const prevDate = index > 0 ? dayjs(dateTimes[index - 1]).format('DD') : '';
      const currentDate = dt.format('DD');
      
      // Si cambia el día o es el primer elemento, mostrar fecha y hora
      if (prevDate !== currentDate || index === 0) {
        return dt.format('D MMM, HH:mm');
      } else {
        // Solo mostrar la hora para los puntos intermedios del mismo día
        return dt.format('HH:mm');
      }
    });
    
    return {
      color: colors,
      tooltip: {
        trigger: 'axis' as const,
        formatter: function(params: TooltipParam[]) {
          // Formatear la fecha y hora para el tooltip
          const dateTime = dayjs(dateTimes[params[0].dataIndex]);
          let result = dateTime.format('DD MMM, YYYY HH:mm') + '<br/>';
          
          // Sort to have total at the end
          const sortedParams = [...params].sort((a, b) => {
            if (a.seriesName === (language === 'es' ? 'Total de mensajes' : 'Total messages')) return 1;
            if (b.seriesName === (language === 'es' ? 'Total de mensajes' : 'Total messages')) return -1;
            return 0;
          });
          
          sortedParams.forEach(param => {
            result += `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${param.color};"></span> ${param.seriesName}: ${param.value}<br/>`;
          });
          return result;
        }
      },
      legend: {
        bottom: '5%',
        data: series.map(s => s.name),
        itemWidth: 16,
        itemHeight: 8,
        itemGap: 10,
        type: 'scroll',
        pageButtonPosition: 'end'
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '15%',
        top: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'category' as const,
        boundaryGap: false,
        data: formattedLabels,
        axisLine: {
          lineStyle: {
            color: '#E0E0E0'
          }
        },
        axisLabel: {
          formatter: (value: string, index: number) => value,
          interval: 0, // Mostrar todas las etiquetas
          rotate: 45, // Rotar las etiquetas para mejor legibilidad
          textStyle: {
            fontSize: 11 // Reducir el tamaño de fuente para evitar superposición
          }
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#E0E0E0',
            type: 'dashed' as const
          }
        }
      },
      yAxis: {
        type: 'value' as const,
        name: language === 'es' ? 'Mensajes' : 'Messages',
        nameLocation: 'middle' as const,
        nameGap: 50,
        axisLine: {
          lineStyle: {
            color: '#E0E0E0'
          }
        },
        splitLine: {
          lineStyle: {
            color: '#E0E0E0'
          }
        }
      },
      series: series
    };
  };

  if (loading) {
    return (
      <div className="text-center my-5">
        <Spinner animation="border" />
        <p className="mt-2">{language === 'es' ? 'Cargando mensajes...' : 'Loading messages...'}</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="alert alert-danger my-3">
        <i className="fas fa-exclamation-circle me-2"></i>
        {error}
      </div>
    );
  }

  if (conversationStats.length === 0) {
    return (
      <div className="alert alert-info my-3">
        <i className="fas fa-info-circle me-2"></i>
        {language === 'es' 
          ? 'No hay mensajes disponibles para el período seleccionado.' 
          : 'No messages available for the selected period.'}
      </div>
    );
  }

  return (
    <Card className="shadow-sm">
      <Card.Header>
        <h5 className="mb-0">
          {language === 'es' 
            ? `Evolución de mensajes por contacto (últimas ${days * 24} horas hasta ahora)` 
            : `Message evolution by contact (last ${days * 24} hours to now)`}
        </h5>
      </Card.Header>
      <Card.Body>
        <ReactEChartsCore
          echarts={echarts}
          option={getChartOptions()}
          style={{ height: '400px', width: '100%' }}
          notMerge={true}
          lazyUpdate={true}
        />
      </Card.Body>
    </Card>
  );
};

export default WhatsAppConversationsCharts;