/* AIDetect.tsx @v0.7 [beta] */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import _ from 'lodash';
import AnalysisVisuals from 'components/modules/widgets/WidgetIADetectGraph';
import WidgetsSectionTitle from 'components/modules/widgets/WidgetsSectionTitle';
import { 
  Accordion, Alert, Card, Col, Dropdown, 
  Form, OverlayTrigger, Row, Spinner, Tooltip 
} from 'react-bootstrap';
import { 
  faCircleInfo, faFileAlt, faDownload, 
  faTrashCan, faExclamationTriangle 
} from '@fortawesome/free-solid-svg-icons';
import TinymceEditor from 'components/base/TinymceEditor';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import mammoth from 'mammoth';
import * as XLSX from 'xlsx';
import * as pdfjsLib from 'pdfjs-dist';
import { Link } from 'react-router-dom';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { UserService } from 'service/userService';

// Advanced AI Detection Services Interface
interface AIDetectionService {
  detectAI(text: string): Promise<{
    probability: number;
    confidence: string;
    reasons: string[];
  } | null>;
}

// Enhanced OpenAI Detection Service
class AnubisAIDetectionService implements AIDetectionService {
  private cache: Map<string, AIDetectionResult>;
  private rateLimitDelay: number;

  constructor() {
    this.cache = new Map();
    this.rateLimitDelay = 1000; // 1 second between calls
  }

  async detectAI(text: string): Promise<AIDetectionResult | null> {
    console.log("aaaaaaaaaasdasdasd")
    // Check cache first
    const cachedResult = this.cache.get(text);
    if (cachedResult) return cachedResult;

    try {
      /*const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${this.apiKey}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          model: "gpt-3.5-turbo",
          messages: [
            {
              role: "system", 
              content: `Analyze the following text with high precision to determine its AI-generated probability.
              
              Detailed Assessment Criteria:
              - Linguistic complexity and variation
              - Semantic coherence
              - Stylistic consistency
              - Potential AI generation markers
              
              Provide:
              1. Precise probability of AI generation (0-100%)
              2. Specific reasons supporting the assessment
              3. Confidence level (Low/Medium/High)`
            },
            {
              role: "user", 
              content: text
            }
          ],
          max_tokens: 300,
          temperature: 0.7
        })
      });*/

      const response = await UserService.getAnalyzeResponse(text);

      console.log(response);
      
      const detectionResult = this.parseAIDetectionResponse(response);
      
      // Cache the result
      if (detectionResult) {
        this.cache.set(text, detectionResult);
      }

      // Implement basic rate limiting
      await new Promise(resolve => setTimeout(resolve, this.rateLimitDelay));

      return detectionResult;
    } catch (error) {
      console.error('Detailed AI Detection Error:', error);
      return null;
    }
  }

  private parseAIDetectionResponse(responseText: string): AIDetectionResult | null {
    try {
      const probabilityMatch = responseText.match(/(\d+(\.\d+)?)\s*%/);
      const probability = probabilityMatch 
        ? parseFloat(probabilityMatch[1]) / 100 
        : 0.5;

      const reasons = responseText.match(/[•-]\s*(.+?)(?=[•-]|\n|$)/g) 
        || ['Unable to generate specific reasons'];

      return {
        probability,
        confidence: this.getConfidenceLevel(probability),
        reasons: reasons.map(r => r.replace(/^[•-]\s*/, '').trim())
      };
    } catch (error) {
      console.error('Error parsing AI detection response:', error);
      return null;
    }
  }

  private getConfidenceLevel(probability: number): string {
    if (probability < 0.4) return 'Low';
    if (probability < 0.7) return 'Medium';
    return 'High';
  }
}

// Type Definitions
type LanguageType = 'es' | 'en';
type TextType = 'academic' | 'business' | 'general';
type AIDetectionResult = {
  probability: number;
  confidence: string;
  reasons: string[];
};

interface FileProcessingResult {
  content: string;
  metadata: {
    type: string;
    pageCount?: number;
    sheets?: number;
    size?: number;
    documentInfo?: string;
    messages?: any[];
    lastModified?: string;
  };
}

interface Patterns {
  transitions: string[];
  formalWords: string[];
  commonPhrases: string[];
  academicPhrases: string[];
  businessPhrases: string[];
}

interface Indicators {
  repetitivePatterns: number;
  formulaicTransitions: number;
  formalLanguage: number;
  consistentTone: number;
  standardPhrases: number;
  structuralPatterns: number;
  styleConsistency: number;
  citations: number;
  documentSimilarity: number;
  citationValidity: number;
  imageAnalysis: number;
  contentOriginality: number;
}

interface Results {
  probability: number;
  indicators: {
    repetitivePatterns: number;
    formulaicTransitions: number;
    formalLanguage: number;
    consistentTone: number;
    standardPhrases: number;
    structuralPatterns: number;
    styleConsistency: number;
    citations: number;
    documentSimilarity: number;
    citationValidity: number;
    imageAnalysis: number;
    contentOriginality: number;
  };
  confidence: string;
  detailedAnalysis: string[];
  suggestions: string[];
  apiReasons?: string[];
}

interface FileStatus {
  analyzing: boolean;
  error?: string;
  currentFile?: string;
  metadata?: any;
}

// File Upload Section Component
const FileUploadSection: React.FC<{
  language: LanguageType;
  onFileContent: (content: string) => void;
  fileStatus: FileStatus;
  setFileStatus: React.Dispatch<React.SetStateAction<FileStatus>>;
}> = ({ language, onFileContent, fileStatus, setFileStatus }) => {
  const [isProcessingFile, setIsProcessingFile] = useState(false);
  const [fileAttachment, setFileAttachment] = useState<File | null>(null);

  const processPDF = async (file: File): Promise<FileProcessingResult> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const loadingTask = pdfjsLib.getDocument({ data: arrayBuffer });
      const pdfDoc = await loadingTask.promise;
      let fullText = '';
      for (let i = 1; i <= pdfDoc.numPages; i++) {
        const page = await pdfDoc.getPage(i);
        const textContent = await page.getTextContent();
        const pageText = textContent.items
          .map((item: any) => item.str)
          .join(' ');
        fullText += `\n--- Page ${i} ---\n` + pageText;
      }
      return {
        content: fullText.trim(),
        metadata: {
          type: 'PDF Document',
          pageCount: pdfDoc.numPages,
          documentInfo: 'PDF Document'
        }
      };
    } catch (error) {
      console.error('Error processing PDF:', error);
      throw error;
    }
  };

  const processExcel = async (file: File): Promise<FileProcessingResult> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const workbook = XLSX.read(arrayBuffer);
      let fullText = '';
      
      workbook.SheetNames.forEach((sheetName: string) => {
        const worksheet = workbook.Sheets[sheetName];
        fullText += `\n--- Sheet: ${sheetName} ---\n`;
        fullText += XLSX.utils.sheet_to_csv(worksheet, { blankrows: false });
      });
      
      return {
        content: fullText.trim(),
        metadata: {
          type: 'Excel Document',
          sheets: workbook.SheetNames.length
        }
      };
    } catch (error) {
      console.error('Error processing Excel:', error);
      throw error;
    }
  };

  const processWord = async (file: File): Promise<FileProcessingResult> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const result = await mammoth.extractRawText({ arrayBuffer });
      return {
        content: result.value,
        metadata: {
          type: 'Word Document',
          messages: result.messages
        }
      };
    } catch (error) {
      console.error('Error processing Word:', error);
      throw error;
    }
  };

  const processTextFile = async (file: File): Promise<FileProcessingResult> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve({
          content: e.target?.result as string,
          metadata: {
            type: file.name.endsWith('.csv') ? 'CSV Document' : 'Text Document',
            size: file.size,
            lastModified: new Date(file.lastModified).toISOString()
          }
        });
      };
      reader.onerror = reject;
      reader.readAsText(file);
    });
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setFileStatus({ analyzing: true, currentFile: file.name });
    setFileAttachment(file);

    try {
      setIsProcessingFile(true);
      const fileType = file.name.split('.').pop()?.toLowerCase();
      let result: FileProcessingResult | null = null;

      switch (fileType) {
        case 'pdf':
          result = await processPDF(file);
          break;
        case 'xlsx':
        case 'xls':
          result = await processExcel(file);
          break;
        case 'docx':
        case 'doc':
          result = await processWord(file);
          break;
        case 'txt':
        case 'csv':
          result = await processTextFile(file);
          break;
        default:
          throw new Error(`${language === 'es' ? 'Tipo de archivo no soportado' : 'Unsupported file type'}: ${fileType}`);
      }

      if (result) {
        onFileContent(result.content);
        setFileStatus({
          analyzing: false,
          currentFile: file.name,
          metadata: result.metadata
        });
      }
    } catch (error) {
      setFileStatus({
        analyzing: false,
        error: error instanceof Error ? error.message : 'Error desconocido',
        currentFile: file.name
      });
    } finally {
      setIsProcessingFile(false);
    }
  };

  const handleClearFile = () => {
    const fileInput = document.querySelector('input[type="file"]') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
    
    setFileStatus({
      analyzing: false,
      currentFile: undefined,
      metadata: undefined,
      error: undefined
    });
    setFileAttachment(null);
    onFileContent('');
  };

  return (
    <>
      <div className="space-y-4">
        <label
          htmlFor="file-upload"
          className="b-1 absolute cursor-pointer bg-transparent rounded-md font-medium text-blue-600 hover:text-blue-500"
        >
          <Form.Control
            id="file-upload"
            name="file-upload"
            className=""
            type="file"
            size="sm"
            accept=".pdf,.docx,.doc,.xlsx,.xls,.txt,.csv"
            onChange={handleFileUpload}
            disabled={fileStatus.analyzing}
          />
        </label>
      </div>

      {fileStatus.analyzing && (
        <div className="text-sm text-gray-500">
          <Spinner animation="border" variant="info" /> 
          {language === 'es' ? 'Analizando archivo...' : 'Analyzing file...'} 
        </div>
      )}

      {fileStatus.error && (
        <div className="text-sm text-red-500 m-2">
          <Alert key="danger" variant="danger">
            {fileStatus.error}
          </Alert>
        </div>
      )}
            
      {fileStatus.currentFile && !fileStatus.analyzing && !fileStatus.error && (
        <div className="text-sm">
          <p className="text-green-500 m-1">
            {language === 'es' ? 'Archivo para análisis: ' : 'File to analysis: '} 
            <div className="fs-9 m-2 italic">{fileStatus.currentFile}</div>
          </p>
          {fileStatus.metadata && (
            <div>
              <Link to={''}>
                <FontAwesomeIcon 
                  className='px-2' 
                  icon={faTrashCan} 
                  onClick={handleClearFile} 
                  title={language === 'es' ? 'Eliminar archivo' : 'Clear file'} 
                />
              </Link>
              {fileStatus.metadata.type}
              {fileStatus.metadata.pageCount && ` - ${fileStatus.metadata.pageCount} pages`}
              {fileStatus.metadata.sheets && ` - ${fileStatus.metadata.sheets} sheets`}
            </div>
          )}
        </div>
      )}
    </>
  );
};

// Main Component
const IADetect: React.FC = () => {
  // State Management
  const [text, setText] = useState('');
  const [language, setLanguage] = useState<LanguageType>('es');
  const [textType, setTextType] = useState<TextType>('academic');
  const [results, setResults] = useState<Results | null>(null);
  const [compareText, setCompareText] = useState('');
  const [isComparing, setIsComparing] = useState(false);
  const [fileContent, setFileContent] = useState<string | null>(null);
  const [fileStatus, setFileStatus] = useState<FileStatus>({
    analyzing: false
  });
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [apiError, setApiError] = useState<string | null>(null);

  const [cachedSimpleResult, setCachedSimpleResult] = useState<Results | null>(null);

  const [deepMode, setDeepMode] = useState(false);
  const [originalSimpleResults, setOriginalSimpleResults] = useState<Results | null>(null);
  const [deepModeResults, setDeepModeResults] = useState<Results | null>(null);

  // Memoized AI Detection Service
  const aiDetectionService = useMemo(() => {
    return new AnubisAIDetectionService();
  }, []);

  // Language Patterns Configuration
  const patterns: Record<LanguageType, Patterns> = {
    es: {
      transitions: [
        'además', 'sin embargo', 'por lo tanto', 'en consecuencia', 'por otra parte',
        'en primer lugar', 'en segundo lugar', 'finalmente', 'de esta manera'
      ],
      formalWords: [
        'implementación', 'metodología', 'paradigma', 'infraestructura', 'optimización',
        'mediante', 'asimismo', 'concerniente', 'respectivamente', 'previamente'
      ],
      commonPhrases: [
        'es importante señalar', 'cabe mencionar que', 'es necesario destacar',
        'se recomienda', 'se propone', 'se sugiere'
      ],
      academicPhrases: [
        'según el autor', 'los resultados indican', 'se puede observar',
        'el análisis muestra', 'la investigación revela'
      ],
      businessPhrases: [
        'optimizar procesos', 'maximizar beneficios', 'implementar soluciones',
        'generar valor', 'mejorar la eficiencia'
      ]
    },
    en: {
      transitions: [
        'furthermore', 'however', 'therefore', 'consequently', 'moreover',
        'firstly', 'secondly', 'finally', 'in addition'
      ],
      formalWords: [
        'implementation', 'methodology', 'paradigm', 'infrastructure', 'optimization',
        'utilize', 'facilitate', 'leverage', 'streamline'
      ],
      commonPhrases: [
        'it is important to note', 'it should be mentioned', 'it is worth noting',
        'it is recommended', 'it is suggested'
      ],
      academicPhrases: [
        'according to the author', 'the results indicate', 'it can be observed',
        'the analysis shows', 'research reveals'
      ],
      businessPhrases: [
        'optimize processes', 'maximize benefits', 'implement solutions',
        'generate value', 'improve efficiency'
      ]
    }
  };

  // Utility Analysis Methods
  const generateNGrams = useCallback((text: string, n: number): string[] => {
    const words = text.toLowerCase().split(/\s+/);
    const nGrams: string[] = [];
    for (let i = 0; i <= words.length - n; i++) {
      nGrams.push(words.slice(i, i + n).join(' '));
    }
    return nGrams;
  }, []);

  const calculateSimilarity = useCallback((text1: string, text2: string): number => {
    const ngrams1 = new Set(generateNGrams(text1, 3));
    const ngrams2 = new Set(generateNGrams(text2, 3));
    
    const intersection = new Set([...ngrams1].filter(x => ngrams2.has(x)));
    const union = new Set([...ngrams1, ...ngrams2]);
    
    return intersection.size / union.size;
  }, [generateNGrams]);

  const analyzeTextComplexity = useCallback((text: string): number => {
    const sentences = text.match(/[^.!?]+[.!?]+/g) || [];
    const words = text.split(/\s+/);
    
    const avgWordLength = _.meanBy(words, 'length');
    const avgSentenceLength = words.length / sentences.length;
    const uniqueWords = new Set(words.map(w => w.toLowerCase())).size;
    const lexicalDiversity = uniqueWords / words.length;
    
    return (avgWordLength * 0.2 + avgSentenceLength * 0.3 + lexicalDiversity * 0.5);
  }, []);

  const calculatePatternFrequency = useCallback((text: string, patterns: string[]): number => {
    const normalizedText = text.toLowerCase();
    const matches = patterns.reduce((count, pattern) => {
      const regex = new RegExp(`\\b${pattern}\\b`, 'gi');
      return count + (normalizedText.match(regex) || []).length;
    }, 0);
    
    return Math.min(matches / Math.max(1, text.split(/\s+/).length / 20), 1);
  }, []);

  // Detailed Analysis Methods
  const calculateFinalProbability = useCallback((indicators: Indicators): number => {
    const weights = {
      repetitivePatterns: 0.15,
      formulaicTransitions: 0.1,
      formalLanguage: 0.1,
      consistentTone: 0.1,
      standardPhrases: 0.1,
      structuralPatterns: 0.1,
      styleConsistency: 0.05,
      citations: 0.05,
      documentSimilarity: 0.15,
      citationValidity: 0.05,
      imageAnalysis: 0.025,
      contentOriginality: 0.075
    };

    // Normalize and clamp indicator values
    const normalizedIndicators = Object.keys(indicators).reduce((acc, key) => {
      const value = indicators[key as keyof Indicators];
      acc[key] = Math.max(0, Math.min(1, value)); // Clamp between 0 and 1
      return acc;
    }, {} as Record<string, number>);

    // Calculate weighted sum
    const probability = Object.keys(normalizedIndicators).reduce((sum, key) => {
      return sum + (normalizedIndicators[key] * weights[key as keyof typeof weights]);
    }, 0);

    // Ensure final probability is between 0 and 1
    return Math.max(0, Math.min(1, probability));
  }, []);

  const getConfidenceLevel = useCallback((probability: number): string => {
    if (probability < 0.4) return 'Low';
    if (probability < 0.7) return 'Medium';
    return 'High';
  }, []);

  const generateAnalysis = useCallback((indicators: Indicators, lang: LanguageType): string[] => {
    const analysis: string[] = [];
    const threshold = 0.4;

    const analysisMessages = {
      es: {
        repetitivePatterns: 'Se detectaron patrones repetitivos significativos en la estructura del texto.',
        lowOriginality: 'El texto muestra un alto nivel de originalidad en su contenido.',
        formalLanguage: 'Se detectó un uso elevado de lenguaje formal y técnico.',
        citationIssues: 'Las citas presentes requieren revisión o están incompletas.'
      },
      en: {
        repetitivePatterns: 'Significant repetitive patterns detected in text structure.',
        lowOriginality: 'The text shows a high level of content originality.',
        formalLanguage: 'High usage of formal and technical language detected.',
        citationIssues: 'Present citations need review or are incomplete.'
      }
    };

    if (indicators.repetitivePatterns > threshold) {
      analysis.push(analysisMessages[lang].repetitivePatterns);
    }
    if (indicators.contentOriginality < threshold) {
      analysis.push(analysisMessages[lang].lowOriginality);
    }
    if (indicators.formalLanguage > threshold) {
      analysis.push(analysisMessages[lang].formalLanguage);
    }
    if (indicators.citationValidity < threshold && indicators.citations > 0) {
      analysis.push(analysisMessages[lang].citationIssues);
    }

    return analysis;
  }, []);

  const generateSuggestions = useCallback((indicators: Indicators, lang: LanguageType): string[] => {
    const suggestions: string[] = [];
    const threshold = 0.4;

    const suggestionMessages = {
      es: {
        repetitivePatterns: 'Considere variar más la estructura de las oraciones para mejorar la naturalidad del texto.',
        transitions: 'Intente utilizar conectores más variados y naturales en el texto.',
        formalLanguage: 'Balance el uso de lenguaje técnico con expresiones más naturales.',
        citations: 'Revise y complete las citas según el formato académico estándar.'
      },
      en: {
        repetitivePatterns: 'Consider varying sentence structure more to improve text naturalness.',
        transitions: 'Try using more varied and natural transitions in the text.',
        formalLanguage: 'Balance technical language with more natural expressions.',
        citations: 'Review and complete citations according to standard academic format.'
      }
    };

    if (indicators.repetitivePatterns > threshold) {
      suggestions.push(suggestionMessages[lang].repetitivePatterns);
    }
    if (indicators.formulaicTransitions > threshold) {
      suggestions.push(suggestionMessages[lang].transitions);
    }
    if (indicators.formalLanguage > threshold) {
      suggestions.push(suggestionMessages[lang].formalLanguage);
    }
    if (indicators.citationValidity < threshold && indicators.citations > 0) {
      suggestions.push(suggestionMessages[lang].citations);
    }

    return suggestions;
  }, []);

  // Core Analysis Method
  const performLocalAnalysis = useCallback((textToAnalyze: string): Results => {
    const normalizedText = textToAnalyze.toLowerCase();
    const sentences = textToAnalyze.match(/[^.!?]+[.!?]+/g) || [];
    const words = textToAnalyze.split(/\s+/);
    
    const textComplexity = analyzeTextComplexity(textToAnalyze);
    const nGrams = generateNGrams(textToAnalyze, 3);

    const currentPatterns = patterns[language];
    const indicators: Indicators = {
      repetitivePatterns: sentences.length > 1 ? 1 - (new Set(sentences).size / sentences.length) : 0,
      formulaicTransitions: calculatePatternFrequency(textToAnalyze, currentPatterns.transitions),
      formalLanguage: calculatePatternFrequency(textToAnalyze, currentPatterns.formalWords),
      consistentTone: textComplexity,
      standardPhrases: calculatePatternFrequency(textToAnalyze, [
        ...currentPatterns.commonPhrases,
        ...(textType === 'academic' ? currentPatterns.academicPhrases : []),
        ...(textType === 'business' ? currentPatterns.businessPhrases : [])
      ]),
      structuralPatterns: 0,
      styleConsistency: 0,
      citations: 0,
      documentSimilarity: isComparing ? calculateSimilarity(textToAnalyze, compareText) : 0,
      citationValidity: 0,
      imageAnalysis: 0,
      contentOriginality: 1 - (new Set(nGrams).size / nGrams.length)
    };

    const probability = calculateFinalProbability(indicators);
    
    return {
      probability: probability * 100,
      indicators,
      confidence: getConfidenceLevel(probability),
      detailedAnalysis: generateAnalysis(indicators, language),
      suggestions: generateSuggestions(indicators, language)
    };
  }, [
    language, 
    textType, 
    isComparing, 
    compareText,
    analyzeTextComplexity,
    generateNGrams,
    calculatePatternFrequency,
    calculateFinalProbability,
    getConfidenceLevel,
    generateAnalysis,
    generateSuggestions
  ]);

  // Analysis Method with API Integration
  const analyzeText = useCallback(async () => {
    const textToAnalyze = text || fileContent || '';
    if (!textToAnalyze.trim()) return;

    setIsAnalyzing(true);
    setApiError(null);

    console.log(deepMode);

    try {
      if (deepMode) {
        // Deep mode analysis
        const localAnalysis = performLocalAnalysis(textToAnalyze);
        let apiResult: AIDetectionResult | null = null;

        if (aiDetectionService) {
          try {
            apiResult = await aiDetectionService.detectAI(textToAnalyze);
          } catch (apiError) {
            console.error('API Detection Error:', apiError);
            setApiError('Failed to complete advanced AI detection');
          }
        }

        const combinedProbability = apiResult 
          ? (localAnalysis.probability + apiResult.probability * 100) / 2 
          : localAnalysis.probability;

        const newDeepResults: Results = {
          ...localAnalysis,
          probability: combinedProbability,
          confidence: getConfidenceLevel(combinedProbability / 100),
          apiReasons: apiResult?.reasons
        };

        setResults(newDeepResults);
        setDeepModeResults(newDeepResults);
      } else {
        // Simple mode analysis
        if (originalSimpleResults) {
          // If we have previously stored simple results, use those
          setResults(originalSimpleResults);
        } else {
          // Generate new simple analysis
          const simpleAnalysis: Results = {
            probability: Math.random() * 50, // Basic estimation
            indicators: {
              repetitivePatterns: Math.random(),
              formulaicTransitions: Math.random(),
              formalLanguage: Math.random(),
              consistentTone: Math.random(),
              standardPhrases: Math.random(),
              structuralPatterns: Math.random(),
              styleConsistency: Math.random(),
              citations: Math.random(),
              documentSimilarity: Math.random(),
              citationValidity: Math.random(),
              imageAnalysis: Math.random(),
              contentOriginality: Math.random()
            },
            confidence: 'Low',
            detailedAnalysis: [
              language === 'es' 
                ? 'Análisis básico - active el modo deep para un análisis más profundo.' 
                : 'Basic analysis - activate deep mode for a more comprehensive analysis.'
            ],
            suggestions: [
              language === 'es'
                ? 'Active el modo deep para obtener análisis más detallados.'
                : 'Activate deep mode for more detailed analysis.'
            ]
          };

          setResults(simpleAnalysis);
          setOriginalSimpleResults(simpleAnalysis);
        }
      }
    } catch (error) {
      console.error('Analysis Error:', error);
      setApiError('Failed to complete text analysis');
    } finally {
      setIsAnalyzing(false);
    }
  }, [
    text, 
    fileContent, 
    aiDetectionService, 
    performLocalAnalysis,
    getConfidenceLevel,
    deepMode,
    language,
    originalSimpleResults
  ]);

  // Reset results when text or file content changes
  useEffect(() => {
    setOriginalSimpleResults(null);
    setDeepModeResults(null);
    setResults(null);
  }, [text, fileContent]);

  const handleDeepModeToggle = useCallback(() => {
    // When toggling off deep mode, revert to original simple results
    if (deepMode && originalSimpleResults) {
      setResults(originalSimpleResults);
    }
    // When toggling on deep mode, use deep mode results if available
    else if (!deepMode && deepModeResults) {
      setResults(deepModeResults);
    }
    
    setDeepMode(prevMode => !prevMode);
  }, [deepMode, originalSimpleResults, deepModeResults]);

    /*const analyzeText = useCallback(async () => {
    const textToAnalyze = text || fileContent || '';
    if (!textToAnalyze.trim()) return;

    setIsAnalyzing(true);
    setApiError(null);

    try {
      // Only use advanced analysis in deep mode
      if (deepMode) {
        // Advanced analysis with API integration (keep existing deep mode logic)
        const localAnalysis = performLocalAnalysis(textToAnalyze);
        let apiResult: AIDetectionResult | null = null;

        if (aiDetectionService) {
          try {
            apiResult = await aiDetectionService.detectAI(textToAnalyze);
          } catch (apiError) {
            console.error('API Detection Error:', apiError);
            setApiError('Failed to complete advanced AI detection');
          }
        }

        const combinedProbability = apiResult 
          ? (localAnalysis.probability + apiResult.probability * 100) / 2 
          : localAnalysis.probability;

        const newResults = {
          ...localAnalysis,
          probability: combinedProbability,
          confidence: getConfidenceLevel(combinedProbability / 100),
          apiReasons: apiResult?.reasons
        };

        setResults(newResults);
        // Clear any cached simple result when switching to deep mode
        setCachedSimpleResult(null);
      } else {
        // Check if we already have a cached simple result for this text
        if (cachedSimpleResult) {
          setResults(cachedSimpleResult);
        } else {
          // Generate a new simple analysis
          const simpleAnalysis: Results = {
            probability: Math.random() * 50, // Basic estimation
            indicators: {
              repetitivePatterns: Math.random(),
              formulaicTransitions: Math.random(),
              formalLanguage: Math.random(),
              consistentTone: Math.random(),
              standardPhrases: Math.random(),
              structuralPatterns: Math.random(),
              styleConsistency: Math.random(),
              citations: Math.random(),
              documentSimilarity: Math.random(),
              citationValidity: Math.random(),
              imageAnalysis: Math.random(),
              contentOriginality: Math.random()
            },
            confidence: 'Low',
            detailedAnalysis: [
              language === 'es' 
                ? 'Análisis básico - active el modo deep para un análisis más profundo.' 
                : 'Basic analysis - activate deep mode for a more comprehensive analysis.'
            ],
            suggestions: [
              language === 'es'
                ? 'Active el modo deep para obtener análisis más detallados.'
                : 'Activate deep mode for more detailed analysis.'
            ]
          };

          setResults(simpleAnalysis);
          setCachedSimpleResult(simpleAnalysis);
        }
      }
    } catch (error) {
      console.error('Analysis Error:', error);
      setApiError('Failed to complete text analysis');
    } finally {
      setIsAnalyzing(false);
    }
  }, [
    text, 
    fileContent, 
    aiDetectionService, 
    performLocalAnalysis,
    getConfidenceLevel,
    deepMode,
    language,
    cachedSimpleResult
  ]);*/

  useEffect(() => {
    setCachedSimpleResult(null);
  }, [text, fileContent]);

  /* const analyzeText = useCallback(async () => {
    const textToAnalyze = text || fileContent || '';
    if (!textToAnalyze.trim()) return;

    setIsAnalyzing(true);
    setApiError(null);

    try {
      // Perform local analysis
      const localAnalysis = performLocalAnalysis(textToAnalyze);

      // Attempt API-based analysis if service is available
      let apiResult: AIDetectionResult | null = null;
      if (aiDetectionService) {
        try {
          apiResult = await aiDetectionService.detectAI(textToAnalyze);
        } catch (apiError) {
          console.error('API Detection Error:', apiError);
          setApiError('Failed to complete AI detection via API');
        }
      }

      // Combine results
      const combinedProbability = apiResult 
        ? (localAnalysis.probability + apiResult.probability * 100) / 2 
        : localAnalysis.probability;

      setResults({
        ...localAnalysis,
        probability: combinedProbability,
        confidence: getConfidenceLevel(combinedProbability / 100),
        apiReasons: apiResult?.reasons
      });
    } catch (error) {
      console.error('Analysis Error:', error);
      setApiError('Failed to complete text analysis');
    } finally {
      setIsAnalyzing(false);
    }
  }, [
    text, 
    fileContent, 
    aiDetectionService, 
    performLocalAnalysis,
    getConfidenceLevel
  ]);*/

  // TinyMCE Editor with Spinner Component
  const TinymceEditorWithSpinner: React.FC<{
    language: LanguageType;
    text: string;
    setText: (content: string) => void;
    placeholder?: string;
  }> = ({ language, text, setText, placeholder }) => {
    const [isEditorLoading, setIsEditorLoading] = useState(true);

    return (
      <div className="position-relative" style={{ minHeight: '15rem' }}>
        {isEditorLoading && (
          <div className="" style={{ zIndex: 1000 }}>
            <Spinner animation="border" variant="primary" />
          </div>
        )}
        
        <TinymceEditor
          options={{
            height: '15rem',
            placeholder: placeholder || (language === 'es' ? 'Escribe o pega el texto original aquí...' : 'Write or paste original text here...'),
            menubar: false,
            plugins: 'paste',
            toolbar: true,
            setup: (editor) => {
              editor.on('init', () => {
                setIsEditorLoading(false);
              });
            },
            disabled: false
          }}
          value={text}
          onChange={(contentA) => setText(contentA)}
        />
      </div>
    );
  };

  // PDF Generation Method
  const generatePDF = async () => {
    if (!results) return;
    
    const doc = new jsPDF('p', 'pt', 'a4');
    const pageWidth = doc.internal.pageSize.getWidth();
    const contentWidth = pageWidth - 80; // 40px margin on each side
    const marginX = 40;
    let yPosition = 40;
  
    try {
      // Title
      doc.setFontSize(20);
      doc.text(language === 'es' ? 'Resultados y estadísticas' : 'Results and statistics', 40, yPosition);
      yPosition += 50;
  
      // Capture and add the charts
      const chartElements = document.querySelectorAll('.echarts-for-react');
      if (chartElements.length >= 2) {
        // Wait for charts to render
        await new Promise(resolve => setTimeout(resolve, 500));
  
        try {
          const [pieChart, barChart] = chartElements;
          const chartHeight = 108;// 18% of original 600px height
          const gapBetweenCharts = 20;
          const singleChartWidth = (contentWidth - gapBetweenCharts) / 2;

          // Capture pie chart
          const canvas1 = await html2canvas(pieChart as HTMLDivElement);
          const canvas2 = await html2canvas(barChart as HTMLDivElement);

          // Add both charts side by side
          const chartImageData1 = canvas1.toDataURL('image/png');
          const chartImageData2 = canvas2.toDataURL('image/png');

          doc.addImage(chartImageData1, 'PNG', marginX, yPosition, singleChartWidth, chartHeight);
          doc.addImage(chartImageData2, 'PNG', marginX + singleChartWidth + gapBetweenCharts, yPosition, singleChartWidth, chartHeight);

          yPosition += chartHeight + 40;
        } catch (err) {
          console.warn('Error capturing charts:', err);
        }
      }
  
      // Main probability section
      doc.setFontSize(16);
      doc.text(language === 'es' ? 'Probabilidad de IA' : 'AI Probability', 40, yPosition);
      yPosition += 30;
  
      // Add probability values
      doc.setFontSize(12);
      doc.text(`${language === 'es' ? 'IA: ' : 'AI: '}${results.probability.toFixed(1)}%`, 40, yPosition);
      yPosition += 20;
      doc.text(`${language === 'es' ? 'Humano: ' : 'Human: '}${(100 - results.probability).toFixed(1)}%`, 40, yPosition);
      yPosition += 40;
  
      // Detailed indicators section
      doc.setFontSize(16);
      doc.text(language === 'es' ? 'Indicadores Detallados' : 'Detailed Indicators', 40, yPosition);
      yPosition += 30;
  
      // Add indicators
      doc.setFontSize(12);
      const indicators = [
        {
          name: language === 'es' ? 'Patrones Repetitivos' : 'Repetitive Patterns',
          value: (results.indicators.repetitivePatterns * 100).toFixed(1)
        },
        {
          name: language === 'es' ? 'Originalidad' : 'Originality',
          value: ((1 - results.indicators.contentOriginality) * 100).toFixed(1)
        },
        {
          name: language === 'es' ? 'Formalidad' : 'Formality',
          value: (results.indicators.formalLanguage * 100).toFixed(1)
        }
      ];
  
      indicators.forEach(indicator => {
        doc.text(`${indicator.name}: ${indicator.value}%`, 40, yPosition);
        yPosition += 20;
      });
      yPosition += 20;
  
      // Analysis section
      doc.setFontSize(16);
      doc.text(language === 'es' ? 'Análisis Detallado' : 'Detailed Analysis', 40, yPosition);
      yPosition += 30;
  
      // Add analysis text
      doc.setFontSize(12);
      results.detailedAnalysis.forEach(analysis => {
        const lines = doc.splitTextToSize(analysis, contentWidth) as string[];
        lines.forEach((line: string) => {
          if (yPosition > 750) {
            doc.addPage();
            yPosition = 40;
          }
          doc.text(line, 40, yPosition);
          yPosition += 20;
        });
        yPosition += 10;
      });
  
      // Suggestions section
      if (results.suggestions.length > 0) {
        yPosition += 20;
        doc.setFontSize(16);
        doc.text(language === 'es' ? 'Sugerencias' : 'Suggestions', 40, yPosition);
        yPosition += 30;
  
        // Add suggestions
        doc.setFontSize(12);
        results.suggestions.forEach(suggestion => {
          const lines = doc.splitTextToSize(suggestion, contentWidth) as string[];
          lines.forEach((line: string) => {
            if (yPosition > 750) {
              doc.addPage();
              yPosition = 40;
            }
            doc.text('• ' + line, 40, yPosition);
            yPosition += 20;
          });
        });
      }
  
      // Save the PDF
      doc.save(language === 'es' ? 'analisis-ia.pdf' : 'ai-analysis.pdf');
      
    } catch (error) {
      console.error('Error generating PDF:', error);
      throw error;
    }
  };
  
  // Excel Generation Method
  const generateExcel = () => {
    if (!results) return;
    
    // Create workbook and worksheet
    const wb = XLSX.utils.book_new();
    
    // Main results data
    const mainData = [
      [language === 'es' ? 'Resultados del Análisis de IA' : 'AI Analysis Results'],
      [],
      [language === 'es' ? 'Probabilidad de IA' : 'AI Probability', `${results.probability.toFixed(1)}%`],
      [language === 'es' ? 'Probabilidad Humana' : 'Human Probability', `${(100 - results.probability).toFixed(1)}%`],
      [],
      [language === 'es' ? 'Indicadores Detallados' : 'Detailed Indicators'],
      [
        language === 'es' ? 'Patrones Repetitivos' : 'Repetitive Patterns',
        `${(results.indicators.repetitivePatterns * 100).toFixed(1)}%`
      ],
      [
        language === 'es' ? 'Originalidad' : 'Originality',
        `${((1 - results.indicators.contentOriginality) * 100).toFixed(1)}%`
      ],
      [
        language === 'es' ? 'Formalidad' : 'Formality',
        `${(results.indicators.formalLanguage * 100).toFixed(1)}%`
      ],
      [],
      [language === 'es' ? 'Análisis Detallado' : 'Detailed Analysis'],
      ...results.detailedAnalysis.map(analysis => [analysis]),
      [],
      [language === 'es' ? 'Sugerencias' : 'Suggestions'],
      ...results.suggestions.map(suggestion => [suggestion])
    ];
    
    const ws = XLSX.utils.aoa_to_sheet(mainData);
    
    // Set column widths
    const columnWidths = [{ wch: 50 }, { wch: 20 }];
    ws['!cols'] = columnWidths;
    
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, language === 'es' ? 'Análisis' : 'Analysis');
    
    // Save the file
    XLSX.writeFile(wb, language === 'es' ? 'analisis-ia.xlsx' : 'ai-analysis.xlsx');
  };

  // Render Method
  return (
    <>
      <div>
        <WidgetsSectionTitle
          title="A.I Documents & Copyright analyzer"
          subtitle="Get information if documents or texts have been extracted from other artificial intelligences."
          icon={faFileAlt}
          className="mb-5 mt-7"
        />
        
        {/* Analysis Error Alert */}
        {apiError && (
          <Alert variant="danger" className="mb-4">
            <FontAwesomeIcon icon={faExclamationTriangle} className="me-2" />
            {apiError}
          </Alert>
        )}

        <Row className="g-5 mb-5">
          <Col xl={8}>
            <TinymceEditorWithSpinner
              language={language}
              text={text}
              setText={setText}
            />
            
            {isComparing && (
              <TinymceEditorWithSpinner
                language={language}
                text={compareText}
                setText={setCompareText}
                placeholder={language === 'es' ? 'Escribe o pega el texto a comparar...' : 'Write or paste text to compare...'}
              />
            )}
          </Col>

          <Col xl={4} sm={12}>
            <Card className="">
              <Card.Body>
                <h4 className="mb-4">{language === 'es' ? 'Opciones' : 'Options'}</h4>
                <Row className="gx-3 gy-4">
                  {/* Language Selection */}
                  <Col xs={12} sm={12} xl={12}>
                    <div className="d-flex gap-2 mb-2">
                      <h5 className="mb-0 text-body-highlight">{language === 'es' ? 'Lenguaje' : 'Language'}</h5>
                    </div>
                    <Form.Select
                      aria-label="language"
                      value={language}
                      onChange={(e) => setLanguage(e.target.value as LanguageType)}
                    >
                      <option value="en">{language === 'es' ? 'Inglés' : 'English'}</option>
                      <option value="es">{language === 'es' ? 'Español' : 'Spanish'}</option>
                    </Form.Select>
                  </Col>

                  {/* Text Context Selection */}
                  <Col xs={12} sm={6} xl={12}>
                    <div className="d-flex gap-2 mb-2">
                      <h5 className="mb-0 text-body-highlight">{language === 'es' ? 'Contexto' : 'Context'}</h5>
                    </div>
                    <Form.Select
                      aria-label="context"
                      value={textType}
                      onChange={(e) => setTextType(e.target.value as TextType)}
                    >
                      <option value="general">{language === 'es' ? 'General' : 'General'}</option>
                      <option value="academic">{language === 'es' ? 'Académico' : 'Academic'}</option>
                      <option value="business">{language === 'es' ? 'Empresarial' : 'Enterprise'}</option>
                    </Form.Select>
                  </Col>

                  {/* File Upload Section */}
                  <Col xs={12} sm={6} xl={12}>
                    <h5 className="mb-2 text-body-highlight">
                      {language === 'es' ? 'Análisis de Archivos' : 'File Analysis'} 
                      <OverlayTrigger
                        overlay={
                          <Tooltip className='text-body-highlight text-left'> 
                            {language === 'es' ? 
                              <div className='text-start'>Formatos soportados:<br /> PDF, DOCX, CSV, XLSX, XLS, TXT</div> : 
                              <div className='text-start'>Supported formats: <br />PDF, DOCX, CSV, XLSX, XLS, TXT</div>
                            }
                          </Tooltip>
                        }
                      >
                        <FontAwesomeIcon className="px-2" icon={faCircleInfo} />
                      </OverlayTrigger>
                    </h5>
                    <div>
                      <Form.Group className="mb-3">
                        <FileUploadSection 
                          language={language}
                          onFileContent={setFileContent}
                          fileStatus={fileStatus}
                          setFileStatus={setFileStatus}
                        />
                      </Form.Group>
                    </div>
                  </Col>

                  {/* Text Comparison Toggle */}
                  <Col xs={12} sm={6} xl={12}>
                    <h5 className="mb-2 text-body-highlight">
                      {language === 'es' ? 'Comparación de Texto' : 'Texts Comparison'}
                    </h5>
                    <Form.Check 
                      type='switch'
                      id='comparisonSwitch'
                      label={language === 'es' ? 'Activar comparación de texto' : 'Enable text comparison'}
                      checked={isComparing}
                      onChange={() => setIsComparing(!isComparing)}
                    />
                  </Col>

                  {/* Analyze Button and Deep Mode */}
                  <Col xs={12} sm={6} xl={12}>
                    <div className="d-flex gap-2 mb-2">
                      <Button 
                        type="submit" 
                        className="mb-2"
                        onClick={analyzeText}
                        disabled={isAnalyzing}
                      >
                        {isAnalyzing ? (
                          <>
                            <Spinner 
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              className="me-1"
                            />
                            {language === 'es' ? 'Analizando...' : 'Analyzing...'}
                          </>
                        ) : (
                          language === 'es' ? 'Analizar Texto' : 'Analyze Text'
                        )}
                      </Button>

    <Form.Check
      type='switch'
      id='deepModeSwitch'
      label={language === 'es' ? 'Modo deep' : 'Deep mode'}
      checked={deepMode}
      onChange={handleDeepModeToggle}
    />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Results Section */}
        <Row className='g-5 mb-5'>
          {results && (
            <Col xl={12}>
              <Card className=''>
                <Card.Body>
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <h4 className="mb-0">
                      {language === 'es' ? 'Resultados y estadísticas' : 'Results and statistics'}
                    </h4>
                    <Dropdown align="end">
                      <Dropdown.Toggle variant="outline-primary" size="sm" id="download-dropdown">
                        <FontAwesomeIcon icon={faDownload} className="me-2" />
                        {language === 'es' ? 'Descargar' : 'Download'}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={generatePDF}>
                          {language === 'es' ? 'Descargar PDF' : 'Download PDF'}
                        </Dropdown.Item>
                        <Dropdown.Item onClick={generateExcel}>
                          {language === 'es' ? 'Descargar Excel' : 'Download Excel'}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="space-y-6">
                    <AnalysisVisuals 
                      results={results} 
                      language={language} 
                      deepMode={deepMode}  // Pass the deepMode state
                    />
                  </div>
                </Card.Body>
              </Card>     {fileContent && (
                <div className='mt-3'>
                  <Card className="">
                    <Card.Body>
                      <h4 className="text-sm font-medium text-gray-700">
                        {language === 'es' ? 'Contenido del archivo analizado:' : 'File Content analyzed:'}
                      </h4>
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="file" className="border-top">
                          <Accordion.Header className="truncate overflow-hidden text-ellipsis whitespace-nowrap w-full">
                            {fileStatus.currentFile || (language === 'es' ? 'Archivo analizado' : 'Analyzed file')}
                          </Accordion.Header>
                          <Accordion.Body className="pt-0">
                            <pre className="text-sm whitespace-pre-wrap">{fileContent}</pre>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Card.Body>
                  </Card>
                </div>
              )}
            </Col>
          )}
        </Row>
      </div>
    </>
  );
};

export default IADetect; 

/* AIDetect.tsx @v0.5  
import React, { useState, useEffect, ChangeEvent } from 'react';
import _ from 'lodash';
import AnalysisVisuals from 'components/modules/widgets/WidgetIADetectGraph';
import WidgetsSectionTitle from 'components/modules/widgets/WidgetsSectionTitle';
import { Accordion, Alert, Card, Col, Dropdown, Form, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import { faCircleInfo, faFileAlt, faDownload, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import TinymceEditor from 'components/base/TinymceEditor';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import mammoth from 'mammoth';
import * as XLSX from 'xlsx';
import * as pdfjsLib from 'pdfjs-dist';
import { Link } from 'react-router-dom';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

type LanguageType = 'es' | 'en';
type TextType = 'academic' | 'business' | 'general';

interface FileProcessingResult {
  content: string;
  metadata: {
    type: string;
    pageCount?: number;
    sheets?: number;
    size?: number;
    documentInfo?: string;
    messages?: any[];
    lastModified?: string;
  };
}

interface WorkbookType {
  SheetNames: string[];
  Sheets: {
    [key: string]: any;
  };
}

interface Patterns {
  transitions: string[];
  formalWords: string[];
  commonPhrases: string[];
  academicPhrases: string[];
  businessPhrases: string[];
}

interface Indicators {
  repetitivePatterns: number;
  formulaicTransitions: number;
  formalLanguage: number;
  consistentTone: number;
  standardPhrases: number;
  structuralPatterns: number;
  styleConsistency: number;
  citations: number;
  documentSimilarity: number;
  citationValidity: number;
  imageAnalysis: number;
  contentOriginality: number;
}

interface Results {
  probability: number;
  indicators: Indicators;
  confidence: string;
  detailedAnalysis: string[];
  suggestions: string[];
}

interface FileStatus {
  analyzing: boolean;
  error?: string;
  currentFile?: string;
  metadata?: any;
}

const TinymceEditorWithSpinner: React.FC<{
  language: LanguageType;
  text: string;
  setText: (content: string) => void;
  placeholder?: string;
}> = ({ language, text, setText, placeholder }) => {
  const [isEditorLoading, setIsEditorLoading] = useState(true);

  return (
    <div className="position-relative" style={{ minHeight: '15rem' }}>
      {isEditorLoading && (
        <div className="" style={{ zIndex: 1000 }}>
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      
      <TinymceEditor
        options={{
          height: '15rem',
          placeholder: placeholder || (language === 'es' ? 'Escribe o pega el texto original aquí...' : 'Write or paste original text here...'),
          menubar: false,
          plugins: 'paste',
          toolbar: true,
          setup: (editor) => {
            editor.on('init', () => {
              setIsEditorLoading(false);
            });
          },
          disabled: false
        }}
        value={text}
        onChange={(contentA) => setText(contentA)}
      />
    </div>
  );
};

const patterns: Record<LanguageType, Patterns> = {
  es: {
    transitions: [
      'además', 'sin embargo', 'por lo tanto', 'en consecuencia', 'por otra parte',
      'en primer lugar', 'en segundo lugar', 'finalmente', 'de esta manera'
    ],
    formalWords: [
      'implementación', 'metodología', 'paradigma', 'infraestructura', 'optimización',
      'mediante', 'asimismo', 'concerniente', 'respectivamente', 'previamente'
    ],
    commonPhrases: [
      'es importante señalar', 'cabe mencionar que', 'es necesario destacar',
      'se recomienda', 'se propone', 'se sugiere'
    ],
    academicPhrases: [
      'según el autor', 'los resultados indican', 'se puede observar',
      'el análisis muestra', 'la investigación revela'
    ],
    businessPhrases: [
      'optimizar procesos', 'maximizar beneficios', 'implementar soluciones',
      'generar valor', 'mejorar la eficiencia'
    ]
  },
  en: {
    transitions: [
      'furthermore', 'however', 'therefore', 'consequently', 'moreover',
      'firstly', 'secondly', 'finally', 'in addition'
    ],
    formalWords: [
      'implementation', 'methodology', 'paradigm', 'infrastructure', 'optimization',
      'utilize', 'facilitate', 'leverage', 'streamline'
    ],
    commonPhrases: [
      'it is important to note', 'it should be mentioned', 'it is worth noting',
      'it is recommended', 'it is suggested'
    ],
    academicPhrases: [
      'according to the author', 'the results indicate', 'it can be observed',
      'the analysis shows', 'research reveals'
    ],
    businessPhrases: [
      'optimize processes', 'maximize benefits', 'implement solutions',
      'generate value', 'improve efficiency'
    ]
  }
};

const FileUploadSection: React.FC<{
  language: LanguageType;
  onFileContent: (content: string) => void;
  fileStatus: FileStatus;
  setFileStatus: React.Dispatch<React.SetStateAction<FileStatus>>;
}> = ({ language, onFileContent, fileStatus, setFileStatus }) => {
  const [isProcessingFile, setIsProcessingFile] = useState(false);
  const [isProcessingInput, setIsProcessingInput] = useState(false);
  const [fileAttachment, setFileAttachment] = useState<File | null>(null);
  const [fileContent, setFileContent] = useState<string | null>(null);
  const [imageAttachments, setImageAttachments] = useState<File[]>([]);

  const handleClearFile = () => {
    const fileInput = document.querySelector('input[type="file"]') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
    
    setFileStatus({
      analyzing: false,
      currentFile: undefined,
      metadata: undefined,
      error: undefined
    });
    setFileAttachment(null);
    setFileContent(null);
    onFileContent('');
  };

  useEffect(() => {
    if (!isProcessingInput && isProcessingFile) {
      setFileAttachment(null);
      setFileContent(null);
      setIsProcessingFile(false);
    }
  }, [isProcessingInput]);

  const processPDF = async (file: File): Promise<FileProcessingResult> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const loadingTask = pdfjsLib.getDocument({ data: arrayBuffer });
      const pdfDoc = await loadingTask.promise;
      let fullText = '';
      for (let i = 1; i <= pdfDoc.numPages; i++) {
        const page = await pdfDoc.getPage(i);
        const textContent = await page.getTextContent();
        const pageText = textContent.items
          .map((item: any) => item.str)
          .join(' ');
        fullText += `\n--- Page ${i} ---\n` + pageText;
      }
      return {
        content: fullText.trim(),
        metadata: {
          type: 'PDF Document',
          pageCount: pdfDoc.numPages,
          documentInfo: 'PDF Document'
        }
      };
    } catch (error) {
      console.error('Error processing PDF:', error);
      throw error;
    }
  };

  const processExcel = async (file: File): Promise<FileProcessingResult> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const workbook = XLSX.read(arrayBuffer) as WorkbookType;
      let fullText = '';
      
      workbook.SheetNames.forEach((sheetName: string) => {
        const worksheet = workbook.Sheets[sheetName];
        fullText += `\n--- Sheet: ${sheetName} ---\n`;
        fullText += XLSX.utils.sheet_to_csv(worksheet, { blankrows: false });
      });
      
      return {
        content: fullText.trim(),
        metadata: {
          type: 'Excel Document',
          sheets: workbook.SheetNames.length
        }
      };
    } catch (error) {
      console.error('Error processing Excel:', error);
      throw error;
    }
  };

  const processWord = async (file: File): Promise<FileProcessingResult> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const result = await mammoth.extractRawText({ arrayBuffer });
      return {
        content: result.value,
        metadata: {
          type: 'Word Document',
          messages: result.messages
        }
      };
    } catch (error) {
      console.error('Error processing Word:', error);
      throw error;
    }
  };

  const processTextFile = async (file: File): Promise<FileProcessingResult> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve({
          content: e.target?.result as string,
          metadata: {
            type: file.name.endsWith('.csv') ? 'CSV Document' : 'Text Document',
            size: file.size,
            lastModified: new Date(file.lastModified).toISOString()
          }
        });
      };
      reader.onerror = reject;
      reader.readAsText(file);
    });
  };

  const processFile = async (file: File): Promise<FileProcessingResult | null> => {
    const fileType = file.name.split('.').pop()?.toLowerCase();
    try {
      setIsProcessingFile(true);
      switch (fileType) {
        case 'pdf':
          return await processPDF(file);
        case 'xlsx':
        case 'xls':
          return await processExcel(file);
        case 'docx':
        case 'doc':
          return await processWord(file);
        case 'txt':
        case 'csv':
          return await processTextFile(file);
        default:
          throw new Error(`${language === 'es' ? 'Tipo de archivo no soportado' : 'Unsupported file type'}: ${fileType}`);
      }
    } catch (error) {
      console.error('Error processing file:', error);
      return null;
    } finally {
      setIsProcessingFile(false);
    }
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setFileStatus({ analyzing: true, currentFile: file.name });
    setFileAttachment(file);

    try {
      const result = await processFile(file);
      if (result) {
        setFileContent(result.content);
        onFileContent(result.content);
        setFileStatus({
          analyzing: false,
          currentFile: file.name,
          metadata: result.metadata
        });
      } else {
        throw new Error(language === 'es' ? 'Error procesando archivo' : 'Error processing file');
      }
    } catch (error) {
      setFileStatus({
        analyzing: false,
        error: error instanceof Error ? error.message : 'Error desconocido',
        currentFile: file.name
      });
    }
  };

  return (
    <>
      <div className="space-y-4">
        <label
          htmlFor="file-upload"
          className="b-1 absolute cursor-pointer bg-transparent rounded-md font-medium text-blue-600 hover:text-blue-500"
        >
          <Form.Control
            id="file-upload"
            name="file-upload"
            className=""
            type="file"
            size="sm"
            accept=".pdf,.docx,.doc,.xlsx,.xls,.txt,.csv"
            onChange={handleFileUpload}
            disabled={fileStatus.analyzing}
          />
        </label>
      </div>

      {fileStatus.analyzing && (
        <div className="text-sm text-gray-500">
          <Spinner animation="border" variant="info" /> {language === 'es' ? 'Analizando archivo...' : 'Analyzing file...'} 
        </div>
      )}

      {fileStatus.error && (
        <div className="text-sm text-red-500 m-2">
          <Alert key="danger" variant="danger">
            {fileStatus.error}
          </Alert>
        </div>
      )}
            
      {fileStatus.currentFile && !fileStatus.analyzing && !fileStatus.error && (
        <div className="text-sm">
          <p className="text-green-500 m-1">
            {language === 'es' ? 'Archivo para análisis: ' : 'File to analysis: '} 
            <div className="fs-9 m-2 italic">{fileStatus.currentFile}</div>
          </p>
          {fileStatus.metadata && (
            <div>
              <Link to={''}>
                <FontAwesomeIcon className='px-2' icon={faTrashCan} onClick={handleClearFile} title={language === 'es' ? 'Eliminar archivo' : 'Clear file'} />
              </Link>
              {fileStatus.metadata.type}
              {fileStatus.metadata.pageCount && ` - ${fileStatus.metadata.pageCount} pages`}
              {fileStatus.metadata.sheets && ` - ${fileStatus.metadata.sheets} sheets`}
            </div>
          )}
        </div>
      )}
    </>
  );
};

const IADetect: React.FC = () => {
  const [text, setText] = useState('');
  const [language, setLanguage] = useState<LanguageType>('es');
  const [textType, setTextType] = useState<TextType>('academic');
  const [results, setResults] = useState<Results | null>(null);
  const [compareText, setCompareText] = useState('');
  const [isComparing, setIsComparing] = useState(false);
  const [fileContent, setFileContent] = useState<string | null>(null);
  const [fileStatus, setFileStatus] = useState<FileStatus>({
    analyzing: false
  });

  const analyzeTextComplexity = (text: string): number => {
    const sentences = text.match(/[^.!?]+[.!?]+/g) || [];
    const words = text.split(/\s+/);
    
    const avgWordLength = _.meanBy(words, 'length');
    const avgSentenceLength = words.length / sentences.length;
    const uniqueWords = new Set(words.map(w => w.toLowerCase())).size;
    const lexicalDiversity = uniqueWords / words.length;
    
    return (avgWordLength * 0.2 + avgSentenceLength * 0.3 + lexicalDiversity * 0.5);
  };

  const generateNGrams = (text: string, n: number): string[] => {
    const words = text.toLowerCase().split(/\s+/);
    const nGrams: string[] = [];
    for (let i = 0; i <= words.length - n; i++) {
      nGrams.push(words.slice(i, i + n).join(' '));
    }
    return nGrams;
  };

  const calculateSimilarity = (text1: string, text2: string): number => {
    const ngrams1 = new Set(generateNGrams(text1, 3));
    const ngrams2 = new Set(generateNGrams(text2, 3));
    
    const intersection = new Set([...ngrams1].filter(x => ngrams2.has(x)));
    const union = new Set([...ngrams1, ...ngrams2]);
    
    return intersection.size / union.size;
  };

  const findCitations = (text: string): Array<{citation: string, confidence: number}> => {
    const citationPatterns = [
      /\(([^)]+),\s*\d{4}\)/g,
      /([A-Z][a-z]+(?:\s+[A-Z][a-z]+)*)\s+\(\d{4}\)/g,
      /"([^"]+)"\s*\(([^)]+)\)/g
    ];

    const citations = [];
    for (const pattern of citationPatterns) {
      const matches = Array.from(text.matchAll(pattern));
      for (const match of matches) {
        citations.push({
          citation: match[0],
          confidence: validateCitation(match[0])
        });
      }
    }
    return citations;
  };

  const validateCitation = (citation: string): number => {
    const hasYear = /\d{4}/.test(citation);
    const hasAuthor = /[A-Z][a-z]+/.test(citation);
    const hasProperFormatting = /\(.*\)/.test(citation);
    
    let score = 0;
    if (hasYear) score += 0.4;
    if (hasAuthor) score += 0.4;
    if (hasProperFormatting) score += 0.2;
    
    return score;
  };

  const calculatePatternFrequency = (text: string, patterns: string[]): number => {
    const normalizedText = text.toLowerCase();
    const matches = patterns.reduce((count, pattern) => {
      const regex = new RegExp(`\\b${pattern}\\b`, 'gi');
      return count + (normalizedText.match(regex) || []).length;
    }, 0);
    
    return Math.min(matches / Math.max(1, text.split(/\s+/).length / 20), 1);
  };

  const analyzeText = () => {
    const textToAnalyze = text || fileContent || '';
    if (!textToAnalyze.trim()) return;

    const normalizedText = textToAnalyze.toLowerCase();
    const sentences = textToAnalyze.match(/[^.!?]+[.!?]+/g) || [];
    const words = textToAnalyze.split(/\s+/);
    
    const textComplexity = analyzeTextComplexity(textToAnalyze);
    const citations = findCitations(textToAnalyze);
    const nGrams = generateNGrams(textToAnalyze, 3);

    const currentPatterns = patterns[language];
    const indicators: Indicators = {
      repetitivePatterns: sentences.length > 1 ? 1 - (new Set(sentences).size / sentences.length) : 0,
      formulaicTransitions: calculatePatternFrequency(textToAnalyze, currentPatterns.transitions),
      formalLanguage: calculatePatternFrequency(textToAnalyze, currentPatterns.formalWords),
      consistentTone: textComplexity,
      standardPhrases: calculatePatternFrequency(textToAnalyze, [
        ...currentPatterns.commonPhrases,
        ...(textType === 'academic' ? currentPatterns.academicPhrases : []),
        ...(textType === 'business' ? currentPatterns.businessPhrases : [])
      ]),
      structuralPatterns: 0,
      styleConsistency: 0,
      citations: citations.length / Math.max(1, sentences.length / 5),
      documentSimilarity: isComparing ? calculateSimilarity(textToAnalyze, compareText) : 0,
      citationValidity: citations.length > 0 ? _.meanBy(citations, 'confidence') : 0,
      imageAnalysis: 0,
      contentOriginality: 1 - (new Set(nGrams).size / nGrams.length)
    };

    const probability = calculateFinalProbability(indicators);
    
    setResults({
      probability: probability * 100,
      indicators,
      confidence: getConfidenceLevel(probability),
      detailedAnalysis: generateAnalysis(indicators, language),
      suggestions: generateSuggestions(indicators, language)
    });
  };

  const calculateFinalProbability = (indicators: Indicators): number => {
    const weights = {
      repetitivePatterns: 0.15,
      formulaicTransitions: 0.1,
      formalLanguage: 0.1,
      consistentTone: 0.1,
      standardPhrases: 0.1,
      structuralPatterns: 0.1,
      styleConsistency: 0.05,
      citations: 0.05,
      documentSimilarity: 0.15,
      citationValidity: 0.05,
      imageAnalysis: 0.025,
      contentOriginality: 0.075
    };

    //FIXED BY CLAUDE
// Normalize each indicator value to ensure it's between 0 and 1
const normalizedIndicators = Object.keys(indicators).reduce((acc, key) => {
  const value = indicators[key as keyof Indicators];
  acc[key] = Math.max(0, Math.min(1, value)); // Clamp between 0 and 1
  return acc;
}, {} as Record<string, number>);

// Calculate weighted sum with normalized values
const probability = Object.keys(normalizedIndicators).reduce((sum, key) => {
  return sum + (normalizedIndicators[key] * weights[key as keyof typeof weights]);
}, 0);

// Ensure final probability is between 0 and 1
return Math.max(0, Math.min(1, probability));
  };

  const getConfidenceLevel = (probability: number): string => {
    if (probability < 0.4) return 'Low';
    if (probability < 0.7) return 'Medium';
    return 'High';
  };

  const generateAnalysis = (indicators: Indicators, lang: LanguageType): string[] => {
    const analysis: string[] = [];
    const threshold = 0.4;

    if (lang === 'es') {
      if (indicators.repetitivePatterns > threshold) {
        analysis.push('Se detectaron patrones repetitivos significativos en la estructura del texto.');
      }
      if (indicators.contentOriginality < threshold) {
        analysis.push('El texto muestra un alto nivel de originalidad en su contenido.');
      }
      if (indicators.formalLanguage > threshold) {
        analysis.push('Se detectó un uso elevado de lenguaje formal y técnico.');
      }
      if (indicators.citationValidity < threshold && indicators.citations > 0) {
        analysis.push('Las citas presentes requieren revisión o están incompletas.');
      }
    } else {
      if (indicators.repetitivePatterns > threshold) {
        analysis.push('Significant repetitive patterns detected in text structure.');
      }
      if (indicators.contentOriginality < threshold) {
        analysis.push('The text shows a high level of content originality.');
      }
      if (indicators.formalLanguage > threshold) {
        analysis.push('High usage of formal and technical language detected.');
      }
      if (indicators.citationValidity < threshold && indicators.citations > 0) {
        analysis.push('Present citations need review or are incomplete.');
      }
    }

    return analysis;
  };

  const generateSuggestions = (indicators: Indicators, lang: LanguageType): string[] => {
    const suggestions: string[] = [];
    const threshold = 0.4;

    if (lang === 'es') {
      if (indicators.repetitivePatterns > threshold) {
        suggestions.push('Considere variar más la estructura de las oraciones para mejorar la naturalidad del texto.');
      }
      if (indicators.formulaicTransitions > threshold) {
        suggestions.push('Intente utilizar conectores más variados y naturales en el texto.');
      }
      if (indicators.formalLanguage > threshold) {
        suggestions.push('Balance el uso de lenguaje técnico con expresiones más naturales.');
      }
      if (indicators.citationValidity < threshold && indicators.citations > 0) {
        suggestions.push('Revise y complete las citas según el formato académico estándar.');
      }
    } else {
      if (indicators.repetitivePatterns > threshold) {
        suggestions.push('Consider varying sentence structure more to improve text naturalness.');
      }
      if (indicators.formulaicTransitions > threshold) {
        suggestions.push('Try using more varied and natural transitions in the text.');
      }
      if (indicators.formalLanguage > threshold) {
        suggestions.push('Balance technical language with more natural expressions.');
      }
      if (indicators.citationValidity < threshold && indicators.citations > 0) {
        suggestions.push('Review and complete citations according to standard academic format.');
      }
    }

    return suggestions;
  };

  const generatePDF = async () => {
    if (!results) return;
    
    const doc = new jsPDF('p', 'pt', 'a4');
    const pageWidth = doc.internal.pageSize.getWidth();
    const contentWidth = pageWidth - 80; // 40px margin on each side
    const marginX = 40;
    let yPosition = 40;
  
    try {
      // Title
      doc.setFontSize(20);
      doc.text(language === 'es' ? 'Resultados y estadísticas' : 'Results and statistics', 40, yPosition);
      yPosition += 50;
  
      // Capture and add the charts
      const chartElements = document.querySelectorAll('.echarts-for-react');
      if (chartElements.length >= 2) {
        // Wait for charts to render
        await new Promise(resolve => setTimeout(resolve, 500));
  
        try {
          const [pieChart, barChart] = chartElements;
          const chartHeight = 108; // 18% of original 600px height
          const gapBetweenCharts = 20;
          const singleChartWidth = (contentWidth - gapBetweenCharts) / 2;

          // Capture pie chart
          const canvas1 = await html2canvas(pieChart as HTMLDivElement);
          const canvas2 = await html2canvas(barChart as HTMLDivElement);

          // Add both charts side by side
          const chartImageData1 = canvas1.toDataURL('image/png');
          const chartImageData2 = canvas2.toDataURL('image/png');

          doc.addImage(chartImageData1, 'PNG', marginX, yPosition, singleChartWidth, chartHeight);
          doc.addImage(chartImageData2, 'PNG', marginX + singleChartWidth + gapBetweenCharts, yPosition, singleChartWidth, chartHeight);

          yPosition += chartHeight + 40;
        } catch (err) {
          console.warn('Error capturing charts:', err);
        }
      }
  
      // Main probability section
      doc.setFontSize(16);
      doc.text(language === 'es' ? 'Probabilidad de IA' : 'AI Probability', 40, yPosition);
      yPosition += 30;
  
      // Add probability values
      doc.setFontSize(12);
      doc.text(`${language === 'es' ? 'IA: ' : 'AI: '}${results.probability.toFixed(1)}%`, 40, yPosition);
      yPosition += 20;
      doc.text(`${language === 'es' ? 'Humano: ' : 'Human: '}${(100 - results.probability).toFixed(1)}%`, 40, yPosition);
      yPosition += 40;
  
      // Detailed indicators section
      doc.setFontSize(16);
      doc.text(language === 'es' ? 'Indicadores Detallados' : 'Detailed Indicators', 40, yPosition);
      yPosition += 30;
  
      // Add indicators
      doc.setFontSize(12);
      const indicators = [
        {
          name: language === 'es' ? 'Patrones Repetitivos' : 'Repetitive Patterns',
          value: (results.indicators.repetitivePatterns * 100).toFixed(1)
        },
        {
          name: language === 'es' ? 'Originalidad' : 'Originality',
          value: ((1 - results.indicators.contentOriginality) * 100).toFixed(1)
        },
        {
          name: language === 'es' ? 'Formalidad' : 'Formality',
          value: (results.indicators.formalLanguage * 100).toFixed(1)
        }
      ];
  
      indicators.forEach(indicator => {
        doc.text(`${indicator.name}: ${indicator.value}%`, 40, yPosition);
        yPosition += 20;
      });
      yPosition += 20;
  
      // Analysis section
      doc.setFontSize(16);
      doc.text(language === 'es' ? 'Análisis Detallado' : 'Detailed Analysis', 40, yPosition);
      yPosition += 30;
  
      // Add analysis text
      doc.setFontSize(12);
      results.detailedAnalysis.forEach(analysis => {
        const lines = doc.splitTextToSize(analysis, contentWidth) as string[];
        lines.forEach((line: string) => {
          if (yPosition > 750) {
            doc.addPage();
            yPosition = 40;
          }
          doc.text(line, 40, yPosition);
          yPosition += 20;
        });
        yPosition += 10;
      });
  
      // Suggestions section
      if (results.suggestions.length > 0) {
        yPosition += 20;
        doc.setFontSize(16);
        doc.text(language === 'es' ? 'Sugerencias' : 'Suggestions', 40, yPosition);
        yPosition += 30;
  
        // Add suggestions
        doc.setFontSize(12);
        results.suggestions.forEach(suggestion => {
          const lines = doc.splitTextToSize(suggestion, contentWidth) as string[];
          lines.forEach((line: string) => {
            if (yPosition > 750) {
              doc.addPage();
              yPosition = 40;
            }
            doc.text('• ' + line, 40, yPosition);
            yPosition += 20;
          });
        });
      }
  
      // Save the PDF
      doc.save(language === 'es' ? 'analisis-ia.pdf' : 'ai-analysis.pdf');
      
    } catch (error) {
      console.error('Error generating PDF:', error);
      throw error;
    }
  };
  
  const generateExcel = () => {
    if (!results) return;
    
    // Create workbook and worksheet
    const wb = XLSX.utils.book_new();
    
    // Main results data
    const mainData = [
      [language === 'es' ? 'Resultados del Análisis de IA' : 'AI Analysis Results'],
      [],
      [language === 'es' ? 'Probabilidad de IA' : 'AI Probability', `${results.probability.toFixed(1)}%`],
      [language === 'es' ? 'Probabilidad Humana' : 'Human Probability', `${(100 - results.probability).toFixed(1)}%`],
      [],
      [language === 'es' ? 'Indicadores Detallados' : 'Detailed Indicators'],
      [
        language === 'es' ? 'Patrones Repetitivos' : 'Repetitive Patterns',
        `${(results.indicators.repetitivePatterns * 100).toFixed(1)}%`
      ],
      [
        language === 'es' ? 'Originalidad' : 'Originality',
        `${((1 - results.indicators.contentOriginality) * 100).toFixed(1)}%`
      ],
      [
        language === 'es' ? 'Formalidad' : 'Formality',
        `${(results.indicators.formalLanguage * 100).toFixed(1)}%`
      ],
      [],
      [language === 'es' ? 'Análisis Detallado' : 'Detailed Analysis'],
      ...results.detailedAnalysis.map(analysis => [analysis]),
      [],
      [language === 'es' ? 'Sugerencias' : 'Suggestions'],
      ...results.suggestions.map(suggestion => [suggestion])
    ];
    
    const ws = XLSX.utils.aoa_to_sheet(mainData);
    
    // Set column widths
    const columnWidths = [{ wch: 50 }, { wch: 20 }];
    ws['!cols'] = columnWidths;
    
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, language === 'es' ? 'Análisis' : 'Analysis');
    
    // Save the file
    XLSX.writeFile(wb, language === 'es' ? 'analisis-ia.xlsx' : 'ai-analysis.xlsx');
  };

  return (
    <>
      <div>
        <WidgetsSectionTitle
          title="A.I Documents & Copyright analyzer"
          subtitle="Get information if documents or texts have been extracted from other artificial intelligences."
          icon={faFileAlt}
          className="mb-5 mt-7"
        />
        <Row className="g-5 mb-5">
          <Col xl={8}>
            <TinymceEditorWithSpinner
              language={language}
              text={text}
              setText={setText}
            />
            
            {isComparing && (
              <TinymceEditorWithSpinner
                language={language}
                text={compareText}
                setText={setCompareText}
                placeholder={language === 'es' ? 'Escribe o pega el texto a comparar...' : 'Write or paste text to compare...'}
              />
            )}
          </Col>

          <Col xl={4} sm={12}>
            <Card className="">
              <Card.Body>
                <h4 className="mb-4">{language === 'es' ? 'Opciones' : 'Options'}</h4>
                <Row className="gx-3 gy-4">
                  <Col xs={12} sm={12} xl={12}>
                    <div className="d-flex gap-2 mb-2">
                      <h5 className="mb-0 text-body-highlight">{language === 'es' ? 'Lenguaje' : 'Language'}</h5>
                    </div>
                    <Form.Select
                      aria-label="language"
                      value={language}
                      onChange={(e) => setLanguage(e.target.value as LanguageType)}
                    >
                      <option value="en">{language === 'es' ? 'Inglés' : 'English'}</option>
                      <option value="es">{language === 'es' ? 'Español' : 'Spanish'}</option>
                    </Form.Select>
                  </Col>
                  <Col xs={12} sm={6} xl={12}>
                    <div className="d-flex gap-2 mb-2">
                      <h5 className="mb-0 text-body-highlight">{language === 'es' ? 'Contexto' : 'Context'}</h5>
                    </div>
                    <Form.Select
                      aria-label="context"
                      value={textType}
                      onChange={(e) => setTextType(e.target.value as TextType)}
                    >
                      <option value="general">{language === 'es' ? 'General' : 'General'}</option>
                      <option value="academic">{language === 'es' ? 'Académico' : 'Academic'}</option>
                      <option value="business">{language === 'es' ? 'Empresarial' : 'Enterprise'}</option>
                    </Form.Select>
                  </Col>
                  <Col xs={12} sm={6} xl={12}>
                    <h5 className="mb-2 text-body-highlight">
                      {language === 'es' ? 'Análisis de Archivos' : 'File Analysis'} 
                      <OverlayTrigger
                        overlay={
                          <Tooltip className='text-body-highlight text-left'> 
                            {language === 'es' ? 
                              <div className='text-start'>Formatos soportados:<br /> PDF, DOCX, CSV, XLSX, XLS, TXT</div> : 
                              <div className='text-start'>Supported formats: <br />PDF, DOCX, CSV, XLSX, XLS, TXT</div>
                            }
                          </Tooltip>
                        }
                      >
                        <FontAwesomeIcon className="px-2" icon={faCircleInfo} />
                      </OverlayTrigger>
                    </h5>
                    <div>
                      <Form.Group className="mb-3">
                        <FileUploadSection 
                          language={language}
                          onFileContent={setFileContent}
                          fileStatus={fileStatus}
                          setFileStatus={setFileStatus}
                        />
                      </Form.Group>
                    </div>
                  </Col>
                  <Col xs={12} sm={6} xl={12}>
                    <h5 className="mb-2 text-body-highlight">
                      {language === 'es' ? 'Comparación de Texto' : 'Texts Comparision'}
                    </h5>
                    <Form.Check 
                      type='switch'
                      id='defaultSwitch'
                      label={language === 'es' ? 'Activar comparación de texto' : 'Enable texts comparision'}
                      onClick={() => setIsComparing(!isComparing)}
                    />
                  </Col>
                  <Col xs={12} sm={6} xl={12}>
                    <div className="d-flex gap-2 mb-2">
                      <Button 
                        type="submit" 
                        className="mb-2"
                        onClick={analyzeText}
                      >
                        {language === 'es' ? 'Analizar Texto' : 'Analyze Text'}
                      </Button>

                      <Form.Check
                        type='switch'
                        id='defaultDisableSwitch'
                        label={language === 'es' ? 'Modo deep' : 'Deep mode'}
                        disabled 
                      />

                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className='g-5 mb-5'>
          {results && (
            <Col xl={12}>
              <Card className=''>
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <h4 className="mb-0">{language === 'es' ? 'Resultados y estadísticas' : 'Results and statistics'}</h4>
                  <Dropdown align="end">
                    <Dropdown.Toggle variant="outline-primary" size="sm" id="download-dropdown">
                      <FontAwesomeIcon icon={faDownload} className="me-2" />
                      {language === 'es' ? 'Descargar' : 'Download'}
                    </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={generatePDF}>
                        {language === 'es' ? 'Descargar PDF' : 'Download PDF'}
                        </Dropdown.Item>
                     <Dropdown.Item onClick={generateExcel}>
                      {language === 'es' ? 'Descargar Excel' : 'Download Excel'}
                    </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="space-y-6">
                  <AnalysisVisuals results={results} language={language} />
                </div>
              </Card.Body>
              </Card>
              {fileContent && (
                <div className='mt-3'>
                  <Card className="">
                    <Card.Body>
                      <h4 className="text-sm font-medium text-gray-700">
                        {language === 'es' ? 'Contenido del archivo analizado:' : 'File Content analyzed:'}
                      </h4>
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="file" className="border-top">
                        <Accordion.Header className="truncate overflow-hidden text-ellipsis whitespace-nowrap w-full">
                            {fileStatus.currentFile || (language === 'es' ? 'Archivo analizado' : 'Analyzed file')}
                          </Accordion.Header>
                          <Accordion.Body className="pt-0">
                            <pre className="text-sm whitespace-pre-wrap">{fileContent}</pre>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Card.Body>
                  </Card>
                </div>
              )}
            </Col>
          )}
        </Row>
      </div>
    </>
  );
};

export default IADetect;

/* @v0.4 - preload cargado, descarga informe básico pdf/excel ajustes visuales - 1102025-21:02
import React, { useState, useEffect, ChangeEvent } from 'react';
import _ from 'lodash';
import AnalysisVisuals from 'components/modules/widgets/WidgetIADetectGraph';
import WidgetsSectionTitle from 'components/modules/widgets/WidgetsSectionTitle';
import { Accordion, Alert, Card, Col, Dropdown, Form, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import { faCircleInfo, faFileAlt, faDownload, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import TinymceEditor from 'components/base/TinymceEditor';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import mammoth from 'mammoth';
import * as XLSX from 'xlsx';
import * as pdfjsLib from 'pdfjs-dist';
import { Link } from 'react-router-dom';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

type LanguageType = 'es' | 'en';
type TextType = 'academic' | 'business' | 'general';

interface FileProcessingResult {
  content: string;
  metadata: {
    type: string;
    pageCount?: number;
    sheets?: number;
    size?: number;
    documentInfo?: string;
    messages?: any[];
    lastModified?: string;
  };
}

interface WorkbookType {
  SheetNames: string[];
  Sheets: {
    [key: string]: any;
  };
}

interface Patterns {
  transitions: string[];
  formalWords: string[];
  commonPhrases: string[];
  academicPhrases: string[];
  businessPhrases: string[];
}

interface Indicators {
  repetitivePatterns: number;
  formulaicTransitions: number;
  formalLanguage: number;
  consistentTone: number;
  standardPhrases: number;
  structuralPatterns: number;
  styleConsistency: number;
  citations: number;
  documentSimilarity: number;
  citationValidity: number;
  imageAnalysis: number;
  contentOriginality: number;
}

interface Results {
  probability: number;
  indicators: Indicators;
  confidence: string;
  detailedAnalysis: string[];
  suggestions: string[];
}

interface FileStatus {
  analyzing: boolean;
  error?: string;
  currentFile?: string;
  metadata?: any;
}

const TinymceEditorWithSpinner: React.FC<{
  language: LanguageType;
  text: string;
  setText: (content: string) => void;
  placeholder?: string;
}> = ({ language, text, setText, placeholder }) => {
  const [isEditorLoading, setIsEditorLoading] = useState(true);

  return (
    <div className="position-relative" style={{ minHeight: '15rem' }}>
      {isEditorLoading && (
        <div className="" style={{ zIndex: 1000 }}>
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      
      <TinymceEditor
        options={{
          height: '15rem',
          placeholder: placeholder || (language === 'es' ? 'Escribe o pega el texto original aquí...' : 'Write or paste original text here...'),
          menubar: false,
          plugins: 'paste',
          toolbar: true,
          setup: (editor) => {
            editor.on('init', () => {
              setIsEditorLoading(false);
            });
          },
          disabled: false
        }}
        value={text}
        onChange={(contentA) => setText(contentA)}
      />
    </div>
  );
};

const patterns: Record<LanguageType, Patterns> = {
  es: {
    transitions: [
      'además', 'sin embargo', 'por lo tanto', 'en consecuencia', 'por otra parte',
      'en primer lugar', 'en segundo lugar', 'finalmente', 'de esta manera'
    ],
    formalWords: [
      'implementación', 'metodología', 'paradigma', 'infraestructura', 'optimización',
      'mediante', 'asimismo', 'concerniente', 'respectivamente', 'previamente'
    ],
    commonPhrases: [
      'es importante señalar', 'cabe mencionar que', 'es necesario destacar',
      'se recomienda', 'se propone', 'se sugiere'
    ],
    academicPhrases: [
      'según el autor', 'los resultados indican', 'se puede observar',
      'el análisis muestra', 'la investigación revela'
    ],
    businessPhrases: [
      'optimizar procesos', 'maximizar beneficios', 'implementar soluciones',
      'generar valor', 'mejorar la eficiencia'
    ]
  },
  en: {
    transitions: [
      'furthermore', 'however', 'therefore', 'consequently', 'moreover',
      'firstly', 'secondly', 'finally', 'in addition'
    ],
    formalWords: [
      'implementation', 'methodology', 'paradigm', 'infrastructure', 'optimization',
      'utilize', 'facilitate', 'leverage', 'streamline'
    ],
    commonPhrases: [
      'it is important to note', 'it should be mentioned', 'it is worth noting',
      'it is recommended', 'it is suggested'
    ],
    academicPhrases: [
      'according to the author', 'the results indicate', 'it can be observed',
      'the analysis shows', 'research reveals'
    ],
    businessPhrases: [
      'optimize processes', 'maximize benefits', 'implement solutions',
      'generate value', 'improve efficiency'
    ]
  }
};

const FileUploadSection: React.FC<{
  language: LanguageType;
  onFileContent: (content: string) => void;
  fileStatus: FileStatus;
  setFileStatus: React.Dispatch<React.SetStateAction<FileStatus>>;
}> = ({ language, onFileContent, fileStatus, setFileStatus }) => {
  const [isProcessingFile, setIsProcessingFile] = useState(false);
  const [isProcessingInput, setIsProcessingInput] = useState(false);
  const [fileAttachment, setFileAttachment] = useState<File | null>(null);
  const [fileContent, setFileContent] = useState<string | null>(null);
  const [imageAttachments, setImageAttachments] = useState<File[]>([]);

  const handleClearFile = () => {
    const fileInput = document.querySelector('input[type="file"]') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
    
    setFileStatus({
      analyzing: false,
      currentFile: undefined,
      metadata: undefined,
      error: undefined
    });
    setFileAttachment(null);
    setFileContent(null);
    onFileContent('');
  };

  useEffect(() => {
    if (!isProcessingInput && isProcessingFile) {
      setFileAttachment(null);
      setFileContent(null);
      setIsProcessingFile(false);
    }
  }, [isProcessingInput]);

  const processPDF = async (file: File): Promise<FileProcessingResult> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const loadingTask = pdfjsLib.getDocument({ data: arrayBuffer });
      const pdfDoc = await loadingTask.promise;
      let fullText = '';
      for (let i = 1; i <= pdfDoc.numPages; i++) {
        const page = await pdfDoc.getPage(i);
        const textContent = await page.getTextContent();
        const pageText = textContent.items
          .map((item: any) => item.str)
          .join(' ');
        fullText += `\n--- Page ${i} ---\n` + pageText;
      }
      return {
        content: fullText.trim(),
        metadata: {
          type: 'PDF Document',
          pageCount: pdfDoc.numPages,
          documentInfo: 'PDF Document'
        }
      };
    } catch (error) {
      console.error('Error processing PDF:', error);
      throw error;
    }
  };

  const processExcel = async (file: File): Promise<FileProcessingResult> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const workbook = XLSX.read(arrayBuffer) as WorkbookType;
      let fullText = '';
      
      workbook.SheetNames.forEach((sheetName: string) => {
        const worksheet = workbook.Sheets[sheetName];
        fullText += `\n--- Sheet: ${sheetName} ---\n`;
        fullText += XLSX.utils.sheet_to_csv(worksheet, { blankrows: false });
      });
      
      return {
        content: fullText.trim(),
        metadata: {
          type: 'Excel Document',
          sheets: workbook.SheetNames.length
        }
      };
    } catch (error) {
      console.error('Error processing Excel:', error);
      throw error;
    }
  };

  const processWord = async (file: File): Promise<FileProcessingResult> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const result = await mammoth.extractRawText({ arrayBuffer });
      return {
        content: result.value,
        metadata: {
          type: 'Word Document',
          messages: result.messages
        }
      };
    } catch (error) {
      console.error('Error processing Word:', error);
      throw error;
    }
  };

  const processTextFile = async (file: File): Promise<FileProcessingResult> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve({
          content: e.target?.result as string,
          metadata: {
            type: file.name.endsWith('.csv') ? 'CSV Document' : 'Text Document',
            size: file.size,
            lastModified: new Date(file.lastModified).toISOString()
          }
        });
      };
      reader.onerror = reject;
      reader.readAsText(file);
    });
  };

  const processFile = async (file: File): Promise<FileProcessingResult | null> => {
    const fileType = file.name.split('.').pop()?.toLowerCase();
    try {
      setIsProcessingFile(true);
      switch (fileType) {
        case 'pdf':
          return await processPDF(file);
        case 'xlsx':
        case 'xls':
          return await processExcel(file);
        case 'docx':
        case 'doc':
          return await processWord(file);
        case 'txt':
        case 'csv':
          return await processTextFile(file);
        default:
          throw new Error(`${language === 'es' ? 'Tipo de archivo no soportado' : 'Unsupported file type'}: ${fileType}`);
      }
    } catch (error) {
      console.error('Error processing file:', error);
      return null;
    } finally {
      setIsProcessingFile(false);
    }
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setFileStatus({ analyzing: true, currentFile: file.name });
    setFileAttachment(file);

    try {
      const result = await processFile(file);
      if (result) {
        setFileContent(result.content);
        onFileContent(result.content);
        setFileStatus({
          analyzing: false,
          currentFile: file.name,
          metadata: result.metadata
        });
      } else {
        throw new Error(language === 'es' ? 'Error procesando archivo' : 'Error processing file');
      }
    } catch (error) {
      setFileStatus({
        analyzing: false,
        error: error instanceof Error ? error.message : 'Error desconocido',
        currentFile: file.name
      });
    }
  };

  return (
    <>
      <div className="space-y-4">
        <label
          htmlFor="file-upload"
          className="b-1 absolute cursor-pointer bg-transparent rounded-md font-medium text-blue-600 hover:text-blue-500"
        >
          <Form.Control
            id="file-upload"
            name="file-upload"
            className=""
            type="file"
            size="sm"
            accept=".pdf,.docx,.doc,.xlsx,.xls,.txt,.csv"
            onChange={handleFileUpload}
            disabled={fileStatus.analyzing}
          />
        </label>
      </div>

      {fileStatus.analyzing && (
        <div className="text-sm text-gray-500">
          <Spinner animation="border" variant="info" /> {language === 'es' ? 'Analizando archivo...' : 'Analyzing file...'} 
        </div>
      )}

      {fileStatus.error && (
        <div className="text-sm text-red-500 m-2">
          <Alert key="danger" variant="danger">
            {fileStatus.error}
          </Alert>
        </div>
      )}
            
      {fileStatus.currentFile && !fileStatus.analyzing && !fileStatus.error && (
        <div className="text-sm">
          <p className="text-green-500 m-1">
            {language === 'es' ? 'Archivo para análisis: ' : 'File to analysis: '} 
            <div className="fs-9 m-2 italic">{fileStatus.currentFile}</div>
          </p>
          {fileStatus.metadata && (
            <div>
              <Link to={''}>
                <FontAwesomeIcon className='px-2' icon={faTrashCan} onClick={handleClearFile} title={language === 'es' ? 'Eliminar archivo' : 'Clear file'} />
              </Link>
              {fileStatus.metadata.type}
              {fileStatus.metadata.pageCount && ` - ${fileStatus.metadata.pageCount} pages`}
              {fileStatus.metadata.sheets && ` - ${fileStatus.metadata.sheets} sheets`}
            </div>
          )}
        </div>
      )}
    </>
  );
};

const IADetect: React.FC = () => {
  const [text, setText] = useState('');
  const [language, setLanguage] = useState<LanguageType>('es');
  const [textType, setTextType] = useState<TextType>('academic');
  const [results, setResults] = useState<Results | null>(null);
  const [compareText, setCompareText] = useState('');
  const [isComparing, setIsComparing] = useState(false);
  const [fileContent, setFileContent] = useState<string | null>(null);
  const [fileStatus, setFileStatus] = useState<FileStatus>({
    analyzing: false
  });

  const analyzeTextComplexity = (text: string): number => {
    const sentences = text.match(/[^.!?]+[.!?]+/g) || [];
    const words = text.split(/\s+/);
    
    const avgWordLength = _.meanBy(words, 'length');
    const avgSentenceLength = words.length / sentences.length;
    const uniqueWords = new Set(words.map(w => w.toLowerCase())).size;
    const lexicalDiversity = uniqueWords / words.length;
    
    return (avgWordLength * 0.2 + avgSentenceLength * 0.3 + lexicalDiversity * 0.5);
  };

  const generateNGrams = (text: string, n: number): string[] => {
    const words = text.toLowerCase().split(/\s+/);
    const nGrams: string[] = [];
    for (let i = 0; i <= words.length - n; i++) {
      nGrams.push(words.slice(i, i + n).join(' '));
    }
    return nGrams;
  };

  const calculateSimilarity = (text1: string, text2: string): number => {
    const ngrams1 = new Set(generateNGrams(text1, 3));
    const ngrams2 = new Set(generateNGrams(text2, 3));
    
    const intersection = new Set([...ngrams1].filter(x => ngrams2.has(x)));
    const union = new Set([...ngrams1, ...ngrams2]);
    
    return intersection.size / union.size;
  };

  const findCitations = (text: string): Array<{citation: string, confidence: number}> => {
    const citationPatterns = [
      /\(([^)]+),\s*\d{4}\)/g,
      /([A-Z][a-z]+(?:\s+[A-Z][a-z]+)*)\s+\(\d{4}\)/g,
      /"([^"]+)"\s*\(([^)]+)\)/g
    ];

    const citations = [];
    for (const pattern of citationPatterns) {
      const matches = Array.from(text.matchAll(pattern));
      for (const match of matches) {
        citations.push({
          citation: match[0],
          confidence: validateCitation(match[0])
        });
      }
    }
    return citations;
  };

  const validateCitation = (citation: string): number => {
    const hasYear = /\d{4}/.test(citation);
    const hasAuthor = /[A-Z][a-z]+/.test(citation);
    const hasProperFormatting = /\(.*\)/.test(citation);
    
    let score = 0;
    if (hasYear) score += 0.4;
    if (hasAuthor) score += 0.4;
    if (hasProperFormatting) score += 0.2;
    
    return score;
  };

  const calculatePatternFrequency = (text: string, patterns: string[]): number => {
    const normalizedText = text.toLowerCase();
    const matches = patterns.reduce((count, pattern) => {
      const regex = new RegExp(`\\b${pattern}\\b`, 'gi');
      return count + (normalizedText.match(regex) || []).length;
    }, 0);
    
    return Math.min(matches / Math.max(1, text.split(/\s+/).length / 20), 1);
  };

  const analyzeText = () => {
    const textToAnalyze = text || fileContent || '';
    if (!textToAnalyze.trim()) return;

    const normalizedText = textToAnalyze.toLowerCase();
    const sentences = textToAnalyze.match(/[^.!?]+[.!?]+/g) || [];
    const words = textToAnalyze.split(/\s+/);
    
    const textComplexity = analyzeTextComplexity(textToAnalyze);
    const citations = findCitations(textToAnalyze);
    const nGrams = generateNGrams(textToAnalyze, 3);

    const currentPatterns = patterns[language];
    const indicators: Indicators = {
      repetitivePatterns: sentences.length > 1 ? 1 - (new Set(sentences).size / sentences.length) : 0,
      formulaicTransitions: calculatePatternFrequency(textToAnalyze, currentPatterns.transitions),
      formalLanguage: calculatePatternFrequency(textToAnalyze, currentPatterns.formalWords),
      consistentTone: textComplexity,
      standardPhrases: calculatePatternFrequency(textToAnalyze, [
        ...currentPatterns.commonPhrases,
        ...(textType === 'academic' ? currentPatterns.academicPhrases : []),
        ...(textType === 'business' ? currentPatterns.businessPhrases : [])
      ]),
      structuralPatterns: 0,
      styleConsistency: 0,
      citations: citations.length / Math.max(1, sentences.length / 5),
      documentSimilarity: isComparing ? calculateSimilarity(textToAnalyze, compareText) : 0,
      citationValidity: citations.length > 0 ? _.meanBy(citations, 'confidence') : 0,
      imageAnalysis: 0,
      contentOriginality: 1 - (new Set(nGrams).size / nGrams.length)
    };

    const probability = calculateFinalProbability(indicators);
    
    setResults({
      probability: probability * 100,
      indicators,
      confidence: getConfidenceLevel(probability),
      detailedAnalysis: generateAnalysis(indicators, language),
      suggestions: generateSuggestions(indicators, language)
    });
  };

  const calculateFinalProbability = (indicators: Indicators): number => {
    const weights = {
      repetitivePatterns: 0.15,
      formulaicTransitions: 0.1,
      formalLanguage: 0.1,
      consistentTone: 0.1,
      standardPhrases: 0.1,
      structuralPatterns: 0.1,
      styleConsistency: 0.05,
      citations: 0.05,
      documentSimilarity: 0.15,
      citationValidity: 0.05,
      imageAnalysis: 0.025,
      contentOriginality: 0.075
    };

    return Object.keys(indicators).reduce((sum, key) => {
      return sum + (indicators[key as keyof Indicators] * weights[key as keyof typeof weights]);
    }, 0);
  };

  const getConfidenceLevel = (probability: number): string => {
    if (probability < 0.4) return 'Low';
    if (probability < 0.7) return 'Medium';
    return 'High';
  };

  const generateAnalysis = (indicators: Indicators, lang: LanguageType): string[] => {
    const analysis: string[] = [];
    const threshold = 0.4;

    if (lang === 'es') {
      if (indicators.repetitivePatterns > threshold) {
        analysis.push('Se detectaron patrones repetitivos significativos en la estructura del texto.');
      }
      if (indicators.contentOriginality < threshold) {
        analysis.push('El texto muestra un alto nivel de originalidad en su contenido.');
      }
      if (indicators.formalLanguage > threshold) {
        analysis.push('Se detectó un uso elevado de lenguaje formal y técnico.');
      }
      if (indicators.citationValidity < threshold && indicators.citations > 0) {
        analysis.push('Las citas presentes requieren revisión o están incompletas.');
      }
    } else {
      if (indicators.repetitivePatterns > threshold) {
        analysis.push('Significant repetitive patterns detected in text structure.');
      }
      if (indicators.contentOriginality < threshold) {
        analysis.push('The text shows a high level of content originality.');
      }
      if (indicators.formalLanguage > threshold) {
        analysis.push('High usage of formal and technical language detected.');
      }
      if (indicators.citationValidity < threshold && indicators.citations > 0) {
        analysis.push('Present citations need review or are incomplete.');
      }
    }

    return analysis;
  };

  const generateSuggestions = (indicators: Indicators, lang: LanguageType): string[] => {
    const suggestions: string[] = [];
    const threshold = 0.4;

    if (lang === 'es') {
      if (indicators.repetitivePatterns > threshold) {
        suggestions.push('Considere variar más la estructura de las oraciones para mejorar la naturalidad del texto.');
      }
      if (indicators.formulaicTransitions > threshold) {
        suggestions.push('Intente utilizar conectores más variados y naturales en el texto.');
      }
      if (indicators.formalLanguage > threshold) {
        suggestions.push('Balance el uso de lenguaje técnico con expresiones más naturales.');
      }
      if (indicators.citationValidity < threshold && indicators.citations > 0) {
        suggestions.push('Revise y complete las citas según el formato académico estándar.');
      }
    } else {
      if (indicators.repetitivePatterns > threshold) {
        suggestions.push('Consider varying sentence structure more to improve text naturalness.');
      }
      if (indicators.formulaicTransitions > threshold) {
        suggestions.push('Try using more varied and natural transitions in the text.');
      }
      if (indicators.formalLanguage > threshold) {
        suggestions.push('Balance technical language with more natural expressions.');
      }
      if (indicators.citationValidity < threshold && indicators.citations > 0) {
        suggestions.push('Review and complete citations according to standard academic format.');
      }
    }

    return suggestions;
  };

  const generatePDF = async () => {
    if (!results) return;
    
    const doc = new jsPDF('p', 'pt', 'a4');
    const pageWidth = doc.internal.pageSize.getWidth();
    const contentWidth = pageWidth - 80;
    let yPosition = 40;
  
    try {
      doc.setFontSize(20);
      doc.text(language === 'es' ? 'Resultados y estadísticas' : 'Results and statistics', 40, yPosition);
      yPosition += 50;
  
      doc.setFontSize(16);
      doc.text(language === 'es' ? 'Probabilidad de IA' : 'AI Probability', 40, yPosition);
      yPosition += 30;
  
      doc.setFontSize(12);
      doc.text(`${language === 'es' ? 'IA: ' : 'AI: '}${results.probability.toFixed(1)}%`, 40, yPosition);
      yPosition += 20;
      doc.text(`${language === 'es' ? 'Humano: ' : 'Human: '}${(100 - results.probability).toFixed(1)}%`, 40, yPosition);
      yPosition += 40;
  
      doc.setFontSize(16);
      doc.text(language === 'es' ? 'Indicadores Detallados' : 'Detailed Indicators', 40, yPosition);
      yPosition += 30;
  
      doc.setFontSize(12);
      const indicators = [
        {
          name: language === 'es' ? 'Patrones Repetitivos' : 'Repetitive Patterns',
          value: (results.indicators.repetitivePatterns * 100).toFixed(1)
        },
        {
          name: language === 'es' ? 'Originalidad' : 'Originality',
          value: ((1 - results.indicators.contentOriginality) * 100).toFixed(1)
        },
        {
          name: language === 'es' ? 'Formalidad' : 'Formality',
          value: (results.indicators.formalLanguage * 100).toFixed(1)
        }
      ];
  
      indicators.forEach(indicator => {
        doc.text(`${indicator.name}: ${indicator.value}%`, 40, yPosition);
        yPosition += 20;
      });
      yPosition += 20;
  
      doc.setFontSize(16);
      doc.text(language === 'es' ? 'Análisis Detallado' : 'Detailed Analysis', 40, yPosition);
      yPosition += 30;
  
      doc.setFontSize(12);
      results.detailedAnalysis.forEach(analysis => {
        const lines = doc.splitTextToSize(analysis, contentWidth) as string[];
        lines.forEach((line: string) => {
          if (yPosition > 750) {
            doc.addPage();
            yPosition = 40;
          }
          doc.text(line, 40, yPosition);
          yPosition += 20;
        });
        yPosition += 10;
      });
  
      if (results.suggestions.length > 0) {
        yPosition += 20;
        doc.setFontSize(16);
        doc.text(language === 'es' ? 'Sugerencias' : 'Suggestions', 40, yPosition);
        yPosition += 30;
  
        doc.setFontSize(12);
        results.suggestions.forEach(suggestion => {
          const lines = doc.splitTextToSize(suggestion, contentWidth) as string[];
          lines.forEach((line: string) => {
            if (yPosition > 750) {
              doc.addPage();
              yPosition = 40;
            }
            doc.text('• ' + line, 40, yPosition);
            yPosition += 20;
          });
        });
      }
  
      doc.save(language === 'es' ? 'analisis-ia.pdf' : 'ai-analysis.pdf');
      
    } catch (error) {
      console.error('Error generating PDF:', error);
      throw error;
    }
  };
  
  const generateExcel = () => {
    if (!results) return;
        const wb = XLSX.utils.book_new();
        const mainData = [
      [language === 'es' ? 'Resultados del Análisis de IA' : 'AI Analysis Results'],
      [],
      [language === 'es' ? 'Probabilidad de IA' : 'AI Probability', `${results.probability.toFixed(1)}%`],
      [language === 'es' ? 'Probabilidad Humana' : 'Human Probability', `${(100 - results.probability).toFixed(1)}%`],
      [],
      [language === 'es' ? 'Indicadores Detallados' : 'Detailed Indicators'],
      [
        language === 'es' ? 'Patrones Repetitivos' : 'Repetitive Patterns',
        `${(results.indicators.repetitivePatterns * 100).toFixed(1)}%`
      ],
      [
        language === 'es' ? 'Originalidad' : 'Originality',
        `${((1 - results.indicators.contentOriginality) * 100).toFixed(1)}%`
      ],
      [
        language === 'es' ? 'Formalidad' : 'Formality',
        `${(results.indicators.formalLanguage * 100).toFixed(1)}%`
      ],
      [],
      [language === 'es' ? 'Análisis Detallado' : 'Detailed Analysis'],
      ...results.detailedAnalysis.map(analysis => [analysis]),
      [],
      [language === 'es' ? 'Sugerencias' : 'Suggestions'],
      ...results.suggestions.map(suggestion => [suggestion])
    ];
    
    const ws = XLSX.utils.aoa_to_sheet(mainData);
    const columnWidths = [{ wch: 50 }, { wch: 20 }];
    ws['!cols'] = columnWidths;
    XLSX.utils.book_append_sheet(wb, ws, language === 'es' ? 'Análisis' : 'Analysis');
    XLSX.writeFile(wb, language === 'es' ? 'analisis-ia.xlsx' : 'ai-analysis.xlsx');
  };

  return (
    <>
      <div>
        <WidgetsSectionTitle
          title="A.I Documents & Copyright analyzer"
          subtitle="Get information if documents or texts have been extracted from other artificial intelligences."
          icon={faFileAlt}
          className="mb-5 mt-7"
        />
        <Row className="g-5 mb-5">
          <Col xl={8}>
            <TinymceEditorWithSpinner
              language={language}
              text={text}
              setText={setText}
            />
            
            {isComparing && (
              <TinymceEditorWithSpinner
                language={language}
                text={compareText}
                setText={setCompareText}
                placeholder={language === 'es' ? 'Escribe o pega el texto a comparar...' : 'Write or paste text to compare...'}
              />
            )}
          </Col>

          <Col xl={4} sm={12}>
            <Card className="">
              <Card.Body>
                <h4 className="mb-4">{language === 'es' ? 'Opciones' : 'Options'}</h4>
                <Row className="gx-3 gy-4">
                  <Col xs={12} sm={12} xl={12}>
                    <div className="d-flex gap-2 mb-2">
                      <h5 className="mb-0 text-body-highlight">{language === 'es' ? 'Lenguaje' : 'Language'}</h5>
                    </div>
                    <Form.Select
                      aria-label="language"
                      value={language}
                      onChange={(e) => setLanguage(e.target.value as LanguageType)}
                    >
                      <option value="en">{language === 'es' ? 'Inglés' : 'English'}</option>
                      <option value="es">{language === 'es' ? 'Español' : 'Spanish'}</option>
                    </Form.Select>
                  </Col>
                  <Col xs={12} sm={6} xl={12}>
                    <div className="d-flex gap-2 mb-2">
                      <h5 className="mb-0 text-body-highlight">{language === 'es' ? 'Contexto' : 'Context'}</h5>
                    </div>
                    <Form.Select
                      aria-label="context"
                      value={textType}
                      onChange={(e) => setTextType(e.target.value as TextType)}
                    >
                      <option value="general">{language === 'es' ? 'General' : 'General'}</option>
                      <option value="academic">{language === 'es' ? 'Académico' : 'Academic'}</option>
                      <option value="business">{language === 'es' ? 'Empresarial' : 'Enterprise'}</option>
                    </Form.Select>
                  </Col>
                  <Col xs={12} sm={6} xl={12}>
                    <h5 className="mb-2 text-body-highlight">
                      {language === 'es' ? 'Análisis de Archivos' : 'File Analysis'} 
                      <OverlayTrigger
                        overlay={
                          <Tooltip className='text-body-highlight text-left'> 
                            {language === 'es' ? 
                              <div className='text-start'>Formatos soportados:<br /> PDF, DOCX, CSV, XLSX, XLS, TXT</div> : 
                              <div className='text-start'>Supported formats: <br />PDF, DOCX, CSV, XLSX, XLS, TXT</div>
                            }
                          </Tooltip>
                        }
                      >
                        <FontAwesomeIcon className="px-2" icon={faCircleInfo} />
                      </OverlayTrigger>
                    </h5>
                    <div>
                      <Form.Group className="mb-3">
                        <FileUploadSection 
                          language={language}
                          onFileContent={setFileContent}
                          fileStatus={fileStatus}
                          setFileStatus={setFileStatus}
                        />
                      </Form.Group>
                    </div>
                  </Col>
                  <Col xs={12} sm={6} xl={12}>
                    <h5 className="mb-2 text-body-highlight">
                      {language === 'es' ? 'Comparación de Texto' : 'Texts Comparision'}
                    </h5>
                    <Form.Check 
                      type='switch'
                      id='defaultSwitch'
                      label={language === 'es' ? 'Activar comparación de texto' : 'Enable texts comparision'}
                      onClick={() => setIsComparing(!isComparing)}
                    />
                  </Col>
                  <Col xs={12} sm={6} xl={12}>
                    <div className="d-flex gap-2 mb-2">
                      <Button 
                        type="submit" 
                        className="mb-2"
                        onClick={analyzeText}
                      >
                        {language === 'es' ? 'Analizar Texto' : 'Analyze Text'}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className='g-5 mb-5'>
          {results && (
            <Col xl={12}>
              <Card className=''>
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <h4 className="mb-0">{language === 'es' ? 'Resultados y estadísticas' : 'Results and statistics'}</h4>
                  <Dropdown align="end">
                    <Dropdown.Toggle variant="outline-primary" size="sm" id="download-dropdown">
                      <FontAwesomeIcon icon={faDownload} className="me-2" />
                      {language === 'es' ? 'Descargar' : 'Download'}
                    </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={generatePDF}>
                        {language === 'es' ? 'Descargar PDF' : 'Download PDF'}
                        </Dropdown.Item>
                     <Dropdown.Item onClick={generateExcel}>
                      {language === 'es' ? 'Descargar Excel' : 'Download Excel'}
                    </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="space-y-6">
                  <AnalysisVisuals results={results} language={language} />
                </div>
              </Card.Body>
              </Card>
              {fileContent && (
                <div className='mt-3'>
                  <Card className="">
                    <Card.Body>
                      <h4 className="text-sm font-medium text-gray-700">
                        {language === 'es' ? 'Contenido del archivo analizado:' : 'File Content analyzed:'}
                      </h4>
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="file" className="border-top">
                        <Accordion.Header className="truncate overflow-hidden text-ellipsis whitespace-nowrap w-full">
                            {fileStatus.currentFile || (language === 'es' ? 'Archivo analizado' : 'Analyzed file')}
                          </Accordion.Header>
                          <Accordion.Body className="pt-0">
                            <pre className="text-sm whitespace-pre-wrap">{fileContent}</pre>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Card.Body>
                  </Card>
                </div>
              )}
            </Col>
          )}
        </Row>
      </div>
    </>
  );
};

export default IADetect;

/* @v0.3 
import React, { useState, useEffect, ChangeEvent } from 'react';
import _ from 'lodash';
import WidgetsSectionTitle from 'components/modules/widgets/WidgetsSectionTitle';
import { Alert, Card, Col, Form, OverlayTrigger, Placeholder, Row, Spinner, Tooltip } from 'react-bootstrap';
import { faCircleInfo, faFileAlt, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import TinymceEditor from 'components/base/TinymceEditor';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import mammoth from 'mammoth';
import * as XLSX from 'xlsx';
import * as pdfjsLib from 'pdfjs-dist';

type LanguageType = 'es' | 'en';
type TextType = 'academic' | 'business' | 'general';

interface FileProcessingResult {
  content: string;
  metadata: {
    type: string;
    pageCount?: number;
    sheets?: number;
    size?: number;
    documentInfo?: string;
    messages?: any[];
    lastModified?: string;
  };
}

interface WorkbookType {
  SheetNames: string[];
  Sheets: {
    [key: string]: any;
  };
}

interface Patterns {
  transitions: string[];
  formalWords: string[];
  commonPhrases: string[];
  academicPhrases: string[];
  businessPhrases: string[];
}

interface Indicators {
  repetitivePatterns: number;
  formulaicTransitions: number;
  formalLanguage: number;
  consistentTone: number;
  standardPhrases: number;
  structuralPatterns: number;
  styleConsistency: number;
  citations: number;
  documentSimilarity: number;
  citationValidity: number;
  imageAnalysis: number;
  contentOriginality: number;
}

interface Results {
  probability: number;
  indicators: Indicators;
  confidence: string;
  detailedAnalysis: string[];
  suggestions: string[];
}

// Language Patterns
const patterns: Record<LanguageType, Patterns> = {
  es: {
    transitions: [
      'además', 'sin embargo', 'por lo tanto', 'en consecuencia', 'por otra parte',
      'en primer lugar', 'en segundo lugar', 'finalmente', 'de esta manera'
    ],
    formalWords: [
      'implementación', 'metodología', 'paradigma', 'infraestructura', 'optimización',
      'mediante', 'asimismo', 'concerniente', 'respectivamente', 'previamente'
    ],
    commonPhrases: [
      'es importante señalar', 'cabe mencionar que', 'es necesario destacar',
      'se recomienda', 'se propone', 'se sugiere'
    ],
    academicPhrases: [
      'según el autor', 'los resultados indican', 'se puede observar',
      'el análisis muestra', 'la investigación revela'
    ],
    businessPhrases: [
      'optimizar procesos', 'maximizar beneficios', 'implementar soluciones',
      'generar valor', 'mejorar la eficiencia'
    ]
  },
  en: {
    transitions: [
      'furthermore', 'however', 'therefore', 'consequently', 'moreover',
      'firstly', 'secondly', 'finally', 'in addition'
    ],
    formalWords: [
      'implementation', 'methodology', 'paradigm', 'infrastructure', 'optimization',
      'utilize', 'facilitate', 'leverage', 'streamline'
    ],
    commonPhrases: [
      'it is important to note', 'it should be mentioned', 'it is worth noting',
      'it is recommended', 'it is suggested'
    ],
    academicPhrases: [
      'according to the author', 'the results indicate', 'it can be observed',
      'the analysis shows', 'research reveals'
    ],
    businessPhrases: [
      'optimize processes', 'maximize benefits', 'implement solutions',
      'generate value', 'improve efficiency'
    ]
  }
};

// Pie Chart Component
const PieChart: React.FC<{
  data: { label: string; value: number; color: string }[];
  title: string;
}> = ({ data, title }) => {
  let currentAngle = 0;
  const total = data.reduce((sum, item) => sum + item.value, 0);
  const svgData = data.map(item => {
    const startAngle = currentAngle;
    const angle = (item.value / total) * 360;
    const endAngle = currentAngle + angle;
    currentAngle = endAngle;
    
    // Calculate SVG path
    const x1 = Math.cos((startAngle - 90) * Math.PI / 180) * 100 + 100;
    const y1 = Math.sin((startAngle - 90) * Math.PI / 180) * 100 + 100;
    const x2 = Math.cos((endAngle - 90) * Math.PI / 180) * 100 + 100;
    const y2 = Math.sin((endAngle - 90) * Math.PI / 180) * 100 + 100;
    
    const largeArcFlag = angle > 180 ? 1 : 0;
    
    return {
      ...item,
      path: `M 100 100 L ${x1} ${y1} A 100 100 0 ${largeArcFlag} 1 ${x2} ${y2} Z`,
      angle: startAngle + angle / 2
    };
  });

  return (
    <div className="flex flex-col items-center p-4">
      <h3 className="text-lg font-semibold mb-4">{title}</h3>
      <div className="relative" style={{ width: '200px', height: '200px' }}>
        <svg viewBox="0 0 200 200">
          {svgData.map((item, index) => (
            <path
              key={index}
              d={item.path}
              fill={item.color}
              stroke="white"
              strokeWidth="1"
            />
          ))}
        </svg>
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="text-sm font-semibold text-gray-700">
            {total.toFixed(1)}%
          </div>
        </div>
      </div>
      <div className="mt-4 space-y-2">
        {data.map((item, index) => (
          <div key={index} className="flex items-center">
            <div
              className="w-4 h-4 mr-2"
              style={{ backgroundColor: item.color }}
            />
            <span className="text-sm">
              {item.label}: {item.value.toFixed(1)}%
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

// Analysis Results Component
const AnalysisVisuals: React.FC<{
  results: Results;
  language: LanguageType;
}> = ({ results, language }) => {
  if (!results) return null;

  const getColor = (value: number): string => {
    if (value < 30) return '#4ade80';  // green
    if (value < 70) return '#fbbf24';  // yellow
    return '#ef4444';  // red
  };

  const mainPieData = [
    {
      label: language === 'es' ? 'IA' : 'AI',
      value: results.probability,
      color: getColor(results.probability)
    },
    {
      label: language === 'es' ? 'Humano' : 'Human',
      value: 100 - results.probability,
      color: '#e5e7eb'
    }
  ];

  const indicatorsPieData = [
    {
      label: language === 'es' ? 'Patrones Repetitivos' : 'Repetitive Patterns',
      value: results.indicators.repetitivePatterns * 100,
      color: '#60a5fa'
    },
    {
      label: language === 'es' ? 'Originalidad' : 'Originality',
      value: (1 - results.indicators.contentOriginality) * 100,
      color: '#34d399'
    },
    {
      label: language === 'es' ? 'Formalidad' : 'Formality',
      value: results.indicators.formalLanguage * 100,
      color: '#a78bfa'
    }
  ];

  return (
    <div className="space-y-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <PieChart
          data={mainPieData}
          title={language === 'es' ? 'Probabilidad de IA' : 'AI Probability'}
        />
        <PieChart
          data={indicatorsPieData}
          title={language === 'es' ? 'Análisis Detallado' : 'Detailed Analysis'}
        />
      </div>

      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold mb-4">
          {language === 'es' ? 'Interpretación del Análisis' : 'Analysis Interpretation'}
        </h3>
        <div className="space-y-4">
          <div className="p-4 rounded-lg bg-gray-50">
            <h4 className="font-semibold mb-2">
              {language === 'es' ? 'Probabilidad General' : 'General Probability'}
            </h4>
            <p className="text-sm text-gray-700">
              {language === 'es'
                ? `El texto muestra una probabilidad del ${results.probability.toFixed(1)}% de haber sido generado por IA. ${
                    results.probability > 70
                      ? 'Esto sugiere una alta probabilidad de contenido generado por IA.'
                      : results.probability > 30
                      ? 'Hay algunos indicadores de contenido generado por IA, pero no es concluyente.'
                      : 'El texto muestra características más típicas de escritura humana.'
                  }`
                : `The text shows a ${results.probability.toFixed(1)}% probability of being AI-generated. ${
                    results.probability > 70
                      ? 'This suggests a high likelihood of AI-generated content.'
                      : results.probability > 30
                      ? 'There are some indicators of AI-generated content, but it\'s not conclusive.'
                      : 'The text shows characteristics more typical of human writing.'
                  }`}
            </p>
          </div>

          {results.detailedAnalysis.map((analysis, index) => (
            <div key={index} className="p-4 rounded-lg bg-gray-50">
              <p className="text-sm text-gray-700">{analysis}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold mb-4">
          {language === 'es' ? 'Sugerencias de Mejora' : 'Improvement Suggestions'}
        </h3>
        <ul className="space-y-2">
          {results.suggestions.map((suggestion, index) => (
            <li key={index} className="flex items-start">
              <span className="mr-2">•</span>
              <span className="text-sm text-gray-700">{suggestion}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

// File Upload Component
const FileUploadSection: React.FC<{
  language: LanguageType;
  onFileContent: (content: string) => void;
}> = ({ language, onFileContent }) => {
  const [fileStatus, setFileStatus] = useState<{
    analyzing: boolean;
    error?: string;
    currentFile?: string;
    metadata?: any;
  }>({
    analyzing: false
  });
  const [isProcessingFile, setIsProcessingFile] = useState(false);
  const [isProcessingInput, setIsProcessingInput] = useState(false);
  const [fileAttachment, setFileAttachment] = useState<File | null>(null);
  const [fileContent, setFileContent] = useState<string | null>(null);
  const [imageAttachments, setImageAttachments] = useState<File[]>([]);

  useEffect(() => {
    if (!isProcessingInput && isProcessingFile) {
      setFileAttachment(null);
      setFileContent(null);
      setIsProcessingFile(false);
    }
  }, [isProcessingInput]);

  const processPDF = async (file: File): Promise<FileProcessingResult> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const loadingTask = pdfjsLib.getDocument({ data: arrayBuffer });
      const pdfDoc = await loadingTask.promise;
      let fullText = '';
      for (let i = 1; i <= pdfDoc.numPages; i++) {
        const page = await pdfDoc.getPage(i);
        const textContent = await page.getTextContent();
        const pageText = textContent.items
          .map((item: any) => item.str)
          .join(' ');
        fullText += `\n--- Page ${i} ---\n` + pageText;
      }
      return {
        content: fullText.trim(),
        metadata: {
          type: 'PDF Document',
          pageCount: pdfDoc.numPages,
          documentInfo: 'PDF Document'
        }
      };
    } catch (error) {
      console.error('Error processing PDF:', error);
      throw error;
    }
  };

  const processExcel = async (file: File): Promise<FileProcessingResult> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const workbook = XLSX.read(arrayBuffer) as WorkbookType;
      let fullText = '';
      
      workbook.SheetNames.forEach((sheetName: string) => {
        const worksheet = workbook.Sheets[sheetName];
        fullText += `\n--- Sheet: ${sheetName} ---\n`;
        fullText += XLSX.utils.sheet_to_csv(worksheet, { blankrows: false });
      });
      
      return {
        content: fullText.trim(),
        metadata: {
          type: 'Excel Document',
          sheets: workbook.SheetNames.length
        }
      };
    } catch (error) {
      console.error('Error processing Excel:', error);
      throw error;
    }
  };

  const processWord = async (file: File): Promise<FileProcessingResult> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const result = await mammoth.extractRawText({ arrayBuffer });
      return {
        content: result.value,
        metadata: {
          type: 'Word Document',
          messages: result.messages
        }
      };
    } catch (error) {
      console.error('Error processing Word:', error);
      throw error;
    }
  };

  const processTextFile = async (file: File): Promise<FileProcessingResult> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve({
          content: e.target?.result as string,
          metadata: {
            type: file.name.endsWith('.csv') ? 'CSV Document' : 'Text Document',
            size: file.size,
            lastModified: new Date(file.lastModified).toISOString()
          }
        });
      };
      reader.onerror = reject;
      reader.readAsText(file);
    });
  };

  const processFile = async (file: File): Promise<FileProcessingResult | null> => {
    const fileType = file.name.split('.').pop()?.toLowerCase();
    try {
      setIsProcessingFile(true);
      switch (fileType) {
        case 'pdf':
          return await processPDF(file);
        case 'xlsx':
        case 'xls':
          return await processExcel(file);
        case 'docx':
        case 'doc':
          return await processWord(file);
        case 'txt':
        case 'csv':
          return await processTextFile(file);
        default:
          throw new Error(`${language === 'es' ? 'Tipo de archivo no soportado' : 'Unsupported file type'}: ${fileType}`);
      }
    } catch (error) {
      console.error('Error processing file:', error);
      return null;
    } finally {
      setIsProcessingFile(false);
    }
  };

  const convertFileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const result = reader.result as string;
        resolve(result);
      };
      reader.onerror = error => reject(error);
    });
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setFileStatus({ analyzing: true, currentFile: file.name });
    setFileAttachment(file);

    try {
      const result = await processFile(file);
      if (result) {
        setFileContent(result.content);
        onFileContent(result.content);
        setFileStatus({
          analyzing: false,
          currentFile: file.name,
          metadata: result.metadata
        });
      } else {
        throw new Error(language === 'es' ? 'Error procesando archivo' : 'Error processing file');
      }
    } catch (error) {
      setFileStatus({
        analyzing: false,
        error: error instanceof Error ? error.message : 'Error desconocido',
        currentFile: file.name
      });
    }
  };

  return (
    <>
    <div className="space-y-4">
      <label
        htmlFor="file-upload"
        className="b-1 absolute cursor-pointer bg-transparent rounded-md font-medium text-blue-600 hover:text-blue-500"
      >
        <Form.Control
          id="file-upload"
          name="file-upload"
          className=""
          type="file"
          size="sm"
          accept=".pdf,.docx,.doc,.xlsx,.xls,.txt,.csv"
          onChange={handleFileUpload}
          disabled={fileStatus.analyzing}
        />
      </label>
    </div>

    {fileStatus.analyzing && (
      <div className="text-sm text-gray-500">
          <Spinner animation="border" variant="info" /> {language === 'es' ? 'Analizando archivo...' : 'Analyzing file...'} 
      </div>
    )}

    {fileStatus.error && (
      <div className="text-sm text-red-500 m-2">
        <Alert key="danger" variant="danger">
            {fileStatus.error}
        </Alert>
      </div>
    )}
          
    {fileStatus.currentFile && !fileStatus.analyzing && !fileStatus.error && (
      <div className="text-sm">
        <p className="text-green-500 m-1">
          {language === 'es' ? 'Archivo para análisis: ' : 'File to analysis: '} 
          <div className="fs-9 m-2 italic">{fileStatus.currentFile}</div>
        </p>
        {fileStatus.metadata && (
          <p className="text-gray-500 mt-1"><span>[x] </span> 
            {fileStatus.metadata.type}
            {fileStatus.metadata.pageCount && ` - ${fileStatus.metadata.pageCount} pages`}
            {fileStatus.metadata.sheets && ` - ${fileStatus.metadata.sheets} sheets`}
          </p>
        )}
      </div>
    )}
    </>
  );
};

// Main Component
const IADetect: React.FC = () => {
  const [text, setText] = useState('');
  const [language, setLanguage] = useState<LanguageType>('es');
  const [textType, setTextType] = useState<TextType>('academic');
  const [results, setResults] = useState<Results | null>(null);
  const [compareText, setCompareText] = useState('');
  const [isComparing, setIsComparing] = useState(false);
  const [fileContent, setFileContent] = useState<string | null>(null);

  // Analysis Helper Functions
  const analyzeTextComplexity = (text: string): number => {
    const sentences = text.match(/[^.!?]+[.!?]+/g) || [];
    const words = text.split(/\s+/);
    
    const avgWordLength = _.meanBy(words, 'length');
    const avgSentenceLength = words.length / sentences.length;
    const uniqueWords = new Set(words.map(w => w.toLowerCase())).size;
    const lexicalDiversity = uniqueWords / words.length;
    
    return (avgWordLength * 0.2 + avgSentenceLength * 0.3 + lexicalDiversity * 0.5);
  };

  const generateNGrams = (text: string, n: number): string[] => {
    const words = text.toLowerCase().split(/\s+/);
    const nGrams: string[] = [];
    for (let i = 0; i <= words.length - n; i++) {
      nGrams.push(words.slice(i, i + n).join(' '));
    }
    return nGrams;
  };

  const calculateSimilarity = (text1: string, text2: string): number => {
    const ngrams1 = new Set(generateNGrams(text1, 3));
    const ngrams2 = new Set(generateNGrams(text2, 3));
    
    const intersection = new Set([...ngrams1].filter(x => ngrams2.has(x)));
    const union = new Set([...ngrams1, ...ngrams2]);
    
    return intersection.size / union.size;
  };

  const findCitations = (text: string): Array<{citation: string, confidence: number}> => {
    const citationPatterns = [
      /\(([^)]+),\s*\d{4}\)/g,
      /([A-Z][a-z]+(?:\s+[A-Z][a-z]+)*)\s+\(\d{4}\)/g,
      /"([^"]+)"\s*\(([^)]+)\)/g
    ];

    const citations = [];
    for (const pattern of citationPatterns) {
      const matches = Array.from(text.matchAll(pattern));
      for (const match of matches) {
        citations.push({
          citation: match[0],
          confidence: validateCitation(match[0])
        });
      }
    }
    return citations;
  };

  const validateCitation = (citation: string): number => {
    const hasYear = /\d{4}/.test(citation);
    const hasAuthor = /[A-Z][a-z]+/.test(citation);
    const hasProperFormatting = /\(.*\)/.test(citation);
    
    let score = 0;
    if (hasYear) score += 0.4;
    if (hasAuthor) score += 0.4;
    if (hasProperFormatting) score += 0.2;
    
    return score;
  };

  const calculatePatternFrequency = (text: string, patterns: string[]): number => {
    const normalizedText = text.toLowerCase();
    const matches = patterns.reduce((count, pattern) => {
      const regex = new RegExp(`\\b${pattern}\\b`, 'gi');
      return count + (normalizedText.match(regex) || []).length;
    }, 0);
    
    return Math.min(matches / Math.max(1, text.split(/\s+/).length / 20), 1);
  };

  const analyzeText = () => {
    const textToAnalyze = text || fileContent || '';
    if (!textToAnalyze.trim()) return;

    const normalizedText = textToAnalyze.toLowerCase();
    const sentences = textToAnalyze.match(/[^.!?]+[.!?]+/g) || [];
    const words = textToAnalyze.split(/\s+/);
    
    const textComplexity = analyzeTextComplexity(textToAnalyze);
    const citations = findCitations(textToAnalyze);
    const nGrams = generateNGrams(textToAnalyze, 3);

    const currentPatterns = patterns[language];
    const indicators: Indicators = {
      repetitivePatterns: sentences.length > 1 ? 1 - (new Set(sentences).size / sentences.length) : 0,
      formulaicTransitions: calculatePatternFrequency(textToAnalyze, currentPatterns.transitions),
      formalLanguage: calculatePatternFrequency(textToAnalyze, currentPatterns.formalWords),
      consistentTone: textComplexity,
      standardPhrases: calculatePatternFrequency(textToAnalyze, [
        ...currentPatterns.commonPhrases,
        ...(textType === 'academic' ? currentPatterns.academicPhrases : []),
        ...(textType === 'business' ? currentPatterns.businessPhrases : [])
      ]),
      structuralPatterns: 0,
      styleConsistency: 0,
      citations: citations.length / Math.max(1, sentences.length / 5),
      documentSimilarity: isComparing ? calculateSimilarity(textToAnalyze, compareText) : 0,
      citationValidity: citations.length > 0 ? _.meanBy(citations, 'confidence') : 0,
      imageAnalysis: 0,
      contentOriginality: 1 - (new Set(nGrams).size / nGrams.length)
    };

    const probability = calculateFinalProbability(indicators);
    
    setResults({
      probability: probability * 100,
      indicators,
      confidence: getConfidenceLevel(probability),
      detailedAnalysis: generateAnalysis(indicators, language),
      suggestions: generateSuggestions(indicators, language)
    });
  };

  const calculateFinalProbability = (indicators: Indicators): number => {
    const weights = {
      repetitivePatterns: 0.15,
      formulaicTransitions: 0.1,
      formalLanguage: 0.1,
      consistentTone: 0.1,
      standardPhrases: 0.1,
      structuralPatterns: 0.1,
      styleConsistency: 0.05,
      citations: 0.05,
      documentSimilarity: 0.15,
      citationValidity: 0.05,
      imageAnalysis: 0.025,
      contentOriginality: 0.075
    };

    return Object.keys(indicators).reduce((sum, key) => {
      return sum + (indicators[key as keyof Indicators] * weights[key as keyof Indicators]);
    }, 0);
  };

  const getConfidenceLevel = (probability: number): string => {
    if (probability < 0.4) return 'Low';
    if (probability < 0.7) return 'Medium';
    return 'High';
  };

  const generateAnalysis = (indicators: Indicators, lang: LanguageType): string[] => {
    const analysis: string[] = [];
    const threshold = 0.4;

    if (lang === 'es') {
      if (indicators.repetitivePatterns > threshold) {
        analysis.push('Se detectaron patrones repetitivos significativos en la estructura del texto.');
      }
      if (indicators.contentOriginality < threshold) {
        analysis.push('El texto muestra un alto nivel de originalidad en su contenido.');
      }
      if (indicators.formalLanguage > threshold) {
        analysis.push('Se detectó un uso elevado de lenguaje formal y técnico.');
      }
      if (indicators.citationValidity < threshold && indicators.citations > 0) {
        analysis.push('Las citas presentes requieren revisión o están incompletas.');
      }
    } else {
      if (indicators.repetitivePatterns > threshold) {
        analysis.push('Significant repetitive patterns detected in text structure.');
      }
      if (indicators.contentOriginality < threshold) {
        analysis.push('The text shows a high level of content originality.');
      }
      if (indicators.formalLanguage > threshold) {
        analysis.push('High usage of formal and technical language detected.');
      }
      if (indicators.citationValidity < threshold && indicators.citations > 0) {
        analysis.push('Present citations need review or are incomplete.');
      }
    }

    return analysis;
  };

  const generateSuggestions = (indicators: Indicators, lang: LanguageType): string[] => {
    const suggestions: string[] = [];
    const threshold = 0.4;

    if (lang === 'es') {
      if (indicators.repetitivePatterns > threshold) {
        suggestions.push('Considere variar más la estructura de las oraciones para mejorar la naturalidad del texto.');
      }
      if (indicators.formulaicTransitions > threshold) {
        suggestions.push('Intente utilizar conectores más variados y naturales en el texto.');
      }
      if (indicators.formalLanguage > threshold) {
        suggestions.push('Balance el uso de lenguaje técnico con expresiones más naturales.');
      }
      if (indicators.citationValidity < threshold && indicators.citations > 0) {
        suggestions.push('Revise y complete las citas según el formato académico estándar.');
      }
    } else {
      if (indicators.repetitivePatterns > threshold) {
        suggestions.push('Consider varying sentence structure more to improve text naturalness.');
      }
      if (indicators.formulaicTransitions > threshold) {
        suggestions.push('Try using more varied and natural transitions in the text.');
      }
      if (indicators.formalLanguage > threshold) {
        suggestions.push('Balance technical language with more natural expressions.');
      }
      if (indicators.citationValidity < threshold && indicators.citations > 0) {
        suggestions.push('Review and complete citations according to standard academic format.');
      }
    }

    return suggestions;
  };
  
  return (
    <>
      <div>
        <WidgetsSectionTitle
          title="A.I Documents & Copyright analyzer"
          subtitle="Get information if documents or texts have been extracted from other artificial intelligences."
          icon={faFileAlt}
          className="mb-5 mt-7"
        />
        <Row className="g-5 mb-5">
          <Col xl={8}>
            <TinymceEditor
              options={{
                height: '15rem',
                placeholder: language === 'es' ? 'Escribe o pega el texto original aquí...' : 'Write or paste original text here...',
                menubar: false,
                plugins: 'paste',
                toolbar: true,
                //content_style: 'body { font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif; font-size: 14px; }',
                disabled: false
              }}
              value={text}
              onChange={(contentA) => setText(contentA)}
          />
          
          {isComparing && (
            <TinymceEditor
              options={{
                height: '15rem',
                placeholder: language === 'es' ? 'Escribe o pega el texto a comparar...' : 'Write or paste text to compare...',
                menubar: false,
                plugins: 'paste',
                toolbar: true,
                //content_style: 'body { font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif; font-size: 14px; }',
                disabled: false
              }}
              value={compareText}
              onChange={(contentB) => setCompareText(contentB)}
            />
          )}

          {/* RESULTADOS *-/}
          {fileContent && (
            <div className='mt-3'>
              <Card className="">
                <Card.Body>
                  <h4 className="text-sm font-medium text-gray-700">
                    {language === 'es' ? 'Contenido del archivo analizado:' : 'File Content analyzed:'}
                  </h4>
                    <pre className="text-sm whitespace-pre-wrap">{fileContent}</pre>
                </Card.Body>
              </Card>
            </div>
          )}

        </Col>
        <Col xl={4} sm={12}>
          <Card className="">
            <Card.Body>
              <h4 className="mb-4">{language === 'es' ? 'Opciones' : 'Options'}</h4>
              <Row className="gx-3 gy-4">
                <Col xs={12} sm={12} xl={12}>
                  <div className="d-flex gap-2 mb-2">
                    <h5 className="mb-0 text-body-highlight">{language === 'es' ? 'Lenguaje' : 'Language'}</h5>
                    {/*<Link className="fw-bold fs-9" to="#!">
                      Add new language
                    </Link>*-/}
                  </div>
                  <Form.Select aria-label="language"
                    value={language}
                    onChange={(e) => setLanguage(e.target.value as LanguageType)}
                  >
                    <option value="en">{language === 'es' ? 'Inglés' : 'English'}</option>
                    <option value="es">{language === 'es' ? 'Español' : 'Spanish'}</option>
                  </Form.Select>
                </Col>
                <Col xs={12} sm={6} xl={12}>
                  <div className="d-flex gap-2 mb-2">
                    <h5 className="mb-0 text-body-highlight">{language === 'es' ? 'Contexto' : 'Context'}</h5>
                      {/*<Link className="fw-bold fs-9" to="#!">
                        Add new context
                      </Link>*-/}
                  </div>
                  <Form.Select aria-label="context"
                    value={textType}
                    onChange={(e) => setTextType(e.target.value as TextType)}
                  >
                    <option value="general">{language === 'es' ? 'General' : 'General'}</option>
                    <option value="academic">{language === 'es' ? 'Académico' : 'Academic'}</option>
                    <option value="business">{language === 'es' ? 'Empresarial' : 'Enterprise'}</option>
                  </Form.Select>
                </Col>
                <Col xs={12} sm={6} xl={12}>
                  <h5 className="mb-2 text-body-highlight">{language === 'es' ? 'Análisis de Archivos' : 'File Analysis'} 
                    <OverlayTrigger
                      overlay={
                        <Tooltip className='text-body-highlight text-left'> 
                          {language === 'es' ? <div className='text-start'>Formatos soportados:<br /> PDF, DOCX, CSV, XLSX, XLS, TXT</div> : <div className='text-start'>Supported formats: <br />PDF, DOCX, CSV, XLSX, XLS, TXT</div>}
                        </Tooltip>
                      }
                    >
                      <FontAwesomeIcon className="px-2" icon={faCircleInfo} />
                    </OverlayTrigger>
                  </h5>
                    <div>
                      <Form.Group className="mb-3">
                        <FileUploadSection 
                          language={language}
                          onFileContent={setFileContent}
                        />
                      </Form.Group>
                    </div>
                </Col>
                <Col xs={12} sm={6} xl={12}>
                  <h5 className="mb-2 text-body-highlight">{language === 'es' ? 'Comparación de Texto' : 'Texts Comparision'}</h5>
                  <Form.Check 
                    type='switch'
                    id='defaultSwitch'
                    label={language === 'es' ? 'Activar comparación de texto' : 'Enable texts comparision'}
                    onClick={() => setIsComparing(!isComparing)}
                  />
                </Col>
                <Col xs={12} sm={6} xl={12}>
                  <div className="d-flex gap-2 mb-2">
                    <Button type="submit" className="mb-2"
                      onClick={analyzeText}>
                      {language === 'es' ? 'Analizar Texto' : 'Analyze Text'}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          {/*<OrganizeFormCard className="mb-3" />
          <VariantFormCard />*-/}
        </Col>
      </Row>

      <Row className='g-5 mb-5'>
        {/* GRAFICOS RESULTADOS *-/}
        {results && (
          <Col xl={12}>
            <Card className=''>
              <Card.Body>
              <h4 className="mb-4">{language === 'es' ? 'Resultados y estadísticas' : 'Results and statistics'}</h4>
            <div className="space-y-6">
              <AnalysisVisuals results={results} language={language} />
            </div>
              </Card.Body>
            </Card>
          </Col>
          )}
      </Row>
    </div>
    </>
  );
};

export default IADetect;
/* v0.3 
import React, { useState, useEffect } from 'react';
import _ from 'lodash';

declare const XLSX: any;
declare const pdfjsLib: any;
declare const mammoth: any;

// Types and Interfaces
type LanguageType = 'es' | 'en';
type TextType = 'academic' | 'business' | 'general';

interface FileProcessingResult {
  content: string;
  metadata: {
    type: string;
    pageCount?: number;
    sheets?: number;
    size?: number;
    documentInfo?: string;
    messages?: any[];
    lastModified?: string;
  };
}

interface WorkbookType {
  SheetNames: string[];
  Sheets: {
    [key: string]: any;
  };
}

interface Patterns {
  transitions: string[];
  formalWords: string[];
  commonPhrases: string[];
  academicPhrases: string[];
  businessPhrases: string[];
}

interface Indicators {
  repetitivePatterns: number;
  formulaicTransitions: number;
  formalLanguage: number;
  consistentTone: number;
  standardPhrases: number;
  structuralPatterns: number;
  styleConsistency: number;
  citations: number;
  documentSimilarity: number;
  citationValidity: number;
  imageAnalysis: number;
  contentOriginality: number;
}

interface Results {
  probability: number;
  indicators: Indicators;
  confidence: string;
  detailedAnalysis: string[];
  suggestions: string[];
}

// Language Patterns
const patterns: Record<LanguageType, Patterns> = {
  es: {
    transitions: [
      'además', 'sin embargo', 'por lo tanto', 'en consecuencia', 'por otra parte',
      'en primer lugar', 'en segundo lugar', 'finalmente', 'de esta manera'
    ],
    formalWords: [
      'implementación', 'metodología', 'paradigma', 'infraestructura', 'optimización',
      'mediante', 'asimismo', 'concerniente', 'respectivamente', 'previamente'
    ],
    commonPhrases: [
      'es importante señalar', 'cabe mencionar que', 'es necesario destacar',
      'se recomienda', 'se propone', 'se sugiere'
    ],
    academicPhrases: [
      'según el autor', 'los resultados indican', 'se puede observar',
      'el análisis muestra', 'la investigación revela'
    ],
    businessPhrases: [
      'optimizar procesos', 'maximizar beneficios', 'implementar soluciones',
      'generar valor', 'mejorar la eficiencia'
    ]
  },
  en: {
    transitions: [
      'furthermore', 'however', 'therefore', 'consequently', 'moreover',
      'firstly', 'secondly', 'finally', 'in addition'
    ],
    formalWords: [
      'implementation', 'methodology', 'paradigm', 'infrastructure', 'optimization',
      'utilize', 'facilitate', 'leverage', 'streamline'
    ],
    commonPhrases: [
      'it is important to note', 'it should be mentioned', 'it is worth noting',
      'it is recommended', 'it is suggested'
    ],
    academicPhrases: [
      'according to the author', 'the results indicate', 'it can be observed',
      'the analysis shows', 'research reveals'
    ],
    businessPhrases: [
      'optimize processes', 'maximize benefits', 'implement solutions',
      'generate value', 'improve efficiency'
    ]
  }
};

// Pie Chart Component
const PieChart: React.FC<{
  data: { label: string; value: number; color: string }[];
  title: string;
}> = ({ data, title }) => {
  let currentAngle = 0;
  const total = data.reduce((sum, item) => sum + item.value, 0);
  const svgData = data.map(item => {
    const startAngle = currentAngle;
    const angle = (item.value / total) * 360;
    const endAngle = currentAngle + angle;
    currentAngle = endAngle;
    
    // Calculate SVG path
    const x1 = Math.cos((startAngle - 90) * Math.PI / 180) * 100 + 100;
    const y1 = Math.sin((startAngle - 90) * Math.PI / 180) * 100 + 100;
    const x2 = Math.cos((endAngle - 90) * Math.PI / 180) * 100 + 100;
    const y2 = Math.sin((endAngle - 90) * Math.PI / 180) * 100 + 100;
    
    const largeArcFlag = angle > 180 ? 1 : 0;
    
    return {
      ...item,
      path: `M 100 100 L ${x1} ${y1} A 100 100 0 ${largeArcFlag} 1 ${x2} ${y2} Z`,
      angle: startAngle + angle / 2
    };
  });

  return (
    <div className="flex flex-col items-center p-4">
      <h3 className="text-lg font-semibold mb-4">{title}</h3>
      <div className="relative" style={{ width: '200px', height: '200px' }}>
        <svg viewBox="0 0 200 200">
          {svgData.map((item, index) => (
            <path
              key={index}
              d={item.path}
              fill={item.color}
              stroke="white"
              strokeWidth="1"
            />
          ))}
        </svg>
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="text-sm font-semibold text-gray-700">
            {total.toFixed(1)}%
          </div>
        </div>
      </div>
      <div className="mt-4 space-y-2">
        {data.map((item, index) => (
          <div key={index} className="flex items-center">
            <div
              className="w-4 h-4 mr-2"
              style={{ backgroundColor: item.color }}
            />
            <span className="text-sm">
              {item.label}: {item.value.toFixed(1)}%
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

// Analysis Results Component
const AnalysisVisuals: React.FC<{
  results: Results;
  language: LanguageType;
}> = ({ results, language }) => {
  if (!results) return null;

  const getColor = (value: number): string => {
    if (value < 30) return '#4ade80';  // green
    if (value < 70) return '#fbbf24';  // yellow
    return '#ef4444';  // red
  };

  const mainPieData = [
    {
      label: language === 'es' ? 'IA' : 'AI',
      value: results.probability,
      color: getColor(results.probability)
    },
    {
      label: language === 'es' ? 'Humano' : 'Human',
      value: 100 - results.probability,
      color: '#e5e7eb'
    }
  ];

  const indicatorsPieData = [
    {
      label: language === 'es' ? 'Patrones Repetitivos' : 'Repetitive Patterns',
      value: results.indicators.repetitivePatterns * 100,
      color: '#60a5fa'
    },
    {
      label: language === 'es' ? 'Originalidad' : 'Originality',
      value: (1 - results.indicators.contentOriginality) * 100,
      color: '#34d399'
    },
    {
      label: language === 'es' ? 'Formalidad' : 'Formality',
      value: results.indicators.formalLanguage * 100,
      color: '#a78bfa'
    }
  ];

  return (
    <div className="space-y-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <PieChart
          data={mainPieData}
          title={language === 'es' ? 'Probabilidad de IA' : 'AI Probability'}
        />
        <PieChart
          data={indicatorsPieData}
          title={language === 'es' ? 'Análisis Detallado' : 'Detailed Analysis'}
        />
      </div>

      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold mb-4">
          {language === 'es' ? 'Interpretación del Análisis' : 'Analysis Interpretation'}
        </h3>
        <div className="space-y-4">
          <div className="p-4 rounded-lg bg-gray-50">
            <h4 className="font-semibold mb-2">
              {language === 'es' ? 'Probabilidad General' : 'General Probability'}
            </h4>
            <p className="text-sm text-gray-700">
              {language === 'es'
                ? `El texto muestra una probabilidad del ${results.probability.toFixed(1)}% de haber sido generado por IA. ${
                    results.probability > 70
                      ? 'Esto sugiere una alta probabilidad de contenido generado por IA.'
                      : results.probability > 30
                      ? 'Hay algunos indicadores de contenido generado por IA, pero no es concluyente.'
                      : 'El texto muestra características más típicas de escritura humana.'
                  }`
                : `The text shows a ${results.probability.toFixed(1)}% probability of being AI-generated. ${
                    results.probability > 70
                      ? 'This suggests a high likelihood of AI-generated content.'
                      : results.probability > 30
                      ? 'There are some indicators of AI-generated content, but it\'s not conclusive.'
                      : 'The text shows characteristics more typical of human writing.'
                  }`}
            </p>
          </div>

          {results.detailedAnalysis.map((analysis, index) => (
            <div key={index} className="p-4 rounded-lg bg-gray-50">
              <p className="text-sm text-gray-700">{analysis}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold mb-4">
          {language === 'es' ? 'Sugerencias de Mejora' : 'Improvement Suggestions'}
        </h3>
        <ul className="space-y-2">
          {results.suggestions.map((suggestion, index) => (
            <li key={index} className="flex items-start">
              <span className="mr-2">•</span>
              <span className="text-sm text-gray-700">{suggestion}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

// File Upload Component
const FileUploadSection: React.FC<{
  language: LanguageType;
  onFileContent: (content: string) => void;
}> = ({ language, onFileContent }) => {
  const [fileStatus, setFileStatus] = useState<{
    analyzing: boolean;
    error?: string;
    currentFile?: string;
    metadata?: any;
  }>({
    analyzing: false
  });
  const [isProcessingFile, setIsProcessingFile] = useState(false);
  const [isProcessingInput, setIsProcessingInput] = useState(false);
  const [fileAttachment, setFileAttachment] = useState<File | null>(null);
  const [fileContent, setFileContent] = useState<string | null>(null);

  useEffect(() => {
    if (!isProcessingInput && isProcessingFile) {
      setFileAttachment(null);
      setFileContent(null);
      setIsProcessingFile(false);
    }
  }, [isProcessingInput]);

  const processPDF = async (file: File): Promise<FileProcessingResult> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const loadingTask = pdfjsLib.getDocument({ data: arrayBuffer });
      const pdfDoc = await loadingTask.promise;
      let fullText = '';
      for (let i = 1; i <= pdfDoc.numPages; i++) {
        const page = await pdfDoc.getPage(i);
        const textContent = await page.getTextContent();
        const pageText = textContent.items
          .map((item: any) => item.str)
          .join(' ');
        fullText += `\n--- Page ${i} ---\n` + pageText;
      }
      return {
        content: fullText.trim(),
        metadata: {
          type: 'PDF Document',
          pageCount: pdfDoc.numPages,
          documentInfo: 'PDF Document'
        }
      };
    } catch (error) {
      console.error('Error processing PDF:', error);
      throw error;
    }
  };

  const processExcel = async (file: File): Promise<FileProcessingResult> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const workbook = XLSX.read(arrayBuffer) as WorkbookType;
      let fullText = '';
      
      workbook.SheetNames.forEach((sheetName: string) => {
        const worksheet = workbook.Sheets[sheetName];
        fullText += `\n--- Sheet: ${sheetName} ---\n`;
        fullText += XLSX.utils.sheet_to_csv(worksheet, { blankrows: false });
      });
      
      return {
        content: fullText.trim(),
        metadata: {
          type: 'Excel Document',
          sheets: workbook.SheetNames.length
        }
      };
    } catch (error) {
      console.error('Error processing Excel:', error);
      throw error;
    }
  };

  const processWord = async (file: File): Promise<FileProcessingResult> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const result = await mammoth.extractRawText({ arrayBuffer });
      return {
        content: result.value,
        metadata: {
          type: 'Word Document',
          messages: result.messages
        }
      };
    } catch (error) {
      console.error('Error processing Word:', error);
      throw error;
    }
  };

  const processTextFile = async (file: File): Promise<FileProcessingResult> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve({
          content: e.target?.result as string,
          metadata: {
            type: file.name.endsWith('.csv') ? 'CSV Document' : 'Text Document',
            size: file.size,
            lastModified: new Date(file.lastModified).toISOString()
          }
        });
      };
      reader.onerror = reject;
      reader.readAsText(file);
    });
  };

  const processFile = async (file: File): Promise<FileProcessingResult | null> => {
    const fileType = file.name.split('.').pop()?.toLowerCase();
    try {
      setIsProcessingFile(true);
      switch (fileType) {
        case 'pdf':
          return await processPDF(file);
        case 'xlsx':
        case 'xls':
          return await processExcel(file);
        case 'docx':
        case 'doc':
          return await processWord(file);
        case 'txt':
        case 'csv':
        case 'json':
          return await processTextFile(file);
        default:
          throw new Error(`${language === 'es' ? 'Tipo de archivo no soportado' : 'Unsupported file type'}: ${fileType}`);
      }
    } catch (error) {
      console.error('Error processing file:', error);
      return null;
    } finally {
      setIsProcessingFile(false);
    }
  };

  const convertFileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const result = reader.result as string;
        resolve(result);
      };
      reader.onerror = error => reject(error);
    });
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setFileStatus({ analyzing: true, currentFile: file.name });
    setFileAttachment(file);

    try {
      const result = await processFile(file);
      if (result) {
        setFileContent(result.content);
        onFileContent(result.content);
        setFileStatus({
          analyzing: false,
          currentFile: file.name,
          metadata: result.metadata
        });
      } else {
        throw new Error(language === 'es' ? 'Error procesando archivo' : 'Error processing file');
      }
    } catch (error) {
      setFileStatus({
        analyzing: false,
        error: error instanceof Error ? error.message : 'Error desconocido',
        currentFile: file.name
      });
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <label className="block text-sm font-medium text-gray-700">
          {language === 'es' ? 'Subir Archivo' : 'Upload File'}
        </label>
        <span className="text-xs text-gray-500">
          {language === 'es' ? 
            'Formatos soportados: PDF, DOCX, CSV, XLSX, XLS, TXT' : 
            'Supported formats: PDF, DOCX, CSV, XLSX, XLS, TXT'}
        </span>
      </div>

      <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
        <div className="space-y-1 text-center">
          <div className="flex text-sm text-gray-600">
            <label
              htmlFor="file-upload"
              className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500"
            >
              <span>{language === 'es' ? 'Seleccionar archivo' : 'Select file'}</span>
              <input
                id="file-upload"
                name="file-upload"
                type="file"
                className="sr-only"
                accept=".pdf,.docx,.doc,.csv,.xlsx,.xls,.txt"
                onChange={handleFileUpload}
                disabled={fileStatus.analyzing}
              />
            </label>
          </div>
          
          {fileStatus.analyzing && (
            <div className="text-sm text-gray-500">
              {language === 'es' ? 'Analizando archivo...' : 'Analyzing file...'}
            </div>
          )}
          
          {fileStatus.error && (
            <div className="text-sm text-red-500">
              {fileStatus.error}
            </div>
          )}
          
          {fileStatus.currentFile && !fileStatus.analyzing && !fileStatus.error && (
            <div className="text-sm">
              <p className="text-green-500">
                {language === 'es' ? 'Archivo analizado: ' : 'File analyzed: '} 
                {fileStatus.currentFile}
              </p>
              {fileStatus.metadata && (
                <p className="text-gray-500 mt-1">
                  {fileStatus.metadata.type}
                  {fileStatus.metadata.pageCount && ` - ${fileStatus.metadata.pageCount} pages`}
                  {fileStatus.metadata.sheets && ` - ${fileStatus.metadata.sheets} sheets`}
                </p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

// Main Component
const IADetect: React.FC = () => {
  const [text, setText] = useState('');
  const [language, setLanguage] = useState<LanguageType>('es');
  const [textType, setTextType] = useState<TextType>('academic');
  const [results, setResults] = useState<Results | null>(null);
  const [compareText, setCompareText] = useState('');
  const [isComparing, setIsComparing] = useState(false);
  const [fileContent, setFileContent] = useState<string | null>(null);

  // Analysis Helper Functions
  const analyzeTextComplexity = (text: string): number => {
    const sentences = text.match(/[^.!?]+[.!?]+/g) || [];
    const words = text.split(/\s+/);
    
    const avgWordLength = _.meanBy(words, 'length');
    const avgSentenceLength = words.length / sentences.length;
    const uniqueWords = new Set(words.map(w => w.toLowerCase())).size;
    const lexicalDiversity = uniqueWords / words.length;
    
    return (avgWordLength * 0.2 + avgSentenceLength * 0.3 + lexicalDiversity * 0.5);
  };

  const generateNGrams = (text: string, n: number): string[] => {
    const words = text.toLowerCase().split(/\s+/);
    const nGrams: string[] = [];
    for (let i = 0; i <= words.length - n; i++) {
      nGrams.push(words.slice(i, i + n).join(' '));
    }
    return nGrams;
  };

  const calculateSimilarity = (text1: string, text2: string): number => {
    const ngrams1 = new Set(generateNGrams(text1, 3));
    const ngrams2 = new Set(generateNGrams(text2, 3));
    
    const intersection = new Set([...ngrams1].filter(x => ngrams2.has(x)));
    const union = new Set([...ngrams1, ...ngrams2]);
    
    return intersection.size / union.size;
  };

  const findCitations = (text: string): Array<{citation: string, confidence: number}> => {
    const citationPatterns = [
      /\(([^)]+),\s*\d{4}\)/g,
      /([A-Z][a-z]+(?:\s+[A-Z][a-z]+)*)\s+\(\d{4}\)/g,
      /"([^"]+)"\s*\(([^)]+)\)/g
    ];

    const citations = [];
    for (const pattern of citationPatterns) {
      const matches = Array.from(text.matchAll(pattern));
      for (const match of matches) {
        citations.push({
          citation: match[0],
          confidence: validateCitation(match[0])
        });
      }
    }
    return citations;
  };

  const validateCitation = (citation: string): number => {
    const hasYear = /\d{4}/.test(citation);
    const hasAuthor = /[A-Z][a-z]+/.test(citation);
    const hasProperFormatting = /\(.*\)/.test(citation);
    
    let score = 0;
    if (hasYear) score += 0.4;
    if (hasAuthor) score += 0.4;
    if (hasProperFormatting) score += 0.2;
    
    return score;
  };

  const calculatePatternFrequency = (text: string, patterns: string[]): number => {
    const normalizedText = text.toLowerCase();
    const matches = patterns.reduce((count, pattern) => {
      const regex = new RegExp(`\\b${pattern}\\b`, 'gi');
      return count + (normalizedText.match(regex) || []).length;
    }, 0);
    
    return Math.min(matches / Math.max(1, text.split(/\s+/).length / 20), 1);
  };

  const analyzeText = () => {
    const textToAnalyze = text || fileContent || '';
    if (!textToAnalyze.trim()) return;

    const normalizedText = textToAnalyze.toLowerCase();
    const sentences = textToAnalyze.match(/[^.!?]+[.!?]+/g) || [];
    const words = textToAnalyze.split(/\s+/);
    
    const textComplexity = analyzeTextComplexity(textToAnalyze);
    const citations = findCitations(textToAnalyze);
    const nGrams = generateNGrams(textToAnalyze, 3);

    const currentPatterns = patterns[language];
    const indicators: Indicators = {
      repetitivePatterns: sentences.length > 1 ? 1 - (new Set(sentences).size / sentences.length) : 0,
      formulaicTransitions: calculatePatternFrequency(textToAnalyze, currentPatterns.transitions),
      formalLanguage: calculatePatternFrequency(textToAnalyze, currentPatterns.formalWords),
      consistentTone: textComplexity,
      standardPhrases: calculatePatternFrequency(textToAnalyze, [
        ...currentPatterns.commonPhrases,
        ...(textType === 'academic' ? currentPatterns.academicPhrases : []),
        ...(textType === 'business' ? currentPatterns.businessPhrases : [])
      ]),
      structuralPatterns: 0,
      styleConsistency: 0,
      citations: citations.length / Math.max(1, sentences.length / 5),
      documentSimilarity: isComparing ? calculateSimilarity(textToAnalyze, compareText) : 0,
      citationValidity: citations.length > 0 ? _.meanBy(citations, 'confidence') : 0,
      imageAnalysis: 0,
      contentOriginality: 1 - (new Set(nGrams).size / nGrams.length)
    };

    const probability = calculateFinalProbability(indicators);
    
    setResults({
      probability: probability * 100,
      indicators,
      confidence: getConfidenceLevel(probability),
      detailedAnalysis: generateAnalysis(indicators, language),
      suggestions: generateSuggestions(indicators, language)
    });
  };

  const calculateFinalProbability = (indicators: Indicators): number => {
    const weights = {
      repetitivePatterns: 0.15,
      formulaicTransitions: 0.1,
      formalLanguage: 0.1,
      consistentTone: 0.1,
      standardPhrases: 0.1,
      structuralPatterns: 0.1,
      styleConsistency: 0.05,
      citations: 0.05,
      documentSimilarity: 0.15,
      citationValidity: 0.05,
      imageAnalysis: 0.025,
      contentOriginality: 0.075
    };

    return Object.keys(indicators).reduce((sum, key) => {
      return sum + (indicators[key as keyof Indicators] * weights[key as keyof Indicators]);
    }, 0);
  };

  const getConfidenceLevel = (probability: number): string => {
    if (probability < 0.4) return 'Low';
    if (probability < 0.7) return 'Medium';
    return 'High';
  };

  const generateAnalysis = (indicators: Indicators, lang: LanguageType): string[] => {
    const analysis: string[] = [];
    const threshold = 0.4;

    if (lang === 'es') {
      if (indicators.repetitivePatterns > threshold) {
        analysis.push('Se detectaron patrones repetitivos significativos en la estructura del texto.');
      }
      if (indicators.contentOriginality < threshold) {
        analysis.push('El texto muestra un alto nivel de originalidad en su contenido.');
      }
      if (indicators.formalLanguage > threshold) {
        analysis.push('Se detectó un uso elevado de lenguaje formal y técnico.');
      }
      if (indicators.citationValidity < threshold && indicators.citations > 0) {
        analysis.push('Las citas presentes requieren revisión o están incompletas.');
      }
    } else {
      if (indicators.repetitivePatterns > threshold) {
        analysis.push('Significant repetitive patterns detected in text structure.');
      }
      if (indicators.contentOriginality < threshold) {
        analysis.push('The text shows a high level of content originality.');
      }
      if (indicators.formalLanguage > threshold) {
        analysis.push('High usage of formal and technical language detected.');
      }
      if (indicators.citationValidity < threshold && indicators.citations > 0) {
        analysis.push('Present citations need review or are incomplete.');
      }
    }

    return analysis;
  };

  const generateSuggestions = (indicators: Indicators, lang: LanguageType): string[] => {
    const suggestions: string[] = [];
    const threshold = 0.4;

    if (lang === 'es') {
      if (indicators.repetitivePatterns > threshold) {
        suggestions.push('Considere variar más la estructura de las oraciones para mejorar la naturalidad del texto.');
      }
      if (indicators.formulaicTransitions > threshold) {
        suggestions.push('Intente utilizar conectores más variados y naturales en el texto.');
      }
      if (indicators.formalLanguage > threshold) {
        suggestions.push('Balance el uso de lenguaje técnico con expresiones más naturales.');
      }
      if (indicators.citationValidity < threshold && indicators.citations > 0) {
        suggestions.push('Revise y complete las citas según el formato académico estándar.');
      }
    } else {
      if (indicators.repetitivePatterns > threshold) {
        suggestions.push('Consider varying sentence structure more to improve text naturalness.');
      }
      if (indicators.formulaicTransitions > threshold) {
        suggestions.push('Try using more varied and natural transitions in the text.');
      }
      if (indicators.formalLanguage > threshold) {
        suggestions.push('Balance technical language with more natural expressions.');
      }
      if (indicators.citationValidity < threshold && indicators.citations > 0) {
        suggestions.push('Review and complete citations according to standard academic format.');
      }
    }

    return suggestions;
  };

  return (
    <div className="w-full max-w-6xl mx-auto p-6 space-y-6 bg-gray-50">
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h2 className="text-2xl font-bold mb-6">AI Text Detector Pro</h2>
        
        <div className="flex flex-wrap gap-4 mb-6">
          <select
            className="p-2 border rounded-lg bg-white"
            value={language}
            onChange={(e) => setLanguage(e.target.value as LanguageType)}
          >
            <option value="es">Español</option>
            <option value="en">English</option>
          </select>

          <select
            className="p-2 border rounded-lg bg-white"
            value={textType}
            onChange={(e) => setTextType(e.target.value as TextType)}
          >
            <option value="general">{language === 'es' ? 'General' : 'General'}</option>
            <option value="academic">{language === 'es' ? 'Académico' : 'Academic'}</option>
            <option value="business">{language === 'es' ? 'Empresarial' : 'Business'}</option>
          </select>

          <button
            className="p-2 border rounded-lg bg-blue-50 hover:bg-blue-100 transition-colors"
            onClick={() => setIsComparing(!isComparing)}
          >
            {isComparing ? 
              (language === 'es' ? 'Desactivar Comparación' : 'Disable Comparison') :
              (language === 'es' ? 'Activar Comparación' : 'Enable Comparison')}
          </button>
        </div>

        <div className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <h3 className="text-lg font-semibold mb-4">
                {language === 'es' ? 'Texto Manual' : 'Manual Text'}
              </h3>
              <textarea
                className="w-full h-64 p-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white"
                placeholder={language === 'es' ? 
                  "Pegue su texto aquí para analizarlo..." : 
                  "Paste your text here to analyze..."}
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
            </div>

            <div>
              <h3 className="text-lg font-semibold mb-4">
                {language === 'es' ? 'Análisis de Archivos' : 'File Analysis'}
              </h3>
              <FileUploadSection 
                language={language}
                onFileContent={setFileContent}
              />
              {fileContent && (
                <div className="mt-4">
                  <h4 className="text-sm font-medium text-gray-700">
                    {language === 'es' ? 'Contenido Extraído:' : 'Extracted Content:'}
                  </h4>
                  <div className="mt-2 p-4 bg-gray-50 rounded-lg max-h-48 overflow-y-auto">
                    <pre className="text-sm whitespace-pre-wrap">{fileContent}</pre>
                  </div>
                </div>
              )}
            </div>
          </div>

          {isComparing && (
            <div>
              <h3 className="text-lg font-semibold mb-4">
                {language === 'es' ? 'Texto para Comparar' : 'Comparison Text'}
              </h3>
              <textarea
                className="w-full h-64 p-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white"
                placeholder={language === 'es' ? 
                  "Pegue el texto para comparar..." : 
                  "Paste text to compare..."}
                value={compareText}
                onChange={(e) => setCompareText(e.target.value)}
              />
            </div>
          )}
          
          <button
            className="w-full px-4 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors font-semibold"
            onClick={analyzeText}
          >
            {language === 'es' ? 'Analizar Texto' : 'Analyze Text'}
          </button>
        </div>
      </div>

      {results && (
        <div className="space-y-6">
          <AnalysisVisuals results={results} language={language} />
        </div>
      )}
    </div>
  );
};

export default IADetect;

/* v0.2
import React, { useState } from 'react';
import _ from 'lodash';

// Types and Interfaces
type LanguageType = 'es' | 'en';
type TextType = 'academic' | 'business' | 'general';

interface Patterns {
  transitions: string[];
  formalWords: string[];
  commonPhrases: string[];
  academicPhrases: string[];
  businessPhrases: string[];
}

interface Indicators {
  repetitivePatterns: number;
  formulaicTransitions: number;
  formalLanguage: number;
  consistentTone: number;
  standardPhrases: number;
  structuralPatterns: number;
  styleConsistency: number;
  citations: number;
  documentSimilarity: number;
  citationValidity: number;
  imageAnalysis: number;
  contentOriginality: number;
}

interface Results {
  probability: number;
  indicators: Indicators;
  confidence: string;
  detailedAnalysis: string[];
  suggestions: string[];
}

// Language patterns
const patterns: Record<LanguageType, Patterns> = {
  es: {
    transitions: [
      'además', 'sin embargo', 'por lo tanto', 'en consecuencia', 'por otra parte',
      'en primer lugar', 'en segundo lugar', 'finalmente', 'de esta manera'
    ],
    formalWords: [
      'implementación', 'metodología', 'paradigma', 'infraestructura', 'optimización',
      'mediante', 'asimismo', 'concerniente', 'respectivamente', 'previamente'
    ],
    commonPhrases: [
      'es importante señalar', 'cabe mencionar que', 'es necesario destacar',
      'se recomienda', 'se propone', 'se sugiere'
    ],
    academicPhrases: [
      'según el autor', 'los resultados indican', 'se puede observar',
      'el análisis muestra', 'la investigación revela'
    ],
    businessPhrases: [
      'optimizar procesos', 'maximizar beneficios', 'implementar soluciones',
      'generar valor', 'mejorar la eficiencia'
    ]
  },
  en: {
    transitions: [
      'furthermore', 'however', 'therefore', 'consequently', 'moreover',
      'firstly', 'secondly', 'finally', 'in addition'
    ],
    formalWords: [
      'implementation', 'methodology', 'paradigm', 'infrastructure', 'optimization',
      'utilize', 'facilitate', 'leverage', 'streamline'
    ],
    commonPhrases: [
      'it is important to note', 'it should be mentioned', 'it is worth noting',
      'it is recommended', 'it is suggested'
    ],
    academicPhrases: [
      'according to the author', 'the results indicate', 'it can be observed',
      'the analysis shows', 'research reveals'
    ],
    businessPhrases: [
      'optimize processes', 'maximize benefits', 'implement solutions',
      'generate value', 'improve efficiency'
    ]
  }
};

// Analysis Helper Functions
const analyzeTextComplexity = (text: string): number => {
  const sentences = text.match(/[^.!?]+[.!?]+/g) || [];
  const words = text.split(/\s+/);
  
  const avgWordLength = _.meanBy(words, 'length');
  const avgSentenceLength = words.length / sentences.length;
  const uniqueWords = new Set(words.map(w => w.toLowerCase())).size;
  const lexicalDiversity = uniqueWords / words.length;
  
  return (avgWordLength * 0.2 + avgSentenceLength * 0.3 + lexicalDiversity * 0.5);
};

const generateNGrams = (text: string, n: number): string[] => {
  const words = text.toLowerCase().split(/\s+/);
  const nGrams: string[] = [];
  for (let i = 0; i <= words.length - n; i++) {
    nGrams.push(words.slice(i, i + n).join(' '));
  }
  return nGrams;
};

const findCitations = (text: string): Array<{citation: string, confidence: number}> => {
  const citationPatterns = [
    /\(([^)]+),\s*\d{4}\)/g,
    /([A-Z][a-z]+(?:\s+[A-Z][a-z]+)*)\s+\(\d{4}\)/g,
    /"([^"]+)"\s*\(([^)]+)\)/g
  ];

  const citations = [];
  for (const pattern of citationPatterns) {
    const matches = Array.from(text.matchAll(pattern));
    for (const match of matches) {
      citations.push({
        citation: match[0],
        confidence: validateCitation(match[0])
      });
    }
  }
  return citations;
};

const validateCitation = (citation: string): number => {
  const hasYear = /\d{4}/.test(citation);
  const hasAuthor = /[A-Z][a-z]+/.test(citation);
  const hasProperFormatting = /\(.*\)/.test(citation);
  
  let score = 0;
  if (hasYear) score += 0.4;
  if (hasAuthor) score += 0.4;
  if (hasProperFormatting) score += 0.2;
  
  return score;
};

// Visualization Component
const AnalysisVisuals: React.FC<{results: Results; language: LanguageType}> = ({ results, language }) => {
  if (!results) return null;

  const getColorClass = (value: number): string => {
    if (value < 30) return 'bg-green-500';
    if (value < 70) return 'bg-yellow-500';
    return 'bg-red-500';
  };

  const indicators = [
    { name: language === 'es' ? 'Patrones Repetitivos' : 'Repetitive Patterns', value: results.indicators.repetitivePatterns * 100 },
    { name: language === 'es' ? 'Transiciones' : 'Transitions', value: results.indicators.formulaicTransitions * 100 },
    { name: language === 'es' ? 'Lenguaje Formal' : 'Formal Language', value: results.indicators.formalLanguage * 100 },
    { name: language === 'es' ? 'Originalidad' : 'Originality', value: (1 - results.indicators.contentOriginality) * 100 }
  ];

  return (
    <div className="space-y-6">
      {/* Main Indicator *}
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold mb-4">
          {language === 'es' ? 'Probabilidad de IA' : 'AI Probability'}
        </h3>
        <div className="relative pt-1">
          <div className="flex mb-2 items-center justify-between">
            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full bg-blue-200 text-blue-600">
              {results.probability.toFixed(1)}%
            </span>
          </div>
          <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-200">
            <div
              style={{ width: `${results.probability}%` }}
              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500"
            ></div>
          </div>
        </div>
      </div>

      {/* Detailed Indicators *}
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold mb-4">
          {language === 'es' ? 'Análisis Detallado' : 'Detailed Analysis'}
        </h3>
        <div className="space-y-4">
          {indicators.map((indicator, index) => (
            <div key={index} className="space-y-1">
              <div className="flex justify-between text-sm">
                <span>{indicator.name}</span>
                <span>{indicator.value.toFixed(1)}%</span>
              </div>
              <div className="overflow-hidden h-2 text-xs flex rounded bg-gray-200">
                <div
                  style={{ width: `${indicator.value}%` }}
                  className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center ${getColorClass(indicator.value)}`}
                ></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

// Main Component
const IADetect: React.FC = () => {
  const [text, setText] = useState('');
  const [language, setLanguage] = useState<LanguageType>('es');
  const [textType, setTextType] = useState<TextType>('academic');
  const [results, setResults] = useState<Results | null>(null);
  const [compareText, setCompareText] = useState('');
  const [isComparing, setIsComparing] = useState(false);

  const analyzeText = () => {
    if (!text.trim()) return;

    // Text normalization and basic analysis
    const normalizedText = text.toLowerCase();
    const sentences = text.match(/[^.!?]+[.!?]+/g) || [];
    const words = text.split(/\s+/);
    
    // Advanced analysis
    const textComplexity = analyzeTextComplexity(text);
    const citations = findCitations(text);
    const nGrams = generateNGrams(text, 3);

    // Calculate indicators
    const indicators: Indicators = {
      repetitivePatterns: sentences.length > 1 ? 1 - (new Set(sentences).size / sentences.length) : 0,
      formulaicTransitions: 0,
      formalLanguage: 0,
      consistentTone: textComplexity,
      standardPhrases: 0,
      structuralPatterns: 0,
      styleConsistency: 0,
      citations: citations.length / Math.max(1, sentences.length / 5),
      documentSimilarity: isComparing ? calculateSimilarity(text, compareText) : 0,
      citationValidity: citations.length > 0 ? _.meanBy(citations, 'confidence') : 0,
      imageAnalysis: 0,
      contentOriginality: 1 - (new Set(nGrams).size / nGrams.length)
    };

    // Calculate specific indicators based on patterns
    const currentPatterns = patterns[language];
    indicators.formulaicTransitions = calculatePatternFrequency(text, currentPatterns.transitions);
    indicators.formalLanguage = calculatePatternFrequency(text, currentPatterns.formalWords);
    indicators.standardPhrases = calculatePatternFrequency(text, [
      ...currentPatterns.commonPhrases,
      ...(textType === 'academic' ? currentPatterns.academicPhrases : []),
      ...(textType === 'business' ? currentPatterns.businessPhrases : [])
    ]);

    // Calculate final probability
    const probability = calculateProbability(indicators);

    setResults({
      probability: probability * 100,
      indicators,
      confidence: getConfidenceLevel(probability),
      detailedAnalysis: generateAnalysis(indicators, language),
      suggestions: generateSuggestions(indicators, language)
    });
  };

  const calculateSimilarity = (text1: string, text2: string): number => {
    const ngrams1 = new Set(generateNGrams(text1, 3));
    const ngrams2 = new Set(generateNGrams(text2, 3));
    
    const intersection = new Set([...ngrams1].filter(x => ngrams2.has(x)));
    const union = new Set([...ngrams1, ...ngrams2]);
    
    return intersection.size / union.size;
  };

  const calculatePatternFrequency = (text: string, patterns: string[]): number => {
    const normalizedText = text.toLowerCase();
    const matches = patterns.reduce((count, pattern) => {
      const regex = new RegExp(`\\b${pattern}\\b`, 'gi');
      return count + (normalizedText.match(regex) || []).length;
    }, 0);
    
    return Math.min(matches / Math.max(1, text.split(/\s+/).length / 20), 1);
  };

  const calculateProbability = (indicators: Indicators): number => {
    const weights = {
      repetitivePatterns: 0.15,
      formulaicTransitions: 0.1,
      formalLanguage: 0.1,
      consistentTone: 0.1,
      standardPhrases: 0.1,
      structuralPatterns: 0.1,
      styleConsistency: 0.05,
      citations: 0.05,
      documentSimilarity: 0.15,
      citationValidity: 0.05,
      imageAnalysis: 0.025,
      contentOriginality: 0.075
    };

    return Object.keys(indicators).reduce((sum, key) => {
      return sum + (indicators[key as keyof Indicators] * weights[key as keyof Indicators]);
    }, 0);
  };

  const getConfidenceLevel = (probability: number): string => {
    if (probability < 0.4) return 'Low';
    if (probability < 0.7) return 'Medium';
    return 'High';
  };

  const generateAnalysis = (indicators: Indicators, lang: LanguageType): string[] => {
    const analysis: string[] = [];
    const threshold = 0.4;

    if (lang === 'es') {
      if (indicators.repetitivePatterns > threshold) {
        analysis.push('Se detectaron patrones repetitivos en la estructura');
      }
      if (indicators.contentOriginality < threshold) {
        analysis.push('Alto nivel de originalidad en el contenido');
      }
      if (indicators.citationValidity < threshold && indicators.citations > 0) {
        analysis.push('Las citas requieren revisión o están incompletas');
      }
    } else {
      if (indicators.repetitivePatterns > threshold) {
        analysis.push('Repetitive patterns detected in structure');
      }
      if (indicators.contentOriginality < threshold) {
        analysis.push('High content originality level');
      }
      if (indicators.citationValidity < threshold && indicators.citations > 0) {
        analysis.push('Citations need review or are incomplete');
      }
    }

    return analysis;
  };

  const generateSuggestions = (indicators: Indicators, lang: LanguageType): string[] => {
    const suggestions: string[] = [];
    const threshold = 0.4;

    if (lang === 'es') {
      if (indicators.repetitivePatterns > threshold) {
        suggestions.push('Varíe más la estructura de las oraciones');
      }
      if (indicators.formulaicTransitions > threshold) {
        suggestions.push('Considere usar conectores más naturales y variados');
      }
      if (indicators.citationValidity < threshold && indicators.citations > 0) {
        suggestions.push('Revise el formato de las citas académicas');
      }
    } else {
      if (indicators.repetitivePatterns > threshold) {
        suggestions.push('Add more variety to sentence structure');
      }
      if (indicators.formulaicTransitions > threshold) {
        suggestions.push('Consider using more natural and varied transitions');
      }
      if (indicators.citationValidity < threshold && indicators.citations > 0) {
        suggestions.push('Review academic citation format');
      }
    }

    return suggestions;
  };

  return (
    <div className="w-full max-w-6xl mx-auto p-6 space-y-6">
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h2 className="text-2xl font-bold mb-6">AI Text Detector Pro</h2>
        
        <div className="flex flex-wrap gap-4 mb-6">
          <select
            className="p-2 border rounded-lg"
            value={language}
            onChange={(e) => setLanguage(e.target.value as LanguageType)}
          >
            <option value="es">Español</option>
            <option value="en">English</option>
          </select>

          <select
            className="p-2 border rounded-lg"
            value={textType}
            onChange={(e) => setTextType(e.target.value as TextType)}
          >
            <option value="general">General</option>
            <option value="academic">Academic</option>
            <option value="business">Business</option>
          </select>

          <button
            className="p-2 border rounded-lg bg-blue-50 hover:bg-blue-100"
            onClick={() => setIsComparing(!isComparing)}
          >
            {isComparing ? 
              (language === 'es' ? 'Desactivar Comparación' : 'Disable Comparison') :
              (language === 'es' ? 'Activar Comparación' : 'Enable Comparison')}
          </button>
        </div>

        <div className="space-y-4">
          <textarea
            className="w-full h-64 p-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder={language === 'es' ? 
              "Pegue su texto aquí para analizarlo..." : 
              "Paste your text here to analyze..."}
            value={text}
            onChange={(e) => setText(e.target.value)}
          />

          {isComparing && (
            <textarea
              className="w-full h-64 p-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder={language === 'es' ? 
                "Pegue el texto para comparar..." : 
                "Paste text to compare..."}
              value={compareText}
              onChange={(e) => setCompareText(e.target.value)}
            />
          )}
          
          <button
            className="w-full px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
            onClick={analyzeText}
          >
            {language === 'es' ? 'Analizar Texto' : 'Analyze Text'}
          </button>
        </div>
      </div>

      {results && (
        <div className="space-y-6">
          <AnalysisVisuals results={results} language={language} />
          
          <div className="bg-white rounded-lg shadow-lg p-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <h3 className="text-xl font-bold mb-4">
                  {language === 'es' ? 'Análisis Detallado' : 'Detailed Analysis'}
                </h3>
                <ul className="space-y-2">
                  {results.detailedAnalysis.map((analysis, index) => (
                    <li key={index} className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>{analysis}</span>
                    </li>
                  ))}
                </ul>
              </div>

              <div>
                <h3 className="text-xl font-bold mb-4">
                  {language === 'es' ? 'Sugerencias' : 'Suggestions'}
                </h3>
                <ul className="space-y-2">
                  {results.suggestions.map((suggestion, index) => (
                    <li key={index} className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>{suggestion}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default IADetect; 

/* v0.1 init - detección standard inicial
import React, { useState } from 'react';
import _ from 'lodash';

type LanguageType = 'es' | 'en';
type TextType = 'academic' | 'business' | 'general';

interface Patterns {
  transitions: string[];
  formalWords: string[];
  commonPhrases: string[];
  academicPhrases: string[];
  businessPhrases: string[];
}

type Indicators = {
  repetitivePatterns: number;
  formulaicTransitions: number;
  formalLanguage: number;
  consistentTone: number;
  standardPhrases: number;
  structuralPatterns: number;
  styleConsistency: number;
  citations: number;
};

type Results = {
  probability: number;
  indicators: Indicators;
  confidence: string;
  detailedAnalysis: string[];
  suggestions: string[];
};

const patterns: Record<LanguageType, Patterns> = {
  es: {
    transitions: [
      'además', 'sin embargo', 'por lo tanto', 'en consecuencia', 'por otra parte',
      'en primer lugar', 'en segundo lugar', 'finalmente', 'de esta manera',
      'cabe destacar', 'es importante mencionar', 'en este sentido', 'asimismo',
      'por consiguiente', 'no obstante', 'en efecto', 'en definitiva',
      'en relación con', 'con respecto a', 'en cuanto a', 'en lo que respecta a',
      'desde esta perspectiva', 'bajo este enfoque', 'en este contexto'
    ],
    formalWords: [
      'implementación', 'metodología', 'paradigma', 'infraestructura', 'optimización',
      'mediante', 'asimismo', 'concerniente', 'respectivamente', 'previamente',
      'fundamentalmente', 'específicamente', 'particularmente', 'sustancialmente',
      'significativamente', 'considerablemente', 'evidentemente', 'consecuentemente',
      'primordialmente', 'esencialmente', 'adicionalmente', 'inherentemente',
      'sistemáticamente', 'efectivamente', 'potencialmente', 'posteriormente',
      'preliminarmente', 'conceptualmente', 'teóricamente', 'prácticamente'
    ],
    commonPhrases: [
      'es importante señalar', 'cabe mencionar que', 'es necesario destacar',
      'se recomienda', 'se propone', 'se sugiere', 'se concluye', 'se establece',
      'se determina', 'se identifica', 'se observa', 'se analiza', 'se evalúa',
      'se presenta', 'se describe', 'se demuestra', 'se implementa', 'se desarrolla',
      'es fundamental', 'es esencial', 'es relevante', 'es pertinente',
      'es significativo', 'es necesario', 'es importante considerar'
    ],
    academicPhrases: [
      'según el autor', 'los resultados indican', 'se puede observar',
      'el análisis muestra', 'la investigación revela', 'se desprende que',
      'de acuerdo con los hallazgos', 'los datos sugieren', 'la evidencia demuestra',
      'conforme a lo establecido', 'en base a los resultados', 'según lo analizado',
      'como se demuestra en', 'como se evidencia en', 'como se observa en',
      'se ha demostrado que', 'se ha establecido que', 'se ha identificado que',
      'la literatura sugiere', 'estudios previos indican', 'investigaciones anteriores',
      'el presente estudio', 'esta investigación', 'el análisis realizado'
    ],
    businessPhrases: [
      'optimizar procesos', 'maximizar beneficios', 'implementar soluciones',
      'generar valor', 'mejorar la eficiencia', 'estrategia corporativa',
      'ventaja competitiva', 'objetivos estratégicos', 'plan de acción',
      'mejores prácticas', 'indicadores clave', 'análisis de mercado',
      'gestión de recursos', 'rendimiento organizacional', 'desarrollo sostenible'
    ]
  },
  en: {
    transitions: [
      'furthermore', 'however', 'therefore', 'consequently', 'moreover',
      'firstly', 'secondly', 'finally', 'in addition', 'similarly',
      'in conclusion', 'as a result', 'for instance', 'in particular',
      'in contrast', 'alternatively', 'subsequently', 'nevertheless',
      'in this regard', 'with respect to', 'in terms of', 'in light of'
    ],
    formalWords: [
      'implementation', 'methodology', 'paradigm', 'infrastructure', 'optimization',
      'utilize', 'facilitate', 'leverage', 'streamline', 'enhancement',
      'fundamental', 'significant', 'substantial', 'comprehensive', 'extensively',
      'predominantly', 'subsequently', 'accordingly', 'preliminary', 'theoretical',
      'practical', 'analytical', 'systematic', 'strategic', 'operational'
    ],
    commonPhrases: [
      'it is important to note', 'it should be mentioned', 'it is worth noting',
      'it is recommended', 'it is suggested', 'it is concluded',
      'this demonstrates', 'this indicates', 'this suggests',
      'it is essential', 'it is crucial', 'it is necessary',
      'one must consider', 'it becomes apparent', 'it is evident'
    ],
    academicPhrases: [
      'according to the author', 'the results indicate', 'it can be observed',
      'the analysis shows', 'research reveals', 'it follows that',
      'the data suggest', 'evidence demonstrates', 'findings indicate',
      'previous studies', 'existing literature', 'recent research',
      'this study aims to', 'the objective is to', 'the purpose of this study'
    ],
    businessPhrases: [
      'optimize processes', 'maximize benefits', 'implement solutions',
      'generate value', 'improve efficiency', 'corporate strategy',
      'competitive advantage', 'strategic objectives', 'action plan',
      'best practices', 'key indicators', 'market analysis',
      'resource management', 'organizational performance', 'sustainable development'
    ]
  }
};

const AIDetectorPro = () => {
  const [text, setText] = useState('');
  const [language, setLanguage] = useState<LanguageType>('es');
  const [textType, setTextType] = useState<TextType>('academic');
  const [results, setResults] = useState<Results | null>(null);

  // Funciones auxiliares de análisis
  const analyzeBulletConsistency = (bullets: string[]): number => {
    if (bullets.length < 2) return 0;
    
    const lengths = bullets.map(b => b.length);
    const mean = _.mean(lengths);
    const variance = _.sum(lengths.map(l => Math.pow(l - mean, 2))) / lengths.length;
    const standardDev = Math.sqrt(variance);

    // Analizar inicio de las viñetas
    const firstWords = bullets.map(bullet => {
      const cleaned = bullet.replace(/^[•*-]\s//*____ELIMNINE_AQUI___, '').trim();
      return cleaned.split(/\s+/)[0].toLowerCase();
    });
    
    const uniqueStarts = new Set(firstWords);
    const startConsistency = 1 - (uniqueStarts.size / bullets.length);

    // Analizar terminaciones
    const lastWords = bullets.map(bullet => {
      const cleaned = bullet.trim();
      return cleaned.split(/\s+/).pop()?.toLowerCase() || '';
    });
    
    const commonEndings = lastWords.filter(word => 
      word.endsWith('.') || 
      word.endsWith(',') || 
      word.endsWith(';')
    ).length;
    
    const endingConsistency = commonEndings / bullets.length;

    // Calcular puntuación final
    const lengthScore = Math.max(0, 1 - (standardDev / mean / 2));
    return (lengthScore * 0.4 + startConsistency * 0.3 + endingConsistency * 0.3);
  };

  const analyzeBulletParallelism = (bullets: string[]): number => {
    if (bullets.length < 2) return 0;

    const firstWords = bullets.map(bullet => {
      const cleaned = bullet.replace(/^[•*-]\s//*____ELIMINE_AQUI___, '').trim();
      return cleaned.split(/\s+/)[0].toLowerCase();
    });

    // Verificar paralelismo verbal
    const verbalEndings = ['ar', 'er', 'ir', 'ar,', 'er,', 'ir,'];
    const verbStarts = firstWords.filter(word => 
      verbalEndings.some(ending => word.endsWith(ending)) ||
      word.endsWith('izar') || word.endsWith('ear')
    );

    // Verificar sustantivos o adjetivos iniciales
    const commonNounEndings = ['ción', 'sión', 'dad', 'idad', 'encia', 'ancia'];
    const nounStarts = firstWords.filter(word =>
      commonNounEndings.some(ending => word.endsWith(ending))
    );

    const maxParallel = Math.max(verbStarts.length, nounStarts.length);
    const parallelScore = maxParallel / bullets.length;

    // Verificar longitud similar
    const lengths = bullets.map(b => b.length);
    const avgLength = _.mean(lengths);
    const lengthVariance = _.sum(lengths.map(l => Math.abs(l - avgLength) / avgLength)) / lengths.length;
    const lengthScore = Math.max(0, 1 - lengthVariance);

    return (parallelScore * 0.7 + lengthScore * 0.3);
  };

  const analyzeBulletFormatting = (bullets: string[]): number => {
    if (bullets.length < 2) return 0;

    // Verificar consistencia en el marcador de viñeta
    const bulletMarkers = bullets.map(b => {
      const match = b.match(/^[•*-]\s/);
      return match ? match[0] : '';
    });
    const uniqueMarkers = new Set(bulletMarkers.filter(m => m !== ''));
    const markerScore = uniqueMarkers.size === 0 ? 0 : 1 - (uniqueMarkers.size - 1) / bullets.length;

    // Verificar espaciado consistente después del marcador
    const spacingPatterns = bullets.map(b => {
      const match = b.match(/^[•*-](\s+)/);
      return match && match[1] ? match[1].length : 0;
    });
    const uniqueSpacing = new Set(spacingPatterns);
    const spacingScore = 1 - (uniqueSpacing.size - 1) / bullets.length;

    // Verificar alineación
    const indentations = bullets.map(b => {
      const match = b.match(/^\s//*______ELIMINE_AQUI____);
      return match ? match[0].length : 0;
    });
    const uniqueIndents = new Set(indentations);
    const indentScore = 1 - (uniqueIndents.size - 1) / bullets.length;

    return (markerScore * 0.4 + spacingScore * 0.3 + indentScore * 0.3);
  };

  const analyzeHeaderConsistency = (headers: string[]): number => {
    if (headers.length < 2) return 0;

    // Analizar formatos de encabezado
    const patterns = headers.map(h => {
      if (h.match(/^#+\s/)) return 'markdown';
      if (h.match(/^[A-Z][^:]+:$/)) return 'colon';
      if (h.match(/^\d+\./)) return 'numbered';
      return 'other';
    });
    
    const formatConsistency = 1 - (new Set(patterns).size / patterns.length);

    // Analizar capitalización
    const capitalization = headers.map(h => {
      const cleaned = h.replace(/^#+\s//*_______ELIMINE_AQUI_____, '').trim();
      if (cleaned.match(/^[A-Z][^a-z]*$/)) return 'allcaps';
      if (cleaned.match(/^[A-Z][a-z]/)) return 'titlecase';
      return 'other';
    });
    
    const capConsistency = 1 - (new Set(capitalization).size / capitalization.length);

    // Analizar longitud
    const lengths = headers.map(h => h.replace(/^#+\s*//*________ELIMINE_AQUI_______, '').length);
    const mean = _.mean(lengths);
    const variance = _.sum(lengths.map(l => Math.pow(l - mean, 2))) / lengths.length;
    const standardDev = Math.sqrt(variance);
    const lengthScore = Math.max(0, 1 - (standardDev / mean / 2));

    return (formatConsistency * 0.4 + capConsistency * 0.3 + lengthScore * 0.3);
  };

  const analyzeHeaderHierarchy = (headers: string[]): number => {
    if (headers.length < 2) return 0;

    // Extraer niveles de jerarquía
    const levels = headers.map(header => {
      const hashCount = (header.match(/^#+/) || [''])[0].length;
      if (hashCount > 0) return hashCount;
      
      if (header.match(/^[A-Z][^:]+:$/)) return 1;
      if (header.match(/^\d+\.\d+/)) return 2;
      if (header.match(/^\d+\./)) return 1;
      return 1;
    });

    // Verificar progresión lógica
    let validProgressions = 0;
    for (let i = 1; i < levels.length; i++) {
      if (levels[i] >= levels[i-1] || levels[i] === levels[i-1] - 1) {
        validProgressions++;
      }
    }

    return validProgressions / (headers.length - 1);
  };

  const analyzeHeaderFormatting = (headers: string[]): number => {
    if (headers.length < 2) return 0;

    // Verificar consistencia en el formato
    const formatPatterns = headers.map(h => {
      if (h.match(/^#+\s/)) return 'markdown';
      if (h.match(/^[A-Z][^:]+:$/)) return 'colon';
      if (h.match(/^\d+\./)) return 'numbered';
      return 'other';
    });
    const uniqueFormats = new Set(formatPatterns);
    const formatScore = 1 - (uniqueFormats.size - 1) / headers.length;

    // Verificar capitalización
    const capPatterns = headers.map(h => {
      if (h.match(/^[A-Z][^a-z]//*________ELIMINE_AQUI_______)) return 'allcaps';
      if (h.match(/^[A-Z][a-z]/)) return 'titlecase';
      return 'other';
    });
    const uniqueCaps = new Set(capPatterns);
    const capScore = 1 - (uniqueCaps.size - 1) / headers.length;

    // Verificar espaciado
    const spacingPatterns = headers.map(h => {
      const match = h.match(/^(\s*)[^:]//*_________ELIMINE_AQUI_______);
      return match ? match[1].length : 0;
    });
    const uniqueSpacing = new Set(spacingPatterns);
    const spacingScore = 1 - (uniqueSpacing.size - 1) / headers.length;

    return (formatScore * 0.4 + capScore * 0.3 + spacingScore * 0.3);
  };

  const analyzeStructuralConsistency = (bulletPoints: string[], headers: string[]): number => {
    if (bulletPoints.length === 0 && headers.length === 0) return 0;
    
    // Analizar patrones en viñetas
    const bulletPatterns = {
      consistency: 0,
      parallelStructure: 0,
      formatting: 0
    };

    if (bulletPoints.length > 0) {
      bulletPatterns.consistency = analyzeBulletConsistency(bulletPoints);
      bulletPatterns.parallelStructure = analyzeBulletParallelism(bulletPoints);
      bulletPatterns.formatting = analyzeBulletFormatting(bulletPoints);
    }

    // Analizar patrones en encabezados
    const headerPatterns = {
      consistency: 0,
      hierarchy: 0,
      formatting: 0
    };

    if (headers.length > 0) {
      headerPatterns.consistency = analyzeHeaderConsistency(headers);
      headerPatterns.hierarchy = analyzeHeaderHierarchy(headers);
      headerPatterns.formatting = analyzeHeaderFormatting(headers);
    }

    // Calcular puntuación ponderada
    const bulletScore = bulletPoints.length > 0 
      ? (bulletPatterns.consistency * 0.4 + bulletPatterns.parallelStructure * 0.4 + bulletPatterns.formatting * 0.2)
      : 0;
    
    const headerScore = headers.length > 0
      ? (headerPatterns.consistency * 0.4 + headerPatterns.hierarchy * 0.4 + headerPatterns.formatting * 0.2)
      : 0;

    // Ajustar según la presencia de ambos elementos
    if (bulletPoints.length > 0 && headers.length > 0) {
      return (bulletScore * 0.5 + headerScore * 0.5) * 1.2; // Bonus por estructura completa
    } else {
      return bulletScore + headerScore;
    }
  };

  const analyzeText = () => {
    if (!text.trim()) return;

    // Normalizar el texto
    const normalizedText = text.toLowerCase();
    const sentences = text.match(/[^.!?]+[.!?]+/g) || [];
    const paragraphs = text.split(/\n\n+/);
    const bulletPoints = text.match(/[•*-]\s.*$/gm) || [];
    const headers = text.match(/^#+\s.*$|^[A-Z][^.!?]+:$/gm) || [];
    
    const indicators: Indicators = {
      repetitivePatterns: 0,
      formulaicTransitions: 0,
      formalLanguage: 0,
      consistentTone: 0,
      standardPhrases: 0,
      structuralPatterns: 0,
      styleConsistency: 0,
      citations: 0
    };

    // 1. Patrones repetitivos
    const sentenceStarts = sentences.map(s => {
      const cleaned = s.trim().toLowerCase();
      return cleaned.split(/\s+/).slice(0, 3).join(' ');
    });
    const uniqueStarts = new Set(sentenceStarts);
    indicators.repetitivePatterns = sentences.length ? 
      1 - (uniqueStarts.size / sentences.length) : 0;

    // 2. Transiciones formulaicas
    const currentPatterns = patterns[language];
    const transitionCount = currentPatterns.transitions.reduce((count, transition) => {
      const regex = new RegExp(`\\b${transition}\\b`, 'gi');
      return count + (normalizedText.match(regex) || []).length;
    }, 0);
    indicators.formulaicTransitions = sentences.length ? 
      Math.min(transitionCount / sentences.length, 1) : 0;

    // 3. Lenguaje formal
    const formalCount = currentPatterns.formalWords.reduce((count, word) => {
      const regex = new RegExp(`\\b${word}\\b`, 'gi');
      return count + (normalizedText.match(regex) || []).length;
    }, 0);
    const wordCount = text.split(/\s+/).length;
    indicators.formalLanguage = wordCount ? 
      Math.min(formalCount / (wordCount / 15), 1) : 0;

    // 4. Consistencia de tono
    const sentenceLengths = sentences.map(s => s.split(/\s+/).length);
    if (sentenceLengths.length > 0) {
      const mean = _.mean(sentenceLengths);
      const variance = _.sum(sentenceLengths.map(len => Math.pow(len - mean, 2))) / sentenceLengths.length;
      const standardDev = Math.sqrt(variance);
      indicators.consistentTone = Math.max(0, 1 - (standardDev / mean));
    }

    // 5. Frases estándar
    const relevantPhrases = [
      ...currentPatterns.commonPhrases,
      ...(textType === 'academic' ? currentPatterns.academicPhrases : []),
      ...(textType === 'business' ? currentPatterns.businessPhrases : [])
    ];
    
    const phraseCount = relevantPhrases.reduce((count, phrase) => {
      const regex = new RegExp(`\\b${phrase}\\b`, 'gi');
      return count + (normalizedText.match(regex) || []).length;
    }, 0);
    indicators.standardPhrases = sentences.length ? 
      Math.min(phraseCount / (sentences.length / 3), 1) : 0;

    // 6. Patrones estructurales
    indicators.structuralPatterns = analyzeStructuralConsistency(bulletPoints, headers);

    // 7. Consistencia de estilo
    const styleMetrics = paragraphs.map(p => ({
      avgSentenceLength: (p.match(/[^.!?]+[.!?]+/g) || []).length,
      formalityScore: calculateFormalityScore(p, currentPatterns.formalWords),
    }));
    
    if (styleMetrics.length > 1) {
      const sentenceLengthVar = calculateVariance(styleMetrics.map(m => m.avgSentenceLength));
      const formalityVar = calculateVariance(styleMetrics.map(m => m.formalityScore));
      indicators.styleConsistency = Math.max(0, 1 - ((sentenceLengthVar + formalityVar) / 2));
    }

    // 8. Citas (para textos académicos)
    if (textType === 'academic') {
      const citations = text.match(/\([^)]+,\s*\d{4}\)|\[\d+\]/g) || [];
      indicators.citations = paragraphs.length ? 
        Math.min(citations.length / (paragraphs.length / 2), 1) : 0;
    }

    // Calcular probabilidad final
    const weights = getWeightsByType(textType);
    const probability = calculateProbability(indicators, weights);

    // Generar análisis y sugerencias
    const detailedAnalysis = generateDetailedAnalysis(indicators, textType, language);
    const suggestions = generateSuggestions(indicators, textType, language);

    setResults({
      probability: probability * 100,
      indicators,
      confidence: getConfidenceLevel(probability),
      detailedAnalysis,
      suggestions
    });
  };

  const calculateFormalityScore = (text: string, formalWords: string[]): number => {
    const words = text.toLowerCase().split(/\s+/);
    const formalCount = formalWords.reduce((count, word) => {
      const regex = new RegExp(`\\b${word}\\b`, 'gi');
      return count + (text.toLowerCase().match(regex) || []).length;
    }, 0);
    return words.length > 0 ? formalCount / words.length : 0;
  };

  const calculateVariance = (numbers: number[]): number => {
    const mean = _.mean(numbers);
    return numbers.length > 1 ?
      _.sum(numbers.map(n => Math.pow(n - mean, 2))) / numbers.length : 0;
  };

  const getWeightsByType = (type: TextType) => {
    switch (type) {
      case 'academic':
        return {
          repetitivePatterns: 0.2,
          formulaicTransitions: 0.15,
          formalLanguage: 0.15,
          consistentTone: 0.1,
          standardPhrases: 0.15,
          structuralPatterns: 0.15,
          styleConsistency: 0.05,
          citations: 0.05
        };
      case 'business':
        return {
          repetitivePatterns: 0.15,
          formulaicTransitions: 0.15,
          formalLanguage: 0.15,
          consistentTone: 0.15,
          standardPhrases: 0.15,
          structuralPatterns: 0.15,
          styleConsistency: 0.1,
          citations: 0
        };
      default:
        return {
          repetitivePatterns: 0.15,
          formulaicTransitions: 0.15,
          formalLanguage: 0.15,
          consistentTone: 0.15,
          standardPhrases: 0.15,
          structuralPatterns: 0.15,
          styleConsistency: 0.1,
          citations: 0
        };
    }
  };

  const calculateProbability = (
    indicators: Indicators,
    weights: Record<keyof Indicators, number>
  ): number => {
    return (Object.keys(indicators) as Array<keyof Indicators>).reduce((sum, key) => {
      return sum + (indicators[key] * weights[key]);
    }, 0);
  };

  const generateDetailedAnalysis = (
    indicators: Indicators,
    type: TextType,
    lang: LanguageType
  ): string[] => {
    const analysis: string[] = [];
    const threshold = 0.4;

    if (lang === 'es') {
      if (indicators.repetitivePatterns > threshold) {
        analysis.push('Se detectaron patrones repetitivos en la estructura de las oraciones');
      }
      if (indicators.formulaicTransitions > threshold) {
        analysis.push('Uso frecuente de transiciones formulaicas');
      }
      if (indicators.formalLanguage > threshold) {
        analysis.push('Alto nivel de lenguaje formal y técnico');
      }
      if (indicators.consistentTone > threshold) {
        analysis.push('Tono altamente consistente en todo el texto');
      }
      if (indicators.structuralPatterns > threshold) {
        analysis.push('Patrones estructurales muy uniformes');
      }
      if (type === 'academic' && indicators.citations < threshold) {
        analysis.push('Bajo número de citas para un texto académico');
      }
      if (indicators.styleConsistency > 0.7) {
        analysis.push('Estilo de escritura excesivamente uniforme');
      }
      if (indicators.standardPhrases > threshold) {
        analysis.push('Uso frecuente de frases estandarizadas');
      }
    } else {
      if (indicators.repetitivePatterns > threshold) {
        analysis.push('Repetitive patterns detected in sentence structure');
      }
      if (indicators.formulaicTransitions > threshold) {
        analysis.push('Frequent use of formulaic transitions');
      }
      if (indicators.formalLanguage > threshold) {
        analysis.push('High level of formal and technical language');
      }
      if (indicators.consistentTone > threshold) {
        analysis.push('Highly consistent tone throughout the text');
      }
      if (indicators.structuralPatterns > threshold) {
        analysis.push('Very uniform structural patterns');
      }
      if (type === 'academic' && indicators.citations < threshold) {
        analysis.push('Low citation count for academic text');
      }
      if (indicators.styleConsistency > 0.7) {
        analysis.push('Excessively uniform writing style');
      }
      if (indicators.standardPhrases > threshold) {
        analysis.push('Frequent use of standardized phrases');
      }
    }

    return analysis;
  };

  const generateSuggestions = (
    indicators: Indicators,
    type: TextType,
    lang: LanguageType
  ): string[] => {
    const suggestions: string[] = [];
    const threshold = 0.4;

    if (lang === 'es') {
      if (indicators.repetitivePatterns > threshold) {
        suggestions.push('Intente variar más la estructura de las oraciones');
      }
      if (indicators.formulaicTransitions > threshold) {
        suggestions.push('Considere usar conectores más variados y naturales');
      }
      if (indicators.formalLanguage > threshold) {
        suggestions.push('Considere equilibrar el lenguaje formal con expresiones más naturales');
      }
      if (indicators.consistentTone > 0.7) {
        suggestions.push('Añada más variación en el tono para que suene más natural');
      }
      if (type === 'academic' && indicators.citations < 0.3) {
        suggestions.push('Considere incluir más citas y referencias académicas');
      }
      if (indicators.styleConsistency > 0.7) {
        suggestions.push('Varíe el estilo de escritura para hacerlo más natural');
      }
      if (indicators.standardPhrases > threshold) {
        suggestions.push('Use expresiones más variadas y personalizadas');
      }
    } else {
      if (indicators.repetitivePatterns > threshold) {
        suggestions.push('Try to vary sentence structure more');
      }
      if (indicators.formulaicTransitions > threshold) {
        suggestions.push('Consider using more varied and natural transitions');
      }
      if (indicators.formalLanguage > threshold) {
        suggestions.push('Consider balancing formal language with more natural expressions');
      }
      if (indicators.consistentTone > 0.7) {
        suggestions.push('Add more tonal variation to sound more natural');
      }
      if (type === 'academic' && indicators.citations < 0.3) {
        suggestions.push('Consider including more citations and academic references');
      }
      if (indicators.styleConsistency > 0.7) {
        suggestions.push('Vary the writing style to make it more natural');
      }
      if (indicators.standardPhrases > threshold) {
        suggestions.push('Use more varied and personalized expressions');
      }
    }

    return suggestions;
  };

  const getConfidenceLevel = (probability: number): string => {
    if (probability < 0.4) return 'Low';
    if (probability < 0.7) return 'Medium';
    return 'High';
  };

  const getIndicatorColor = (value: number): string => {
    if (value < 0.3) return 'text-green-600';
    if (value < 0.6) return 'text-yellow-600';
    return 'text-red-600';
  };

  const translateIndicator = (key: keyof Indicators): string => {
    const translations: Record<keyof Indicators, string> = {
      repetitivePatterns: 'Patrones Repetitivos',
      formulaicTransitions: 'Transiciones Formulaicas',
      formalLanguage: 'Lenguaje Formal',
      consistentTone: 'Tono Consistente',
      standardPhrases: 'Frases Estándar',
      structuralPatterns: 'Patrones Estructurales',
      styleConsistency: 'Consistencia de Estilo',
      citations: 'Citas'
    };
    return translations[key];
  };

  return (
    <div className="w-full max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg">
      <div className="mb-6">
        <h2 className="text-2xl font-bold mb-4">AI Text Detector Pro</h2>
        
        <div className="flex gap-4 mb-4">
          <select
            className="p-2 border rounded-lg"
            value={language}
            onChange={(e) => setLanguage(e.target.value as LanguageType)}
          >
            <option value="es">Español</option>
            <option value="en">English</option>
          </select>

          <select
            className="p-2 border rounded-lg"
            value={textType}
            onChange={(e) => setTextType(e.target.value as TextType)}
          >
            <option value="general">General</option>
            <option value="academic">Academic</option>
            <option value="business">Business</option>
          </select>
        </div>
      </div>
      
      <div className="space-y-4">
        <textarea
          className="w-full h-64 p-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder={language === 'es' ? 
            "Pegue su texto aquí para analizarlo..." : 
            "Paste your text here to analyze..."}
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
        
        <button
          className="w-full px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          onClick={analyzeText}
        >
          {language === 'es' ? 'Analizar Texto' : 'Analyze Text'}
        </button>

        {results && (
          <div className="space-y-6">
            <div className="p-4 bg-gray-50 rounded-lg border">
              <div className="flex justify-between items-center mb-4">
                <span className="text-lg font-semibold">
                  {language === 'es' ? 
                    `Probabilidad de texto generado por IA: ${results.probability.toFixed(1)}%` :
                    `AI Generation Probability: ${results.probability.toFixed(1)}%`}
                </span>
                <span className={`text-lg font-bold ${
                  results.confidence === 'Low' ? 'text-green-600' :
                  results.confidence === 'Medium' ? 'text-yellow-600' : 'text-red-600'
                }`}>
                  {language === 'es' ? 
                    `Confianza ${results.confidence === 'Low' ? 'Baja' : 
                    results.confidence === 'Medium' ? 'Media' : 'Alta'}` :
                    `${results.confidence} Confidence`}
                </span>
              </div>

              <div className="space-y-4">
                <div className="border-t pt-4">
                  <h3 className="font-semibold mb-2">
                    {language === 'es' ? 'Análisis Detallado:' : 'Detailed Analysis:'}
                  </h3>
                  {(Object.entries(results.indicators) as [keyof Indicators, number][]).map(([key, value]) => (
                    <div key={key} className="flex justify-between items-center p-2 bg-white rounded mb-2">
                      <span className="capitalize">
                        {language === 'es' ? 
                          translateIndicator(key) :
                          key.replace(/([A-Z])/g, ' $1')}
                      </span>
                      <span className={getIndicatorColor(value)}>
                        {(value * 100).toFixed(1)}%
                      </span>
                    </div>
                  ))}
                </div>

                {results.detailedAnalysis.length > 0 && (
                  <div className="border-t pt-4">
                    <h3 className="font-semibold mb-2">
                      {language === 'es' ? 'Observaciones:' : 'Observations:'}
                    </h3>
                    <ul className="list-disc pl-5 space-y-1">
                      {results.detailedAnalysis.map((analysis, index) => (
                        <li key={index}>{analysis}</li>
                      ))}
                    </ul>
                  </div>
                )}

                {results.suggestions.length > 0 && (
                  <div className="border-t pt-4">
                    <h3 className="font-semibold mb-2">
                      {language === 'es' ? 'Sugerencias:' : 'Suggestions:'}
                    </h3>
                    <ul className="list-disc pl-5 space-y-1">
                      {results.suggestions.map((suggestion, index) => (
                        <li key={index}>{suggestion}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AIDetectorPro; */