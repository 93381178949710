import { useState, useEffect } from 'react';
import { Table, Button, Spinner, Alert, Modal, Form, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrashAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { IWhatsAppFlow } from 'data/models/IWhatsApp';
import { useUserContext } from 'layouts/UserContext';
import axios from 'axios';

// Extended interface with isActive property
interface ExtendedWhatsAppFlow extends IWhatsAppFlow {
  isActive?: boolean;
}

type DirectivesTableProps = {
  language: 'es' | 'en';
};

const API_BASE_URL = 'https://backend.anubisai.net/api/whatsapp/bot';
const API_KEY = 'anubis-whatsapp-api-key';

const DirectivesTable = ({ language }: DirectivesTableProps) => {
  const { user, isLoading } = useUserContext();
  const [flows, setFlows] = useState<ExtendedWhatsAppFlow[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  // Modal states
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState<'add' | 'edit'>('add');
  const [currentFlow, setCurrentFlow] = useState<ExtendedWhatsAppFlow | null>(null);
  
  // Form states
  const [keywordInput, setKeywordInput] = useState('');
  const [responseInput, setResponseInput] = useState('');
  const [isActiveInput, setIsActiveInput] = useState(true);

  // Use useEffect to set flows when user data changes
  useEffect(() => {
    if (user && user.whatsappInfo && user.whatsappInfo.flows) {
      setFlows(user.whatsappInfo.flows);
    }
  }, [user]);

  // Open modal for adding a new flow
  const handleAddFlow = () => {
    setModalMode('add');
    setKeywordInput('');
    setResponseInput('');
    setIsActiveInput(true);
    setCurrentFlow(null);
    setShowModal(true);
    setError(null);
  };

  // Open modal for editing an existing flow
  const handleEditFlow = (flow: ExtendedWhatsAppFlow) => {
    setModalMode('edit');
    setKeywordInput(flow.keyword ? flow.keyword.join(', ') : '');
    setResponseInput(flow.response || '');
    setIsActiveInput(flow.isActive !== false); // Default to true if undefined
    setCurrentFlow(flow);
    setShowModal(true);
    setError(null);
  };

  // Handle flow deletion
  const handleDeleteFlow = (_id: string) => {
    if (window.confirm(language === 'es' 
      ? '¿Está seguro que desea eliminar esta directiva?' 
      : 'Are you sure you want to delete this directive?')) {
      setFlows(flows.filter(flow => flow._id !== _id));
    }
  };

  // Handle form submission
  const handleSubmit = async () => {
    if (!keywordInput.trim() || !responseInput.trim()) {
      setError(language === 'es' 
        ? 'Los lanzadores y la respuesta son obligatorios' 
        : 'Triggers and response are required');
      return;
    }
    
    try {
      setIsSubmitting(true);
      
      // Parse keywords from comma-separated string
      const keywords = keywordInput
        .split(',')
        .map(keyword => keyword.trim())
        .filter(keyword => keyword.length > 0);
      
      if (keywords.length === 0) {
        setError(language === 'es'
          ? 'Debe especificar al menos un lanzador'
          : 'You must specify at least one trigger');
        setIsSubmitting(false);
        return;
      }
      
      if (modalMode === 'add') {
        // Create new flow (normally would call API here)
        const newFlow: ExtendedWhatsAppFlow = {
          _id: `temp-${Date.now()}`, // This would be replaced by server-generated ID
          keyword: keywords,
          response: responseInput.trim(),
          isActive: isActiveInput,
          createdAt: new Date().toISOString(),
          updatedAt: ''
        };
        
        // Add to existing flows
        setFlows([...flows, newFlow]);
      } else if (currentFlow) {
        // Update existing flow
        const updatedFlow: ExtendedWhatsAppFlow = {
          ...currentFlow,
          keyword: keywords,
          response: responseInput.trim(),
          isActive: isActiveInput,
          updatedAt: new Date().toISOString()
        };
        
        // Update in flows array
        setFlows(flows.map(flow => 
          flow._id === currentFlow._id ? updatedFlow : flow
        ));
      }
      
      setShowModal(false);
    } catch (error) {
      console.error('Error saving directive:', error);
      setError(language === 'es' 
        ? 'Error al guardar la directiva' 
        : 'Error saving directive');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle modal close
  const handleCloseModal = () => {
    setShowModal(false);
    setError(null);
  };

  // Component for the directive modal (shared between add and edit)
  const renderModal = () => (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          {modalMode === 'add' 
            ? (language === 'es' ? 'Nueva Directiva' : 'New Directive')
            : (language === 'es' ? 'Editar Directiva' : 'Edit Directive')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && (
          <Alert variant="danger">
            {error}
          </Alert>
        )}
        
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>{language === 'es' ? 'Lanzadores (separados por comas)' : 'Triggers (comma separated)'}</Form.Label>
            <Form.Control 
              type="text" 
              value={keywordInput}
              onChange={(e) => setKeywordInput(e.target.value)}
              placeholder={language === 'es' 
                ? 'ej: hola, saludos, buenos días' 
                : 'e.g: hello, hi, good morning'}
              disabled={isSubmitting}
            />
            <Form.Text className="text-muted">
              {language === 'es' 
                ? 'Palabras o frases que activarán esta respuesta automática' 
                : 'Words or phrases that will trigger this automatic response'}
            </Form.Text>
          </Form.Group>
          
          <Form.Group className="mb-3">
            <Form.Label>{language === 'es' ? 'Respuesta' : 'Response'}</Form.Label>
            <Form.Control 
              as="textarea" 
              rows={5}
              value={responseInput}
              onChange={(e) => setResponseInput(e.target.value)}
              placeholder={language === 'es' 
                ? 'Escriba la respuesta que se enviará automáticamente...' 
                : 'Write the response that will be sent automatically...'}
              disabled={isSubmitting}
            />
          </Form.Group>
          
          <Form.Group className="mb-3">
            <Form.Check 
              type="switch"
              id="directive-active-switch"
              label={language === 'es' ? 'Directiva Activa' : 'Active Directive'}
              checked={isActiveInput}
              onChange={(e) => setIsActiveInput(e.target.checked)}
              disabled={isSubmitting}
            />
            <Form.Text className="text-muted">
              {language === 'es' 
                ? 'Solo las directivas activas responderán a los mensajes' 
                : 'Only active directives will respond to messages'}
            </Form.Text>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal} disabled={isSubmitting}>
          {language === 'es' ? 'Cancelar' : 'Cancel'}
        </Button>
        <Button 
          variant="primary" 
          onClick={handleSubmit}
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <>
              <Spinner animation="border" size="sm" className="me-2" />
              {language === 'es' ? 'Guardando...' : 'Saving...'}
            </>
          ) : (
            language === 'es' ? 'Guardar' : 'Save'
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );

  // Mostrar spinner durante la carga
  if (!user || isLoading) {
    return (
      <div className="text-center my-5">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">
            {language === 'es' ? 'Cargando...' : 'Loading...'}
          </span>
        </Spinner>
      </div>
    );
  }

  // Si no hay flows, mostrar mensaje
  if (flows.length === 0) {
    return (
      <div className="mt-4">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h4>
            {language === 'es' ? 'Directivas de Respuesta Automática' : 'Auto Answer Directives'}
          </h4>
          <Button 
            variant="primary" 
            onClick={handleAddFlow}
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            {language === 'es' ? 'Nueva Directiva' : 'New Directive'}
          </Button>
        </div>
        
        <Alert variant="info">
          {language === 'es' 
            ? 'No hay directivas de respuesta configuradas. Puede agregar una nueva directiva para automatizar respuestas.' 
            : 'No response directives configured. You can add a new directive to automate responses.'}
        </Alert>
        
        {/* Render modal */}
        {renderModal()}
      </div>
    );
  }

  // Renderizar la tabla con los flows
  return (
    <div className="mt-4">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h4>
          {language === 'es' ? 'Directivas de Respuesta Automática' : 'Auto Answer Directives'}
        </h4>
        <Button 
          variant="primary" 
          onClick={handleAddFlow}
        >
          <FontAwesomeIcon icon={faPlus} className="me-2" />
          {language === 'es' ? 'Nueva Directiva' : 'New Directive'}
        </Button>
      </div>
      
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>{language === 'es' ? 'Lanzador' : 'Trigger'}</th>
            <th>{language === 'es' ? 'Respuesta' : 'Response'}</th>
            <th className="text-center" style={{ width: '100px' }}>
              {language === 'es' ? 'Estado' : 'Status'}
            </th>
            <th className="text-center" style={{ width: '120px' }}>
              {language === 'es' ? 'Acción' : 'Action'}
            </th>
          </tr>
        </thead>
        <tbody>
          {flows.map((flow) => (
            <tr key={flow._id}>
              <td>
                {flow.keyword ? flow.keyword.join(', ') : 'N/A'}
              </td>
              <td>
                <div>{flow.response}</div>
              </td>
              <td className="text-center">
                <Badge bg={flow.isActive !== false ? 'success' : 'secondary'}>
                  {flow.isActive !== false 
                    ? (language === 'es' ? 'Activo' : 'Active') 
                    : (language === 'es' ? 'Inactivo' : 'Inactive')}
                </Badge>
              </td>
              <td className="text-center">
                <Button 
                  variant="outline-primary" 
                  size="sm" 
                  className="me-2"
                  onClick={() => handleEditFlow(flow)}
                  title={language === 'es' ? 'Editar' : 'Edit'}
                >
                  <FontAwesomeIcon icon={faPencilAlt} />
                </Button>
                <Button 
                  variant="outline-danger" 
                  size="sm"
                  onClick={() => handleDeleteFlow(flow._id)}
                  title={language === 'es' ? 'Eliminar' : 'Delete'}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      
      {/* Render modal */}
      {renderModal()}
    </div>
  );
};

export default DirectivesTable;