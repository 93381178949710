import Button from 'components/base/Button';
import { Col, Row } from 'react-bootstrap';
import illus38 from 'assets/img/spot-illustrations/38.webp';
import illusDark38 from 'assets/img/spot-illustrations/dark_38.webp';
import { useWizardFormContext } from 'providers/WizardFormProvider';

const WizardSuccessStep = () => {
  const { startOver, formData } = useWizardFormContext();

  function debug() {
    console.log(JSON.stringify(formData));
  }
  return (
    <Row className="flex-center pb-8 pt-4 gx-3 gy-4">
      <Col xs={12} sm="auto" className="text-center text-sm-start">
        <img src={illus38} alt="" width={220} className="d-dark-none" />
        <img src={illusDark38} alt="" width={220} className="d-light-none" />
      </Col>
      <Col xs={12} sm="auto" className="text-center text-sm-start">
        <h5 className="mb-3">You are all set!</h5>
        <p className="text-body-emphasis fs--1">
          Now you can wait to aprove 
          <br />
          your neuroskill and module(s)
        </p>
        <Button variant="primary" className="px-6" onClick={() => debug()}>
          Proceed!
        </Button>
      </Col>
    </Row>
  );
};

export default WizardSuccessStep;
