import React, { useState, useRef, useEffect, useCallback, forwardRef, useImperativeHandle } from 'react';
import { DIDProps, TalkRequest } from '../../../types/did';
import avatar3d from 'assets/img/Anubis/Anputv0.2.jpeg'

// Definición de tipos
export type ConnectionStatus = 'disconnected' | 'connecting' | 'connected' | 'error';

export interface DIDHandle {
  connect: () => Promise<void>;
  getStatus: () => ConnectionStatus;
  setStatusChangeCallback: (callback: (status: ConnectionStatus) => void) => void;
  talk: (message: string) => Promise<void>;
}

const DIDAvatar = forwardRef<DIDHandle, DIDProps>(({ apiKey, apiUrl, sourceUrl }, ref) => {
  const [status, setStatus] = useState<ConnectionStatus>('disconnected');
  const [statusChangeCallback, setStatusChangeCallback] = useState<((status: ConnectionStatus) => void) | null>(null);
  const [pitch] = useState<number>(1.0);
  const [errorMessage, setErrorMessage] = useState<string>(''); // Added for test messages
  
  const videoRef = useRef<HTMLVideoElement>(null);
  const peerConnectionRef = useRef<RTCPeerConnection | null>(null);
  const streamIdRef = useRef<string | null>(null);
  const sessionIdRef = useRef<string | null>(null);

  const updateStatus = (newStatus: ConnectionStatus) => {
    console.log('DIDAvatar updating status to:', newStatus);
    setStatus(newStatus);
    if (statusChangeCallback) {
      console.log('Calling status change callback with:', newStatus);
      statusChangeCallback(newStatus);
    } else {
      console.log('No status change callback registered');
    }
  };

  const handleConnect = useCallback(async () => {
    try {
      updateStatus('connecting');
      const encodedKey = !apiKey.includes(':') ? apiKey : btoa(apiKey);

      const streamResponse = await fetch(`${apiUrl}/talks/streams`, {
        method: 'POST',
        headers: {
          'Authorization': `Basic ${encodedKey}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          source_url: sourceUrl
        })
      });

      if (!streamResponse.ok) {
        throw new Error('Failed to connect to stream');
      }

      const { id, offer, ice_servers, session_id } = await streamResponse.json();
      streamIdRef.current = id;
      sessionIdRef.current = session_id;

      const defaultIceServers = [
        { urls: 'stun:stun.l.google.com:19302' },
        { urls: 'stun:stun1.l.google.com:19302' },
        { urls: 'stun:stun2.l.google.com:19302' },
        { urls: 'stun:stun3.l.google.com:19302' }
      ];

      const pc = new RTCPeerConnection({ 
        iceServers: ice_servers?.slice(0, 4) || defaultIceServers
      });

      pc.onicecandidate = async (event) => {
        if (event.candidate && streamIdRef.current && sessionIdRef.current) {
          await fetch(`${apiUrl}/talks/streams/${streamIdRef.current}/ice`, {
            method: 'POST',
            headers: {
              'Authorization': `Basic ${encodedKey}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              candidate: event.candidate.candidate,
              sdpMid: event.candidate.sdpMid,
              sdpMLineIndex: event.candidate.sdpMLineIndex,
              session_id: sessionIdRef.current
            })
          });
        }
      };

      pc.ontrack = (event) => {
        if (videoRef.current && event.streams[0]) {
          videoRef.current.srcObject = event.streams[0];
        }
      };

      await pc.setRemoteDescription(offer);
      const answer = await pc.createAnswer();
      await pc.setLocalDescription(answer);

      const sdpResponse = await fetch(`${apiUrl}/talks/streams/${streamIdRef.current}/sdp`, {
        method: 'POST',
        headers: {
          'Authorization': `Basic ${encodedKey}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          answer,
          session_id: sessionIdRef.current
        })
      });

      if (!sdpResponse.ok) {
        throw new Error('Failed to set SDP');
      }

      peerConnectionRef.current = pc;
      updateStatus('connected');
      console.log('Connection established successfully');
      
      if (statusChangeCallback) {
        statusChangeCallback('connected');
      }
    } catch (error) {
      console.error('Error test 2:', error);
      updateStatus('error');
      if (statusChangeCallback) {
        statusChangeCallback('error');
      }
      throw error;
    }
  }, [apiKey, apiUrl, sourceUrl, statusChangeCallback]);

  const handleTalk = useCallback(async (message: string) => {
    if (!message.trim() || status !== 'connected' || !streamIdRef.current || !sessionIdRef.current) return;

    try {
      const encodedKey = !apiKey.includes(':') ? apiKey : btoa(apiKey);
      const talkRequest: TalkRequest = {
        script: {
          type: 'text',
          input: message,
          provider: {
            type: 'elevenlabs',
            voice_id: 'EXAVITQu4vr4xnSDxMaL',
            voice_config: {
              stability: 0.75,
              similarity_boost: 0.65,
              style: 0,
              use_speaker_boost: true,
              pitch: 0
            }
          }
        },
        config: {
          stitch: true,
          fluent: true,
          driver_expressions: {
            expressions: ['neutral'],
            transition_frames: 4
          }
        },
        session_id: sessionIdRef.current
      };

      const response = await fetch(`${apiUrl}/talks/streams/${streamIdRef.current}`, {
        method: 'POST',
        headers: {
          'Authorization': `Basic ${encodedKey}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(talkRequest)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || response.statusText);
      }
    } catch (error) {
      console.error('Error test:', error);
      throw error;
    }
  }, [apiKey, apiUrl, pitch, status]);

  useEffect(() => {
    const currentPeerConnection = peerConnectionRef.current;
    return () => {
      if (currentPeerConnection) {
        currentPeerConnection.close();
      }
    };
  }, []);

  useImperativeHandle(ref, () => ({
    connect: handleConnect,
    getStatus: () => status,
    setStatusChangeCallback: (callback: (status: ConnectionStatus) => void) => {
      console.log('Setting status change callback');
      setStatusChangeCallback(() => callback);
    },
    talk: handleTalk
  }));

  const handleErrorMessageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage(e.target.value);
  };

  const handleTestTalk = () => {
    if (errorMessage.trim()) {
      handleTalk(errorMessage);
    }
  };

  return (
    <div style={{
      backgroundImage: status !== 'connected' ? `url(${window.location.origin}${avatar3d})` : 'none',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      width: '100%',
      height: '200px'
    }}>
      <video
        ref={videoRef}
        autoPlay
        playsInline
        muted={false}
        style={{ 
          width: '100%', 
          height: '200px', 
          backgroundColor: '#f0f0f0',
          visibility: status === 'connected' ? 'visible' : 'hidden' 
        }}
      />
      {status === 'error' && (
        <>
          <div style={{ marginTop: '0rem' }}>
            <button
              onClick={handleConnect}
              style={{
                padding: '0.1rem 1rem',
                backgroundColor: '#007bff',
                color: 'white',
                border: 'none',
                borderRadius: '4px'
              }}
            >
              Reconnect
            </button>
            <span style={{ marginLeft: '0rem' }}>Status: Connection Error</span>
          </div>
        </>
      )}

      {status === 'error' && (
        <div style={{ display: 'flex', gap: '0.1rem' }}>
        <input
          type="text"
          value={errorMessage}
          onChange={handleErrorMessageChange}
          placeholder="Test message..."
          style={{ flex: 0, padding: '0.0rem', width: '150px' }}
        />
        <button
          onClick={handleTestTalk}
          disabled={!errorMessage.trim()}
          style={{
            padding: '0.5rem 1rem',
            backgroundColor: !errorMessage.trim() ? '#ccc' : '#28a745',
            color: 'white',
            border: 'none',
            borderRadius: '4px'
          }}
        >
          Talk
        </button>
      </div>
      )}
    </div>
  );
});

export default DIDAvatar;

/* @v0.1 init
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { DIDProps, TalkRequest } from '../../../types/did';

const DIDAvatar: React.FC<DIDProps> = ({ apiKey, apiUrl, sourceUrl }) => {
  const [status, setStatus] = useState<'disconnected' | 'connecting' | 'connected' | 'error'>('disconnected');
  const [message, setMessage] = useState<string>('');
  const [pitch, setPitch] = useState<number>(1.0);
  
  const videoRef = useRef<HTMLVideoElement>(null);
  const peerConnectionRef = useRef<RTCPeerConnection | null>(null);
  const streamIdRef = useRef<string | null>(null);
  const sessionIdRef = useRef<string | null>(null);

  const handleConnect = useCallback(async () => {
    try {
      setStatus('connecting');
      const encodedKey = !apiKey.includes(':') ? apiKey : btoa(apiKey);

      const streamResponse = await fetch(`${apiUrl}/talks/streams`, {
        method: 'POST',
        headers: {
          'Authorization': `Basic ${encodedKey}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          source_url: sourceUrl
        })
      });

      const { id, offer, ice_servers, session_id } = await streamResponse.json();
      streamIdRef.current = id;
      sessionIdRef.current = session_id;

      const pc = new RTCPeerConnection({ 
        iceServers: ice_servers.slice(0, 4)
      });

      pc.onicecandidate = async (event) => {
        if (event.candidate && streamIdRef.current && sessionIdRef.current) {
          await fetch(`${apiUrl}/talks/streams/${streamIdRef.current}/ice`, {
            method: 'POST',
            headers: {
              'Authorization': `Basic ${encodedKey}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              candidate: event.candidate.candidate,
              sdpMid: event.candidate.sdpMid,
              sdpMLineIndex: event.candidate.sdpMLineIndex,
              session_id: sessionIdRef.current
            })
          });
        }
      };

      pc.ontrack = (event) => {
        if (videoRef.current && event.streams[0]) {
          videoRef.current.srcObject = event.streams[0];
        }
      };

      await pc.setRemoteDescription(offer);
      const answer = await pc.createAnswer();
      await pc.setLocalDescription(answer);

      await fetch(`${apiUrl}/talks/streams/${streamIdRef.current}/sdp`, {
        method: 'POST',
        headers: {
          'Authorization': `Basic ${encodedKey}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          answer,
          session_id: sessionIdRef.current
        })
      });

      peerConnectionRef.current = pc;
      setStatus('connected');
    } catch (error) {
      console.error('Error:', error);
      setStatus('error');
      alert(`Error de conexión: ${error instanceof Error ? error.message : 'Desconocido'}`);
    }
  }, [apiKey, apiUrl, sourceUrl]);

  const handleTalk = useCallback(async () => {
    if (!message.trim() || status !== 'connected' || !streamIdRef.current || !sessionIdRef.current) return;

    try {
      const encodedKey = !apiKey.includes(':') ? apiKey : btoa(apiKey);
      
      const talkRequest: TalkRequest = {
        script: {
          type: 'text',
          input: message,
          provider: {
            type: 'elevenlabs',
            voice_id: 'ErXwobaYiN019PkySvjV',
            voice_config: {
              stability: 0.5,
              similarity_boost: 0.75,
              style: 0,
              use_speaker_boost: true,
              pitch
            }
          }
        },
        config: {
          stitch: true,
          fluent: true,
          driver_expressions: {
            expressions: ['happy', 'neutral'],
            transition_frames: 0
          }
        },
        session_id: sessionIdRef.current
      };

      const response = await fetch(`${apiUrl}/talks/streams/${streamIdRef.current}`, {
        method: 'POST',
        headers: {
          'Authorization': `Basic ${encodedKey}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(talkRequest)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || response.statusText);
      }

      setMessage('');
    } catch (error) {
      console.error('Error:', error);
      alert(`Error al enviar mensaje: ${error instanceof Error ? error.message : 'Desconocido'}`);
    }
  }, [apiKey, apiUrl, message, pitch, status]);

  useEffect(() => {
    const currentPeerConnection = peerConnectionRef.current;
    return () => {
      if (currentPeerConnection) {
        currentPeerConnection.close();
      }
    };
  }, []);

  return (
    <div>
      <video
        ref={videoRef}
        autoPlay
        playsInline
        muted={false}
        style={{ width: '100%', height: '200px', backgroundColor: '#f0f0f0' }}
      />
      <div style={{ marginTop: '0rem' }}>
        <button
          onClick={handleConnect}
          disabled={status === 'connected'}
          style={{
            padding: '0.1rem 1rem',
            backgroundColor: status === 'connected' ? '#ccc' : '#007bff',
            color: 'white',
            border: 'none',
            borderRadius: '4px'
          }}
        >
          {status === 'connecting' ? 'Connecting...' : 'Connect'}
        </button>
        <span style={{ marginLeft: '0rem' }}>Status: {status}</span>
      </div>

      {status === 'connected' && (
        <div style={{ marginTop: '0rem' }}>
          <div style={{ display: 'flex', gap: '0.1rem' }}>
            <input
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Test message..."
              style={{ flex: 0, padding: '0.0rem', width: '150px' }}
            />
            <button
              onClick={handleTalk}
              disabled={!message.trim()}
              style={{
                padding: '0.5rem 1rem',
                backgroundColor: !message.trim() ? '#ccc' : '#28a745',
                color: 'white',
                border: 'none',
                borderRadius: '4px'
              }}
            >
              Talk
            </button>
          </div>
          
          {/*<div style={{ marginTop: '1rem' }}>
            <label>
              Tono de voz (pitch): {pitch}
              <input
                type="range"
                min="0.5"
                max="2.0"
                step="0.1"
                value={pitch}
                onChange={(e) => setPitch(parseFloat(e.target.value))}
                style={{ width: '100%', marginTop: '0.5rem' }}
              />
            </label>
            <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '0.8rem' }}>
              <span>Grave (0.5)</span>
              <span>Normal (1.0)</span>
              <span>Agudo (2.0)</span>
            </div>
          </div>*--}
        </div>
      )}
    </div>
  );
};

export default DIDAvatar;
*/