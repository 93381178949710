import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Form, Dropdown, DropdownMenu, ButtonGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AnubisConversation from 'components/common/hazbot/AnubisConversation';
import AnubisFooter from 'components/common/hazbot/AnubisFooter';
import { useAppContext } from 'providers/AppProvider';
import { useChatWidgetContext } from 'providers/HazbotWidgetProvider';
import { faCircle, faDiceD20, faDownload, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import { BotService } from 'service/botService';
import { Button } from 'react-bootstrap';
import { useUserContext } from 'layouts/UserContext';
import { UserService } from 'service/userService';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';

const MainAnubis = () => {
  const { user, isLoading, refetchUser, isAuthenticated } = useUserContext();

  if (isLoading || !user) {
    return <div>Loading profile, please wait...</div>;
  }

  const { 
    selectedNeuroskill, 
    setSelectedNeuroskill, 
    selectedModule, 
    setSelectedModule,
    conversation, 
    setConversation
  } = useChatWidgetContext();

  const [botStatus, setBotStatus] = useState({ status: "Unavailable", cssClass: "text-danger" });
  const [modules, setModules] = useState<string[]>([]);

  const neuroskillDisplayNames: Record<string, string> = {
    'general_v1': 'General',
    'dideco_v1': 'Dideco',
    'finance_v1': 'Finance',
    'medical_v1': 'Medical',
    'hazmat_v1': 'Hazmat'
  };

  const moduleDisplayNames: Record<string, string> = {
    'general_general': 'General',
    'general_farmaco': 'Farmacología',

    'dideco_general': 'Dideco General',
    'dideco_migracion': 'Migración',

    'general_finance': 'Finanzas y Economía',

    'medical_advanced_trauma': 'Advanced Trauma',
    'medical_emergency_cardiovascular': 'Manejo de Desastres y Respuesta en Masa',
    'medical_emergency_geriatric': 'Emergencias Geriátricas',
    'medical_emergency_neurological': 'Quemaduras y Lesiones Térmicas',
    'medical_emergency_pediatric': 'Soporte Vital Básico y Avanzado',
    'medical_emergency_respiratory': 'Toxicología',
    'medical_bioagent_exposition': 'Exposición a Agentes Biológicos',
    'medical_radiation_expose': 'Exposición a Radiación',
    'medical_burn_thermical': 'Quemaduras y Lesiones Térmicas',
    'medical_vital_basic_advanced_support': 'Soporte Vital Básico y Avanzado',
    'medical_prehospital_support': 'Trauma y Soporte Prehospitalario',
    'medical_toxicology': 'Toxicología',
    'medical_fracture': 'Fracturas',
    'medical_disaster_and_bulk_answer': 'Manejo de Desastres y Respuesta en Masa',
    'medical_chemical_trauma': 'Trauma Químico',

    'hazmat_general': 'Basic Hazmat',
    'chemical_properties': 'Chemical Properties',
    'compliance_and_standards': 'Compliance and Standards',
    'decontamination': 'Decontamination',
    'equipment_selection': 'Equipment Selection',
    'hazmat_response': 'Hazmat Response',
    'marine_emergencies': 'Marine Emergencies',
    'risk_assessment': 'Risk Assessment',
    'scenario_based_calculations': 'Scenario-based Calculations',
    'spill_control': 'Spill Control',
    'transport_and_storage': 'Transport and Storage',
  };

  const [neuroskills, setNeuroskills] = useState<string[]>(['general_v1', 'dideco_v1', 'finance_v1', 'medical_v1', 'hazmat_v1']);
  const neuroskillModules: Record<string, string[]> = {
    'general_v1': ['general_general', 'general_farmaco'],
    'dideco_v1': ['dideco_general', 'dideco_migracion'],
    'finance_v1': ['general_finance'],
    'medical_v1': [
      'medical_advanced_trauma',
      'medical_emergency_cardiovascular',
      'medical_emergency_geriatric',
      'medical_emergency_neurological',
      'medical_emergency_pediatric',
      'medical_emergency_respiratory',
      'medical_bioagent_exposition',
      'medical_radiation_expose',
      'medical_burn_thermical',
      'medical_vital_basic_advanced_support',
      'medical_prehospital_support',
      'medical_toxicology',
      'medical_fracture',
      'medical_disaster_and_bulk_answer',
      'medical_chemical_trauma'
    ],
    'hazmat_v1': [
      'hazmat_general',
      'chemical_properties',
      'compliance_and_standards',
      'decontamination',
      'equipment_selection',
      'hazmat_response',
      'marine_emergencies',
      'risk_assessment',
      'scenario_based_calculations',
      'spill_control',
      'transport_and_storage'
    ]
  };

  useEffect(() => {
    const fetchBotStatus = async () => {
      const status = await BotService.getStatus();
      setBotStatus(status);
    };

    fetchBotStatus();
    
    const intervalId = setInterval(fetchBotStatus, 30000);

    return () => clearInterval(intervalId);
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    const fckingKillThem = async () => {
      UserService.logout();

      await refetchUser();

      navigate('/pages/authentication/split/Sign-in');
    };

    const checkUser = async () => {
      try {
        if (!isAuthenticated) {
          console.error("User is not authenticated, not checking user details.");
          return;
        }
        const user = await UserService.getUser();
      } catch (error) {
        const axiosError = error as AxiosError;
    
        if (!axiosError.response) {
          console.error("Error de red detectado.");
          return;
        }
    
        if (axiosError.response.status === 401) {
          fckingKillThem();
        } else {
          fckingKillThem();
          console.error("Error del servidor:", axiosError.response.status, axiosError.response.data);
        }
      }
    };

    checkUser();
    const intervalId = setInterval(checkUser, 30000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (selectedNeuroskill in neuroskillModules) {
      const newModules = neuroskillModules[selectedNeuroskill];
      setModules(newModules);
      
      // Check if current selectedModule is valid for the new neuroskill
      if (!newModules.includes(selectedModule)) {
        setSelectedModule(newModules[0]);
      }
    } else {
      setModules([]);
      setSelectedModule(''); // Clear selected module if no valid neuroskill
    }
  }, [selectedNeuroskill, selectedModule, setSelectedModule]);

  const clearChat = () => {
    setConversation({ ...conversation, messages: [] });
  };

//Formateo de fecha para registro y nombre del archivo y wea
  const formatDate = (date: Date): string => {
    if (!(date instanceof Date) || isNaN(date.getTime())) {
      date = new Date();
    }
  
    const pad = (num: number): string => num.toString().padStart(2, '0');
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());
    
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };
  
  const parseDate = (time?: string | number | Date): Date => {
    try {
      if (!time) return new Date();
      if (time instanceof Date) {
        return isNaN(time.getTime()) ? new Date() : time;
      }
      if (typeof time === 'number') {
        const date = new Date(time);
        return isNaN(date.getTime()) ? new Date() : date;
      }
      const date = new Date(time);
      return isNaN(date.getTime()) ? new Date() : date;
    } catch (error) {
      return new Date();
    }
  };
  
  // Función para exportar a TXT
  const exportToTxt = () => {
    const text = conversation.messages
      .map(msg => {
        const msgAuthor = msg.type === 'sent' ?  user.name + ' ' + user.lastName : `${process.env.REACT_APP_NAME}`;
        const timestamp = formatDate(parseDate(msg.time));
        return `[${timestamp}] ${msgAuthor}: ${msg.message}`;
      })
      .join('\n\n');
  
    const blob = new Blob([text], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    const fileDate = formatDate(new Date());
    a.download = `${process.env.REACT_APP_NAME}-${process.env.REACT_APP_TITLE}_chatsession-${fileDate}.txt`;
    a.href = url;
    a.click();
    URL.revokeObjectURL(url);
  };

  // Función para exportar a JSON
  const exportToJson = () => {
    const messages = conversation.messages.map(msg => {
      const timestamp = formatDate(parseDate(msg.time));
      return {
        timestamp: timestamp,
        sender: (msg.type === 'sent' ? user.name + ' ' + user.lastName : `${process.env.REACT_APP_NAME}`),
        content: msg.message
      };
    });

    const data = JSON.stringify(messages, null, 2);
    const blob = new Blob([data], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    const fileDate = formatDate(new Date());
    a.href = url;
    a.download = `${process.env.REACT_APP_NAME}-${process.env.REACT_APP_TITLE}_chatsession-${fileDate}.json`;
    a.click();
    URL.revokeObjectURL(url);
  };

  // Función para exportar a CSV
  const exportToCsv = () => {
    const headers = ['sender', 'content', 'timestamp'];
    const csvRows = [headers];
    
    conversation.messages.forEach(msg => {
      if (!msg.message) return;
      // remap aqui tmb
      const msgAuthor = msg.type === 'sent' ? user.name + ' ' + user.lastName : `${process.env.REACT_APP_NAME}`;
      const timestamp = formatDate(parseDate(msg.time));
      csvRows.push([
        msgAuthor,
        `"${msg.message.replace(/"/g, '""')}"`,
        timestamp
      ]);
    });

    const csvContent = csvRows.map(row => row.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    const fileDate = formatDate(new Date());
    a.href = url;
    a.download = `${process.env.REACT_APP_NAME}-${process.env.REACT_APP_TITLE}_chatsession-${fileDate}.csv`;
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <>
      <Row className="g-2 mb-1">
        <Col xs={6} className='bt-1'>
          <Form.Group>
            <Form.Label>Select Neuroskill:</Form.Label>
              <Form.Control 
                as="select" 
                value={selectedNeuroskill}  
                onChange={(e) => setSelectedNeuroskill(e.target.value)}
              >
                {Array.isArray(neuroskills) && neuroskills.length > 0 ? (
                  neuroskills.map((skill) => (
                    <option key={skill} value={skill}>
                      {neuroskillDisplayNames[skill] || skill}
                    </option>
                  ))
                ) : (
                  <option>No neuroskills available</option>
                )}
              </Form.Control>
          </Form.Group>
        </Col>
        <Col xs={6} className='bt-1'>
          <Form.Group>
            <Form.Label>Select Module:</Form.Label>
            <Form.Control 
              as="select" 
              value={selectedModule} 
              onChange={(e) => setSelectedModule(e.target.value)}
            >
              {modules.map((module) => (
                <option key={module} value={module}>
                  {moduleDisplayNames[module] || module}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>

      <Row className="g-1">
        <Col xs={12} xxl={12} xg={12} xl={12}>
          <Card className="bg-body-emphasis chat"> {/* height:'200%'}}> */}
            <Card.Header className="d-flex flex-between-center px-4 py-2 border-bottom">
              <h6 className="mb-0 d-flex align-items-center gap-2">
                {process.env.REACT_APP_NAME} is {botStatus.status}
                <FontAwesomeIcon icon={faCircle} className={`${botStatus.cssClass} fs-11`} />
                <div>{/* contador tokens aca */}</div>
              </h6>
              <div className="d-flex align-items-center gap-2">
                <Button variant="phoenix-secondary" className="p-0 border-0" title="Reset Chat" onClick={clearChat} style={{ "--phoenix-btn-bg": "unset" } as React.CSSProperties} 
                >
                  <label
                    className="text-body-quaternary fs-9 cursor-pointer"
                    htmlFor="widgetImages"
                  >
                    <FontAwesomeIcon icon={faTrash} transform="down-1" size='lg' />
                  </label>
                </Button>

                <Dropdown as={ButtonGroup} className="p-0 border-0" title="Export Chat"
                >
                  <Dropdown.Toggle split variant="phoenix-secondary" as={Button} className="p-0 border-0" style={{ "--phoenix-btn-bg": "none" } as React.CSSProperties}>
                    <FontAwesomeIcon 
                      icon={faDownload} 
                      className="text-body-quaternary fs-7"
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={exportToTxt}>
                      Export as TXT
                    </Dropdown.Item>
                    <Dropdown.Item onClick={exportToJson}>
                      Export as JSON
                    </Dropdown.Item>
                    <Dropdown.Item onClick={exportToCsv}>
                      Export as CSV
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

            </Card.Header>
            <Card.Body className="scrollbar p-2">
              <AnubisConversation />
            </Card.Body>
            <Card.Footer className="border-top ps-1 pe-4 py-2">
              <AnubisFooter />
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default MainAnubis;
