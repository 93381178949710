import React, { useState, ChangeEvent } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { Link } from 'react-router-dom';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import Button from 'components/base/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport, faPlus } from '@fortawesome/free-solid-svg-icons';
import SearchBox from 'components/common/SearchBox';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import ReactSelect from 'components/base/ReactSelect';
import { useUserContext } from 'layouts/UserContext';
import { IUser, IChildRegistration } from 'data/models/user/IUser';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { UserService } from 'service/userService';

const columns: ColumnDef<IUser>[] = [
  {
    accessorKey: 'name',
    header: 'System user',
    cell: ({ row: { original } }) => (
      <Link to="#" className="text-decoration-none fw-bold fs-9">
        {original.email}
      </Link>
    )
  },
  {
    header: 'Register date',
    accessorKey: 'createdAt'
  },
  {
    header: 'Last Login',
    accessorKey: 'updatedAt'
  }
];

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  lastName: Yup.string().required(),
  company: Yup.string().required(),
  email: Yup.string().email().required(),
  password: Yup.string().min(8).required(),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('Confirm password is required'),
  phone: Yup.string().min(5).required(),
  tokens: Yup.number().min(500).max(25000).required(),
  isAdmin: Yup.boolean().default(false).required()
});

type FormData = Yup.InferType<typeof validationSchema>;

const UsersTokensDashboardTable = () => {
  const { user } = useUserContext();
  const table = useAdvanceTable({
    data: user?.children as IUser[],
    columns,
    pageSize: 6,
    pagination: true,
    sortable: true
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    reset
  } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: '',
      lastName: '',
      company: user?.company || '',
      email: '',
      password: '',
      confirmPassword: '',
      phone: '',
      tokens: 500,
      isAdmin: false
    }
  });
  
  const onSubmit = async (data: FormData) => {
    setIsLoading(true);

    try {
      const ipInfo = "0.0.0.0"; //await UserService.userIp();

      const registrationData: IChildRegistration = {
        ...data,
        address: ipInfo,
      };

      await UserService.registerChild(registrationData);

      reset();
      handleClose();

      alert("Registration successful");
    } catch (error: any) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onError = (errors: any) => {
    console.error("Form errors:", errors);
  };

  return (
    <div>
      <Row>
        <div className="mb-4">
          <Row className="g-3">
            <Col xs="auto">
              <SearchBox placeholder="Search members" onChange={handleSearchInputChange} />
            </Col>
            <Col xs="auto" className="scrollbar overflow-hidden-y flex-grow-1"></Col>
            <Col xs="auto">
              <Button disabled variant="link" className="text-body me-4 px-0">
                <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" /> Export
              </Button>
              <Button variant="primary" onClick={handleShow}>
                <FontAwesomeIcon icon={faPlus} className="me-2" /> Add member
              </Button>
            </Col>
          </Row>
        </div>
      </Row>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Register Child Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="email">
                <Form.Label>Email</Form.Label>
                <Controller name="email" control={control} render={({ field }) => <Form.Control {...field} type="email" isInvalid={!!errors.email} />} />
              </Form.Group>

              <Form.Group as={Col} controlId="password">
                <Form.Label>Password</Form.Label>
                <Controller name="password" control={control} render={({ field }) => <Form.Control {...field} type="password" isInvalid={!!errors.password} />} />
              </Form.Group>

              <Form.Group as={Col} controlId="confirmPassword">
                <Form.Label>Confirm Password</Form.Label>
                <Controller name="confirmPassword" control={control} render={({ field }) => <Form.Control {...field} type="password" isInvalid={!!errors.confirmPassword} />} />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="name">
                <Form.Label>Name</Form.Label>
                <Controller name="name" control={control} render={({ field }) => <Form.Control {...field} isInvalid={!!errors.name} />} />
              </Form.Group>

              <Form.Group as={Col} controlId="lastName">
                <Form.Label>Last Name</Form.Label>
                <Controller name="lastName" control={control} render={({ field }) => <Form.Control {...field} isInvalid={!!errors.lastName} />} />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="phone">
                <Form.Label>Phone</Form.Label>
                <Controller name="phone" control={control} render={({ field }) => <Form.Control {...field} isInvalid={!!errors.phone} />} />
              </Form.Group>

{ /*<Form.Group as={Col} controlId="apps">
  <Form.Label>Authorized Apps</Form.Label>
  <Controller
    name="apps"
    control={control}
    render={({ field }) => (
      <ReactSelect
        {...field}
        options={[
          { value: 'AIDetect', label: 'AI Text & Documents' },
          { value: 'XGis', label: 'XGis' },
          { value: 'WhatsApp', label: 'WhatsApp' }
        ]}
        isMulti
        placeholder="Select apps..."
        onChange={(selected) => {
          const selectedOptions = selected as { value: string; label: string }[];
          field.onChange(selectedOptions.map((option) => option.value));
        }}
      />
    )}
  />
</Form.Group>

<Form.Group as={Col} controlId="neuroSkills">
  <Form.Label>Neuroskills</Form.Label>
  <Controller
    name="neuroSkills"
    control={control}
    render={({ field }) => (
      <ReactSelect
        {...field}
        options={[
          { value: 'MIT', label: 'MIT' },
          { value: 'Chicago', label: 'University of Chicago' },
          { value: 'Harvard', label: 'Harvard' }
        ]}
        isMulti
        placeholder="Select neuroskills..."
        onChange={(selected) => {
          const selectedOptions = selected as { value: string; label: string }[];
          field.onChange(selectedOptions.map((option) => option.value));
        }}
      />
    )}
  />
</Form.Group> */ }

              <Form.Group as={Col} controlId="tokens">
                <Form.Label>Tokens</Form.Label>
                <Controller name="tokens" control={control} render={({ field }) => <Form.Control {...field} type="number" isInvalid={!!errors.tokens} />} />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="company">
                <Form.Label>Company</Form.Label>
                <Controller
                  name="company"
                  control={control}
                  render={({ field }) => (
                    <Form.Control {...field} readOnly isInvalid={!!errors.company} />
                  )}
                />
              </Form.Group>
            </Row>

            <Form.Group className="mb-3" controlId="isAdmin">
              <Controller
                name="isAdmin"
                control={control}
                render={({ field: { onChange, value, ...field } }) => (
                  <Form.Check 
                    {...field}
                    type="checkbox"
                    label="Mark as admin"
                    checked={value}
                    onChange={(e) => onChange(e.target.checked)}
                  />
                )}
              />
            </Form.Group>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>Close</Button>
              <Button variant="primary" type="submit" disabled={isSubmitting || isLoading}>
                {isLoading ? 'Registering...' : 'Register'}
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>

      <AdvanceTableProvider {...table}>
        <AdvanceTable tableProps={{ className: 'phoenix-table' }} />
        <AdvanceTableFooter pagination />
      </AdvanceTableProvider>
    </div>
  );
};

export default UsersTokensDashboardTable;
