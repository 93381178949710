// ~/ANUBISBOTv0.1/src/service/ircService.ts
import { io, Socket } from 'socket.io-client';

// Determine the correct backend URL based on environment
const getBackendUrl = () => {
  const currentUrl = window.location.href;
  console.log('Current browser URL:', currentUrl);
  
  // Local development - the backend is likely running on localhost
  if (currentUrl.includes('localhost') || currentUrl.includes('127.0.0.1')) {
    return 'http://localhost:6666/api';
  }
  
  // Production environment - use the actual backend domain
  // Important: Match the protocol (http/https)
  const isSecure = window.location.protocol === 'https:';
  
  if (isSecure) {
    return 'https://backend.anubisai.net/api';
  } else {
    // For HTTP environments
    return 'https://backend.anubisai.net/api';
  }
};

// Set the API URL
const API_URL = getBackendUrl();
const API_URL_BK = 'https://backend.anubisai.net/api'
console.log('Using API URL:', API_URL);

export interface ConnectionStatus {
  active: boolean;
  nickname?: string;
  channels?: string[];
  autoResponse?: boolean;
}

export interface IRCConfig {
  server: string;
  port: number;
  nickname: string;
  channels?: string[];
  useSSL?: boolean;
  password?: string;
  alternativeNicks?: string[];
}

export interface IRCMessage {
  id?: number | string;
  //type: string;
  type: 'message' | 'command' | 'system' | 'query' | string;
  from?: string;
  sender?: string;
  channel?: string;
  text: string;
  timestamp: string;
  source?: 'user' | 'irc' | 'ml' | 'api' | 'fallback'; 
}

class MyBrainService {
  private socket: Socket | null = null;
  private _connectionId: string | null = null;
  private messageListeners: ((message: IRCMessage) => void)[] = [];
  private eventListeners: ((event: IRCMessage) => void)[] = [];
  private errorListeners: ((error: IRCMessage) => void)[] = [];
  private connectionListeners: ((connected: boolean) => void)[] = [];
  private lastPing: number = Date.now();
  private pingInterval: NodeJS.Timeout | null = null;
  private connectionAttempts: number = 0;
  private wasEverConnected: boolean = false;
  private messageBuffer: Map<string, IRCMessage[]> = new Map();
  private messageCompleteTimeout: number = 900; // milisegundos para considerar que un mensaje está completo
  private messageTimeouts: Map<string, NodeJS.Timeout> = new Map();

  get connectionId(): string | null {
    return this._connectionId;
  }

  public async checkConnection(connectionId: string): Promise<ConnectionStatus> {
    try {
      console.log(`Checking connection status for ID: ${connectionId}`);
      const response = await fetch(`${API_URL_BK}/mybrain/status/${connectionId}`);
      const data = await response.json();
      console.log('Connection status:', data);
      return {
        active: data.active,
        nickname: data.nickname,
        channels: data.channels
      };
    } catch (error) {
      console.error('Error checking connection status:', error);
      // Si hay un error de red o el servidor no responde, asumimos que la conexión no está activa
      return { active: false };
    }
  }

  constructor() {
    // Initialize ping mechanism
    this.setupPing();
    
    // Handle page visibility changes to manage connection
    if (typeof document !== 'undefined') {
      document.addEventListener('visibilitychange', this.handleVisibilityChange);
    }
    
    console.log('IRC Service initialized');
  }

  private handleVisibilityChange = () => {
    if (document.visibilityState === 'visible' && this._connectionId) {
      console.log('Page became visible, checking connection...');
      this.checkConnection(this._connectionId)
        .then(status => {
          if (!status.active && this.wasEverConnected) {
            console.log('Connection lost while page was hidden, reconnecting...');
            this.setupSocket();
          }
        })
        .catch(console.error);
    }
  }

  private setupPing() {
    if (this.pingInterval) {
      clearInterval(this.pingInterval);
    }
    
    this.pingInterval = setInterval(() => {
      if (this.socket && this._connectionId) {
        if (Date.now() - this.lastPing > 30000) {
          console.log('Ping timeout detected, reconnecting socket...');
          
          // Don't disconnect immediately, try to reconnect first
          if (this.socket.disconnected) {
            console.log('Socket already disconnected, reconnecting...');
            this.socket.connect();
          } else {
            console.log('Sending ping to check connection');
            this.socket.emit('ping');
          }
          
          // If still no response after 5 more seconds, do a full reconnect
          setTimeout(() => {
            if (Date.now() - this.lastPing > 35000) {
              console.log('Connection still dead after ping, doing full reset');
              if (this.socket) {
                this.socket.disconnect();
                this.socket = null;
              }
              this.setupSocket();
              
              // Notify listeners about connection loss
              this.notifyConnectionListeners(false);
            }
          }, 5000);
        } else {
          // Regular ping
          this.socket.emit('ping');
          console.log('Sent ping');
        }
      }
    }, 15000);
  }

  public async connect(config: IRCConfig): Promise<string> {
    this.connectionAttempts = 0;
    
    try {
      console.log(`Connecting to IRC server: ${config.server}:${config.port}`);
      const response = await fetch(`${API_URL}/mybrain/connect`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(config)
      });

      const data = await response.json();
      console.log('Connection response:', data);

      if (data.success) {
        this._connectionId = data.connectionId;
        this.setupSocket();
        this.notifyConnectionListeners(true);
        this.lastPing = Date.now();
        this.wasEverConnected = true;
        return data.connectionId;
      } else {
        throw new Error(data.error || 'Error desconocido en la conexión');
      }
    } catch (error: any) {
      console.error('Error connecting to IRC:', error);
      this.notifyErrorListeners({
        type: 'error',
        text: error.message || 'Error de conexión',
        timestamp: new Date().toISOString()
      });
      throw error;
    }
  }

  public async reconnectWithAlternativeNick(config: IRCConfig, attemptNumber: number = 0): Promise<string> {
    try {
      this.connectionAttempts++;
      console.log(`Reconnection attempt #${this.connectionAttempts} with alternative nick`);
      
      // Create a copy of the config to modify
      const reconnectConfig = { ...config };
      
      // If this is not the first attempt, use an alternative nickname
      if (attemptNumber > 0 && config.alternativeNicks && config.alternativeNicks.length > 0) {
        // Choose an alternative nickname based on the attempt number
        const nicknameIndex = (attemptNumber - 1) % (config.alternativeNicks.length || 1);
        reconnectConfig.nickname = config.alternativeNicks[nicknameIndex];
        
        // Add a timestamp to make it even more unique if needed
        if (attemptNumber > config.alternativeNicks.length) {
          reconnectConfig.nickname += '_' + Date.now().toString().slice(-4);
        }
        
        console.log(`Using alternative nickname: ${reconnectConfig.nickname}`);
      }
      
      // Try to disconnect any existing connection first (ignore errors)
      if (this._connectionId) {
        try {
          console.log('Disconnecting existing connection before reconnect');
          await this.disconnect();
        } catch (error) {
          console.warn('Error disconnecting before reconnect', error);
        }
      }
      
      // Now try to connect with the potentially modified config
      const connectionId = await this.connect(reconnectConfig);
      console.log(`Reconnected successfully with ID: ${connectionId}`);
      return connectionId;
    } catch (error: any) {
      console.error('Error during reconnection:', error);
      
      // If we're out of alternative nicks or too many attempts, throw the error
      if (!config.alternativeNicks || attemptNumber >= config.alternativeNicks.length + 3 || this.connectionAttempts > 5) {
        throw error;
      }
      
      // Otherwise, retry with the next alternative nickname
      console.log(`Retrying with next alternative nickname, attempt #${attemptNumber + 1}`);
      return this.reconnectWithAlternativeNick(config, attemptNumber + 1);
    }
  }

// Reemplaza el método disconnect en tu frontend (ircService.ts)
public async disconnect(): Promise<boolean> {
  if (!this._connectionId) return false;

  try {
    console.log(`Disconnecting IRC connection: ${this._connectionId}`);
    
    // Guardar una copia del connectionId para referencia
    const connectionIdToDisconnect = this._connectionId;
    
    // 1. Primero realizar limpieza local
    console.log("Realizando limpieza local primero");
    
    // Desconectar socket si existe
    if (this.socket) {
      try {
        this.socket.disconnect();
      } catch (socketError) {
        console.warn("Error al desconectar socket:", socketError);
      }
      this.socket = null;
    }
    
    // Limpiar ID de conexión localmente
    this._connectionId = null;
    
    // Notificar a los listeners sobre la desconexión
    this.notifyConnectionListeners(false);
    
    // 2. Luego intentar desconectar en el servidor (no bloqueante para la UI)
    try {
      console.log(`Enviando petición de desconexión al servidor para: ${connectionIdToDisconnect}`);
      const response = await fetch(`${API_URL}/mybrain/disconnect`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ connectionId: connectionIdToDisconnect }),
        // Timeout de 5 segundos para no bloquear indefinidamente
        signal: AbortSignal.timeout(5000)
      });

      // Analizar respuesta si está disponible
      try {
        const data = await response.json();
        if (data.success) {
          console.log("Servidor confirmó desconexión exitosa");
        } else {
          console.warn(`Servidor reportó error en desconexión: ${data.error || 'Error desconocido'}`);
        }
      } catch (parseError) {
        console.warn("No se pudo analizar respuesta del servidor:", parseError);
      }
    } catch (serverError) {
      // No dejar que un error del servidor afecte la experiencia del usuario
      console.error("Error en comunicación con el servidor durante desconexión:", serverError);
      // No revertimos el estado local, la desconexión ya se realizó localmente
    }
    
    console.log("Proceso de desconexión completado");
    return true;
  } catch (error: any) {
    console.error('Error general durante la desconexión:', error);
    this.notifyErrorListeners({
      type: 'error',
      text: error.message || 'Error al desconectar',
      timestamp: new Date().toISOString()
    });
    
    // Intentar limpiar recursos localmente de todas formas
    this._connectionId = null;
    this.socket = null;
    this.notifyConnectionListeners(false);
    
    return false;
  }
}

  public async sendMessage(channel: string, message: string): Promise<boolean> {
    if (!this._connectionId) return false;

    try {
      console.log(`Sending message to ${channel}: ${message}`);
      const response = await fetch(`${API_URL}/mybrain/send`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          connectionId: this._connectionId,
          channel,
          message
        })
      });

      const data = await response.json();
      return data.success;
    } catch (error: any) {
      console.error('Error sending message:', error);
      this.notifyErrorListeners({
        type: 'error',
        text: error.message || 'Error al enviar mensaje',
        timestamp: new Date().toISOString()
      });
      return false;
    }
  }

  public async getMessages(limit: number = 100): Promise<IRCMessage[]> {
    if (!this._connectionId) return [];

    try {
      console.log(`Fetching last ${limit} messages`);
      const response = await fetch(`${API_URL}/mybrain/messages/${this._connectionId}?limit=${limit}`);
      const data = await response.json();
      return data.messages || [];
    } catch (error) {
      console.error('Error getting messages:', error);
      return [];
    }
  }

  // Event handlers
  public onMessage(callback: (message: IRCMessage) => void): () => void {
    this.messageListeners.push(callback);
    return () => {
      this.messageListeners = this.messageListeners.filter(cb => cb !== callback);
    };
  }

  public onEvent(callback: (event: IRCMessage) => void): () => void {
    this.eventListeners.push(callback);
    return () => {
      this.eventListeners = this.eventListeners.filter(cb => cb !== callback);
    };
  }

  public onError(callback: (error: IRCMessage) => void): () => void {
    this.errorListeners.push(callback);
    return () => {
      this.errorListeners = this.errorListeners.filter(cb => cb !== callback);
    };
  }

  public onConnectionChange(callback: (connected: boolean) => void): () => void {
    this.connectionListeners.push(callback);
    return () => {
      this.connectionListeners = this.connectionListeners.filter(cb => cb !== callback);
    };
  }

  private setupSocket(): void {
    if (this.socket) {
      console.log('Disconnecting existing socket before setup');
      this.socket.disconnect();
    }

    // Get the base URL without the /api path
    const socketUrl = API_URL.replace('/api', '');
    console.log(`Setting up Socket.IO connection to: ${socketUrl}`);
    
    try {
      this.socket = io(socketUrl, {
        reconnection: true,
        reconnectionAttempts: 5,
        reconnectionDelay: 1000,
        // Critical: Use "polling" as the ONLY transport initially
        // This helps establish the first connection through CORS
        transports: ['polling'],
        // Then enable upgrade to websocket after connection
        upgrade: true,
        rememberUpgrade: true,
        forceNew: true,
        timeout: 20000,
        path: '/socket.io/',
        // Debug options
        autoConnect: true,
        withCredentials: false
      });
      
      console.log('Socket.IO instance created with initial polling transport');
      
      if (this._connectionId) {
        console.log(`Joining room: ${this._connectionId}`);
        this.socket.emit('join', this._connectionId);
      }
  
      this.socket.on('connect', () => {
        console.log(`Socket.IO connected successfully! ID: ${this.socket?.id}, Transport: ${this.socket?.io.engine.transport.name}`);
        this.lastPing = Date.now();
        
        if (this._connectionId) {
          console.log(`Re-joining room after connect: ${this._connectionId}`);
          this.socket?.emit('join', this._connectionId);
        }
      });
      
      // Monitor transport changes
      this.socket.io.engine.on('upgrade', (transport) => {
        console.log(`Socket.IO transport upgraded to: ${transport}`);
      });
      
      this.socket.on('connect_error', (error) => {
        console.error('Socket.IO connection error:', error);
        this.notifyErrorListeners({
          type: 'error',
          text: `Error de conexión WebSocket: ${error.message}`,
          timestamp: new Date().toISOString()
        });
      });
  
      this.socket.on('irc-message', (message: IRCMessage) => {
        console.log('Received IRC message:', message);
        this.lastPing = Date.now();
        this.notifyMessageListeners(message);
      });
  
      this.socket.on('irc-event', (event: IRCMessage) => {
        console.log('Received IRC event:', event);
        this.lastPing = Date.now();
        this.notifyEventListeners(event);
      });
  
      this.socket.on('irc-error', (error: IRCMessage) => {
        console.error('Received IRC error:', error);
        this.lastPing = Date.now();
        this.notifyErrorListeners(error);
      });
  
      this.socket.on('disconnect', (reason) => {
        console.warn(`Socket.IO disconnected. Reason: ${reason}`);
        this.notifyConnectionListeners(false);
      });
      
      this.socket.on('pong', () => {
        console.log('Received pong from server');
        this.lastPing = Date.now();
      });
      
      this.socket.on('error', (error) => {
        console.error('Socket error:', error);
      });
    } catch (error) {
      console.error('Error setting up socket:', error);
    }
  }

  private notifyMessageListeners(message: IRCMessage): void {
    // Genera una clave única para el mensaje basada en emisor, canal y timestamp aproximado
    const messageKey = `${message.from || message.sender || 'unknown'}-${message.channel || 'unknown'}-${Math.floor(Date.now() / 1000)}`;
    
    // Si no existe un buffer para esta clave, crea uno
    if (!this.messageBuffer.has(messageKey)) {
      this.messageBuffer.set(messageKey, []);
    }
    
    // Añade el fragmento al buffer
    const currentBuffer = this.messageBuffer.get(messageKey) || [];
    currentBuffer.push(message);
    this.messageBuffer.set(messageKey, currentBuffer);
    
    // Limpia cualquier timeout existente para esta clave
    if (this.messageTimeouts.has(messageKey)) {
      clearTimeout(this.messageTimeouts.get(messageKey));
    }
    
    // Establece un nuevo timeout para procesar este mensaje
    const timeoutId = setTimeout(() => {
      // Cuando se agota el tiempo, asumimos que tenemos todos los fragmentos
      if (this.messageBuffer.has(messageKey)) {
        const fragments = this.messageBuffer.get(messageKey) || [];
        
        // Combina todos los fragmentos en un solo mensaje
        if (fragments.length > 0) {
          const combinedMessage: IRCMessage = {
            ...fragments[0],
            text: fragments.map(frag => frag.text).join(''),
            // Preserva los metadatos del primer fragmento
            timestamp: fragments[0].timestamp
          };
          
          // Notifica a los listeners con el mensaje completo
          this.messageListeners.forEach(listener => listener(combinedMessage));
          
          // Limpia el buffer para esta clave
          this.messageBuffer.delete(messageKey);
        }
      }
      
      // Elimina la referencia al timeout
      this.messageTimeouts.delete(messageKey);
    }, this.messageCompleteTimeout);
    
    // Guarda la referencia al timeout
    this.messageTimeouts.set(messageKey, timeoutId);
  }

  private notifyEventListeners(event: IRCMessage): void {
    this.eventListeners.forEach(listener => listener(event));
  }

  private notifyErrorListeners(error: IRCMessage): void {
    this.errorListeners.forEach(listener => listener(error));
  }

  private notifyConnectionListeners(connected: boolean): void {
    this.connectionListeners.forEach(listener => listener(connected));
  }
}

export default new MyBrainService();

/*
// ~/ANUBISBOTv0.1/src/service/ircService.ts
import { io, Socket } from 'socket.io-client';

// Determine the correct backend URL based on environment
const getBackendUrl = () => {
  const currentUrl = window.location.href;
  console.log('Current browser URL:', currentUrl);
  
  // Local development - the backend is likely running on localhost
  if (currentUrl.includes('localhost') || currentUrl.includes('127.0.0.1')) {
    return 'http://localhost:6666/api';
  }
  
  // Production environment - use the actual backend domain
  // Important: Match the protocol (http/https)
  const isSecure = window.location.protocol === 'https:';
  
  if (isSecure) {
    return 'https://backend.anubisai.net/api';
  } else {
    // For HTTP environments
    return 'https://backend.anubisai.net/api';
  }
};

// Set the API URL
const API_URL = getBackendUrl();
const API_URL_BK = 'https://backend.anubisai.net/api'
console.log('Using API URL:', API_URL);

export interface ConnectionStatus {
  active: boolean;
  nickname?: string;
  channels?: string[];
  autoResponse?: boolean;
}

export interface IRCConfig {
  server: string;
  port: number;
  nickname: string;
  channels?: string[];
  useSSL?: boolean;
  password?: string;
  alternativeNicks?: string[];
}

export interface IRCMessage {
  id?: number | string;
  //type: string;
  type: 'message' | 'command' | 'system' | 'query' | string;
  from?: string;
  sender?: string;
  channel?: string;
  text: string;
  timestamp: string;
  source?: 'user' | 'irc' | 'ml' | 'api' | 'fallback'; 
}

class MyBrainService {
  private socket: Socket | null = null;
  private _connectionId: string | null = null;
  private messageListeners: ((message: IRCMessage) => void)[] = [];
  private eventListeners: ((event: IRCMessage) => void)[] = [];
  private errorListeners: ((error: IRCMessage) => void)[] = [];
  private connectionListeners: ((connected: boolean) => void)[] = [];
  private lastPing: number = Date.now();
  private pingInterval: NodeJS.Timeout | null = null;
  private connectionAttempts: number = 0;
  private wasEverConnected: boolean = false;

  get connectionId(): string | null {
    return this._connectionId;
  }

  public async checkConnection(connectionId: string): Promise<ConnectionStatus> {
    try {
      console.log(`Checking connection status for ID: ${connectionId}`);
      const response = await fetch(`${API_URL_BK}/mybrain/status/${connectionId}`);
      const data = await response.json();
      console.log('Connection status:', data);
      return {
        active: data.active,
        nickname: data.nickname,
        channels: data.channels
      };
    } catch (error) {
      console.error('Error checking connection status:', error);
      // Si hay un error de red o el servidor no responde, asumimos que la conexión no está activa
      return { active: false };
    }
  }

  constructor() {
    // Initialize ping mechanism
    this.setupPing();
    
    // Handle page visibility changes to manage connection
    if (typeof document !== 'undefined') {
      document.addEventListener('visibilitychange', this.handleVisibilityChange);
    }
    
    console.log('IRC Service initialized');
  }

  private handleVisibilityChange = () => {
    if (document.visibilityState === 'visible' && this._connectionId) {
      console.log('Page became visible, checking connection...');
      this.checkConnection(this._connectionId)
        .then(status => {
          if (!status.active && this.wasEverConnected) {
            console.log('Connection lost while page was hidden, reconnecting...');
            this.setupSocket();
          }
        })
        .catch(console.error);
    }
  }

  private setupPing() {
    if (this.pingInterval) {
      clearInterval(this.pingInterval);
    }
    
    this.pingInterval = setInterval(() => {
      if (this.socket && this._connectionId) {
        if (Date.now() - this.lastPing > 30000) {
          console.log('Ping timeout detected, reconnecting socket...');
          
          // Don't disconnect immediately, try to reconnect first
          if (this.socket.disconnected) {
            console.log('Socket already disconnected, reconnecting...');
            this.socket.connect();
          } else {
            console.log('Sending ping to check connection');
            this.socket.emit('ping');
          }
          
          // If still no response after 5 more seconds, do a full reconnect
          setTimeout(() => {
            if (Date.now() - this.lastPing > 35000) {
              console.log('Connection still dead after ping, doing full reset');
              if (this.socket) {
                this.socket.disconnect();
                this.socket = null;
              }
              this.setupSocket();
              
              // Notify listeners about connection loss
              this.notifyConnectionListeners(false);
            }
          }, 5000);
        } else {
          // Regular ping
          this.socket.emit('ping');
          console.log('Sent ping');
        }
      }
    }, 15000);
  }

  public async connect(config: IRCConfig): Promise<string> {
    this.connectionAttempts = 0;
    
    try {
      console.log(`Connecting to IRC server: ${config.server}:${config.port}`);
      const response = await fetch(`${API_URL}/mybrain/connect`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(config)
      });

      const data = await response.json();
      console.log('Connection response:', data);

      if (data.success) {
        this._connectionId = data.connectionId;
        this.setupSocket();
        this.notifyConnectionListeners(true);
        this.lastPing = Date.now();
        this.wasEverConnected = true;
        return data.connectionId;
      } else {
        throw new Error(data.error || 'Error desconocido en la conexión');
      }
    } catch (error: any) {
      console.error('Error connecting to IRC:', error);
      this.notifyErrorListeners({
        type: 'error',
        text: error.message || 'Error de conexión',
        timestamp: new Date().toISOString()
      });
      throw error;
    }
  }

  public async reconnectWithAlternativeNick(config: IRCConfig, attemptNumber: number = 0): Promise<string> {
    try {
      this.connectionAttempts++;
      console.log(`Reconnection attempt #${this.connectionAttempts} with alternative nick`);
      
      // Create a copy of the config to modify
      const reconnectConfig = { ...config };
      
      // If this is not the first attempt, use an alternative nickname
      if (attemptNumber > 0 && config.alternativeNicks && config.alternativeNicks.length > 0) {
        // Choose an alternative nickname based on the attempt number
        const nicknameIndex = (attemptNumber - 1) % (config.alternativeNicks.length || 1);
        reconnectConfig.nickname = config.alternativeNicks[nicknameIndex];
        
        // Add a timestamp to make it even more unique if needed
        if (attemptNumber > config.alternativeNicks.length) {
          reconnectConfig.nickname += '_' + Date.now().toString().slice(-4);
        }
        
        console.log(`Using alternative nickname: ${reconnectConfig.nickname}`);
      }
      
      // Try to disconnect any existing connection first (ignore errors)
      if (this._connectionId) {
        try {
          console.log('Disconnecting existing connection before reconnect');
          await this.disconnect();
        } catch (error) {
          console.warn('Error disconnecting before reconnect', error);
        }
      }
      
      // Now try to connect with the potentially modified config
      const connectionId = await this.connect(reconnectConfig);
      console.log(`Reconnected successfully with ID: ${connectionId}`);
      return connectionId;
    } catch (error: any) {
      console.error('Error during reconnection:', error);
      
      // If we're out of alternative nicks or too many attempts, throw the error
      if (!config.alternativeNicks || attemptNumber >= config.alternativeNicks.length + 3 || this.connectionAttempts > 5) {
        throw error;
      }
      
      // Otherwise, retry with the next alternative nickname
      console.log(`Retrying with next alternative nickname, attempt #${attemptNumber + 1}`);
      return this.reconnectWithAlternativeNick(config, attemptNumber + 1);
    }
  }

// Reemplaza el método disconnect en tu frontend (ircService.ts)
public async disconnect(): Promise<boolean> {
  if (!this._connectionId) return false;

  try {
    console.log(`Disconnecting IRC connection: ${this._connectionId}`);
    
    // Guardar una copia del connectionId para referencia
    const connectionIdToDisconnect = this._connectionId;
    
    // 1. Primero realizar limpieza local
    console.log("Realizando limpieza local primero");
    
    // Desconectar socket si existe
    if (this.socket) {
      try {
        this.socket.disconnect();
      } catch (socketError) {
        console.warn("Error al desconectar socket:", socketError);
      }
      this.socket = null;
    }
    
    // Limpiar ID de conexión localmente
    this._connectionId = null;
    
    // Notificar a los listeners sobre la desconexión
    this.notifyConnectionListeners(false);
    
    // 2. Luego intentar desconectar en el servidor (no bloqueante para la UI)
    try {
      console.log(`Enviando petición de desconexión al servidor para: ${connectionIdToDisconnect}`);
      const response = await fetch(`${API_URL}/mybrain/disconnect`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ connectionId: connectionIdToDisconnect }),
        // Timeout de 5 segundos para no bloquear indefinidamente
        signal: AbortSignal.timeout(5000)
      });

      // Analizar respuesta si está disponible
      try {
        const data = await response.json();
        if (data.success) {
          console.log("Servidor confirmó desconexión exitosa");
        } else {
          console.warn(`Servidor reportó error en desconexión: ${data.error || 'Error desconocido'}`);
        }
      } catch (parseError) {
        console.warn("No se pudo analizar respuesta del servidor:", parseError);
      }
    } catch (serverError) {
      // No dejar que un error del servidor afecte la experiencia del usuario
      console.error("Error en comunicación con el servidor durante desconexión:", serverError);
      // No revertimos el estado local, la desconexión ya se realizó localmente
    }
    
    console.log("Proceso de desconexión completado");
    return true;
  } catch (error: any) {
    console.error('Error general durante la desconexión:', error);
    this.notifyErrorListeners({
      type: 'error',
      text: error.message || 'Error al desconectar',
      timestamp: new Date().toISOString()
    });
    
    // Intentar limpiar recursos localmente de todas formas
    this._connectionId = null;
    this.socket = null;
    this.notifyConnectionListeners(false);
    
    return false;
  }
}

  public async sendMessage(channel: string, message: string): Promise<boolean> {
    if (!this._connectionId) return false;

    try {
      console.log(`Sending message to ${channel}: ${message}`);
      const response = await fetch(`${API_URL}/mybrain/send`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          connectionId: this._connectionId,
          channel,
          message
        })
      });

      const data = await response.json();
      return data.success;
    } catch (error: any) {
      console.error('Error sending message:', error);
      this.notifyErrorListeners({
        type: 'error',
        text: error.message || 'Error al enviar mensaje',
        timestamp: new Date().toISOString()
      });
      return false;
    }
  }

  public async getMessages(limit: number = 100): Promise<IRCMessage[]> {
    if (!this._connectionId) return [];

    try {
      console.log(`Fetching last ${limit} messages`);
      const response = await fetch(`${API_URL}/mybrain/messages/${this._connectionId}?limit=${limit}`);
      const data = await response.json();
      return data.messages || [];
    } catch (error) {
      console.error('Error getting messages:', error);
      return [];
    }
  }

  // Event handlers
  public onMessage(callback: (message: IRCMessage) => void): () => void {
    this.messageListeners.push(callback);
    return () => {
      this.messageListeners = this.messageListeners.filter(cb => cb !== callback);
    };
  }

  public onEvent(callback: (event: IRCMessage) => void): () => void {
    this.eventListeners.push(callback);
    return () => {
      this.eventListeners = this.eventListeners.filter(cb => cb !== callback);
    };
  }

  public onError(callback: (error: IRCMessage) => void): () => void {
    this.errorListeners.push(callback);
    return () => {
      this.errorListeners = this.errorListeners.filter(cb => cb !== callback);
    };
  }

  public onConnectionChange(callback: (connected: boolean) => void): () => void {
    this.connectionListeners.push(callback);
    return () => {
      this.connectionListeners = this.connectionListeners.filter(cb => cb !== callback);
    };
  }

  private setupSocket(): void {
    if (this.socket) {
      console.log('Disconnecting existing socket before setup');
      this.socket.disconnect();
    }

    // Get the base URL without the /api path
    const socketUrl = API_URL.replace('/api', '');
    console.log(`Setting up Socket.IO connection to: ${socketUrl}`);
    
    try {
      this.socket = io(socketUrl, {
        reconnection: true,
        reconnectionAttempts: 5,
        reconnectionDelay: 1000,
        // Critical: Use "polling" as the ONLY transport initially
        // This helps establish the first connection through CORS
        transports: ['polling'],
        // Then enable upgrade to websocket after connection
        upgrade: true,
        rememberUpgrade: true,
        forceNew: true,
        timeout: 20000,
        path: '/socket.io/',
        // Debug options
        autoConnect: true,
        withCredentials: false
      });
      
      console.log('Socket.IO instance created with initial polling transport');
      
      if (this._connectionId) {
        console.log(`Joining room: ${this._connectionId}`);
        this.socket.emit('join', this._connectionId);
      }
  
      this.socket.on('connect', () => {
        console.log(`Socket.IO connected successfully! ID: ${this.socket?.id}, Transport: ${this.socket?.io.engine.transport.name}`);
        this.lastPing = Date.now();
        
        if (this._connectionId) {
          console.log(`Re-joining room after connect: ${this._connectionId}`);
          this.socket?.emit('join', this._connectionId);
        }
      });
      
      // Monitor transport changes
      this.socket.io.engine.on('upgrade', (transport) => {
        console.log(`Socket.IO transport upgraded to: ${transport}`);
      });
      
      this.socket.on('connect_error', (error) => {
        console.error('Socket.IO connection error:', error);
        this.notifyErrorListeners({
          type: 'error',
          text: `Error de conexión WebSocket: ${error.message}`,
          timestamp: new Date().toISOString()
        });
      });
  
      this.socket.on('irc-message', (message: IRCMessage) => {
        console.log('Received IRC message:', message);
        this.lastPing = Date.now();
        this.notifyMessageListeners(message);
      });
  
      this.socket.on('irc-event', (event: IRCMessage) => {
        console.log('Received IRC event:', event);
        this.lastPing = Date.now();
        this.notifyEventListeners(event);
      });
  
      this.socket.on('irc-error', (error: IRCMessage) => {
        console.error('Received IRC error:', error);
        this.lastPing = Date.now();
        this.notifyErrorListeners(error);
      });
  
      this.socket.on('disconnect', (reason) => {
        console.warn(`Socket.IO disconnected. Reason: ${reason}`);
        this.notifyConnectionListeners(false);
      });
      
      this.socket.on('pong', () => {
        console.log('Received pong from server');
        this.lastPing = Date.now();
      });
      
      this.socket.on('error', (error) => {
        console.error('Socket error:', error);
      });
    } catch (error) {
      console.error('Error setting up socket:', error);
    }
  }

  private notifyMessageListeners(message: IRCMessage): void {
    this.messageListeners.forEach(listener => listener(message));
  }

  private notifyEventListeners(event: IRCMessage): void {
    this.eventListeners.forEach(listener => listener(event));
  }

  private notifyErrorListeners(error: IRCMessage): void {
    this.errorListeners.forEach(listener => listener(error));
  }

  private notifyConnectionListeners(connected: boolean): void {
    this.connectionListeners.forEach(listener => listener(connected));
  }
}

export default new MyBrainService();

/* @v0.2 - no ML Endpoint AI, NL manual mode, no contesta directo 
// ~/ANUBISBOTv0.1/src/service/ircService.ts
import { io, Socket } from 'socket.io-client';

// Determine the correct backend URL based on environment
const getBackendUrl = () => {
  const currentUrl = window.location.href;
  console.log('Current browser URL:', currentUrl);
  
  // Local development - the backend is likely running on localhost
  if (currentUrl.includes('localhost') || currentUrl.includes('127.0.0.1')) {
    return 'http://localhost:3456/api';
  }
  
  // Production environment - use the actual backend domain
  // Important: Match the protocol (http/https)
  const isSecure = window.location.protocol === 'https:';
  
  if (isSecure) {
    return 'https://backend.anubisai.net/api';
  } else {
    // For HTTP environments
    return 'https://backend.anubisai.net/api';
  }
};

// Set the API URL
const API_URL = getBackendUrl();
const API_URL_BK = 'https://backend.anubisai.net/api'
console.log('Using API URL:', API_URL);

export interface ConnectionStatus {
  active: boolean;
  nickname?: string;
  channels?: string[];
}

export interface IRCConfig {
  server: string;
  port: number;
  nickname: string;
  channels?: string[];
  useSSL?: boolean;
  password?: string;
  alternativeNicks?: string[];
}

export interface IRCMessage {
  id?: number | string;
  type: string;
  from?: string;
  sender?: string;
  channel?: string;
  text: string;
  timestamp: string;
  source?: 'user' | 'irc' | 'ml' | 'api'; 
}

class IRCService {
  private socket: Socket | null = null;
  private _connectionId: string | null = null;
  private messageListeners: ((message: IRCMessage) => void)[] = [];
  private eventListeners: ((event: IRCMessage) => void)[] = [];
  private errorListeners: ((error: IRCMessage) => void)[] = [];
  private connectionListeners: ((connected: boolean) => void)[] = [];
  private lastPing: number = Date.now();
  private pingInterval: NodeJS.Timeout | null = null;
  private connectionAttempts: number = 0;
  private wasEverConnected: boolean = false;

  get connectionId(): string | null {
    return this._connectionId;
  }

  public async checkConnection(connectionId: string): Promise<ConnectionStatus> {
    try {
      console.log(`Checking connection status for ID: ${connectionId}`);
      const response = await fetch(`${API_URL_BK}/irc/status/${connectionId}`);
      const data = await response.json();
      console.log('Connection status:', data);
      return {
        active: data.active,
        nickname: data.nickname,
        channels: data.channels
      };
    } catch (error) {
      console.error('Error checking connection status:', error);
      // Si hay un error de red o el servidor no responde, asumimos que la conexión no está activa
      return { active: false };
    }
  }

  constructor() {
    // Initialize ping mechanism
    this.setupPing();
    
    // Handle page visibility changes to manage connection
    if (typeof document !== 'undefined') {
      document.addEventListener('visibilitychange', this.handleVisibilityChange);
    }
    
    console.log('IRC Service initialized');
  }

  private handleVisibilityChange = () => {
    if (document.visibilityState === 'visible' && this._connectionId) {
      console.log('Page became visible, checking connection...');
      this.checkConnection(this._connectionId)
        .then(status => {
          if (!status.active && this.wasEverConnected) {
            console.log('Connection lost while page was hidden, reconnecting...');
            this.setupSocket();
          }
        })
        .catch(console.error);
    }
  }

  private setupPing() {
    if (this.pingInterval) {
      clearInterval(this.pingInterval);
    }
    
    this.pingInterval = setInterval(() => {
      if (this.socket && this._connectionId) {
        if (Date.now() - this.lastPing > 30000) {
          console.log('Ping timeout detected, reconnecting socket...');
          
          // Don't disconnect immediately, try to reconnect first
          if (this.socket.disconnected) {
            console.log('Socket already disconnected, reconnecting...');
            this.socket.connect();
          } else {
            console.log('Sending ping to check connection');
            this.socket.emit('ping');
          }
          
          // If still no response after 5 more seconds, do a full reconnect
          setTimeout(() => {
            if (Date.now() - this.lastPing > 35000) {
              console.log('Connection still dead after ping, doing full reset');
              if (this.socket) {
                this.socket.disconnect();
                this.socket = null;
              }
              this.setupSocket();
              
              // Notify listeners about connection loss
              this.notifyConnectionListeners(false);
            }
          }, 5000);
        } else {
          // Regular ping
          this.socket.emit('ping');
          console.log('Sent ping');
        }
      }
    }, 15000);
  }

  public async connect(config: IRCConfig): Promise<string> {
    this.connectionAttempts = 0;
    
    try {
      console.log(`Connecting to IRC server: ${config.server}:${config.port}`);
      const response = await fetch(`${API_URL}/irc/connect`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(config)
      });

      const data = await response.json();
      console.log('Connection response:', data);

      if (data.success) {
        this._connectionId = data.connectionId;
        this.setupSocket();
        this.notifyConnectionListeners(true);
        this.lastPing = Date.now();
        this.wasEverConnected = true;
        return data.connectionId;
      } else {
        throw new Error(data.error || 'Error desconocido en la conexión');
      }
    } catch (error: any) {
      console.error('Error connecting to IRC:', error);
      this.notifyErrorListeners({
        type: 'error',
        text: error.message || 'Error de conexión',
        timestamp: new Date().toISOString()
      });
      throw error;
    }
  }

  public async reconnectWithAlternativeNick(config: IRCConfig, attemptNumber: number = 0): Promise<string> {
    try {
      this.connectionAttempts++;
      console.log(`Reconnection attempt #${this.connectionAttempts} with alternative nick`);
      
      // Create a copy of the config to modify
      const reconnectConfig = { ...config };
      
      // If this is not the first attempt, use an alternative nickname
      if (attemptNumber > 0 && config.alternativeNicks && config.alternativeNicks.length > 0) {
        // Choose an alternative nickname based on the attempt number
        const nicknameIndex = (attemptNumber - 1) % (config.alternativeNicks.length || 1);
        reconnectConfig.nickname = config.alternativeNicks[nicknameIndex];
        
        // Add a timestamp to make it even more unique if needed
        if (attemptNumber > config.alternativeNicks.length) {
          reconnectConfig.nickname += '_' + Date.now().toString().slice(-4);
        }
        
        console.log(`Using alternative nickname: ${reconnectConfig.nickname}`);
      }
      
      // Try to disconnect any existing connection first (ignore errors)
      if (this._connectionId) {
        try {
          console.log('Disconnecting existing connection before reconnect');
          await this.disconnect();
        } catch (error) {
          console.warn('Error disconnecting before reconnect', error);
        }
      }
      
      // Now try to connect with the potentially modified config
      const connectionId = await this.connect(reconnectConfig);
      console.log(`Reconnected successfully with ID: ${connectionId}`);
      return connectionId;
    } catch (error: any) {
      console.error('Error during reconnection:', error);
      
      // If we're out of alternative nicks or too many attempts, throw the error
      if (!config.alternativeNicks || attemptNumber >= config.alternativeNicks.length + 3 || this.connectionAttempts > 5) {
        throw error;
      }
      
      // Otherwise, retry with the next alternative nickname
      console.log(`Retrying with next alternative nickname, attempt #${attemptNumber + 1}`);
      return this.reconnectWithAlternativeNick(config, attemptNumber + 1);
    }
  }

// Reemplaza el método disconnect en tu frontend (ircService.ts)
public async disconnect(): Promise<boolean> {
  if (!this._connectionId) return false;

  try {
    console.log(`Disconnecting IRC connection: ${this._connectionId}`);
    
    // Guardar una copia del connectionId para referencia
    const connectionIdToDisconnect = this._connectionId;
    
    // 1. Primero realizar limpieza local
    console.log("Realizando limpieza local primero");
    
    // Desconectar socket si existe
    if (this.socket) {
      try {
        this.socket.disconnect();
      } catch (socketError) {
        console.warn("Error al desconectar socket:", socketError);
      }
      this.socket = null;
    }
    
    // Limpiar ID de conexión localmente
    this._connectionId = null;
    
    // Notificar a los listeners sobre la desconexión
    this.notifyConnectionListeners(false);
    
    // 2. Luego intentar desconectar en el servidor (no bloqueante para la UI)
    try {
      console.log(`Enviando petición de desconexión al servidor para: ${connectionIdToDisconnect}`);
      const response = await fetch(`${API_URL}/irc/disconnect`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ connectionId: connectionIdToDisconnect }),
        // Timeout de 5 segundos para no bloquear indefinidamente
        signal: AbortSignal.timeout(5000)
      });

      // Analizar respuesta si está disponible
      try {
        const data = await response.json();
        if (data.success) {
          console.log("Servidor confirmó desconexión exitosa");
        } else {
          console.warn(`Servidor reportó error en desconexión: ${data.error || 'Error desconocido'}`);
        }
      } catch (parseError) {
        console.warn("No se pudo analizar respuesta del servidor:", parseError);
      }
    } catch (serverError) {
      // No dejar que un error del servidor afecte la experiencia del usuario
      console.error("Error en comunicación con el servidor durante desconexión:", serverError);
      // No revertimos el estado local, la desconexión ya se realizó localmente
    }
    
    console.log("Proceso de desconexión completado");
    return true;
  } catch (error: any) {
    console.error('Error general durante la desconexión:', error);
    this.notifyErrorListeners({
      type: 'error',
      text: error.message || 'Error al desconectar',
      timestamp: new Date().toISOString()
    });
    
    // Intentar limpiar recursos localmente de todas formas
    this._connectionId = null;
    this.socket = null;
    this.notifyConnectionListeners(false);
    
    return false;
  }
}

  public async sendMessage(channel: string, message: string): Promise<boolean> {
    if (!this._connectionId) return false;

    try {
      console.log(`Sending message to ${channel}: ${message}`);
      const response = await fetch(`${API_URL}/irc/send`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          connectionId: this._connectionId,
          channel,
          message
        })
      });

      const data = await response.json();
      return data.success;
    } catch (error: any) {
      console.error('Error sending message:', error);
      this.notifyErrorListeners({
        type: 'error',
        text: error.message || 'Error al enviar mensaje',
        timestamp: new Date().toISOString()
      });
      return false;
    }
  }

  public async getMessages(limit: number = 100): Promise<IRCMessage[]> {
    if (!this._connectionId) return [];

    try {
      console.log(`Fetching last ${limit} messages`);
      const response = await fetch(`${API_URL}/irc/messages/${this._connectionId}?limit=${limit}`);
      const data = await response.json();
      return data.messages || [];
    } catch (error) {
      console.error('Error getting messages:', error);
      return [];
    }
  }

  // Event handlers
  public onMessage(callback: (message: IRCMessage) => void): () => void {
    this.messageListeners.push(callback);
    return () => {
      this.messageListeners = this.messageListeners.filter(cb => cb !== callback);
    };
  }

  public onEvent(callback: (event: IRCMessage) => void): () => void {
    this.eventListeners.push(callback);
    return () => {
      this.eventListeners = this.eventListeners.filter(cb => cb !== callback);
    };
  }

  public onError(callback: (error: IRCMessage) => void): () => void {
    this.errorListeners.push(callback);
    return () => {
      this.errorListeners = this.errorListeners.filter(cb => cb !== callback);
    };
  }

  public onConnectionChange(callback: (connected: boolean) => void): () => void {
    this.connectionListeners.push(callback);
    return () => {
      this.connectionListeners = this.connectionListeners.filter(cb => cb !== callback);
    };
  }

  private setupSocket(): void {
    if (this.socket) {
      console.log('Disconnecting existing socket before setup');
      this.socket.disconnect();
    }

    // Get the base URL without the /api path
    const socketUrl = API_URL.replace('/api', '');
    console.log(`Setting up Socket.IO connection to: ${socketUrl}`);
    
    try {
      this.socket = io(socketUrl, {
        reconnection: true,
        reconnectionAttempts: 5,
        reconnectionDelay: 1000,
        // Critical: Use "polling" as the ONLY transport initially
        // This helps establish the first connection through CORS
        transports: ['polling'],
        // Then enable upgrade to websocket after connection
        upgrade: true,
        rememberUpgrade: true,
        forceNew: true,
        timeout: 20000,
        path: '/socket.io/',
        // Debug options
        autoConnect: true,
        withCredentials: false
      });
      
      console.log('Socket.IO instance created with initial polling transport');
      
      if (this._connectionId) {
        console.log(`Joining room: ${this._connectionId}`);
        this.socket.emit('join', this._connectionId);
      }
  
      this.socket.on('connect', () => {
        console.log(`Socket.IO connected successfully! ID: ${this.socket?.id}, Transport: ${this.socket?.io.engine.transport.name}`);
        this.lastPing = Date.now();
        
        if (this._connectionId) {
          console.log(`Re-joining room after connect: ${this._connectionId}`);
          this.socket?.emit('join', this._connectionId);
        }
      });
      
      // Monitor transport changes
      this.socket.io.engine.on('upgrade', (transport) => {
        console.log(`Socket.IO transport upgraded to: ${transport}`);
      });
      
      this.socket.on('connect_error', (error) => {
        console.error('Socket.IO connection error:', error);
        this.notifyErrorListeners({
          type: 'error',
          text: `Error de conexión WebSocket: ${error.message}`,
          timestamp: new Date().toISOString()
        });
      });
  
      this.socket.on('irc-message', (message: IRCMessage) => {
        console.log('Received IRC message:', message);
        this.lastPing = Date.now();
        this.notifyMessageListeners(message);
      });
  
      this.socket.on('irc-event', (event: IRCMessage) => {
        console.log('Received IRC event:', event);
        this.lastPing = Date.now();
        this.notifyEventListeners(event);
      });
  
      this.socket.on('irc-error', (error: IRCMessage) => {
        console.error('Received IRC error:', error);
        this.lastPing = Date.now();
        this.notifyErrorListeners(error);
      });
  
      this.socket.on('disconnect', (reason) => {
        console.warn(`Socket.IO disconnected. Reason: ${reason}`);
        this.notifyConnectionListeners(false);
      });
      
      this.socket.on('pong', () => {
        console.log('Received pong from server');
        this.lastPing = Date.now();
      });
      
      this.socket.on('error', (error) => {
        console.error('Socket error:', error);
      });
    } catch (error) {
      console.error('Error setting up socket:', error);
    }
  }

  private notifyMessageListeners(message: IRCMessage): void {
    this.messageListeners.forEach(listener => listener(message));
  }

  private notifyEventListeners(event: IRCMessage): void {
    this.eventListeners.forEach(listener => listener(event));
  }

  private notifyErrorListeners(error: IRCMessage): void {
    this.errorListeners.forEach(listener => listener(error));
  }

  private notifyConnectionListeners(connected: boolean): void {
    this.connectionListeners.forEach(listener => listener(connected));
  }
}

export default new IRCService();

/* @v0.1 init 
// ~/ANUBISBOTv0.1/src/service/ircService.ts
import { io, Socket } from 'socket.io-client';
const getBaseUrl = () => {
  // For local development
  if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
    return 'https://localhost:3456/api';
  }

  // For production deployment
  // Match the protocol (http/https) with the current page
  const protocol = window.location.protocol === 'https:' ? 'https' : 'http';
  
  // Use wss for https, ws for http
  const wsProtocol = protocol === 'https' ? 'wss' : 'wss';

  // Log the determined URL for debugging
  console.log(`Using ${protocol} for API and ${wsProtocol} for WebSockets`);
  
  return `${protocol}s://backend.anubisai.net/api`;
};

const API_URL = getBaseUrl();//'https://backend.anubisai.net/api'; //process.env.REACT_APP_API_URL || 'http://localhost:4001/api';
// En ircService.ts
/* const API_URL = process.env.NODE_ENV === 'production'
  ? 'https://backend.anubisai.net/api'
  : 'http://localhost:3456/api'; */
/* _/
interface ConnectionStatus {
  active: boolean;
  nickname?: string;
  channels?: string[];
}

interface IRCConfig {
  server: string;
  port: number;
  nickname: string;
  channels?: string[];
  useSSL?: boolean;
  password?: string;
  alternativeNicks?: string[];
}

interface IRCMessage {
  id?: number;
  type: string;
  from?: string;
  channel?: string;
  text: string;
  timestamp: string;
}

class IRCService {
  private socket: Socket | null = null;
  //private connectionId: string | null = null;
  private messageListeners: ((message: IRCMessage) => void)[] = [];
  private eventListeners: ((event: IRCMessage) => void)[] = [];
  private errorListeners: ((error: IRCMessage) => void)[] = [];
  private connectionListeners: ((connected: boolean) => void)[] = [];
  private lastPing: number = Date.now();
  private pingInterval: NodeJS.Timeout | null = null;

  // Add this getter to the IRCService class
public get connectionId(): string | null {
  return this._connectionId;
}

// Rename the existing connectionId property to _connectionId
private _connectionId: string | null = null;
  // Add this method to IRCService class
public async reconnectWithAlternativeNick(config: IRCConfig, attemptNumber: number = 0): Promise<string> {
  try {
    // Create a copy of the config to modify
    const reconnectConfig = { ...config };
    
    // If this is not the first attempt, use an alternative nickname
    if (attemptNumber > 0 && config.alternativeNicks && config.alternativeNicks.length > 0) {
      // Choose an alternative nickname based on the attempt number
      const nicknameIndex = (attemptNumber - 1) % (config.alternativeNicks.length || 1);
      reconnectConfig.nickname = config.alternativeNicks[nicknameIndex];
      
      // Add a timestamp to make it even more unique if needed
      if (attemptNumber > config.alternativeNicks.length) {
        reconnectConfig.nickname += '_' + Date.now().toString().slice(-4);
      }
    }
    
    // Try to disconnect any existing connection first (ignore errors)
    if (this._connectionId) {
      try {
        await this.disconnect();
      } catch (error) {
        console.warn('Error disconnecting before reconnect', error);
      }
    }
    
    // Now try to connect with the potentially modified config
    const connectionId = await this.connect(reconnectConfig);
    return connectionId;
  } catch (error: any) {
    // If we're out of alternative nicks, throw the error
    if (!config.alternativeNicks || attemptNumber >= config.alternativeNicks.length + 3) {
      throw error;
    }
    
    // Otherwise, retry with the next alternative nickname
    return this.reconnectWithAlternativeNick(config, attemptNumber + 1);
  }
}

  public async checkConnection(connectionId: string): Promise<ConnectionStatus> {
    try {
      const response = await fetch(`${API_URL}/irc/status/${connectionId}`);
      const data = await response.json();
      return {
        active: data.active,
        nickname: data.nickname,
        channels: data.channels
      };
    } catch (error) {
      // Si hay un error de red o el servidor no responde, asumimos que la conexión no está activa
      return { active: false };
    }
  }

  constructor() {
    // Inicia un ping periódico para mantener la conexión viva
    this.setupPing();
  }

  private setupPing() {
    if (this.pingInterval) {
      clearInterval(this.pingInterval);
    }
    
    this.pingInterval = setInterval(() => {
      if (this.socket && this._connectionId) {
        if (Date.now() - this.lastPing > 30000) {
          // Log the reconnection attempt
          console.log('Ping timeout, reconnecting...');
          
          // Don't disconnect immediately, try to reconnect first
          this.socket.connect();
          
          // If reconnection doesn't work after 5 seconds, then do a full reset
          setTimeout(() => {
            if (Date.now() - this.lastPing > 35000) {
              console.log('Reconnection failed, doing full reset');
              if (this.socket) {
                this.socket.disconnect();
                this.socket = null;
              }
              this.setupSocket();
            }
          }, 5000);
        } else {
          // Send regular ping and log it
          console.log('Sending ping to keep connection alive');
          this.socket.emit('ping');
        }
      }
    }, 15000);
  }

  public async connect(config: IRCConfig): Promise<string> {
    try {
      const response = await fetch(`${API_URL}/irc/connect`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(config)
      });

      const data = await response.json();

      if (data.success) {
        this._connectionId = data.connectionId;
        this.setupSocket();
        this.notifyConnectionListeners(true);
        this.lastPing = Date.now();
        return data.connectionId;
      } else {
        throw new Error(data.error || 'Error desconocido en la conexión');
      }
    } catch (error: any) {
      this.notifyErrorListeners({
        type: 'error',
        text: error.message || 'Error de conexión',
        timestamp: new Date().toISOString()
      });
      throw error;
    }
  }

  public async disconnect(): Promise<boolean> {
    if (!this.connectionId) return false;

    try {
      const response = await fetch(`${API_URL}/irc/disconnect`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ connectionId: this.connectionId })
      });

      const data = await response.json();

      if (data.success) {
        if (this.socket) {
          this.socket.disconnect();
          this.socket = null;
        }
        this._connectionId = null;
        this.notifyConnectionListeners(false);
        return true;
      } else {
        throw new Error(data.error || 'Error al desconectar');
      }
    } catch (error: any) {
      this.notifyErrorListeners({
        type: 'error',
        text: error.message || 'Error al desconectar',
        timestamp: new Date().toISOString()
      });
      return false;
    }
  }

  public async sendMessage(channel: string, message: string): Promise<boolean> {
    if (!this.connectionId) return false;

    try {
      const response = await fetch(`${API_URL}/irc/send`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          connectionId: this.connectionId,
          channel,
          message
        })
      });

      const data = await response.json();
      return data.success;
    } catch (error: any) {
      this.notifyErrorListeners({
        type: 'error',
        text: error.message || 'Error al enviar mensaje',
        timestamp: new Date().toISOString()
      });
      return false;
    }
  }

  public async getMessages(limit: number = 100): Promise<IRCMessage[]> {
    if (!this.connectionId) return [];

    try {
      const response = await fetch(`${API_URL}/irc/messages/${this.connectionId}?limit=${limit}`);
      const data = await response.json();
      return data.messages || [];
    } catch (error) {
      return [];
    }
  }

  public onMessage(callback: (message: IRCMessage) => void): () => void {
    this.messageListeners.push(callback);
    return () => {
      this.messageListeners = this.messageListeners.filter(cb => cb !== callback);
    };
  }

  public onEvent(callback: (event: IRCMessage) => void): () => void {
    this.eventListeners.push(callback);
    return () => {
      this.eventListeners = this.eventListeners.filter(cb => cb !== callback);
    };
  }

  public onError(callback: (error: IRCMessage) => void): () => void {
    this.errorListeners.push(callback);
    return () => {
      this.errorListeners = this.errorListeners.filter(cb => cb !== callback);
    };
  }

  public onConnectionChange(callback: (connected: boolean) => void): () => void {
    this.connectionListeners.push(callback);
    return () => {
      this.connectionListeners = this.connectionListeners.filter(cb => cb !== callback);
    };
  }

  private setupSocket(): void {
    if (this.socket) {
      this.socket.disconnect();
    }
    const socketUrl = API_URL.replace('/api', '');
  
    console.log(`Connecting to Socket.IO server at: ${socketUrl}`);
    // Conectar a la raíz del servidor, no a la ruta API
    this.socket = io(socketUrl, {
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
      transports: ['websocket', 'polling'], // Try WebSocket first, fall back to polling
      /*extraHeaders: {
        // Adding origin header to match the expected origin
        "Origin": window.location.origin
      },*_/
      forceNew: true, // Create a new connection
      timeout: 10000 // Increase timeout for slower connections
    });

    console.log('Socket.IO instance created. Attempting connection...');
  
  this.socket.on('connect', () => {
    console.log('Socket.IO connected successfully');
    if (this._connectionId) {
      console.log(`Joining room: ${this._connectionId}`);
      this.socket?.emit('join', this._connectionId);
    }
  });
  
  this.socket.on('connect_error', (error) => {
    console.error('Socket.IO connection error:', error);
    this.notifyErrorListeners({
      type: 'error',
      text: `Error de conexión WebSocket: ${error.message}`,
      timestamp: new Date().toISOString()
    });
  });
    
    if (this.connectionId) {
      this.socket.emit('join', this.connectionId);
    }

    this.socket.on('irc-message', (message: IRCMessage) => {
      console.log('Received IRC message:', message);
      this.lastPing = Date.now();
      this.notifyMessageListeners(message);
    });
  
    this.socket.on('irc-event', (event: IRCMessage) => {
      console.log('Received IRC event:', event);
      this.lastPing = Date.now();
      this.notifyEventListeners(event);
    });
  
    this.socket.on('irc-error', (error: IRCMessage) => {
      console.error('Received IRC error:', error);
      this.lastPing = Date.now();
      this.notifyErrorListeners(error);
    });
  
    this.socket.on('disconnect', (reason) => {
      console.warn(`Socket.IO disconnected. Reason: ${reason}`);
      this.notifyConnectionListeners(false);
    });
    
    this.socket.on('pong', () => {
      console.log('Received pong from server');
      this.lastPing = Date.now();
    });

    this.socket.on('irc-message', (message: IRCMessage) => {
      this.lastPing = Date.now();
      this.notifyMessageListeners(message);
    });

    this.socket.on('irc-event', (event: IRCMessage) => {
      this.lastPing = Date.now();
      this.notifyEventListeners(event);
    });

    this.socket.on('irc-error', (error: IRCMessage) => {
      this.lastPing = Date.now();
      this.notifyErrorListeners(error);
    });

    this.socket.on('disconnect', () => {
      this.notifyConnectionListeners(false);
    });
    
    this.socket.on('connect', () => {
      if (this.connectionId) {
        this.socket?.emit('join', this.connectionId);
      }
    });
    
    this.socket.on('pong', () => {
      this.lastPing = Date.now();
    });
  }

  private notifyMessageListeners(message: IRCMessage): void {
    this.messageListeners.forEach(listener => listener(message));
  }

  private notifyEventListeners(event: IRCMessage): void {
    this.eventListeners.forEach(listener => listener(event));
  }

  private notifyErrorListeners(error: IRCMessage): void {
    this.errorListeners.forEach(listener => listener(error));
  }

  private notifyConnectionListeners(connected: boolean): void {
    this.connectionListeners.forEach(listener => listener(connected));
  }
}

export default new IRCService(); /*
*/