import { useRef, useState, useEffect } from 'react';
import { faChevronDown, faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import classNames from 'classnames';
import { useAppContext } from 'providers/AppProvider';
import { useChatWidgetContext } from 'providers/HazbotWidgetProvider';
import DIDAvatar, { ConnectionStatus, DIDHandle } from 'components/DIDAvatar';
import { Card } from 'react-bootstrap';
import avatar3d from 'assets/img/Anubis/Anputv0.2.jpeg'

const apiKey = process.env.REACT_APP_DID_API_KEY || '';

const HelpWidget = () => {
  const {
    config: { isChatWidgetVisible }
  } = useAppContext();
  const { isOpenChat, setIsOpenChat, conversation } = useChatWidgetContext();
  const didAvatarRef = useRef<DIDHandle>(null);
  const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus>('disconnected');
  const lastMessageRef = useRef<string | null>(null);

  const handleChatToggle = async () => {
    console.log('Current connection status:', connectionStatus);
    setIsOpenChat(!isOpenChat);
    
    if (!isOpenChat && didAvatarRef.current) {
      try {
        console.log('Attempting to connect...');
        await didAvatarRef.current.connect();
        console.log('Connection successful, updating status to connected');
        setConnectionStatus('connected');
      } catch (error) {
        console.error('Connection error:', error);
        setConnectionStatus('error');
      }
    }
  };

  useEffect(() => {
    console.log('Connection status updated:', connectionStatus);
  }, [connectionStatus]);

  useEffect(() => {
    if (didAvatarRef.current) {
      console.log('Setting up status change callback');
      didAvatarRef.current.setStatusChangeCallback((newStatus) => {
        console.log('Status change callback received:', newStatus);
        setConnectionStatus(newStatus);
      });
    }
  }, []);

  useEffect(() => {
    const messages = conversation.messages;
    if (messages.length === 0) return;

    const lastMessage = messages[messages.length - 1];
    
    if (
      lastMessage.type === 'received' && 
      lastMessage.message && 
      lastMessage.message !== lastMessageRef.current &&
      connectionStatus === 'connected' &&
      isOpenChat &&
      didAvatarRef.current
    ) {
      lastMessageRef.current = lastMessage.message;
      didAvatarRef.current.talk(lastMessage.message).catch(error => {
        console.error('Error making avatar talk:', error);
      });
    }
  }, [conversation.messages, connectionStatus, isOpenChat]);

  return (
    <div
      className={classNames({
        'd-none': !isChatWidgetVisible
      })}
    >
      <div
        className={classNames('container-fluid support-chat mb-15 pb-0 px-4 py-0', {
          'show-chat': isOpenChat
        })}
        style={{ border: '0px solid green', maxWidth: '280px', maxHeight: '345px' }}
      >
        <Card className="bg-body-emphasis mb-5 py-0 px-0">
          <Card.Header className="d-flex flex-between-center px-4 py-2 border-bottom">
            <h5 className="mb-0 d-flex align-items-center gap-2">
              {process.env.REACT_APP_VIRTUAL_AGENT}
              <FontAwesomeIcon 
                icon={faCircle} 
                className={classNames('fs-11', {
                  'text-success': connectionStatus === 'connected',
                  'text-warning': connectionStatus !== 'connected'
                })}
              />
            </h5>
          </Card.Header>
          <Card.Body className="scrollbar p-3" >
          <DIDAvatar 
              ref={didAvatarRef}
              apiKey={apiKey}
              apiUrl="https://api.d-id.com"
              sourceUrl={`${window.location.origin}${avatar3d}`}
              
            />
          </Card.Body>
        </Card>
      </div>
      <Button
        className={classNames(
          'p-1 border border-translucent btn-support-chat mb-11',
          {
            'btn-chat-close': isOpenChat
          }
        )}
        onClick={handleChatToggle}
      >
        <span className="fs-9 btn-text text-primary text-nowrap">
          {process.env.REACT_APP_VIRTUAL_AGENT}
        </span>
        <FontAwesomeIcon 
          icon={faCircle} 
          className={classNames('fs-9 ms-2', {
            'text-success': connectionStatus === 'connected',
            'text-warning': connectionStatus !== 'connected'
          })}
        />
        <FontAwesomeIcon icon={faChevronDown} className="text-primary fs-7" />
      </Button>
    </div>
  );
};

export default HelpWidget; 
/* 
import { useRef, useState, useEffect } from 'react';
import { faChevronDown, faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import classNames from 'classnames';
import { useAppContext } from 'providers/AppProvider';
import { useChatWidgetContext } from 'providers/ChatWidgetProvider';
import DIDAvatar, { ConnectionStatus, DIDHandle } from 'components/DIDAvatar';
import { Card } from 'react-bootstrap';
import avatar3d from 'assets/img/Anubis/Anputv0.2.jpeg'

const apiKey = process.env.REACT_APP_DID_API_KEY || '';

const HelpWidget = () => {
  const {
    config: { isChatWidgetVisible }
  } = useAppContext();
  const { isOpenChat, setIsOpenChat } = useChatWidgetContext();
  const didAvatarRef = useRef<DIDHandle>(null);
  const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus>('disconnected');

  const handleChatToggle = async () => {
    console.log('Current connection status:', connectionStatus);
    setIsOpenChat(!isOpenChat);
    
    if (!isOpenChat && didAvatarRef.current) {
      try {
        console.log('Attempting to connect...');
        await didAvatarRef.current.connect();
        console.log('Connection successful, updating status to connected');
        setConnectionStatus('connected');
      } catch (error) {
        console.error('Connection error:', error);
        setConnectionStatus('error');
      }
    }
  };

  useEffect(() => {
    console.log('Connection status updated:', connectionStatus);
  }, [connectionStatus]);

  useEffect(() => {
    if (didAvatarRef.current) {
      console.log('Setting up status change callback');
      didAvatarRef.current.setStatusChangeCallback((newStatus) => {
        console.log('Status change callback received:', newStatus);
        setConnectionStatus(newStatus);
      });
    }
  }, []);

  return (
    <div
      className={classNames({
        'd-none': !isChatWidgetVisible
      })}
    >
      <div
        className={classNames('container-fluid support-chat mb-15 pb-0 px-4 py-0', {
          'show-chat': isOpenChat
        })}
        style={{ border: '0px solid green', maxWidth: '300px', maxHeight: '350px' }}
      >
        <Card className="bg-body-emphasis mb-5 py-0 px-0" style={{ border: '0px solid red' }}>
          <Card.Header className="d-flex flex-between-center px-4 py-2 border-bottom">
            <h5 className="mb-0 d-flex align-items-center gap-2">
              {process.env.REACT_APP_VIRTUAL_AGENT}
              <FontAwesomeIcon 
                icon={faCircle} 
                className={classNames('fs-11', {
                  'text-success': connectionStatus === 'connected',
                  'text-warning': connectionStatus !== 'connected'
                })}
              />
            </h5>
          </Card.Header>
          <Card.Body className="scrollbar p-3">
              <DIDAvatar 
              ref={didAvatarRef}
              apiKey={apiKey}
              apiUrl="https://api.d-id.com"
              sourceUrl={`${window.location.origin}${avatar3d}`}
            />
          </Card.Body>
        </Card>
      </div>
      <Button
        className={classNames(
          'p-1 border border-translucent btn-support-chat mb-11',
          {
            'btn-chat-close': isOpenChat
          }
        )}
        onClick={handleChatToggle}
      >
        <span className="fs-9 btn-text text-primary text-nowrap">
          {process.env.REACT_APP_VIRTUAL_AGENT}
        </span>
        <FontAwesomeIcon 
          icon={faCircle} 
          className={classNames('fs-9 ms-2', {
            'text-success': connectionStatus === 'connected',
            'text-warning': connectionStatus !== 'connected'
          })}
        />
        <FontAwesomeIcon icon={faChevronDown} className="text-primary fs-7" />
      </Button>
    </div>
  );
};

export default HelpWidget;

/* @v0.1 init 
import { faChevronDown, faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import { Card, Dropdown } from 'react-bootstrap';
import Button from 'components/base/Button';
import classNames from 'classnames';
import { useAppContext } from 'providers/AppProvider';
import ChatWidgetConversation from './ChatWidgetConversation';
import { useChatWidgetContext } from 'providers/ChatWidgetProvider';
import ChatWidgetFooter from './ChatWidgetFooter';

import DIDAvatar from 'components/DIDAvatar';
const apiKey = process.env.REACT_APP_DID_API_KEY || '';


const HelpWidget = () => {
  const {
    config: { isChatWidgetVisible }
  } = useAppContext();
  const { isOpenChat, setIsOpenChat } = useChatWidgetContext();
  return (
    <div
      className={classNames({
        'd-none': !isChatWidgetVisible
      })}
    >
      <div
        className={classNames('container-fluid support-chat mb-15 pb-0 px-4 py-0', {
          'show-chat': isOpenChat
        })} style={{border: '0px solid green', maxWidth: '300px', maxHeight: '350px'}}
      >
        <Card className="bg-body-emphasis mb-5 py-0 px-0" style={{border: '0px solid red'}}>
          <Card.Header className="d-flex flex-between-center px-4 py-2 border-bottom" style={{ backgroundColor: "#ccff00" }}>
            <h5 className="mb-0 d-flex align-items-center gap-2">
              {process.env.REACT_APP_VIRTUAL_AGENT}
              <FontAwesomeIcon icon={faCircle} className="text-success fs-11" />
            </h5>
            {/*<RevealDropdownTrigger>
              <RevealDropdown>
                <Dropdown.Item>Request a callback</Dropdown.Item>
                <Dropdown.Item>Search in chat</Dropdown.Item>
                <Dropdown.Item>Show history</Dropdown.Item>
                <Dropdown.Item>Report to Admin</Dropdown.Item>
                <Dropdown.Item onClick={() => setIsOpenChat(!isOpenChat)}>
                  Close Support
                </Dropdown.Item>
              </RevealDropdown>
            </RevealDropdownTrigger>*--/}
          </Card.Header>
          <Card.Body className="scrollbar p-3" style={{ backgroundColor: "#ccff00" }}>
            {/*<ChatWidgetConversation />*--/}
            {/*<img src="https://vault.creatimus.com/~tsunami/Ambiparbot.png" width="225"></img>*--/}
            <DIDAvatar 
              apiKey={apiKey}
              apiUrl="https://api.d-id.com"
              sourceUrl="https://vault.creatimus.com/~tsunami/Ambiparbot.png"
            />
          </Card.Body>
          {/*<Card.Footer className="border-top ps-3 pe-4 py-3">
            <ChatWidgetFooter />
          </Card.Footer>*--/}
        </Card>
      </div>
      <Button
        className={classNames(
          'p-1 border border-translucent btn-support-chat mb-11',
          {
            'btn-chat-close': isOpenChat
          }
        )}
        onClick={() => setIsOpenChat(!isOpenChat)}
      >
        <span className="fs-9 btn-text text-primary text-nowrap">
          {process.env.REACT_APP_VIRTUAL_AGENT}
        </span>
        <FontAwesomeIcon icon={faCircle} className="text-success fs-9 ms-2" />
        <FontAwesomeIcon icon={faChevronDown} className="text-primary fs-7" />
      </Button>
    </div>
  );
};

export default HelpWidget; */