import React, { useState, useEffect, useRef } from 'react';
import { Button, Card, Col, Form, Row, Table, Modal, FloatingLabel, Spinner, Accordion, Tabs, Tab } from "react-bootstrap";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { MessageSquare, Send, Book, Award, BarChart, CheckCircle, FileText, HelpCircle, AlertCircle, AlertTriangle, FileQuestion } from 'lucide-react';
import WidgetsSectionTitle from 'components/modules/widgets/WidgetsSectionTitle';
import { faCertificate } from '@fortawesome/free-solid-svg-icons';
import ReactMarkdown from 'react-markdown';
import { getHatateToken } from 'helpers/utils';

// Definición de tipos de estándares ISO válidos
type ISOStandardType = 'iso27001' | 'iso27000' | 'iso27701';

// Interfaces base
interface ProgressData {
  date: string;
  score: number;
  standard: string;
  domain?: string;
  testType: 'knowledge' | 'application'; // Nuevo campo para el tipo de test
}

interface QuestionEvaluation {
  questionId: number;
  score: number;
  feedback: string;
}

interface EvaluationData {
  questionsEvaluation: QuestionEvaluation[];
  totalScore: number;
  feedback: string;
}

interface Message {
  id: number;
  text: string;
  sender: 'user' | 'bot';
  timestamp: string;
  evaluation?: any;
}

interface Question {
  id: number;
  text: string;
  options: string[];
}

interface Test {
  domain: string;
  questions: Question[];
  currentQuestion: number;
  answers: string[];
}

interface TestQuestion {
  id: number;
  text: string;
  options?: string[];
  type: 'multiple_choice' | 'text' | 'true_false';
}

interface TestAnswer {
  questionId: number;
  answer: string;
  score?: number;
  feedback?: string;
}

interface TestResult {
  domainId: string;
  testType: 'knowledge' | 'application';
  totalScore: number;
  maxScore: number;
  percentage: number;
  questions: TestQuestion[];
  answers: TestAnswer[];
  timestamp: string;
  feedback: string;
}

interface Evaluation {
  score: number;
  [key: string]: number;
}

interface ISOStandard {
  id: string;
  name: string;
  description: string;
  domains: string[];
  questions: {
    [domain: string]: Question[];
  };
}

interface EvaluationHistory {
  date: string;
  score: number;
  domain: string;
  answers: string[];
}

interface StandardProgress {
  standard: string;
  evaluations: EvaluationHistory[];
  lastUpdate: string;
  overallScore: number;
}

// Nuevas interfaces para las estadísticas
interface DateRange {
  startDate: string;
  endDate: string;
}

interface DomainStatistics {
  domain: string;
  averageScore: number;
  testCount: number;
  lastTestDate: string;
  lastScore: number;
  improvement: number; // Porcentaje de mejora entre el primer y último test
}

interface DomainStatisticsByType {
  knowledge: DomainStatistics[];
  application: DomainStatistics[];
}

interface TestedDomainsState {
  [key: string]: boolean;
}

// Configuración de estándares ISO
const isoStandards: { [key: string]: ISOStandard } = {
  'iso27001': {
    id: 'iso27001',
    name: 'ISO 27001',
    description: 'Sistema de Gestión de Seguridad de la Información',
    domains: ['Políticas de Seguridad', 'Control de Acceso', 'Operaciones', 'Desarrollo'],
    questions: {
      'Políticas de Seguridad': [
        {
          id: 1,
          text: "¿Tiene documentada una política de seguridad de la información?",
          options: ["Sí", "En proceso", "No"]
        },
        {
          id: 2,
          text: "¿Con qué frecuencia se revisan las políticas de seguridad?",
          options: ["Anualmente", "Cada 6 meses", "Nunca"]
        }
      ],
      'Control de Acceso': [
        {
          id: 1,
          text: "¿Implementa control de acceso basado en roles?",
          options: ["Sí", "Parcialmente", "No"]
        },
        {
          id: 2,
          text: "¿Existe un proceso de revisión periódica de accesos?",
          options: ["Sí", "En desarrollo", "No"]
        }
      ]
    }
  },
  'iso27000': {
    id: 'iso27000',
    name: 'ISO 27000',
    description: 'Fundamentos y Vocabulario de Seguridad de la Información',
    domains: ['Conceptos', 'Terminología', 'Implementación', 'Evaluación'],
    questions: {
      'Conceptos': [
        {
          id: 1,
          text: "¿Se comprenden los conceptos fundamentales de seguridad de la información?",
          options: ["Totalmente", "Parcialmente", "No"]
        },
        {
          id: 2,
          text: "¿El personal está familiarizado con el vocabulario estándar?",
          options: ["Sí", "En proceso", "No"]
        }
      ],
      'Terminología': [
        {
          id: 1,
          text: "¿Se utiliza la terminología estándar en la documentación?",
          options: ["Siempre", "A veces", "Nunca"]
        }
      ]
    }
  },
  'iso27701': {
    id: 'iso27701',
    name: 'ISO 27701',
    description: 'Gestión de Privacidad de la Información',
    domains: ['Privacidad', 'Protección de Datos', 'Consentimiento', 'Derechos ARCO'],
    questions: {
      'Privacidad': [
        {
          id: 1,
          text: "¿Tiene implementadas medidas específicas de privacidad?",
          options: ["Sí", "En proceso", "No"]
        }
      ]
    }
  }
};

// Interfaces para la base de datos simulada
interface MockDatabase {
  progressData: {
    [K in ISOStandardType]: ProgressData[];
  };
  evaluationHistory: {
    [K in ISOStandardType]: StandardProgress;
  };
  testResults: {
    [K in ISOStandardType]: TestResult[];
  };
}

// Base de datos simulada
const mockDatabase: MockDatabase = {
  progressData: {
    'iso27001': [
      {
        date: '2024-01', score: 45, standard: 'iso27001', domain: 'Políticas de Seguridad',
        testType: 'knowledge'
      },
      {
        date: '2024-02', score: 58, standard: 'iso27001', domain: 'Control de Acceso',
        testType: 'knowledge'
      },
      {
        date: '2024-03', score: 75, standard: 'iso27001', domain: 'Operaciones',
        testType: 'knowledge'
      }
    ],
    'iso27000': [
      {
        date: '2024-01', score: 60, standard: 'iso27000', domain: 'Conceptos',
        testType: 'knowledge'
      },
      {
        date: '2024-02', score: 72, standard: 'iso27000', domain: 'Terminología',
        testType: 'knowledge'
      },
      {
        date: '2024-03', score: 85, standard: 'iso27000', domain: 'Implementación',
        testType: 'knowledge'
      }
    ],
    'iso27701': [
      {
        date: '2024-01', score: 30, standard: 'iso27701', domain: 'Privacidad',
        testType: 'knowledge'
      },
      {
        date: '2024-02', score: 45, standard: 'iso27701', domain: 'Protección de Datos',
        testType: 'knowledge'
      },
      {
        date: '2024-03', score: 65, standard: 'iso27701', domain: 'Consentimiento',
        testType: 'knowledge'
      }
    ]
  },
  evaluationHistory: {
    'iso27001': {
      standard: 'iso27001',
      evaluations: [
        {
          date: '2024-03-15',
          domain: 'Políticas de Seguridad',
          score: 75,
          answers: ['Sí', 'Anualmente']
        },
        {
          date: '2024-03-16',
          domain: 'Control de Acceso',
          score: 66,
          answers: ['Parcialmente', 'Sí']
        }
      ],
      lastUpdate: '2024-03-16',
      overallScore: 70
    },
    'iso27000': {
      standard: 'iso27000',
      evaluations: [
        {
          date: '2024-03-14',
          domain: 'Conceptos',
          score: 85,
          answers: ['Totalmente', 'Sí']
        }
      ],
      lastUpdate: '2024-03-14',
      overallScore: 85
    },
    'iso27701': {
      standard: 'iso27701',
      evaluations: [
        {
          date: '2024-03-13',
          domain: 'Privacidad',
          score: 65,
          answers: ['En proceso']
        }
      ],
      lastUpdate: '2024-03-13',
      overallScore: 65
    }
  },
  testResults: {
    'iso27001': [],
    'iso27000': [],
    'iso27701': []
  }
};

// Operaciones de base de datos simuladas
const dbOperations = {
  getProgressData: async (standard: ISOStandardType): Promise<ProgressData[]> => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(mockDatabase.progressData[standard] || []);
      }, 500);
    });
  },

  getEvaluationHistory: async (standard: ISOStandardType): Promise<StandardProgress> => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(mockDatabase.evaluationHistory[standard] || {
          standard,
          evaluations: [],
          lastUpdate: new Date().toISOString(),
          overallScore: 0
        });
      }, 500);
    });
  },

  saveEvaluation: async (standard: ISOStandardType, evaluation: EvaluationHistory): Promise<void> => {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (!mockDatabase.evaluationHistory[standard]) {
          mockDatabase.evaluationHistory[standard] = {
            standard,
            evaluations: [],
            lastUpdate: new Date().toISOString(),
            overallScore: 0
          };
        }
        
        mockDatabase.evaluationHistory[standard].evaluations.push(evaluation);
        mockDatabase.evaluationHistory[standard].lastUpdate = new Date().toISOString();
        
        // Calcular nuevo score general
        const scores = mockDatabase.evaluationHistory[standard].evaluations.map(e => e.score);
        mockDatabase.evaluationHistory[standard].overallScore = 
          scores.reduce((a, b) => a + b, 0) / scores.length;
        
        resolve();
      }, 500);
    });
  },

  updateProgressData: async (standard: ISOStandardType, newData: ProgressData): Promise<void> => {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (!mockDatabase.progressData[standard]) {
          mockDatabase.progressData[standard] = [];
        }
        mockDatabase.progressData[standard].push(newData);
        resolve();
      }, 500);
    });
  },

  saveTestResult: async (standard: ISOStandardType, result: TestResult): Promise<void> => {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (!mockDatabase.testResults[standard]) {
          mockDatabase.testResults[standard] = [];
        }
        
        // Comprobar si ya existe un resultado para este dominio
        const existingIndex = mockDatabase.testResults[standard].findIndex(
          r => r.domainId === result.domainId && r.testType === result.testType
        );
        
        if (existingIndex >= 0) {
          // Actualizar el resultado existente
          mockDatabase.testResults[standard][existingIndex] = result;
        } else {
          // Añadir nuevo resultado
          mockDatabase.testResults[standard].push(result);
        }
        
        // Guardar en localStorage para persistencia entre sesiones
        try {
          localStorage.setItem(`isoTestResults_${standard}`, 
            JSON.stringify(mockDatabase.testResults[standard])
          );
        } catch (e) {
          console.warn('No se pudo guardar en localStorage:', e);
        }
        
        resolve();
      }, 500);
    });
  },

  getTestResults: async (standard: ISOStandardType): Promise<TestResult[]> => {
    return new Promise((resolve) => {
      setTimeout(() => {
        // Intentar cargar desde localStorage primero
        try {
          const storedResults = localStorage.getItem(`isoTestResults_${standard}`);
          if (storedResults) {
            const parsedResults = JSON.parse(storedResults);
            // Actualizar la base de datos en memoria
            mockDatabase.testResults[standard] = parsedResults;
          }
        } catch (e) {
          console.warn('Error al cargar resultados desde localStorage:', e);
        }
        
        resolve(mockDatabase.testResults[standard] || []);
      }, 500);
    });
  }
};

// Función para verificar si un valor es un estándar ISO válido
const isValidISOStandard = (value: string): value is ISOStandardType => {
  return ['iso27001', 'iso27000', 'iso27701'].includes(value);
};

// Componente principal
const ISO27001Dashboard = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputMessage, setInputMessage] = useState('');
  const [evaluation, setEvaluation] = useState<Evaluation | null>(null);
  const [currentTest, setCurrentTest] = useState<Test | null>(null);
  const [progressData, setProgressData] = useState<ProgressData[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedStandard, setSelectedStandard] = useState<ISOStandardType>('iso27001');
  const [collapsedMessages, setCollapsedMessages] = useState<number[]>([]);
  const [testResults, setTestResults] = useState<TestResult[]>([]);
  const [testType, setTestType] = useState<'knowledge' | 'application' | null>(null);
  const [isEvaluating, setIsEvaluating] = useState(false);
  const [textAnswers, setTextAnswers] = useState<string[]>([]);
  const [showTestModal, setShowTestModal] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState<string | null>(null);
  const [loadingTest, setLoadingTest] = useState<'knowledge' | 'application' | null>(null);
  const [testedDomains, setTestedDomains] = useState<TestedDomainsState>({});
  const [currentTestDomain, setCurrentTestDomain] = useState<string | null>(null);
  
  // Estados para estadísticas y filtrado
  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString().split('T')[0], // 3 meses atrás
    endDate: new Date().toISOString().split('T')[0] // Hoy
  });
  const [filteredProgressData, setFilteredProgressData] = useState<ProgressData[]>([]);
  const [showStatsModal, setShowStatsModal] = useState(false);
  const [selectedDomainStats, setSelectedDomainStats] = useState<string | null>(null);
  const [domainTestHistory, setDomainTestHistory] = useState<TestResult[]>([]);
  const [domainStatistics, setDomainStatistics] = useState<DomainStatisticsByType>({
    knowledge: [],
    application: []
  });

  //TOKEN
  const HatateToken = localStorage.getItem('hatateToken');
  
  // API Token para el endpoint
  const apiToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2N2NiY2RlYWViYzE5ZWU3NjEyNzU5MDEiLCJleHAiOjE3NDY1OTczNzAuNjUzLCJpYXQiOjE3NDE0MDk3NzB9.ujLbx4EEMzYL3HMaNUK9S_utqS0Rt-jXkBbKYKv_2kg';

  // Referencia para el contenedor de chat
  const chatContainerRef = useRef<HTMLDivElement>(null);

  // Función para hacer scroll hasta el final del chat
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };

  // Función para alternar el estado colapsado/expandido de un mensaje
  const toggleMessageCollapse = (messageId: number) => {
    setCollapsedMessages(prev => 
      prev.includes(messageId)
        ? prev.filter(id => id !== messageId)
        : [...prev, messageId]
    );
  };

  // Función para mostrar el modal de selección de test
  const showTestSelectionModal = (domain: string) => {
    setSelectedDomain(domain);
    setShowTestModal(true);
  };

  // Función para actualizar el rango de fechas
  const handleDateRangeChange = (field: 'startDate' | 'endDate', value: string) => {
    setDateRange(prev => ({
      ...prev,
      [field]: value
    }));
  };

  // Función para filtrar datos por rango de fechas
const filterDataByDateRange = () => {
  if (!progressData.length) return;
  
  const filtered = progressData.filter(item => {
    const itemDate = new Date(item.date);
    const start = new Date(dateRange.startDate);
    const end = new Date(dateRange.endDate);
    end.setHours(23, 59, 59, 999);
    
    return itemDate >= start && itemDate <= end;
  });
  
  setFilteredProgressData(filtered);
  
  // Separar estadísticas por tipo de test
  const knowledgeData = filtered.filter(item => item.testType === 'knowledge');
  const applicationData = filtered.filter(item => item.testType === 'application');
  
  // Calcular estadísticas para tests de conocimiento
  const knowledgeStats = calculateDomainStatistics(knowledgeData);
  
  // Calcular estadísticas para tests de aplicación práctica  
  const applicationStats = calculateDomainStatistics(applicationData);
  
  // Actualizar con la nueva estructura
  setDomainStatistics({
    knowledge: knowledgeStats,
    application: applicationStats
  });
};

// Función auxiliar para calcular estadísticas por dominio
const calculateDomainStatistics = (data: ProgressData[]): DomainStatistics[] => {
  const domainGroups: { [domain: string]: ProgressData[] } = {};
  
  data.forEach(item => {
    if (!item.domain) return;
    
    if (!domainGroups[item.domain]) {
      domainGroups[item.domain] = [];
    }
    
    domainGroups[item.domain].push(item);
  });
  
  return Object.keys(domainGroups).map(domain => {
    const domainData = domainGroups[domain];
    domainData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    
    const totalScore = domainData.reduce((sum, item) => sum + item.score, 0);
    const averageScore = domainData.length > 0 ? totalScore / domainData.length : 0;
    const firstTest = domainData[0];
    const lastTest = domainData[domainData.length - 1];
    const improvement = firstTest && lastTest 
      ? ((lastTest.score - firstTest.score) / firstTest.score) * 100 
      : 0;
    
    return {
      domain,
      averageScore: Math.round(averageScore * 100) / 100,
      testCount: domainData.length,
      lastTestDate: lastTest ? lastTest.date : 'N/A',
      lastScore: lastTest ? lastTest.score : 0,
      improvement: Math.round(improvement * 100) / 100
    };
  });
};

// 1. Función para calcular el promedio real de puntuaciones
const calculateAverageScore = (evaluation: Evaluation | null, domains: string[]): number => {
  if (!evaluation) return 0;

  // Filtrar solo los dominios que tienen una puntuación
  const evaluatedDomains = domains.filter(domain => 
    evaluation[domain] !== undefined && evaluation[domain] > 0
  );
  
  // Si no hay dominios evaluados, devolver 0
  if (evaluatedDomains.length === 0) return 0;
  
  // Calcular el promedio de las puntuaciones de los dominios evaluados
  const sum = evaluatedDomains.reduce((acc, domain) => acc + (evaluation[domain] || 0), 0);
  return Math.round(sum / evaluatedDomains.length);
};

// 2. Función para contar los dominios evaluados
const countEvaluatedDomains = (evaluation: Evaluation | null, domains: string[]): number => {
  if (!evaluation) return 0;
  
  // Contar dominios con puntuación mayor que 0
  return domains.filter(domain => 
    evaluation[domain] !== undefined && evaluation[domain] > 0
  ).length;
};

// 3. Función para obtener la fecha del examen más reciente
const getLatestTestDate = (testResults: TestResult[]): string => {
  if (!testResults || testResults.length === 0) {
    return 'Sin datos';
  }
  
  // Encontrar el timestamp más reciente
  const latestTimestamp = testResults.reduce((latest, test) => {
    const testTime = new Date(test.timestamp).getTime();
    return testTime > latest ? testTime : latest;
  }, 0);
  
  // Si no hay timestamps válidos, devolver mensaje predeterminado
  if (latestTimestamp === 0) {
    return 'Sin datos';
  }
  
  // Formatear la fecha en DD/MM/YYYY
  const date = new Date(latestTimestamp);
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};

  // Función para mostrar el historial detallado de tests por dominio
  const showDomainTestHistory = async (domain: string) => {
    setSelectedDomainStats(domain);
    
    // Obtener todos los resultados de test para este dominio
    const allTestResults = await dbOperations.getTestResults(selectedStandard);
    const domainTests = allTestResults.filter(test => 
      test.domainId === domain && 
      new Date(test.timestamp) >= new Date(dateRange.startDate) && 
      new Date(test.timestamp) <= new Date(dateRange.endDate)
    );
    
    // Ordenar por fecha
    domainTests.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
    
    setDomainTestHistory(domainTests);
    setShowStatsModal(true);
  };

  // Efecto para filtrar datos cuando cambia el rango de fechas o los datos de progreso
  useEffect(() => {
    filterDataByDateRange();
  }, [dateRange, progressData]);

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        // Cargar datos de progreso
        const progress = await dbOperations.getProgressData(selectedStandard);
        setProgressData(progress);

        // Cargar historial de evaluaciones
        const history = await dbOperations.getEvaluationHistory(selectedStandard);
        setEvaluation({
          score: history.overallScore,
          ...history.evaluations.reduce((acc, evaluation) => ({
            ...acc,
            [evaluation?.domain]: evaluation?.score
          }), {})
        });

        // Cargar resultados de tests
        const results = await dbOperations.getTestResults(selectedStandard);
        setTestResults(results);
      } catch (error) {
        console.error('Error loading data:', error);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [selectedStandard]);

  // Efecto para hacer scroll cuando cambian los mensajes
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleStandardChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    if (isValidISOStandard(value)) {
      setSelectedStandard(value);
      setCurrentTest(null);
      setEvaluation(null);
      setMessages([]);
      setCollapsedMessages([]);
      setTestType(null);
      setTextAnswers([]);
    } else {
      console.error('Estándar ISO inválido:', value);
    }
  };

  // Función para renderizar mensajes en estilo Anubis
  const renderMessage = (message: Message) => {
    // Verificar si el mensaje está colapsado
    const isCollapsed = message.sender === 'bot' && collapsedMessages.includes(message.id);
    // If the message contains markdown-like content
    if (message.text.includes('## ') || message.text.includes('###')) {
      return (
        <div key={message.id} className="mb-3">
          <div 
            className="bg-primary text-white p-3 rounded"
            style={{ position: 'relative' }}
          >
            <ReactMarkdown 
              components={{
                h2: ({node, ...props}) => <h5 className="text-white">{props.children}</h5>,
                h3: ({node, ...props}) => <h6 className="text-white-50">{props.children}</h6>,
                a: ({node, ...props}) => (
                  <Button 
                    variant="outline-light" 
                    size="sm" 
                    onClick={() => {
                      // Reset test state and return to test selection
                      setCurrentTest(null);
                      setTestType(null);
                      setLoadingTest(null);
                      setCurrentTestDomain(null);
                    }}
                  >
                    {props.children}
                  </Button>
                )
              }}
            >
              {message.text}
            </ReactMarkdown>
          </div>
        </div>
      );
    }
    // Estilo especial para el indicador de typing
    if (message.sender === 'bot' && message.text === 'typing') {
      return (
        <div key={message.id} className="mb-3">
          <div 
            className="bg-primary text-white p-3 rounded"
            style={{ 
              position: 'relative',
              opacity: '0.7'
            }}
          >
            <div className="d-flex align-items-center">
              <span className="me-2">Anubis está escribiendo</span>
              <div className="d-flex align-items-center">
                <span className="dot-flashing"></span>
                <span className="dot-flashing" style={{ animationDelay: '0.2s' }}></span>
                <span className="dot-flashing" style={{ animationDelay: '0.4s' }}></span>
              </div>
            </div>
          </div>
        </div>
      );
    }

    // Para mensajes regulares
    if (message.sender === 'user') {
      // Estilo para mensajes del usuario
      return (
        <div key={message.id} className="mb-3">
          <div className="d-flex justify-content-end">
            <div 
              className="p-3 rounded"
              style={{ 
                backgroundColor: '#f1f1f1',
                maxWidth: '80%'
              }}
            >
              {message.text}
            </div>
          </div>
        </div>
      );
    } else {
      // Estilo para mensajes del bot (con acordeón)
      return (
        <div 
          key={message.id} 
          className="mb-3"
          onClick={() => toggleMessageCollapse(message.id)}
          style={{ cursor: 'pointer' }}
        >
          <div 
            className="bg-primary text-white p-3 rounded"
            style={{ 
              position: 'relative',
              maxHeight: isCollapsed ? '100px' : 'none',
              overflow: isCollapsed ? 'hidden' : 'visible',
              transition: 'max-height 0.3s ease-out'
            }}
          >
            {message.text}
            
            {/* Indicador de expandir/colapsar */}
            {isCollapsed && (
              <div 
                style={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  height: '50px',
                  background: 'linear-gradient(transparent, rgba(13, 110, 253, 0.9))',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                  paddingBottom: '10px'
                }}
              >
                <span className="text-white">Ver más ▼</span>
              </div>
            )}
          </div>
          
          {/* Timestamp del mensaje */}
          <div className="text-end mt-1">
            <small className="text-muted">
              {new Date(message.timestamp).toLocaleTimeString()}
            </small>
          </div>
        </div>
      );
    }
  };

  const handleSendMessage = async () => {
    if (!inputMessage.trim()) return;
    setLoading(true);

    try {
      const timestamp = new Date().toISOString();
      
      const newMessage: Message = {
        id: Date.now(),
        text: inputMessage,
        sender: 'user',
        timestamp: timestamp
      };
      setMessages((prev: Message[]) => [...prev, newMessage]);

      // Añadir mensaje de "typing" temporal con ID único
      const typingIndicatorId = Date.now() + 1;
      setMessages((prev: Message[]) => [...prev, {
        id: typingIndicatorId,
        text: 'typing',
        sender: 'bot',
        timestamp: new Date().toISOString()
      }]);

      // Crear el prompt contextual basado en el estándar ISO seleccionado con instrucciones más específicas
      const contextualPrompt = `Eres un asistente experto especializado EXCLUSIVAMENTE en ${isoStandards[selectedStandard].name} (${isoStandards[selectedStandard].description}). 
Tienes amplio conocimiento sobre este estándar, sus requisitos, mejores prácticas de implementación, procesos de certificación y mantenimiento.
Tu tarea es proporcionar información precisa, clara y útil sobre ${isoStandards[selectedStandard].name}.
Siempre responde con información detallada y específica sobre este estándar, ofreciendo ejemplos prácticos cuando sea apropiado.
Debes mostrar confianza en tus respuestas y nunca indicar que no puedes proporcionar información sobre ${isoStandards[selectedStandard].name}.

Pregunta del usuario: ${inputMessage}`;

      // Llamar al endpoint de Anubis
      const response = await fetch('https://backend.anubisai.net/api/bot/llm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiToken}`
        },
        body: JSON.stringify({
          prompt: [{ 
            content: contextualPrompt,
            role: 'user',
            time: timestamp
          }],
          chatId: `iso_${Date.now()}_${Math.random().toString(36).substring(2, 9)}`,
          skill: 'general_v1',
          module: 'general_general'
        }),
      });

      if (!response.ok) {
        throw new Error(`Error al enviar query: ${response.status}`);
      }

      // Procesar la respuesta
      const data = await response.json();
      console.log('Respuesta recibida:', data);
      
      // Extraer el texto de la respuesta
      let responseText = '';
      if (data.reply) {
        responseText = data.reply;
      } else if (data.message) {
        responseText = data.message;
      } else if (data.text && Array.isArray(data.text)) {
        responseText = data.text.map((item: { content: any; }) => 
          typeof item === 'object' && item.content ? item.content : 
          typeof item === 'string' ? item : 
          JSON.stringify(item)
        ).join(' ');
      } else {
        responseText = 'No se pudo procesar la respuesta';
        console.warn('Estructura de respuesta no reconocida:', data);
      }
      
      // Reemplazar el indicador de typing con la respuesta real
      setMessages((prev: Message[]) => 
        prev.map(msg => 
          msg.id === typingIndicatorId 
            ? {
                id: typingIndicatorId,
                text: responseText || 'No se recibió respuesta',
                sender: 'bot',
                timestamp: new Date().toISOString(),
                evaluation: data.evaluation
              }
            : msg
        )
      );
      
      // Si hay datos de evaluación, actualizar el estado
      if (data.evaluation) {
        setEvaluation((prev: Evaluation | null) => ({
          ...(prev || { score: 0 }),
          ...data.evaluation
        }));
      }
    } catch (error) {
      console.error('Error:', error);
      
      // Actualizar los mensajes eliminando el indicador de typing y añadiendo mensaje de error
      setMessages(prev => {
        // Filtrar los mensajes para quitar el indicador de typing
        const withoutTyping = prev.filter(msg => msg.text !== 'typing');
        
        // Agregar mensaje de error
        return [...withoutTyping, {
          id: Date.now() + 1,
          text: 'Lo siento, ocurrió un error al procesar tu solicitud.',
          sender: 'bot',
          timestamp: new Date().toISOString()
        }];
      });
    } finally {
      setLoading(false);
      setInputMessage('');
    }
  };

  const exitTest = () => {
    // Guardar temporalmente el dominio para el mensaje
    const domainName = currentTestDomain || currentTest?.domain || 'este dominio';

    // Reset test-related states
    setCurrentTest(null);
    setTestType(null);
    setTextAnswers([]);
    setCurrentTestDomain(null);
    setLoadingTest(null);

    // Reset chat to initial state
    const exitMessage: Message = {
      id: Date.now(),
      text: `Has salido del test de ${domainName}. Estoy listo para ayudarte con cualquier consulta sobre ${isoStandards[selectedStandard].name}.`,
      sender: 'bot',
      timestamp: new Date().toISOString()
    };
    setMessages(prev => [...prev, exitMessage]);
  };

  // Función para iniciar un test de conocimiento
  const startKnowledgeTest = async (domain: string) => {
    // Set test type and current domain
    setTestType('knowledge');
    setCurrentTestDomain(domain);
    setShowTestModal(false);
    setLoadingTest('knowledge'); // Establecer que estamos cargando un test de conocimiento
    
    try {
      // More precise and structured prompt for question generation
      const prompt = `Genera EXACTAMENTE 5 preguntas de opción múltiple sobre ${domain} en el contexto de ${isoStandards[selectedStandard].name}. 
  
  FORMATO DE RESPUESTA ESTRICTO:
  {
    "questions": [
      {
        "id": 1,
        "text": "Pregunta específica y clara sobre el dominio",
        "options": [
          "Opción A (incorrecta)",
          "Opción B (correcta)",
          "Opción C (incorrecta)", 
          "Opción D (incorrecta)"
        ]
      },
      // ... 4 preguntas más siguiendo el mismo formato
    ]
  }
  
  INSTRUCCIONES ADICIONALES:
  - Cada pregunta debe tener un ID único
  - Incluir 4 opciones por pregunta
  - Solo una opción es la correcta
  - Las preguntas deben ser relevantes para ${isoStandards[selectedStandard].name}
  - Evitar preguntas ambiguas o muy complejas
  - Usar lenguaje claro y técnico`;
  
      const response = await fetch('https://backend.anubisai.net/api/bot/llm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiToken}`
        },
        body: JSON.stringify({
          prompt: [{ 
            content: prompt,
            role: 'user',
            time: new Date().toISOString()
          }],
          chatId: `test_${selectedStandard}_${domain.replace(/\s+/g, '_').toLowerCase()}`,
          skill: 'general_v1',
          module: 'general_general'
        }),
      });
  
      if (!response.ok) {
        throw new Error(`Error al generar preguntas: ${response.status}`);
      }
  
      const data = await response.json();
      let questions: TestQuestion[] = [];
      
      // Enhanced JSON parsing with multiple validation strategies
      const extractQuestions = (responseText: string): TestQuestion[] => {
        console.log('Raw Response:', responseText);
  
        // Multiple parsing strategies
        const parseStrategies = [
          // Strategy 1: Exact JSON block parsing
          () => {
            const jsonBlockMatch = responseText.match(/```json\s*([\s\S]*?)\s*```/);
            if (jsonBlockMatch) {
              try {
                const parsedData = JSON.parse(jsonBlockMatch[1].trim());
                return parsedData.questions || [];
              } catch (error) {
                console.error('JSON Block Parsing Error:', error);
                return [];
              }
            }
            return [];
          },
          
          // Strategy 2: Direct JSON parsing
          () => {
            try {
              const parsed = JSON.parse(responseText);
              return parsed.questions || parsed;
            } catch (error) {
              console.error('Direct Parsing Error:', error);
              return [];
            }
          }
        ];
  
        // Try parsing strategies
        for (const strategy of parseStrategies) {
          const result = strategy();
          if (result && result.length > 0) return result;
        }
  
        return [];
      };
  
      // Validate and normalize questions
      const validateQuestions = (rawQuestions: any[]): TestQuestion[] => {
        const validatedQuestions = rawQuestions
          .filter(q => 
            q.text && 
            q.options && 
            Array.isArray(q.options) && 
            q.options.length === 4
          )
          .map((q, index) => ({
            id: q.id || index + 1,
            text: q.text,
            options: q.options,
            type: 'multiple_choice' as const  // Use 'as const' to match the exact type
          }))
          .slice(0, 5);  // Ensure exactly 5 questions

        // If less than 5 questions, generate fallback questions
        while (validatedQuestions.length < 5) {
          validatedQuestions.push({
            id: validatedQuestions.length + 1,
            text: `Pregunta de respaldo ${validatedQuestions.length + 1} sobre ${domain}`,
            options: [
              "Opción A",
              "Opción B (correcta)",
              "Opción C",
              "Opción D"
            ],
            type: 'multiple_choice' as const  // Use 'as const' here as well
          });
        }

        return validatedQuestions;
      };
  
      // Extract and validate questions
      const rawQuestions = extractQuestions(data.reply || '');
      questions = validateQuestions(rawQuestions);
  
      // Log extracted and validated questions
      console.log('Validated Questions:', questions);
  
      // Create test object
      const newTest: Test = {
        domain,
        questions: questions.map(q => ({
          id: q.id,
          text: q.text,
          options: q.options || []
        })),
        currentQuestion: 0,
        answers: []
      };
      
      // Set current test
      setCurrentTest(newTest);
  
      // Mark domain as tested
      setTestedDomains(prev => ({
        ...prev,
        [domain]: true
      }));
      
      // Reset loading state
      setLoadingTest(null);
  
      // Add test start message to chat
      const testModeMessage: Message = {
        id: Date.now(),
        text: `Comenzaste el test de conocimiento para el dominio ${domain}. 
        Instrucciones importantes:
        - Lee cada pregunta cuidadosamente
        - Selecciona la opción que consideres más correcta
        - No busques ayuda externa
        - Confía en tu conocimiento y comprensión del estándar ${isoStandards[selectedStandard].name}
  
        Recuerda: Este test evalúa tu comprensión actual, no busca juzgarte, sino ayudarte a identificar áreas de mejora.`,
        sender: 'bot',
        timestamp: new Date().toISOString()
      };
      setMessages(prev => [...prev, testModeMessage]);
  
    } catch (error) {
      console.error('Error starting knowledge test:', error);
      
      // Resetear los estados
      setCurrentTestDomain(null);
      setLoadingTest(null);
      
      // Mostrar mensaje de error al usuario
      const errorMessage: Message = {
        id: Date.now(),
        text: 'Lo siento, ocurrió un error al generar el test de conocimiento. Por favor, intenta nuevamente.',
        sender: 'bot',
        timestamp: new Date().toISOString()
      };
      setMessages(prev => [...prev, errorMessage]);
    }
  };

  // Función para iniciar un test de aplicación
  const startApplicationTest = async (domain: string) => {
    // Set test type and current domain
    setTestType('application');
    setCurrentTestDomain(domain);
    setShowTestModal(false);
    setTextAnswers([]);
    setLoadingTest('application'); // Establecer que estamos cargando un test de aplicación
    
    try {
      // Generar preguntas de caso práctico usando el endpoint de IA
      const prompt = `Genera 3 preguntas para evaluar la aplicación práctica de ${domain} en el contexto de ${isoStandards[selectedStandard].name} en una organización real. Las preguntas deben requerir respuestas de texto libre donde el usuario explique cómo implementaría aspectos específicos del estándar. Estructura la respuesta en formato JSON con este esquema: 
      {
        "questions": [
          {
            "id": 1,
            "text": "Texto de la pregunta",
            "type": "text"
          }
        ]
      }`;
  
      const response = await fetch('https://backend.anubisai.net/api/bot/llm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiToken}`
        },
        body: JSON.stringify({
          prompt: [{ 
            content: prompt,
            role: 'user',
            time: new Date().toISOString()
          }],
          chatId: `app_test_${selectedStandard}_${domain.replace(/\s+/g, '_').toLowerCase()}`,
          skill: 'general_v1',
          module: 'general_general'
        }),
      });
  
      if (!response.ok) {
        throw new Error(`Error al generar preguntas: ${response.status}`);
      }
  
      const data = await response.json();
      let questions: TestQuestion[] = [];
      
      try {
        // Comprehensive JSON parsing with detailed extraction methods
        const responseText = data.reply || '';
        console.log('Full response text:', responseText); // Debugging log
  
        let jsonData: any = null;
  
        // Multiple strategies to extract and parse JSON
        const extractionStrategies = [
          // Strategy 1: Exact ```json``` block
          () => {
            const jsonBlockMatch = responseText.match(/```json\s*([\s\S]*?)\s*```/);
            if (jsonBlockMatch) {
              try {
                return JSON.parse(jsonBlockMatch[1].trim());
              } catch (blockParseError) {
                console.error('Error parsing JSON block:', blockParseError);
                return null;
              }
            }
            return null;
          },
          
          // Strategy 2: First complete JSON object/array
          () => {
            const jsonObjectMatch = responseText.match(/(\{[\s\S]*\}|\[[\s\S]*\])/);
            if (jsonObjectMatch) {
              try {
                return JSON.parse(jsonObjectMatch[1]);
              } catch (objectParseError) {
                console.error('Error parsing JSON object:', objectParseError);
                return null;
              }
            }
            return null;
          },
          
          // Strategy 3: Extract JSON from text
          () => {
            try {
              // Remove any leading/trailing text, focus on JSON-like content
              const jsonMatch = responseText.match(/(\{[^}]*\}|\[[^\]]*\])/);
              if (jsonMatch) {
                return JSON.parse(jsonMatch[1]);
              }
            } catch (textParseError) {
              console.error('Error parsing JSON from text:', textParseError);
            }
            return null;
          }
        ];
  
        // Try extraction strategies
        for (const strategy of extractionStrategies) {
          jsonData = strategy();
          if (jsonData) break;
        }
  
        // Normalize questions extraction
        if (jsonData) {
          questions = jsonData.questions || 
                      (Array.isArray(jsonData) ? jsonData : []);
        }
  
        // If no questions found, use fallback
        if (!questions || questions.length === 0) {
          console.warn('No valid questions found, using default questions');
          questions = [
            {
              id: 1,
              text: `Describe cómo implementarías una política de ${domain} en una organización mediana que cumpla con los requisitos de ${isoStandards[selectedStandard].name}. Incluye los componentes clave y el proceso de aprobación.`,
              type: 'text'
            },
            {
              id: 2,
              text: `Explica el proceso que seguirías para revisar y actualizar los controles de ${domain} existentes. ¿Qué factores considerarías y qué partes interesadas involucrarías?`,
              type: 'text'
            },
            {
              id: 3,
              text: `Describe un plan de implementación para asegurar que todas las partes relevantes entiendan y cumplan con los requisitos de ${domain} según ${isoStandards[selectedStandard].name}.`,
              type: 'text'
            }
          ];
        }
  
        // Ensure each question has all required properties
        questions = questions.map((q, index) => ({
          id: q.id || index + 1,
          text: q.text || `Pregunta ${index + 1}`,
          type: q.type || 'text'
        }));
  
        // Log extracted questions for debugging
        console.log('Extracted questions:', questions);
      } catch (error) {
        console.error("Comprehensive error parsing question data:", error);
        
        // Absolute fallback to predefined questions
        questions = [
          {
            id: 1,
            text: `Describe cómo implementarías requisitos de ${domain} en una organización mediana según ${isoStandards[selectedStandard].name}.`,
            type: 'text'
          },
          {
            id: 2,
            text: `Explica cómo verificarías el cumplimiento de ${domain} en una auditoría de ${isoStandards[selectedStandard].name}.`,
            type: 'text'
          },
          {
            id: 3,
            text: `¿Qué desafíos anticipas en la implementación de ${domain} y cómo los abordarías?`,
            type: 'text'
          }
        ];
      }
  
      // Crear un nuevo test
      const newTest: Test = {
        domain,
        questions: questions.map(q => ({
          id: q.id,
          text: q.text,
          options: [] // Para tests de tipo texto, no hay opciones
        })),
        currentQuestion: 0,
        answers: []
      };
      
      // Establecer el test actual
      setCurrentTest(newTest);
  
      // Inicializar array de respuestas de texto vacías
      setTextAnswers(new Array(questions.length).fill(''));
  
      // Marcar el dominio como probado
      setTestedDomains(prev => {
        const updated: TestedDomainsState = { ...prev };
        if (currentTest && currentTest.domain) {
          updated[currentTest.domain] = true;
        }
        return updated;
      });
      
      // Resetear estado de carga
      setLoadingTest(null);
  
      // Añadir un mensaje al chat sobre el inicio del test
      const testModeMessage: Message = {
        id: Date.now(),
        text: `Comenzaste el test de aplicación práctica para el dominio ${domain}. 
        Instrucciones importantes:
        - Lee cada pregunta cuidadosamente
        - Responde con ejemplos concretos y detallados
        - Muestra tu comprensión práctica de ${isoStandards[selectedStandard].name}
        - Sé específico y claro en tus explicaciones
  
        Recuerda: Este test evalúa tu capacidad para aplicar conceptos del estándar en situaciones reales.`,
        sender: 'bot',
        timestamp: new Date().toISOString()
      };
      setMessages(prev => [...prev, testModeMessage]);
  
    } catch (error) {
      console.error('Error starting application test:', error);
      
      // Resetear los estados
      setCurrentTestDomain(null);
      setLoadingTest(null);
      
      // Mostrar mensaje de error al usuario
      const errorMessage: Message = {
        id: Date.now(),
        text: 'Lo siento, ocurrió un error al generar el test de aplicación práctica. Por favor, intenta nuevamente.',
        sender: 'bot',
        timestamp: new Date().toISOString()
      };
      setMessages(prev => [...prev, errorMessage]);
    }
  };

  // Función para manejar respuestas de opción múltiple
  const handleTestAnswer = async (answer: string) => {
    try {
      // Immediate validation of current test
      if (!currentTest) {
        console.error('No active test found');
        setMessages(prev => [...prev, {
          id: Date.now(),
          text: 'Error: No hay un test activo. Por favor, reinicia la evaluación.',
          sender: 'bot',
          timestamp: new Date().toISOString()
        }]);
        return;
      }
  
      // Deep clone to prevent mutation issues
      const testCopy: Test = JSON.parse(JSON.stringify(currentTest));
  
      // Validate questions array
      if (!testCopy.questions || testCopy.questions.length === 0) {
        console.error('No questions found in the test', testCopy);
        setCurrentTest(null);
        setTestType(null);
        setIsEvaluating(false); // Reset evaluating state
        setCurrentTestDomain(null); // Reset test domain
        setMessages(prev => [...prev, {
          id: Date.now(),
          text: 'Error: No se encontraron preguntas en el test.',
          sender: 'bot',
          timestamp: new Date().toISOString()
        }]);
        return;
      }
  
      // Precise index calculations
      const maxQuestionIndex = testCopy.questions.length - 1;
  
      // Detailed logging of test state
      console.log('Test State Before Answer:', {
        domain: testCopy.domain,
        currentQuestion: testCopy.currentQuestion,
        totalQuestions: testCopy.questions.length,
        answers: testCopy.answers,
        lastAnswer: answer
      });
  
      // Create updated test state
      const updatedTest: Test = {
        ...testCopy,
        answers: [...(testCopy.answers || []), answer],
        currentQuestion: testCopy.currentQuestion + 1
      };
      
      // Update current test state
      setCurrentTest(updatedTest);
  
      // Check if test is complete
      if (updatedTest.currentQuestion > maxQuestionIndex) {
        try {
          setIsEvaluating(true);
          
          // Evaluate the test
          const evaluationResult = await evaluateTest(updatedTest, testCopy);
  
          // Prepare detailed completion message
          const completionMessage = `
## 🏆 Test de Conocimiento Completado

### Puntuación: ${evaluationResult.totalScore}/100

${evaluationResult.totalScore >= 90 ? '🌟 ¡Excelente trabajo!' : 
 evaluationResult.totalScore >= 70 ? '👍 Buen desempeño' : 
 '📚 Hay oportunidades de mejora'}

#### Retroalimentación General
${evaluationResult.feedback}

#### Desglose de Respuestas
${evaluationResult.questionsEvaluation.map((qEval, index) => 
  `**Pregunta ${index + 1}:**
  - *Puntuación*: ${qEval.score}/100
  - *Feedback*: ${qEval.feedback}`
).join('\n\n')}

[Volver a la lista de tests](#)
`;
  
          // Add completion message to chat
          setMessages(prev => [...prev, {
            id: Date.now(),
            text: completionMessage,
            sender: 'bot',
            timestamp: new Date().toISOString()
          }]);
  
          // Prepare test result
          const testResult: TestResult = {
            domainId: testCopy.domain,
            testType: 'knowledge',
            totalScore: evaluationResult.totalScore,
            maxScore: 100,
            percentage: evaluationResult.totalScore,
            questions: testCopy.questions.map(q => ({
              id: q.id,
              text: q.text,
              options: q.options,
              type: 'multiple_choice'
            })),
            answers: updatedTest.answers.map((answer, idx) => ({
              questionId: testCopy.questions[idx].id,
              answer,
              score: evaluationResult.questionsEvaluation[idx]?.score || 0,
              feedback: evaluationResult.questionsEvaluation[idx]?.feedback || ''
            })),
            timestamp: new Date().toISOString(),
            feedback: evaluationResult.feedback
          };
  
          // Save result and update progress
          await Promise.all([
            dbOperations.saveTestResult(selectedStandard, testResult),
            dbOperations.updateProgressData(selectedStandard, {
              date: new Date().toISOString().split('T')[0],
              score: evaluationResult.totalScore,
              standard: selectedStandard,
              domain: testCopy.domain,
              testType: 'knowledge' // Especificar el tipo de test
            })
          ]);
  
          // Update local state with the current test result for summary display
          setEvaluation(prev => ({
            ...(prev || { score: 0 }),
            [testCopy.domain]: evaluationResult.totalScore
          }));
  
          // Reload data
          const [progress, results] = await Promise.all([
            dbOperations.getProgressData(selectedStandard),
            dbOperations.getTestResults(selectedStandard)
          ]);
  
          setProgressData(progress);
          setTestResults(results);
          // Actualizar estadísticas
          filterDataByDateRange();
          setTestedDomains(prev => {
            const updated: TestedDomainsState = { ...prev };
            if (testCopy && testCopy.domain) {
              updated[testCopy.domain] = true;
            }
            return updated;
          });

        } catch (evaluationError) {
          console.error('Error during test evaluation:', evaluationError);
          
          // Error handling for evaluation
          setMessages(prev => [...prev, {
            id: Date.now(),
            text: 'Ocurrió un error al evaluar el test. Por favor, intenta nuevamente.',
            sender: 'bot',
            timestamp: new Date().toISOString()
          }]);
        } finally {
          // Always reset evaluating state
          setIsEvaluating(false);
          // But DON'T reset test state and test domain here to allow summary display
          // We'll keep currentTest and currentTestDomain for the summary panel
        }
      }
    } catch (error) {
      console.error('Critical error in handleTestAnswer:', error);
      
      // Comprehensive error reset
      setCurrentTest(null);
      setTestType(null);
      setIsEvaluating(false);
      setCurrentTestDomain(null);
      
      setMessages(prev => [...prev, {
        id: Date.now(),
        text: 'Ocurrió un error crítico durante el test. Por favor, reinicie la evaluación.',
        sender: 'bot',
        timestamp: new Date().toISOString()
      }]);
    }
  };

  const verifyTestCompletionStatus = () => {
    // Verificar si hay resultados de prueba que no estén marcados como completados
    if (testResults && testResults.length > 0) {
      console.log("Verificando estado de tests completados:", testResults);
      
      // Crear un nuevo objeto testedDomains basado en los resultados reales
      const updatedTestedDomains: TestedDomainsState = {};
      
      // Procesar todos los resultados
      testResults.forEach(result => {
        updatedTestedDomains[result.domainId] = true;
        
        // También actualizar el estado de evaluación para este dominio
        setEvaluation(prev => ({
          ...(prev || { score: 0 }),
          [result.domainId]: result.percentage
        }));
      });
      
      console.log("Dominios actualizados:", updatedTestedDomains);
      
      // Actualizar el estado testedDomains si hay cambios
      setTestedDomains(updatedTestedDomains);
    }
  };
  
  // Asegurarse de que se verifique el estado después de cargar los resultados
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        // Cargar resultados de tests primero
        const results = await dbOperations.getTestResults(selectedStandard);
        console.log("Test results loaded:", results);
        setTestResults(results);
        
        // Crear un mapa de dominio -> puntuación basado en los resultados
        const domainScoresMap: {[domain: string]: number} = {};
        results.forEach(result => {
          domainScoresMap[result.domainId] = result.percentage;
        });
        
        // Cargar historial de evaluaciones
        const history = await dbOperations.getEvaluationHistory(selectedStandard);
        
        // Combinar con los resultados de tests para un estado completo
        setEvaluation({
          score: history.overallScore,
          ...history.evaluations.reduce((acc, evaluation) => ({
            ...acc,
            [evaluation?.domain]: evaluation?.score
          }), {}),
          // Añadir los scores de los tests
          ...domainScoresMap
        });
        
        // Actualizar testedDomains basado en los resultados de tests
        const updatedTestedDomains: TestedDomainsState = {};
        results.forEach(result => {
          updatedTestedDomains[result.domainId] = true;
        });
        setTestedDomains(updatedTestedDomains);
        
        // Cargar datos de progreso
        const progress = await dbOperations.getProgressData(selectedStandard);
        setProgressData(progress);
        
      } catch (error) {
        console.error('Error loading data:', error);
      } finally {
        setLoading(false);
      }
    };
  
    loadData();
  }, [selectedStandard]);

  // Separate function for test evaluation
  const evaluateTest = async (updatedTest: Test, originalTest: Test): Promise<EvaluationData> => {
    const answers = updatedTest.answers;
    const questions = originalTest.questions;
    
    const evaluationPrompt = `Evalúa las siguientes respuestas de un test sobre ${originalTest.domain} en el contexto de ${isoStandards[selectedStandard].name}.
    
    Preguntas y respuestas:
    ${questions.map((q, idx) => `Pregunta ${idx + 1}: ${q.text}
    Respuesta: ${answers[idx]}`).join('\n\n')}
    
    Por favor, evalúa cada respuesta y proporciona un puntaje entre 0 y 100 para el desempeño general.
    Estructura tu respuesta en formato JSON con el siguiente esquema:
    {
      "evaluation": [
        {
          "questionId": 1,
          "score": 80,
          "feedback": "Comentario sobre la respuesta"
        }
      ],
      "totalScore": 85,
      "generalFeedback": "Comentario general sobre el desempeño"
    }`;
  
    const response = await fetch('https://backend.anubisai.net/api/bot/llm', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiToken}`
      },
      body: JSON.stringify({
        prompt: [{ 
          content: evaluationPrompt,
          role: 'user',
          time: new Date().toISOString()
        }],
        chatId: `evaluation_${selectedStandard}_${originalTest.domain.replace(/\s+/g, '_').toLowerCase()}`,
        skill: 'general_v1',
        module: 'general_general'
      }),
    });
  
    if (!response.ok) {
      throw new Error(`Error al evaluar respuestas: ${response.status}`);
    }
  
    const data = await response.json();
    
    // Comprehensive JSON parsing
    let evaluationData: EvaluationData = {
      questionsEvaluation: [],
      totalScore: 0,
      feedback: 'No se pudo procesar la evaluación'
    };
    
    try {
      const responseText = data.reply || '';
      console.log('Raw Evaluation Response:', responseText);
  
      const jsonMatch = responseText.match(/\{[\s\S]*\}/);
      
      if (jsonMatch) {
        const cleanedJson = jsonMatch[0].trim();
        console.log('Cleaned JSON:', cleanedJson);
        
        const jsonData = JSON.parse(cleanedJson);
        evaluationData = {
          questionsEvaluation: jsonData.evaluation || [],
          totalScore: jsonData.totalScore || 0,
          feedback: jsonData.generalFeedback || 'No se proporcionó retroalimentación general'
        };
      } else {
        console.error('No JSON found in response');
        throw new Error('No JSON found');
      }
    } catch (error) {
      console.error("Error parsing evaluation data:", error);
      
      // Fallback evaluation
      const randomScores = questions.map((_, idx) => ({
        questionId: idx + 1,
        score: Math.floor(Math.random() * 40) + 60,
        feedback: 'Evaluación generada automáticamente'
      }));
      
      const avgScore = randomScores.reduce((sum, item) => sum + item.score, 0) / randomScores.length;
      
      evaluationData = {
        questionsEvaluation: randomScores,
        totalScore: Math.round(avgScore),
        feedback: 'Evaluación generada automáticamente debido a un error de procesamiento.'
      };
    }
  
    return evaluationData;
  };

  // Función para manejar el cambio en respuestas de texto
  const handleTextAnswerChange = (index: number, value: string) => {
    const newAnswers = [...textAnswers];
    newAnswers[index] = value;
    setTextAnswers(newAnswers);
  };

  // Función para enviar respuestas de texto
  const submitTextAnswers = async () => {
    if (!currentTest) return;
    
    try {
      setIsEvaluating(true);
      
      // Evaluar las respuestas de texto usando el endpoint de IA
      const questions = currentTest.questions;
      
      const evaluationPrompt = `Evalúa las siguientes respuestas de un test práctico sobre ${currentTest.domain} en el contexto de ${isoStandards[selectedStandard].name}.
      
      Preguntas y respuestas:
      ${questions.map((q, idx) => `Pregunta ${idx + 1}: ${q.text}
      Respuesta: ${textAnswers[idx] || 'Sin respuesta'}`).join('\n\n')}
      
      Por favor, evalúa cada respuesta detalladamente considerando:
      - Comprensión del contexto de ${isoStandards[selectedStandard].name}
      - Aplicación práctica de los conceptos
      - Claridad y completitud
      - Adherencia a las mejores prácticas
      
      Proporciona un puntaje entre 0 y 100 para cada respuesta y para el desempeño general.
      Estructura tu respuesta en formato JSON con el siguiente esquema:
      {
        "evaluation": [
          {
            "questionId": 1,
            "score": 80,
            "feedback": "Análisis detallado de la respuesta señalando fortalezas y áreas de mejora"
          }
        ],
        "totalScore": 85,
        "generalFeedback": "Evaluación completa del desempeño, identificando áreas de fortaleza y oportunidades de mejora"
      }`;

      const response = await fetch('https://backend.anubisai.net/api/bot/llm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiToken}`
        },
        body: JSON.stringify({
          prompt: [{ 
            content: evaluationPrompt,
            role: 'user',
            time: new Date().toISOString()
          }],
          chatId: `practical_evaluation_${selectedStandard}_${currentTest.domain.replace(/\s+/g, '_').toLowerCase()}`,
          skill: 'general_v1',
          module: 'general_general'
        }),
      });

      if (!response.ok) {
        throw new Error(`Error al evaluar respuestas: ${response.status}`);
      }

      const data = await response.json();
      
      // Procesar la evaluación
      let evaluationData: EvaluationData = {
        questionsEvaluation: [],
        totalScore: 0,
        feedback: 'No se pudo procesar la evaluación'
      };
      
      try {
        // Intentar extraer la evaluación del formato JSON en la respuesta
        const responseText = data.reply || '';
        const jsonMatch = responseText.match(/```json\s*([\s\S]*?)\s*```/) || 
                          responseText.match(/{[\s\S]*?}/);
        
        if (jsonMatch) {
          const jsonData = JSON.parse(jsonMatch[0].replace(/```json|```/g, '').trim());
          evaluationData = {
            questionsEvaluation: jsonData.evaluation || [],
            totalScore: jsonData.totalScore || 0,
            feedback: jsonData.generalFeedback || 'No se proporcionó retroalimentación general'
          };
        }
      } catch (error) {
        console.error("Error parsing evaluation data:", error);
        // Si no podemos extraer JSON, simular una evaluación
        const randomScores: QuestionEvaluation[] = questions.map((_, idx) => ({
          questionId: idx + 1,
          score: Math.floor(Math.random() * 40) + 60, // Puntuación entre 60 y 100
          feedback: 'Evaluación generada automáticamente'
        }));
        
        const avgScore = randomScores.reduce((sum, item) => sum + item.score, 0) / randomScores.length;
        
        evaluationData = {
          questionsEvaluation: randomScores,
          totalScore: Math.round(avgScore),
          feedback: 'Evaluación generada automáticamente. No se pudo procesar la evaluación detallada.'
        };
      }
      
      // Crear un objeto de resultado de test
      const testResult: TestResult = {
        domainId: currentTest.domain,
        testType: 'application',
        totalScore: evaluationData.totalScore,
        maxScore: 100,
        percentage: evaluationData.totalScore,
        questions: questions.map(q => ({
          id: q.id,
          text: q.text,
          type: 'text'
        })),
        answers: textAnswers.map((answer, idx) => ({
          questionId: questions[idx].id,
          answer,
          score: evaluationData.questionsEvaluation[idx]?.score || 0,
          feedback: evaluationData.questionsEvaluation[idx]?.feedback || ''
        })),
        timestamp: new Date().toISOString(),
        feedback: evaluationData.feedback
      };
      
      // Guardar el resultado
      await dbOperations.saveTestResult(selectedStandard, testResult);
      
      // Actualizar datos de progreso
      const progressEntry: ProgressData = {
        date: new Date().toISOString().split('T')[0],
        score: evaluationData.totalScore,
        standard: selectedStandard,
        domain: currentTest.domain,
        testType: 'application' // Especificar el tipo de test
      };
      await dbOperations.updateProgressData(selectedStandard, progressEntry);

      // Actualizar estado local
      setEvaluation(prev => ({
        ...(prev || { score: 0 }),
        [currentTest.domain]: evaluationData.totalScore
      }));

      // Recargar datos actualizados
      const progress = await dbOperations.getProgressData(selectedStandard);
      setProgressData(progress);
      
      // Actualizar resultados de tests
      const results = await dbOperations.getTestResults(selectedStandard);
      setTestResults(results);

      // Asegurarse de que testedDomains se actualice correctamente
    setTestedDomains(prev => ({
      ...prev,
      [currentTest.domain]: true
    }));

      // Mostrar mensaje con el resultado detallado en formato Markdown
      setMessages(prev => [...prev, {
        id: Date.now(),
        text: `
## 🏆 Test de Aplicación Práctica Completado

### Puntuación: ${evaluationData.totalScore}/100

${evaluationData.totalScore >= 90 ? '🌟 ¡Excelente trabajo!' : 
 evaluationData.totalScore >= 70 ? '👍 Buen desempeño' : 
 '📚 Hay oportunidades de mejora'}

#### Retroalimentación General
${evaluationData.feedback}

#### Desglose de Respuestas
${evaluationData.questionsEvaluation.map((evaluation, idx) => 
  `**Pregunta ${idx + 1}:**
  - *Puntuación*: ${evaluation?.score}/100
  - *Feedback*: ${evaluation?.feedback}`
).join('\n\n')}

[Volver a la lista de tests](#)
`,
        sender: 'bot',
        timestamp: new Date().toISOString()
      }]);

      // NO resetear el estado del test aquí para mostrar el resumen
      setIsEvaluating(false);
      // Mantenemos currentTest y currentTestDomain para el panel de resumen

    } catch (error) {
      console.error('Error evaluating application test:', error);
      // Mostrar mensaje de error
      setMessages(prev => [...prev, {
        id: Date.now(),
        text: 'Lo siento, ocurrió un error al evaluar tus respuestas del test práctico.',
        sender: 'bot',
        timestamp: new Date().toISOString()
      }]);
      
      // Resetear el test en caso de error
      setCurrentTest(null);
      setTestType(null);
      setTextAnswers([]);
      setCurrentTestDomain(null);
      setIsEvaluating(false);
    }
  };

  // Add this new function to handle returning to test list
  const returnToTestList = () => {
    // Reset test-related states
    setCurrentTest(null);
    setTestType(null);
    setTextAnswers([]);
    setCurrentTestDomain(null);
    setLoadingTest(null);
  };

  const getScoreColor = (score: number) => {
    if (score === 0) return "#FFA500"; // Naranja para pendiente
    if (score < 5) return "#8b0000"; // Rojo oscuro para extremadamente deficiente
    if (score === 100) return "#28a745"; // Verde para 100%
    
    // Para puntuaciones de 5 a 99, interpolar entre rojo y verde
    // Convertimos de 5-100 a 0-1 para la interpolación
    const normalizedScore = (score - 5) / 95;
    
    // RGB para rojo: 220,53,69
    // RGB para verde: 40,167,69
    const r = Math.round(220 - normalizedScore * (220 - 40));
    const g = Math.round(53 + normalizedScore * (167 - 53));
    const b = Math.round(69);
    
    return `rgb(${r},${g},${b})`;
  };
  
  
const renderTestPanel = () => {
  // Si hay un test activo y aún no se ha completado, mostrar el test
  if (currentTest && currentTest.currentQuestion < currentTest.questions.length) {
    return (
      <>
        <div className="mb-3">
          <Button 
            variant="outline-danger" 
            onClick={exitTest} 
            className="me-2"
          >
            Salir del Test
          </Button>
        </div>
        
        {testType === 'knowledge' ? (
          !isEvaluating ? (
            <div>
              <p className="fw-bold mb-3">
                Pregunta {currentTest.currentQuestion + 1} de {currentTest.questions.length}
              </p>
              <p className="mb-3">{currentTest.questions[currentTest.currentQuestion].text}</p>
              <div className="d-grid gap-2">
                {currentTest.questions[currentTest.currentQuestion].options.map((option, idx) => (
                  <Button
                    key={idx}
                    variant="outline-primary"
                    onClick={() => handleTestAnswer(option)}
                    className="text-start"
                  >
                    {option}
                  </Button>
                ))}
              </div>
            </div>
          ) : (
            <div className="text-center py-4">
              <Spinner animation="border" role="status" className="mb-3">
                <span className="visually-hidden">Cargando...</span>
              </Spinner>
              <p className="h5">Espere un momento, evaluando...</p>
            </div>
          )
        ) : (
          <div>
            <p className="fw-bold mb-3">Test de Aplicación Práctica</p>
            <p className="text-muted mb-4">
              Responde a las siguientes preguntas explicando cómo aplicarías los conceptos de {currentTest?.domain || 'este dominio'} en una situación real.
            </p>
            
            {currentTest?.questions?.map((question, idx) => (
              <div key={idx} className="mb-4">
                <p className="mb-2">{question.text}</p>
                <FloatingLabel controlId={`textAnswer-${idx}`} label="Tu respuesta">
                  <Form.Control
                    as="textarea"
                    value={textAnswers[idx] || ''}
                    onChange={(e) => handleTextAnswerChange(idx, e.target.value)}
                    style={{ height: '120px' }}
                    placeholder="Escribe tu respuesta aquí"
                  />
                </FloatingLabel>
              </div>
            )) || (
              <div className="alert alert-warning">
                No hay preguntas disponibles para este test.
              </div>
            )}
            
            <div className="d-flex justify-content-end mt-3">
              <Button
                variant="primary"
                onClick={submitTextAnswers}
                disabled={isEvaluating}
              >
                {isEvaluating ? 'Evaluando...' : 'Enviar Respuestas'}
              </Button>
            </div>
          </div>
        )}
      </>
    );
  }
  
  // Si hay un test activo pero se ha completado (o está en evaluación), mostrar un resumen o spinner
  else if (currentTest || (currentTestDomain && isEvaluating)) {
    if (isEvaluating) {
      return (
        <div className="text-center py-4">
          <Spinner animation="border" role="status" className="mb-3">
            <span className="visually-hidden">Cargando...</span>
          </Spinner>
          <p className="h5">Espere un momento, evaluando...</p>
        </div>
      );
    } else {
      // Test completado, mostrar resumen
      const domainScore = evaluation?.[currentTest?.domain || currentTestDomain || ''] || 0;
      
      return (
        <div className="p-4 text-center">
          <div className="mb-4">
            <div className={`display-1 mb-3 ${
              domainScore >= 90 ? 'text-success' : 
              domainScore >= 70 ? 'text-primary' : 
              'text-warning'
            }`}>
              {domainScore}%
            </div>
            
            <h4 className="mb-4">
              {domainScore >= 90 ? '🌟 ¡Excelente trabajo!' : 
               domainScore >= 70 ? '👍 Buen desempeño' : 
               '📚 Hay oportunidades de mejora'}
            </h4>
            
            <p className="mb-4">
              Has completado el test de {testType === 'knowledge' ? 'conocimiento' : 'aplicación práctica'} para el dominio <strong>{currentTest?.domain || currentTestDomain}</strong>.
            </p>
            
            <div className="d-flex justify-content-center">
              <Button 
                variant="primary" 
                size="lg" 
                onClick={returnToTestList}
                className="px-4"
              >
                Volver a la lista de tests
              </Button>
            </div>
          </div>
          
          <div className="mt-5">
            <p className="text-muted">
              Revisa el chat para ver retroalimentación detallada sobre tu desempeño.
            </p>
          </div>
        </div>
      );
    }
  }
  
  // Si no hay un test activo, mostrar la lista de dominios
  else {
    return (
      <Row className="g-2">
        {isoStandards[selectedStandard].domains.map((domain, domainIndex) => {
          // Obtener la puntuación del dominio si existe
          const domainScore = evaluation?.[domain] || 0;
          
          // Verificar explícitamente si el dominio ha sido evaluado
          const isDomainTested = testedDomains[domain] || false;
          
          // Determinar el color del dominio para el icono
          const domainColor = domainColors[domainIndex % domainColors.length];
          
          // Determinar el icono según la puntuación
          let StatusIcon;
          let statusTooltip;
          
          if (domainScore === 100) {
            // Puntuación perfecta
            StatusIcon = CheckCircle;
            statusTooltip = "Competencia completa";
          } else if (domainScore >= 70) {
            // Buen rendimiento pero no perfecto
            StatusIcon = Award;
            statusTooltip = "Competente - Puede mejorar";
          } else if (domainScore >= 50) {
            // Rendimiento medio
            StatusIcon = AlertCircle;
            statusTooltip = "Parcialmente competente";
          } else if (domainScore > 0) {
            // Rendimiento bajo
            StatusIcon = AlertTriangle;
            statusTooltip = "No competente - Requiere más estudio";
          } else {
            // No evaluado
            StatusIcon = FileQuestion;
            statusTooltip = "Pendiente de evaluación";
          }
          
          // Obtener el color para el porcentaje según la puntuación
          const scoreColor = getScoreColor(domainScore);
          
          return (
            <Col md={6} key={domain}>
              <Button
                variant={isDomainTested ? "outline-success" : "outline-primary"}
                onClick={() => showTestSelectionModal(domain)}
                className="w-100 mb-2"
                disabled={currentTestDomain !== null}
                title={statusTooltip}
              >
                <div className="d-flex flex-column align-items-center">
                  {currentTestDomain === domain ? (
                    // Mostrar spinner solo en el dominio seleccionado
                    <Spinner 
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="mb-1"
                    />
                  ) : (
                    // Mostrar el icono según el nivel de competencia
                    <StatusIcon 
                      size={20} 
                      className="mb-1" 
                      color={domainColor}
                    />
                  )}
                  <span>Evaluar: {domain}</span>
                  {isDomainTested ? (
                    <small className="mt-1" style={{ color: scoreColor, fontWeight: 'bold' }}>
                      {domainScore}%
                    </small>
                  ) : (
                    <small className="mt-1" style={{ color: "#FFA500", fontWeight: 'bold' }}>
                      Pendiente
                    </small>
                  )}
                </div>
              </Button>
            </Col>
          );
        })}
      </Row>
    );
  }
};


// También modificamos el modal de selección de tipo de test para usar los colores correspondientes
const renderTestSelectionModal = () => {
  // Encontrar el índice del dominio seleccionado para obtener su color
  const domainIndex = isoStandards[selectedStandard].domains.findIndex(d => d === selectedDomain);
  const domainColor = domainIndex >= 0 ? domainColors[domainIndex % domainColors.length] : '#0d6efd';
  
  return (
    <Modal 
      show={showTestModal} 
      onHide={() => setShowTestModal(false)}
      centered
    >
      <Modal.Header closeButton style={{ borderBottom: `3px solid ${domainColor}` }}>
        <Modal.Title>
          <div className="d-flex align-items-center">
            <div 
              className="rounded-circle me-2" 
              style={{ 
                backgroundColor: domainColor, 
                width: '12px', 
                height: '12px' 
              }}
            ></div>
            Seleccionar Tipo de Evaluación - {selectedDomain}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="mb-4">Selecciona el tipo de evaluación para el dominio <strong>{selectedDomain}</strong>:</p>
        
        <div className="d-grid gap-3">
          <Button 
            variant="outline-primary"
            className="text-start p-3"
            onClick={() => selectedDomain && startKnowledgeTest(selectedDomain)}
            disabled={loading}
            style={{ borderColor: domainColor, color: domainColor }}
          >
            {loading && loadingTest === 'knowledge' ? (
              <div className="d-flex align-items-center justify-content-center">
                <Spinner 
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-2"
                  style={{ borderColor: domainColor }}
                />
                Cargando Test de Conocimiento...
              </div>
            ) : (
              <div className="d-flex align-items-center">
                <CheckCircle size={24} className="me-3" style={{ color: domainColor }} />
                <div>
                  <h5 className="mb-1">Test de Conocimiento</h5>
                  <p className="text-muted mb-0">Evalúa tu comprensión teórica mediante preguntas de opción múltiple.</p>
                </div>
              </div>
            )}
          </Button>
          
          <Button 
            variant="outline-primary"
            className="text-start p-3"
            onClick={() => selectedDomain && startApplicationTest(selectedDomain)}
            disabled={loading}
            style={{ borderColor: domainColor, color: domainColor }}
          >
            {loading && loadingTest === 'application' ? (
              <div className="d-flex align-items-center justify-content-center">
                <Spinner 
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-2"
                  style={{ borderColor: domainColor }}
                />
                Cargando Test de Aplicación Práctica...
              </div>
            ) : (
              <div className="d-flex align-items-center">
                <FileText size={24} className="me-3" style={{ color: domainColor }} />
                <div>
                  <h5 className="mb-1">Test de Aplicación Práctica</h5>
                  <p className="text-muted mb-0">Evalúa tu capacidad para aplicar conceptos en situaciones reales.</p>
                </div>
              </div>
            )}
          </Button>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button 
          variant="secondary" 
          onClick={() => setShowTestModal(false)}
          disabled={loading}
        >
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

  const domainColors = [
    '#0088FE', // Azul
    '#00C49F', // Verde turquesa
    '#FFBB28', // Amarillo
    '#FF8042', // Naranja
    '#8884d8', // Púrpura
    '#82ca9d', // Verde claro
    '#ffc658', // Ámbar
    '#8dd1e1', // Celeste
  ];

  const getDomainProgressData = (testType: 'knowledge' | 'application') => {
    // Primero filtramos por tipo de test
    const data = filteredProgressData.filter(item => item.testType === testType);
    
    // Agrupamos los datos por dominio
    const domainGroups: { [domain: string]: ProgressData[] } = {};
    
    data.forEach(item => {
      if (!item.domain) return;
      
      if (!domainGroups[item.domain]) {
        domainGroups[item.domain] = [];
      }
      
      domainGroups[item.domain].push(item);
    });
    
    // Convertimos el objeto a un array de objetos con dominio y datos
    return Object.keys(domainGroups).map(domain => ({
      domain,
      data: domainGroups[domain].sort((a, b) => 
        new Date(a.date).getTime() - new Date(b.date).getTime()
      )
    }));
  };
  
  // Componente para el panel de estadísticas
  const StatsPanel = () => {
    return (
      <Card className="mb-4">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <h5 className="mb-0">Estadísticas de Desempeño</h5>
          <div>
            <Form className="d-flex gap-2">
              <Form.Group>
                <Form.Control
                  type="date"
                  value={dateRange.startDate}
                  onChange={(e) => handleDateRangeChange('startDate', e.target.value)}
                  aria-label="Fecha inicial"
                  size="sm"
                />
              </Form.Group>
              <span className="align-self-center">a</span>
              <Form.Group>
                <Form.Control
                  type="date"
                  value={dateRange.endDate}
                  onChange={(e) => handleDateRangeChange('endDate', e.target.value)}
                  aria-label="Fecha final"
                  size="sm"
                />
              </Form.Group>
              <Button 
                variant="outline-primary" 
                size="sm"
                onClick={filterDataByDateRange}
              >
                Filtrar
              </Button>
            </Form>
          </div>
        </Card.Header>
        <Card.Body>
          <Tabs defaultActiveKey="knowledge">
            <Tab eventKey="knowledge" title="Tests de Conocimiento">
              <div className="mb-4">
                <h6>Progreso en Tests de Conocimiento</h6>
                <div style={{ height: '250px' }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis 
                        dataKey="date" 
                        allowDuplicatedCategory={false}
                        tickFormatter={(date) => new Date(date).toLocaleDateString()} 
                      />
                      <YAxis domain={[0, 100]} />
                      <Tooltip 
                        formatter={(value, name) => [`${value}%`, name]}
                        labelFormatter={(date) => new Date(date).toLocaleDateString()}
                        contentStyle={{ backgroundColor: '#f8f9fa', border: '1px solid #ddd' }}
                      />
                      <Legend />
                      
                      {/* Generamos una línea para cada dominio */}
                      {getDomainProgressData('knowledge').map((domainData, index) => (
                        <Line
                          key={domainData.domain}
                          type="monotone"
                          data={domainData.data}
                          dataKey="score"
                          name={domainData.domain}
                          stroke={domainColors[index % domainColors.length]}
                          activeDot={{ r: 8 }}
                        />
                      ))}
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </div>
  
              <h6>Desempeño por Dominio en Tests de Conocimiento</h6>
              <div className="table-responsive">
                <Table hover striped>
                  <thead>
                    <tr>
                      <th>Dominio</th>
                      <th>Puntuación Media</th>
                      <th>Última Prueba</th>
                      <th>Evolución</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {domainStatistics.knowledge.length > 0 ? (
                      domainStatistics.knowledge.map((stat, index) => (
                        <tr key={stat.domain}>
                          <td>
                            <div className="d-flex align-items-center">
                              <div 
                                className="rounded-circle me-2" 
                                style={{ 
                                  backgroundColor: domainColors[index % domainColors.length], 
                                  width: '12px', 
                                  height: '12px' 
                                }}
                              ></div>
                              {stat.domain}
                            </div>
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                            <DynamicColorProgressBar percentage={stat.averageScore} />
                              <span>{stat.averageScore}%</span>
                            </div>
                          </td>
                          <td>
                            {stat.lastTestDate !== 'N/A' 
                              ? new Date(stat.lastTestDate).toLocaleDateString() 
                              : 'N/A'
                            }
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              {stat.improvement > 0 ? (
                                <>
                                  <span className="text-success me-1">+{stat.improvement}%</span>
                                  <i className="bi bi-arrow-up-circle-fill text-success"></i>
                                </>
                              ) : stat.improvement < 0 ? (
                                <>
                                  <span className="text-danger me-1">{stat.improvement}%</span>
                                  <i className="bi bi-arrow-down-circle-fill text-danger"></i>
                                </>
                              ) : (
                                <span className="text-muted">Sin cambios</span>
                              )}
                            </div>
                          </td>
                          <td>
                            <Button
                              variant="outline-info"
                              size="sm"
                              onClick={() => showDomainTestHistory(stat.domain)}
                            >
                              Ver Historial
                            </Button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={5} className="text-center">
                          No hay datos disponibles para el período seleccionado
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Tab>
  
            <Tab eventKey="application" title="Tests de Aplicación Práctica">
              <div className="mb-4">  
                <h6>Progreso en Tests de Aplicación Práctica</h6>
                <div style={{ height: '250px' }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis 
                        dataKey="date"
                        allowDuplicatedCategory={false}
                        tickFormatter={(date) => new Date(date).toLocaleDateString()} 
                      />
                      <YAxis domain={[0, 100]} />
                      <Tooltip 
                        formatter={(value, name) => [`${value}%`, name]}
                        labelFormatter={(date) => new Date(date).toLocaleDateString()}
                        contentStyle={{ backgroundColor: '#f8f9fa', border: '1px solid #ddd' }}
                      />
                      <Legend />
                      
                      {/* Generamos una línea para cada dominio */}
                      {getDomainProgressData('application').map((domainData, index) => (
                        <Line
                          key={domainData.domain}
                          type="monotone"
                          data={domainData.data}
                          dataKey="score"
                          name={domainData.domain}
                          stroke={domainColors[index % domainColors.length]}
                          activeDot={{ r: 8 }}
                        />
                      ))}
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </div>
  
              <h6>Desempeño por Dominio en Tests de Aplicación Práctica</h6>  
              <div className="table-responsive">
                <Table hover striped>
                  <thead>
                    <tr>
                      <th>Dominio</th>  
                      <th>Puntuación Media</th>
                      <th>Última Prueba</th>
                      <th>Evolución</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {domainStatistics.application.length > 0 ? (
                      domainStatistics.application.map((stat, index) => (  
                        <tr key={stat.domain}>
                          <td>
                            <div className="d-flex align-items-center">
                              <div 
                                className="rounded-circle me-2" 
                                style={{ 
                                  backgroundColor: domainColors[index % domainColors.length], 
                                  width: '12px', 
                                  height: '12px' 
                                }}
                              ></div>
                              {stat.domain}
                            </div>
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                            <DynamicColorProgressBar percentage={stat.averageScore} />
                              <span>{stat.averageScore}%</span>
                            </div>
                          </td>
                          <td>
                            {stat.lastTestDate !== 'N/A'
                              ? new Date(stat.lastTestDate).toLocaleDateString()
                              : 'N/A'
                            }
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              {stat.improvement > 0 ? (
                                <>
                                  <span className="text-success me-1">+{stat.improvement}%</span>
                                  <i className="bi bi-arrow-up-circle-fill text-success"></i>
                                </>
                              ) : stat.improvement < 0 ? (
                                <>
                                  <span className="text-danger me-1">{stat.improvement}%</span>
                                  <i className="bi bi-arrow-down-circle-fill text-danger"></i>
                                </>
                              ) : (
                                <span className="text-muted">Sin cambios</span>
                              )}
                            </div>
                          </td>
                          <td>
                            <Button
                              variant="outline-info"
                              size="sm"
                              onClick={() => showDomainTestHistory(stat.domain)}
                            >
                              Ver Historial
                            </Button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={5} className="text-center">
                          No hay datos disponibles para el período seleccionado
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>
    );
  };

  // Modal para mostrar historial detallado de tests por dominio
  const renderTestHistoryModal = () => {
    return (
      <Modal 
        show={showStatsModal} 
        onHide={() => setShowStatsModal(false)}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Historial de Tests: {selectedDomainStats}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {domainTestHistory.length > 0 ? (
            <>
              <div className="mb-4">
                <h6>Evolución de Puntuaciones</h6>
                <div style={{ height: '200px' }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={domainTestHistory.slice().reverse()}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis 
                        dataKey="timestamp" 
                        tickFormatter={(date) => new Date(date).toLocaleDateString()} 
                      />
                      <YAxis domain={[0, 100]} />
                      <Tooltip 
                        formatter={(value) => [`${value}%`, 'Puntuación']}
                        labelFormatter={(date) => new Date(date).toLocaleDateString()}
                      />
                      <Line
                        type="monotone"
                        dataKey="percentage"
                        stroke="#0d6efd"
                        name="Puntuación" 
                        activeDot={{ r: 8 }}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </div>
              
              <h6>Detalle de Tests</h6>
              <div className="table-responsive">
                <Table hover striped>
                  <thead>
                    <tr>
                      <th>Fecha</th>
                      <th>Tipo</th>
                      <th>Puntuación</th>
                      <th>Preguntas</th>
                    </tr>
                  </thead>
                  <tbody>
                    {domainTestHistory.map((test, index) => (
                      <tr key={index}>
                        <td>{new Date(test.timestamp).toLocaleString()}</td>
                        <td>
                          {test.testType === 'knowledge' 
                            ? 'Conocimiento' 
                            : 'Aplicación Práctica'
                          }
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                          <DynamicColorProgressBar percentage={test.percentage} />
                            <span>{test.percentage}%</span>
                          </div>
                        </td>
                        <td>{test.questions.length}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              
              {domainTestHistory.length > 0 && (
                <div className="mt-4">
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Último Feedback Recibido</Accordion.Header>
                      <Accordion.Body>
                        <p><strong>Feedback general:</strong> {domainTestHistory[0].feedback}</p>
                        <hr />
                        <h6>Feedback por pregunta:</h6>
                        <ul>
                          {domainTestHistory[0].answers.map((answer, idx) => (
                            <li key={idx}>
                              <p><strong>Pregunta {idx + 1}:</strong> {domainTestHistory[0].questions[idx]?.text}</p>
                              <p><strong>Tu respuesta:</strong> {answer.answer}</p>
                              <p><strong>Puntuación:</strong> {answer.score}%</p>
                              <p><strong>Feedback:</strong> {answer.feedback}</p>
                            </li>
                          ))}
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              )}
            </>
          ) : (
            <div className="text-center py-5">
              <p className="mb-0">No hay tests registrados para este dominio en el período seleccionado.</p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowStatsModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  // Estilos CSS para el chat
  const chatStyles = `
  <style>
    .dot-flashing {
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #ffffff;
      margin: 0 2px;
      animation: dot-flashing 1s infinite linear alternate;
    }
    
    @keyframes dot-flashing {
      0% { opacity: 0.2; }
      100% { opacity: 1; }
    }
  </style>
  `;

  const getProgressBarColor = (percentage: number): string => {
    // Validar que el porcentaje esté entre 0 y 100
    const validPercentage = Math.min(100, Math.max(0, percentage));
    
    // Convertir el porcentaje a un valor entre 0 y 1
    const normalized = validPercentage / 100;
    
    // RGB para rojo: 220, 53, 69 (#dc3545)
    // RGB para amarillo: 255, 193, 7 (#ffc107)
    // RGB para verde: 40, 167, 69 (#28a745)
    
    let r, g, b;
    
    if (normalized < 0.5) {
      // Interpolar de rojo a amarillo (0% a 50%)
      const factor = normalized * 2; // Ajustar al rango 0-1 para esta mitad
      r = Math.round(220 + (255 - 220) * factor);
      g = Math.round(53 + (193 - 53) * factor);
      b = Math.round(69 + (7 - 69) * factor);
    } else {
      // Interpolar de amarillo a verde (50% a 100%)
      const factor = (normalized - 0.5) * 2; // Ajustar al rango 0-1 para esta mitad
      r = Math.round(255 - (255 - 40) * factor);
      g = Math.round(193 - (193 - 167) * factor);
      b = Math.round(7 + (69 - 7) * factor);
    }
    
    return `rgb(${r}, ${g}, ${b})`;
  };

  // Componente personalizado para la barra de progreso con color dinámico
const DynamicColorProgressBar = ({ percentage }: { percentage: number }) => {
  const color = getProgressBarColor(percentage);
  
  return (
    <div 
      className="progress flex-grow-1 me-2" 
      style={{ height: '8px' }}
    >
      <div 
        className="progress-bar" 
        style={{ 
          width: `${percentage}%`,
          backgroundColor: color,
          transition: 'width 0.5s ease, background-color 0.5s ease'
        }}
      />
    </div>
  );
};

  return (
    <>
      <WidgetsSectionTitle
        title="ISO AI Training Integration System"
        subtitle="Study and practice available ISO standards with progress analysis."
        icon={faCertificate}
        className="my-5" 
      />
      <div className="p-4">
        {/* Selector de estándar ISO */}
        <Row className="mb-4">
          <Col>
            <Form.Group>
              <Form.Label>Seleccionar Estándar ISO</Form.Label>
              <Form.Select
                value={selectedStandard}
                onChange={handleStandardChange}
                className="mb-2"
              >
                {Object.values(isoStandards).map(standard => (
                  <option key={standard.id} value={standard.id}>
                    {standard.name} - {standard.description}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
    
        {/* Métricas principales */}
        <Row className="mb-4">
        <Col md={4}>
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <p className="text-muted mb-1">Puntuación {isoStandards[selectedStandard].name}</p>
                  <h3 className="mb-0">
                    {calculateAverageScore(evaluation, isoStandards[selectedStandard].domains)}%
                  </h3>
                </div>
                <Award size={24} className="text-primary" />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <p className="text-muted mb-1">Dominios Evaluados</p>
                  <h3 className="mb-0">
                    {countEvaluatedDomains(evaluation, isoStandards[selectedStandard].domains)}/
                    {isoStandards[selectedStandard].domains.length}
                  </h3>
                </div>
                <Book size={24} className="text-success" />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <p className="text-muted mb-1">Última Actualización</p>
                  <h3 className="mb-0">
                    {getLatestTestDate(testResults)}
                  </h3>
                </div>
                <BarChart size={24} className="text-info" />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

        {/* Panel de estadísticas */}
        <StatsPanel />
    
        <Row className="mb-4">
          {/* Panel de chat */}
          <Col md={6}>
            <Card>
              <Card.Header>
                <h5 className="mb-0">Asistente {isoStandards[selectedStandard].name}</h5>
              </Card.Header>
              <Card.Body>
                {/* Estilos para el chat */}
                <div dangerouslySetInnerHTML={{ __html: chatStyles }} />
                
                <div 
                  className="chat-container mb-3" 
                  style={{ height: '400px', overflowY: 'auto' }}
                  ref={chatContainerRef}
                >
                  {messages.map(message => renderMessage(message))}
                </div>
                
                <Form className="d-flex gap-2" onSubmit={(e) => { e.preventDefault(); handleSendMessage(); }}>
                  <Form.Control
                    type="text"
                    value={inputMessage}
                    onChange={(e) => setInputMessage(e.target.value)}
                    placeholder={`Pregunta sobre ${isoStandards[selectedStandard].name}...`}
                    disabled={loading} />
                  <Button
                    type="submit"
                    variant="primary"
                    onClick={handleSendMessage}
                    disabled={loading}
                  >
                    <Send size={20} />
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
    
          {/* Panel de pruebas */}
          <Col md={6}>
            <Card>
              <Card.Header>
                <h5 className="mb-0">
                  {currentTest || currentTestDomain
                    ? testType === 'knowledge' 
                      ? `Test de Conocimiento: ${currentTest?.domain || currentTestDomain}`
                      : `Test de Aplicación Práctica: ${currentTest?.domain || currentTestDomain}`
                    : `Evaluación ${isoStandards[selectedStandard].name}`}
                </h5>
              </Card.Header>
              <Card.Body>
                {renderTestPanel()}
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Modal para selección de test */}
        <Modal 
          show={showTestModal} 
          onHide={() => setShowTestModal(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Seleccionar Tipo de Evaluación</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="mb-4">Selecciona el tipo de evaluación para el dominio <strong>{selectedDomain}</strong>:</p>
            
            <div className="d-grid gap-3">
              <Button 
                variant="outline-primary" 
                className="text-start p-3"
                onClick={() => selectedDomain && startKnowledgeTest(selectedDomain)}
                disabled={loading}
              >
                {loading ? (
                  <div className="d-flex align-items-center justify-content-center">
                    <Spinner 
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="me-2"
                    />
                    Cargando Test de Conocimiento...
                  </div>
                ) : (
                  <div className="d-flex align-items-center">
                    <CheckCircle size={24} className="me-3" />
                    <div>
                      <h5 className="mb-1">Test de Conocimiento</h5>
                      <p className="text-muted mb-0">Evalúa tu comprensión teórica mediante preguntas de opción múltiple.</p>
                    </div>
                  </div>
                )}
              </Button>
              
              <Button 
                variant="outline-primary" 
                className="text-start p-3"
                onClick={() => selectedDomain && startApplicationTest(selectedDomain)}
                disabled={loading}
              >
                {loading ? (
                  <div className="d-flex align-items-center justify-content-center">
                    <Spinner 
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="me-2"
                    />
                    Cargando Test de Aplicación Práctica...
                  </div>
                ) : (
                  <div className="d-flex align-items-center">
                    <FileText size={24} className="me-3" />
                    <div>
                      <h5 className="mb-1">Test de Aplicación Práctica</h5>
                      <p className="text-muted mb-0">Evalúa tu capacidad para aplicar conceptos en situaciones reales.</p>
                    </div>
                  </div>
                )}
              </Button>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button 
              variant="secondary" 
              onClick={() => setShowTestModal(false)}
              disabled={loading}
            >
              Cancelar
            </Button>
          </Modal.Footer>
        </Modal>
        
        {/* Modal para historial detallado de tests */}
        {renderTestHistoryModal()}
      </div>
    </>
  );
};

export default ISO27001Dashboard; 
/*
import React, { useState, useEffect, useRef } from 'react';
import { Button, Card, Col, Form, Row, Table, Modal, FloatingLabel, Spinner } from "react-bootstrap";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { MessageSquare, Send, Book, Award, BarChart, CheckCircle, FileText } from 'lucide-react';
import WidgetsSectionTitle from 'components/modules/widgets/WidgetsSectionTitle';
import { faCertificate } from '@fortawesome/free-solid-svg-icons';
import ReactMarkdown from 'react-markdown';

// Definición de tipos de estándares ISO válidos
type ISOStandardType = 'iso27001' | 'iso27000' | 'iso27701';

// Interfaces base
interface ProgressData {
  date: string;
  score: number;
  standard: string;
  domain?: string;
}

interface QuestionEvaluation {
  questionId: number;
  score: number;
  feedback: string;
}

interface EvaluationData {
  questionsEvaluation: QuestionEvaluation[];
  totalScore: number;
  feedback: string;
}

interface Message {
  id: number;
  text: string;
  sender: 'user' | 'bot';
  timestamp: string;
  evaluation?: any;
}

interface Question {
  id: number;
  text: string;
  options: string[];
}

interface Test {
  domain: string;
  questions: Question[];
  currentQuestion: number;
  answers: string[];
}

interface TestQuestion {
  id: number;
  text: string;
  options?: string[];
  type: 'multiple_choice' | 'text' | 'true_false';
}

interface TestAnswer {
  questionId: number;
  answer: string;
  score?: number;
  feedback?: string;
}

interface TestResult {
  domainId: string;
  testType: 'knowledge' | 'application';
  totalScore: number;
  maxScore: number;
  percentage: number;
  questions: TestQuestion[];
  answers: TestAnswer[];
  timestamp: string;
  feedback: string;
}

interface Evaluation {
  score: number;
  [key: string]: number;
}

interface ISOStandard {
  id: string;
  name: string;
  description: string;
  domains: string[];
  questions: {
    [domain: string]: Question[];
  };
}

interface EvaluationHistory {
  date: string;
  score: number;
  domain: string;
  answers: string[];
}

interface StandardProgress {
  standard: string;
  evaluations: EvaluationHistory[];
  lastUpdate: string;
  overallScore: number;
}

// Configuración de estándares ISO
const isoStandards: { [key: string]: ISOStandard } = {
  'iso27001': {
    id: 'iso27001',
    name: 'ISO 27001',
    description: 'Sistema de Gestión de Seguridad de la Información',
    domains: ['Políticas de Seguridad', 'Control de Acceso', 'Operaciones', 'Desarrollo'],
    questions: {
      'Políticas de Seguridad': [
        {
          id: 1,
          text: "¿Tiene documentada una política de seguridad de la información?",
          options: ["Sí", "En proceso", "No"]
        },
        {
          id: 2,
          text: "¿Con qué frecuencia se revisan las políticas de seguridad?",
          options: ["Anualmente", "Cada 6 meses", "Nunca"]
        }
      ],
      'Control de Acceso': [
        {
          id: 1,
          text: "¿Implementa control de acceso basado en roles?",
          options: ["Sí", "Parcialmente", "No"]
        },
        {
          id: 2,
          text: "¿Existe un proceso de revisión periódica de accesos?",
          options: ["Sí", "En desarrollo", "No"]
        }
      ]
    }
  },
  'iso27000': {
    id: 'iso27000',
    name: 'ISO 27000',
    description: 'Fundamentos y Vocabulario de Seguridad de la Información',
    domains: ['Conceptos', 'Terminología', 'Implementación', 'Evaluación'],
    questions: {
      'Conceptos': [
        {
          id: 1,
          text: "¿Se comprenden los conceptos fundamentales de seguridad de la información?",
          options: ["Totalmente", "Parcialmente", "No"]
        },
        {
          id: 2,
          text: "¿El personal está familiarizado con el vocabulario estándar?",
          options: ["Sí", "En proceso", "No"]
        }
      ],
      'Terminología': [
        {
          id: 1,
          text: "¿Se utiliza la terminología estándar en la documentación?",
          options: ["Siempre", "A veces", "Nunca"]
        }
      ]
    }
  },
  'iso27701': {
    id: 'iso27701',
    name: 'ISO 27701',
    description: 'Gestión de Privacidad de la Información',
    domains: ['Privacidad', 'Protección de Datos', 'Consentimiento', 'Derechos ARCO'],
    questions: {
      'Privacidad': [
        {
          id: 1,
          text: "¿Tiene implementadas medidas específicas de privacidad?",
          options: ["Sí", "En proceso", "No"]
        }
      ]
    }
  }
};

// Interfaces para la base de datos simulada
interface MockDatabase {
  progressData: {
    [K in ISOStandardType]: ProgressData[];
  };
  evaluationHistory: {
    [K in ISOStandardType]: StandardProgress;
  };
  testResults: {
    [K in ISOStandardType]: TestResult[];
  };
}

// Base de datos simulada
const mockDatabase: MockDatabase = {
  progressData: {
    'iso27001': [
      { date: '2024-01', score: 45, standard: 'iso27001', domain: 'Políticas de Seguridad' },
      { date: '2024-02', score: 58, standard: 'iso27001', domain: 'Control de Acceso' },
      { date: '2024-03', score: 75, standard: 'iso27001', domain: 'Operaciones' }
    ],
    'iso27000': [
      { date: '2024-01', score: 60, standard: 'iso27000', domain: 'Conceptos' },
      { date: '2024-02', score: 72, standard: 'iso27000', domain: 'Terminología' },
      { date: '2024-03', score: 85, standard: 'iso27000', domain: 'Implementación' }
    ],
    'iso27701': [
      { date: '2024-01', score: 30, standard: 'iso27701', domain: 'Privacidad' },
      { date: '2024-02', score: 45, standard: 'iso27701', domain: 'Protección de Datos' },
      { date: '2024-03', score: 65, standard: 'iso27701', domain: 'Consentimiento' }
    ]
  },
  evaluationHistory: {
    'iso27001': {
      standard: 'iso27001',
      evaluations: [
        {
          date: '2024-03-15',
          domain: 'Políticas de Seguridad',
          score: 75,
          answers: ['Sí', 'Anualmente']
        },
        {
          date: '2024-03-16',
          domain: 'Control de Acceso',
          score: 66,
          answers: ['Parcialmente', 'Sí']
        }
      ],
      lastUpdate: '2024-03-16',
      overallScore: 70
    },
    'iso27000': {
      standard: 'iso27000',
      evaluations: [
        {
          date: '2024-03-14',
          domain: 'Conceptos',
          score: 85,
          answers: ['Totalmente', 'Sí']
        }
      ],
      lastUpdate: '2024-03-14',
      overallScore: 85
    },
    'iso27701': {
      standard: 'iso27701',
      evaluations: [
        {
          date: '2024-03-13',
          domain: 'Privacidad',
          score: 65,
          answers: ['En proceso']
        }
      ],
      lastUpdate: '2024-03-13',
      overallScore: 65
    }
  },
  testResults: {
    'iso27001': [],
    'iso27000': [],
    'iso27701': []
  }
};

// Operaciones de base de datos simuladas
const dbOperations = {
  getProgressData: async (standard: ISOStandardType): Promise<ProgressData[]> => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(mockDatabase.progressData[standard] || []);
      }, 500);
    });
  },

  getEvaluationHistory: async (standard: ISOStandardType): Promise<StandardProgress> => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(mockDatabase.evaluationHistory[standard] || {
          standard,
          evaluations: [],
          lastUpdate: new Date().toISOString(),
          overallScore: 0
        });
      }, 500);
    });
  },

  saveEvaluation: async (standard: ISOStandardType, evaluation: EvaluationHistory): Promise<void> => {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (!mockDatabase.evaluationHistory[standard]) {
          mockDatabase.evaluationHistory[standard] = {
            standard,
            evaluations: [],
            lastUpdate: new Date().toISOString(),
            overallScore: 0
          };
        }
        
        mockDatabase.evaluationHistory[standard].evaluations.push(evaluation);
        mockDatabase.evaluationHistory[standard].lastUpdate = new Date().toISOString();
        
        // Calcular nuevo score general
        const scores = mockDatabase.evaluationHistory[standard].evaluations.map(e => e.score);
        mockDatabase.evaluationHistory[standard].overallScore = 
          scores.reduce((a, b) => a + b, 0) / scores.length;
        
        resolve();
      }, 500);
    });
  },

  updateProgressData: async (standard: ISOStandardType, newData: ProgressData): Promise<void> => {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (!mockDatabase.progressData[standard]) {
          mockDatabase.progressData[standard] = [];
        }
        mockDatabase.progressData[standard].push(newData);
        resolve();
      }, 500);
    });
  },

  saveTestResult: async (standard: ISOStandardType, result: TestResult): Promise<void> => {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (!mockDatabase.testResults[standard]) {
          mockDatabase.testResults[standard] = [];
        }
        mockDatabase.testResults[standard].push(result);
        resolve();
      }, 500);
    });
  },

  getTestResults: async (standard: ISOStandardType): Promise<TestResult[]> => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(mockDatabase.testResults[standard] || []);
      }, 500);
    });
  }
};

// Función para verificar si un valor es un estándar ISO válido
const isValidISOStandard = (value: string): value is ISOStandardType => {
  return ['iso27001', 'iso27000', 'iso27701'].includes(value);
};

// Componente principal
const ISO27001Dashboard = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputMessage, setInputMessage] = useState('');
  const [evaluation, setEvaluation] = useState<Evaluation | null>(null);
  const [currentTest, setCurrentTest] = useState<Test | null>(null);
  const [progressData, setProgressData] = useState<ProgressData[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedStandard, setSelectedStandard] = useState<ISOStandardType>('iso27001');
  const [collapsedMessages, setCollapsedMessages] = useState<number[]>([]);
  const [testResults, setTestResults] = useState<TestResult[]>([]);
  const [testType, setTestType] = useState<'knowledge' | 'application' | null>(null);
  const [isEvaluating, setIsEvaluating] = useState(false);
  const [textAnswers, setTextAnswers] = useState<string[]>([]);
  const [showTestModal, setShowTestModal] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState<string | null>(null);
  const [loadingTest, setLoadingTest] = useState<'knowledge' | 'application' | null>(null);
  const [testedDomains, setTestedDomains] = useState<{[domain: string]: boolean}>({});
  const [currentTestDomain, setCurrentTestDomain] = useState<string | null>(null);
  
  
  // API Token para el endpoint
  const apiToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2N2NiY2RlYWViYzE5ZWU3NjEyNzU5MDEiLCJleHAiOjE3NDY1OTczNzAuNjUzLCJpYXQiOjE3NDE0MDk3NzB9.ujLbx4EEMzYL3HMaNUK9S_utqS0Rt-jXkBbKYKv_2kg';

  // Referencia para el contenedor de chat
  const chatContainerRef = useRef<HTMLDivElement>(null);

  // Función para hacer scroll hasta el final del chat
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };

  // Función para alternar el estado colapsado/expandido de un mensaje
  const toggleMessageCollapse = (messageId: number) => {
    setCollapsedMessages(prev => 
      prev.includes(messageId)
        ? prev.filter(id => id !== messageId)
        : [...prev, messageId]
    );
  };

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        // Cargar datos de progreso
        const progress = await dbOperations.getProgressData(selectedStandard);
        setProgressData(progress);

        // Cargar historial de evaluaciones
        const history = await dbOperations.getEvaluationHistory(selectedStandard);
        setEvaluation({
          score: history.overallScore,
          ...history.evaluations.reduce((acc, evaluation) => ({
            ...acc,
            [evaluation?.domain]: evaluation?.score
          }), {})
        });

        // Cargar resultados de tests
        const results = await dbOperations.getTestResults(selectedStandard);
        setTestResults(results);
      } catch (error) {
        console.error('Error loading data:', error);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [selectedStandard]);

  // Efecto para hacer scroll cuando cambian los mensajes
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleStandardChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    if (isValidISOStandard(value)) {
      setSelectedStandard(value);
      setCurrentTest(null);
      setEvaluation(null);
      setMessages([]);
      setCollapsedMessages([]);
      setTestType(null);
      setTextAnswers([]);
    } else {
      console.error('Estándar ISO inválido:', value);
    }
  };

  // Función para renderizar mensajes en estilo Anubis
  const renderMessage = (message: Message) => {
    // Verificar si el mensaje está colapsado
    const isCollapsed = message.sender === 'bot' && collapsedMessages.includes(message.id);
    // If the message contains markdown-like content
  if (message.text.includes('## ') || message.text.includes('###')) {
    return (
      <div key={message.id} className="mb-3">
        <div 
          className="bg-primary text-white p-3 rounded"
          style={{ position: 'relative' }}
        >
          <ReactMarkdown 
            components={{
              h2: ({node, ...props}) => <h5 className="text-white">{props.children}</h5>,
              h3: ({node, ...props}) => <h6 className="text-white-50">{props.children}</h6>,
              a: ({node, ...props}) => (
                <Button 
                  variant="outline-light" 
                  size="sm" 
                  onClick={() => {
                    // Reset test state and return to test selection
                    setCurrentTest(null);
                    setTestType(null);
                  }}
                >
                  {props.children}
                </Button>
              )
            }}
          >
            {message.text}
          </ReactMarkdown>
        </div>
      </div>
    );
  }
    // Estilo especial para el indicador de typing
    if (message.sender === 'bot' && message.text === 'typing') {
      return (
        <div key={message.id} className="mb-3">
          <div 
            className="bg-primary text-white p-3 rounded"
            style={{ 
              position: 'relative',
              opacity: '0.7'
            }}
          >
            <div className="d-flex align-items-center">
              <span className="me-2">Anubis está escribiendo</span>
              <div className="d-flex align-items-center">
                <span className="dot-flashing"></span>
                <span className="dot-flashing" style={{ animationDelay: '0.2s' }}></span>
                <span className="dot-flashing" style={{ animationDelay: '0.4s' }}></span>
              </div>
            </div>
          </div>
        </div>
      );
    }

    // Para mensajes regulares
    if (message.sender === 'user') {
      // Estilo para mensajes del usuario
      return (
        <div key={message.id} className="mb-3">
          <div className="d-flex justify-content-end">
            <div 
              className="p-3 rounded"
              style={{ 
                backgroundColor: '#f1f1f1',
                maxWidth: '80%'
              }}
            >
              {message.text}
            </div>
          </div>
        </div>
      );
    } else {
      // Estilo para mensajes del bot (con acordeón)
      return (
        <div 
          key={message.id} 
          className="mb-3"
          onClick={() => toggleMessageCollapse(message.id)}
          style={{ cursor: 'pointer' }}
        >
          <div 
            className="bg-primary text-white p-3 rounded"
            style={{ 
              position: 'relative',
              maxHeight: isCollapsed ? '100px' : 'none',
              overflow: isCollapsed ? 'hidden' : 'visible',
              transition: 'max-height 0.3s ease-out'
            }}
          >
            {message.text}
            
            {/* Indicador de expandir/colapsar * /}
            {isCollapsed && (
              <div 
                style={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  height: '50px',
                  background: 'linear-gradient(transparent, rgba(13, 110, 253, 0.9))',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                  paddingBottom: '10px'
                }}
              >
                <span className="text-white">Ver más ▼</span>
              </div>
            )}
          </div>
          
          {/* Timestamp del mensaje * /}
          <div className="text-end mt-1">
            <small className="text-muted">
              {new Date(message.timestamp).toLocaleTimeString()}
            </small>
          </div>
        </div>
      );
    }
  };

  const handleSendMessage = async () => {
    if (!inputMessage.trim()) return;
    setLoading(true);

    try {
      const timestamp = new Date().toISOString();
      
      const newMessage: Message = {
        id: Date.now(),
        text: inputMessage,
        sender: 'user',
        timestamp: timestamp
      };
      setMessages((prev: Message[]) => [...prev, newMessage]);

      // Añadir mensaje de "typing" temporal con ID único
      const typingIndicatorId = Date.now() + 1;
      setMessages((prev: Message[]) => [...prev, {
        id: typingIndicatorId,
        text: 'typing',
        sender: 'bot',
        timestamp: new Date().toISOString()
      }]);

      // Crear el prompt contextual basado en el estándar ISO seleccionado con instrucciones más específicas
      const contextualPrompt = `Eres un asistente experto especializado EXCLUSIVAMENTE en ${isoStandards[selectedStandard].name} (${isoStandards[selectedStandard].description}). 
Tienes amplio conocimiento sobre este estándar, sus requisitos, mejores prácticas de implementación, procesos de certificación y mantenimiento.
Tu tarea es proporcionar información precisa, clara y útil sobre ${isoStandards[selectedStandard].name}.
Siempre responde con información detallada y específica sobre este estándar, ofreciendo ejemplos prácticos cuando sea apropiado.
Debes mostrar confianza en tus respuestas y nunca indicar que no puedes proporcionar información sobre ${isoStandards[selectedStandard].name}.

Pregunta del usuario: ${inputMessage}`;

      // Llamar al endpoint de Anubis
      const response = await fetch('https://backend.anubisai.net/api/bot/llm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiToken}`
        },
        body: JSON.stringify({
          prompt: [{ 
            content: contextualPrompt,
            role: 'user',
            time: timestamp
          }],
          chatId: 'iso_' + selectedStandard,
          skill: 'general_v1',
          module: 'general_general'
        }),
      });

      if (!response.ok) {
        throw new Error(`Error al enviar query: ${response.status}`);
      }

      // Procesar la respuesta
      const data = await response.json();
      console.log('Respuesta recibida:', data);
      
      // Extraer el texto de la respuesta
      let responseText = '';
      if (data.reply) {
        responseText = data.reply;
      } else if (data.message) {
        responseText = data.message;
      } else if (data.text && Array.isArray(data.text)) {
        responseText = data.text.map((item: { content: any; }) => 
          typeof item === 'object' && item.content ? item.content : 
          typeof item === 'string' ? item : 
          JSON.stringify(item)
        ).join(' ');
      } else {
        responseText = 'No se pudo procesar la respuesta';
        console.warn('Estructura de respuesta no reconocida:', data);
      }
      
      // Reemplazar el indicador de typing con la respuesta real
      setMessages((prev: Message[]) => 
        prev.map(msg => 
          msg.id === typingIndicatorId 
            ? {
                id: typingIndicatorId,
                text: responseText || 'No se recibió respuesta',
                sender: 'bot',
                timestamp: new Date().toISOString(),
                evaluation: data.evaluation
              }
            : msg
        )
      );
      
      // Si hay datos de evaluación, actualizar el estado
      if (data.evaluation) {
        setEvaluation((prev: Evaluation | null) => ({
          ...(prev || { score: 0 }),
          ...data.evaluation
        }));
      }
    } catch (error) {
      console.error('Error:', error);
      
      // Actualizar los mensajes eliminando el indicador de typing y añadiendo mensaje de error
      setMessages(prev => {
        // Filtrar los mensajes para quitar el indicador de typing
        const withoutTyping = prev.filter(msg => msg.text !== 'typing');
        
        // Agregar mensaje de error
        return [...withoutTyping, {
          id: Date.now() + 1,
          text: 'Lo siento, ocurrió un error al procesar tu solicitud.',
          sender: 'bot',
          timestamp: new Date().toISOString()
        }];
      });
    } finally {
      setLoading(false);
      setInputMessage('');
    }
  };

  // Función para mostrar el modal de selección de test
  const showTestSelectionModal = (domain: string) => {
    setSelectedDomain(domain);
    setShowTestModal(true);
  };

  // Función para iniciar un test de conocimiento
  const startKnowledgeTest = async (domain: string) => {
    // Set test type and current domain
    setTestType('knowledge');
    setCurrentTestDomain(domain);
    setShowTestModal(false);
    
    try {
      // More precise and structured prompt for question generation
      const prompt = `Genera EXACTAMENTE 5 preguntas de opción múltiple sobre ${domain} en el contexto de ${isoStandards[selectedStandard].name}. 
  
  FORMATO DE RESPUESTA ESTRICTO:
  {
    "questions": [
      {
        "id": 1,
        "text": "Pregunta específica y clara sobre el dominio",
        "options": [
          "Opción A (incorrecta)",
          "Opción B (correcta)",
          "Opción C (incorrecta)", 
          "Opción D (incorrecta)"
        ]
      },
      // ... 4 preguntas más siguiendo el mismo formato
    ]
  }
  
  INSTRUCCIONES ADICIONALES:
  - Cada pregunta debe tener un ID único
  - Incluir 4 opciones por pregunta
  - Solo una opción es la correcta
  - Las preguntas deben ser relevantes para ${isoStandards[selectedStandard].name}
  - Evitar preguntas ambiguas o muy complejas
  - Usar lenguaje claro y técnico`;
  
      const response = await fetch('https://backend.anubisai.net/api/bot/llm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiToken}`
        },
        body: JSON.stringify({
          prompt: [{ 
            content: prompt,
            role: 'user',
            time: new Date().toISOString()
          }],
          chatId: `test_${selectedStandard}_${domain.replace(/\s+/g, '_').toLowerCase()}`,
          skill: 'general_v1',
          module: 'general_general'
        }),
      });
  
      if (!response.ok) {
        throw new Error(`Error al generar preguntas: ${response.status}`);
      }
  
      const data = await response.json();
      let questions: TestQuestion[] = [];
      
      // Enhanced JSON parsing with multiple validation strategies
      const extractQuestions = (responseText: string): TestQuestion[] => {
        console.log('Raw Response:', responseText);
  
        // Multiple parsing strategies
        const parseStrategies = [
          // Strategy 1: Exact JSON block parsing
          () => {
            const jsonBlockMatch = responseText.match(/```json\s*([\s\S]*?)\s*```/);
            if (jsonBlockMatch) {
              try {
                const parsedData = JSON.parse(jsonBlockMatch[1].trim());
                return parsedData.questions || [];
              } catch (error) {
                console.error('JSON Block Parsing Error:', error);
                return [];
              }
            }
            return [];
          },
          
          // Strategy 2: Direct JSON parsing
          () => {
            try {
              const parsed = JSON.parse(responseText);
              return parsed.questions || parsed;
            } catch (error) {
              console.error('Direct Parsing Error:', error);
              return [];
            }
          }
        ];
  
        // Try parsing strategies
        for (const strategy of parseStrategies) {
          const result = strategy();
          if (result && result.length > 0) return result;
        }
  
        return [];
      };
  
// Validate and normalize questions
const validateQuestions = (rawQuestions: any[]): TestQuestion[] => {
  const validatedQuestions = rawQuestions
    .filter(q => 
      q.text && 
      q.options && 
      Array.isArray(q.options) && 
      q.options.length === 4
    )
    .map((q, index) => ({
      id: q.id || index + 1,
      text: q.text,
      options: q.options,
      type: 'multiple_choice' as const  // Use 'as const' to match the exact type
    }))
    .slice(0, 5);  // Ensure exactly 5 questions

  // If less than 5 questions, generate fallback questions
  while (validatedQuestions.length < 5) {
    validatedQuestions.push({
      id: validatedQuestions.length + 1,
      text: `Pregunta de respaldo ${validatedQuestions.length + 1} sobre ${domain}`,
      options: [
        "Opción A",
        "Opción B (correcta)",
        "Opción C",
        "Opción D"
      ],
      type: 'multiple_choice' as const  // Use 'as const' here as well
    });
  }

  return validatedQuestions;
};
  
      // Extract and validate questions
      const rawQuestions = extractQuestions(data.reply || '');
      questions = validateQuestions(rawQuestions);
  
      // Log extracted and validated questions
      console.log('Validated Questions:', questions);
  
      // Create test object
      const newTest: Test = {
        domain,
        questions: questions.map(q => ({
          id: q.id,
          text: q.text,
          options: q.options || []
        })),
        currentQuestion: 0,
        answers: []
      };
      
      // Set current test
      setCurrentTest(newTest);
  
      // Mark domain as tested
      setTestedDomains(prev => ({
        ...prev,
        [domain]: true
      }));
  
      // Add test start message to chat
      const testModeMessage: Message = {
        id: Date.now(),
        text: `Comenzaste el test de conocimiento para el dominio ${domain}. 
        Instrucciones importantes:
        - Lee cada pregunta cuidadosamente
        - Selecciona la opción que consideres más correcta
        - No busques ayuda externa
        - Confía en tu conocimiento y comprensión del estándar ${isoStandards[selectedStandard].name}
  
        Recuerda: Este test evalúa tu comprensión actual, no busca juzgarte, sino ayudarte a identificar áreas de mejora.`,
        sender: 'bot',
        timestamp: new Date().toISOString()
      };
      setMessages(prev => [...prev, testModeMessage]);
  
    } catch (error) {
      console.error('Error starting knowledge test:', error);
      
      // Reset test state
      setCurrentTestDomain(null);
      
      // Show error message
      const errorMessage: Message = {
        id: Date.now(),
        text: 'Lo siento, ocurrió un error al generar el test de conocimiento. Por favor, intenta nuevamente.',
        sender: 'bot',
        timestamp: new Date().toISOString()
      };
      setMessages(prev => [...prev, errorMessage]);
    }
  };

  // Función para iniciar un test de aplicación
  const startApplicationTest = async (domain: string) => {
    // Set test type and current domain
    setTestType('application');
    setCurrentTestDomain(domain);
    setShowTestModal(false);
    setTextAnswers([]);
    
    try {
      // Generar preguntas de caso práctico usando el endpoint de IA
      const prompt = `Genera 3 preguntas para evaluar la aplicación práctica de ${domain} en el contexto de ${isoStandards[selectedStandard].name} en una organización real. Las preguntas deben requerir respuestas de texto libre donde el usuario explique cómo implementaría aspectos específicos del estándar. Estructura la respuesta en formato JSON con este esquema: 
      {
        "questions": [
          {
            "id": 1,
            "text": "Texto de la pregunta",
            "type": "text"
          }
        ]
      }`;
  
      const response = await fetch('https://backend.anubisai.net/api/bot/llm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiToken}`
        },
        body: JSON.stringify({
          prompt: [{ 
            content: prompt,
            role: 'user',
            time: new Date().toISOString()
          }],
          chatId: `app_test_${selectedStandard}_${domain.replace(/\s+/g, '_').toLowerCase()}`,
          skill: 'general_v1',
          module: 'general_general'
        }),
      });
  
      if (!response.ok) {
        throw new Error(`Error al generar preguntas: ${response.status}`);
      }
  
      const data = await response.json();
      let questions: TestQuestion[] = [];
      
      try {
        // Comprehensive JSON parsing with detailed extraction methods
        const responseText = data.reply || '';
        console.log('Full response text:', responseText); // Debugging log
  
        let jsonData: any = null;
  
        // Multiple strategies to extract and parse JSON
        const extractionStrategies = [
          // Strategy 1: Exact ```json``` block
          () => {
            const jsonBlockMatch = responseText.match(/```json\s*([\s\S]*?)\s*```/);
            if (jsonBlockMatch) {
              try {
                return JSON.parse(jsonBlockMatch[1].trim());
              } catch (blockParseError) {
                console.error('Error parsing JSON block:', blockParseError);
                return null;
              }
            }
            return null;
          },
          
          // Strategy 2: First complete JSON object/array
          () => {
            const jsonObjectMatch = responseText.match(/(\{[\s\S]*\}|\[[\s\S]*\])/);
            if (jsonObjectMatch) {
              try {
                return JSON.parse(jsonObjectMatch[1]);
              } catch (objectParseError) {
                console.error('Error parsing JSON object:', objectParseError);
                return null;
              }
            }
            return null;
          },
          
          // Strategy 3: Extract JSON from text
          () => {
            try {
              // Remove any leading/trailing text, focus on JSON-like content
              const jsonMatch = responseText.match(/(\{[^}]*\}|\[[^\]]*\])/);
              if (jsonMatch) {
                return JSON.parse(jsonMatch[1]);
              }
            } catch (textParseError) {
              console.error('Error parsing JSON from text:', textParseError);
            }
            return null;
          }
        ];
  
        // Try extraction strategies
        for (const strategy of extractionStrategies) {
          jsonData = strategy();
          if (jsonData) break;
        }
  
        // Normalize questions extraction
        if (jsonData) {
          questions = jsonData.questions || 
                      (Array.isArray(jsonData) ? jsonData : []);
        }
  
        // If no questions found, use fallback
        if (!questions || questions.length === 0) {
          console.warn('No valid questions found, using default questions');
          questions = [
            {
              id: 1,
              text: `Describe cómo implementarías una política de ${domain} en una organización mediana que cumpla con los requisitos de ${isoStandards[selectedStandard].name}. Incluye los componentes clave y el proceso de aprobación.`,
              type: 'text'
            },
            {
              id: 2,
              text: `Explica el proceso que seguirías para revisar y actualizar los controles de ${domain} existentes. ¿Qué factores considerarías y qué partes interesadas involucrarías?`,
              type: 'text'
            },
            {
              id: 3,
              text: `Describe un plan de implementación para asegurar que todas las partes relevantes entiendan y cumplan con los requisitos de ${domain} según ${isoStandards[selectedStandard].name}.`,
              type: 'text'
            }
          ];
        }
  
        // Ensure each question has all required properties
        questions = questions.map((q, index) => ({
          id: q.id || index + 1,
          text: q.text || `Pregunta ${index + 1}`,
          type: q.type || 'text'
        }));
  
        // Log extracted questions for debugging
        console.log('Extracted questions:', questions);
      } catch (error) {
        console.error("Comprehensive error parsing question data:", error);
        
        // Absolute fallback to predefined questions
        questions = [
          {
            id: 1,
            text: `Describe cómo implementarías requisitos de ${domain} en una organización mediana según ${isoStandards[selectedStandard].name}.`,
            type: 'text'
          },
          {
            id: 2,
            text: `Explica cómo verificarías el cumplimiento de ${domain} en una auditoría de ${isoStandards[selectedStandard].name}.`,
            type: 'text'
          },
          {
            id: 3,
            text: `¿Qué desafíos anticipas en la implementación de ${domain} y cómo los abordarías?`,
            type: 'text'
          }
        ];
      }
  
      // Crear un nuevo test
      const newTest: Test = {
        domain,
        questions: questions.map(q => ({
          id: q.id,
          text: q.text,
          options: [] // Para tests de tipo texto, no hay opciones
        })),
        currentQuestion: 0,
        answers: []
      };
      
      // Establecer el test actual
      setCurrentTest(newTest);
  
      // Inicializar array de respuestas de texto vacías
      setTextAnswers(new Array(questions.length).fill(''));
  
      // Marcar el dominio como probado
      setTestedDomains(prev => ({
        ...prev,
        [domain]: true
      }));
  
      // Añadir un mensaje al chat sobre el inicio del test
      const testModeMessage: Message = {
        id: Date.now(),
        text: `Comenzaste el test de aplicación práctica para el dominio ${domain}. 
        Instrucciones importantes:
        - Lee cada pregunta cuidadosamente
        - Responde con ejemplos concretos y detallados
        - Muestra tu comprensión práctica de ${isoStandards[selectedStandard].name}
        - Sé específico y claro en tus explicaciones
  
        Recuerda: Este test evalúa tu capacidad para aplicar conceptos del estándar en situaciones reales.`,
        sender: 'bot',
        timestamp: new Date().toISOString()
      };
      setMessages(prev => [...prev, testModeMessage]);
  
    } catch (error) {
      console.error('Error starting application test:', error);
      
      // Resetear el estado del test
      setCurrentTestDomain(null);
      
      // Mostrar mensaje de error al usuario
      const errorMessage: Message = {
        id: Date.now(),
        text: 'Lo siento, ocurrió un error al generar el test de aplicación práctica. Por favor, intenta nuevamente.',
        sender: 'bot',
        timestamp: new Date().toISOString()
      };
      setMessages(prev => [...prev, errorMessage]);
    }
  };

  const exitTest = () => {
    // Reset test-related states
    setCurrentTest(null);
    setTestType(null);
    setTextAnswers([]);
    setCurrentTestDomain(null);
  
    // Reset chat to initial state
    const exitMessage: Message = {
      id: Date.now(),
      text: `Has salido del test de ${currentTestDomain}. Estoy listo para ayudarte con cualquier consulta sobre ${isoStandards[selectedStandard].name}.`,
      sender: 'bot',
      timestamp: new Date().toISOString()
    };
    setMessages(prev => [...prev, exitMessage]);
  };

  // Función para manejar respuestas de opción múltiple
  const handleTestAnswer = async (answer: string) => {
    try {
      // Immediate validation of current test
      if (!currentTest) {
        console.error('No active test found');
        setMessages(prev => [...prev, {
          id: Date.now(),
          text: 'Error: No hay un test activo. Por favor, reinicia la evaluación.',
          sender: 'bot',
          timestamp: new Date().toISOString()
        }]);
        return;
      }
  
      // Deep clone to prevent mutation issues
      const testCopy: Test = JSON.parse(JSON.stringify(currentTest));
  
      // Validate questions array
      if (!testCopy.questions || testCopy.questions.length === 0) {
        console.error('No questions found in the test', testCopy);
        setCurrentTest(null);
        setTestType(null);
        setIsEvaluating(false); // Reset evaluating state
        setCurrentTestDomain(null); // Reset test domain
        setMessages(prev => [...prev, {
          id: Date.now(),
          text: 'Error: No se encontraron preguntas en el test.',
          sender: 'bot',
          timestamp: new Date().toISOString()
        }]);
        return;
      }
  
      // Precise index calculations
      const maxQuestionIndex = testCopy.questions.length - 1;
  
      // Detailed logging of test state
      console.log('Test State Before Answer:', {
        domain: testCopy.domain,
        currentQuestion: testCopy.currentQuestion,
        totalQuestions: testCopy.questions.length,
        answers: testCopy.answers,
        lastAnswer: answer
      });
  
      // Create updated test state
      const updatedTest: Test = {
        ...testCopy,
        answers: [...(testCopy.answers || []), answer],
        currentQuestion: testCopy.currentQuestion + 1
      };
      
      // Update current test state
      setCurrentTest(updatedTest);
  
      // Check if test is complete
      if (updatedTest.currentQuestion > maxQuestionIndex) {
        try {
          setIsEvaluating(true);
          
          // Evaluate the test
          const evaluationResult = await evaluateTest(updatedTest, testCopy);
  
          // Prepare detailed completion message
          const completionMessage = `
  ## 🏆 Test de Conocimiento Completado
  
  ### Puntuación: ${evaluationResult.totalScore}/100
  
  ${evaluationResult.totalScore >= 90 ? '🌟 ¡Excelente trabajo!' : 
   evaluationResult.totalScore >= 70 ? '👍 Buen desempeño' : 
   '📚 Hay oportunidades de mejora'}
  
  #### Retroalimentación General
  ${evaluationResult.feedback}
  
  #### Desglose de Respuestas
  ${evaluationResult.questionsEvaluation.map((qEval, index) => 
    `**Pregunta ${index + 1}:**
    - *Puntuación*: ${qEval.score}/100
    - *Feedback*: ${qEval.feedback}`
  ).join('\n\n')}
  
  [Volver a la lista de tests](#)
  `;
  
          // Add completion message to chat
          setMessages(prev => [...prev, {
            id: Date.now(),
            text: completionMessage,
            sender: 'bot',
            timestamp: new Date().toISOString()
          }]);
  
          // Prepare test result
          const testResult: TestResult = {
            domainId: testCopy.domain,
            testType: 'knowledge',
            totalScore: evaluationResult.totalScore,
            maxScore: 100,
            percentage: evaluationResult.totalScore,
            questions: testCopy.questions.map(q => ({
              id: q.id,
              text: q.text,
              options: q.options,
              type: 'multiple_choice'
            })),
            answers: updatedTest.answers.map((answer, idx) => ({
              questionId: testCopy.questions[idx].id,
              answer,
              score: evaluationResult.questionsEvaluation[idx]?.score || 0,
              feedback: evaluationResult.questionsEvaluation[idx]?.feedback || ''
            })),
            timestamp: new Date().toISOString(),
            feedback: evaluationResult.feedback
          };
  
          // Save result and update progress
          await Promise.all([
            dbOperations.saveTestResult(selectedStandard, testResult),
            dbOperations.updateProgressData(selectedStandard, {
              date: new Date().toISOString().split('T')[0],
              score: evaluationResult.totalScore,
              standard: selectedStandard,
              domain: testCopy.domain
            })
          ]);
  
          // Update local state with the current test result for summary display
          setEvaluation(prev => ({
            ...(prev || { score: 0 }),
            [testCopy.domain]: evaluationResult.totalScore
          }));
  
          // Reload data
          const [progress, results] = await Promise.all([
            dbOperations.getProgressData(selectedStandard),
            dbOperations.getTestResults(selectedStandard)
          ]);
  
          setProgressData(progress);
          setTestResults(results);
  
        } catch (evaluationError) {
          console.error('Error during test evaluation:', evaluationError);
          
          // Error handling for evaluation
          setMessages(prev => [...prev, {
            id: Date.now(),
            text: 'Ocurrió un error al evaluar el test. Por favor, intenta nuevamente.',
            sender: 'bot',
            timestamp: new Date().toISOString()
          }]);
        } finally {
          // Always reset evaluating state
          setIsEvaluating(false);
          // But DON'T reset test state and test domain here to allow summary display
          // We'll keep currentTest and currentTestDomain for the summary panel
        }
      }
    } catch (error) {
      console.error('Critical error in handleTestAnswer:', error);
      
      // Comprehensive error reset
      setCurrentTest(null);
      setTestType(null);
      setIsEvaluating(false);
      setCurrentTestDomain(null);
      
      setMessages(prev => [...prev, {
        id: Date.now(),
        text: 'Ocurrió un error crítico durante el test. Por favor, reinicie la evaluación.',
        sender: 'bot',
        timestamp: new Date().toISOString()
      }]);
    }
  };
  
  // Separate function for test evaluation
  const evaluateTest = async (updatedTest: Test, originalTest: Test): Promise<EvaluationData> => {
    const answers = updatedTest.answers;
    const questions = originalTest.questions;
    
    const evaluationPrompt = `Evalúa las siguientes respuestas de un test sobre ${originalTest.domain} en el contexto de ${isoStandards[selectedStandard].name}.
    
    Preguntas y respuestas:
    ${questions.map((q, idx) => `Pregunta ${idx + 1}: ${q.text}
    Respuesta: ${answers[idx]}`).join('\n\n')}
    
    Por favor, evalúa cada respuesta y proporciona un puntaje entre 0 y 100 para el desempeño general.
    Estructura tu respuesta en formato JSON con el siguiente esquema:
    {
      "evaluation": [
        {
          "questionId": 1,
          "score": 80,
          "feedback": "Comentario sobre la respuesta"
        }
      ],
      "totalScore": 85,
      "generalFeedback": "Comentario general sobre el desempeño"
    }`;
  
    const response = await fetch('https://backend.anubisai.net/api/bot/llm', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiToken}`
      },
      body: JSON.stringify({
        prompt: [{ 
          content: evaluationPrompt,
          role: 'user',
          time: new Date().toISOString()
        }],
        chatId: `evaluation_${selectedStandard}_${originalTest.domain.replace(/\s+/g, '_').toLowerCase()}`,
        skill: 'general_v1',
        module: 'general_general'
      }),
    });
  
    if (!response.ok) {
      throw new Error(`Error al evaluar respuestas: ${response.status}`);
    }
  
    const data = await response.json();
    
    // Comprehensive JSON parsing
    let evaluationData: EvaluationData = {
      questionsEvaluation: [],
      totalScore: 0,
      feedback: 'No se pudo procesar la evaluación'
    };
    
    try {
      const responseText = data.reply || '';
      console.log('Raw Evaluation Response:', responseText);
  
      const jsonMatch = responseText.match(/\{[\s\S]*\}/);
      
      if (jsonMatch) {
        const cleanedJson = jsonMatch[0].trim();
        console.log('Cleaned JSON:', cleanedJson);
        
        const jsonData = JSON.parse(cleanedJson);
        evaluationData = {
          questionsEvaluation: jsonData.evaluation || [],
          totalScore: jsonData.totalScore || 0,
          feedback: jsonData.generalFeedback || 'No se proporcionó retroalimentación general'
        };
      } else {
        console.error('No JSON found in response');
        throw new Error('No JSON found');
      }
    } catch (error) {
      console.error("Error parsing evaluation data:", error);
      
      // Fallback evaluation
      const randomScores = questions.map((_, idx) => ({
        questionId: idx + 1,
        score: Math.floor(Math.random() * 40) + 60,
        feedback: 'Evaluación generada automáticamente'
      }));
      
      const avgScore = randomScores.reduce((sum, item) => sum + item.score, 0) / randomScores.length;
      
      evaluationData = {
        questionsEvaluation: randomScores,
        totalScore: Math.round(avgScore),
        feedback: 'Evaluación generada automáticamente debido a un error de procesamiento.'
      };
    }
  
    return evaluationData;
  };

  // Función para manejar el cambio en respuestas de texto
  const handleTextAnswerChange = (index: number, value: string) => {
    const newAnswers = [...textAnswers];
    newAnswers[index] = value;
    setTextAnswers(newAnswers);
  };

  // Función para enviar respuestas de texto
  const submitTextAnswers = async () => {
    if (!currentTest) return;
    
    try {
      setIsEvaluating(true);
      
      // Evaluar las respuestas de texto usando el endpoint de IA
      const questions = currentTest.questions;
      
      const evaluationPrompt = `Evalúa las siguientes respuestas de un test práctico sobre ${currentTest.domain} en el contexto de ${isoStandards[selectedStandard].name}.
      
      Preguntas y respuestas:
      ${questions.map((q, idx) => `Pregunta ${idx + 1}: ${q.text}
      Respuesta: ${textAnswers[idx] || 'Sin respuesta'}`).join('\n\n')}
      
      Por favor, evalúa cada respuesta detalladamente considerando:
      - Comprensión del contexto de ${isoStandards[selectedStandard].name}
      - Aplicación práctica de los conceptos
      - Claridad y completitud
      - Adherencia a las mejores prácticas
      
      Proporciona un puntaje entre 0 y 100 para cada respuesta y para el desempeño general.
      Estructura tu respuesta en formato JSON con el siguiente esquema:
      {
        "evaluation": [
          {
            "questionId": 1,
            "score": 80,
            "feedback": "Análisis detallado de la respuesta señalando fortalezas y áreas de mejora"
          }
        ],
        "totalScore": 85,
        "generalFeedback": "Evaluación completa del desempeño, identificando áreas de fortaleza y oportunidades de mejora"
      }`;
  
      const response = await fetch('https://backend.anubisai.net/api/bot/llm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiToken}`
        },
        body: JSON.stringify({
          prompt: [{ 
            content: evaluationPrompt,
            role: 'user',
            time: new Date().toISOString()
          }],
          chatId: `practical_evaluation_${selectedStandard}_${currentTest.domain.replace(/\s+/g, '_').toLowerCase()}`,
          skill: 'general_v1',
          module: 'general_general'
        }),
      });
  
      if (!response.ok) {
        throw new Error(`Error al evaluar respuestas: ${response.status}`);
      }
  
      const data = await response.json();
      
      // Procesar la evaluación
      let evaluationData: EvaluationData = {
        questionsEvaluation: [],
        totalScore: 0,
        feedback: 'No se pudo procesar la evaluación'
      };
      
      try {
        // Intentar extraer la evaluación del formato JSON en la respuesta
        const responseText = data.reply || '';
        const jsonMatch = responseText.match(/```json\s*([\s\S]*?)\s*```/) || 
                          responseText.match(/{[\s\S]*?}/);
        
        if (jsonMatch) {
          const jsonData = JSON.parse(jsonMatch[0].replace(/```json|```/g, '').trim());
          evaluationData = {
            questionsEvaluation: jsonData.evaluation || [],
            totalScore: jsonData.totalScore || 0,
            feedback: jsonData.generalFeedback || 'No se proporcionó retroalimentación general'
          };
        }
      } catch (error) {
        console.error("Error parsing evaluation data:", error);
        // Si no podemos extraer JSON, simular una evaluación
        const randomScores: QuestionEvaluation[] = questions.map((_, idx) => ({
          questionId: idx + 1,
          score: Math.floor(Math.random() * 40) + 60, // Puntuación entre 60 y 100
          feedback: 'Evaluación generada automáticamente'
        }));
        
        const avgScore = randomScores.reduce((sum, item) => sum + item.score, 0) / randomScores.length;
        
        evaluationData = {
          questionsEvaluation: randomScores,
          totalScore: Math.round(avgScore),
          feedback: 'Evaluación generada automáticamente. No se pudo procesar la evaluación detallada.'
        };
      }
      
      // Crear un objeto de resultado de test
      const testResult: TestResult = {
        domainId: currentTest.domain,
        testType: 'application',
        totalScore: evaluationData.totalScore,
        maxScore: 100,
        percentage: evaluationData.totalScore,
        questions: questions.map(q => ({
          id: q.id,
          text: q.text,
          type: 'text'
        })),
        answers: textAnswers.map((answer, idx) => ({
          questionId: questions[idx].id,
          answer,
          score: evaluationData.questionsEvaluation[idx]?.score || 0,
          feedback: evaluationData.questionsEvaluation[idx]?.feedback || ''
        })),
        timestamp: new Date().toISOString(),
        feedback: evaluationData.feedback
      };
      
      // Guardar el resultado
      await dbOperations.saveTestResult(selectedStandard, testResult);
      
      // Actualizar datos de progreso
      const progressEntry: ProgressData = {
        date: new Date().toISOString().split('T')[0],
        score: evaluationData.totalScore,
        standard: selectedStandard,
        domain: currentTest.domain
      };
      await dbOperations.updateProgressData(selectedStandard, progressEntry);
  
      // Actualizar estado local
      setEvaluation(prev => ({
        ...(prev || { score: 0 }),
        [currentTest.domain]: evaluationData.totalScore
      }));
  
      // Recargar datos actualizados
      const progress = await dbOperations.getProgressData(selectedStandard);
      setProgressData(progress);
      
      // Actualizar resultados de tests
      const results = await dbOperations.getTestResults(selectedStandard);
      setTestResults(results);
  
      // Mostrar mensaje con el resultado detallado en formato Markdown
      setMessages(prev => [...prev, {
        id: Date.now(),
        text: `
  ## 🏆 Test de Aplicación Práctica Completado
  
  ### Puntuación: ${evaluationData.totalScore}/100
  
  ${evaluationData.totalScore >= 90 ? '🌟 ¡Excelente trabajo!' : 
   evaluationData.totalScore >= 70 ? '👍 Buen desempeño' : 
   '📚 Hay oportunidades de mejora'}
  
  #### Retroalimentación General
  ${evaluationData.feedback}
  
  #### Desglose de Respuestas
  ${evaluationData.questionsEvaluation.map((evaluation, idx) => 
    `**Pregunta ${idx + 1}:**
    - *Puntuación*: ${evaluation?.score}/100
    - *Feedback*: ${evaluation?.feedback}`
  ).join('\n\n')}
  
  [Volver a la lista de tests](#)
  `,
        sender: 'bot',
        timestamp: new Date().toISOString()
      }]);
  
      // NO resetear el estado del test aquí para mostrar el resumen
      setIsEvaluating(false);
      // Mantenemos currentTest y currentTestDomain para el panel de resumen
  
    } catch (error) {
      console.error('Error evaluating application test:', error);
      // Mostrar mensaje de error
      setMessages(prev => [...prev, {
        id: Date.now(),
        text: 'Lo siento, ocurrió un error al evaluar tus respuestas del test práctico.',
        sender: 'bot',
        timestamp: new Date().toISOString()
      }]);
      
      // Resetear el test en caso de error
      setCurrentTest(null);
      setTestType(null);
      setTextAnswers([]);
      setCurrentTestDomain(null);
      setIsEvaluating(false);
    }
  };
  
  // Add this new function to handle returning to test list
  const returnToTestList = () => {
    // Reset test-related states
    setCurrentTest(null);
    setTestType(null);
    setTextAnswers([]);
    setCurrentTestDomain(null);
    setLoadingTest(null);
  };
  
  const renderTestPanel = () => {
    // Si hay un test activo y aún no se ha completado, mostrar el test
    if (currentTest && currentTest.currentQuestion < currentTest.questions.length) {
      return (
        <>
          <div className="mb-3">
            <Button 
              variant="outline-danger" 
              onClick={exitTest} 
              className="me-2"
            >
              Salir del Test
            </Button>
          </div>
          
          {testType === 'knowledge' ? (
            !isEvaluating ? (
              <div>
                <p className="fw-bold mb-3">
                  Pregunta {currentTest.currentQuestion + 1} de {currentTest.questions.length}
                </p>
                <p className="mb-3">{currentTest.questions[currentTest.currentQuestion].text}</p>
                <div className="d-grid gap-2">
                  {currentTest.questions[currentTest.currentQuestion].options.map((option, idx) => (
                    <Button
                      key={idx}
                      variant="outline-primary"
                      onClick={() => handleTestAnswer(option)}
                      className="text-start"
                    >
                      {option}
                    </Button>
                  ))}
                </div>
              </div>
            ) : (
              <div className="text-center py-4">
                <Spinner animation="border" role="status" className="mb-3">
                  <span className="visually-hidden">Cargando...</span>
                </Spinner>
                <p className="h5">Espere un momento, evaluando...</p>
              </div>
            )
          ) : (
            <div>
              <p className="fw-bold mb-3">Test de Aplicación Práctica</p>
              <p className="text-muted mb-4">
                Responde a las siguientes preguntas explicando cómo aplicarías los conceptos de {currentTest?.domain || 'este dominio'} en una situación real.
              </p>
              
              {currentTest?.questions?.map((question, idx) => (
                <div key={idx} className="mb-4">
                  <p className="mb-2">{question.text}</p>
                  <FloatingLabel controlId={`textAnswer-${idx}`} label="Tu respuesta">
                    <Form.Control
                      as="textarea"
                      value={textAnswers[idx] || ''}
                      onChange={(e) => handleTextAnswerChange(idx, e.target.value)}
                      style={{ height: '120px' }}
                      placeholder="Escribe tu respuesta aquí"
                    />
                  </FloatingLabel>
                </div>
              )) || (
                <div className="alert alert-warning">
                  No hay preguntas disponibles para este test.
                </div>
              )}
              
              <div className="d-flex justify-content-end mt-3">
                <Button
                  variant="primary"
                  onClick={submitTextAnswers}
                  disabled={isEvaluating}
                >
                  {isEvaluating ? 'Evaluando...' : 'Enviar Respuestas'}
                </Button>
              </div>
            </div>
          )}
        </>
      );
    }
    
    // Si hay un test activo pero se ha completado (o está en evaluación), mostrar un resumen o spinner
    else if (currentTest || (currentTestDomain && isEvaluating)) {
      if (isEvaluating) {
        return (
          <div className="text-center py-4">
            <Spinner animation="border" role="status" className="mb-3">
              <span className="visually-hidden">Cargando...</span>
            </Spinner>
            <p className="h5">Espere un momento, evaluando...</p>
          </div>
        );
      } else {
        // Test completado, mostrar resumen
        const domainScore = evaluation?.[currentTest?.domain || currentTestDomain || ''] || 0;
        
        return (
          <div className="p-4 text-center">
            <div className="mb-4">
              <div className={`display-1 mb-3 ${
                domainScore >= 90 ? 'text-success' : 
                domainScore >= 70 ? 'text-primary' : 
                'text-warning'
              }`}>
                {domainScore}%
              </div>
              
              <h4 className="mb-4">
                {domainScore >= 90 ? '🌟 ¡Excelente trabajo!' : 
                 domainScore >= 70 ? '👍 Buen desempeño' : 
                 '📚 Hay oportunidades de mejora'}
              </h4>
              
              <p className="mb-4">
                Has completado el test de {testType === 'knowledge' ? 'conocimiento' : 'aplicación práctica'} para el dominio <strong>{currentTest?.domain || currentTestDomain}</strong>.
              </p>
              
              <div className="d-flex justify-content-center">
                <Button 
                  variant="primary" 
                  size="lg" 
                  onClick={returnToTestList}
                  className="px-4"
                >
                  Volver a la lista de tests
                </Button>
              </div>
            </div>
            
            <div className="mt-5">
              <p className="text-muted">
                Revisa el chat para ver retroalimentación detallada sobre tu desempeño.
              </p>
            </div>
          </div>
        );
      }
    }
    
    // Si no hay un test activo, mostrar la lista de dominios
    else {
      return (
        <Row className="g-2">
          {isoStandards[selectedStandard].domains.map((domain) => (
            <Col md={6} key={domain}>
              <Button
                variant={testedDomains[domain] ? "outline-success" : "outline-primary"}
                onClick={() => showTestSelectionModal(domain)}
                className="w-100 mb-2"
                disabled={currentTestDomain !== null} // Deshabilitar todos los botones cuando se está cargando cualquier test
              >
                <div className="d-flex flex-column align-items-center">
                  {currentTestDomain === domain ? (
                    // Mostrar spinner solo en el dominio seleccionado
                    <Spinner 
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="mb-1"
                    />
                  ) : (
                    testedDomains[domain] ? (
                      <CheckCircle size={20} className="mb-1 text-success" />
                    ) : (
                      <CheckCircle size={20} className="mb-1" />
                    )
                  )}
                  <span>Evaluar: {domain}</span>
                </div>
              </Button>
            </Col>
          ))}
        </Row>
      );
    }
  };

  // Estilos CSS para el chat
  const chatStyles = `
  <style>
    .dot-flashing {
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #ffffff;
      margin: 0 2px;
      animation: dot-flashing 1s infinite linear alternate;
    }
    
    @keyframes dot-flashing {
      0% { opacity: 0.2; }
      100% { opacity: 1; }
    }
  </style>
  `;

  return (
    <><WidgetsSectionTitle
      title="ISO AI Training Integration System"
      subtitle="Study and practice available ISO standards with progress analysis."
      icon={faCertificate}
      className="my-5" />
    <div className="p-4">
      {/* Selector de estándar ISO * /}
      <Row className="mb-4">
        <Col>
          <Form.Group>
            <Form.Label>Seleccionar Estándar ISO</Form.Label>
            <Form.Select
              value={selectedStandard}
              onChange={handleStandardChange}
              className="mb-2"
            >
              {Object.values(isoStandards).map(standard => (
                <option key={standard.id} value={standard.id}>
                  {standard.name} - {standard.description}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
  
      {/* Métricas principales * /}
      <Row className="mb-4">
        <Col md={4}>
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <p className="text-muted mb-1">Puntuación {isoStandards[selectedStandard].name}</p>
                  <h3 className="mb-0">{evaluation?.score || 0}%</h3>
                </div>
                <Award size={24} className="text-primary" />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <p className="text-muted mb-1">Dominios Evaluados</p>
                  <h3 className="mb-0">
                    {progressData.length}/{isoStandards[selectedStandard].domains.length}
                  </h3>
                </div>
                <Book size={24} className="text-success" />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <p className="text-muted mb-1">Última Actualización</p>
                  <h3 className="mb-0">
                    {progressData.length > 0
                      ? new Date(progressData[progressData.length - 1].date).toLocaleDateString()
                      : 'Sin datos'}
                  </h3>
                </div>
                <BarChart size={24} className="text-info" />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
  
      <Row className="mb-4">
        {/* Panel de chat * /}
        <Col md={6}>
          <Card>
            <Card.Header>
              <h5 className="mb-0">Asistente {isoStandards[selectedStandard].name}</h5>
            </Card.Header>
            <Card.Body>
              {/* Estilos para el chat * /}
              <div dangerouslySetInnerHTML={{ __html: chatStyles }} />
              
              <div 
                className="chat-container mb-3" 
                style={{ height: '400px', overflowY: 'auto' }}
                ref={chatContainerRef}
              >
                {messages.map(message => renderMessage(message))}
              </div>
              
              <Form className="d-flex gap-2" onSubmit={(e) => { e.preventDefault(); handleSendMessage(); }}>
                <Form.Control
                  type="text"
                  value={inputMessage}
                  onChange={(e) => setInputMessage(e.target.value)}
                  placeholder={`Pregunta sobre ${isoStandards[selectedStandard].name}...`}
                  disabled={loading} />
                <Button
                  type="submit"
                  variant="primary"
                  onClick={handleSendMessage}
                  disabled={loading}
                >
                  <Send size={20} />
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
  
        {/* Panel de pruebas * /}
        <Col md={6}>
  <Card>
    <Card.Header>
      <h5 className="mb-0">
        {currentTest || currentTestDomain
          ? testType === 'knowledge' 
            ? `Test de Conocimiento: ${currentTest?.domain || currentTestDomain}`
            : `Test de Aplicación Práctica: ${currentTest?.domain || currentTestDomain}`
          : `Evaluación ${isoStandards[selectedStandard].name}`}
      </h5>
    </Card.Header>
    <Card.Body>
      {renderTestPanel()}
    </Card.Body>
  </Card>
</Col>
      </Row>
  
      {/* Gráfico de progreso * /}
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <h5 className="mb-0">Progreso de Cumplimiento {isoStandards[selectedStandard].name}</h5>
            </Card.Header>
            <Card.Body>
              <div style={{ height: '300px' }}>
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={progressData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis domain={[0, 100]} />
                    <Tooltip />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey="score"
                      stroke="#0d6efd"
                      name={`Puntuación ${isoStandards[selectedStandard].name}`} 
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  
    {/* Modal de selección de tipo de test * /}
    <Modal 
      show={showTestModal} 
      onHide={() => setShowTestModal(false)}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Seleccionar Tipo de Evaluación</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="mb-4">Selecciona el tipo de evaluación para el dominio <strong>{selectedDomain}</strong>:</p>
        
        <div className="d-grid gap-3">
          <Button 
            variant="outline-primary" 
            className="text-start p-3"
            onClick={() => selectedDomain && startKnowledgeTest(selectedDomain)}
            disabled={loading}
          >
            {loading ? (
              <div className="d-flex align-items-center justify-content-center">
                <Spinner 
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-2"
                />
                Cargando Test de Conocimiento...
              </div>
            ) : (
              <div className="d-flex align-items-center">
                <CheckCircle size={24} className="me-3" />
                <div>
                  <h5 className="mb-1">Test de Conocimiento</h5>
                  <p className="text-muted mb-0">Evalúa tu comprensión teórica mediante preguntas de opción múltiple.</p>
                </div>
              </div>
            )}
          </Button>
          
          <Button 
            variant="outline-primary" 
            className="text-start p-3"
            onClick={() => selectedDomain && startApplicationTest(selectedDomain)}
            disabled={loading}
          >
            {loading ? (
              <div className="d-flex align-items-center justify-content-center">
                <Spinner 
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-2"
                />
                Cargando Test de Aplicación Práctica...
              </div>
            ) : (
              <div className="d-flex align-items-center">
                <FileText size={24} className="me-3" />
                <div>
                  <h5 className="mb-1">Test de Aplicación Práctica</h5>
                  <p className="text-muted mb-0">Evalúa tu capacidad para aplicar conceptos en situaciones reales.</p>
                </div>
              </div>
            )}
          </Button>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button 
          variant="secondary" 
          onClick={() => setShowTestModal(false)}
          disabled={loading}
        >
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
    </>
  );
};

export default ISO27001Dashboard;
*/