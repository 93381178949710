// Crear un nuevo componente: ConversationsTable.tsx

import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Card, Spinner } from 'react-bootstrap';
import axios from 'axios';

type LanguageType = 'es' | 'en';

interface Conversation {
  id: string;
  name: string;
  lastMessageTime: string;
  firstMessageTime: string;
  messageCount: number;
  preview: string;
}

interface Message {
  timestamp: string;
  sender: string;
  text: string;
}

interface ConversationsTableProps {
  language: LanguageType;
}

const API_BASE_URL = 'https://backend.anubisai.net/api/whatsapp/bot';
const API_KEY = 'anubis-whatsapp-api-key';

const ConversationsTable: React.FC<ConversationsTableProps> = ({ language }) => {
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedConversation, setSelectedConversation] = useState<string | null>(null);
  const [conversationMessages, setConversationMessages] = useState<Message[]>([]);
  const [loadingMessages, setLoadingMessages] = useState<boolean>(false);
  
  useEffect(() => {
    fetchConversations();
  }, []);
  
  // Agregar esta función al componente
const trainMLWithConversation = async (conversationId: string) => {
  try {
    setLoading(true);
    
    const response = await axios.post(`${API_BASE_URL}/train-ml-conversations`, 
      { conversationId },
      { headers: { 'x-api-key': API_KEY } }
    );
    
    if (response.data.success) {
      alert(language === 'es' 
        ? `Entrenamiento exitoso: ${response.data.examples_added} ejemplos añadidos al ML` 
        : `Training successful: ${response.data.examples_added} examples added to ML`);
    } else {
      alert(language === 'es' 
        ? 'Error en el entrenamiento: ' + response.data.message 
        : 'Training error: ' + response.data.message);
    }
  } catch (error) {
    console.error('Error al entrenar ML:', error);
    alert(language === 'es' 
      ? 'Error al entrenar el ML con esta conversación' 
      : 'Error training ML with this conversation');
  } finally {
    setLoading(false);
  }
};

  const fetchConversations = async () => {
    try {
      setLoading(true);
      
      // Asegúrate de que esta URL coincida con la ruta definida en tu backend
      console.log('Consultando endpoint:', `${API_BASE_URL}/conversations`);
      
      const response = await axios.get(`${API_BASE_URL}/conversations`, {
        headers: { 'x-api-key': API_KEY }
      });
      
      console.log('Respuesta recibida:', response.data);
      
      if (response.data.success) {
        setConversations(response.data.conversations);
      } else {
        setError(response.data.message || 'Error al cargar conversaciones');
      }
    } catch (error) {
      console.error('Error al obtener conversaciones:', error);
      setError(language === 'es' 
        ? 'No se pudieron cargar las conversaciones. Intente más tarde.' 
        : 'Could not load conversations. Please try again later.');
    } finally {
      setLoading(false);
    }
  };
  
  const fetchConversationDetail = async (conversationId: string) => {
    try {
      setLoadingMessages(true);
      const response = await axios.get(`${API_BASE_URL}/conversation-detail`, {
        headers: { 'x-api-key': API_KEY },
        params: { id: conversationId }
      });
      
      if (response.data.success) {
        setConversationMessages(response.data.messages);
      } else {
        console.error('Error al cargar mensajes:', response.data.message);
      }
    } catch (error) {
      console.error('Error al obtener detalles de conversación:', error);
    } finally {
      setLoadingMessages(false);
    }
  };
  
  const handleViewConversation = (conversationId: string) => {
    setSelectedConversation(conversationId);
    fetchConversationDetail(conversationId);
  };
  
  const handleCloseModal = () => {
    setSelectedConversation(null);
    setConversationMessages([]);
  };
  
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat(language === 'es' ? 'es-ES' : 'en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(date);
  };
  
  if (loading) {
    return (
      <div className="text-center my-5">
        <Spinner animation="border" />
        <p className="mt-2">{language === 'es' ? 'Cargando conversaciones...' : 'Loading conversations...'}</p>
      </div>
    );
  }
  
  if (error) {
    return (
      <div className="alert alert-danger my-3">
        <i className="fas fa-exclamation-circle me-2"></i>
        {error}
      </div>
    );
  }
  
  if (conversations.length === 0) {
    return (
      <div className="alert alert-info my-3">
        <i className="fas fa-info-circle me-2"></i>
        {language === 'es' 
          ? 'No hay conversaciones disponibles. Conecta tu WhatsApp para empezar a chatear.' 
          : 'No conversations available. Connect your WhatsApp to start chatting.'}
      </div>
    );
  }
  
  return (
    <>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>{language === 'es' ? 'Contacto' : 'Contact'}</th>
            <th>{language === 'es' ? 'Última actividad' : 'Last activity'}</th>
            <th>{language === 'es' ? 'Mensajes' : 'Messages'}</th>
            <th>{language === 'es' ? 'Vista previa' : 'Preview'}</th>
            <th>{language === 'es' ? 'Acciones' : 'Actions'}</th>
          </tr>
        </thead>
        <tbody>
          {conversations.map((conversation) => (
            <tr key={conversation.id}>
              <td>{conversation.name}</td>
              <td>{formatDate(conversation.lastMessageTime)}</td>
              <td>{conversation.messageCount}</td>
              <td>{conversation.preview}</td>
              <td>
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() => handleViewConversation(conversation.id)}
                >
                  {language === 'es' ? 'Ver mensajes' : 'View messages'}
                </Button>
                <Button
  variant="success"
  size="sm"
  className="ms-2"
  onClick={() => trainMLWithConversation(conversation.id)}
>
  {language === 'es' ? 'Entrenar ML' : 'Train ML'}
</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      
      {/* Modal para ver los mensajes de la conversación */}
      <Modal
        show={selectedConversation !== null}
        onHide={handleCloseModal}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {language === 'es' ? 'Conversación con ' : 'Conversation with '}
            {selectedConversation ? 
              conversations.find(c => c.id === selectedConversation)?.name : ''}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loadingMessages ? (
            <div className="text-center py-4">
              <Spinner animation="border" />
              <p className="mt-2">{language === 'es' ? 'Cargando mensajes...' : 'Loading messages...'}</p>
            </div>
          ) : conversationMessages.length === 0 ? (
            <div className="alert alert-info">
              {language === 'es' 
                ? 'No hay mensajes disponibles para esta conversación.' 
                : 'No messages available for this conversation.'}
            </div>
          ) : (
            <div className="conversation-messages">
              {conversationMessages.map((message, index) => (
                <Card 
                  key={index} 
                  className={`mb-2 ${message.sender === 'bot' ? 'ms-auto bg-light' : 'me-auto'}`}
                  style={{ maxWidth: '80%' }}
                >
                  <Card.Body className="py-2 px-3">
                    <div className="mb-1">
                      <small className="text-muted">
                        {message.sender === 'bot' 
                          ? (language === 'es' ? 'Bot' : 'Bot')
                          : (language === 'es' ? 'Usuario' : 'User')}
                        {' - '}
                        {formatDate(message.timestamp)}
                      </small>
                    </div>
                    <div>{message.text}</div>
                  </Card.Body>
                </Card>
              ))}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            {language === 'es' ? 'Cerrar' : 'Close'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConversationsTable;
