import { CSSProperties } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { useAppContext } from 'providers/AppProvider';
import { TooltipComponent } from 'echarts/components';
import { ITopTokenUser } from 'data/models/neuro/INeuroSkillUsage';

// Register necessary ECharts components
echarts.use([TooltipComponent]);

interface ContactsBySourceChartProps {
  data?: ITopTokenUser[];
  style: CSSProperties;
}

const ContactsBySourceChart = ({ data = [], style }: ContactsBySourceChartProps) => {
  const {
    getThemeColor,
    config: { isDark }
  } = useAppContext();

  // Prepare the chart data from ITopTokenUser array
  const chartData = data.map((user, index) => {
    // Create color mapping based on index to match the original color scheme
    const colorMap = [
      'primary',
      'success',
      'info',
      isDark ? 'info-dark' : 'info-light',
      isDark ? 'danger-darker' : 'danger-lighter',
      isDark ? 'warning-dark' : 'warning-light'
    ];

    return {
      name: user.username || 'Unknown User',
      value: user.tokensUsed || 0,
      color: colorMap[index % colorMap.length]
    };
  });

  const getDefaultOptions = (
    getThemeColor: (name: string) => string,
    isDark: boolean
  ) => ({
    color: [
      getThemeColor('primary'),
      getThemeColor('success'),
      getThemeColor('info'),
      !isDark ? getThemeColor('info-light') : getThemeColor('info-dark'),
      !isDark ? getThemeColor('danger-lighter') : getThemeColor('danger-darker'),
      !isDark ? getThemeColor('warning-light') : getThemeColor('warning-dark')
    ],
    tooltip: {
      trigger: 'item',
      borderWidth: 0,
      formatter: '{b}: {c} tokens ({d}%)'
    },
    responsive: true,
    maintainAspectRatio: false,
    series: [
      {
        name: 'Neurotoken Usage',
        type: 'pie',
        radius: ['55%', '90%'],
        startAngle: 90,
        avoidLabelOverlap: false,
        itemStyle: {
          borderColor: getThemeColor('body-bg'),
          borderWidth: 3
        },
        label: {
          show: false
        },
        emphasis: {
          label: {
            show: false
          }
        },
        labelLine: {
          show: false
        },
        data: chartData
      }
    ],
    grid: {
      bottom: 0,
      top: 0,
      left: 0,
      right: 0,
      containLabel: false
    }
  });

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={getDefaultOptions(getThemeColor, isDark)}
      style={style}
    />
  );
};

export default ContactsBySourceChart;