import classNames from 'classnames';
import ContactsBySourceChart from 'components/charts/e-charts/ContactsBySourceChart';
import ContactSourceItem from 'components/grid-list-items/ContactSourceItem';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import WidgetsSectionTitle from '../widgets/WidgetsSectionTitle';
import { faPercentage } from '@fortawesome/free-solid-svg-icons';
import { ITopTokenUser, ITopTokenUsersResponse } from 'data/models/neuro/INeuroSkillUsage';
import { UserService } from 'service/userService';

const ContactsBySource = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [topUsers, setTopUsers] = useState<ITopTokenUser[]>([]);
  const [totalTokensUsed, setTotalTokensUsed] = useState(0);

  useEffect(() => {
    const fetchTopUsers = async () => {
      try {
        setIsLoading(true);
        const response: ITopTokenUsersResponse = await UserService.getTopNeuroSkillUsage();
        setTopUsers(response.users.slice(0, 6)); // Get top 6 users
        
        // Calculate total tokens used
        const total = response.users.reduce((acc: any, user: ITopTokenUser) => acc + (user.tokensUsed || 0), 0);
        setTotalTokensUsed(total);
        
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching top neuro skill usage:', error);
        setIsLoading(false);
      }
    };

    fetchTopUsers();
  }, []);

  if (isLoading) {
    return <div className="text-center">Loading...</div>;
  }

  return (
    <>
      <WidgetsSectionTitle
        title="Users & Tokens"
        subtitle="General Users Neurotokens Statistics."
        icon={faPercentage}
        className="my-5"
      />
      <Row>
        <Col xs={12} sm={7} md={8} xxl={8} className="mb-md-3 mb-lg-0">
          <h3>Top 6 Neurotokens consumption info</h3>
          <p className="text-body-tertiary">
            Distribution of neurotoken consumption per user.
          </p>
          <Row className="g-0">
            {topUsers.map((user, index) => (
              <Col xs={6} xl={4} key={user.userId}>
                <ContactSourceItem
                  value={user.tokensUsed || 0}
                  label={(user.username || 'Unknown User').slice(0, 12)} // Cut username to 12 chars
                  color={'#' + Math.floor(Math.random() * 16777215).toString(16)} // Generate random color
                  className={classNames('border-translucent', {
                    'border-bottom border-end': index === 0,
                    'border-bottom border-end-md-0 border-end-xl': index === 1,
                    'border-bottom border-end border-end-md border-end-xl-0': index === 2,
                    'border-end-xl border-bottom border-bottom-xl-0': index === 3,
                    'border-end': index === 4
                  })}
                />
              </Col>
            ))}
          </Row>
        </Col>
        <Col sm={5} md={4} xxl={4} className="my-3 my-sm-0">
          <div className="position-relative d-flex flex-center mb-sm-4 mb-xl-0 echart-contact-by-source-container mt-sm-7 mt-lg-4 mt-xl-0">
            <ContactsBySourceChart 
              data={topUsers}
              style={{ height: 245, width: '100%' }} 
            />
            <div
              className="position-absolute rounded-circle bg-primary-subtle top-50 start-50 translate-middle d-flex flex-center"
              style={{ height: '100px', width: '100px' }}
            >
              <h3 className="mb-0 text-primary-dark fw-bolder">{totalTokensUsed}</h3>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ContactsBySource;