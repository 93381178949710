import { faWhatsappSquare } from "@fortawesome/free-brands-svg-icons";
import { faCircleDot, faQrcode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProjectElevenProgress from "components/modules/whatsapp/dashboard/WhatsAppDashboard";
import WhatsAppConversationsCharts from "components/modules/whatsapp/charts/WhatsAppConversationsCharts";
import WidgetsSectionTitle from "components/modules/widgets/WidgetsSectionTitle";
import { useState, useEffect, useRef } from 'react';
import { Button, Card, Col, Form, Row, Table, Modal, Spinner } from "react-bootstrap";
import DirectivesTable from "./DirectivesTable"; 
import DirectivesCopilot from "./CoPilotPromptsTable";
import ConversationsTable from "./ConversationsTable";
import axios from 'axios'; 
import { useUserContext } from 'layouts/UserContext';

type LanguageType = 'es' | 'en';

const API_BASE_URL = 'https://backend.anubisai.net/api/whatsapp/bot';
const ML_API_URL = 'https://backend.anubisai.net/api/mybrain/ml/status';
const API_KEY = 'anubis-whatsapp-api-key'; // La API key que configuraste en el backend

// PM2 process name o ID (debe ser único por usuario)
const PM2_PROCESS_NAME = '5'; // Ajustar según el usuario
/*const {
    user,
    isLoading,
    error
  } = useUserContext();
  
  // Generate the PM2 process name using the first character of the username
  const PM2_PROCESS_NAME2 = user ? `WSAPP-SRV_${user.username.charAt(0)}` : '';*/

const WhatsApp = () => {
    const [isWhatsAppEnabled, setIsWhatsAppEnabled] = useState(false);
    const [isDirectivesEnabled, setIsDirectivesEnabled] = useState(false);
    const [restartMessage, setRestartMessage] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [isCoPilotEnabled, setIsCoPilotEnabled] = useState(false);
    const [language, setLanguage] = useState<LanguageType>('es');
    const [pm2Status, setPm2Status] = useState(null);
    const [MLStatus, setMLStatus] = useState<string | null>(null);
    // Estados para el modal del QR code
    const [showQRModal, setShowQRModal] = useState(false);
    const [qrTimestamp, setQrTimestamp] = useState(Date.now());
    const [countdownSeconds, setCountdownSeconds] = useState(15);
    const [qrLoadError, setQrLoadError] = useState(false);
    const [isEndpointAvailable, setIsEndpointAvailable] = useState(true); // Estado para la disponibilidad del endpoint
    const qrUpdateInterval = useRef<NodeJS.Timeout | null>(null);
    const autoCloseTimeout = useRef<NodeJS.Timeout | null>(null);
    const countdownInterval = useRef<NodeJS.Timeout | null>(null);
    
    // Nuevo estado para mostrar indicación de reinicio
    const [isRestarting, setIsRestarting] = useState(false);
    
    // Nuevo estado para rastrear errores de API
    const [apiError, setApiError] = useState<string | null>(null);
    
    // Duración del autoclose en milisegundos (15 segundos)
    const AUTO_CLOSE_DURATION = 15000;
    
    // Verificar el estado actual del bot al cargar el componente
    useEffect(() => {
        // Verificación inicial
        checkBotStatus();
        
        // Verificar periódicamente el estado (cada 60 segundos en lugar de 30)
        const statusInterval = setInterval(checkBotStatus, 60000);
        
        return () => {
            clearInterval(statusInterval);
        };
    }, []);
    
// Función para verificar el estado actual del bot desde la API
const checkBotStatus = async () => {
    try {
        // Incluir el nombre del proceso PM2 en la solicitud
        const response = await axios.get(`${API_BASE_URL}/status`, {
            headers: { 'x-api-key': API_KEY },
            params: { processName: PM2_PROCESS_NAME },
            timeout: 10000 // 10 segundos
        });
        
        if (response.data.success) {
            console.log("Estado recibido del servidor:", response.data);
            
            // IMPORTANTE: Sincronizar explícitamente los estados con lo que reporta el servidor
            // Esto debería resolver el problema donde la UI no refleja el estado real
            setIsWhatsAppEnabled(response.data.botEnabled);
            setIsDirectivesEnabled(response.data.dynamicFlowsEnabled);
            setIsCoPilotEnabled(response.data.copilotEnabled || false);
            
            // Verificar el estado del proceso PM2
            if (response.data.pm2) {
                const pm2Status = response.data.pm2.status;
                setPm2Status(pm2Status);
                
                // Solo mostrar advertencia si el proceso está caído y no está en proceso de reinicio
                if (!response.data.pm2.found || (pm2Status !== 'online' && !isRestarting)) {
                    console.warn(`Proceso PM2 no disponible o no en línea: ${pm2Status}`);
                    if (response.data.botEnabled) {
                        setApiError(language === 'es' 
                            ? 'El servicio WhatsApp no está en ejecución pero la interfaz está activada. Contacte al administrador.' 
                            : 'WhatsApp service is not running but the interface is enabled. Contact administrator.');
                    }
                }
            }
        }

        // Verificar el estado del servicio ML en una llamada separada
        try {
            const mlResponse = await axios.get(ML_API_URL, {
                headers: { 'x-api-key': API_KEY },
                timeout: 5000 // 5 segundos
            });
            
            console.log("Estado ML recibido:", mlResponse.data);
            if (mlResponse.data) {
                // Log detallado para depuración
                console.log("ML status details - success:", mlResponse.data.success, "status:", mlResponse.data.status);
                
                // Actualización importante: establecer siempre como 'running' cuando esté activo
                if (mlResponse.data.success === true || mlResponse.data.status === 'running') {
                    console.log("Setting ML status to 'running'");
                    setMLStatus('running');
                } else {
                    console.log("Setting ML status to 'stopped'");
                    setMLStatus('stopped');
                }
            } else {
                console.log("ML response data is null or undefined");
                setMLStatus(null);
            }
        } catch (mlError) {
            console.error('Error al verificar estado de ML Service:', mlError);
            setMLStatus(null);
        }
    } catch (error) {
        console.error('Error al verificar el estado del bot:', error);
        
        // No mostrar error si estamos en proceso de reinicio
        if (!isRestarting) {
            setApiError('No se pudo conectar con el servicio de WhatsApp');
        }
    }
};
    
    // Función para controlar el estado del bot (encendido/apagado)
    const toggleBotState = async (enabled: boolean) => {
        try {
            setIsRestarting(true); // Mostrar indicador de reinicio
            
            const response = await axios.post(`${API_BASE_URL}/toggle-ui`, 
                { 
                    enabled: enabled,
                    processName: PM2_PROCESS_NAME
                },
                { headers: { 'x-api-key': API_KEY } }
            );
            
            if (response.data.success) {
                console.log(`Bot ${enabled ? 'activado' : 'desactivado'} exitosamente`);
                console.log('PM2 action:', response.data.pm2Action);
                console.log('PM2 message:', response.data.pm2Message);
                
                // Si no se realizó ninguna acción, quizás no sea necesario esperar
                const waitTime = response.data.pm2Action === 'none' ? 1000 : 5000;
                
                // Esperar un tiempo para que el servicio se reinicie o actualice
                setTimeout(() => {
                    checkBotStatus(); // Verificar el estado actual después de la acción
                    setIsRestarting(false); // Ocultar indicador de reinicio
                }, waitTime);
                
                return true;
            } else {
                console.error('Error al cambiar el estado del bot:', response.data.message);
                setApiError(response.data.message);
                setIsRestarting(false);
                return false;
            }
        } catch (error) {
            console.error('Error en la API al cambiar el estado del bot:', error);
            setApiError('Error al comunicarse con el servicio de WhatsApp');
            setIsRestarting(false);
            return false;
        }
    };
    
    // Función para controlar el estado de los flujos dinámicos
    const toggleDynamicFlows = async (enabled: boolean) => {
        try {
            setIsRestarting(true); // Mostrar indicador de reinicio
            
            const response = await axios.post(`${API_BASE_URL}/dynamicflows/toggle`,
                { 
                    enabled: enabled,
                    processName: PM2_PROCESS_NAME
                },
                { headers: { 'x-api-key': API_KEY } }
            );
            
            if (response.data.success) {
                console.log(`Flujos dinámicos ${enabled ? 'activados' : 'desactivados'} exitosamente`);
                console.log('PM2 action:', response.data.pm2Action);
                console.log('PM2 message:', response.data.pm2Message);
                
                // Esperar un tiempo para que el servicio se reinicie
                const waitTime = response.data.pm2Action === 'none' ? 1000 : 5000;
                
                setTimeout(() => {
                    checkBotStatus(); // Verificar el estado actual después del reinicio
                    setIsRestarting(false); // Ocultar indicador de reinicio
                }, waitTime);
                
                return true;
            } else {
                console.error('Error al cambiar el estado de los flujos dinámicos:', response.data.message);
                setApiError(response.data.message);
                setIsRestarting(false);
                return false;
            }
        } catch (error) {
            console.error('Error en la API al cambiar el estado de los flujos dinámicos:', error);
            setApiError('Error al comunicarse con el servicio de WhatsApp');
            setIsRestarting(false);
            return false;
        }
    };
    
    // Función para controlar el estado de CoPilot
    const toggleCoPilot = async (enabled: boolean) => {
        try {
            setIsRestarting(true); // Mostrar indicador de reinicio
            
            const response = await axios.post(`${API_BASE_URL}/copilot/toggle`,
                { 
                    enabled: enabled,
                    processName: PM2_PROCESS_NAME
                },
                { headers: { 'x-api-key': API_KEY } }
            );
            
            if (response.data.success) {
                console.log(`Modo CoPilot ${enabled ? 'activado' : 'desactivado'} exitosamente`);
                console.log('PM2 action:', response.data.pm2Action);
                console.log('PM2 message:', response.data.pm2Message);
                
                // Esperar un tiempo para que el servicio se reinicie
                const waitTime = response.data.pm2Action === 'none' ? 1000 : 5000;
                
                setTimeout(() => {
                    checkBotStatus(); // Verificar el estado actual después del reinicio
                    setIsRestarting(false); // Ocultar indicador de reinicio
                }, waitTime);
                
                return true;
            } else {
                console.error('Error al cambiar el estado de CoPilot:', response.data.message);
                setApiError(response.data.message);
                setIsRestarting(false);
                return false;
            }
        } catch (error) {
            console.error('Error en la API al cambiar el estado de CoPilot:', error);
            setApiError('Error al comunicarse con el servicio de WhatsApp');
            setIsRestarting(false);
            return false;
        }
    };
    
    // Función para el interruptor principal de WhatsApp
    const handleWhatsAppSwitch = async () => {
        // Si el endpoint no está disponible, no permitir activar
        if (!isEndpointAvailable && !isWhatsAppEnabled) {
            alert(language === 'es' 
                ? 'El servicio de WhatsApp no está disponible en este momento. Inténtelo más tarde.' 
                : 'WhatsApp service is not available at the moment. Please try again later.');
            return;
        }
        
        // Si activamos, verificar que el proceso PM2 esté en ejecución
        if (!isWhatsAppEnabled) {
            try {
                const response = await axios.get(`${API_BASE_URL}/pm2status`, {
                    headers: { 'x-api-key': API_KEY },
                    params: { processName: PM2_PROCESS_NAME }
                });
                
                if (response.data.success && (!response.data.found || response.data.status !== 'online')) {
                    setApiError(language === 'es' 
                        ? 'El servicio WhatsApp no está en ejecución. Contacte al administrador.' 
                        : 'WhatsApp service is not running. Contact administrator.');
                    return;
                }
            } catch (error) {
                console.error('Error al verificar el proceso PM2:', error);
                setApiError('Error al comprobar el estado del servicio');
                return;
            }
        }
        
        const newState = !isWhatsAppEnabled;
        
        // IMPORTANTE: Mostrar indicador de reinicio para WhatsApp también,
        // ya que ahora requiere reinicio del proceso
        setIsRestarting(true);
        
        try {
            // Actualizar el estado local inmediatamente para mejor UX
            setIsWhatsAppEnabled(newState);
            
            // Mensaje específico para el estado de reinicio
            setRestartMessage(language === 'es'
                ? `${newState ? 'Activando' : 'Desactivando'} WhatsApp Assistant y reiniciando el servicio. Este proceso puede tardar hasta 30 segundos...`
                : `${newState ? 'Enabling' : 'Disabling'} WhatsApp Assistant and restarting the service. This process may take up to 30 seconds...`);
            
            // Si estamos desactivando WhatsApp, también desactivamos las otras opciones
            if (!newState) {
                setIsDirectivesEnabled(false);
                setIsCoPilotEnabled(false);
            }
            
            // Actualizar la configuración en el backend Y reiniciar el proceso
            const response = await axios.post(`${API_BASE_URL}/toggle-ui`, 
                { 
                    enabled: newState,
                    processName: PM2_PROCESS_NAME
                },
                { 
                    headers: { 'x-api-key': API_KEY },
                    timeout: 30000
                }
            );
            
            if (response.data.success) {
                console.log(`Interfaz de WhatsApp ${newState ? 'activada' : 'desactivada'}`);
                
                // Actualizar el mensaje de reinicio
                setRestartMessage(language === 'es'
                    ? 'Cambio aplicado. Esperando a que el servicio se estabilice...'
                    : 'Change applied. Waiting for the service to stabilize...');
                
                // Primera verificación después de 5 segundos
                setTimeout(() => {
                    checkBotStatus();
                    setRestartMessage(language === 'es' ? 'Verificando estado del servicio (1/3)...' : 'Checking service status (1/3)...');
                }, 3000);
                
                // Segunda verificación después de 15 segundos
                setTimeout(() => {
                    checkBotStatus();
                    setRestartMessage(language === 'es' ? 'Verificando estado del servicio (2/3)...' : 'Checking service status (2/3)...');
                }, 3000);
                
                // Verificación final y quitar el indicador de reinicio después de 30 segundos
                setTimeout(() => {
                    checkBotStatus();
                    setIsRestarting(false);
                    setRestartMessage(null);
                    
                    setSuccessMessage(language === 'es'
                        ? `WhatsApp Assistant ${newState ? 'activado' : 'desactivado'} correctamente`
                        : `WhatsApp Assistant ${newState ? 'enabled' : 'disabled'} successfully`);
                    
                    setTimeout(() => {
                        setSuccessMessage(null);
                    }, 3000);
                }, 10000);
            } else {
                console.error('Error al cambiar estado:', response.data.message);
                setApiError(response.data.message);
                setIsRestarting(false);
                setRestartMessage(null);
                // Revertir cambio local si hubo error
                setIsWhatsAppEnabled(!newState);
            }
        } catch (error) {
            console.error('Error al comunicarse con el API:', error);
            setApiError('Error de comunicación con el servicio WhatsApp');
            setIsRestarting(false);
            setRestartMessage(null);
        }
    };

    // Función para el interruptor de Directivas
    const handleDirectivesSwitch = async () => {
        // No permitir activar si la interfaz principal está desactivada
        if (!isWhatsAppEnabled) {
            setApiError(language === 'es'
                ? 'Debe activar WhatsApp Assistant primero'
                : 'You must enable WhatsApp Assistant first');
            return;
        }
        
        const newState = !isDirectivesEnabled;
        
        // Mostrar indicador de reinicio con mensaje específico
        setIsRestarting(true);
        
        try {
            // Actualizar el estado local inmediatamente para mejor UX
            setIsDirectivesEnabled(newState);
            
            // Mensaje específico para el estado de reinicio
            setRestartMessage(language === 'es'
                ? `${newState ? 'Activando' : 'Desactivando'} directivas y reiniciando el servicio. Este proceso puede tardar hasta 30 segundos...`
                : `${newState ? 'Enabling' : 'Disabling'} directives and restarting the service. This process may take up to 30 seconds...`);
            
            // CAMBIO IMPORTANTE: NO desactivar WhatsApp cuando se desactivan las directivas
            // Sólo desactivar CoPilot si estamos desactivando directivas y CoPilot está activo
            if (!newState && isCoPilotEnabled) {
                setIsCoPilotEnabled(false);
            }
            
            // Llamada a la API
            console.log('Enviando solicitud para cambiar flujos dinámicos...');
            const response = await axios.post(`${API_BASE_URL}/dynamicflows/toggle`,
                { 
                    enabled: newState,
                    processName: PM2_PROCESS_NAME
                },
                { 
                    headers: { 'x-api-key': API_KEY },
                    timeout: 30000
                }
            );
            
            if (response.data.success) {
                console.log(`Flujos dinámicos ${newState ? 'activados' : 'desactivados'} exitosamente`);
                
                // Actualizar el mensaje de reinicio
                setRestartMessage(language === 'es'
                    ? 'Cambio aplicado. Esperando a que el servicio se estabilice...'
                    : 'Change applied. Waiting for the service to stabilize...');
                
                // IMPORTANTE: Aumentar el tiempo de espera a 30 segundos en total
                // para dar tiempo suficiente al proceso PM2 para reiniciarse completamente
                
                // Primera verificación después de 5 segundos
                setTimeout(() => {
                    checkBotStatus();
                    console.log('Primera verificación después de 5 segundos');
                    
                    // Actualizar mensaje
                    setRestartMessage(language === 'es'
                        ? 'Verificando estado del servicio (1/3)...'
                        : 'Checking service status (1/3)...');
                    
                }, 5000);
                
                // Segunda verificación después de 5 segundos
                setTimeout(() => {
                    checkBotStatus();
                    console.log('Segunda verificación después de 5 segundos');
                    
                    // Actualizar mensaje
                    setRestartMessage(language === 'es'
                        ? 'Verificando estado del servicio (2/3)...'
                        : 'Checking service status (2/3)...');
                    
                }, 5000);
                
                // Verificación final y quitar el indicador de reinicio después de 30 segundos
                setTimeout(() => {
                    checkBotStatus();
                    setIsRestarting(false);
                    setRestartMessage(null);
                    console.log('Verificación final después de 30 segundos, proceso completado');
                    
                    // Mostrar mensaje de éxito
                    setSuccessMessage(language === 'es'
                        ? `Directivas ${newState ? 'activadas' : 'desactivadas'} correctamente`
                        : `Directives ${newState ? 'enabled' : 'disabled'} successfully`);
                    
                    // Auto-ocultar mensaje de éxito después de 3 segundos
                    setTimeout(() => {
                        setSuccessMessage(null);
                    }, 3000);
                    
                }, 30000);
            } else {
                console.error('Error al cambiar flujos dinámicos:', response.data.message);
                setApiError(response.data.message);
                setIsRestarting(false);
                setRestartMessage(null);
                // Revertir cambio local si hubo error
                setIsDirectivesEnabled(!newState);
            }
        } catch (error) {
            console.error('Error en la API:', error);
            setApiError(language === 'es'
                ? 'Error de comunicación con el servicio. El reinicio puede seguir en proceso, espere unos minutos antes de intentar nuevamente.'
                : 'Communication error with the service. The restart may still be in progress, wait a few minutes before trying again.');
            setIsRestarting(false);
            setRestartMessage(null);
            
            // No revertimos el estado local aquí, porque el reinicio podría seguir en proceso
            // a pesar del timeout de la solicitud HTTP
        }
    };

    // Función para el interruptor de CoPilot
    const handleCoPilotSwitch = async () => {
        // Implementación similar a handleDirectivesSwitch con los mismos tiempos de espera...
        // (código similar al anterior)
        
        // No permitir activar si la interfaz principal o las directivas están desactivadas
        if (!isWhatsAppEnabled || !isDirectivesEnabled) {
            setApiError(language === 'es'
                ? 'Debe activar WhatsApp Assistant y Directivas primero'
                : 'You must enable WhatsApp Assistant and Directives first');
            return;
        }
        
        const newState = !isCoPilotEnabled;
        
        // Mostrar indicador de reinicio con mensaje específico
        setIsRestarting(true);
        
        try {
            // Actualizar estado local inmediatamente para mejor UX
            setIsCoPilotEnabled(newState);
            
            // Mensaje específico para el estado de reinicio
            setRestartMessage(language === 'es'
                ? `${newState ? 'Activando' : 'Desactivando'} modo CoPilot y reiniciando el servicio. Este proceso puede tardar hasta 30 segundos...`
                : `${newState ? 'Enabling' : 'Disabling'} CoPilot mode and restarting the service. This process may take up to 30 seconds...`);
            
            // Llamada a la API con timeout extendido
            console.log('Enviando solicitud para cambiar modo CoPilot...');
            const response = await axios.post(`${API_BASE_URL}/copilot/toggle`,
                { 
                    enabled: newState,
                    processName: PM2_PROCESS_NAME
                },
                { 
                    headers: { 'x-api-key': API_KEY },
                    timeout: 30000 
                }
            );
            
            if (response.data.success) {
                console.log(`CoPilot ${newState ? 'activado' : 'desactivado'} exitosamente`);
                
                // Actualizar el mensaje de reinicio
                setRestartMessage(language === 'es'
                    ? 'Cambio aplicado. Esperando a que el servicio se estabilice...'
                    : 'Change applied. Waiting for the service to stabilize...');
                
                // Misma secuencia de verificaciones que en handleDirectivesSwitch
                setTimeout(() => {
                    checkBotStatus();
                    setRestartMessage(language === 'es' ? 'Verificando estado del servicio (1/3)...' : 'Checking service status (1/3)...');
                }, 5000);
                
                setTimeout(() => {
                    checkBotStatus();
                    setRestartMessage(language === 'es' ? 'Verificando estado del servicio (2/3)...' : 'Checking service status (2/3)...');
                }, 5000);
                
                setTimeout(() => {
                    checkBotStatus();
                    setIsRestarting(false);
                    setRestartMessage(null);
                    
                    setSuccessMessage(language === 'es'
                        ? `Modo CoPilot ${newState ? 'activado' : 'desactivado'} correctamente`
                        : `CoPilot mode ${newState ? 'enabled' : 'disabled'} successfully`);
                    
                    setTimeout(() => {
                        setSuccessMessage(null);
                    }, 3000);
                }, 30000);
            } else {
                console.error('Error al cambiar CoPilot:', response.data.message);
                setApiError(response.data.message);
                setIsRestarting(false);
                setRestartMessage(null);
                setIsCoPilotEnabled(!newState);
            }
        } catch (error) {
            console.error('Error en la API:', error);
            setApiError(language === 'es'
                ? 'Error de comunicación con el servicio. El reinicio puede seguir en proceso, espere unos minutos antes de intentar nuevamente.'
                : 'Communication error with the service. The restart may still be in progress, wait a few minutes before trying again.');
            setIsRestarting(false);
            setRestartMessage(null);
        }
    };
    
    // Función para abrir el modal del QR
    const handleShowQRModal = () => {
        setShowQRModal(true);
        setQrTimestamp(Date.now());
        setCountdownSeconds(15); // Reiniciar el contador a 15 segundos
        setQrLoadError(false); // Resetear el estado de error
        
        // Configurar un intervalo para actualizar el QR code cada 1 segundo
        qrUpdateInterval.current = setInterval(() => {
            setQrTimestamp(Date.now());
        }, 1000);
        
        // Configurar el autoclose después de 15 segundos
        autoCloseTimeout.current = setTimeout(() => {
            handleCloseQRModal();
        }, AUTO_CLOSE_DURATION);
        
        // Configurar el intervalo para el countdown
        countdownInterval.current = setInterval(() => {
            setCountdownSeconds(prevSeconds => {
                if (prevSeconds <= 1) {
                    return 0;
                }
                return prevSeconds - 1;
            });
        }, 1000);
    };
    
    // Función para cerrar el modal del QR
    const handleCloseQRModal = () => {
        setShowQRModal(false);
        
        // Limpiar todos los intervalos y timeouts cuando se cierra el modal
        if (qrUpdateInterval.current) {
            clearInterval(qrUpdateInterval.current);
            qrUpdateInterval.current = null;
        }
        
        if (autoCloseTimeout.current) {
            clearTimeout(autoCloseTimeout.current);
            autoCloseTimeout.current = null;
        }
        
        if (countdownInterval.current) {
            clearInterval(countdownInterval.current);
            countdownInterval.current = null;
        }
    };
    
    // Verificar disponibilidad del endpoint al cargar el componente
    useEffect(() => {
        checkEndpointAvailability();
        
        // Verificar periódicamente la disponibilidad del endpoint (cada 30 segundos)
        const endpointCheckInterval = setInterval(checkEndpointAvailability, 30000);
        
        return () => {
            clearInterval(endpointCheckInterval);
        };
    }, []);
    
    // Función para verificar la disponibilidad del endpoint
    const checkEndpointAvailability = () => {
        // Crear una imagen temporal para probar la conexión
        const testImg = new Image();
        const timestamp = Date.now();
        testImg.src = `https://backend.anubisai.net/api/whatsapp/bot/qr/qr.png?time=${timestamp}`;
        
        // Establecer un timeout de 5 segundos
        const timeoutId = setTimeout(() => {
            setIsEndpointAvailable(false);
            console.log('WhatsApp endpoint is not available (timeout)');
        }, 5000);
        
        // Manejar carga exitosa
        testImg.onload = () => {
            clearTimeout(timeoutId);
            setIsEndpointAvailable(true);
            console.log('WhatsApp endpoint is available');
        };
        
        // Manejar error de carga
        testImg.onerror = () => {
            clearTimeout(timeoutId);
            setIsEndpointAvailable(false);
            console.log('WhatsApp endpoint is not available (error)');
        };
    };
    
    // Efecto para deshabilitar WhatsApp cuando el endpoint no está disponible
    useEffect(() => {
        if (!isEndpointAvailable && isWhatsAppEnabled) {
            setIsWhatsAppEnabled(false);
            setIsDirectivesEnabled(false);
            setIsCoPilotEnabled(false);
            
            // Desactivar el bot en el backend solo si podemos conectarnos al API
            try {
                toggleBotState(false);
            } catch (error) {
                console.error('No se pudo desactivar el bot debido a problemas de conexión');
            }
        }
    }, [isEndpointAvailable, isWhatsAppEnabled]);

    // Limpiar los intervalos y timeouts cuando el componente se desmonta
    useEffect(() => {
        return () => {
            if (qrUpdateInterval.current) {
                clearInterval(qrUpdateInterval.current);
            }
            
            if (autoCloseTimeout.current) {
                clearTimeout(autoCloseTimeout.current);
            }
            
            if (countdownInterval.current) {
                clearInterval(countdownInterval.current);
            }
        };
    }, []);
    
    // Visualización de los datos de WhatsApp conversaciones
    const renderConversationsChart = () => {
        if (!isWhatsAppEnabled || isRestarting) {
            return null;
        }
        
        return (
            <div className="mt-4">
                <h4 className="mb-3">
                    {language === 'es' ? 'Evolución de mensajes por contacto' : 'Message evolution by contact'}
                </h4>
                <WhatsAppConversationsCharts language={language} days={7} />
            </div>
        );
    };

    return(<> 
        <div>
            <WidgetsSectionTitle
                title="Whatsapp AI Integration System"
                subtitle="Manage and administer your AI with your personal or business WhatsApp"
                icon={faWhatsappSquare}
                className="my-5"
            />
            <div className="px-3 mb-5">
                <ProjectElevenProgress language={language} />
                {!isEndpointAvailable && (
                    <div className="alert alert-warning mt-3">
                        <i className="fas fa-exclamation-triangle me-2"></i>
                        {language === 'es' 
                            ? 'El servidor de WhatsApp no está disponible en este momento. Las funciones de WhatsApp han sido deshabilitadas.' 
                            : 'WhatsApp server is currently unavailable. WhatsApp features have been disabled.'}
                    </div>
                )}
                {apiError && (
                    <div className="alert alert-danger mt-3">
                        <i className="fas fa-exclamation-circle me-2"></i>
                        {apiError}
                        <button 
                            type="button" 
                            className="btn-close float-end" 
                            onClick={() => setApiError(null)}
                            aria-label="Close"
                        ></button>
                    </div>
                )}
                {isRestarting && (
                    <div className="alert alert-info mt-3">
                        <Spinner animation="border" size="sm" className="me-2" />
                        {restartMessage || (language === 'es' 
                            ? 'Actualizando el servicio de WhatsApp...' 
                            : 'Updating WhatsApp service...')}
                    </div>
                )}
                {successMessage && !isRestarting && (
                    <div className="alert alert-success mt-3">
                        <i className="fas fa-check-circle me-2"></i>
                        {successMessage}
                    </div>
                )}
            </div>
            <div className="mb-3">
                <Button 
                    variant="primary" 
                    className="fs-10"
                    disabled={!isWhatsAppEnabled || !isEndpointAvailable || isRestarting}
                    onClick={handleShowQRModal}
                >
                    <FontAwesomeIcon icon={faQrcode} /> {language === 'es' ? 'Mostrar código QR' : 'Show QR Code'}
                </Button>
            </div>
            <Row xs={12} xl={12}>
                <Col sm={12} xl={8}>
                    <Table striped bordered hover>
                        <thead className="text-center">
                            <tr>
                                <th>?</th>
                                <th>Options</th>
                                <th>Enabled / disabled</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="text-center"><FontAwesomeIcon icon={faCircleDot} /></td>
                                <td>WhatsApp Assistant</td>
                                <td>
                                    <Form.Check 
                                        type='switch'
                                        id='WSOnOff'
                                        label={isWhatsAppEnabled 
                                            ? (language === 'es' ? 'Desactivar asistente WhatsApp' : 'Disable WhatsApp assistant')
                                            : isEndpointAvailable 
                                                ? (language === 'es' ? 'WhatsApp está desactivado' : 'WhatsApp has been disabled')
                                                : (language === 'es' ? 'Servicio WhatsApp no disponible' : 'WhatsApp service unavailable')}
                                        checked={isWhatsAppEnabled}
                                        onChange={handleWhatsAppSwitch}
                                        disabled={(!isEndpointAvailable || isEndpointAvailable !==true && !isWhatsAppEnabled && !pm2Status || pm2Status !=='online') || isRestarting}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="text-center"><FontAwesomeIcon icon={faCircleDot} /></td>
                                <td>{language === 'es' ? 'Directivas de Respuesta Automática' : 'Auto Answer Directives'}</td>
                                <td>
                                <Form.Check 
                                    type='switch'
                                    id='directivesWS'
                                    label={isDirectivesEnabled 
                                        ? (language === 'es' ? 'Directivas activas' : 'Directives active')  
                                        : (language === 'es' ? 'Directivas inactivas' : 'Directives inactive')}
                                    checked={isDirectivesEnabled}
                                    onChange={handleDirectivesSwitch}
                                    disabled={!isWhatsAppEnabled || isRestarting || !pm2Status || pm2Status !== 'online'}
                                />
                                </td>
                            </tr>
                            <tr>
                                <td className="text-center"><FontAwesomeIcon icon={faCircleDot} /></td>
                                <td>{process.env.REACT_APP_NAME} {language === 'es' ? 'Modo CoPilot (auto-aprendizaje)' : 'CoPilot mode (autolearn)'}</td>
                                <td>
                                    <Form.Check 
                                        type='switch'
                                        id='coPilotWS'
                                        label={isCoPilotEnabled 
                                            ? (language === 'es' ? 'Desactivar copilot' : 'Disable copilot') 
                                            : (language === 'es' ? 'Copilot desactivado' : 'Co-pilot has been disabled')}
                                        checked={isCoPilotEnabled}
                                        onChange={handleCoPilotSwitch}
                                        disabled={!isWhatsAppEnabled || !isDirectivesEnabled || !isEndpointAvailable || !MLStatus || isRestarting}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
                <Col xl={4} sm={12}>
                    <Card className="">
                        <Card.Body>
                            <h4 className="mb-4">{language === 'es' ? 'Servicio WhatsApp' : 'WhatsApp Service'}</h4>
                            <Row className="mb-2">
                                <Col xl={6}>
                                    <div>{language === 'es' ? 'Estado del servicio' : 'Service status'}:
                                    </div>
                                </Col>
                                <Col xl={4}>
                                    <div><span className={`ms-1 ${isEndpointAvailable ? 'text-success' : 'text-danger'}`}>
                                        {isEndpointAvailable 
                                            ? (language === 'es' ? 'En línea' : 'Online') 
                                            : (language === 'es' ? 'Fuera de línea' : 'Offline')}
                                        </span></div>
                                </Col>
                                <Col>
                                    <div 
                                        className={`status-indicator me-2 ${isEndpointAvailable ? 'bg-success' : 'bg-danger'}`} 
                                        style={{ 
                                            width: '12px', 
                                            height: '12px', 
                                            borderRadius: '50%',
                                        }}
                                    ></div>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                            <Col xl={6}>
                                    <div>{language === 'es' ? 'Servicio PM2' : 'PM2 Service'}:
                                    </div>
                                </Col>
                                <Col xl={4}>
                                    <span className={`ms-1 ${pm2Status === 'online' ? 'text-success' : 'text-danger'}`}>
                                        {pm2Status === 'online' 
                                            ? (language === 'es' ? 'En línea' : 'Running') 
                                            : pm2Status === 'stopped' 
                                                ? (language === 'es' ? 'Detenido' : 'Stopped') 
                                                : (language === 'es' ? 'Desconocido' : 'Unknown')}
                                    </span>
                                </Col>
                                <Col>
                                    <div 
                                        className={`status-indicator me-2 ${pm2Status === 'online' ? 'bg-success' : 'bg-danger'}`} 
                                        style={{ 
                                            width: '12px', 
                                            height: '12px', 
                                            borderRadius: '50%',
                                        }}
                                    ></div>
                                </Col>
                            </Row>
                            <Row className="mb-2">
    <Col xl={6}>
        <div>{language === 'es' ? 'Servicio ML' : 'ML Service'}:
        </div>
    </Col>
    <Col xl={4}>
        <span className={`ms-1 ${MLStatus === 'running' ? 'text-success' : 'text-danger'}`}>
            {MLStatus === 'running' 
                ? (language === 'es' ? 'En línea' : 'Running') 
                : MLStatus === 'stopped' 
                    ? (language === 'es' ? 'Detenido' : 'Stopped') 
                    : (language === 'es' ? 'Desconocido' : 'Unknown')}
        </span>
    </Col>
    <Col>
        <div 
            className={`status-indicator me-2 ${MLStatus === 'running' ? 'bg-success' : 'bg-danger'}`} 
            style={{ 
                width: '12px', 
                height: '12px', 
                borderRadius: '50%',
            }}
        ></div>
    </Col>
</Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            
            {/* Tabla de directivas de respuesta automática */}
            {isDirectivesEnabled && isWhatsAppEnabled && !isRestarting && (
                <DirectivesTable language={language} />
            )}
            
            {/* Sección de CoPilot */}
            {isCoPilotEnabled && isWhatsAppEnabled && !isRestarting && (
                <>{/*<DirectivesCopilot language={language} />*/}<div className="mt-4">
                    <h4 className="mb-3">
                        {language === 'es' ? 'Configuración de CoPilot' : 'CoPilot Configuration'}
                    </h4>
                    <DirectivesCopilot language={language} />
                </div></>
            )}
            
            {/* Sección de conversaciones de WhatsApp */}
            {isWhatsAppEnabled && !isRestarting && (
                <div className="mt-4">
                    <h4 className="mb-3">
                        {language === 'es' ? 'Conversaciones de WhatsApp' : 'WhatsApp Conversations'}
                    </h4>
                    <ConversationsTable language={language} />
                </div>
            )}
            
            {/* Modal para mostrar el código QR */}
            <Modal show={showQRModal} onHide={handleCloseQRModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{language === 'es' ? 'Escanear código QR de WhatsApp' : 'Scan WhatsApp QR Code'}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <p>{language === 'es' ? 'Escanea este código QR con tu WhatsApp para conectar' : 'Scan this QR code with your WhatsApp to connect'}</p>
                    
                    {!qrLoadError ? (
                        <img 
                            src={`https://backend.anubisai.net/api/whatsapp/bot/qr/qr.png?time=${qrTimestamp}`} 
                            alt="WhatsApp QR Code" 
                            style={{ maxWidth: '100%', height: 'auto' }}
                            onError={(e) => {
                                console.error("Error loading QR code", e);
                                setQrLoadError(true);
                            }}
                        />
                    ) : (
                        <div className="alert alert-warning my-4">
                            <i className="fas fa-exclamation-triangle me-2"></i>
                            {language === 'es' 
                                ? 'El código QR no está disponible en este momento. Por favor, inténtelo más tarde.' 
                                : 'QR code is not available at the moment. Please try again later.'}
                        </div>
                    )}
                    
                    <p className="mt-3 text-muted">
                        {language === 'es' 
                            ? `Cerrando automáticamente en `
                            : `Auto-closing in `} 
                            <span className="fw-bold">{countdownSeconds}</span> 
                            {language === 'es' ? ` segundos` : ` seconds`}
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseQRModal}>
                            {language === 'es' ? 'Cerrar' : 'Close'}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>);
    };
    
    export default WhatsApp;