import Avatar from 'components/base/Avatar';
import { useWizardFormContext } from 'providers/WizardFormProvider';
import { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import avatarPlaceholder from 'assets/img/team/avatar.webp';
import AvatarDropzone from 'components/common/AvatarNSDropzone';
import { WizardFormData } from 'pages/modules/forms/WizardExample';
import Dropzone from 'components/base/Dropzone';
import { UserService } from 'service/userService';
import { INeuroSkillFileManifest } from 'data/models/neuro/INeuroSkill';

const WizardPersonalForm = () => {
  const methods = useWizardFormContext<WizardFormData>();
  const { formData, onChange, validation } = methods;
  const [avatar, setAvatar] = useState(avatarPlaceholder);

  const [fileError, setFileError] = useState<string | null>(null);
  const [isRequestingManifest, setRequestingManifest] = useState(false);

  const onDrop = (acceptedFiles: File[]) => {
    setAvatar(URL.createObjectURL(acceptedFiles[0]));
  };

  async function uploadFile(file: File) {
    try {
      setRequestingManifest(true);
      const manifest: INeuroSkillFileManifest = await UserService.requestNeuroSkillFileManifest(file);

      methods.formData.moduleManifests.push(manifest);

      console.log("===== MANIFESTS =====")
      methods.formData.moduleManifests.forEach((manifest) => {
        console.log(manifest);
      });

      setFileError(null); 
    } catch (error) {
      console.log(error);
      setFileError("Failed to fetch manifest.");
    } finally {
      setRequestingManifest(false);
    }
  }

  return (
    <>
      <Row className="mb-4">
        <Col md="auto">
          <Avatar src={avatar} placeholder size="3xl" />
        </Col>
        <Col>
          <AvatarDropzone onDrop={onDrop} />
        </Col>
      </Row>
      <Form.Group className="mb-2">
        <Form.Label>Module General</Form.Label>
        <Form.Control
          name="general_v1"
          type="text"
          onChange={onChange}
          required={validation}
          placeholder='Module General v1.0.0'
          value="Module General v1.0.0"
          readOnly
        >
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          This field is required.
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label>Files for module:</Form.Label>
          <Dropzone reactDropZoneProps={{
            accept: {
              'application/pdf': ['.pdf'],
            },
            multiple: false,
            maxSize: 400000, // 400KB
            disabled: isRequestingManifest,
            onDropRejected: (reject) => {
              setFileError(reject[0].errors[0].message);
            }
          }} onDrop={acceptedFiles => {
            console.log(acceptedFiles);

            if (acceptedFiles.length > 0) {
              uploadFile(acceptedFiles[0]);
            }
          }}/>
        <Form.Control.Feedback type="invalid">
          This field is required.
        </Form.Control.Feedback>
        {fileError && <Form.Text className="text-danger">{fileError}</Form.Text>}
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label>Description for version</Form.Label>
        <Form.Control
          as="textarea"
          value={formData.address}
          onChange={onChange}
          rows={4}
          required={validation}
        />
        <Form.Control.Feedback type="invalid">
          This field is required.
        </Form.Control.Feedback>
      </Form.Group>
    </>
  );
};

export default WizardPersonalForm;
